import { differenceInDays, isBefore, isSameDay, isValid } from 'date-fns';
import {
  ActivityRegionType,
  AdvertiseRegionOptionsEnum,
  EEventApplicationType,
} from 'ui/enums';
import { ActivityRegion } from 'ui/types/activities';
import { createNationWithRegionsDictionary } from 'ui/utils/activityRegions';
import { TestContext } from 'yup';

import { volunteerNumberSchemaValidation } from '~/pages/Authenticated/Events/schemaValidations';
import { ICreateActivityDTO, IEditActivityDTO } from '~/types/dtos';
import Yup from '~/utils/validations/yup';

import { CheckboxesRegions } from './types';

interface TestContextExtended {
  from: {
    value: ICreateActivityDTO;
  }[];
}

export const datesSchemaValidation = Yup.object().shape(
  {
    startDate: Yup.date()
      .required()
      .nullable()
      .transform((_, originalValue) =>
        isValid(originalValue) ? originalValue : null,
      )
      .required()
      .test(
        'startDate',
        "The End Date can't be more that 3 days apart from the Start Date",
        (value, context) => {
          const { endDate } = context.parent;
          if (!value || !endDate) return true;
          const differenceBetweenStartAndEnd = differenceInDays(endDate, value);
          if (differenceBetweenStartAndEnd > 2) {
            return false;
          } else return true;
        },
      ),
    endDate: Yup.date()
      .nullable()
      .transform((_, originalValue) =>
        isValid(originalValue) ? originalValue : null,
      )
      .required()
      .test(
        'endDate',
        'The end date must be after the start date',
        (value, context) => {
          const { startDate } = context.parent;
          if (!value || !startDate) return true;

          return isBefore(startDate, value) || isSameDay(value, startDate);
        },
      ),
    dueDate: Yup.date().when('dueDate', {
      is: (value: Date | undefined) => isValid(value),
      then: Yup.date().test(
        'dueDate',
        'The due date must be within the event date',
        (value, context) => {
          const { endDate } = context.parent;
          if (!value || !endDate) return true;
          return isBefore(value, endDate) || isSameDay(value, endDate);
        },
      ),
      otherwise: Yup.date()
        .nullable()
        .transform((_, originalValue) =>
          isValid(originalValue) ? originalValue : null,
        ),
    }),
    noVolunteerLimit: Yup.boolean(),
    // @mycatdoitbetter TODO: Change this value tu number when the Input component can receive a number
    volunteerNumber: volunteerNumberSchemaValidation,
    teamsNumber: Yup.number().test('eventApplicationType', (value, context) => {
      const { from } = context as TestContext & TestContextExtended;
      const [, parent] = from;

      const teamsNumber = value;

      if (parent.value.eventApplicationType === EEventApplicationType.Team) {
        if (teamsNumber) {
          return true;
        }
        return false;
      } else {
        return true;
      }
    }),
    teamsMaxSize: Yup.number().test(
      'eventApplicationType',
      (value, context) => {
        const { from } = context as TestContext & TestContextExtended;
        const [, parent] = from;

        const teamsMaxSize = value;

        if (parent.value.eventApplicationType === EEventApplicationType.Team) {
          if (teamsMaxSize) {
            return true;
          }
          return false;
        } else {
          return true;
        }
      },
    ),
    teamsMinSize: Yup.number().test(
      'eventApplicationType',
      (value, context) => {
        const { from } = context as TestContext & TestContextExtended;
        const [currentTree, parent] = from;

        const teamsMinSize = value;
        const teamsMaxSize = currentTree.value.teamsMaxSize;
        if (parent.value.eventApplicationType === EEventApplicationType.Team) {
          if (teamsMinSize && teamsMaxSize) {
            if (teamsMaxSize > teamsMinSize) {
              return true;
            } else {
              return false;
            }
          }
          return false;
        } else {
          return true;
        }
      },
    ),
  },
  [
    ['dueDate', 'dueDate'],
    ['endDate', 'endDate'],
  ],
);

export const fromHomeSchemaValidationEvent = Yup.object().shape({
  advertiseRegion: Yup.string().required(),
  meeting: Yup.string().optional(),
  dates: Yup.array().of(datesSchemaValidation),
});
export const fromHomeSchemaValidation = Yup.object().shape({
  advertiseRegion: Yup.string().required(),
  meeting: Yup.string().optional(),
});

export const makeCheckboxesFields = (
  allRegions: ActivityRegion[],
  activityRegions?: ActivityRegion[] | null,
) => {
  const allNationsFromAllRegions = allRegions?.filter(
    (regionItem) => regionItem.type === ActivityRegionType.Nation,
  );
  const nationsWithAllRegionsDictionary = createNationWithRegionsDictionary(
    allNationsFromAllRegions,
    allRegions,
  );

  const initialCheckboxFields = allNationsFromAllRegions.map((nation) => {
    const allNationRegions =
      nationsWithAllRegionsDictionary[nation.displayName];

    return {
      isAllChecked: false,
      isPartialSelected: false,
      mainRegionData: nation,
      childrenData: allNationRegions.map((region: ActivityRegion) => ({
        label: region.displayName,
        value: `${nation.displayName}, ${region.displayName}`,
        checked: false,
        data: region,
      })),
    };
  });

  if (!activityRegions) return initialCheckboxFields;

  const allNationsFromActivityRegions = activityRegions?.filter(
    (regionItem) => !regionItem.relatedTo,
  );

  const nationsWithActivityRegionsDictionary =
    createNationWithRegionsDictionary(
      allNationsFromActivityRegions,
      activityRegions,
    );

  const checkboxesFields: CheckboxesRegions[] = allNationsFromAllRegions.map(
    (nation) => {
      const activityNationsRegions =
        nationsWithActivityRegionsDictionary[nation.displayName];

      const allNationsRegions =
        nationsWithAllRegionsDictionary[nation.displayName];

      if (activityNationsRegions?.length > 0 && allNationsRegions.length > 0) {
        if (activityNationsRegions?.length === allNationsRegions.length) {
          return {
            isAllChecked: true,
            isPartialSelected: false,
            mainRegionData: nation,
            childrenData: allNationsRegions.map((region: ActivityRegion) => ({
              label: region.displayName,
              value: `${nation.displayName}, ${region.displayName}`,
              checked: true,
              data: region,
            })),
          };
        } else {
          const mapRegionsName = activityNationsRegions.map(
            (region) => region.displayName,
          );
          const activityRegionsSet = new Set(mapRegionsName);

          return {
            isAllChecked: false,
            isPartialSelected: true,
            mainRegionData: nation,
            childrenData: allNationsRegions.map((region: ActivityRegion) => ({
              label: region.displayName,
              value: `${nation.displayName}, ${region.displayName}`,
              checked: activityRegionsSet.has(region.displayName),
              data: region,
            })),
          };
        }
      }
      return {
        isAllChecked: false,
        isPartialSelected: false,
        mainRegionData: nation,
        childrenData: allNationsRegions.map((region: ActivityRegion) => ({
          label: region.displayName,
          value: `${nation.displayName}, ${region.displayName}`,
          checked: false,
          data: region,
        })),
      };
    },
  );

  return checkboxesFields;
};

export const formInitialDefaultValues = {
  meetingLink: '',
  dates: [
    {
      indexPosition: 0,
      placeFormIndex: 0,
      volunteerNumber: undefined,
      noVolunteerLimit: false,
      startDate: undefined,
      endDate: undefined,
      dueDate: undefined,
      teamsNumber: undefined,
      teamsMaxSize: undefined,
      teamsMinSize: undefined,
    },
  ],
};

export const getFormDefaultValues = (
  isTeamEvent?: boolean,
  defaultValues?: IEditActivityDTO,
) => {
  if (defaultValues) {
    return {
      ...defaultValues,
      advertiseRegion:
        defaultValues?.regions && defaultValues?.regions?.length > 0
          ? AdvertiseRegionOptionsEnum.SelectRegions
          : AdvertiseRegionOptionsEnum.AnywhereInUK,
    };
  } else {
    return {
      ...formInitialDefaultValues,
      eventApplicationType: isTeamEvent
        ? EEventApplicationType.Team
        : EEventApplicationType.Individual,
    };
  }
};
