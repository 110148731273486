import { PopulatedNetworkAppSharing } from '~/types/interfaces/networkAppSharing';

import { useQuery } from '../useQuery';

export const useLoadSharableAppsByOrganization = (organizationId?: string) => {
  const {
    data: appsConnectedToOrganization,
    isLoading: isLoadingAppsConnectedToOrganization,
    refetch: refetchAppsConnectedToOrganization,
  } = useQuery<PopulatedNetworkAppSharing[]>(
    `/network-app-sharing/organization/${organizationId}/sharable`,
    {
      queryOptions: {
        enabled: !!organizationId,
      },
      key: `all-sharable-network-app-sharing-${organizationId}`,
    },
  );

  return {
    appsConnectedToOrganization,
    isLoadingAppsConnectedToOrganization,
    refetchAppsConnectedToOrganization,
  };
};
