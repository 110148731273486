export enum ESliceNames {
  AUTH = 'auth',
  FORGOT_PASSWORD = 'forgotPassword',
  JOIN_AS_ADMIN = 'joinAsAdmin',
  JOIN_AS_PARTNER = 'joinAsPartner',
  ECOSYSTEM = 'ecosystem',
  ADD_MEMBER = 'addMember',
  REDIRECT = 'redirect',
  PERMISSIONS = 'permissions',
  NOTIFICATION = 'notification',
  CHANGE_ORGANIZATION = 'changeOrganization',
}
