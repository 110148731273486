import FeatherIcon from 'feather-icons-react';
import { FormControl } from 'native-base';
import { colors } from 'ui/theme/colors';

import { FormErrorProps } from './types';

const FormError = ({
  errorMessage,
  iconErrorMessage,
  iconErrorSize,
}: FormErrorProps) => {
  return (
    <>
      {errorMessage ? (
        <FormControl.ErrorMessage
          ml={2}
          leftIcon={
            iconErrorMessage ? (
              <FeatherIcon
                icon={iconErrorMessage}
                size={iconErrorSize}
                color={colors.error[400]}
              />
            ) : undefined
          }
        >
          {errorMessage}
        </FormControl.ErrorMessage>
      ) : null}
    </>
  );
};

export default FormError;
