import { Box, Divider, HStack, Stack, Text } from 'native-base';
import Button from 'ui/components/Button';
import Icon from 'ui/components/Icon';
import ReadMore from 'ui/components/ReadMore';

import { AppsAccessCard } from '~/components/AppsAccessCard';
import { colors } from '~/theme/colors';

import { useChildOrgAccessController } from './controller';
import { styles } from './styles';

export const ChildOrgAccess = () => {
  const { childOrgPermissions } = useChildOrgAccessController();
  return (
    <Stack
      bgColor="white"
      borderRadius="3xl"
      width="100%"
      space={3}
      padding={6}
    >
      <HStack alignItems="center" justifyContent="space-between">
        <Stack space={2}>
          <Text fontSize="lg" fontWeight={500} color="gray.600">
            Access
          </Text>
          <Text fontSize="sm" fontWeight={400} color="gray.600">
            View and manage the apps this member can access
          </Text>
        </Stack>
        <Button
          leftIconName="sliders"
          leftIconColor={colors.primary[400]}
          maxWidth="168px"
          width="100%"
          variant="outline"
          borderColor="primary.400"
        >
          Manage access
        </Button>
      </HStack>
      <Divider />

      <HStack
        mt={6}
        space={6}
        width="100%"
        flexWrap="wrap"
        style={styles.appsCard}
      >
        <Box width="23%">
          <AppsAccessCard />
        </Box>
        <Box width="23%">
          <AppsAccessCard />
        </Box>
        <Box width="23%">
          <AppsAccessCard />
        </Box>
        <Box width="23%">
          <AppsAccessCard />
        </Box>
      </HStack>

      <HStack mt={10} alignItems="center" justifyContent="space-between">
        <Stack space={2}>
          <Text fontSize="lg" fontWeight={500} color="gray.600">
            Permissions
          </Text>
          <Text fontSize="sm" fontWeight={400} color="gray.600">
            View and manage permissions
          </Text>
        </Stack>
        <Button
          leftIconName="edit"
          leftIconColor={colors.primary[400]}
          maxWidth="168px"
          width="100%"
          variant="outline"
          borderColor="primary.400"
        >
          Edit permissions
        </Button>
      </HStack>

      <Divider />

      <Stack
        width="50%"
        bgColor="gray.50"
        borderRadius="xl"
        padding={4}
        space={6}
        mt={4}
        borderWidth="1px"
        borderColor="gray.200"
      >
        <Stack space={2}>
          <Text fontSize="md" fontWeight={500} color="darkText">
            Child Org Admin
          </Text>
          <Text fontSize="xs" fontWeight={400} color="gray.600">
            Applies to child org administrators{' '}
          </Text>
        </Stack>

        <ReadMore
          textHeight="230px"
          btnBgColor="gray.50"
          btnTextColor="darkText"
          rightIconColor="darkText"
          text={
            <div id="membersDiv">
              <Stack space={6}>
                {childOrgPermissions?.map((m) => (
                  <Stack space={2} key={m.title}>
                    <Text fontSize="sm" fontWeight={500} color="gray.600">
                      {m.title}
                    </Text>

                    <Stack space={3} mt={3}>
                      {m.accesses?.map((a) => (
                        <HStack space={2} key={a}>
                          <Icon
                            icon="check"
                            size={20}
                            color={colors.success[500]}
                          />
                          <Text fontSize="xs" fontWeight={400} color="gray.600">
                            {a}
                          </Text>
                        </HStack>
                      ))}
                    </Stack>
                  </Stack>
                ))}
              </Stack>
            </div>
          }
        />
      </Stack>
    </Stack>
  );
};
