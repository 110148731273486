import { HStack, Stack, Switch, Text } from 'native-base';
import { Controller } from 'react-hook-form';

import { colors } from '../../theme/colors';
import { CustomSwitchProps } from './types';

export const CustomSwitch = ({
  name,
  title,
  control,
  description,
  titleColor = colors.singletons.darkText,
  offTrackColor = 'gray.400',
  offThumbColor = 'gray.100',
  onTrackColor = 'primary.100',
  onThumbColor = 'primary.400',
}: CustomSwitchProps) => {
  return (
    <Stack space={2} alignItems="center">
      <HStack alignItems="center" justifyContent="space-between" width="100%">
        <Text fontWeight={500} fontSize="md" color={titleColor}>
          {title}
        </Text>
        <Controller
          name={name}
          control={control}
          render={({ field: { onChange, value } }) => {
            return (
              <Switch
                isChecked={value}
                value={value}
                offTrackColor={offTrackColor}
                offThumbColor={offThumbColor}
                onTrackColor={onTrackColor}
                onThumbColor={onThumbColor}
                onToggle={onChange}
                onChange={onChange}
              />
            );
          }}
        />
      </HStack>
      {description ? (
        <Stack width="100%">
          <Text fontSize="sm" fontWeight={400} color="gray.500">
            {description}
          </Text>
        </Stack>
      ) : null}
    </Stack>
  );
};
