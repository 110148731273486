import { Flex, Hidden, HStack, Stack } from 'native-base';
import { InviteCompose } from 'ui/components/InviteCompose';
import { RedirectPage } from 'ui/components/RedirectPage';
import { PoweredByDoit } from 'ui/components/v2/Labels/PoweredByDoit';

import { Loading } from '~/components/Loading';
import { ChosenEcosystem } from '~/pages/Common/JoinAsPartner/ChosenEcosystem';
import { CreatingConnection } from '~/pages/Common/JoinAsPartner/components/CreatingConnection';
import { JoinAsPartnerHome } from '~/pages/Common/JoinAsPartner/JoinAsPartnerHome';
import { JoinAsPartnerSuccess } from '~/pages/Common/JoinAsPartner/JoinAsPartnerSuccess';
import { colors } from '~/theme/colors';

import { useJoinAsPartnerController } from './controller';

export const JoinAsPartner = () => {
  const {
    currentTab,
    control,
    inviteCode,
    isLoading,
    isSubmitting,
    isValid,
    organization,
    usedInviteCode,
    ecosystemOptions,
    errors,
    inviteEcosystem,
    isAuthenticated,
    goToHomePage,
    handleDeclineInvitation,
    onSubmit,
    handleNextTab,
  } = useJoinAsPartnerController();

  if (!isAuthenticated) return null;

  if (isLoading) {
    return <Loading />;
  }

  if (!inviteCode) {
    return (
      <RedirectPage
        title="Invite not found"
        text="We couldn't find your invitation; please contact the partner organization for assistance."
        onButtonPress={goToHomePage}
        buttonColor={colors.gray[900]}
      />
    );
  }

  if (usedInviteCode) {
    return (
      <RedirectPage
        text={'You already are partners'}
        onButtonPress={goToHomePage}
        buttonText="Go to home page"
        buttonWidth="100%"
        textWidth="100%"
        buttonColor={colors.gray[900]}
      />
    );
  }

  if (isSubmitting && currentTab === 1) {
    return (
      <CreatingConnection
        inviteLogo={inviteCode?.composeSettings?.logo}
        brandColor={inviteCode?.composeSettings?.brandColor}
      />
    );
  }

  const tabComponents: Record<number, React.ReactNode> = {
    0: (
      <JoinAsPartnerHome
        control={control}
        handleDeclineInvitation={handleDeclineInvitation}
        isSubmitting={isSubmitting}
        isValid={isValid}
        onSubmit={handleNextTab}
        organization={organization}
      />
    ),
    1: (
      <ChosenEcosystem
        control={control}
        ecosystemOptions={ecosystemOptions}
        errors={errors}
        isSubmitting={isSubmitting}
        isValid={isValid}
        ecosystemName={inviteEcosystem?.name || ''}
        onSubmit={onSubmit}
      />
    ),
    2: (
      <JoinAsPartnerSuccess
        handleGoToHome={goToHomePage}
        ecosystemName={inviteEcosystem?.name || ''}
      />
    ),
  };

  return (
    <>
      <Flex
        minH="100vh"
        height="100%"
        width="100%"
        flexDir={{ base: 'column', lg: 'row' }}
      >
        <Stack height="100vh" width={{ base: '100%', lg: '55%' }}>
          <InviteCompose
            title={inviteCode?.composeSettings?.title || ''}
            message={inviteCode?.composeSettings?.message || ''}
            brandColor={inviteCode.composeSettings?.brandColor}
            organizationLogo={organization?.logo || ''}
            organizationName={organization?.name || ''}
            logo={inviteCode?.composeSettings?.logo}
            backgroundImage={inviteCode?.composeSettings?.backgroundImage}
          />
        </Stack>
        <Stack
          width={{ base: '100%', lg: '45%' }}
          height={{ base: '55%', lg: '100vh' }}
          position={{ base: 'absolute', lg: 'relative' }}
          justifyContent={{ base: 'initial', lg: 'center' }}
          paddingLeft={{ base: 5, lg: '6.3rem' }}
          paddingRight={{ base: 5, lg: '6.3rem' }}
          padding={{ base: 5, lg: 0 }}
          bgColor={colors.singletons.lightText}
          borderTopRadius={60}
          bottom={0}
        >
          {tabComponents[currentTab]}
          <Hidden till="lg">
            <HStack
              position="absolute"
              alignItems="center"
              bottom={0}
              right={0}
              padding={12}
            >
              <PoweredByDoit />
            </HStack>
          </Hidden>
        </Stack>
      </Flex>
    </>
  );
};
