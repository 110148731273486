import { INPUT_COMPONENT_TYPES } from 'ui/components/FormBuilderV2';
import { IOptionsV2 } from 'ui/components/FormBuilderV2/types';
import { EEventApplicationType } from 'ui/enums';

import {
  ActivityPublishedAppsSection,
  ActivityRadioAppSection,
} from '~/components/ActivityFormBuilderPresets/InputSections';
import {
  eventApprovalRequiredOptions,
  spaceOptions,
} from '~/constants/forms.constants';
import { EventCategoryOptions, EventConfigurationOptions } from '~/enums';
import { colors } from '~/theme/colors';

import { ICreateFormSchemaProps } from './types';

export const eventConfigDictionary = {
  [EEventApplicationType.Individual]: 'Individual',
  [EEventApplicationType.Team]: 'Team',
};

export const eventCategoryDictionary = {
  [EventCategoryOptions.Volunteering]: 'Volunteering',
};

export const eventApplicationOptions = [
  {
    text: eventConfigDictionary[EEventApplicationType.Individual],
    value: EEventApplicationType.Individual,
  },
  {
    text: eventConfigDictionary[EEventApplicationType.Team],
    value: EEventApplicationType.Team,
  },
];

export const eventCategoryOptions = [
  {
    label: 'Volunteering',
    value: 'volunteering',
  },
];

export const createFormSchema = ({
  causeOptions,
  requirementOptions,
  connectedApps,
  commonController,
  handleBack,
}: ICreateFormSchemaProps): IOptionsV2 => ({
  inputsSections: [
    ActivityRadioAppSection({
      appsList: connectedApps,
      helpLink: commonController.appsHelpLink,
      isDisabled: commonController.isHostAppDisabled,
      onChangeValue: commonController.onChangeApp,
    }),

    ActivityPublishedAppsSection({
      appsList: connectedApps,
      disabledValues: commonController.disabledAppIds,
      helpLink: commonController.appsHelpLink,
    }),
    {
      key: 'main-section',
      sectionTitle: 'Basic information',
      sectionSubtitle: 'Basic information about the event',
      sectionInputRows: [
        {
          key: 'title-row',
          inputs: [
            {
              id: 'title',
              name: 'title',
              isRequired: true,
              component: INPUT_COMPONENT_TYPES.INPUT,
              inputProps: {
                label: 'Title',
                placeholder: 'Type at least one cause',
              },
            },
          ],
        },
        {
          key: 'category-subcategory-row',
          inputs: [
            {
              id: 'category',
              name: 'category',
              isRequired: true,
              component: INPUT_COMPONENT_TYPES.SELECT,
              inputProps: {
                label: 'Category',
                isMulti: false,
                placeholder: 'Select the event category',
                options: eventCategoryOptions,
                defaultValue: eventCategoryOptions[0].value,
              },
            },
            {
              id: 'cause',
              name: 'cause',
              isRequired: true,
              component: INPUT_COMPONENT_TYPES.SELECT,
              inputProps: {
                label: 'Cause',
                isMulti: true,
                placeholder: 'Select at least one cause',
                options: causeOptions,
              },
            },
          ],
        },
        {
          key: 'description-row',
          inputs: [
            {
              id: 'description',
              name: 'description',
              component: INPUT_COMPONENT_TYPES.TEXT_AREA,
              inputProps: {
                h: 194,
                label: 'Description',
                placeholder: 'About the event',
              },
            },
          ],
        },
        {
          key: 'event-application-type-row',
          inputs: [
            {
              id: 'eventApplicationType',
              name: 'eventApplicationType',
              isRequired: true,
              component: INPUT_COMPONENT_TYPES.RADIO,
              inputProps: {
                label: 'Event configuration',
                checkedColor: colors.primary[600],
                flexDirection: 'row',
                options: eventApplicationOptions,
                isDisabled: true,
              },
            },
          ],
        },
        {
          key: 'event-application-approval',
          inputs: [
            {
              id: 'eventApprovalRequired',
              name: 'eventApprovalRequired',
              isRequired: true,
              component: INPUT_COMPONENT_TYPES.RADIO,
              inputProps: {
                label: 'Application approval required',
                checkedColor: colors.primary[600],
                flexDirection: 'row',
                options: eventApprovalRequiredOptions,
              },
            },
          ],
        },
        {
          key: 'event-space-options',
          inputs: [
            {
              id: 'spaceOptions',
              name: 'spaceOptions',
              isRequired: true,
              component: INPUT_COMPONENT_TYPES.RADIO,
              inputProps: {
                label: 'Space options',
                checkedColor: colors.primary[600],
                flexDirection: 'row',
                options: spaceOptions,
              },
            },
          ],
        },
      ],
    },
    {
      sectionTitle: 'Details',
      sectionSubtitle:
        'Tell participants more details on the event. This is optional, but we encourage you to fill out as many details as possible.',
      key: 'details-section',
      sectionInputRows: [
        {
          key: 'requirements-input-row',
          inputs: [
            {
              id: 'requirementOptions',
              name: 'requirementOptions',
              component: INPUT_COMPONENT_TYPES.SELECT,
              isRequired: true,
              inputProps: {
                label: 'Requirements',
                isMulti: true,
                placeholder: 'Select as many requirements as needed',
                options: requirementOptions,
              },
            },
          ],
        },
        {
          key: 'participant-requirements-input-row',
          inputs: [
            {
              id: 'volunteerRequirements',
              name: 'volunteerRequirements',
              component: INPUT_COMPONENT_TYPES.DYNAMIC_INPUT,
              maxLength: 58,
              inputProps: {
                label: 'What do participants need to bring?',
                helperText: 'Limit of 58 characters.',
                tagIconColor: colors.primary[600],
                placeholder:
                  'Add activities participants should do or things they should bring',
              },
            },
          ],
        },
        {
          key: 'participant-rewards-input-row',
          inputs: [
            {
              id: 'volunteerRewards',
              name: 'volunteerRewards',
              component: INPUT_COMPONENT_TYPES.DYNAMIC_INPUT,
              maxLength: 58,
              inputProps: {
                label: 'What will participants receive?',
                tagIconColor: colors.primary[600],
                helperText: 'Limit of 58 characters.',
                placeholder:
                  'Rewards or favorable conditions that will have in the event',
              },
            },
          ],
        },
      ],
    },
  ],

  actions: {
    options: {
      alignItems: 'center',
      justifyContent: 'space-around',
      spacingBetweenItems: 2,
    },
    items: [
      {
        id: 'reset',
        name: 'back',
        text: 'Back',
        buttonProps: {
          variant: 'ghost',
          flex: 1,
        },
        onClick: handleBack,
        textProps: { color: 'primary.400', w: '100%' },
      },
      {
        id: 'submit',
        name: 'submit',
        isSubmitButton: true,
        text: 'Continue',
        buttonProps: {
          flex: 1,
        },
        textProps: { color: 'white' },
      },
    ],
  },
});
