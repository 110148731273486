import { useMemo } from 'react';
import { UseFormGetValues } from 'react-hook-form';
import { groupPermissionsByCategory } from 'ui/utils/groupPermissionByCategory';

import { AddPermissionGroupFormValues } from '~/pages/Authenticated/Settings/Permissions/AddPermissionsGroup/types';

export const useReviewSectionController = (
  getValues: UseFormGetValues<AddPermissionGroupFormValues>,
) => {
  const selectedPermissions = getValues('groupedPermissions');

  const permissionsChosen = useMemo(() => {
    const currentPermissions = [];
    for (const selectedPermission of selectedPermissions) {
      if (selectedPermission?.permissions) {
        for (const configuration of selectedPermission.configurations) {
          const findPermission = selectedPermission.permissions.find(
            (p) => p.permissionTemplateId === configuration.templateId,
          );
          if (findPermission) {
            currentPermissions.push(findPermission);
          }
        }
      }
    }
    return currentPermissions;
  }, [selectedPermissions]);

  const groupedPermissions = groupPermissionsByCategory(permissionsChosen);

  return { groupedPermissions };
};
