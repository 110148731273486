import {
  AnyAction,
  Dispatch,
  Middleware,
  MiddlewareAPI,
} from '@reduxjs/toolkit';

import { PAGES } from '~/constants/pages.constants';
import { RootState } from '~/store';
import { OrgActionGuardMiddlewareEnum } from '~/store/common/actions/enums';
import { RedirectSlice } from '~/store/slices/redirect';

export const OrgActionGuardMiddleware: Middleware =
  (storeAPI: MiddlewareAPI<Dispatch<AnyAction | any>, RootState>) =>
  (next: Dispatch<AnyAction>) =>
  async (action: AnyAction) => {
    const result = next(action);
    if (Object.values(OrgActionGuardMiddlewareEnum).includes(action.type)) {
      const state = storeAPI.getState();
      const { organizations } = state.auth;

      /* NOTE: IF USER DOESNT HAVE ANY ORGANIZATION NO REDIRECT MUST BE MADE */
      if (!organizations.length) return;

      let redirectUrl = String(PAGES.CHANGE_ORG);
      if (organizations?.length === 1) {
        redirectUrl = `${PAGES.CHANGE_ORG}?organization=${organizations[0]._id}`;
      }
      storeAPI.dispatch(RedirectSlice.actions.updateURL(redirectUrl));
    }

    return result;
  };
