import { PAGES } from '~/constants/pages.constants';
import { useAppSelector } from '~/hooks/useAppSelector';
import { useRouter } from '~/hooks/useRouter';

export const useJoinAsAdminInviteDeclinedController = () => {
  const { isAuthenticated } = useAppSelector(({ auth }) => auth);
  const replaceRoutePath = isAuthenticated ? PAGES.ROOT : PAGES.SIGNIN;

  const { replaceRoute } = useRouter();

  const goToHome = () => replaceRoute(replaceRoutePath);

  return { goToHome };
};
