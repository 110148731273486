import { Stack, Text } from 'native-base';
import Button from 'ui/components/Button';
import Select from 'ui/components/Select';

import { ChosenEcosystemProps } from '~/pages/Common/JoinAsPartner/types';
import { colors } from '~/theme/colors';

export const ChosenEcosystem = ({
  control,
  ecosystemOptions,
  errors,
  isSubmitting,
  isValid,
  ecosystemName,
  onSubmit,
}: ChosenEcosystemProps) => {
  return (
    <Stack
      width="100%"
      height="100%"
      justifyContent="center"
      space={{ base: 0, lg: 6 }}
      bgColor={colors.singletons.lightText}
      alignItems={{ base: 'center', lg: 'initial' }}
    >
      <Stack
        space={3}
        width="100%"
        maxW={{ base: '100%', lg: '500px' }}
        alignItems={{ base: 'center', lg: 'initial' }}
      >
        <Text
          fontWeight={500}
          fontSize={{ base: 'lg', lg: '2xl' }}
          textAlign="left"
          color="gray.900"
          mb={2}
        >
          Choose ecosystem
        </Text>

        <Text
          fontWeight={400}
          fontSize={{ base: 'xs', lg: 'lg' }}
          textAlign="left"
          color="gray.900"
          width="100%"
        >
          Which ecosystem you want to connect to{' '}
          <Text fontWeight={500}>“{ecosystemName}”?</Text>
        </Text>
      </Stack>
      <Stack
        width="100%"
        maxW={{ base: '100%', lg: '500px' }}
        mt={{ base: 4, lg: 0 }}
      >
        <Select
          control={control}
          name="ecosystem"
          label="Ecosystem"
          options={ecosystemOptions}
          errorMessage={errors?.ecosystem?.message}
        />
      </Stack>

      <Stack
        space={3}
        mt={{ base: 20, lg: 8 }}
        width="100%"
        maxW={{ base: '100%', lg: '500px' }}
      >
        <Button
          isLoading={isSubmitting}
          isDisabled={!isValid || isSubmitting}
          variant="solid"
          onPress={onSubmit}
          bgColor="gray.900"
          padding={6}
          _text={{ fontWeight: 500 }}
        >
          Connect and enter ecosystem
        </Button>
      </Stack>
    </Stack>
  );
};
