import TeamMemberCard from '../TeamMemberCardBase';
import { TeamMemberStatusMap } from './constants';
import { TeamMemberCardProps } from './types';

export const TeamCandidateMemberCard = (props: TeamMemberCardProps) => {
  const { name, email, status, onDeleteMember, showDeleteMemberButton } = props;
  const statusConfig = TeamMemberStatusMap[status];

  return (
    <TeamMemberCard
      title={email || name || 'Unnamed'}
      description={statusConfig.label}
      descriptionColor={statusConfig.color}
      showAvatar={false}
      showDeleteMemberButton={showDeleteMemberButton}
      onDeleteMember={onDeleteMember}
    />
  );
};

export default TeamCandidateMemberCard;
