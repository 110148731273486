import { yupResolver } from '@hookform/resolvers/yup';
import { useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';

import { COMMON_MESSAGES } from '~/constants/messages.constants';
import { useAppDispatch } from '~/hooks/useAppDispatch';
import { useAppSelector } from '~/hooks/useAppSelector';
import AuthService from '~/services/resources/auth';
import { forgotPasswordSliceActions } from '~/store/slices/forgotPassword';
import { IAccessCode } from '~/types/dtos';

import { PAGES } from '../../../../constants/pages.constants';
import { useRouter } from '../../../../hooks/useRouter';
import { verifyCodeSchemaValidation } from './utils';

export const useVerifyCodeController = () => {
  const { goToRoute, location, replaceRoute } = useRouter();
  //States
  const { email } = useAppSelector(({ forgotPassword }) => forgotPassword);
  const isUserUnverified = location?.state?.isUserUnverified;

  //Hooks

  const dispatch = useAppDispatch();

  const {
    control,
    handleSubmit,
    formState: { errors, isValid, isDirty, isSubmitting },
  } = useForm<IAccessCode>({
    resolver: yupResolver(verifyCodeSchemaValidation),
  });

  //Memos
  const isDisabled = useMemo(
    () => !isValid || !isDirty || isSubmitting,
    [isValid, isDirty, isSubmitting],
  );

  //Functions
  const hadleResendCode = async () => {
    try {
      if (!email) return;

      if (isUserUnverified) {
        await AuthService.resendConfirmationCode({ email });
        toast.success(COMMON_MESSAGES.ACCESS_CODE_SENT);
      } else {
        await AuthService.requestForgotPassword({ email });
        toast.success(COMMON_MESSAGES.ACCESS_CODE_SENT);
      }
    } catch (error) {
      toast.error(COMMON_MESSAGES.UNABLE_SEND_ACCESS_CODE);
    }
  };

  const onVerifyCode = handleSubmit(async ({ accessCode }) => {
    if (isUserUnverified && email) {
      await AuthService.confirmSignUp({
        email,
        accessCode,
        password: '',
      });

      goToRoute(PAGES.FORGOT_PASSWORD, {
        state: {
          email,
        },
      });
      toast.info('Account verified. You can now reset your password.');
    }

    dispatch(forgotPasswordSliceActions.update({ accessCode }));
    dispatch(forgotPasswordSliceActions.nextStep());
  });

  return {
    control,
    errors,
    email,
    isSubmitting,
    hadleResendCode,
    onVerifyCode,
    isDisabled,
  };
};
