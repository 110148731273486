import { yupResolver } from '@hookform/resolvers/yup';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';

import { EMAIL_PREFERENCES } from '~/constants/messages.constants';
import { useAppSelector } from '~/hooks/useAppSelector';
import { useQuery } from '~/hooks/useQuery';
import AdminProfileService from '~/services/resources/adminProfile';
import { AdminProfile } from '~/types/interfaces/admin-profile';

import { schema } from './constants';

export const useEmailPreferencesController = () => {
  const { organizationSelectedId } = useAppSelector(({ auth }) => auth);

  const { data: adminProfile, isLoading: isLoadingAdminProfile } =
    useQuery<AdminProfile>(
      `admin-profile/organization/${organizationSelectedId}`,
      {
        key: `fetch-admin-profile-${organizationSelectedId}`,
        queryOptions: {
          enabled: !!organizationSelectedId,
        },
      },
    );

  const {
    control,
    handleSubmit,
    formState: { isSubmitting },
    reset,
  } = useForm({
    resolver: yupResolver(schema),
  });

  const handleSaveEmailPreferences = handleSubmit(async (data) => {
    try {
      if (!organizationSelectedId) return;

      if (adminProfile?._id) {
        await AdminProfileService.updateAdminProfile(adminProfile._id, {
          emailPreferences: data,
          organization: organizationSelectedId,
        });
      } else {
        await AdminProfileService.createAdminProfile({
          emailPreferences: data,
          organization: organizationSelectedId,
        });
      }
      toast.success(EMAIL_PREFERENCES.SAVE_SUCCESS);
    } catch (error) {
      toast.error(EMAIL_PREFERENCES.ERROR_ON_SAVE);
    }
  });

  useEffect(() => {
    if (adminProfile) {
      reset({
        dailyDigest: !!adminProfile?.emailPreferences?.dailyDigest,
        weeklyDigest: !!adminProfile?.emailPreferences?.weeklyDigest,
      });
    }
  }, [adminProfile, reset]);

  return {
    control,
    isLoadingAdminProfile,
    isSubmitting,
    handleSaveEmailPreferences,
  };
};
