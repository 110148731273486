import { Box, HStack, Spinner, Stack, VStack } from 'native-base';
import React from 'react';
import FormHeader from 'ui/components/FormBuilder/components/FormHeader';

import { EcosystemsList } from './EcosystemsList';
import { IAccessComponentProps } from './types';

// Definindo valor para seleção de todos os checkboxes
export const CHECKBOX_SELECT_ALL_VALUE = 'all';

export const AccessFormSection = ({
  appsConnectedToOrganization,
  ecosystems,
  isLoading,
  control,
  memberType,
  setValue,
}: IAccessComponentProps) => {
  if (isLoading) {
    return (
      <HStack h="30vh" alignItems="center" justifyContent="center">
        <Spinner size="sm" />
      </HStack>
    );
  }

  return (
    <Stack justifyContent="center" w="100%" alignItems="center" p={6}>
      <VStack w="620px">
        <FormHeader
          title="Access"
          subTitle="Select which apps and ecosystems you want to grant access to"
        />
        <Box h="8" />
        <EcosystemsList
          memberType={memberType}
          control={control}
          ecosystems={ecosystems}
          appsConnectedToOrganization={appsConnectedToOrganization}
          setValue={setValue}
        />
      </VStack>
    </Stack>
  );
};
