import { HStack, Pressable, Text } from 'native-base';
import { ComponentProps, useState } from 'react';

import { colors } from '~/theme/colors';

type HStackProps = ComponentProps<typeof HStack>;

export type InsightTabOption = {
  label?: string;
  value: string;
};

type InsightTabsProps = {
  options: InsightTabOption[];
  defaultValue?: string;
  onChange: (option: InsightTabOption) => void;
} & Pick<HStackProps, 'minW' | 'maxW' | 'w' | 'mx' | 'flex' | 'mb'>;

export const InsightTabs = ({
  onChange,
  options,
  defaultValue,
  ...hstackProps
}: InsightTabsProps) => {
  const [selectedValue, setSelectedValue] = useState(
    defaultValue ?? options?.[0].value,
  );

  const handleSelectOption = (option: InsightTabOption) => () => {
    setSelectedValue(option.value);
    onChange(option);
  };

  return (
    <HStack
      space={2}
      p={1}
      borderWidth={1}
      borderColor={'#F2F4F7'}
      bg={colors.singletons.mediumWhite}
      borderRadius={8}
      width={'full'}
      h={'50px'}
      maxH={'50px'}
      {...hstackProps}
    >
      {options.map((option) => (
        <Pressable
          shadow={selectedValue === option.value ? 1 : undefined}
          key={option.value}
          onPress={handleSelectOption(option)}
          backgroundColor={selectedValue === option.value ? 'white' : undefined}
          p={2}
          px={4}
          borderRadius={8}
          minW={'120px'}
          flex={1}
          flexDirection={'row'}
          justifyContent={'center'}
          alignItems={'center'}
        >
          <Text
            color={
              selectedValue === option.value
                ? 'rgba(21, 21, 24, 1)'
                : 'gray.500'
            }
            fontSize="sm"
            fontWeight={selectedValue === option.value ? 500 : 400}
            textAlign="center"
          >
            {option.label ?? option.value}
          </Text>
        </Pressable>
      ))}
    </HStack>
  );
};
