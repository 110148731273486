import { Box, HStack, Image, Skeleton, Stack, Text, VStack } from 'native-base';
import placeholderImage from 'ui/assets/svgs/placeholder.svg';
import Icon from 'ui/components/Icon';
import { ActivityTypeBadge } from 'ui/components/v2/Badges/ActivityTypeBadge';
import { colors } from 'ui/theme/colors';

import { useActivityDefinitionTileController } from './controller';
import { ActivityDefinitionTileProps } from './types';

export const ActivityDefinitionTile = (props: ActivityDefinitionTileProps) => {
  const {
    title,
    coverImage,
    isFromHome,
    semanticType,
    organizationName,
    formattedTargetAmount,
  } = useActivityDefinitionTileController(props);

  return (
    <HStack
      width={'full'}
      flex={1}
      p={3}
      backgroundColor={'gray.50'}
      borderRadius={8}
      space={3}
      overflow={'hidden'}
    >
      {/* Leading Image */}
      <Box w="24" h="24" overflow="hidden" borderRadius={8}>
        <Image
          w="full"
          h="full"
          resizeMode="cover"
          source={{ uri: coverImage || '' }}
          fallbackSource={{ uri: placeholderImage }}
        />

        <Stack width="100%" height="100%">
          <Skeleton width="100%" height="100%" />
        </Stack>
      </Box>

      <VStack flex={1} width={'full'} space={2}>
        {/* Header */}
        <HStack space={3} justifyContent={'space-between'}>
          <ActivityTypeBadge activityType={semanticType} defaultSize="sm" />

          {!!isFromHome && (
            <HStack space={1} alignItems={'center'}>
              <Icon icon="home" size={16} color={colors.gray[400]} />
              <Text
                fontSize={'.65rem'}
                fontWeight={400}
                color={colors.gray[600]}
              >
                From home
              </Text>
            </HStack>
          )}
        </HStack>

        {/* Body */}
        <VStack>
          <Text
            fontSize={'sm'}
            fontWeight={500}
            color={colors.darkText}
            noOfLines={1}
          >
            {title}
          </Text>

          <Text
            fontSize={'xs'}
            fontWeight={400}
            color={colors.gray[600]}
            noOfLines={1}
          >
            by {organizationName}
          </Text>
        </VStack>

        {/* Footer */}
        <HStack alignItems={'center'} space={1}>
          {!!formattedTargetAmount && (
            <>
              <Icon
                enableAutoCustom
                icon="target_and_arrow"
                size={'16px'}
                color={colors.gray[400]}
              />
              <Text
                fontSize={'xs'}
                fontWeight={500}
                color={colors.gray[600]}
                noOfLines={1}
              >
                {formattedTargetAmount}
              </Text>
            </>
          )}
        </HStack>
      </VStack>
    </HStack>
  );
};
