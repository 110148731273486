import { useRef } from 'react';
import { IModalRefProps } from 'ui/components/Modals/Modal/types';

export const useActivityMetricsController = () => {
  const modalRef = useRef<IModalRefProps>(null);

  const modalInfoRef = useRef<IModalRefProps>(null);

  const handleOpenModal = () => {
    modalRef.current?.open();
  };

  const handleCloseModal = () => {
    modalRef.current?.close();
  };

  const handleOpenModalInformation = () => {
    modalInfoRef.current?.open();
  };

  return {
    handleOpenModal,
    handleCloseModal,
    handleOpenModalInformation,
    modalRef,
    modalInfoRef,
  };
};
