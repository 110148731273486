import {
  TableOperatorNames,
  TableOperatorType,
} from 'ui/components/DataTable/types';

export const defaultFiltersValues = [
  {
    name: 'receiverEmail',
    operator: TableOperatorNames.Contains,
    type: TableOperatorType.String,
    value: '',
  },
  {
    name: 'createdAt',
    operator: TableOperatorNames.AfterOrOn,
    type: TableOperatorType.Date,
    value: '',
  },
];
