import FeatherIcon from 'feather-icons-react';
import {
  Box,
  Flex,
  FormControl,
  HStack,
  Input as NBInput,
  Link,
} from 'native-base';
import { useCallback } from 'react';
import { Controller } from 'react-hook-form';
import { colors } from 'ui/theme/colors';

import { validateSlug } from '../../utils/validations/validations';
import { UrlInputProps } from './types';
import { useUrlInputController } from './useUrlInputController';

const InputUrl = ({ isFullWidth, ...rest }: UrlInputProps) => {
  const {
    leftIconName,
    leftIconColor,
    leftIconSize,
    _formControl,
    rightIconName,
    control,
    name,
    leftIconErrorSize,
    rightIconColor,
    helperText,
    rightIconSize,
    currentType,
    hasErrorOrErrorMessage,
    isInvalid,
    isDisabled,
    isReadOnly,
    isRequired,
    errorMessage,
    iconErrorMessage,
    isPasswordField,
    isShowPasswordToggle,
    label,
    restProps,
    hasError,
    toggleShowPasswordType,
  } = useUrlInputController(rest);

  const getInputRightElement = useCallback(() => {
    if (isPasswordField && isShowPasswordToggle) {
      return (
        <Link mr={2} variant="unstyled" onPress={toggleShowPasswordType}>
          <FeatherIcon
            icon={currentType === 'password' ? 'eye' : 'eye-off'}
            size={rightIconSize}
            color={hasErrorOrErrorMessage ? colors.error[700] : rightIconColor}
          />
        </Link>
      );
    }

    if (rightIconName) {
      return (
        <Box mr={2}>
          <FeatherIcon
            icon={rightIconName}
            size={rightIconSize}
            color={hasErrorOrErrorMessage ? colors.error[700] : rightIconColor}
          />
        </Box>
      );
    }
  }, [
    currentType,
    hasErrorOrErrorMessage,
    isPasswordField,
    isShowPasswordToggle,
    rightIconColor,
    rightIconName,
    rightIconSize,
    toggleShowPasswordType,
  ]);

  return (
    <Box alignItems="center" w={isFullWidth ? 'full' : undefined}>
      <FormControl
        isInvalid={hasErrorOrErrorMessage ? true : isInvalid}
        isDisabled={isDisabled}
        isRequired={isRequired}
        isReadOnly={isReadOnly}
        {..._formControl}
      >
        {label ? <FormControl.Label>{label}</FormControl.Label> : null}
        <Controller
          control={control}
          name={name}
          defaultValue=""
          render={({ field: { onChange, value, ...restField } }) => (
            <HStack borderWidth="1" rounded="xl" borderColor="muted.200">
              <Flex
                bg="gray.200"
                alignItems="center"
                justifyContent="center"
                rounded="xl"
                borderRightWidth="0"
                borderRightRadius="0"
                px="2"
              >
                {/* TODO: Maybe change to environment variable. Pretty sure this isn't going to be the url */}
                doit.life/
              </Flex>
              <NBInput
                {...restProps}
                {...restField}
                value={value}
                onChangeText={(newValue) => onChange(validateSlug(newValue))}
                flex="1"
                type={currentType}
                backgroundColor="white"
                borderWidth="1"
                borderRadius="0"
                borderTopRightRadius="xl"
                borderBottomRightRadius="xl"
                borderColor={hasErrorOrErrorMessage ? 'error.400' : 'muted.200'}
                InputRightElement={getInputRightElement()}
                InputLeftElement={
                  leftIconName ? (
                    <Box ml={2} variant="unstyled">
                      <FeatherIcon
                        icon={leftIconName}
                        size={leftIconSize}
                        color={
                          hasErrorOrErrorMessage
                            ? colors.error[700]
                            : leftIconColor
                        }
                      />
                    </Box>
                  ) : undefined
                }
              />
            </HStack>
          )}
        />
        {!hasError && !errorMessage && helperText ? (
          <FormControl.HelperText ml={2}>{helperText}</FormControl.HelperText>
        ) : null}
        {errorMessage ? (
          <FormControl.ErrorMessage
            ml={2}
            leftIcon={
              iconErrorMessage ? (
                <FeatherIcon
                  icon={iconErrorMessage}
                  size={leftIconErrorSize}
                  color={colors.error[400]}
                />
              ) : undefined
            }
          >
            {errorMessage}
          </FormControl.ErrorMessage>
        ) : null}
      </FormControl>
    </Box>
  );
};

export default InputUrl;
