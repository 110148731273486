import { FieldSchema } from 'csv-file-validator';

export const csvPlacesHeadersValidator: FieldSchema[] = [
  {
    required: true,
    name: 'activity_identifier',
    inputName: 'activity_identifier',
  },
  { required: true, name: 'address', inputName: 'address' },
  { required: true, name: 'start_date', inputName: 'start_date' },
  { required: true, name: 'end_date', inputName: 'end_date' },
  { required: true, name: 'due_date', inputName: 'due_date' },
  { required: true, name: 'volunteers', inputName: 'volunteers' },
  { required: true, name: 'meeting_link', inputName: 'meeting_link' },
  { required: true, name: 'regions', inputName: 'regions' },
  {
    required: true,
    name: 'external_apply_link',
    inputName: 'external_apply_link',
  },
];

export const legacyCsvPlacesHeadersValidator: FieldSchema[] = [
  {
    required: true,
    name: 'activity_identifier',
    inputName: 'activity_identifier',
  },
  { required: true, name: 'address', inputName: 'address' },
  { required: true, name: 'start_date', inputName: 'start_date' },
  { required: true, name: 'end_date', inputName: 'end_date' },
  { required: true, name: 'due_date', inputName: 'due_date' },
  { required: true, name: 'volunteers', inputName: 'volunteers' },
  { required: true, name: 'meeting_link', inputName: 'meeting_link' },
];

export const PLACES_TEMPLATE_S3_URL =
  'https://s3.eu-west-2.amazonaws.com/doit2.0-production-static/TEMPLATE_PLACES.tsv';

export const PLACES_TEMPLATE_GOOGLE_SHEET_URL =
  'https://docs.google.com/spreadsheets/d/1sUXZ58B_JGgRxPhShnYV0OdL3j5-4fqY4Fn4khWD0j8/edit#gid=278195267';
