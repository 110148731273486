import { useMemo } from 'react';
import Tag from 'ui/components/Tags/Tag';

import { colors } from '~/theme/colors';
import { ActivityApplicationEnum } from '~/types/interfaces/activity';

import { ApplicationStatusTagProps } from './types';

export const ApplicationStatusTag = ({ status }: ApplicationStatusTagProps) => {
  const renderTag = useMemo(() => {
    switch (status) {
      case ActivityApplicationEnum.Canceled:
      case ActivityApplicationEnum.Declined:
      case ActivityApplicationEnum.Rejected:
        return (
          <Tag
            text={status}
            type="rejected"
            showCustomIcon
            showIcon
            icon="x"
            leftIconSize={16}
            tagTextColor={colors.error['500']}
          />
        );
      case ActivityApplicationEnum.Pending:
        return (
          <Tag
            text={status}
            type="pending"
            showCustomIcon
            showIcon
            icon="clock"
            leftIconSize={16}
            tagTextColor={colors.warning['500']}
          />
        );
      case ActivityApplicationEnum.Approved:
        return (
          <Tag
            text={status}
            type="done"
            showCustomIcon
            showIcon
            icon="check"
            leftIconSize={16}
            tagTextColor={colors.success['500']}
          />
        );
      case ActivityApplicationEnum.Referral:
        return (
          <Tag
            text={status}
            type="booked"
            showCustomIcon
            showIcon
            icon="repeat"
            leftIconSize={16}
            tagTextColor={colors.blue['700']}
          />
        );
      default:
        return (
          <Tag
            text="Unknown"
            type="info"
            showCustomIcon
            showIcon
            icon="clock"
            leftIconSize={16}
            tagTextColor={colors.coolGray['500']}
          />
        );
    }
  }, [status]);

  return status ? renderTag : null;
};
