import { HStack, Text } from 'native-base';
import Icon from 'ui/components/Icon';
import { colors } from 'ui/theme/colors';

type AwardedNameProps = {
  name: string;
  index: number;
};

export const AwardedName = ({ name, index }: AwardedNameProps) => {
  const renderAwardIcon = () => {
    let color = 'rgba(218, 171, 3, 1)';
    switch (index) {
      case 0:
        color = 'rgba(218, 171, 3, 1)';
        break;
      case 1:
        color = 'rgba(166, 166, 166, 1)';
        break;
      case 2:
      default:
        color = 'rgba(227, 95, 0, 1)';
        break;
    }

    return index < 3 ? <Icon icon="award" size={20} color={color} /> : null;
  };

  return (
    <HStack alignItems="center" space={1}>
      {renderAwardIcon()}
      <Text
        fontSize="sm"
        fontWeight="medium"
        color={colors.singletons.darkText}
      >
        {name}
      </Text>
    </HStack>
  );
};
