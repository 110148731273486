import { useMemo, useRef } from 'react';
import { IModalRefProps } from 'ui/components/Modals/Modal/types';

import { MediaFormProps } from './types';
import { createFormSchema } from './utils';

export const useMediaFormController = ({
  handleContinue,
  handleBack,
  defaultValues,
  isLoading,
}: MediaFormProps) => {
  const modalRef = useRef<IModalRefProps>(null);

  const formSchema = useMemo(() => {
    return createFormSchema({
      handleBack,
      defaultCoverImage: defaultValues?.coverImage as string,
      defaultImages: defaultValues?.carouselImages,
    });
  }, [handleBack, defaultValues]);

  return {
    formSchema,
    defaultValues,
    modalRef,
    handleContinue,
    isLoading,
  };
};
