import { PAGES } from '~/constants/pages.constants';
import { useRouter } from '~/hooks/useRouter';

export const usePasswordResetedController = () => {
  const { goToRoute } = useRouter();

  const handleGoToLogin = () => {
    goToRoute(PAGES.SIGNIN);
  };

  return { handleGoToLogin };
};
