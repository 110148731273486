import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { ESliceNames } from '~/store/types';

import { JoinAsAdminState } from './types';

const initialState: JoinAsAdminState = {
  code: undefined,
  organizationId: undefined,
  userId: undefined,
  isJoiningAsAdmin: false,
};

export const joinAsAdminSlice = createSlice({
  name: ESliceNames.JOIN_AS_ADMIN,
  initialState,
  reducers: {
    update: (state, { payload }: PayloadAction<JoinAsAdminState>) => {
      return {
        ...state,
        ...payload,
      };
    },
    clear: () => initialState,
  },
});

export const {
  reducer: joinAsAdminSliceReducer,
  actions: joinAsAdminSliceActions,
} = joinAsAdminSlice;
