import { useEffect, useRef, useState } from 'react';
import { IModalRefProps } from 'ui/components/Modals/Modal/types';
import { ActivityDefinition } from 'ui/types/interfaces/activity-definition';
import { MeasurementUnit } from 'ui/types/measurementUnit';

import { useLoadActivityApplicationsCount } from '~/hooks/activity-application/useLoadActivityApplicationsCount';
import { useLoadFeaturedActivityDefinitionByEcosystem } from '~/hooks/featured-activity-definition/useLoadFeaturedActivityDefinitionByEcosystem';
import { useAppSelector } from '~/hooks/useAppSelector';

export const useFeaturedActivityController = () => {
  const { selectedEcosystem } = useAppSelector(({ ecosystem }) => ecosystem);

  // States
  const featuredActivityModalRef = useRef<IModalRefProps>(null);
  const [activityDefinition, setActivityDefinition] =
    useState<ActivityDefinition | null>(null);

  const [measurementUnit, setMeasurementUnit] =
    useState<MeasurementUnit | null>(null);

  const {
    data: featuredActivityDefinition,
    isLoading: isFeaturedActivityDefinitionLoading,
    refetch,
  } = useLoadFeaturedActivityDefinitionByEcosystem(
    selectedEcosystem?._id || '',
  );

  const { data: applicationsCount, isLoading: isApplicationCountLoading } =
    useLoadActivityApplicationsCount(
      { activityDefinition: activityDefinition?._id },
      !!activityDefinition,
    );

  useEffect(() => {
    if (!featuredActivityDefinition) return;
    const _activityDefinition =
      featuredActivityDefinition.activityDefinitionSubDocument;

    const _measurementUnit =
      _activityDefinition.measurementUnit as unknown as MeasurementUnit;

    setActivityDefinition(_activityDefinition);
    setMeasurementUnit(_measurementUnit);
  }, [featuredActivityDefinition]);

  const isLoading =
    isFeaturedActivityDefinitionLoading ||
    (featuredActivityDefinition && !activityDefinition);

  const openFeaturedActivityModal = () => {
    featuredActivityModalRef.current?.open();
  };

  return {
    activityDefinition,
    measurementUnit,
    applicationsCount,
    isApplicationCountLoading,
    isLoading,
    featuredActivityModalRef,
    openFeaturedActivityModal,
    refetch,
  };
};
