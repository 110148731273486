import { useMemo, useRef, useState } from 'react';
import { toast } from 'react-toastify';
import { ModalImperativeRef } from 'ui/components/v2/Modal/types';
import { getAspectRatioLabel } from 'ui/utils/images';

import { MIN_HEIGHT, MIN_WIDTH } from './constants';
import { ImagePickerProps } from './types';
import { validateImageFile } from './utils';

export const useImagePickerSharedController = ({
  src,
  aspectRatio,
  onChange,
}: Pick<ImagePickerProps, 'src' | 'aspectRatio' | 'onChange'>) => {
  // States
  const [isLoading, setIsLoading] = useState(false);
  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const modalRef = useRef<ModalImperativeRef | null>(null);
  const [imgSrc, setImgSrc] = useState(src);

  // Handlers
  const handlePress = () => {
    fileInputRef.current?.click();
  };

  const handleNewFile = async (file: File) => {
    setIsLoading(true);
    try {
      const { isValid, message } = await validateImageFile(
        file,
        MIN_WIDTH,
        MIN_HEIGHT,
      );

      if (!isValid) {
        toast.error(message);
        return;
      }

      await new Promise((resolve) => {
        const reader = new FileReader();

        reader.addEventListener('load', () => {
          setImgSrc(reader.result?.toString() || '');
          modalRef.current?.open();
          resolve(true);
        });

        reader.addEventListener('error', () => {
          resolve(true);
        });

        reader.readAsDataURL(file);
      });
    } catch (error) {
      toast.error('Failed to load image');
    } finally {
      setIsLoading(false);
    }
  };

  const handleSubmit = (base64: string | null) => {
    modalRef.current?.close();
    setImgSrc(base64 || '');
    onChange?.(base64);
  };

  const handleClear = () => {
    setImgSrc('');
    onChange?.('');
  };

  // Hooks
  const aspectRatioLabel = useMemo(() => {
    return `${getAspectRatioLabel(aspectRatio || 1)}`;
  }, [aspectRatio]);

  return {
    imgSrc,
    modalRef,
    fileInputRef,
    isLoading,
    aspectRatioLabel,
    handleNewFile,
    handleSubmit,
    handlePress,
    handleClear,
  };
};
