import { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { FormBuilderImperativeRef } from 'ui/components/FormBuilderV2/hooks/useFormImperativeHandle/types';

import {
  UseActivityFormCommonControllerProps,
  UseActivityFormCommonControllerResult,
} from '~/components/ActivityFormBuilderPresets/Controllers/types';
import { PAGES } from '~/constants/pages.constants';
import { useLoadActivityDefinitionAppsEditingCondition } from '~/hooks/activity-definition/useLoadActivityDefinitionAppsEditingCondition';
import { useAppSelector } from '~/hooks/useAppSelector';

export const useActivityFormCommonController = ({
  defaultSelectedApp,
}: UseActivityFormCommonControllerProps): UseActivityFormCommonControllerResult => {
  // Providers
  const { selectedEcosystem } = useAppSelector(({ ecosystem }) => ecosystem);
  const { id: activityDefinitionId } = useParams();

  // States
  const formBuilderImperativeRef = useRef<FormBuilderImperativeRef>();
  const [selectedApp, setSelectedApp] = useState(defaultSelectedApp || '');
  const [isHostAppDisabled, setIsHostAppDisabled] = useState(true);
  const [disabledPublishedAppIds, setDisabledPublishedAppIds] = useState<
    string[]
  >([]);

  // Hooks
  const {
    data: appsEditingCondition,
    isLoading: isAppsEditingConditionLoading,
  } = useLoadActivityDefinitionAppsEditingCondition(activityDefinitionId);

  useEffect(() => {
    if (!defaultSelectedApp) return;
    setSelectedApp(defaultSelectedApp);
  }, [defaultSelectedApp]);

  useEffect(() => {
    if (isAppsEditingConditionLoading) return;
    const newDisabledPublishedAppIds: string[] = [];

    if (!appsEditingCondition) {
      setIsHostAppDisabled(false);
    } else {
      setIsHostAppDisabled(!appsEditingCondition?.app.isEditable);

      for (const [appId, payload] of Object.entries(
        appsEditingCondition?.publishedApps ?? {},
      )) {
        if (!payload.isEditable) {
          newDisabledPublishedAppIds.push(appId as string);
        }
      }
    }

    if (selectedApp) {
      newDisabledPublishedAppIds.push(selectedApp);
    }

    setDisabledPublishedAppIds(newDisabledPublishedAppIds);
  }, [appsEditingCondition, isAppsEditingConditionLoading, selectedApp]);

  // Handlers
  const syncPublishedApps = (newValue: string[]) => {
    if (!newValue) return;
    const previousValues =
      formBuilderImperativeRef?.current?.getValue('publishedApps') || [];

    const publishedAppsValues = [...new Set([...previousValues, ...newValue])];

    formBuilderImperativeRef?.current?.setValue(
      'publishedApps',
      publishedAppsValues as never,
    );
  };

  const onChangeApp = (value: string) => {
    setSelectedApp((prev) => (prev === value ? '' : value));
    syncPublishedApps([value]);
  };

  // Computed
  const appsHelpLink = `${PAGES.EDIT_ECOSYSTEM}/${
    selectedEcosystem?._id || ''
  }/manage-apps`;

  return {
    onChangeApp,
    selectedApp,
    appsHelpLink,
    formBuilderImperativeRef,
    disabledAppIds: disabledPublishedAppIds,
    isHostAppDisabled,
  };
};
