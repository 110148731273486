import { sanitizeQueryParams } from 'ui/utils/queryParams';

import { api } from '~/services/api';
import { INSIGHT_USER_PROFILE_ENGAGEMENT } from '~/services/resources/insights/constants';
import { BaseInsightQueryParams } from '~/services/resources/types';

import { GetUserProfilesEngagementMetricsResponse } from './types';

export default class UserProfileInsightsService {
  static async getUserProfilesEngagementMetrics(
    props: BaseInsightQueryParams,
  ): Promise<GetUserProfilesEngagementMetricsResponse> {
    const { organization, ...queryParams } = props;
    const url = INSIGHT_USER_PROFILE_ENGAGEMENT.replace(
      ':organizationId',
      organization,
    );

    const response = await api.get<GetUserProfilesEngagementMetricsResponse>(
      url,
      { params: sanitizeQueryParams(queryParams) },
    );

    return response?.data || [];
  }
}
