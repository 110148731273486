import { Alert, HStack, Link, Stack, Text } from 'native-base';
import Button from 'ui/components/Button';
import DataTable from 'ui/components/DataTable';
import Icon from 'ui/components/Icon';

import Scaffold from '~/components/Scaffold';
import ModalNewFileActivityUpload from '~/pages/Authenticated/BulkUploads/components/ModalNewFileActivityUpload';
import { useUploadsController } from '~/pages/Authenticated/BulkUploads/controller';
import { defaultFilterValue } from '~/pages/Authenticated/BulkUploads/utils';
import { colors } from '~/theme/colors';

const HELP_DESK_LINK =
  'https://intercom.help/doitlife/en/articles/7024209-bulk-uploading-opportunities';

export const BulkUploads = () => {
  const {
    columns,
    newActivitiesUpload,
    newPlacesUpload,
    openModalNewFileActivity,
    loadData,
    modalRef,
  } = useUploadsController();

  return (
    <>
      <ModalNewFileActivityUpload
        ref={modalRef}
        newActivitiesUpload={newActivitiesUpload}
        newPlacesUpload={newPlacesUpload}
      />

      <Scaffold
        headerChildren={
          <>
            <HStack maxWidth="100%" justifyContent={'space-between'}>
              <HStack alignItems={'center'} space={4}>
                <Text color="gray.500" fontSize="2xl" fontWeight="700">
                  Upload activities
                </Text>

                <Link href={HELP_DESK_LINK} isExternal>
                  <Icon
                    icon="help-circle"
                    size={20}
                    color={colors.primary[400]}
                  />
                </Link>
              </HStack>

              <Stack>
                <Button
                  h="44px"
                  px={5}
                  onPress={openModalNewFileActivity}
                  bg="primary.400"
                >
                  <HStack space={2} alignItems={'center'}>
                    <Icon
                      isCustom
                      icon="file-import"
                      color={colors.white[300]}
                      size={5}
                    />
                    <Text color="white" fontSize="md" fontWeight={500}>
                      Import file
                    </Text>
                  </HStack>
                </Button>
              </Stack>
            </HStack>
          </>
        }
      >
        <Stack space="1">
          <Text color="gray.600" fontSize={18} fontWeight="medium">
            All your uploaded files
          </Text>
          <Text color="gray.600" fontSize={14}>
            View your uploaded activities and places
          </Text>
        </Stack>

        <Alert status="warning">
          <Text>
            The bulk upload template was updated on 10/04/24 to cover regional
            work-from-home activities and applications external to our platform.
            If you are using the previously provided template, the bulk upload
            will also work for this model.
          </Text>
        </Alert>

        <Stack py="4" w="100%">
          <DataTable
            columns={columns}
            defaultFilterValues={defaultFilterValue}
            loadData={loadData}
          />
        </Stack>
      </Scaffold>
    </>
  );
};
