import { Stack } from 'native-base';

import { IInputsProps } from './types';
import { useFormFieldsRenderController } from './useFormFieldsRenderController';

const FormFieldsRender = (props: IInputsProps) => {
  const { control, items, formState, renderInput } =
    useFormFieldsRenderController(props);

  return (
    <Stack>
      {items?.map((input) => (
        <Stack key={input.id} w="100%" my={4} zIndex={9999}>
          {renderInput({ ...input, control, formState })}
        </Stack>
      )) || null}
    </Stack>
  );
};

export default FormFieldsRender;
