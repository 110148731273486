import { Auth } from 'aws-amplify';
import { HStack, Text, VStack } from 'native-base';
import Button from 'ui/components/Button';
import Illustration, { ILLUSTRATIONS_NAME } from 'ui/components/Illustration';
import { colors } from 'ui/theme/colors';

import { PAGES } from '~/constants/pages.constants';
import { useCurrentOrganization } from '~/hooks/organization/useCurrentOrganization';
import { useAppDispatch } from '~/hooks/useAppDispatch';
import { useAppSelector } from '~/hooks/useAppSelector';
import { useRouter } from '~/hooks/useRouter';
import { authSliceActions } from '~/store/slices/auth';
import { ecosystemSliceActions } from '~/store/slices/ecosystem';

export const SignUpIncomplete = () => {
  const { hasOrganization } = useCurrentOrganization();
  const { goToRoute, replaceRoute } = useRouter();
  const { user } = useAppSelector(({ auth }) => auth);
  const dispatch = useAppDispatch();

  const handleSignOut = () => {
    Auth.signOut();
    dispatch(authSliceActions.logout());
    dispatch(ecosystemSliceActions.resetEcosystem(undefined));
    replaceRoute(PAGES.ROOT);
  };

  const goToOrganizationSignUp = () => {
    if (!hasOrganization) {
      goToRoute(PAGES.SIGN_UP_CREATE_ORGANISATION);
    }
  };

  return (
    <VStack
      pt={24}
      alignItems={'center'}
      justifyContent={'flex-start'}
      px={{ base: 4 }}
      minH={'calc(100vh - 80px)'}
    >
      <VStack
        direction="column"
        alignItems="center"
        justifyContent="center"
        maxW={'400'}
        space={6}
        mb={32}
      >
        <Illustration
          name={ILLUSTRATIONS_NAME.DOIT_SOMETHING_WENT_WRONG}
          width={{ base: 150, lg: 168 }}
          height={{ base: 150, lg: 168 }}
        />

        <VStack
          w="full"
          justifyContent={'center'}
          alignItems={'center'}
          space={1}
        >
          <HStack w="fit-content" alignItems={'center'} space={1}>
            <Text textAlign="center">
              <Text
                fontSize={'lg'}
                textAlign="center"
                fontWeight={400}
                lineHeight="150%"
              >
                {'Hello, '}
              </Text>
              <Text
                fontSize={'lg'}
                textAlign="center"
                fontWeight={500}
                lineHeight="150%"
              >
                {user?.name || ''}.
              </Text>
            </Text>
          </HStack>

          <Text
            fontSize={'md'}
            fontWeight={400}
            lineHeight="150%"
            textAlign={'center'}
          >
            {
              'It looks like you don’t have an organisation yet.\n Create a new one or join using an invitation.'
            }
          </Text>
        </VStack>

        <VStack
          space={3}
          justifyContent="center"
          alignItems="center"
          flexWrap="wrap"
          backgroundColor={colors.white}
          w="full"
        >
          <Button
            maxW={'320px'}
            w="full"
            onPress={handleSignOut}
            leftIconName={'log-out'}
          >
            <Text fontSize={'sm'} color={'singletons.white'}>
              Logout
            </Text>
          </Button>

          <Button
            maxW={'320px'}
            variant={'outline'}
            onPress={goToOrganizationSignUp}
            leftIconName={'plus-square'}
            leftIconColor={colors.primary[600]}
            borderColor={'primary.600'}
          >
            <Text fontSize={'sm'} color={'primary.600'}>
              Create Organisation
            </Text>
          </Button>
        </VStack>
      </VStack>
    </VStack>
  );
};
