import { yupResolver } from '@hookform/resolvers/yup';
import { useRef } from 'react';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { ITabsRefProps } from 'ui/components/Tabs/types';

import { ICreateEcosystemForm } from '~/components/EcosystemForm/types';
import { useAppSelector } from '~/hooks/useAppSelector';
import { usePermissions } from '~/hooks/usePermissions';
import { useRouter } from '~/hooks/useRouter';
import { EcosystemService } from '~/services/resources/ecosystem';
import { NetworkAppSharingService } from '~/services/resources/networkAppSharing';
import { App } from '~/types/interfaces/app';

import { schemaValidation } from './constants';

export const useManageAppsController = () => {
  const tabsRef = useRef<ITabsRefProps>(null);

  const { params } = useRouter();

  const currentTab = 2;
  const ecosystemId = params.id;

  const { handleUpdatePartnerPermissions } = usePermissions();
  const { selectedEcosystem } = useAppSelector(({ ecosystem }) => ecosystem);

  const {
    control,
    formState: { isSubmitting, isValid, errors },
    handleSubmit,
  } = useForm<ICreateEcosystemForm>({
    mode: 'onBlur',
    resolver: yupResolver(schemaValidation),
  });

  const { goBack, goToRoute } = useRouter();

  const handleBack = () => {
    goBack();
  };

  const onSubmit = handleSubmit(async (data) => {
    if (!ecosystemId) return;
    try {
      let appsConnectedKey = '';
      if (data?.apps?.length) {
        const appIds = data?.apps?.map((app: App) => app._id);
        for (const appId of appIds) {
          await EcosystemService.connectEcosystemToApp({
            ecosystem: ecosystemId,
            app: appId,
          });
          appsConnectedKey += `, ${appId}`;
        }
      }

      if (data?.myAppSharings?.length) {
        for (const appSharing of data.myAppSharings) {
          if (appSharing?._id) {
            await NetworkAppSharingService.updateStatus(appSharing._id, {
              isEnabled: appSharing.isEnabled,
            });
          }
        }
      }

      toast.success(`Ecosystem updated with success`);

      if (selectedEcosystem)
        handleUpdatePartnerPermissions(selectedEcosystem._id);

      goToRoute(`/settings/ecosystems/${ecosystemId}`, {
        state: { appsConnectedKey },
      });
    } catch (error) {
      toast.error(`Error on trying to update ecosystem`);
    }
  });

  return {
    control,
    errors,
    isValid,
    isSubmitting,
    tabsRef,
    currentTab,
    ecosystemId,
    onSubmit,
    handleBack,
  };
};
