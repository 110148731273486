import { useCallback } from 'react';
import { useController, useFieldArray } from 'react-hook-form';
import { toast } from 'react-toastify';

import { SIGN_UP_MESSAGES } from '~/constants/messages.constants';
import { useAppDispatch } from '~/hooks/useAppDispatch';
import { AddOrgMembersFormValues } from '~/pages/Authenticated/AddOrgMember/components/Form/types';
import { addMemberActions } from '~/store/slices/addMember';

import { IInvitationFormSectionProps } from './types';

export const useInvitationFormSectionController = ({
  control,
}: IInvitationFormSectionProps) => {
  // Email input

  const { field, fieldState } = useController({
    control,
    name: 'employeeEmail' as never,
  });

  // ExternalId input
  const { field: externalIdField, fieldState: externalIdFieldState } =
    useController({
      control,
      name: 'externalId' as never,
    });

  // Email list
  const {
    fields: employeesEmailsArray,
    prepend,
    remove,
  } = useFieldArray<AddOrgMembersFormValues, never, 'email' | 'externalId'>({
    name: 'userConfigurations' as never,
    control,
  });

  const dispatch = useAppDispatch();

  const handleAppendEmail = useCallback(() => {
    if (fieldState.invalid || !field.value) {
      return;
    }

    if (externalIdFieldState.invalid) {
      return;
    }

    const emails = employeesEmailsArray.map(({ email }) => email);
    const externalIds = employeesEmailsArray.map(
      ({ externalId }) => externalId,
    );

    if (emails.includes(field.value)) {
      toast.error(SIGN_UP_MESSAGES.EMAIL_ALREADY_EXISTS);
      return;
    }

    if (externalIds.includes(externalIdField.value)) {
      toast.error(SIGN_UP_MESSAGES.EXTERNAL_ID_ALREADY_EXISTS);
      return;
    }

    dispatch(
      addMemberActions.setInviteUserConfigurations({
        email: field.value,
        externalId: externalIdField?.value,
      }),
    );

    prepend({ email: field.value, externalId: externalIdField.value || '' });
    field.onChange('');
    externalIdField.onChange('');
  }, [
    employeesEmailsArray,
    field,
    fieldState.invalid,
    prepend,
    dispatch,
    externalIdField,
    externalIdFieldState.invalid,
  ]);

  const handleRemoveEmail = (index: number) => {
    const emailToRemove = employeesEmailsArray[index];

    if (emailToRemove.email) {
      dispatch(
        addMemberActions.removeInviteUserConfiguration(emailToRemove.email),
      );
    }

    remove(index);
  };

  return {
    employeesEmailsArray,
    field,
    fieldState,
    handleAppendEmail,
    handleRemoveEmail,
  };
};
