import { App } from 'ui/types/interfaces/app';

import { PermissionCategories, PermissionType } from '../../enums/index';

export enum TeamEventsConditionFields {
  ACTIVITIES_LIMIT = 'ACTIVITIES_LIMIT',
}

export const PermissionConditionErrorMessages: Record<
  TeamEventsConditionFields,
  Record<PermissionConflictResolutionMethodType, (value: number) => string>
> = {
  [TeamEventsConditionFields.ACTIVITIES_LIMIT]: {
    UseLowestValue: (value: number) =>
      `Team events limit must be lower than or equal to ${value}`,
    UseHighestValue: (value: number) =>
      `Team events limit must be higher than or equal to ${value}`,
  },
};

export interface PermissionCategory {
  name: PermissionCategories;
  description: string;
  displayName: string;
}

export type PermissionRestrictionType =
  | 'Boolean'
  | 'Quantitative'
  | 'ResourceBased';

export type ConditionSupportedOperators =
  | '$eq'
  | '$lt'
  | '$lte'
  | '$gt'
  | '$gte'
  | '$in';

export enum PermissionConditionValueTypes {
  OBJECT_ID = 'ObjectId',
  STRING = 'String',
  NUMBER = 'Number',
  BOOLEAN = 'Boolean',
  DATE = 'Date',
}

export type PermissionConflictResolutionMethodType =
  | 'UseHighestValue'
  | 'UseLowestValue';

export interface PermissionCondition {
  field: string; // Ex: "tag", "data"
  operator: ConditionSupportedOperators;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  value: any;
  defaultValue: any;
  valueType: PermissionConditionValueTypes;
}

export interface PermissionSharingSettings {
  isShared: boolean;
  denyEdition: boolean;
  isRequired: boolean;
  editConflictResolutionMethod?: PermissionConflictResolutionMethodType;
}

export interface Permission {
  _id: string;
  name: string;
  description: string;
  type: PermissionType;
  category: PermissionCategory;
  restrictionType: PermissionRestrictionType;
  conditions?: PermissionCondition[];
  permissionTemplateId?: string;
  isTemplate: boolean;
  sharingSettings: {
    isShared: boolean;
    isRequired: boolean;
    denyEdition: boolean;
    editConflictResolutionMethod?: PermissionConflictResolutionMethodType;
  };
}

export interface AppPermissionGroup {
  _id: string;
  app: string;
  description: string;
  name: string;
  permissions: string[];
  permissionsSubDocument: Permission[];
  ecosystem: string;
  type: PermissionType;
  organization: string;
  isDraft?: boolean;
  isDefault?: boolean;
}

export interface PopulatedAppPermissionGroup
  extends Omit<AppPermissionGroup, 'permissions' | 'app'> {
  app: App | string;
  permissions: Permission[];
}
