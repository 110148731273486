import { Text, VStack } from 'native-base';
import Button from 'ui/components/Button';
import Modal from 'ui/components/Modals/Modal';

import { ModalInformationProps } from './types';

export const ModalInformation = ({
  headerText,
  children,
  buttonText,
  modalRef,
  buttonAction,
}: ModalInformationProps) => {
  return (
    <Modal headerText={headerText} modalContentMaxW="600" ref={modalRef}>
      <VStack space={4}>
        {children}
        {buttonAction && buttonText ? (
          <Button backgroundColor="primary.500" onPress={buttonAction}>
            {buttonText}
          </Button>
        ) : null}
      </VStack>
    </Modal>
  );
};
