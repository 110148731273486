import FeatherIcon from 'feather-icons-react';
import { Box, FormControl, HStack } from 'native-base';
import { Controller } from 'react-hook-form';

import { colors } from '../../theme/colors';
import RadioItem from './RadioItem';
import { ICustomRadioProps } from './types';
import { useRadioController } from './useRadioController';

const _Radio = (props: ICustomRadioProps) => {
  const {
    _formControl,
    control,
    name,
    helperText,
    hasError,
    options,
    isInvalid,
    isDisabled,
    isReadOnly,
    isRequired,
    iconErrorSize,
    errorMessage,
    iconErrorMessage,
    label,
    onChangeValue,
    hasDescriptions,
  } = useRadioController(props);

  if (!options?.length) {
    return null;
  }

  return (
    <Box>
      <FormControl
        isInvalid={hasError || errorMessage ? true : isInvalid}
        isDisabled={isDisabled}
        isRequired={isRequired}
        isReadOnly={isReadOnly}
        {..._formControl}
      >
        {label ? <FormControl.Label>{label}</FormControl.Label> : null}
        <Controller
          control={control}
          defaultValue={props.defaultValue}
          name={name}
          render={({ field: { onChange, value } }) => (
            <HStack
              flex={1}
              flexDirection="row"
              justifyContent={'flex-start'}
              space={8}
            >
              {options?.map((item, index) => (
                <RadioItem
                  text={item.text}
                  value={item.value}
                  checked={item.value === value}
                  key={index}
                  description={item.description}
                  isDisabled={isDisabled}
                  onChange={() => {
                    if (isDisabled) return;
                    onChange(item.value);
                    if (onChangeValue) {
                      onChangeValue(item.value);
                    }
                  }}
                />
              ))}
            </HStack>
          )}
        />
        {!hasError && !errorMessage && helperText ? (
          <FormControl.HelperText ml={2}>{helperText}</FormControl.HelperText>
        ) : null}
        {errorMessage ? (
          <FormControl.ErrorMessage
            ml={2}
            leftIcon={
              iconErrorMessage ? (
                <FeatherIcon
                  icon={iconErrorMessage}
                  size={iconErrorSize}
                  color={colors.error[400]}
                />
              ) : undefined
            }
          >
            {errorMessage}
          </FormControl.ErrorMessage>
        ) : null}
      </FormControl>
    </Box>
  );
};

export default _Radio;
