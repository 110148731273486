import { Text, VStack } from 'native-base';
import Modal from 'ui/components/Modals/Modal';

import { ModalInformationProps } from './types';

export const ModalInformation = ({
  headerText,
  description,
  modalRef,
  placement = 'top',
}: ModalInformationProps) => {
  return (
    <Modal
      placement={placement}
      headerText={headerText}
      modalContentMaxW="600"
      ref={modalRef}
    >
      <VStack space={4}>
        <Text fontSize="md" color="gray.600">
          {description}
        </Text>
      </VStack>
    </Modal>
  );
};
