import { Stack } from 'native-base';
import React from 'react';
import { Controller } from 'react-hook-form';
import FileZone from 'ui/components/Dropzone/FileZone';

import { EImportFileType } from '~/pages/Authenticated/BulkUploads/types';

import { IActivitiesUploadProps } from './types';

const _ActivitiesUploadContent = ({
  control,
  haveImports,
  uploadIsLoading,
  defaultImportedFile,
  handleDropFile,
  handleRemoveFile,
  importFileType,
  isDisabled = false,
}: IActivitiesUploadProps) => {
  return (
    <Stack py="4" borderRadius="16" w="100%" space="6">
      <Controller
        name={
          importFileType === EImportFileType.ActivityDefinition
            ? 'activity-definition'
            : 'places'
        }
        control={control}
        render={({ field: { onChange } }) => {
          const canRemoveFile =
            !haveImports && !uploadIsLoading && !defaultImportedFile;

          return (
            <FileZone
              defaultImportedFile={defaultImportedFile}
              fileUploadType={importFileType}
              filesField={
                importFileType === EImportFileType.Activity
                  ? 'places-file'
                  : 'activity-file'
              }
              title={
                importFileType === EImportFileType.Activity
                  ? 'Places file'
                  : 'Activity file'
              }
              handleDropFile={(file) =>
                handleDropFile(onChange, file, isDisabled)
              }
              handleRemoveFile={() => handleRemoveFile(isDisabled)}
              isDisabled={isDisabled}
              canRemoveFile={canRemoveFile}
            />
          );
        }}
      />
    </Stack>
  );
};

export const ActivitiesUploadContent = React.memo(_ActivitiesUploadContent);
