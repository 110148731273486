import SelectFilter from '@inovua/reactdatagrid-community/SelectFilter';
import { VStack } from 'native-base';
import {
  IColumn,
  TableOperatorNames,
  TableOperatorType,
} from 'ui/components/DataTable/types';
import { TableCellWithImage } from 'ui/components/TableCellWithImage';
import { ActivityTypeBadge } from 'ui/components/v2/Badges/ActivityTypeBadge';
import { ActivityType } from 'ui/types/activities';
import { Organization } from 'ui/types/interfaces/organization';
import { mapToActivitySemanticType } from 'ui/utils/activity/mappers';

export type MapActivityDefinitionToTableColumnProps = {
  organizations: Organization[];
};

export const MapActivityDefinitionToTableColumn = ({
  organizations,
}: MapActivityDefinitionToTableColumnProps): IColumn[] => {
  const organizationsDataSource = (organizations || [])?.map(
    (organization) => ({
      id: organization._id,
      label: organization.name,
    }),
  );

  return [
    {
      filterable: true,
      name: 'title',
      header: 'Activity',
      defaultFlex: 1,
      render: ({ data }) => data?.title || '-',
    },
    {
      name: 'type',
      header: 'Type',
      filterEditor: SelectFilter,
      filterEditorProps: {
        multiple: false,
        wrapMultiple: false,
        dataSource: Object.values(ActivityType).map((activityType) => {
          return {
            id: activityType,
            label:
              activityType === ActivityType.Ongoing ? 'Ongoing' : activityType,
          };
        }),
      },
      render: ({ data }) => {
        const semanticType = mapToActivitySemanticType(
          data.type,
          data.eventApplicationType,
        );
        return (
          <VStack w="fit-content">
            <ActivityTypeBadge activityType={semanticType} />
          </VStack>
        );
      },
    },
    {
      name: 'organization',
      header: 'Organisation',
      defaultFlex: 1,
      filterEditor: SelectFilter,
      filterEditorProps: {
        multiple: false,
        wrapMultiple: false,
        dataSource: organizationsDataSource,
      },
      render: ({ data }: any) => (
        <TableCellWithImage
          round
          name={data?.organization?.name || '-'}
          logo={data?.organization?.logo || '-'}
        />
      ),
    },
  ];
};

export const defaultActivityDefinitionToTableFilterValue = [
  {
    name: 'title',
    operator: TableOperatorNames.Contains,
    type: TableOperatorType.String,
    value: '',
  },
  {
    name: 'type',
    operator: TableOperatorNames.Equals,
    type: TableOperatorType.String,
    value: '',
  },
  {
    name: 'organization',
    operator: TableOperatorNames.Equals,
    type: TableOperatorType.String,
    value: '',
  },
];
