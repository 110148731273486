import { useMemo } from 'react';
import { useQuery } from 'react-query';

import { api } from '~/services/api';
import { IActivityCategoryDTO } from '~/types/dtos';

const queryFilter = {
  causeOptions: {
    filter: JSON.stringify({ relatedTo: ['causeOptions'] }),
  },
  requirementOptions: {
    filter: JSON.stringify({ relatedTo: ['requirementOptions'] }),
  },
};

export const useActivityCategoryOptions = () => {
  const { data: causeOptionsData, isFetching: isLoadingCauses } = useQuery({
    queryKey: 'causeOptions',
    queryFn: async () => {
      const response = await api.get('activity-category', {
        //TODO: In the future if needed add pagination, for now we're not getting all the options without this
        params: { ...queryFilter.causeOptions, limit: 200 },
      });

      return response.data;
    },
  });
  const { data: requirementOptionsData, isFetching: isLoadingRequirements } =
    useQuery({
      queryKey: 'requirementOptions',
      queryFn: async () => {
        const response = await api.get('activity-category', {
          //TODO: In the future if needed add pagination, for now we're not getting all the options without this
          params: { ...queryFilter.requirementOptions, limit: 200 },
        });

        return response.data;
      },
    });

  const causeOptions = useMemo(() => {
    if (
      !isLoadingCauses &&
      causeOptionsData &&
      causeOptionsData?.data?.length > 0
    ) {
      const uniqueCauses = causeOptionsData.data.reduce(
        (
          acc: { value: string; label: string }[],
          current: IActivityCategoryDTO,
        ) => {
          const existsCause = acc.find(
            (accCause) => accCause.label === current.displayName,
          );

          if (!existsCause) {
            acc.push({
              value: current._id,
              label: current.displayName,
            });
          }
          return acc;
        },
        [] as { value: string; label: string }[],
      );
      return uniqueCauses;
    }
    return [];
  }, [causeOptionsData, isLoadingCauses]);

  const requirementOptions = useMemo(() => {
    if (
      !isLoadingRequirements &&
      requirementOptionsData &&
      requirementOptionsData?.data?.length > 0
    ) {
      const uniqueRequirements = requirementOptionsData.data.reduce(
        (
          acc: { value: string; label: string }[],
          current: IActivityCategoryDTO,
        ) => {
          const existsRequirement = acc.find(
            (accRequirement) => accRequirement.label === current.displayName,
          );

          if (!existsRequirement) {
            acc.push({
              value: current._id,
              label: current.displayName,
            });
          }
          return acc;
        },
        [] as { value: string; label: string }[],
      );
      return uniqueRequirements;
    }
    return [];
  }, [requirementOptionsData, isLoadingRequirements]);

  return {
    requirementOptions,
    causeOptions,
    requirementOptionsData,
    causeOptionsData,
  };
};
