import { ActivityRegion } from 'ui/types/activities';
import { IPermission } from 'ui/types/interfaces/permission';

import { ActivityTypeEnum } from '~/enums';

export interface ActivityDetailsProps {
  activityType: ActivityTypeEnum;
}

export interface ActivityDetailsSectionProps {
  appId: string;
  activityPermissions: Partial<IPermission>[];
  isAppAdmin?: boolean;
}
export enum ActivityDetailsDetailsTabPages {
  About = 0,
  Applications = 1,
}
