import { yupResolver } from '@hookform/resolvers/yup';
import { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';

import { PAGES } from '~/constants/pages.constants';
import { useAppDispatch } from '~/hooks/useAppDispatch';
import { useAppSelector } from '~/hooks/useAppSelector';
import { useLoadOrganization } from '~/hooks/useLoadOrganization';
import { useLoadOrganizationEcosystems } from '~/hooks/useLoadOrganizationEcosystems';
import { useLoadPartnerInviteCode } from '~/hooks/useLoadPartnerInviteCode';
import { useLoadUsedPartnerInvite } from '~/hooks/useLoadUsedPartnerInvite';
import { usePermissions } from '~/hooks/usePermissions';
import { useRouter } from '~/hooks/useRouter';
import {
  JoinAsPartnerFormValues,
  schema,
} from '~/pages/Common/JoinAsPartner/constants';
import { usePartnerInviteFromUrl } from '~/pages/Common/JoinAsPartner/hooks/usePartnerInviteFromUrl';
import { InvitePartnerService } from '~/services/resources/invitePartner';
import { joinAsPartnerSliceActions } from '~/store/slices/joinAsPartner';
import { redirectSliceActions } from '~/store/slices/redirect';

export const useJoinAsPartnerController = () => {
  const [isSubmitting, setIsSubmitting] = useState(false);

  const { currentTab } = useAppSelector(({ joinAsPartner }) => joinAsPartner);
  const { isAuthenticated, user } = useAppSelector(({ auth }) => auth);

  const { organizationSelectedId, organizations } = useAppSelector(
    ({ auth }) => auth,
  );

  const { handleUpdatePartnerPermissions } = usePermissions();
  const dispatch = useAppDispatch();
  const { location, goToRoute, replaceRoute } = useRouter();
  const { inviteURL, code } = usePartnerInviteFromUrl();

  const {
    control,
    handleSubmit,
    reset,
    setValue,
    formState: { isValid, errors },
  } = useForm<JoinAsPartnerFormValues>({
    defaultValues: { termsOfServiceAccepted: false, ecosystem: undefined },
    resolver: yupResolver(schema?.[currentTab]),
  });

  const { data: inviteCode, isLoading: isLoadingInviteCode } =
    useLoadPartnerInviteCode(code || '');

  const { data: organization, isLoading: isLoadingOrganization } =
    useLoadOrganization(inviteCode?.sharerOrganization || '');

  const { data: usedInviteCode, isLoading: isLoadingUsedInviteCode } =
    useLoadUsedPartnerInvite(inviteCode?.code, user?.email);

  const { ecosystems, isLoadingOrganizationEcosystems } =
    useLoadOrganizationEcosystems();

  const handleNextTab = handleSubmit(async () => {
    if (code) {
      dispatch(
        joinAsPartnerSliceActions.update({
          organizationName: organization?.name || '',
          code,
        }),
      );
      dispatch(joinAsPartnerSliceActions.nextTab());
    }
  });

  const onSubmit = handleSubmit(async (data) => {
    try {
      if (!code) return;
      setIsSubmitting(true);
      await InvitePartnerService.acceptInvite({
        receiverEcosystem: data.ecosystem,
        code,
      });
      dispatch(joinAsPartnerSliceActions.nextTab());
    } catch (error) {
      toast.error(`Error on trying to accept invite.`);
    } finally {
      setIsSubmitting(false);
    }
  });

  const handleDeclineInvitation = () => {
    replaceRoute(`/invite/partner/${code}/declined`);
    dispatch(joinAsPartnerSliceActions.clear());
    dispatch(redirectSliceActions.clear());
  };

  const inviteEcosystem = useMemo(() => {
    if (!inviteCode) return;
    return inviteCode?.configurations[0].ecosystemId;
  }, [inviteCode]);

  const goToHomePage = async () => {
    if (ecosystems) await handleUpdatePartnerPermissions(ecosystems[0]._id);
    replaceRoute(PAGES.ROOT);
  };

  const ecosystemOptions = useMemo(() => {
    return (
      ecosystems?.map((ecosystem) => {
        return {
          label: ecosystem.name,
          value: ecosystem._id,
        };
      }) || []
    );
  }, [ecosystems]);

  useEffect(() => {
    dispatch(joinAsPartnerSliceActions.clear());

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!isAuthenticated) {
      replaceRoute(`${location.pathname}/signup`);
    } else if (!organizationSelectedId) {
      goToRoute(`${PAGES.SIGN_UP_CREATE_ORGANISATION}?redirect=${inviteURL}`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inviteCode, organizationSelectedId, isAuthenticated]);

  useEffect(() => {
    if (organization && !organization.termsOfServicesLink) {
      // make hidden checkbox checked to turn button active
      reset({ termsOfServiceAccepted: true });
    }
  }, [reset, organization]);

  useEffect(() => {
    if (ecosystemOptions.length === 1 && currentTab === 1) {
      setValue('ecosystem', ecosystemOptions[0].value);
      onSubmit();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentTab]);

  const isLoading =
    isLoadingInviteCode ||
    isLoadingOrganization ||
    isLoadingUsedInviteCode ||
    isLoadingOrganizationEcosystems;

  return {
    inviteCode,
    organization,
    organizations,
    isLoading,
    control,
    isSubmitting,
    isValid,
    isAuthenticated,
    usedInviteCode,
    ecosystemOptions,
    currentTab,
    errors,
    inviteEcosystem,
    handleNextTab,
    onSubmit,
    handleDeclineInvitation,
    goToHomePage,
  };
};
