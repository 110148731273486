import { Box, Divider, HStack, Skeleton, Stack, Text } from 'native-base';
import Button from 'ui/components/Button';
import Icon from 'ui/components/Icon';

import { useReportCardsController } from './controller';
import { borderStyles } from './styles';
import { ReportCardsProps } from './types';

export const ReportCards = ({
  title,
  value,
  valueInfo,
  dividerColor,
  icon,
  iconColor = 'gray.900',
  subtitle,
  isIconCustom,
  contentChildren,
  paddingTitle = 5,
  //TODO: Refactor this prop to something like titleSectionHeight?
  cardHeight = '73px',
  isLoading,
  withBorder,
  direction = 'row',
  action,
  actionButtonName,
  actionButtonIcon,
  actionButtonIconPosition = 'left',
}: ReportCardsProps) => {
  const { optionsMemo, commonIconName } = useReportCardsController({
    direction,
    actionButtonName,
  });

  return (
    <Stack
      backgroundColor="white"
      borderRadius="2xl"
      space={2}
      style={withBorder && borderStyles.border}
      flex="1"
    >
      <Stack height={cardHeight} space={2} p={paddingTitle} py={1}>
        <HStack
          space={2}
          alignItems="center"
          px={withBorder ? 4 : 0}
          overflow="hidden"
        >
          {icon ? (
            <Icon
              size="25"
              color={iconColor}
              icon={icon}
              isCustom={isIconCustom}
            />
          ) : null}
          <HStack
            space={2}
            justifyContent="space-between"
            w="full"
            alignItems="center"
            minH="35px"
          >
            <Stack overflow="hidden">
              <Text fontSize="sm" fontWeight="500" isTruncated>
                {title}
              </Text>
            </Stack>
            {action && actionButtonName ? (
              <Stack>
                <Button
                  onPress={action}
                  variant="link"
                  w="auto"
                  pl={0}
                  leftIconName={
                    actionButtonIconPosition === 'left'
                      ? commonIconName
                      : undefined
                  }
                  rightIconName={
                    actionButtonIconPosition === 'right'
                      ? commonIconName
                      : undefined
                  }
                  leftIconColor="primary.600"
                  rightIconColor="primary.600"
                  color="primary.600"
                >
                  <Text fontSize="sm" fontWeight="500" color="primary.600">
                    {actionButtonName}
                  </Text>
                </Button>
              </Stack>
            ) : null}
          </HStack>
        </HStack>
        {subtitle ? (
          <Text fontSize="xs" color="gray.400" fontWeight="500">
            {subtitle}
          </Text>
        ) : null}
      </Stack>
      <Divider bgColor={dividerColor} size="0.5" width="100%" />
      <Stack flexDirection={optionsMemo.direction} space={2} px={4} pb={5}>
        <Stack space={2} width={contentChildren ? '1/2' : 'full'}>
          {isLoading ? (
            <Box w="50%" fontSize="2xl">
              <Skeleton />
            </Box>
          ) : (
            <>
              <Text fontSize="3xl" fontWeight="700" color="gray.900">
                {value}
              </Text>
              {valueInfo ? (
                <Text fontSize="xs" color="gray.500" fontWeight="500">
                  {valueInfo}
                </Text>
              ) : null}
            </>
          )}
        </Stack>
        {contentChildren ? contentChildren : null}
      </Stack>
    </Stack>
  );
};
export default ReportCards;
