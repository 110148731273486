import { PermissionType } from 'ui/enums';

import Yup from '~/utils/validations/yup';

export const yupSchema = Yup.object().shape({
  app: Yup.string().required(),
  type: Yup.string().oneOf(Object.values(PermissionType)).required(),
  name: Yup.string().required(),
  description: Yup.string().max(72),
  networkAppSharingId: Yup.string(),
});
