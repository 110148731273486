import { Checkbox, HStack, Link, Stack, Text } from 'native-base';
import { Controller } from 'react-hook-form';
import Button from 'ui/components/Button';

import { JoinAsPartnerHomeProps } from '~/pages/Common/JoinAsPartner/types';
import { colors } from '~/theme/colors';
import { transformURL } from '~/utils/transformURL';

export const JoinAsPartnerHome = ({
  control,
  isSubmitting,
  isValid,
  organization,
  handleDeclineInvitation,
  onSubmit,
}: JoinAsPartnerHomeProps) => {
  return (
    <Stack
      width="100%"
      height="100%"
      justifyContent="center"
      bgColor={colors.singletons.lightText}
      alignItems={{ base: 'center', lg: 'initial' }}
    >
      <Stack>
        <Text fontSize={{ base: 'lg', lg: '2xl' }} fontWeight={500}>
          You have been invited to join!
        </Text>
      </Stack>

      <Text mt={4} fontSize={{ base: 'xs', lg: 'lg' }}>
        Will you accept the invitation?
      </Text>

      <Stack width="100%">
        {organization?.termsOfServicesLink ? (
          <Controller
            control={control}
            name="termsOfServiceAccepted"
            render={({ field: { value, onChange } }) => (
              <HStack alignItems="center" space="3" mt="10">
                <Checkbox
                  _checked={{ bgColor: 'gray.900', borderColor: 'gray.900' }}
                  value="true"
                  isChecked={value}
                  onChange={onChange}
                >
                  <Text
                    maxW="400px"
                    width="100%"
                    fontSize={{ base: 'xs', lg: 'sm' }}
                  >
                    By accepting this invitation, you are accepting the{' '}
                    <Text underline>
                      <Link
                        isExternal
                        color="gray.800"
                        href={transformURL(organization.termsOfServicesLink)}
                      >
                        terms and conditions.
                      </Link>
                    </Text>
                  </Text>
                </Checkbox>
              </HStack>
            )}
          />
        ) : null}
        <Stack
          mt={{ base: 20, lg: 8 }}
          space={2}
          maxW={{ base: '100%', lg: '500px' }}
          width="100%"
        >
          <Button
            onPress={onSubmit}
            padding={6}
            bgColor="gray.900"
            _text={{ fontWeight: 500 }}
            isDisabled={!isValid || isSubmitting}
          >
            Accept invitation
          </Button>
          <Button
            onPress={handleDeclineInvitation}
            padding={6}
            variant="ghost"
            colorScheme="error"
            _text={{ color: 'error.500', fontWeight: 500 }}
          >
            Decline
          </Button>
        </Stack>
      </Stack>
    </Stack>
  );
};
