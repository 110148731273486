import { useQuery } from '~/hooks/useQuery';
import { PopulatedInvitePartners } from '~/types/interfaces/invite-partner';

export const useLoadPartnerInviteCodePublic = (code = '') => {
  const { data, isLoading } = useQuery<PopulatedInvitePartners>(
    `invite-partners/public/code/${code}`,
    {
      queryOptions: { enabled: !!code },
      key: `invite-partners-code-public-${code}`,
      isOpenApi: true,
    },
  );

  return { data, isLoading };
};
