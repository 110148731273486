import { Divider, HStack, Stack, Text, VStack } from 'native-base';
import { toast } from 'react-toastify';
import Button from 'ui/components/Button';
import Icon from 'ui/components/Icon';
import Tag from 'ui/components/Tags/Tag';

import { colors } from '~/theme/colors';

import { styles } from './styles';

export const ChildOrgDetails = () => {
  return (
    <Stack
      bgColor="white"
      borderRadius="3xl"
      width="100%"
      space={5}
      padding={6}
    >
      <HStack justifyContent="space-between" space={10}>
        <Stack space={2} width="46%">
          <Text fontSize="lg" fontWeight="500" color="gray.600">
            Basic Information
          </Text>
          <Divider />

          <VStack space={2} mt={6}>
            <Text fontSize="sm" fontWeight="500" color="gray.600">
              Causes
            </Text>
            <HStack space={2}>
              <Tag
                text="Cause 1"
                textTransform="none"
                tagTextColor="gray.500"
                tagBgColor="gray.100"
              />
              <Tag
                text="Cause 2"
                textTransform="none"
                tagTextColor="gray.500"
                tagBgColor="gray.100"
              />
            </HStack>
          </VStack>
          <VStack space={2} mt={6}>
            <Text fontSize="sm" fontWeight="500" color="gray.600">
              Purpose
            </Text>
            <Text fontSize="sm" fontWeight="400" color="gray.600">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit.
              Vestibulum mollis nunc a molestie dictum mauris.
            </Text>
          </VStack>
        </Stack>
        <Stack space={2} width="46%">
          <Text fontSize="lg" fontWeight="500" color="gray.600">
            Details
          </Text>
          <Divider />

          <VStack space={2} mt={6}>
            <Text fontSize="sm" fontWeight="500" color="gray.600">
              Contact phone number
            </Text>
            <HStack space={2} width="70%" justifyContent="space-between">
              <HStack
                space={2}
                alignItems="center"
                justifyContent="space-between"
              >
                <Icon icon="phone" color={colors.gray[400]} size={16} />
                <Text fontSize="sm" fontWeight={400} color="darkText">
                  (425) 234-0480
                </Text>
              </HStack>
              <Button
                leftIconName="copy"
                leftIconColor={colors.primary[600]}
                leftIconSize={16}
                maxWidth="75px"
                minWidth="75px"
                bgColor="primary.50"
                _text={styles.buttonCopy}
                onPress={() => toast.success('Phone copied to clipboard')}
              >
                Copy
              </Button>
            </HStack>
          </VStack>

          <VStack space={2} mt={6}>
            <Text fontSize="sm" fontWeight="500" color="gray.600">
              Contact email address
            </Text>
            <HStack space={2} width="70%" justifyContent="space-between">
              <HStack
                space={2}
                alignItems="center"
                justifyContent="space-between"
              >
                <Icon icon="mail" color={colors.gray[400]} size={16} />
                <Text fontSize="sm" fontWeight={400} color="darkText">
                  contact@fareshare.com
                </Text>
              </HStack>
              <Button
                leftIconName="copy"
                leftIconColor={colors.primary[600]}
                leftIconSize={16}
                maxWidth="75px"
                minWidth="75px"
                bgColor="primary.50"
                _text={styles.buttonCopy}
                onPress={() => toast.success('Email copied to clipboard')}
              >
                Copy
              </Button>
            </HStack>
          </VStack>

          <VStack space={2} mt={6}>
            <Text fontSize="sm" fontWeight="500" color="gray.600">
              Full Address
            </Text>
            <HStack space={2} alignItems="center">
              <Icon icon="map-pin" color={colors.gray[400]} size={16} />
              <Text fontSize="sm" fontWeight={400} color="darkText">
                Stamfordham Rd, Westerhope, Newcastle, UK{' '}
              </Text>
            </HStack>
          </VStack>

          <VStack space={2} mt={6}>
            <Text fontSize="sm" fontWeight="500" color="gray.600">
              URL
            </Text>
            <HStack space={2} alignItems="center">
              <Icon icon="globe" color={colors.gray[400]} size={16} />
              <Text fontSize="sm" fontWeight={400} color="darkText">
                www.doit.life/companyname.com
              </Text>
            </HStack>
          </VStack>

          <VStack space={2} mt={6}>
            <Text fontSize="sm" fontWeight="500" color="gray.600">
              Description
            </Text>
            <HStack space={2} alignItems="center">
              <Text fontSize="sm" fontWeight={400} color="darkText">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                Vestibulum mollis nunc a molestie dictum. Mauris venenatis,
                felis scelerisque aliquet lacinia, nulla nisi venenatis odio, id
                blandit mauris ipsum id sapien. Vestibulum malesuada orci sit
                amet pretium facilisis. In lobortis congue augue, a commodo
                libero tincidunt scelerisque.{' '}
              </Text>
            </HStack>
          </VStack>

          <VStack space={2} mt={6}>
            <Text fontSize="sm" fontWeight="500" color="gray.600">
              Terms of Service
            </Text>
            <HStack space={2}>
              <Button variant="link" width={'1/3'}>
                terms-and-cond.pdf
              </Button>
            </HStack>
          </VStack>
        </Stack>
      </HStack>
    </Stack>
  );
};
