import { Avatar, Box, HStack, Stack, Text, VStack } from 'native-base';
import Icon from 'ui/components/Icon';
import { AppVisibility } from 'ui/enums';

import { colors } from '~/theme/colors';

import { AppCardProps } from './types';

export const AppCard = ({ app }: AppCardProps) => (
  <VStack
    bg={colors.singletons.lightText}
    p="6"
    rounded="2xl"
    borderWidth="1"
    borderColor="gray.300"
    position="relative"
    w="full"
  >
    <Stack space="3">
      <Avatar rounded="xl" source={{ uri: app.logo }} />
      <Text
        numberOfLines={2}
        color="gray.600"
        fontSize="xl"
        fontWeight={500}
        height="3.4rem"
      >
        {app?.name || ''}
      </Text>
    </Stack>

    <HStack justifyContent="space-between" mt="6">
      <Box>
        <HStack space="1" alignItems="center">
          {app?.visibility === AppVisibility.Private ? (
            <>
              <Icon icon="lock" size="16" color={colors.gray[500]} />
              <Text color="gray.500">Private</Text>
            </>
          ) : (
            <>
              <Icon icon="eye" size="16" color={colors.gray[500]} />
              <Text color="gray.500">Public</Text>
            </>
          )}
        </HStack>
      </Box>
      {/* <Pressable>
        <HStack alignItems="center" space="3">
          <Text color="primary.400">View app</Text>
          <Icon icon="arrow-right" size="16" color={colors.primary[400]} />
        </HStack>
      </Pressable> */}
    </HStack>
  </VStack>
);
