import { HStack, Text } from 'native-base';
import { AppSelectorFilter } from 'ui/components/v2/AppInputs/AppSelector';

import { useInsightsController } from '~/pages/Authenticated/Insights/controller';
import { InsightDateFilter } from '~/pages/Authenticated/Insights/InsightDateFilter';

export const InsightsHeader = () => {
  const { apps, filters, defaultFilters, handleDateChange, handleSelectApp } =
    useInsightsController();

  return (
    <HStack
      w="full"
      alignItems={['flex-start', 'flex-start', 'center']}
      justifyContent={['center', 'center', 'flex-start']}
      direction={['column', 'column', 'row']}
      space={4}
      bg={'gray.50'}
      flexWrap={'wrap'}
      px={8}
      py={5}
      overflow={'hidden'}
    >
      <HStack alignItems={'center'} space={10} flex={1} w="full">
        <Text fontSize={['lg', 'lg', 'xl']} fontWeight={500}>
          Insights
        </Text>
        <AppSelectorFilter
          apps={apps}
          values={filters.apps}
          onChange={handleSelectApp}
        />
      </HStack>

      <InsightDateFilter
        key={defaultFilters?.periodType || ''}
        onChange={handleDateChange}
        defaultSelectedDates={
          defaultFilters
            ? {
                from: new Date(defaultFilters.from || ''),
                to: new Date(defaultFilters.to || ''),
                periodType: defaultFilters.periodType,
              }
            : undefined
        }
      />
    </HStack>
  );
};
