import { Checkbox, Flex, Spacer, Stack, Text } from 'native-base';
import Icon from 'ui/components/Icon';
import { colors } from 'ui/theme/colors';

import { EcosystemVisibility } from '~/enums';

import { style } from './style';
import { IEcosystemItemHeaderProps } from './types';

const CHECKBOX_SELECT_ALL_VALUE = 'all';

export const EcosystemItemHeader = ({
  name,
  visibility,
}: IEcosystemItemHeaderProps) => {
  return (
    <Flex w="full" flexDir="row" justifyContent="space-between">
      <Flex w="full" flexDir="row" align="center" mb="6">
        <Text size="md" fontWeight="medium">
          {name}
        </Text>
        <Flex
          flexDir="row"
          ml="4"
          align="center"
          marginRight={'4'}
          alignSelf={'flex-start'}
        >
          <Icon
            icon={visibility === EcosystemVisibility.Private ? 'lock' : 'eye'}
            color={colors.gray[500]}
            size="18"
          />
          <Text ml="1" color="gray.500">
            {visibility === EcosystemVisibility.Private ? 'Private' : 'Public'}
          </Text>
        </Flex>
        <Spacer />
        {/* <Stack alignSelf={'flex-start'}>
          <Checkbox
            _text={style.checkboxText}
            value={CHECKBOX_SELECT_ALL_VALUE}
            onChange={onSelectAll}
          >
            Select ecosystem
          </Checkbox>
        </Stack> */}
      </Flex>
    </Flex>
  );
};
