import { Stack, VStack } from 'native-base';
import { Controller } from 'react-hook-form';
import FormHeader from 'ui/components/FormBuilderV2/components/FormHeader';
import Input from 'ui/components/Input';
import { PickColorInput } from 'ui/components/PickColorInput';
import { ImagePicker } from 'ui/components/v2/ImageInputs/ImagePicker';

import { ComposeFormSectionProps } from './types';

export const ComposeFormSection = ({
  control,
  formState,
}: ComposeFormSectionProps) => {
  const inputTitleLength = control._getWatch('title')?.length || 0;
  const inputMessageLength = control._getWatch('message')?.length || 0;

  return (
    <Stack justifyContent="center" w="100%" alignItems="center" p={6}>
      <VStack w="620px">
        <FormHeader
          title="Compose"
          subTitle="Compose and preview your invitation message."
        />

        <Stack mt={4} space={4}>
          <Input
            name="title"
            placeholder="Title"
            label="Title"
            control={control}
            errorMessage={formState?.errors?.title?.message}
            helperText={`${inputTitleLength}/85`}
          />
          <Input
            name="message"
            placeholder="Message"
            label="Message"
            control={control}
            errorMessage={formState?.errors?.message?.message}
            numberOfLines={8}
            multiline
            helperText={`${inputMessageLength}/500`}
          />
          <PickColorInput
            control={control}
            inputProps={{
              label: 'Brand color',
              name: 'brandColor',
              placeholder: 'Brand color',
              errorMessage: formState?.errors?.brandColor?.message,
            }}
          />
        </Stack>
        <Stack mt={8}>
          <FormHeader
            title="Images"
            subTitle="Add images to customize the invitation layout."
          />
          <Stack mt={4} maxW="20%">
            <Controller
              name="logo"
              control={control}
              render={({ field: { onChange, value } }) => (
                <ImagePicker.Base
                  src={value as string}
                  circularCrop
                  aspectRatio={1}
                  onChange={onChange}
                  cropModalTitle="Adjust your logo image"
                  submitLabel="Upload image"
                  label="Logo"
                />
              )}
            />
          </Stack>
          <Stack mt={8}>
            <Controller
              name="backgroundImage"
              control={control}
              render={({ field: { onChange, value } }) => {
                return (
                  <ImagePicker.DropArea
                    aspectRatio={3}
                    src={value || ''}
                    onChange={onChange}
                    cropModalTitle="Adjust your Background image"
                    submitLabel="Change cover image"
                    label="Background image"
                  />
                );
              }}
            />
          </Stack>
        </Stack>
      </VStack>
    </Stack>
  );
};
