import { Avatar, Divider, HStack, Pressable, Text, VStack } from 'native-base';
import Button from 'ui/components/Button';
import Icon from 'ui/components/Icon';

import { colors } from '~/theme/colors';

import { AppDetailsProps } from './types';
import { useAppDetailsController } from './useAppDetailsController';

export const AppDetails = ({
  app,
  isPicked,
  onGetApp,
  onGoBack,
}: AppDetailsProps) => {
  const { numberOfLines, organization, handleReadMore } =
    useAppDetailsController(app.organization);

  return (
    <VStack
      rounded="xl"
      borderWidth="1"
      borderColor="gray.200"
      bg="white"
      p="6"
      space="6"
    >
      <HStack justifyContent="space-between" alignItems="center">
        <Pressable flexDir="row" alignItems="center" onPress={onGoBack}>
          <Icon icon="chevron-left" size="16" color={colors.singletons.black} />
          <Text ml="2">Back</Text>
        </Pressable>
        <HStack alignItems="center" space="4">
          {/*TODO: Add when we have the app page */}
          {/* <Button
            w="40"
            minW="auto"
            leftIconName="external-link"
            leftIconColor={colors.black}
            variant="ghost"
          >
            View app page
          </Button> */}
          <Button
            w="10"
            leftIconName="download"
            bgColor="primary.400"
            isDisabled={isPicked}
            onPress={() => onGetApp(app)}
          >
            {isPicked ? 'Added' : 'Get app'}
          </Button>
        </HStack>
      </HStack>
      <HStack space="8">
        <Avatar w="128" height="128" rounded="3xl" source={{ uri: app.logo }} />
        <VStack flex="1" space="6">
          <Text fontSize="2xl">{app.name}</Text>
          <Divider />
          <VStack space="2">
            <Text color="gray.600" fontSize="md" fontWeight="500">
              Owner
            </Text>
            <HStack space="3" alignItems="center">
              <Avatar
                rounded="sm"
                w="24px"
                h="24px"
                source={{ uri: organization?.logo }}
              />
              <Text color="gray.600">{organization?.name}</Text>
            </HStack>
          </VStack>
          <Divider />
          <VStack space="2">
            <Text fontSize="md" color="gray.600" fontWeight="500">
              Description
            </Text>
            <Text color="gray.600" numberOfLines={numberOfLines}>
              {app.description ?? ''}
            </Text>
            <Pressable
              flexDir="row"
              alignItems="center"
              alignSelf="center"
              mt="3"
              onPress={handleReadMore}
            >
              <Text mr="2">
                {numberOfLines === 3 ? 'Read more' : 'Show less'}
              </Text>
              {numberOfLines === 3 ? (
                <Icon icon="chevron-down" size="18" color={colors.gray[800]} />
              ) : (
                <Icon icon="chevron-up" size="18" color={colors.gray[800]} />
              )}
            </Pressable>
          </VStack>
        </VStack>
      </HStack>
    </VStack>
  );
};
