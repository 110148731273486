import { MeasurementCategory } from 'ui/enums';
import { sanitizeQueryParams } from 'ui/utils/queryParams';

import { api } from '~/services/api';
import {
  INSIGHT_MOST_COMPLETED_ACTIVITIES_URL,
  INSIGHT_SPACE_OPTIONS_BASE_URL,
  INSIGHT_TRENDING_BASE_URL,
} from '~/services/resources/insights/constants';
import { BaseInsightQueryParams } from '~/services/resources/types';

import {
  GetMostCompletedActivitiesSummaryResponse,
  GetSpaceOptionsMetricsResponse,
  GetTrendingSummaryResponse,
} from './types';

export default class InsightsService {
  static async getTrendingSummaries(props: BaseInsightQueryParams) {
    const { organization, ...queryParams } = props;
    const url = INSIGHT_TRENDING_BASE_URL.replace(
      ':organizationId',
      organization,
    );

    const response = await api.get<GetTrendingSummaryResponse>(url, {
      params: sanitizeQueryParams(queryParams),
    });

    return response.data || {};
  }

  static async getSpaceOptionMetrics(
    props: BaseInsightQueryParams,
  ): Promise<GetSpaceOptionsMetricsResponse[]> {
    const { organization, ...queryParams } = props;
    const url = INSIGHT_SPACE_OPTIONS_BASE_URL.replace(
      ':organizationId',
      organization,
    );

    const response = await api.get<GetSpaceOptionsMetricsResponse[]>(url, {
      params: sanitizeQueryParams({
        ...queryParams,
        groupBy: ['SpaceOptions'],
      }),
    });

    return response?.data || [];
  }

  static async getMostCompletedActivitiesSummaries(
    props: BaseInsightQueryParams,
  ): Promise<GetMostCompletedActivitiesSummaryResponse[]> {
    const { organization, ...queryParams } = props;
    const url = INSIGHT_MOST_COMPLETED_ACTIVITIES_URL.replace(
      ':organizationId',
      organization,
    );

    const response = await api.get<GetMostCompletedActivitiesSummaryResponse[]>(
      url,
      { params: sanitizeQueryParams(queryParams) },
    );

    return response?.data || [];
  }
}
