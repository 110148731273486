import { VStack } from 'native-base';
import FormBuilderV2 from 'ui/components/FormBuilderV2';

import { useMediaFormController } from './controller';
import { MediaFormProps } from './types';

export const MediaForm = (props: MediaFormProps) => {
  const { handleContinue, defaultValues, formSchema, isLoading } =
    useMediaFormController(props);
  return (
    <VStack w="100%" space={4}>
      <VStack w="full">
        <FormBuilderV2
          options={formSchema}
          onSubmit={handleContinue}
          defaultValues={defaultValues}
          isLoadingSubmit={isLoading}
        />
      </VStack>
    </VStack>
  );
};
