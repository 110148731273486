import { ForwardedRef, useImperativeHandle, useState } from 'react';

import { App } from '~/types/interfaces/app';

export const useAddAppModalController = (
  ref: ForwardedRef<any>,
  onClose: () => void,
  onDone?: () => void,
  pickedApps?: App[],
) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedApp, setSelectedApp] = useState<App | null>(null);

  useImperativeHandle(
    ref,
    () => ({
      open() {
        setIsOpen(true);
      },
      close() {
        setIsOpen(false);
      },
    }),
    [setIsOpen],
  );

  const handleGoBack = () => setSelectedApp(null);

  const handleOnViewApp = (app: App) => setSelectedApp(app);

  const handleOnClose = () => {
    setIsOpen(false);
    onClose();
    setSelectedApp(null);
  };

  const handleOnDone = () => {
    if (onDone) onDone();
    setIsOpen(false);
  };

  const isPickedAppDetails =
    selectedApp &&
    pickedApps?.find((pickedApp) => pickedApp._id === selectedApp._id);

  return {
    isOpen,
    selectedApp,
    handleGoBack,
    handleOnClose,
    handleOnDone,
    handleOnViewApp,
    isPickedAppDetails,
  };
};
