import { toast } from 'react-toastify';
import { ImageZoneImageItem } from 'ui/components/Dropzone/ImageZoneMini/types';
import { isBase64Image, isUrlImage } from 'ui/utils/validations/url';

import { ORGANIZATION_MESSAGES } from '~/constants/messages.constants';
import { UploadsSignedURLObjectTarget } from '~/services/resources/types';
import { uploadImageFromBase64 } from '~/utils/uploadLogoIfProvided';

import { uploadImageToS3 } from './uploadImageToS3';

export async function generateCarouselImagesURLsArrayForFiles(
  carouselImages: ImageZoneImageItem[],
) {
  const carouselImagesURLs: string[] = [];

  if (carouselImages?.length) {
    try {
      for (const carouselImage of carouselImages) {
        let imageUrl = '';

        if (carouselImage?.isFile) {
          imageUrl = await uploadImageToS3(
            [carouselImage.imageFile as File],
            'Extra images',
          );

          carouselImagesURLs.push(imageUrl);
        }
      }
      return carouselImagesURLs;
    } catch {
      toast.error(ORGANIZATION_MESSAGES.ERROR_ON_UPLOAD);
      return [];
    }
  } else {
    return [];
  }
}

export const handleCarouselImagesForActivityEdit = async (
  carouselImages: ImageZoneImageItem[],
) => {
  if (carouselImages.length === 0) {
    return [];
  }
  const carouselImageHasNotChanged = carouselImages?.every(
    (carouselImage) => carouselImage.imageUrl,
  );

  if (!carouselImageHasNotChanged) {
    const onlyImagesWithFile = carouselImages.filter(
      (carouselImage) => carouselImage.isFile,
    );

    const onlyImagesWithUrl = carouselImages.filter(
      (carouselImage) => !carouselImage.isFile,
    );

    const existingUrls = onlyImagesWithUrl.map(
      (carouselImage) => carouselImage.imageUrl,
    );

    const newImagesUrls = await generateCarouselImagesURLsArrayForFiles(
      onlyImagesWithFile,
    );

    const carouselImagesUrl = [...existingUrls, ...newImagesUrls];

    if (carouselImagesUrl.length > 0) {
      return carouselImagesUrl;
    }

    return undefined;
  }

  const carouselImagesUrl = carouselImages.map(
    (carouselImage) => carouselImage.imageUrl,
  );

  if (carouselImagesUrl.length > 0) {
    return carouselImagesUrl;
  }

  return undefined;
};

export const handleActivityCoverImage = async (
  coverImage: string | undefined,
  thumbnailCoverImage: string | undefined,
) => {
  let coverImageURL;
  let thumbnailImageURL;

  if (!!coverImage && isBase64Image(coverImage)) {
    coverImageURL = await uploadImageFromBase64(
      coverImage,
      UploadsSignedURLObjectTarget.ActivityPicture,
      'Cover image',
      { maxWidth: 1200 },
    );

    thumbnailImageURL = await uploadImageFromBase64(
      coverImage,
      UploadsSignedURLObjectTarget.ActivityPicture,
      'Cover image',
      { maxWidth: 480 },
    );
  } else {
    if (isUrlImage(coverImage || '')) {
      coverImageURL = coverImage;
    }
    if (isUrlImage(thumbnailCoverImage || '')) {
      thumbnailImageURL = thumbnailCoverImage;
    }
  }

  return { coverImageURL, thumbnailImageURL };
};
