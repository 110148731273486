import CSVFileValidator, { RowError } from 'csv-file-validator';
import { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { CustomFile } from 'ui/components/Dropzone/types';

import { IMPORT_FILE_MESSAGES } from '~/constants/messages.constants';
import { PAGES } from '~/constants/pages.constants';
import { useAppSelector } from '~/hooks/useAppSelector';
import { useQuery } from '~/hooks/useQuery';
import { useRouter } from '~/hooks/useRouter';
import {
  csvPlacesHeadersValidator,
  legacyCsvPlacesHeadersValidator,
  PLACES_TEMPLATE_GOOGLE_SHEET_URL,
} from '~/pages/Authenticated/BulkUploads/PlacesUpload/utils';
import {
  EImportFileType,
  IImportFile,
} from '~/pages/Authenticated/BulkUploads/types';
import { ImportFilesServices } from '~/services/resources/import-files';
import { UploadsSignedURLObjectTarget } from '~/services/resources/types';

import { useLoadImportProgress } from '../../../../hooks/useLoadImportProgress';
import { fileContainsString } from '../../../../utils/file/fileContainsString';

const PROGRESS_INTERVAL = 2500;

export const usePlacesUploadController = () => {
  // Providers
  const { control } = useForm();
  const { params, goToRoute } = useRouter();
  const { organizationSelectedId } = useAppSelector(({ auth }) => auth);

  // States
  const [refetchQuantity, setRefetchQuantity] = useState(0);
  const [uploadIsLoading, setUploadIsLoading] = useState(false);
  const [currentFile, setCurrentFile] = useState<CustomFile[]>();
  const [selectedImportFileId, setSelectedImportFileId] = useState<string>();
  const [csvValidationErrors, setCsvValidationErrors] = useState<RowError[]>();

  // Hooks
  const { data: defaultImportFile, isLoading: isDefaultImportFileLoading } =
    useQuery<IImportFile>(`import-file/${params.id}`, {
      queryOptions: {
        enabled: !!params?.id,
      },
    });

  const { data: progressValue } = useLoadImportProgress(
    params.id,
    PROGRESS_INTERVAL,
  );

  const {
    data: activityDefinitionImportFiles,
    isLoading: isActivityDefinitionImportFilesLoading,
  } = useQuery<{ data: IImportFile[] }>(`import-file`, {
    queryOptions: { enabled: !!organizationSelectedId },
    requestOptions: {
      params: {
        filter: JSON.stringify({
          organization: organizationSelectedId,
          importFileType: EImportFileType.ActivityDefinition,
        }),
        limit: 100,
      },
    },
  });

  useEffect(() => {
    if (!defaultImportFile) return;
    setSelectedImportFileId(defaultImportFile.activityDefinitionImportFile);
  }, [defaultImportFile]);

  const relatedActivityImportFile = useMemo(() => {
    if (!selectedImportFileId) return null;
    const importFile = (activityDefinitionImportFiles?.data ?? []).find(
      (importFile: IImportFile) => importFile._id === selectedImportFileId,
    );
    return importFile ?? null;
  }, [selectedImportFileId, activityDefinitionImportFiles]);

  const validateCSV = async (
    file: CustomFile,
  ): Promise<{ errors: boolean; isLegacyTemplate: boolean }> => {
    try {
      const isLegacyTemplate = !(await fileContainsString(file, 'regions'));

      const validator = isLegacyTemplate
        ? legacyCsvPlacesHeadersValidator
        : csvPlacesHeadersValidator;

      const { inValidData, data } = await CSVFileValidator(file, {
        headers: validator,
      });

      const onlyHeaderErrors: RowError[] = inValidData.filter(
        ({ rowIndex }) => rowIndex === 1,
      );

      if (data.length === 0) {
        onlyHeaderErrors.push({ message: 'File is empty' });
        return { errors: true, isLegacyTemplate };
      }

      if (onlyHeaderErrors.length > 0) {
        setCsvValidationErrors(onlyHeaderErrors);
        return { errors: true, isLegacyTemplate };
      }

      return { errors: false, isLegacyTemplate };
    } catch (error) {
      console.log(error);
      toast.error('Error on validate file.');
      return { errors: true, isLegacyTemplate: false };
    }
  };

  const uploadPlaceFile = async () => {
    setUploadIsLoading(true);
    try {
      if (!currentFile?.length || !relatedActivityImportFile) {
        toast.error(IMPORT_FILE_MESSAGES.PLACES_INPUTS_MUST_NOT_BE_EMPTY);
        return;
      }

      const organizationId = relatedActivityImportFile.organization;
      const app = relatedActivityImportFile.app;
      const publishedApps = relatedActivityImportFile.publishedApps ?? [];
      const ecosystemId = relatedActivityImportFile.ecosystem;

      const [file] = currentFile;
      const fileType = file.name.split('.').pop();

      if (fileType !== 'tsv') {
        throw new Error(IMPORT_FILE_MESSAGES.FILE_MUST_BE_TSV_ERROR);
      }
      const { errors, isLegacyTemplate } = await validateCSV(file);
      if (errors) return;

      const newImportFile = await ImportFilesServices.createImportFile({
        organizationId,
        ecosystemId,
        app,
        publishedApps,
        fileName: file.name,
        importFileType: EImportFileType.Activity,
        activityDefinitionImportFile: selectedImportFileId,
        isLegacy: isLegacyTemplate,
      });

      const signedURL = await ImportFilesServices.generatePreSignedAdminURL({
        Target: UploadsSignedURLObjectTarget.ImportActivity,
        FileExtension: 'tsv',
        ImportFileId: newImportFile._id,
      });

      await ImportFilesServices.uploadFileToS3({
        file: file,
        signedURL,
      });

      goToRoute(`${PAGES.UPLOADS_PLACES}/${newImportFile._id}`);
    } catch (error: any) {
      if (error.message === IMPORT_FILE_MESSAGES.FILE_MUST_BE_TSV_ERROR) {
        toast.error(IMPORT_FILE_MESSAGES.FILE_MUST_BE_TSV_ERROR);
        return;
      } else if (error.message === 'RELATED_UPLOAD_NOT_COMPLETED') {
        toast.error(IMPORT_FILE_MESSAGES.RELATED_UPLOAD_NOT_COMPLETED);
        return;
      }
      toast.error(IMPORT_FILE_MESSAGES.UPLOAD_ERROR);
    } finally {
      setUploadIsLoading(false);
    }
  };

  const refreshImports = async () => {
    setRefetchQuantity((quantity) => quantity + 1);
  };

  const handleDropFile = (
    onChange: (file: CustomFile[]) => void,
    files: CustomFile[],
    isDisabled: boolean,
  ) => {
    if (isDisabled) return;
    onChange(files);
    setCurrentFile(files);
    setCsvValidationErrors(undefined);
  };

  const handleRemoveFile = (isDisabled: boolean) => {
    if (isDisabled) return;
    setCurrentFile(undefined);
    setCsvValidationErrors(undefined);
  };

  const handleDownloadTemplate = () => {
    const link = document.createElement('a');
    link.href = PLACES_TEMPLATE_GOOGLE_SHEET_URL;
    link.setAttribute('target', '_blank');
    document.body.appendChild(link);
    link.click();
  };

  const activityDefinitionOptionList = useMemo(() => {
    return (activityDefinitionImportFiles?.data ?? [])?.map(
      (fileImport: any) => {
        return {
          label: fileImport?.key || '-',
          value: fileImport._id,
        };
      },
    );
  }, [activityDefinitionImportFiles]);

  const isViewMode = useMemo(() => {
    if (!params || !params.id) return false;
    return true;
  }, [params]);

  const isDropAreaDisabled = useMemo(() => {
    return !!uploadIsLoading || !selectedImportFileId;
  }, [uploadIsLoading, selectedImportFileId]);

  return {
    control,
    currentFile,
    uploadPlaceFile,
    refreshImports,
    uploadIsLoading,
    selectedImportFileId,
    setSelectedImportFileId,
    csvValidationErrors,
    defaultImportFile,
    isDefaultImportFileLoading,
    refetchQuantity,
    handleDropFile,
    handleRemoveFile,
    handleDownloadTemplate,
    activityDefinitionOptionList,
    isViewMode,
    isDropAreaDisabled,
    activityDefinitionImportFiles,
    isActivityDefinitionImportFilesLoading,
    relatedActivityImportFile,
    goToRoute,
    progressValue,
  };
};
