import { HStack, Image, Stack, Text } from 'native-base';
import Button from 'ui/components/Button';
import Icon from 'ui/components/Icon';
import { AppVisibility } from 'ui/enums';

import { colors } from '~/theme/colors';

import { useAppsAccessCardController } from './controller';
import { AppsAccessCardProps } from './types';

export const AppsAccessCard = ({ app }: AppsAccessCardProps) => {
  const { handleGoToManageApps } = useAppsAccessCardController();
  return (
    <Stack
      bgColor="gray.50"
      height="120px"
      padding={5}
      space={3}
      borderRadius="xl"
      borderColor="gray.200"
      borderWidth="1px"
    >
      <HStack alignItems="center" space={3}>
        <Image
          width="48px"
          height="48px"
          alt="App logo"
          borderRadius="md"
          source={{
            uri: app?.logo,
          }}
        />
        <Text fontWeight={500} fontSize="sm" color="darkText">
          {app?.name || ''}
        </Text>
      </HStack>
      <HStack justifyContent="space-between" alignItems="center">
        <HStack space={2}>
          <Icon
            icon={app?.visibility === AppVisibility.Private ? 'lock' : 'eye'}
            color={colors.gray[500]}
            size={16}
          />
          <Text fontSize="xs" color="gray.500" fontWeight={500}>
            {app?.visibility === AppVisibility.Private ? 'Private' : 'Public'}
          </Text>
        </HStack>
        <Button
          leftIconName="external-link"
          leftIconColor={colors.primary[400]}
          leftIconSize={16}
          variant="link"
          onPress={() => handleGoToManageApps(app?._id || '')}
        >
          View app
        </Button>
      </HStack>
    </Stack>
  );
};
