import { AnimatePresence, motion } from 'framer-motion';
import { Avatar, HStack, Pressable, Stack, Text } from 'native-base';
import Icon from 'ui/components/Icon';
import { colors } from 'ui/theme/colors';
import { getAvatarText } from 'ui/utils/getAvatarText';

import { AppSelectItemProps } from './types';

export const AppSelectItem = ({
  label = '',
  imageUrl,
  isActive,
  onPress,
  showIcon = false,
  showLabel = false,
}: AppSelectItemProps) => {
  const backgroundColor = isActive ? colors.gray[200] : 'transparent';

  return (
    <Pressable onPress={onPress} borderRadius={12}>
      {/* decorator */}
      <AnimatePresence>
        {isActive && (
          <motion.div
            style={{
              left: 0,
              top: 0,
              width: '100%',
              height: '100%',
              borderWidth: 2,
              position: 'absolute',
              backgroundColor: backgroundColor,
              borderRadius: 8,
            }}
            initial={{ opacity: 0, scale: 0, y: 100 }}
            animate={{ opacity: 1, scale: 1, y: 0 }}
            transition={{ duration: 0.4, ease: 'easeInOut' }}
            exit={{ opacity: 0, scale: 0, y: 100 }}
          />
        )}
      </AnimatePresence>

      {/* content */}
      <HStack p={1} space={3} alignItems={'center'}>
        <Stack
          w="8"
          h="8"
          borderWidth={1}
          borderRadius={8}
          borderColor={'gray.300'}
          backgroundColor={'white'}
          overflow={'hidden'}
          alignItems={'center'}
          justifyContent={'center'}
        >
          {showIcon ? (
            <Icon icon="grid" size={20} color={colors.primary[400]} />
          ) : (
            <Avatar
              h="full"
              w="full"
              bgColor="muted.100"
              _text={{ color: 'darkText' }}
              source={{ uri: imageUrl || '' }}
              borderRadius={0}
              _image={{ borderRadius: 0 }}
            >
              {getAvatarText(label)}
            </Avatar>
          )}
        </Stack>

        {showLabel && (
          <Text fontSize={'sm'} fontWeight={500} mr={2}>
            {label}
          </Text>
        )}
      </HStack>
    </Pressable>
  );
};
