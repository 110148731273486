import FeatherIcon from 'feather-icons-react';
import {
  Box,
  FormControl,
  HStack,
  Input,
  Pressable,
  Stack,
  Text,
} from 'native-base';
import { Controller } from 'react-hook-form';
import FormError from 'ui/components/Form/FormError';
import Icon from 'ui/components/Icon';

import useTagGroupController from './controller';
import { TagGroupProps } from './types';

const TagGroup = (props: TagGroupProps) => {
  const {
    label,
    placeholder,
    tags,
    rightIconName,
    rightIconColor,
    rightIconSize,
    errorMessage,
    isRequired,
    name,
    isInvalid,
    isDisabled,
    hasError,
    iconErrorMessage,
    iconErrorSize,
    tagIconName,
    tagIconSize,
    tagIconColor,
    tagColor,
    currentTag,
    control,
    maxLength,
    helperText,
    handleAddTag,
    handlePress,
    handleRemoveTag,
    handleSetCurrentTag,
  } = useTagGroupController(props);

  return (
    <Box>
      <FormControl
        isInvalid={hasError || errorMessage ? true : isInvalid}
        isRequired={isRequired}
        isDisabled={isDisabled}
      >
        <Stack space="2">
          <FormControl.Label>
            <Text color="gray.600" fontSize={14} fontWeight="500">
              {label}
            </Text>
          </FormControl.Label>
          <Controller
            control={control}
            name={name}
            defaultValue={tags}
            render={({ field: { onChange, onBlur } }) => (
              <>
                <Input
                  value={currentTag}
                  onChange={handleSetCurrentTag}
                  maxLength={maxLength}
                  onKeyPress={(e) => {
                    handlePress(e, onChange);
                    onBlur();
                  }}
                  placeholder={placeholder}
                  isDisabled={isDisabled}
                  InputRightElement={
                    rightIconName ? (
                      <Pressable
                        onPress={() => {
                          onChange(handleAddTag());
                          onBlur();
                        }}
                      >
                        <Box mr={2}>
                          <FeatherIcon
                            icon={rightIconName}
                            size={rightIconSize}
                            color={rightIconColor}
                          />
                        </Box>
                      </Pressable>
                    ) : undefined
                  }
                />
                <FormControl.HelperText>{helperText}</FormControl.HelperText>
                <HStack space="1" mt="2" flexWrap="wrap">
                  {tags?.map((tag: string) => (
                    <Stack key={tag} m="1">
                      <Pressable
                        onPress={() => {
                          onChange(handleRemoveTag(tag));
                          onBlur();
                        }}
                      >
                        <HStack
                          borderColor={tagColor}
                          borderWidth={1}
                          px="4"
                          py="2"
                          space="2"
                          rounded="xl"
                          alignItems="center"
                        >
                          <Text color={tagColor} fontSize={12} fontWeight="500">
                            {tag}
                          </Text>
                          <Icon
                            icon={tagIconName}
                            color={tagIconColor}
                            size={tagIconSize}
                          />
                        </HStack>
                      </Pressable>
                    </Stack>
                  )) || null}
                </HStack>
              </>
            )}
          />

          <FormError
            errorMessage={errorMessage}
            iconErrorMessage={iconErrorMessage}
            iconErrorSize={iconErrorSize}
          />
        </Stack>
      </FormControl>
    </Box>
  );
};

export default TagGroup;
