import { Divider, Stack, Text } from 'native-base';
import { Control, FieldValues } from 'react-hook-form';
import Button from 'ui/components/Button';
import Input from 'ui/components/Input';
import TextArea from 'ui/components/TextArea';

import { IStepsMetrics } from './types';

export const StepsMetrics = ({
  control,
  fields,
  addOneForm,
  isEditing,
  errors,
}: IStepsMetrics) => {
  return (
    <>
      {fields?.map((field, index) => (
        <Stack key={field.id} mt={5}>
          <Stack>
            <Text fontSize="xl" fontWeight={500} color="gray.600">
              Step {index + 1}
            </Text>
            <Divider my={4} />
          </Stack>
          <Stack space={5}>
            <Stack key={`steps[${index}].title`} w="100%" my={4} zIndex={9999}>
              <Input
                name={`steps[${index}].title`}
                label="Title"
                isRequired
                control={control}
                placeholder="Type the title of the step (60 characters max)."
                maxLength={60}
                helperText="Specify how many kilograms volunteers need to achieve."
                errorMessage={errors?.steps?.[index]?.message}
                isDisabled={isEditing}
              />
            </Stack>
            <Stack>
              <TextArea
                name={`steps[${index}].description`}
                label="Description (Optional)"
                isRequired={false}
                control={control}
                errorMessage={errors?.steps?.[index]?.message}
                maxLength={140}
                placeholder={
                  'Tell volunteers about the task they need to do (140 characters max).'
                }
                isDisabled={isEditing}
              />
            </Stack>
          </Stack>
        </Stack>
      ))}

      <Stack mt={5} w="full" mb={4}>
        <Button
          variant="outline"
          py="4"
          leftIconName="plus-circle"
          leftIconColor="black"
          borderStyle="none"
          bgColor="gray.100"
          onPress={addOneForm}
          isDisabled={isEditing}
        >
          <Text color="black" fontWeight={500}>
            Add step
          </Text>
        </Button>
      </Stack>
    </>
  );
};
