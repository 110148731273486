import { Avatar, Spinner, Stack, Text } from 'native-base';
import Illustration, { ILLUSTRATIONS_NAME } from 'ui/components/Illustration';

import { CreatingConnectionProps } from '~/pages/Common/JoinAsPartner/types';
import { colors } from '~/theme/colors';

export const CreatingConnection = ({
  inviteLogo,
  brandColor = colors.gray[900],
}: CreatingConnectionProps) => {
  return (
    <Stack height="100vh" alignItems="center" justifyContent="center" space={5}>
      {inviteLogo ? (
        <Avatar source={{ uri: inviteLogo }} size="2xl" />
      ) : (
        <Illustration
          name={ILLUSTRATIONS_NAME.BLACK_DOIT_LOGO}
          width="200px"
          height="100px"
        />
      )}
      <Text
        textAlign="center"
        fontSize={{ base: 'md', lg: 'lg' }}
        width="400px"
      >
        Please wait, we are establishing your connection with the partner
        organisation.
      </Text>
      <Spinner size="lg" color={brandColor} />
    </Stack>
  );
};
