import {
  Avatar,
  Box,
  Divider,
  HStack,
  Pressable,
  Spinner,
  Stack,
  Text,
} from 'native-base';
import Button from 'ui/components/Button';
import DataTable from 'ui/components/DataTable';
import Icon from 'ui/components/Icon';
import ReadMore from 'ui/components/ReadMore';
import { AppVisibility } from 'ui/enums';

import { colors } from '~/theme/colors';

import { IEcosystem } from '../../../../types/interfaces/ecosystem';
import { tableColumns } from './tableColumns';
import { useAppController } from './useAppController';

export const AppDetails = () => {
  const { app, isLoadingApp, loadAppSharings, goBack, totalConnections } =
    useAppController();

  if (isLoadingApp) {
    return <Spinner mt="6" />;
  }

  return (
    <Stack w="full">
      <Pressable flexDir="row" alignItems="center" onPress={goBack}>
        <Icon icon="chevron-left" size="18" color={colors.black} />
        <Text ml="1">Back to Apps</Text>
      </Pressable>

      <HStack mt="6" space="6">
        <Text color="gray.500" fontSize="2xl" fontWeight={700}>
          {app?.name}
        </Text>
        <HStack alignItems="center" space="1">
          <Icon
            size="18"
            color={colors.gray[400]}
            icon={app?.visibility === AppVisibility.Private ? 'lock' : 'eye'}
          />
          <Text color="gray.400">
            {app?.visibility === AppVisibility.Private ? 'Private' : 'Public'}
          </Text>
        </HStack>
      </HStack>

      <HStack
        mt="10"
        space="6"
        alignItems="center"
        justifyContent="space-between"
      >
        <Stack space="2">
          <Text color="gray.600" fontSize="lg" fontWeight={400}>
            About the app
          </Text>
          <Text color="gray.500">Basic information about this app</Text>
        </Stack>
        <Box>
          {/* <Button
            leftIconName="edit"
            variant="outline"
            leftIconColor={colors.primary[600]}
            onPress={handleEditApp}
          >
            Edit
          </Button> */}
        </Box>
      </HStack>

      <Divider mt="3" />

      <HStack mt="8" space="10" w="full">
        <Stack space="4">
          <Text color="gray.600" fontSize="md" fontWeight={400}>
            Logo image
          </Text>
          <Avatar source={{ uri: app?.logo }} w="130px" height="130px" />
        </Stack>

        <Stack flex={1}>
          <Stack space="2" flex="1">
            <Text color="gray.600" fontSize="md" fontWeight={400}>
              Ecosystem (owner)
            </Text>
            <Text color={'black'} height="2.5rem">
              {(app?.ecosystem as IEcosystem)?.name}
            </Text>
          </Stack>
          <Stack space="2" flex="1">
            <Text color="gray.600" fontSize="md" fontWeight={400}>
              Description
            </Text>

            <ReadMore
              textHeight="70px"
              btnBgColor="white"
              btnTextColor="darkText"
              rightIconColor="darkText"
              text={app?.description ?? ''}
            />
          </Stack>

          {app?.termsWebpage && (
            <Stack>
              <Text color="gray.600" fontSize="md" fontWeight={400}>
                Terms of services
              </Text>

              <Stack flexDirection="row" alignItems={'center'}>
                <Icon icon="link" size="18" color={colors.gray[400]} />
                <Button variant="link" marginLeft="-8px">
                  {app?.termsWebpage}
                </Button>
              </Stack>
            </Stack>
          )}
        </Stack>
      </HStack>
      <HStack
        mt="10"
        space="6"
        alignItems="center"
        justifyContent="space-between"
      >
        <Stack space="2">
          <Text color="gray.600" fontSize="lg" fontWeight={400}>
            Connected to{' '}
            <Text color="gray.600" fontSize="lg" fontWeight={500}>
              {totalConnections}{' '}
              {totalConnections === 1 ? 'ecosystem' : 'ecosystems'}
            </Text>
          </Text>
          <Text color="gray.500">Ecosystems this app is connected to</Text>
        </Stack>
        <Box>
          {/* <Button
            leftIconName="edit"
            variant="outline"
            leftIconColor={colors.primary[600]}
            onPress={handleEditApp}
          >
            Edit
          </Button> */}
        </Box>
      </HStack>
      <Divider mt="3" />
      <Stack width="100%" pt="6">
        <DataTable loadData={loadAppSharings as any} columns={tableColumns()} />
      </Stack>
    </Stack>
  );
};
