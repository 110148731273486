// FIXME: @rovelcio: why aren't we using Intl.RelativeTimeFormat here?
// Would be easier to new Date() + seconds then formatting it...

export const secondsToHoursMinutesString = (totalSeconds: number) => {
  if (totalSeconds === 0) return '00h 00m';
  const hours = Math.floor(totalSeconds / 3600);
  const minutes = Math.floor((totalSeconds % 3600) / 60);

  const formattedHours = hours.toString().padStart(2, '0') + 'h';
  const formattedMinutes = minutes.toString().padStart(2, '0') + 'm';
  return `${formattedHours} ${formattedMinutes}`;
};

export const formatDateShort = (date: Date | string): string => {
  const dateObj = typeof date === 'string' ? new Date(date) : date;
  const intl = new Intl.DateTimeFormat('en-GB', {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
    timeZone: 'Europe/London',
  });
  return intl.format(dateObj);
};

export const browserTimezoneFormatDateShort = (date: Date | string): string => {
  const { timeZone, locale } = Intl.DateTimeFormat().resolvedOptions();
  const dateObj = typeof date === 'string' ? new Date(date) : date;
  const intl = new Intl.DateTimeFormat(locale, {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
    timeZone: timeZone,
  });
  return intl.format(dateObj);
};

export const formatDateLong = (date: Date | string): string => {
  const dateObj = typeof date === 'string' ? new Date(date) : date;
  const intl = new Intl.DateTimeFormat('en-GB', {
    weekday: 'long',
    day: '2-digit',
    month: 'long',
    year: 'numeric',
    timeZone: 'Europe/London',
  });
  return intl.format(dateObj);
};

export const formatDateShortWithTime = (date: Date | string): string => {
  const dateObj = typeof date === 'string' ? new Date(date) : date;
  const intl = new Intl.DateTimeFormat('en-GB', {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
    timeZone: 'Europe/London',
  });
  return intl.format(dateObj);
};
