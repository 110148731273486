import {
  Button,
  Checkbox,
  CheckIcon,
  Divider,
  HStack,
  MinusIcon,
  PresenceTransition,
  Spinner,
  Stack,
  Text,
  VStack,
} from 'native-base';
import { FieldErrors } from 'react-hook-form';
import Input from 'ui/components/Input';
import Radio from 'ui/components/Radio';
import { AdvertiseRegionOptionsEnum } from 'ui/enums';
import { colors } from 'ui/theme/colors';
import { ActivityType } from 'ui/types/activities';

import { ExternalApplyLinkInput } from '~/components/ExternalApplyLinkInput';
import { activityRegionOptions } from '~/constants/forms.constants';
import { IDatesDTO } from '~/types/dtos';

import { DatesForm } from '../DatesForm';
import { FromHomeProps } from './types';
import { useFromHomeController } from './useFromHomeController';

export const FromHome = ({
  activityType,
  isEditing = false,
  ...rest
}: FromHomeProps) => {
  const {
    control,
    advertiseRegions,
    setAdvertiseRegions,
    regionCheckboxFields,
    handleSelectMainRegion,
    handleSelectSubRegion,
    datesFields,
    addDateForm,
    appendDate,
    removeDateForm,
    errors,
    isLoading,
    isTeamEvent,
    isDisabledPublishButton,
    handleSubmitForm,
    handleBack,
    isLoadingRegions,
    setValue,
  } = useFromHomeController({
    activityType,
    ...rest,
  });

  return (
    <VStack>
      <Stack
        my={4}
        w="100%"
        flexDirection="row"
        justifyContent="space-between"
        alignItems="center"
        space={3}
      >
        <Stack w="100%" mt={activityType != ActivityType.Action ? '6' : '0'}>
          <Stack
            mb="10"
            p={activityType != ActivityType.Action ? 6 : 0}
            borderRadius={activityType != ActivityType.Action ? 'xl' : 'none'}
            borderWidth={activityType != ActivityType.Action ? '1' : 0}
            borderColor={
              activityType != ActivityType.Action ? 'gray.200' : 'white'
            }
            bgColor={
              activityType !== ActivityType.Action
                ? colors.singletons.lightText
                : colors.white
            }
          >
            <Stack flex={1}>
              {activityType !== ActivityType.Action ? (
                <VStack>
                  <HStack
                    w="full"
                    alignItems="center"
                    justifyContent="space-between"
                  >
                    <Text color="gray.600" fontWeight="500" fontSize="18px">
                      From home
                    </Text>
                  </HStack>
                  <Divider my="10px" />
                </VStack>
              ) : null}
              <VStack space={8}>
                <VStack
                  space={1}
                  mb={activityType !== ActivityType.Action ? '0' : '6'}
                >
                  <Input
                    isFullWidth
                    label="Online link (optional)"
                    placeholder="Paste online link"
                    leftIconName="globe"
                    name="meetingLink"
                    control={control}
                  />
                  <Text fontSize="xs" color="gray.600">
                    If it is an online activity, please paste the link here.
                  </Text>
                </VStack>
                {[ActivityType.Action, ActivityType.Ongoing].includes(
                  activityType,
                ) ? (
                  <ExternalApplyLinkInput
                    control={control}
                    isEditing={isEditing}
                  />
                ) : (
                  <></>
                )}
                <Radio
                  label="Where do you want to advertise the opportunity?"
                  isRequired
                  control={control}
                  name="advertiseRegion"
                  flexDirection="row"
                  checkedColor={colors.primary[600]}
                  options={activityRegionOptions}
                  onChangeValue={(value) =>
                    setAdvertiseRegions(value as AdvertiseRegionOptionsEnum)
                  }
                />

                {advertiseRegions ===
                AdvertiseRegionOptionsEnum.SelectRegions ? (
                  !isLoadingRegions ? (
                    regionCheckboxFields.map((parentField) => {
                      return (
                        <VStack
                          key={parentField.mainRegionData.displayName}
                          space={2}
                        >
                          <Checkbox
                            icon={
                              parentField.isAllChecked ? (
                                <CheckIcon />
                              ) : (
                                <MinusIcon />
                              )
                            }
                            value={parentField.mainRegionData.displayName}
                            isChecked={
                              parentField.isAllChecked ||
                              parentField.isPartialSelected
                            }
                            onChange={() =>
                              handleSelectMainRegion(
                                parentField.mainRegionData.displayName,
                              )
                            }
                          >
                            {parentField.mainRegionData.displayName}
                          </Checkbox>

                          <PresenceTransition
                            visible={
                              parentField.childrenData.length > 0 &&
                              (parentField.isAllChecked ||
                                parentField.isPartialSelected)
                            }
                            style={{
                              width: '100%',
                            }}
                            initial={{ opacity: 0 }}
                            animate={{
                              opacity: 1,
                              transition: { duration: 300 },
                            }}
                            exit={{
                              opacity: 0,
                              transition: { duration: 300 },
                            }}
                          >
                            <VStack
                              display={
                                parentField.childrenData.length > 0 &&
                                (parentField.isAllChecked ||
                                  parentField.isPartialSelected)
                                  ? 'flex'
                                  : 'none'
                              }
                              mt={2}
                              ml={8}
                              space={4}
                            >
                              {parentField.childrenData.map((childrenField) => (
                                <Checkbox
                                  key={childrenField.label}
                                  value={childrenField.value}
                                  isChecked={childrenField.checked}
                                  onChange={() =>
                                    handleSelectSubRegion(childrenField.value)
                                  }
                                >
                                  {childrenField.label}
                                </Checkbox>
                              ))}
                            </VStack>
                          </PresenceTransition>
                        </VStack>
                      );
                    })
                  ) : (
                    <Spinner />
                  )
                ) : null}
              </VStack>
            </Stack>
          </Stack>
        </Stack>
      </Stack>
      {activityType === ActivityType.Event ? (
        <Stack w="100%">
          <Stack
            mb="10"
            p={6}
            borderRadius="xl"
            borderWidth={'1'}
            borderColor="gray.200"
            bgColor={colors.singletons.lightText}
          >
            <Stack flex={1}>
              <HStack
                w="full"
                alignItems="center"
                justifyContent="space-between"
              >
                <Text color="gray.600" fontWeight="500" fontSize="18px">
                  Dates
                </Text>
              </HStack>
              <Divider my="10px" />
              <DatesForm
                control={control as any}
                placeFormIndex={0}
                fields={datesFields as any}
                append={appendDate as any}
                addOneForm={addDateForm as any}
                removeOneForm={removeDateForm}
                errors={errors as FieldErrors<{ [key: string]: IDatesDTO[] }>}
                isLoading={isLoading}
                isTeamEvent={isTeamEvent}
                isEventActivity={activityType === ActivityType.Event}
                isEditing={isEditing}
                setValue={setValue}
              />
            </Stack>
          </Stack>
        </Stack>
      ) : null}
      <HStack>
        <Stack flex={1} ml={4}>
          <Button onPress={handleBack} flex={1} variant="ghost">
            <Text color="primary.600">Back</Text>
          </Button>
        </Stack>
        <Stack flex={1} ml={4}>
          <Button
            onPress={handleSubmitForm}
            flex={1}
            isDisabled={isDisabledPublishButton}
            isLoading={isLoading}
          >
            <Text color="white">Publish</Text>
          </Button>
        </Stack>
      </HStack>
    </VStack>
  );
};
