import { useEffect, useState } from 'react';

import { useAppDispatch } from '~/hooks/useAppDispatch';
import { useAppSelector } from '~/hooks/useAppSelector';
import { ecosystemSlice } from '~/store/slices/ecosystem';
import { IEcosystem } from '~/types/interfaces/ecosystem';

import { useLoadOrganizationEcosystems } from '../useLoadOrganizationEcosystems';
import { usePermissions } from '../usePermissions';

export const useSetEcosystemsToSelectOptions = () => {
  const [ecosystemMap, setEcosystemMap] = useState<Record<string, IEcosystem>>(
    {},
  );
  const { user } = useAppSelector(({ auth }) => auth);
  const { selectedEcosystem } = useAppSelector(({ ecosystem }) => ecosystem);
  const { allPartnerPermissionsFromAvailableApps } = useAppSelector(
    ({ permissions }) => permissions,
  );
  const { ecosystems } = useLoadOrganizationEcosystems();

  const ecosystemLogo = selectedEcosystem?.logo || '';
  const ecosystemName = selectedEcosystem?.name;

  const dispatch = useAppDispatch();
  const { handleUpdatePartnerPermissions } = usePermissions();

  const onChangeSelectedEcosystem = (ecosystemId: string) => {
    const selectedEcosystem = ecosystemMap[ecosystemId];

    if (selectedEcosystem) {
      dispatch(ecosystemSlice.actions.selectEcosystem(selectedEcosystem));
    }
  };

  useEffect(() => {
    if (ecosystems) {
      const map: Record<string, IEcosystem> = {};
      for (const ecosystem of ecosystems) {
        map[ecosystem._id] = ecosystem;
      }
      setEcosystemMap(map);
    }
  }, [ecosystems]);

  useEffect(() => {
    if (selectedEcosystem) {
      if (!allPartnerPermissionsFromAvailableApps[selectedEcosystem._id]) {
        handleUpdatePartnerPermissions(selectedEcosystem?._id);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedEcosystem]);

  return {
    ecosystemLogo,
    onChangeSelectedEcosystem,
    ecosystems,
    selectedEcosystem,
    ecosystemName,
  };
};
