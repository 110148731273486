import { FormControl, Spacer, Text, VStack } from 'native-base';
import Input from 'ui/components/Input';

import { useExternalApplyLinkInputController } from '~/components/ExternalApplyLinkInput/controller';
import { ExternalApplyLinkInputProps } from '~/components/ExternalApplyLinkInput/types';

export const ExternalApplyLinkInput = ({
  isTeamEvent = false,
  control,
  isEditing,
  name = 'externalApplyLink',
  onChange,
}: ExternalApplyLinkInputProps) => {
  const { field } = useExternalApplyLinkInputController(name, control);

  return (
    <VStack>
      <FormControl flex={0.5}>
        <Spacer my={1} />
        <FormControl.Label>
          External Application Link (optional)
        </FormControl.Label>
        {!isTeamEvent ? (
          <>
            <Text fontSize={'sm'} color="gray.500">
              We will refer the applicant to this link to finish their
              application to this activity.
            </Text>
            <Text fontSize={'sm'} color="gray.500">
              Beware that providing this link will disable the application
              management in the Do IT platform for this activity(including
              volunteer limit).
            </Text>
            <Spacer my={1} />
            <Input
              control={control}
              leftIconName="globe"
              placeholder="URL"
              isDisabled={isTeamEvent || isEditing}
              helperText="You can only set the external application link when creating activities. It will not be possible to change it later."
              name={name}
              onChangeText={(value) => {
                if (onChange) {
                  onChange(value);
                  field.onChange(value);
                }
              }}
            />
          </>
        ) : (
          <Text fontSize={'sm'} color="gray.500">
            Unfortunately, team events are not compatible with external
            application links.
          </Text>
        )}
      </FormControl>
    </VStack>
  );
};
