import { Auth } from 'aws-amplify';
import axios, { AxiosHeaders } from 'axios';

import { DEFAULT_URL_UPLOADS_ADMIN_API } from '~/config';
export const adminUploadsApi = axios.create({
  baseURL: DEFAULT_URL_UPLOADS_ADMIN_API,
});

adminUploadsApi.interceptors.request.use(async (config) => {
  try {
    const currentSession = await Auth.currentSession();
    const idToken = currentSession.getIdToken().getJwtToken();
    (config.headers as AxiosHeaders).set('Authorization', `Bearer ${idToken}`);
    return config;
  } catch (error) {
    return config;
  }
});
