import { PAGES } from '~/constants/pages.constants';
import { useRouter } from '~/hooks/useRouter';

export const usePublicInviteSuccessController = () => {
  const { goToRoute, location } = useRouter();

  const inviteURL = location?.state?.inviteURL;

  const handleGoToMembersPage = () => goToRoute(PAGES.MEMBERS);

  return { handleGoToMembersPage, inviteURL };
};
