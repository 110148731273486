import { toast } from 'react-toastify';
import { compressImage } from 'ui/utils/compressImage';

import { DEFAULT_AWS_PUBLIC_BUCKET } from '~/config';
import { UPLOAD_IMAGE_ERROR } from '~/constants/messages.constants';
import { UploadsSignedURLObjectTarget } from '~/services/resources/types';
import { UploadsService } from '~/services/resources/uploads';

export const uploadImageToS3 = async (logo: File[], fieldName: string) => {
  let imageURL = '';

  if (logo?.length && logo[0]?.type) {
    try {
      const compressedImage = (await compressImage(logo[0])) as File;
      const signedURLResponse = await UploadsService.generatePreSignedURL({
        Target: UploadsSignedURLObjectTarget.OrganizationPicture,
        FileExtension: compressedImage.type.split('/').pop() || '',
      });

      imageURL = `${DEFAULT_AWS_PUBLIC_BUCKET}/${signedURLResponse.Key}`;

      await UploadsService.uploadFileToS3({
        file: compressedImage,
        signedURL: signedURLResponse,
      });
    } catch {
      toast.error(UPLOAD_IMAGE_ERROR(fieldName));
      throw new Error(UPLOAD_IMAGE_ERROR(fieldName));
    }
  }

  return imageURL;
};
