import { Avatar, HStack, Stack, Text } from 'native-base';
import { PoweredByDoit } from 'ui/components/v2/Labels/PoweredByDoit';
import { getNameInitials } from 'ui/utils/getNameInititals';

import { useAppDispatch } from '~/hooks/useAppDispatch';
import { useAppSelector } from '~/hooks/useAppSelector';
import { notificationSliceActions } from '~/store/slices/notification';
import { IOrganization } from '~/types/interfaces/organization';

export const useSwitchNotifyOrg = () => {
  const dispatch = useAppDispatch();

  const { user } = useAppSelector((state) => state.auth);

  const handleOrgSwitch = (org: IOrganization) => {
    dispatch(
      notificationSliceActions.notify({
        content: (
          <Stack maxW={300} width="100%">
            <Text fontSize="sm" color="gray.500" fontWeight={500}>
              Hi, {user?.name || ''} Welcome to!
            </Text>
            <HStack space={3} width="100%">
              <Avatar.Group>
                <Avatar source={{ uri: org?.logo }}>
                  {getNameInitials(user?.name || '')}
                </Avatar>
              </Avatar.Group>
              <Stack maxW="100%" width="100%" space={1} justifyContent="center">
                <Text
                  numberOfLines={1}
                  fontSize="md"
                  fontWeight={500}
                  color="darkText"
                >
                  {org?.name}
                </Text>
                <PoweredByDoit size="md" />
              </Stack>
            </HStack>
          </Stack>
        ),
      }),
    );
  };

  return { handleOrgSwitch };
};
