import { Modal as NBModal, Text, VStack } from 'native-base';
import { ImageCropper } from 'ui/components/v2/ImageInputs/ImageCropper';
import Modal from 'ui/components/v2/Modal';

import { ImageTransformModalProps } from './types';
export const ImageTransformModal = ({
  modalRef,
  src,
  onSubmit,
  aspectRatio = 1,
  circularCrop,
  maxSale,
  minScale,
  title = 'Crop image',
}: ImageTransformModalProps) => {
  return (
    <Modal ref={modalRef} width={'full'} maxWidth={'560px'}>
      {/* Modal Content */}
      <VStack
        w="full"
        backgroundColor={'white'}
        overflow={'hidden'}
        borderRadius={8}
      >
        {/* Header */}
        <NBModal.Header flexShrink={0}>
          <Text
            textAlign={'center'}
            color={'gray.600'}
            fontWeight={500}
            fontSize={'sm'}
          >
            {title}
          </Text>

          <NBModal.CloseButton />
        </NBModal.Header>

        {/* Content */}
        <VStack p={4} mt={2} w="full" alignItems={'center'} borderRadius={8}>
          <ImageCropper
            src={src}
            key={src}
            maxSale={maxSale}
            minScale={minScale}
            aspectRatio={aspectRatio}
            circularCrop={circularCrop}
            onSubmit={onSubmit}
          />
        </VStack>
      </VStack>
    </Modal>
  );
};

export default ImageTransformModal;
