import { HStack, Stack, Text, VStack } from 'native-base';
import Button from 'ui/components/Button';
import Icon from 'ui/components/Icon';

import { CopyInviteLinkComponent } from '~/pages/Authenticated/AddOrgMember/components/Form/InvitationFormSection/CopyInviteComponent';
import { colors } from '~/theme/colors';

import { usePublicInviteSuccessController } from './controller';

export const PublicInviteSuccess = () => {
  const { handleGoToMembersPage, inviteURL } =
    usePublicInviteSuccessController();

  if (!inviteURL) {
    return null;
  }

  return (
    <Stack
      w="100%"
      h="100%"
      position="fixed"
      left={0}
      right={0}
      overflowY="scroll"
      justifyContent="center"
      alignItems="center"
      bg="rgba(0,0,0,0.5)"
      padding={{ base: 5, md: 0 }}
    >
      <VStack
        w={{ base: '100%', md: '40%' }}
        pb={12}
        bg="white"
        borderRadius="xl"
        overflow="hidden"
      >
        <HStack
          pt={6}
          px={6}
          alignItems="center"
          justifyContent="space-between"
        >
          <Text fontSize="2xl" fontWeight="medium">
            Public invite
          </Text>
          <Button
            variant="ghost"
            minW="2rem"
            w="2rem"
            onPress={handleGoToMembersPage}
          >
            <Icon icon="x" size={24} color={colors.gray[400]} />
          </Button>
        </HStack>
        <Stack width="100%" pt={6} px={6}>
          <Text fontSize="md" color="gray.600">
            Your public link has been generated successfully.
          </Text>
          <CopyInviteLinkComponent link={inviteURL || ''} />
        </Stack>
      </VStack>
    </Stack>
  );
};
