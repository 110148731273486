import {
  Box,
  Checkbox as NBCheckbox,
  Divider,
  HStack,
  Stack,
  Text,
} from 'native-base';
import { Controller } from 'react-hook-form';
import Input from 'ui/components/Input';
import LocationAutoComplete from 'ui/components/v2/LocationInputs/LocationAutoComplete';
import { ILocation } from 'ui/types/interfaces';

import { ExternalApplyLinkInput } from '~/components/ExternalApplyLinkInput';
import { GooglePlacesAutoCompleteAdapter } from '~/services/google/google-places-auto-auto-complete';
import { colors } from '~/theme/colors';

import { SingleLocationOngoingFormProps } from './types';

const googlePlacesAutoComplete = GooglePlacesAutoCompleteAdapter.getSingleton();

export const SingleLocationOngoingForm = ({
  control,
  errors,
  isEditing = false,
  updateNoVolunteerLimitValue,
}: SingleLocationOngoingFormProps) => {
  const noVolunteerLimit = control._getWatch(`noVolunteerLimit`);
  const externalApplyLink = control._getWatch(`externalApplyLink`);

  return (
    <Stack w="100%" mt="10">
      <Stack
        mb="10"
        p={6}
        borderRadius="xl"
        borderWidth={'1'}
        borderColor="gray.200"
        bgColor={colors.singletons.lightText}
      >
        <Stack flex={1}>
          <HStack w="full" alignItems="center" justifyContent="space-between">
            <Text color="gray.600" fontWeight="500" fontSize="18px">
              Place
            </Text>
          </HStack>
          <Divider my="10px" />
        </Stack>

        <Stack flex={1} zIndex={1} mb={4}>
          <Controller
            name={`address`}
            control={control}
            render={({ field: { onChange, value } }) => (
              <LocationAutoComplete
                isRequired
                label="Full address"
                onChange={onChange}
                defaultValue={value as ILocation}
                provider={googlePlacesAutoComplete}
              />
            )}
          />
        </Stack>
        <Stack flexDir="row" space={4} mb={4}>
          <Box w="1/2" mt={4}>
            <Input
              label="Volunteers"
              isNumeric
              isRequired={true}
              placeholder="Type the limit number of volunteers"
              name={`volunteerNumber`}
              control={control}
              isDisabled={noVolunteerLimit}
              errorMessage={
                noVolunteerLimit ? '' : errors.volunteerNumber?.message
              }
            />
            <Stack ml={1} mt={2}>
              <Controller
                control={control}
                name={`noVolunteerLimit`}
                render={({ field: { onChange, value } }) => {
                  return (
                    <NBCheckbox
                      // Value is just a string without purpose, as the value is handled by the Controller
                      value={'noVolunteerLimitValue'}
                      isChecked={value}
                      onChange={(isSelected: boolean) => {
                        onChange(isSelected);
                        updateNoVolunteerLimitValue(isSelected);
                      }}
                      isDisabled={!!externalApplyLink}
                    >
                      <Text fontWeight={400} fontSize="sm" mx={1} mt={1}>
                        No volunteer limit
                      </Text>
                    </NBCheckbox>
                  );
                }}
              />
            </Stack>
          </Box>
        </Stack>
        <ExternalApplyLinkInput
          control={control}
          isEditing={isEditing}
          onChange={(value) => {
            updateNoVolunteerLimitValue(!!value);
          }}
        />
      </Stack>
    </Stack>
  );
};
