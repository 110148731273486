import { HStack, Stack } from 'native-base';
import { HexColorPicker } from 'react-colorful';

import { colors } from '../../theme/colors';
import Button from '../Button';
import Input from '../Input';
import { usePickColorInputController } from './controller';
import { PickColorInputProps } from './types';

export const PickColorInput = ({
  inputProps,
  control,
}: PickColorInputProps) => {
  const { label, name, placeholder, errorMessage } = inputProps;

  const {
    isColorPickerOpen,
    field,
    defaultValue,
    onInputChange,
    toggleColorPicker,
    onBrandColorChange,
  } = usePickColorInputController({
    control,
    inputProps,
  });

  return (
    <Stack space={6} width="100%">
      <Input
        name={name}
        label={label}
        labelColor={colors.gray[500]}
        placeholder={placeholder}
        onChangeText={onInputChange}
        control={control}
        onFocus={() => toggleColorPicker(true)}
        onBlur={() => toggleColorPicker(false)}
        errorMessage={errorMessage}
        leftElement={
          <Stack
            ml={2}
            width={6}
            height={6}
            bgColor={field.value}
            borderRadius="full"
          />
        }
      />
      {isColorPickerOpen ? (
        <Stack
          space={2}
          bgColor="white"
          p={4}
          borderWidth={1}
          borderRadius="xl"
          borderColor="gray.200"
        >
          <HexColorPicker
            style={{ width: '100%' }}
            onChange={(color) => onBrandColorChange(color)}
            color={field.value}
            defaultValue={defaultValue}
          />

          <HStack justifyContent="flex-end" space={2}>
            <Button
              onPress={() => toggleColorPicker(false)}
              variant="outline"
              minW="80px"
              maxW="80px"
            >
              Apply
            </Button>
          </HStack>
        </Stack>
      ) : null}
    </Stack>
  );
};
