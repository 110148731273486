import { useLocation, useNavigate } from 'react-router-dom';

import { VITE_DOIT_VOLUNTEER_URL } from '~/config';
import { useAppSelector } from '~/hooks/useAppSelector';
import { IActivityDefinition } from '~/types/interfaces/activity';

export const useOpportunitySuccessfulController = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const activity: IActivityDefinition = location.state?.activity;

  const activityUrl = `${VITE_DOIT_VOLUNTEER_URL}my-wallet/activities/${activity._id}`;

  const { selectedEcosystem } = useAppSelector(({ ecosystem }) => ecosystem);

  return { activity, activityUrl, selectedEcosystem, navigate };
};
