import {
  ITeam,
  ITeamPopulateEcosystem,
  TeamMemberStatus,
} from '../types/interfaces/team';
import { IUserProfile } from '../types/interfaces/user';

interface ParsedTeamMemberData {
  _id: string;
  name: string;
  avatarUrl?: string;
  phoneNumber?: string;
}

const getParsedLeaderData = (leaderSubDocument: IUserProfile) => {
  return {
    _id: leaderSubDocument._id,
    name: leaderSubDocument.userSummary.name,
    avatarUrl: leaderSubDocument.profilePicture,
    phoneNumber: leaderSubDocument.userSummary.phoneNumber,
  };
};

const getParsedMembersData = (membersSubDocument: IUserProfile[]) => {
  return membersSubDocument.map((member) => ({
    _id: member._id,
    name: member.userSummary.name,
    avatarUrl: member.profilePicture,
    phoneNumber: member.userSummary.phoneNumber,
  }));
};

// Method to get leader and members separated by status
export const getLeaderAndMembersByStatus = (
  teamSubDocument: ITeam | ITeamPopulateEcosystem,
  status: TeamMemberStatus,
): { leader: ParsedTeamMemberData; members: ParsedTeamMemberData[] } => {
  const { leaderSubDocument, membersSubDocument, membersStatuses } =
    teamSubDocument;

  const leader = getParsedLeaderData(leaderSubDocument);

  const filteredMembers = membersSubDocument.filter(
    (member) => membersStatuses[member._id] === status,
  );

  const members = getParsedMembersData(filteredMembers);

  return { leader, members };
};

export const getTeamMembersQuantity = (
  membersLength?: number,
  leaderName?: string,
) => {
  if (membersLength === 0) {
    if (leaderName) {
      return 1;
    }
  } else {
    if (leaderName && membersLength) {
      return membersLength + (leaderName ? 1 : 0);
    }
  }
};

export const getUserAcceptedTeams = (
  userProfileId: string,
  teamsList: ITeam[],
) => {
  return teamsList.filter((team) => {
    // if user is leader
    if (team.leader === userProfileId) {
      return team;
    }

    if (team.membersStatuses[userProfileId] === TeamMemberStatus.ACCEPTED) {
      return team;
    }
  });
};
