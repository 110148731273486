import {
  TableOperatorNames,
  TableOperatorType,
} from 'ui/components/DataTable/types';

export const defaultChildOrgActivitiesFilterValue = [
  {
    name: 'activity',
    operator: TableOperatorNames.StartsWith,
    type: TableOperatorType.String,
    value: '',
  },
  {
    name: 'organization',
    operator: TableOperatorNames.StartsWith,
    type: TableOperatorType.String,
    value: '',
  },
  {
    name: 'type',
    operator: TableOperatorNames.Equals,
    type: TableOperatorType.String,
    value: '',
  },
  {
    name: 'applications',
    operator: TableOperatorNames.GreaterThanOrEquals,
    type: TableOperatorType.Number,
    value: 0,
  },
  {
    name: 'members_amount',
    operator: TableOperatorNames.StartsWith,
    type: TableOperatorType.String,
    value: '',
  },
];
