import { Box, Flex, HStack, Stack, Text } from 'native-base';
import React from 'react';
import Button from 'ui/components/Button';
import Input from 'ui/components/Input';
import { colors } from 'ui/theme/colors';

import { MemberTypeEnum } from '~/enums';

import { IAddEmailProps } from './types';

export const AddEmailInputComponent = ({
  onAddEmail,
  onChange,
  value,
  isInvalid,
  errors,
  control,
  organization,
  memberType,
}: IAddEmailProps) => {
  const handleAppendEmail = () => {
    if (isInvalid) return;

    onAddEmail();
  };

  const hasExternalId =
    organization?.organizationSettings?.hasExternalId &&
    memberType === MemberTypeEnum.Member;

  return (
    <>
      <Box h="8" />
      <Text mb="3" fontSize="md" fontWeight="medium" color="gray.600">
        Invite by email
      </Text>
      <Flex
        flexDirection={hasExternalId ? 'column' : 'row'}
        alignItems="initial"
        style={{ gap: 20 }}
      >
        <Stack width={hasExternalId ? '100%' : '80%'}>
          <Input
            width="100%"
            control={control}
            value={value}
            name="employeeEmail"
            placeholder="Enter your employee's email address"
            onChange={onChange}
            onSubmitEditing={handleAppendEmail}
            errorMessage={errors?.userConfigurations?.message}
          />
        </Stack>
        <HStack width="100%" space={5}>
          {hasExternalId ? (
            <Stack width="77%">
              <Input
                width="100%"
                control={control}
                value={value}
                name="externalId"
                placeholder="Enter external ID for this user"
                onChange={onChange}
                onSubmitEditing={handleAppendEmail}
              />
            </Stack>
          ) : null}

          <Button
            borderColor="primary.600"
            leftIconName="plus-circle"
            leftIconColor={colors.green[600]}
            py="6"
            variant="outline"
            onPress={handleAppendEmail}
            isDisabled={isInvalid}
            minW="20%"
            maxW="20%"
          >
            <Text color="primary.600" fontSize={16} fontWeight="500">
              Add
            </Text>
          </Button>
        </HStack>
      </Flex>
    </>
  );
};
