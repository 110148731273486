import { FlatList, VStack } from 'native-base';
import { useEffect } from 'react';
import { useQuery } from 'react-query';
import { MeasurementCategory } from 'ui/enums';
import { defaultMeasurementUnits } from 'ui/utils/measurements/constants';
import { formatMeasurementAmount } from 'ui/utils/measurements/format';

import { InsightsGenericEmptyState } from '~/pages/Authenticated/Insights/components/EmptyState';
import { InsightScaffoldChildrenReactComponent } from '~/pages/Authenticated/Insights/components/InsightScaffold';
import {
  VolunteeringHourItem,
  VolunteeringHourItemSkeleton,
} from '~/pages/Authenticated/Insights/components/VolunteeringHours/VolunteeringHourItem';
import {
  InsightPageFilters,
  useInsightFilters,
} from '~/pages/Authenticated/Insights/useInsightFilters';
import { InsightCacheConfig } from '~/pages/Authenticated/Insights/utils';
import InsightMeasurementService, {
  GetMeasurementByUserReportQueryParams,
} from '~/services/resources/insightMeasurement';
import { InsightMeasurementGroupedBy } from '~/services/resources/types';

import { skeletonData } from './utils';

const buildQueryKey = (filters: InsightPageFilters) => {
  const params: GetMeasurementByUserReportQueryParams = {
    apps: filters.apps,
    ecosystem: filters.selectedEcosystemId,
    organization: filters.selectedOrganizationId,
    startDate: filters.from!,
    endDate: filters.to!,
    granularity: 'Daily',
    groupBy: [InsightMeasurementGroupedBy.UserProfile],
    measurementType: MeasurementCategory.Time,
  };
  return [InsightMeasurementService.MEASUREMENT_REPORT_KEY, params];
};

export const VolunteeringHoursEmployees: InsightScaffoldChildrenReactComponent =
  ({ onSetChart }) => {
    const { filters, hasFilterSetted } = useInsightFilters();

    const {
      data: response,
      isFetching,
      isLoading,
    } = useQuery({
      queryKey: buildQueryKey(filters),
      queryFn: async ({ queryKey }) => {
        const [, params] = queryKey;

        return await InsightMeasurementService.getMeasurementByUserReport(
          params as GetMeasurementByUserReportQueryParams,
        );
      },
      enabled: hasFilterSetted,
      ...InsightCacheConfig,
    });

    useEffect(() => {
      const flattenData = (response ?? []).map((item) => ({
        ...item.userProfile,
        profilePicture: item.userProfile.profilePicture || '',
        totalInSeconds: item.total,
        totalInHours: formatMeasurementAmount(
          item.total,
          defaultMeasurementUnits.hours,
        ),
      }));
      onSetChart(null, flattenData);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [response]);

    return (
      <VStack mb={6} flex={1}>
        <FlatList
          maxH={'420px'}
          height={'full'}
          scrollEnabled
          keyExtractor={(item, index) => `${item.userProfile._id}-${index}`}
          data={isLoading || isFetching ? skeletonData : response ?? []}
          renderItem={({ item, index }) =>
            isLoading || isFetching ? (
              <VolunteeringHourItemSkeleton />
            ) : (
              <VolunteeringHourItem
                hours={item.total}
                name={item.userProfile.name}
                avatar={item.userProfile.profilePicture}
                index={index}
              />
            )
          }
          ItemSeparatorComponent={() => <VStack height={4} />}
        />
        {!isLoading && !response?.length && (
          <InsightsGenericEmptyState showActionButton />
        )}
      </VStack>
    );
  };
