import { MeasurementUnitsCategory } from '~/enums';
import { IEditActivityDTO } from '~/types/dtos';

import { DefaultInputMetrics } from './DefaultInputMetrics';
import { StepsMetrics } from './StepsMetrics';
import { ActivityMetricsProps } from './types';

export const handleShowUnitField = ({
  control,
  fields,
  options,
  isEditing,
  watch,
  addOneForm,
  handleOpenModalInformation,
}: ActivityMetricsProps<IEditActivityDTO>) => {
  const activityMetricSelectedId: string = watch('measurementUnit') as string;

  const getCategory = () => {
    const lookup: { [key: string]: string } = {};
    for (const category of options) {
      for (const option of category.options) {
        lookup[option.value] = category.label;
      }
    }
    return lookup[activityMetricSelectedId];
  };

  const category = getCategory();

  const activityMetrics = {
    [MeasurementUnitsCategory.Time]: {
      helperText: 'Can only be set/changed on newly created activities',
      label: 'Target hours',
      placeholder: 'Type the number',
      isEditing,
      control,
    },
    [MeasurementUnitsCategory.Weight]: {
      helperText: 'Can only be set/changed on newly created activities',
      label: 'Target weight',
      placeholder: 'Type the number',
      isEditing,
      control,
    },
    [MeasurementUnitsCategory.Completion]: {
      helperText: 'Can only be set/changed on newly created activities',
      addOneForm,
      fields,
      control,
      isEditing,
    },
    [MeasurementUnitsCategory.CustomUnit]: {
      helperText: 'Can only be set/changed on newly created activities',
      label: 'Target amount',
      placeholder: 'Type the number',
      isEditing,
      control,
    },
  };

  const renderMetricComponent = (category: MeasurementUnitsCategory) =>
    ({
      [MeasurementUnitsCategory.Time]: (
        <DefaultInputMetrics
          control={control}
          isEditing={isEditing}
          label={activityMetrics[MeasurementUnitsCategory.Time]?.label}
          placeholder={
            activityMetrics[MeasurementUnitsCategory.Time]?.placeholder
          }
          helperText={
            activityMetrics[MeasurementUnitsCategory.Time]?.helperText
          }
          handleOpenHelpIcon={handleOpenModalInformation}
        />
      ),
      [MeasurementUnitsCategory.Weight]: (
        <DefaultInputMetrics
          control={control}
          isEditing={isEditing}
          label={activityMetrics[MeasurementUnitsCategory.Weight]?.label}
          placeholder={
            activityMetrics[MeasurementUnitsCategory.Weight]?.placeholder
          }
          helperText={
            activityMetrics[MeasurementUnitsCategory.Weight]?.helperText
          }
          handleOpenHelpIcon={handleOpenModalInformation}
        />
      ),
      [MeasurementUnitsCategory.Completion]: (
        <StepsMetrics
          control={control}
          isEditing={isEditing}
          addOneForm={addOneForm}
          fields={fields}
        />
      ),
      [MeasurementUnitsCategory.CustomUnit]: (
        <DefaultInputMetrics
          control={control}
          isEditing={isEditing}
          label={activityMetrics[MeasurementUnitsCategory.CustomUnit]?.label}
          placeholder={
            activityMetrics[MeasurementUnitsCategory.CustomUnit]?.placeholder
          }
          helperText={
            activityMetrics[MeasurementUnitsCategory.CustomUnit]?.helperText
          }
          handleOpenHelpIcon={handleOpenModalInformation}
        />
      ),
    }[category]);

  return renderMetricComponent(category as MeasurementUnitsCategory) ?? null;
};
