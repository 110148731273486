import { HeaderLayoutProps } from '~/components/HeaderLayout/types';
import { HeaderAuthenticated } from '~/HeaderAuthenticated';

import { Header } from '../Header';

export const HeaderLayout = ({
  children,
  isAuthenticated,
  ecosystemLogo,
  ecosystemName,
  onChange,
}: HeaderLayoutProps) => (
  <>
    {isAuthenticated ? (
      <HeaderAuthenticated
        ecosystemLogo={ecosystemLogo}
        ecosystemName={ecosystemName}
        onChange={onChange}
      />
    ) : (
      <Header />
    )}
    {children}
  </>
);
