export const tabsElements = [
  {
    title: 'Group details',
    component: () => <></>,
  },
  {
    title: 'Permissions',
    component: () => <></>,
  },
  {
    title: 'Review',
    component: () => <></>,
  },
];
