import { EEventApplicationType, LocationOptionsEnum } from 'ui/enums';
import { ActivityType } from 'ui/types/activities';
import { ActivityDefinition } from 'ui/types/interfaces/activity-definition';

import { ActivityTypeEnum } from '~/enums';
import { IActivityCategoryDTO, IStep } from '~/types/dtos';
import { App } from '~/types/interfaces/app';
import { IOrganization } from '~/types/interfaces/organization';
import { ITeam } from '~/types/interfaces/team';
import { UserProfile } from '~/types/interfaces/userProfile';

// todo: check location
export interface IAddress {
  location?: {
    type: string;
    coordinates: number[];
  };
  lat?: number;
  lng?: number;
  address?: string;
  street?: string;
  rawLocation?: string;
  startDate?: Date;
  endDatee?: Date;
  _id?: string;
  createdAt?: string;
  updatedAt?: string;
  placeID?: string | null;
}
// todo: check location
export interface ICauseOptions {
  deleted?: boolean;
  displayName: string;
  relatedTo: string;
  images: [];
  app: string;
  _id: string;
  createdAt?: string;
  updatedAt?: string;
}

export interface IBaseActivityDefinitionSubDocument {
  title: string;
  description: string;
  type: string;
  periodOptions: [];
  causeOptions: ICauseOptions[];
  requirementOptions: { displayName: string; _id: string }[];
  coverImage: string;
  carouselImages: string[];
  isApprovalRequired: boolean;
  ecosystem: string;
  organization: string;
  organizationSubDocument: IOrganization;
  app: string;
  volunteerRewards: string[];
  volunteerRequirements: string[];
  _id: string;
  createdAt: string;
  updatedAt: string;
  __v?: number;
}

export interface IActivityDefinitionSubDocument
  extends IBaseActivityDefinitionSubDocument {
  attendanceType: string;
  durationInHours: string;
  spaceOptions: string;
  typeOfWork: string;
  eventType: string;
}

type EventType = 'National' | 'Local';

export interface IActivity {
  deleted: boolean;
  _id: string;
  startDate: Date;
  volunteerNumber?: number;
  endDate: Date;
  isVolunteerNumberLimited: boolean;
  noVolunteerLimit: boolean;
  eventType: EventType;
  address: IAddress;
  activityDefinitionSubDocument: IActivityDefinitionSubDocument;
  activityDefinition: string;
  createdAt: string;
  updatedAt: string;
  __v?: number;
  isOnline: true;
  meetingLink: string;
  bookingsNumber: number;
  attendeesNumber: number;
  dueDate?: Date;
  externalApplyLink?: string;
  externalId?: string;
  externalProvider?: string;
}

export interface IActivityDefinition {
  _id: string;
  title: string;
  description: string;
  // We need the new type so we can use the utils from packages, if we refactor this type will break alot of things for now
  type: ActivityTypeEnum | ActivityType;
  causeOptions: ICauseOptions[];
  requirementOptions: IActivityCategoryDTO[];
  coverImage?: string;
  carouselImages?: string[];
  isApprovalRequired: boolean;
  app: string;
  ecosystem: string;
  organization: string;
  volunteerRewards?: string[];
  volunteerRequirements: string[];
  measurementUnit?: string;
  measurementUnitPluralLabel?: string;
  attendanceType?: string;
  spaceOptions?: string;
  eventType?: string;
  typeOfWork?: string;
  targetAmount: number | string;
  steps?: IStep[];
  eventApplicationType?: EEventApplicationType;
  locationOption: LocationOptionsEnum;
  createdAt: string;
  updatedAt: string;
  __v: number;
}

export interface IActivityDefinitionAppPopulate
  extends Omit<ActivityDefinition, 'app'> {
  app: App;
}

export enum ActivityApplicationEnum {
  Approved = 'Approved',
  Canceled = 'Canceled',
  Rejected = 'Rejected',
  Pending = 'Pending',
  Declined = 'Declined',
  Referral = 'Referral',
}

export interface IActivityApplicationDeleteModal {
  activityId: string;
  attendanceId: string;
  userName: string;
}

export type IEvent = IActivityDefinition;

export interface IActivityApplication {
  _id: string;
  applicationDate: Date;
  cancelApplicationDate: Date;
  status: ActivityApplicationEnum;
  isAttendanceConfirmed: boolean;
  userProfileSubDocument: UserProfile;
  teamSubDocument: ITeam;
  activitySubDocument: IActivity;
  userProfile: string;
  user: string;
  activity: string;
  organization: IOrganization;
  ecosystem: string;
  measurementTargetAmount: number;
  measurementTargetReached: boolean;
  applicationType: EEventApplicationType;
  statusHistory: [];
  createdAt: string;
  updatedAt: string;
  __v: number;
}
