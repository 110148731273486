import './pie.css';

import { ApexOptions } from 'apexcharts';
import { Box, HStack, Skeleton, Text, VStack } from 'native-base';
import { useMemo } from 'react';
import Chart from 'react-apexcharts';
import { generateRandomColors } from 'ui/utils/colors';

import { InsightsGenericEmptyState } from '~/pages/Authenticated/Insights/components/EmptyState';

import { apexChartsDefaultColors } from './constants';
export type PieChartDataParams = {
  series: number[];
  labels: string[];
};

type PieChartProps = PieChartDataParams & {
  chartId?: string;
  isLoading?: boolean;
  tooltip?: ApexOptions['tooltip'];
  showLegend?: boolean;
};

const PieChartSkeleton = () => (
  <>
    <Skeleton height="180px" width="180px" borderRadius="full" mx="auto" />
    <Skeleton.Text mt="4" px="4" lines={1} width={'25%'} />
    <Skeleton.Text mt="4" px="4" lines={1} width={'25%'} />
    <Skeleton.Text mt="4" px="4" lines={1} width={'25%'} />
    <Skeleton.Text mt="4" px="4" lines={1} width={'25%'} />
    <Skeleton.Text mt="4" px="4" lines={1} width={'25%'} />
  </>
);

const PieChartEmptyState = () => (
  <VStack>
    <InsightsGenericEmptyState />
  </VStack>
);

export const PieChart = ({
  chartId,
  labels,
  series,
  isLoading,
  tooltip = {
    enabled: true,
  },
  showLegend = true,
}: PieChartProps) => {
  const colors = useMemo(() => {
    return generateRandomColors(labels.length || 0, apexChartsDefaultColors);
  }, [labels]);

  if (isLoading) {
    return <PieChartSkeleton />;
  }

  if (!series.length) {
    return <PieChartEmptyState />;
  }

  return (
    <VStack overflowY={'auto'} maxH={'524px'}>
      <Chart
        series={series}
        type="pie"
        width="100%"
        height={256}
        options={{
          labels,
          colors,
          tooltip,
          dataLabels: {
            enabled: true,
            style: {
              fontSize: '10px',
              fontWeight: '700',
            },
            background: {
              enabled: false,
            },
            dropShadow: {
              enabled: true,
              opacity: 0.25,
              top: 1,
              blur: 1,
            },
          },
          legend: {
            show: false,
          },
          chart: {
            id: chartId,
          },
        }}
      />

      {showLegend && (
        <VStack mt={6} space={3}>
          {labels?.map((label, i) => {
            return (
              <HStack key={i} alignItems={'center'} space={3}>
                <Box
                  h={'20px'}
                  w={'20px'}
                  borderRadius={4}
                  backgroundColor={colors?.[i] || ''}
                />
                <Text fontSize={'sm'} color="darkText">
                  {label}
                </Text>
              </HStack>
            );
          })}
        </VStack>
      )}
    </VStack>
  );
};
