import Yup from '../../utils/validations/yup';
import {
  IInputItems,
  INPUT_COMPONENT_TYPES,
} from './components/FormFieldsRender/types';

export const schemeValidationGenerator = (items: IInputItems[]) => {
  if (items.length) {
    const validations = items.reduce((acc, current) => {
      if (current.isRequired) {
        switch (current.component) {
          case INPUT_COMPONENT_TYPES.INPUT:
            if (current?.inputProps?.type === 'password') {
              return {
                ...acc,
                [current.name]: Yup.string().password().required(),
              };
            }
            return {
              ...acc,
              [current.name]: Yup.string().required(),
            };
          case INPUT_COMPONENT_TYPES.CHECKBOX:
            return {
              ...acc,
              [current.name]: Yup.array().min(1).required(),
            };
          case INPUT_COMPONENT_TYPES.RADIO:
            return {
              ...acc,
              [current.name]: Yup.string().required(),
            };
          case INPUT_COMPONENT_TYPES.SELECT:
            if (current.inputProps.isMulti) {
              return {
                ...acc,
                [current.name]: Yup.array().min(1).required(),
              };
            }
            return {
              ...acc,
              [current.name]: Yup.string().required(),
            };
          case INPUT_COMPONENT_TYPES.DYNAMIC_INPUT:
            return {
              ...acc,
              [current.name]: Yup.array().min(1).required(),
            };
          case INPUT_COMPONENT_TYPES.LOCALE:
            return {
              ...acc,
              [current.name]: Yup.object().shape({
                lat: Yup.number().required(),
                lng: Yup.number().required(),
              }),
            };

          case INPUT_COMPONENT_TYPES.APP_SELECT:
            return {
              ...acc,
              [current.name]: Yup.array().min(1).required(),
            };

          case INPUT_COMPONENT_TYPES.RADIO_APP_SELECT:
            return {
              ...acc,
              [current.name]: Yup.array().min(1).max(1).required(),
            };

          default:
            return {
              ...acc,
              [current.name]: Yup.string().required(),
            };
        }
      }
      return acc;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    }, {} as any);

    return Yup.object(validations).required();
  }

  return Yup.object({});
};
