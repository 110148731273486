import axios from 'axios';

import { uploadsApi } from '../uploadsApi';
import {
  SignedURLResponse,
  UploadsGenerateURLProps,
  UploadsUploadFileToS3Props,
  UploadURLFields,
} from './types';

export class UploadsService {
  static async generatePreSignedURL({
    Target,
    FileExtension,
  }: UploadsGenerateURLProps) {
    const response = await uploadsApi.get<SignedURLResponse>('', {
      params: {
        Target,
        FileExtension,
      },
    });
    return response.data;
  }

  static async uploadFileToS3({ signedURL, file }: UploadsUploadFileToS3Props) {
    const url = signedURL.uploadURL.url;
    const formData = this.buildFormData(signedURL, file);

    return await axios({
      method: 'POST',
      url,
      data: formData,
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  }

  static buildFormData(signedURL: SignedURLResponse, file: File) {
    const formData = new FormData();
    const fields = signedURL.uploadURL.fields;
    for (const key in fields) {
      formData.append(key, fields[key as keyof UploadURLFields]);
    }
    formData.append('file', file);
    return formData;
  }
}
