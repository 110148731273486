import { VStack } from 'native-base';
import { useState } from 'react';

import {
  InsightScaffold,
  InsightScaffoldChildrenReactComponent,
} from '~/pages/Authenticated/Insights/components/InsightScaffold';
import {
  InsightTabOption,
  InsightTabs,
} from '~/pages/Authenticated/Insights/components/InsightTabs';
import { VolunteeringHoursDepartments } from '~/pages/Authenticated/Insights/components/VolunteeringHours/VolunteeringHoursDepartments';
import { VolunteeringHoursEmployees } from '~/pages/Authenticated/Insights/components/VolunteeringHours/VolunteeringHoursEmployees';

import { VolunteeringHoursProps } from './types';
import { INSIGHT_TABS, insightTabList } from './utils';

export const VolunteeringHoursTabs: InsightScaffoldChildrenReactComponent = ({
  onSetChart,
}) => {
  const [selectedTab, setSelectedTab] = useState<InsightTabOption>(
    insightTabList?.[0],
  );

  const renderTab = () => {
    switch (selectedTab.value) {
      case INSIGHT_TABS.EMPLOYEES:
        return <VolunteeringHoursEmployees onSetChart={onSetChart} />;
      case INSIGHT_TABS.DEPARTMENTS:
        return <VolunteeringHoursDepartments onSetChart={onSetChart} />;
      default:
        return null;
    }
  };

  return (
    <VStack space={4} mx={6} pb={6} flex={1}>
      <InsightTabs onChange={setSelectedTab} options={insightTabList} mb={6} />
      {renderTab()}
    </VStack>
  );
};

export const VolunteeringHours = ({ className }: VolunteeringHoursProps) => {
  return (
    <InsightScaffold
      className={className}
      title={{
        icon: 'clock',
        name: 'Volunteering Hours',
      }}
    >
      {VolunteeringHoursTabs}
    </InsightScaffold>
  );
};
