import { HStack, Progress, Text, VStack } from 'native-base';
import Icon from 'ui/components/Icon';
import { useBaseLogProgressTileController } from 'ui/components/v2/Tiles/LogProgressTile/controller';
import { BaseLogProgressTileProps } from 'ui/components/v2/Tiles/LogProgressTile/types';
import { colors } from 'ui/theme/colors';

export type TargetLogProgressTileProps = BaseLogProgressTileProps;

export const TargetLogProgressTile = (props: TargetLogProgressTileProps) => {
  const { formattedValues, measurementIcon, progressValue, targetValue } =
    useBaseLogProgressTileController(props);

  return (
    <VStack
      borderWidth={1}
      bg={'gray.100'}
      w={'full'}
      borderColor={'gray.300'}
      borderRadius={'xl'}
      py={3}
      px={4}
    >
      <VStack w={'full'} space={1}>
        <HStack
          w={'full'}
          alignItems={'center'}
          justifyContent={'space-between'}
        >
          <Text flex={1} fontWeight={500} fontSize={'sm'} color={'black'}>
            {formattedValues.amountStr}
          </Text>

          <HStack
            flex={1}
            w={'full'}
            alignItems={'center'}
            justifyContent={'flex-end'}
            space={1}
          >
            <Icon
              icon={measurementIcon}
              enableAutoCustom
              size={'18px'}
              color={colors.gray['600']}
            />

            <Text
              fontSize={'sm'}
              lineHeight={'100%'}
              fontWeight={500}
              color={'gray.600'}
            >
              {formattedValues.targetStr}
            </Text>
          </HStack>
        </HStack>
        <Progress
          width={'full'}
          value={progressValue}
          max={targetValue}
          bg={'gray.300'}
          h={2}
          _filledTrack={{ bg: 'black' }}
        />
      </VStack>
    </VStack>
  );
};
