import { useQuery } from '~/hooks/useQuery';

import { LoadActivityApplicationsCountProps } from './types';

export const useLoadActivityApplicationsCount = (
  filter: LoadActivityApplicationsCountProps,
  isEnabled = false,
) => {
  const { activityDefinition } = filter;
  const url = `activity-application/count`;

  const { data, isLoading, refetch, remove } = useQuery<{
    count: number;
  }>(url, {
    key: `activity-application-count-${JSON.stringify(filter)}`,
    queryOptions: { enabled: isEnabled },
    requestOptions: {
      params: {
        filter: JSON.stringify({
          'activitySubDocument.activityDefinition': activityDefinition,
        }),
      },
    },
  });

  return {
    data: data?.count || 0,
    isLoading,
    refetch,
    remove,
  };
};
