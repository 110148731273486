import DateFilter from '@inovua/reactdatagrid-community/DateFilter';
import SelectFilter from '@inovua/reactdatagrid-community/SelectFilter';
import {
  TableOperatorNames,
  TableOperatorType,
} from 'ui/components/DataTable/@types/basicTypes';
import { IColumn } from 'ui/components/DataTable/types';
import { TableCellWithAvatar } from 'ui/components/TableCellWithAvatar';
import { EEventApplicationType } from 'ui/enums';
import { ActivityType } from 'ui/types/activities';
import { Team } from 'ui/types/teams';
import { formattedDate } from 'ui/utils/formatter';
import { getTeamMembersTotal } from 'ui/utils/teams';

import { ApplicationActions } from '~/pages/Authenticated/Activities/components/ApplicationActions';
import { ApplicationStatusTag } from '~/pages/Authenticated/Activities/components/ApplicationStatusTag';
import {
  ActivityApplicationEnum,
  IActivityApplication,
} from '~/types/interfaces/activity';

import { ApplicationsColumnsProps, IApplicationColumnsData } from './types';

export const baseApplicationsColumns = ({
  onAcceptApplication,
  onRejectApplication,
  isHandlingApplications,
  handleViewMemberApplication,
  handleViewTeamApplication,
}: Omit<ApplicationsColumnsProps, 'eventApplicationType'>): IColumn[] => [
  {
    name: 'applicationDate',
    header: 'Date of Application',
    defaultFlex: 1.5,
    filterEditor: DateFilter,
    filterEditorProps: () => {
      return {
        dateFormat: 'YYYY-MM-DD',
        cancelButton: false,
        highlightWeekends: false,
      };
    },
    render: ({ data }: IApplicationColumnsData) =>
      formattedDate(data.applicationDate, 'dd/MM/yyyy'),
  },
  {
    name: 'activitySubDocument.activityDefinitionSubDocument.title',
    header: 'Activity',
    defaultFlex: 1,

    render: ({ data }: IApplicationColumnsData) =>
      data.activitySubDocument.activityDefinitionSubDocument.title,
  },
  {
    name: 'activitySubDocument.address.street',
    header: 'Activity Location',
    defaultFlex: 1.3,
    render: ({ data }: IApplicationColumnsData) => {
      const typesWithAddress = [ActivityType.Event, ActivityType.Ongoing];
      if (
        typesWithAddress.includes(
          data.activitySubDocument.activityDefinitionSubDocument
            .type as ActivityType,
        )
      ) {
        return (
          data.activitySubDocument.address?.street ||
          data.activitySubDocument.meetingLink
        );
      } else {
        return 'From Home';
      }
    },
  },
  {
    name: 'activitySubDocument.startDate',
    header: 'Start Date',
    defaultFlex: 1.1,

    render: ({ data }: IApplicationColumnsData) =>
      data.activitySubDocument.startDate
        ? formattedDate(data.activitySubDocument.startDate)
        : 'N/A',
  },
  {
    name: 'status',
    header: 'Status',
    defaultFlex: 1,
    filterEditor: SelectFilter,
    filterEditorProps: {
      multiple: false,
      wrapMultiple: false,
      dataSource: Object.values(ActivityApplicationEnum).map(
        (applicationStatus) => {
          return {
            id: applicationStatus,
            label: applicationStatus,
          };
        },
      ),
    },
    render: ({ data }: IApplicationColumnsData) => (
      <ApplicationStatusTag status={data.status as ActivityApplicationEnum} />
    ),
  },
  {
    name: 'actions',
    header: 'Actions',
    defaultFlex: 1.5,
    render: ({ data }: IApplicationColumnsData) => (
      <ApplicationActions
        showDeclineButton={
          (data.status === ActivityApplicationEnum.Pending ||
            data.status === ActivityApplicationEnum.Approved) &&
          !data.isAttendanceConfirmed
        }
        showAcceptButton={
          data.status === ActivityApplicationEnum.Pending ||
          data.status === ActivityApplicationEnum.Rejected
        }
        onPressAccept={() =>
          onAcceptApplication({
            activityId: data.activitySubDocument._id,
            isTeamApplication:
              data.applicationType === EEventApplicationType.Team,
            userProfile: data.userProfileSubDocument
              ? data.userProfileSubDocument
              : undefined,
            teamProfile: data.teamSubDocument
              ? data.teamSubDocument
              : undefined,
          })
        }
        onPressDecline={() => onRejectApplication(data as IActivityApplication)}
        onPressView={() => {
          if (data.applicationType === EEventApplicationType.Team) {
            handleViewTeamApplication(data._id);
            return;
          }
          handleViewMemberApplication(data.userProfileSubDocument?._id || '');
        }}
        isButtonsDisabled={isHandlingApplications}
      />
    ),
  },
];

export const columnsByEventApplication = ({
  onAcceptApplication,
  onRejectApplication,
  isHandlingApplications,
  eventApplicationType,
  handleViewMemberApplication,
  handleViewTeamApplication,
}: ApplicationsColumnsProps): IColumn[] => {
  return {
    [EEventApplicationType.Individual]: [
      {
        filterable: true,
        name: 'userProfileSubDocument.userSummary.name',
        header: 'Participant',
        defaultFlex: 1.5,
        render: ({ data }: IApplicationColumnsData) => (
          <TableCellWithAvatar
            name={data.userProfileSubDocument.userSummary.name}
            avatar={data.userProfileSubDocument.userSummary.profileImage}
          />
        ),
      },
      {
        filterable: true,
        name: 'organization.name',
        header: 'Member of',
        defaultFlex: 1.5,
        render: ({ data }: IApplicationColumnsData) => (
          <TableCellWithAvatar
            name={data.organization.name}
            avatar={data.organization.logo}
            isOrganization
          />
        ),
      },
      ...baseApplicationsColumns({
        onAcceptApplication,
        onRejectApplication,
        isHandlingApplications,
        handleViewMemberApplication,
        handleViewTeamApplication,
      }),
    ],
    [EEventApplicationType.Team]: [
      {
        filterable: true,
        name: 'teamSubDocument.name',
        header: 'Team',
        defaultFlex: 1.5,
        render: ({ data }: IApplicationColumnsData) =>
          data.teamSubDocument.name,
      },
      {
        filterable: true,
        name: 'teamSubDocument.members',
        header: 'Members',
        defaultFlex: 0.9,
        render: ({ data }: IApplicationColumnsData) =>
          getTeamMembersTotal(data.teamSubDocument as unknown as Team),
      },
      ...baseApplicationsColumns({
        onAcceptApplication,
        onRejectApplication,
        isHandlingApplications,
        handleViewMemberApplication,
        handleViewTeamApplication,
      }),
    ],
  }[eventApplicationType];
};

export const defaultApplicationsListFilters = [
  {
    name: 'userProfileSubDocument.userSummary.name',
    operator: TableOperatorNames.Equals,
    type: TableOperatorType.String,
    value: '',
  },
  {
    name: 'teamSubDocument.name',
    operator: TableOperatorNames.Equals,
    type: TableOperatorType.String,
    value: '',
  },
  {
    name: 'organization.name',
    operator: TableOperatorNames.Equals,
    type: TableOperatorType.String,
    value: '',
  },
  {
    name: 'teamSubDocument.members',
    operator: TableOperatorNames.GreaterThanOrEquals,
    type: TableOperatorType.Number,
    value: '',
  },
  {
    name: 'applicationDate',
    operator: TableOperatorNames.AfterOrOn,
    type: TableOperatorType.Date,
    value: '',
  },
  {
    name: 'activitySubDocument.activityDefinitionSubDocument.title',
    operator: TableOperatorNames.Contains,
    type: TableOperatorType.String,
    value: '',
  },
  {
    name: 'activitySubDocument.address.street',
    operator: TableOperatorNames.Equals,
    type: TableOperatorType.String,
    value: '',
  },
  {
    name: 'activitySubDocument.startDate',
    operator: TableOperatorNames.AfterOrOn,
    type: TableOperatorType.Date,
    value: '',
  },
  {
    name: 'status',
    operator: TableOperatorNames.Equals,
    type: TableOperatorType.String,
    value: '',
  },
];
