import { Box, FormControl, HStack, Stack, Text } from 'native-base';
import Dropzone from 'react-dropzone';
import Button from 'ui/components/Button';
import FormError from 'ui/components/Form/FormError';
import { colors } from 'ui/theme/colors';

import useDropzoneController from '../../Dropzone/controller';
import ThumbnailMini from '../../Dropzone/ThumbnailMini';
import { ImageZoneMiniProps } from './types';
import { useImageZoneMiniController } from './useImageZoneMiniController';

const ImageZoneMini = ({
  btnIconColor = colors.primaryAdmin[600],
  btnLabelColor = 'primary.600',
  btnBgColor = 'primary.50',
  btnLabel = 'Select images',
  title = 'Extra images',
  description = `These images will appear below the description, use them to show more about the activity.`,
  errorMessage,
  isRequired,
  isDisabled,
  isInvalid,
  isReadOnly,
  hasError,
  iconErrorMessage,
  iconErrorSize,
  filesAccepted = { 'image/jpeg': [], 'image/png': [], 'image/jpg': [] },
  ...restProps
}: ImageZoneMiniProps) => {
  const {
    imageZoneMiniImages,
    showTitle,
    handleDeleteFile,
    onDropAccepted,
    onDropRejected,
  } = useImageZoneMiniController(restProps);

  return (
    <Dropzone
      onDropAccepted={onDropAccepted}
      onDropRejected={onDropRejected}
      accept={filesAccepted}
    >
      {({ getRootProps, getInputProps, open }) => (
        <div {...getRootProps()}>
          <input {...getInputProps()} />
          <Stack space={3}>
            <FormControl
              isInvalid={hasError || errorMessage ? true : isInvalid}
              isDisabled={isDisabled}
              isRequired={isRequired}
              isReadOnly={isReadOnly}
            >
              {showTitle ? (
                <Stack space="2">
                  <Box>
                    {title ? (
                      <FormControl.Label>
                        <Text color="gray.600" fontSize={14} fontWeight="500">
                          {title}
                        </Text>
                      </FormControl.Label>
                    ) : null}
                  </Box>
                  <Box>
                    <Text color="gray.600" fontSize={14} fontWeight="400">
                      {description}
                    </Text>
                  </Box>
                </Stack>
              ) : null}
              <HStack alignItems="center">
                {imageZoneMiniImages.length > 0 ? (
                  <ThumbnailMini
                    images={imageZoneMiniImages}
                    handleDeleteFile={handleDeleteFile}
                    btnIconColor={btnIconColor}
                  />
                ) : (
                  <Stack
                    alignItems="center"
                    justifyContent="center"
                    h="120px"
                    w="100%"
                    borderWidth={1}
                    borderRadius={24}
                    borderColor="muted.200"
                    mt={2}
                  >
                    <Text color="gray.600" fontSize={12} fontWeight="400">
                      Drag your desired images here
                    </Text>
                  </Stack>
                )}
              </HStack>

              <HStack space="2" flexWrap="wrap" my="2" justifyContent="center">
                <Box my="1">
                  <Button
                    leftIconName="upload"
                    leftIconSize={16}
                    leftIconColor={btnIconColor}
                    bgColor={btnBgColor}
                    onPress={open}
                  >
                    <Text color={btnLabelColor} fontSize="14" fontWeight="500">
                      {btnLabel}
                    </Text>
                  </Button>
                </Box>
              </HStack>
              <FormError
                errorMessage={errorMessage}
                iconErrorMessage={iconErrorMessage}
                iconErrorSize={iconErrorSize}
              />
            </FormControl>
          </Stack>
        </div>
      )}
    </Dropzone>
  );
};

export default ImageZoneMini;
