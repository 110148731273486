import { Stack, View, VStack } from 'native-base';

import { GenericStateText } from './components/GenericStateText';
import { LoadingSkeleton } from './components/LoadingSkeleton';
import { RefetchButton } from './components/RefetchButton';
import { useFeedScaffoldController } from './controller';
import { FeedScaffoldProps } from './types';

export const FeedScaffold: React.FC<FeedScaffoldProps> = (props) => {
  const {
    children,
    headerSlot,
    resourceName = 'data',
    emptyStateSlot = <GenericStateText label="Empty" />,
    outOfDataStateSlot = <GenericStateText label={`No more ${resourceName}`} />,
    customSkeleton,
  } = props;

  const {
    topRef,
    bottomRef,
    isEmpty,
    isOutData,
    futureDataCount,
    showRefetchButton,
    isNextPageLoading,
    isRefetchingLoading,
    handleRefetchButtonPress,
  } = useFeedScaffoldController(props);

  return (
    <VStack flex={1} space={4} ref={topRef}>
      {/* Header */}
      {headerSlot && <VStack zIndex={2}>{headerSlot}</VStack>}
      {/* Content */}
      <VStack zIndex={1} position={'relative'}>
        {showRefetchButton && (
          <Stack
            top={6}
            left={0}
            right={0}
            zIndex={999}
            margin={'auto'}
            position={'sticky'}
            marginBottom={-16}
            marginTop={4}
          >
            <RefetchButton onPress={handleRefetchButtonPress} />
          </Stack>
        )}

        {isRefetchingLoading
          ? customSkeleton || <LoadingSkeleton rows={futureDataCount || 3} />
          : children}

        {isNextPageLoading ? (
          customSkeleton || <LoadingSkeleton rows={6} />
        ) : (
          <>
            {isOutData && outOfDataStateSlot}
            {isEmpty && emptyStateSlot}
          </>
        )}
      </VStack>
      {!isOutData && <View ref={bottomRef} w={'full'} h={32} />}
    </VStack>
  );
};
