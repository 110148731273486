import { ICustomCheckboxProps } from './types';

export const useCheckboxController = ({
  label,
  _formControl,
  hasError,
  options,
  control,
  name,
  isInvalid,
  isDisabled,
  isRequired,
  isReadOnly,
  errorMessage,
  helperText,
  iconErrorMessage,
  iconErrorSize,
  marginYItem,
  ...restProps
}: ICustomCheckboxProps) => {
  return {
    _formControl,
    control,
    name,
    helperText,
    hasError,
    isInvalid,
    isDisabled,
    isReadOnly,
    options,
    isRequired,
    errorMessage,
    iconErrorSize,
    iconErrorMessage,
    label,
    restProps,
    marginYItem,
  };
};
