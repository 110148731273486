import { useMemo } from 'react';
import { ImagePickerBaseProps } from 'ui/components/v2/ImageInputs/ImagePicker/Base/types';
import { useImagePickerSharedController } from 'ui/components/v2/ImageInputs/ImagePicker/controller';

export const useBaseImagePickerController = ({
  src,
  aspectRatio,
  onChange,
}: ImagePickerBaseProps) => {
  const {
    imgSrc,
    modalRef,
    fileInputRef,
    isLoading,
    handlePress,
    handleSubmit,
    handleNewFile,
  } = useImagePickerSharedController({
    src,
    aspectRatio,
    onChange,
  });

  const handleInputFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      const file = e.target.files[0];
      handleNewFile(file);
      // Important Reset input value allowing select the same file again
      e.target.value = '';
    }
  };

  const imagePreviewDimensions = useMemo(() => {
    const width = 124;
    const height = width / (aspectRatio || 1);

    return { width, height };
  }, [aspectRatio]);

  return {
    imgSrc,
    modalRef,
    fileInputRef,
    imagePreviewDimensions,
    isLoading,
    handleSubmit,
    handlePress,
    handleInputFileChange,
  };
};
