import Yup from '~/utils/validations/yup';

export const schemaValidation = Yup.object({
  email: Yup.string()
    .transform((value: string) => value?.trim() || '')
    .email()
    .required(),
});

export const defaultValues = {
  email: '',
};
