import { RowError } from 'csv-file-validator';
import { Box, Stack, Text } from 'native-base';
import React from 'react';
import Icon from 'ui/components/Icon';

interface ICSVErrorsList {
  errors?: RowError[];
}

const _CSVErrorList = ({ errors }: ICSVErrorsList) => {
  if (!errors?.length) return null;

  return (
    <Stack px="6" mt="4" w="100%" space="2">
      {errors?.map((error) => (
        <Box
          key={error.message}
          flexDir="row"
          alignItems={'center'}
          bg="red.100"
          px="4"
          py="3"
          w="100%"
        >
          <Icon icon="alert-triangle" size={15} color="darkText" />
          <Text ml="3">{error.message}</Text>
        </Box>
      ))}
    </Stack>
  );
};

export const CSVErrorList = React.memo(_CSVErrorList);
