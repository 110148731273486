import { VStack } from 'native-base';

import { FormContainerProps } from '~/components/ActivityFormBuilderPresets/components/types';

export const ActivityFormContainer = ({ children }: FormContainerProps) => {
  return (
    <VStack w="100%" maxW={'768px'}>
      {children}
    </VStack>
  );
};
