import { Checkbox, Flex, HStack, Stack, Text } from 'native-base';
import Illustration, { ILLUSTRATIONS_NAME } from 'ui/components/Illustration';

import { MemberTypeEnum } from '~/enums';
import { IAppModel } from '~/pages/Authenticated/AddOrgMember/components/Form/types';

import { EcosystemAppItem } from './EcosystemAppItem';
import { useEcosystemItemController } from './ecosystemItemController';
import { EcosystemItemHeader } from './EcosystemItemHeader';
import { IEcosystemItemProps } from './types';

export const EcosystemItem = ({
  _id,
  name,
  appsConnectedToOrganization,
  control,
  memberType,
  visibility,
  isDisabled,
  setValue,
}: IEcosystemItemProps) => {
  const { ecosystem, filteredAppsByEcosystem, field, handleOnChange } =
    useEcosystemItemController({
      _id,
      name,
      visibility,
      appsConnectedToOrganization,
      control,
      memberType,
      setValue,
    });

  const renderEcosystemAppsCard = () => {
    return (
      <Stack space={2} paddingLeft={0}>
        <HStack space={5} alignItems="center" flexWrap="wrap">
          {filteredAppsByEcosystem?.map((appByEcosystem) => (
            <Stack key={appByEcosystem._id}>
              <EcosystemAppItem
                key={appByEcosystem.app._id}
                isChecked={
                  !!ecosystem?.appsData?.find(
                    (appData) => appData.appId === appByEcosystem.app._id,
                  )
                }
                isDisabled={isDisabled}
                {...(appByEcosystem.app as IAppModel)}
              />
            </Stack>
          ))}
        </HStack>
      </Stack>
    );
  };

  return (
    <Flex
      padding="4"
      flexDir="column"
      borderRadius="xl"
      minH="30"
      borderWidth="1"
      borderColor="gray.300"
    >
      <Checkbox.Group
        onChange={handleOnChange}
        value={field.value}
        _checkbox={{
          borderColor: 'gray.300',
          _hover: { borderColor: 'gray.300' },
          _checked: {
            _hover: { borderColor: 'primary.500' },
            borderColor: 'primary.400',
            backgroundColor: 'primary.400',
          },
        }}
      >
        <EcosystemItemHeader name={name} visibility={visibility} />

        {renderEcosystemAppsCard()}

        {!filteredAppsByEcosystem?.length &&
        memberType === MemberTypeEnum.Member ? (
          <Stack width="100%" alignItems="center">
            <HStack alignItems="center">
              <Illustration
                name={ILLUSTRATIONS_NAME.ALERT}
                width="40px"
                height="40px"
              />
              <Text color="gray.500">
                There are no apps in this ecosystem yet.
              </Text>
            </HStack>
          </Stack>
        ) : null}
      </Checkbox.Group>
    </Flex>
  );
};
