import { api } from '~/services/api';
import { QueryParams } from '~/services/resources/types';
import { ICreateEventDTO } from '~/types/dtos';

const MODULE_URL = 'activity';

interface PayloadDeleteVolunteer {
  description: string;
}

interface PayloadApproveTeam {
  team: string;
  members: string[];
}

/**
 * Event/Action -> "activity-definition"
 * Places -> "activity"
 * Volunteers -> "activity-application"
 */
export default class ActivityService {
  static async findAll(params?: QueryParams) {
    const options = {
      params: {
        ...params,
      },
    };

    return await api.get(MODULE_URL, options);
  }

  static async findOne(id: string) {
    return await api.get(`${MODULE_URL}/${id}`);
  }

  static async editEvent(id: string, values: Partial<ICreateEventDTO>) {
    return await api.put(`${MODULE_URL}/event/${id}`, values);
  }

  static async deleteOne(id: string) {
    return await api.delete(`${MODULE_URL}/${id}`);
  }

  static async get(id: string) {
    return await api.get(`${MODULE_URL}/${id}`);
  }

  static async getOneFilter(id: string) {
    const options = {
      filter: JSON.stringify({ _id: id }),
      limit: 1,
    };
    return await api.get(`${MODULE_URL}`, {
      params: options,
    });
  }

  static async getByDefinition(id: string) {
    const options = {
      params: {
        filter: JSON.stringify({ activityDefinition: id }),
        limit: 1,
      },
    };
    return await api.get(`${MODULE_URL}`, options);
  }

  static async getAllByDefinition(id: string, limit = 20) {
    const options = {
      params: {
        filter: JSON.stringify({ activityDefinition: id }),
        limit,
      },
    };
    return await api.get(`${MODULE_URL}`, options);
  }

  static async approve(activityId: string, attendeeId: string) {
    return await api.post(
      `${MODULE_URL}/approve/${activityId}/attendance/${attendeeId}`,
    );
  }

  static async reject(
    activityId: string,
    attendanceId: string,
    data: PayloadDeleteVolunteer,
  ) {
    return await api.post(
      `${MODULE_URL}/reject/${activityId}/attendance/${attendanceId}`,
      data,
    );
  }

  static async approveTeam(
    activityId: string,
    teamId: string,
    data: PayloadApproveTeam,
  ) {
    return await api.post(
      `${MODULE_URL}/approve/${activityId}/team/${teamId}`,
      data,
    );
  }

  static async rejectTeam(
    activityId: string,
    teamId: string,
    data: PayloadApproveTeam,
  ) {
    return await api.post(
      `${MODULE_URL}/reject/${activityId}/team/${teamId}`,
      data,
    );
  }
}
