import { Divider, Stack, Text, VStack } from 'native-base';
import DataTable from 'ui/components/DataTable';
import { ActivityType } from 'ui/types/activities';

import { ModalActionReport } from '~/components/ModalActionReport';
import { ActivityTypeEnum } from '~/enums';
import { secondsToHoursMinutesString } from '~/utils/functions';

import { tableActivitiesColumns } from './activitiesColumns';
import { ActivitiesReportSection } from './components/ActivitiesReportSection';
import { useActivitiesListController } from './controller';
import { ActivitiesListProps } from './types';
import { defaultActivitiesListFilterValue } from './utils';

export const ActivitiesList = ({}: ActivitiesListProps) => {
  const {
    actionReportModalRef,
    loadActivitiesList,
    handleEditActivity,
    handleViewActivity,
    activitiesCount,
    applicationsCount,
    logHoursReportData,
    logHoursTotal,
    connectedApps,
    isLoadingConnectedApps,
    isRefetchingConnectedApps,
    setTableRef,
    isLoadingReports,
    openModal,
    validateIfAppHasPermission,
    validateIfOrganizationIsAppOwner,
  } = useActivitiesListController();

  return (
    <Stack w="full">
      <Stack p={6} background="white" borderRadius="3xl" space={5}>
        <VStack space={2}>
          <Text color="gray.600" fontSize="2xl" fontWeight={500}>
            Activities report
          </Text>
          <Text color="gray.600" fontSize="sm" fontWeight={500}>
            {"Keep track of this organisation's activities"}
          </Text>
        </VStack>
        <Divider />
        <ActivitiesReportSection
          isLoadingReports={isLoadingReports}
          eventsCount={applicationsCount?.Event || 0}
          actionsCount={applicationsCount?.Action || 0}
          ongoingsCount={applicationsCount?.OngoingOpportunity || 0}
          eventsLogHoursCount={logHoursReportData?.Event || 0}
          actionsLogHoursCount={logHoursReportData?.Action || 0}
          ongoingsLogHoursCount={logHoursReportData?.OngoingOpportunity || 0}
          totalLogHours={logHoursTotal?.total || 0}
          onOpenActionModal={openModal}
        />
        <Stack space={8}>
          <Stack space={2}>
            <Text fontWeight={500} fontSize="2xl" color="gray.600">
              {activitiesCount} activities
            </Text>
            <Text fontWeight={400} fontSize="sm" color="gray.600">
              Total number of activities in your ecosystems.
            </Text>
          </Stack>
          <DataTable
            onReady={setTableRef as () => void}
            defaultFilterValues={defaultActivitiesListFilterValue}
            loadData={loadActivitiesList as any}
            columns={tableActivitiesColumns({
              onEditActivity: (
                activityType: ActivityTypeEnum | ActivityType,
                id: string,
              ) => handleEditActivity(activityType as ActivityTypeEnum, id),
              onViewActivity: (
                activityType: ActivityTypeEnum | ActivityType,
                id: string,
              ) => handleViewActivity(activityType as ActivityTypeEnum, id),
              connectedApps: connectedApps,
              validateIfAppHasPermission,
              validateIfOrganizationIsAppOwner,
            })}
            loading={isLoadingConnectedApps || isRefetchingConnectedApps}
          />
        </Stack>
      </Stack>
      <ModalActionReport
        modalRef={actionReportModalRef}
        headerText="Actions"
        totalTimeLoggedInAction={secondsToHoursMinutesString(250)}
        totalApplications={43}
      />
    </Stack>
  );
};
