import { VStack } from 'native-base';

import { HorizontalBarChart } from '~/pages/Authenticated/Insights/charts/HorizontalBarChart';
import { InsightScaffold } from '~/pages/Authenticated/Insights/components/InsightScaffold';
import { useSpaceOptionsController } from '~/pages/Authenticated/Insights/components/SpaceOptions/controller';
import { SpaceOptionsSkeleton } from '~/pages/Authenticated/Insights/components/SpaceOptions/SpaceOptionsSkeleton';

type SpaceOptionsProps = {
  className: string;
};

export const SpaceOptions = ({ className }: SpaceOptionsProps) => {
  const { isLoading, spaceOptionsMetrics, chartData } =
    useSpaceOptionsController();
  return (
    <InsightScaffold
      title={{ icon: 'home', name: 'Space Options' }}
      className={className}
      chartData={spaceOptionsMetrics}
    >
      {isLoading ? (
        <SpaceOptionsSkeleton />
      ) : (
        <VStack
          w="full"
          h="full"
          flex={1}
          px={{ base: 3, md: 6 }}
          pb={6}
          mx="auto"
        >
          <HorizontalBarChart
            isLoading={isLoading}
            series={chartData.series}
            categories={chartData.categories}
            total={chartData.total}
            maxValue={chartData?.maxValue}
          />
        </VStack>
      )}
    </InsightScaffold>
  );
};
