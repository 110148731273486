import { Avatar, HStack, Text, VStack } from 'native-base';
import Button from 'ui/components/Button';

import { AppCardProps } from './types';

export const AppCard = ({
  app,
  isPicked,
  onGetApp,
  onViewApp,
}: AppCardProps) => {
  return (
    <VStack
      p="4"
      bg="white"
      rounded="xl"
      borderWidth="1"
      borderColor="gray.200"
      space="6"
    >
      <HStack space="3" alignItems="center">
        {/* NOTE: Should we have a default icon ? */}
        <Avatar
          rounded="xl"
          w={12}
          h={12}
          source={{ uri: app.logo }}
          _image={{ rounded: 12 }}
        />
        <Text color="gray.600" numberOfLines={3}>
          {app.name}
        </Text>
      </HStack>
      <HStack flex="1" space="2">
        <Button
          w="20"
          minW="auto"
          rounded="2xl"
          isDisabled={isPicked}
          onPress={() => onGetApp(app)}
        >
          {isPicked ? 'Added' : 'Get app'}
        </Button>
        <Button
          w="24"
          minW="auto"
          variant="outline"
          rounded="2xl"
          borderColor="primary.200"
          onPress={() => onViewApp(app)}
        >
          View app
        </Button>
      </HStack>
    </VStack>
  );
};
