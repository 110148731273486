import { ResponsiveValue } from 'native-base/lib/typescript/components/types';

export const styleConfig = {
  buttonStackDirection: {
    base: 'column-reverse',
    md: 'row',
  } as ResponsiveValue<'column' | 'row' | 'column-reverse' | 'row-reverse'>,
  buttonWidth: { base: '100%', md: '50%' },
  grid: {
    display: 'grid',
    grid: 'auto / 1fr 1fr',
    rowGap: 24,
    columnGap: 16,
  },
  previewBtn: (selectedLocation: boolean) => ({
    bgColor: selectedLocation ? 'primary.50' : 'gray.300',
    borderWidth: selectedLocation ? null : '1px',
    borderColor: selectedLocation ? null : 'gray.500',
  }),
  txtPreviewBtn: (selectedLocation: boolean) => ({
    color: selectedLocation ? 'primary.500' : 'gray.600',
  }),
};
