export const VALID_IMAGE_MIME_TYPES = ['image/jpeg', 'image/png', 'image/webp'];
export const VALID_EXTENSIONS = ['jpg', 'jpeg', 'png', 'webp'];
export const MAX_SIZE_IN_BYTES = 3 * 1024 * 1024; // 3MB
export const MIN_WIDTH = 0;
export const MIN_HEIGHT = 0;

// DROP ZONE
export const filesAccepted = {
  'image/jpeg': [],
  'image/png': [],
  'image/jpg': [],
};
