import { useRef } from 'react';
import { IModalRefProps } from 'ui/components/Modals/Modal/types';

import { useAppSelector } from '~/hooks/useAppSelector';
import ActivityReportService from '~/services/resources/activityReport';

export const useActivitiesReportController = () => {
  const { organizationSelectedId } = useAppSelector(({ auth }) => auth);
  const reportModalRef = useRef<IModalRefProps>(null);

  const openModal = () => {
    reportModalRef.current?.open();
  };

  const handleExportVolunteersReport = async () => {
    if (!organizationSelectedId) return;
    await ActivityReportService.volunteersCountDownload(organizationSelectedId);
  };
  return {
    handleExportVolunteersReport,
    openModal,
    reportModalRef,
  };
};
