import './index.css';

import { VStack } from 'native-base';
import React from 'react';

import { Applications } from '~/pages/Authenticated/Insights/components/Applications';
import { CharitiesHelped } from '~/pages/Authenticated/Insights/components/CharitiesHelped';
import { EmployeesEngagement } from '~/pages/Authenticated/Insights/components/EmployeesEngagement';
import { FavoriteCauses } from '~/pages/Authenticated/Insights/components/FavoriteCauses';
import { MostCompletedActivities } from '~/pages/Authenticated/Insights/components/MostCompletedActivities';
import { PreferableActivities } from '~/pages/Authenticated/Insights/components/PreferableActivities';
import { SpaceOptions } from '~/pages/Authenticated/Insights/components/SpaceOptions';
import { TotalHoursByEmployees } from '~/pages/Authenticated/Insights/components/TotalHoursByEmployees';
import { TrendingTab } from '~/pages/Authenticated/Insights/components/Trending/TrendingTab';
import { VolunteeringHours } from '~/pages/Authenticated/Insights/components/VolunteeringHours';
import { InsightsHeader } from '~/pages/Authenticated/Insights/InsightsHeader';
import { InsightsLastUpdatedHeader } from '~/pages/Authenticated/Insights/InsightsLastUpdatedHeader';

export const Insights: React.FC = () => {
  return (
    <VStack alignItems={'center'}>
      {/* Header */}
      <InsightsHeader />

      {/* Main */}
      <VStack w="full" h="full" maxW="1720px" px={8} pb={8} pt={4}>
        {/* TODO: implement this component */}
        {/* <InsightsLastUpdatedHeader /> */}

        {/* Leading */}
        <TrendingTab></TrendingTab>

        {/* Content */}
        <div className="dashboard">
          <TotalHoursByEmployees className="total-hours" />
          <VolunteeringHours className="volunteering-hours" />
          <EmployeesEngagement className="engagement" />
          <FavoriteCauses className="favorite-causes" />
          <SpaceOptions className="space-options" />
          <MostCompletedActivities className="completed-activities" />
          <Applications className="applications" />
          <PreferableActivities className="preferable-activities" />
          <CharitiesHelped className="charities-helped" />
        </div>
      </VStack>
    </VStack>
  );
};
