import Yup from '~/utils/validations/yup';

export const defaultCoolDownTime = 60;

export const schemaValidation = Yup.object({
  accessCode: Yup.string().required(),
});

export const defaultValues = {
  accessCode: '',
};
