import { FlatList, VStack } from 'native-base';
import { useEffect } from 'react';
import { useQuery } from 'react-query';
import { formatMeasurementAmount } from 'ui/utils/measurements/format';

import { InsightsGenericEmptyState } from '~/pages/Authenticated/Insights/components/EmptyState';
import { InsightScaffoldChildrenReactComponent } from '~/pages/Authenticated/Insights/components/InsightScaffold';
import { departmentsSkeletonData } from '~/pages/Authenticated/Insights/components/VolunteeringHours/utils';
import {
  VolunteeringHourItem,
  VolunteeringHourItemSkeleton,
} from '~/pages/Authenticated/Insights/components/VolunteeringHours/VolunteeringHourItem';
import {
  InsightPageFilters,
  useInsightFilters,
} from '~/pages/Authenticated/Insights/useInsightFilters';
import { InsightCacheConfig } from '~/pages/Authenticated/Insights/utils';
import InsightMeasurementService from '~/services/resources/insightMeasurement';
import MeasurementInsightsService from '~/services/resources/insights/MeasurementInsightsService';
import { BaseInsightQueryParams } from '~/services/resources/types';

const buildQueryKey = (filters: InsightPageFilters) => {
  const params: BaseInsightQueryParams = {
    apps: filters.apps,
    ecosystem: filters.selectedEcosystemId,
    organization: filters.selectedOrganizationId,
    startDate: filters.from!,
    endDate: filters.to!,
    granularity: 'Daily',
  };
  return [InsightMeasurementService.MEASUREMENT_REPORT_KEY, params];
};

export const VolunteeringHoursDepartments: InsightScaffoldChildrenReactComponent =
  ({ onSetChart }) => {
    const { filters, hasFilterSetted } = useInsightFilters();

    const {
      data: response,
      isFetching,
      isLoading,
    } = useQuery({
      queryKey: buildQueryKey(filters),
      queryFn: ({ queryKey }) => {
        const [, params] = queryKey;
        return MeasurementInsightsService.getDepartmentsMeasurementMetrics(
          params as BaseInsightQueryParams,
        );
      },
      enabled: hasFilterSetted,
      ...InsightCacheConfig,
    });

    useEffect(() => {
      onSetChart(
        null,
        response?.map((item) => ({
          ...item.userProfileDepartment,
          totalInSeconds: item.total,
          totalInHours: formatMeasurementAmount(item.total),
        })) ?? [],
      );

      return () => {
        onSetChart(null, []);
      };
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [response]);

    return (
      <VStack mb={6} flex={1}>
        <FlatList
          maxH={'420px'}
          height={'full'}
          scrollEnabled
          keyExtractor={(item) => `${item.userProfileDepartment._id}`}
          data={
            isLoading || isFetching ? departmentsSkeletonData : response ?? []
          }
          renderItem={({ item, index }) =>
            isLoading || isFetching ? (
              <VolunteeringHourItemSkeleton />
            ) : (
              <VolunteeringHourItem
                hours={item.total}
                shouldRenderAvatar={false}
                name={item.userProfileDepartment?.name || 'Unnamed'}
                index={index}
              />
            )
          }
          ItemSeparatorComponent={() => <VStack height={4} />}
        />
        {!isLoading && !response?.length && (
          <InsightsGenericEmptyState showActionButton />
        )}
      </VStack>
    );
  };
