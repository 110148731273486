import { Stack, Text } from 'native-base';

import Page403 from '~/components/Page403';
import { Permissions } from '~/components/Permissions';
import { CreateEditActivityPermissions } from '~/constants/permissions.org.constants';

import { ActionForm } from './ActionForm';

export const AddAction = () => {
  return (
    // <Permissions.ValidateAll
    //   requiredPermissions={CreateEditActivityPermissions.Action}
    //   fallbackComponent={<Page403 />}
    // >
    <Stack>
      <ActionForm />
    </Stack>
    // </Permissions.ValidateAll>
  );
};
