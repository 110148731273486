import { HStack, Skeleton, Stack, Text } from 'native-base';
import { Fragment } from 'react';
import Button from 'ui/components/Button';
import { CustomSwitch } from 'ui/components/CustomSwitch';

import { emailPreferencesOptions } from './constants';
import { LoadingEmailPreferences } from './loadingAdminProfile';
import { useEmailPreferencesController } from './useEmailPreferencesController';

export const EmailPreferences = () => {
  const {
    control,
    isLoadingAdminProfile,
    handleSaveEmailPreferences,
    isSubmitting,
  } = useEmailPreferencesController();
  return (
    <Stack space={10}>
      {isLoadingAdminProfile ? (
        <LoadingEmailPreferences />
      ) : (
        <HStack width="100%" justifyContent="space-between">
          <Stack space={2}>
            <Text fontSize="xl" fontWeight="500" color="gray.600">
              Email preferences
            </Text>
            <Text fontSize="sm" fontWeight="400" color="gray.600">
              Email and communications preferences
            </Text>
          </Stack>
          <Button
            maxW="124px"
            variant="solid"
            onPress={handleSaveEmailPreferences}
            isDisabled={isSubmitting}
            isLoading={isSubmitting}
          >
            Save changes
          </Button>
        </HStack>
      )}
      <Stack width="100%" space={8}>
        {emailPreferencesOptions?.map((item, index) => (
          <Fragment key={index}>
            {isLoadingAdminProfile ? (
              <Stack space={4}>
                <HStack space={2} alignItems="center">
                  <Skeleton height="66px" width="95%" />
                  <Skeleton height="15px" width="5%" />
                </HStack>
              </Stack>
            ) : (
              <CustomSwitch
                control={control}
                name={item.name}
                title={item.title}
                description={item.description}
              />
            )}
          </Fragment>
        ))}
      </Stack>
    </Stack>
  );
};
