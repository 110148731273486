import { HStack, Stack, Text, VStack } from 'native-base';
import DataTable from 'ui/components/DataTable';

import { ModalEditExternalId } from '~/pages/Authenticated/Members/Member/MembersList/components/ModalEditExternalId';
import { MembersListProps } from '~/pages/Authenticated/Members/types';

import { DownloadReportButton } from '../../../../../components/DownloadReportButton';
import ModalDelete from '../../../../../components/ModalDelete';
import { ModalReportProcessing } from '../../../../../components/ModalReportProcessing';
import { useMembersListController } from './controller';
import { tableColumns } from './tableColumns';
import { defaultFiltersValues } from './utils';

const MembersList = ({ organization }: MembersListProps) => {
  const {
    memberId,
    loadMembers,
    membersCount,
    handleViewMember,
    handleRemoveMember,
    handleCloseModal,
    handleOpenModalEditExternalId,
    isSubmitting,
    reportModalRef,
    modalRef,
    modalExternalIdRef,
    handleOpenModalRemoveMember,
    refetchQuantity,
    refetchMembers,
    handleExportMembersLogHoursReport,
    handleOpenReportModal,
  } = useMembersListController();

  return (
    <Stack w="full">
      <Stack background="white" borderRadius="2xl">
        <HStack p={6} justifyContent={'space-between'} alignItems={'center'}>
          <VStack space={2}>
            <Text color="gray.600" fontSize="2xl" fontWeight={500}>
              {membersCount} {membersCount === 1 ? 'member' : 'members'}
            </Text>
            <Text color="gray.600" fontSize="sm" fontWeight={500}>
              View and manage all members in your organisation
            </Text>
          </VStack>
          <DownloadReportButton
            title="Export log-hours report"
            onPress={handleOpenReportModal}
          />
        </HStack>
        <Stack width="100%" p={6}>
          <DataTable
            defaultFilterValues={defaultFiltersValues}
            loadData={loadMembers}
            refetchQuantity={refetchQuantity}
            columns={tableColumns(
              {
                onView: handleViewMember,
                onDelete: handleOpenModalRemoveMember,
                onEdit: handleOpenModalEditExternalId,
              },
              organization?.organizationSettings,
            )}
          />
        </Stack>
      </Stack>
      <ModalEditExternalId
        refetchMembers={refetchMembers}
        memberId={memberId}
        modalRef={modalExternalIdRef}
      />
      <ModalDelete
        modalRef={modalRef}
        headerText="Remove member"
        handleCloseModal={handleCloseModal}
        handleDelete={handleRemoveMember}
        rightBtnLoading={isSubmitting}
        rightBtnText="Remove"
      >
        <Stack space={5}>
          <Text fontWeight={400} fontSize="md" color="gray.500">
            Are you sure that you want to remove this member?
          </Text>

          <Text fontWeight={400} fontSize="md" color="gray.500">
            The member will no longer have access to the ecosystem and will lose
            all permissions associated with it.
          </Text>
        </Stack>
      </ModalDelete>
      <ModalReportProcessing
        onExportPress={handleExportMembersLogHoursReport}
        modalRef={reportModalRef}
      />
    </Stack>
  );
};

export default MembersList;
