import { useMemo } from 'react';

import { useAppSelector } from '~/hooks/useAppSelector';

export const useEcosystemAvailableApps = () => {
  const { selectedEcosystem, ecosystemApps } = useAppSelector(
    ({ ecosystem }) => ecosystem,
  );

  const value = useMemo(() => {
    const apps = Object.values(ecosystemApps || {});
    if (selectedEcosystem) {
      const targetOrganization = selectedEcosystem.organization;
      apps.sort((a, b) => {
        if (a.organization === targetOrganization) return -1;
        if (b.organization === targetOrganization) return 1;
        return a.organization.localeCompare(b.organization);
      });
    }

    const appIds = apps.map((app) => app._id);

    return { apps, appIds };
  }, [selectedEcosystem, ecosystemApps]);

  return value;
};
