import Yup from '~/utils/validations/yup';

export const schema = [
  Yup.object({
    termsOfServiceAccepted: Yup.boolean().oneOf(
      [true],
      'You must accept the terms of service',
    ),
  }),
  Yup.object({
    ecosystem: Yup.string().required('Ecosystem is required'),
  }),
];

// export const defaultValues = [
//   { termsOfServiceAccepted: false },
//   { ecosystem: '' },
// ];

export const secondStepSchema = Yup.object({
  ecosystem: Yup.string().required('Ecosystem is required'),
});

export interface JoinAsPartnerFormValues {
  termsOfServiceAccepted: boolean;
  ecosystem: string;
}
