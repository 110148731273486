import {
  TableOperatorNames,
  TableOperatorType,
} from 'ui/components/DataTable/types';

export const defaultMemberActivitiesFilterValue = [
  {
    name: 'applicationDate',
    operator: TableOperatorNames.AfterOrOn,
    type: TableOperatorType.Date,
    value: '',
  },
  {
    name: 'activitySubDocument.activityDefinitionSubDocument.title',
    operator: TableOperatorNames.StartsWith,
    type: TableOperatorType.String,
    value: '',
  },
  {
    name: 'activitySubDocument.activityDefinitionSubDocument.organizationSubDocument.name',
    operator: TableOperatorNames.StartsWith,
    type: TableOperatorType.String,
    value: '',
  },
  {
    name: 'activitySubDocument.activityDefinitionSubDocument.type',
    operator: TableOperatorNames.Equals,
    type: TableOperatorType.String,
    value: '',
  },
  {
    name: 'activitySubDocument.address.street',
    operator: TableOperatorNames.Equals,
    type: TableOperatorType.String,
    value: '',
  },
  {
    name: 'activitySubDocument.startDate',
    operator: TableOperatorNames.AfterOrOn,
    type: TableOperatorType.Date,
    value: '',
  },
];
