import { AxiosRequestConfig, AxiosResponse } from 'axios';
import { useQuery as useReactQuery, UseQueryResult } from 'react-query';

import { api } from '~/services/api';
import { openApi } from '~/services/openApi';

import { IUseQueryOptions } from './types';

export function useQuery<TData = unknown, TError = unknown>(
  path: string,
  {
    method = 'get',
    key,
    requestOptions,
    queryOptions,
    isOpenApi,
  }: IUseQueryOptions = {} as IUseQueryOptions,
) {
  const result = useReactQuery(
    key || path,
    async () => {
      const selectedApi = isOpenApi ? openApi : api;
      const response = await selectedApi.request<
        AxiosRequestConfig,
        AxiosResponse<TData>
      >({
        url: path,
        method,
        ...requestOptions,
      });

      return response.data;
    },
    queryOptions,
  );

  return result as UseQueryResult<TData, TError>;
}
