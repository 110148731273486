import { Pressable, VStack } from 'native-base';
import Icon from 'ui/components/Icon';

export type HelpButtonProps = {
  onPress?: VoidFunction;
};

export const HelpButton = ({ onPress }: HelpButtonProps) => {
  return (
    <VStack p={2} h="fit-content">
      <Pressable onPress={onPress}>
        <Icon icon="question" color={'primary.400'} size={4} enableAutoCustom />
      </Pressable>
    </VStack>
  );
};
