import { Stack } from 'native-base';
import { forwardRef, useImperativeHandle } from 'react';

import FormAction from './components/FormAction';
import FormFieldsRender from './components/FormFieldsRender';
import { INPUT_COMPONENT_TYPES } from './components/FormFieldsRender/types';
import FormHeader from './components/FormHeader';
import { IFormBuilderV2Props } from './types';
import { useFormBuilderController } from './useFormBuilderController';

const FormBuilderV2 = forwardRef(function FormBuilder(
  props: IFormBuilderV2Props,
  ref,
) {
  const {
    control,
    watch,
    options,
    handleSubmitForm,
    formState,
    isLoadingSubmit,
  } = useFormBuilderController(props);

  useImperativeHandle(
    ref,
    () => ({
      handleGetControl() {
        return { control, watch };
      },
    }),
    [control, watch],
  );

  return (
    <Stack w="full">
      <FormHeader {...options.header} />
      <FormFieldsRender
        control={options.customControl ? options.customControl : control}
        formState={formState}
        inputsSections={options.inputsSections}
      />
      <FormAction
        {...options.actions}
        formState={formState}
        isLoadingSubmit={isLoadingSubmit}
        handleSubmitForm={handleSubmitForm}
      />
    </Stack>
  );
});

export default FormBuilderV2;
export { INPUT_COMPONENT_TYPES };
