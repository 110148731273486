import FeatherIcon from 'feather-icons-react';
import { Box, FormControl, Text } from 'native-base';
import React from 'react';
import { Controller } from 'react-hook-form';
import ReactSelect from 'react-select';

import { colors } from '../../theme/colors';
import { IOptionsItem, ISelectProps } from './types';
import { useSelectController } from './useSelectController';

export const Select = ({
  control,
  name,
  isMulti,
  hasError,
  isDisabled,
  helperText,
  iconErrorMessage,
  leftIconErrorSize = 16,
  errorMessage,
  label,
  isRequired,
  options,
  hasOptionsGroup,
  usePortal = true,
  fontSize = 12,
  ...restProps
}: ISelectProps) => {
  const { handleOnChange, handleGetValue, defaultStyle } = useSelectController({
    options,
    fontSize,
    isMulti,
    hasError,
    errorMessage,
    hasOptionsGroup,
  });

  return (
    <>
      {label ? (
        <FormControl.Label>
          {label}
          {isRequired ? <Text color="error.600">*</Text> : null}
        </FormControl.Label>
      ) : null}
      <Controller
        control={control}
        name={name}
        render={({ field: { onChange, value, ...restField } }) => (
          <ReactSelect
            {...restField}
            {...restProps}
            options={options}
            menuPortalTarget={usePortal ? document.body : undefined}
            styles={defaultStyle}
            defaultValue=""
            isDisabled={isDisabled}
            value={handleGetValue(value)}
            isMulti={isMulti}
            onChange={(value, metaData) => {
              restProps.onChange?.(value, metaData);
              onChange(handleOnChange(value as IOptionsItem | IOptionsItem[]));
            }}
          />
        )}
      />
      {!hasError && !errorMessage && helperText ? (
        <FormControl.HelperText ml={2}>{helperText}</FormControl.HelperText>
      ) : null}
      {errorMessage ? (
        <Box mt={2} pl={2}>
          {iconErrorMessage ? (
            <FeatherIcon
              icon={iconErrorMessage}
              size={leftIconErrorSize}
              color={colors.error[400]}
            />
          ) : null}
          <Text fontSize="xs" fontWeight={400} color="error.600">
            {errorMessage}
          </Text>
        </Box>
      ) : null}
    </>
  );
};

export default React.memo(Select);
