import { Stack, Text } from 'native-base';
import Illustration, { ILLUSTRATIONS_NAME } from 'ui/components/Illustration';

export const GeneratingReport = () => {
  return (
    <Stack
      space={10}
      height="100vh"
      alignItems="center"
      justifyContent="center"
      p={5}
    >
      <Illustration
        maxWidth="313px"
        maxHeight="255px"
        width="100%"
        height="100%"
        name={ILLUSTRATIONS_NAME.LOADING}
      />
      <Stack space={3} alignItems="center">
        <Text fontWeight="500" fontSize="2xl">
          We are generating your report
        </Text>
        <Text fontSize="md" fontWeight={400}>
          It will be sent to your inbox in a few moments.
        </Text>
      </Stack>
    </Stack>
  );
};
