import { HStack, Stack, Text, VStack } from 'native-base';
import DataTable from 'ui/components/DataTable';

import ModalDelete from '~/components/ModalDelete';

import { DownloadReportButton } from '../../../../../components/DownloadReportButton';
import { ModalReportProcessing } from '../../../../../components/ModalReportProcessing';
import { useChildOrgsListController } from './controller';
import { tableColumns } from './tableColumns';

const ChildOrgsList = () => {
  const {
    modalRef,
    organizationSelectedId,
    isSubmitting,
    refetchQuantity,
    showPartnersCountText,
    loadPartners,
    handleCloseModal,
    handleOpenModalRemovePartner,
    handleRemovePartner,
    handleViewPartner,
    handleOpenReportModal,
    reportModalRef,
    handleExportChildOrgReport,
    defaultFilterValues,
  } = useChildOrgsListController();

  return (
    <Stack w="full">
      <Stack background="white" borderRadius="2xl">
        <HStack p={6} justifyContent={'space-between'} alignItems={'center'}>
          <VStack space={2}>
            <Text color="gray.600" fontSize="2xl" fontWeight={500}>
              {showPartnersCountText()}
            </Text>
            <Text color="gray.600" fontSize="sm" fontWeight={500}>
              View and manage all Childs Organisations
            </Text>
          </VStack>
          <DownloadReportButton
            title="Export child orgs applications"
            onPress={handleOpenReportModal}
          />
        </HStack>
        <Stack width="100%" p={6}>
          <DataTable
            defaultFilterValues={defaultFilterValues}
            loadData={loadPartners}
            refetchQuantity={refetchQuantity}
            columns={tableColumns(
              {
                onView: handleViewPartner,
                onDelete: handleOpenModalRemovePartner,
              },
              organizationSelectedId || '',
            )}
          />
        </Stack>
      </Stack>
      <ModalDelete
        modalRef={modalRef}
        headerText="Remove partner"
        handleCloseModal={handleCloseModal}
        handleDelete={handleRemovePartner}
        rightBtnLoading={isSubmitting}
        rightBtnText="Remove"
      >
        <Stack space={5}>
          <Text fontWeight={400} fontSize="md" color="gray.500">
            Are you sure that you want to remove this partner?
          </Text>

          <Text fontWeight={400} fontSize="md" color="gray.500">
            The members and admins will no longer have access to the ecosystem
            and will lose all permissions associated with it.
          </Text>
        </Stack>
      </ModalDelete>
      <ModalReportProcessing
        onExportPress={handleExportChildOrgReport}
        modalRef={reportModalRef}
      />
    </Stack>
  );
};

export default ChildOrgsList;
