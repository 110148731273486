import { Hidden, Stack, Text } from 'native-base';
import Input from 'ui/components/Input';
import { colors } from 'ui/theme/colors';

import { SwitchEcosystemNavBarProps } from '~/pages/Authenticated/SwitchEcosystem/components/SwitchEcosystemNavBar/types';

export const SwitchEcosystemNavBar = ({
  control,
  handleOnChangeText,
}: SwitchEcosystemNavBarProps) => {
  return (
    <Stack alignItems="center" justifyContent="center">
      <Hidden till="lg">
        <Text color="gray.600" fontSize="xl" fontWeight={500}>
          Click on a ecosystem to enter
        </Text>
      </Hidden>

      <Stack width="100%" maxW="450px" mt={{ lg: 4 }}>
        <Input
          name="search"
          control={control}
          label="Search"
          labelColor={colors.gray[600]}
          placeholder="Search ecosystem..."
          leftIconName="search"
          leftIconSize={16}
          leftIconColor={colors.gray[400]}
          onChangeText={handleOnChangeText}
        />
      </Stack>
    </Stack>
  );
};
