export const TOO_LARGE_FILE_ERROR = 'The image is too large';
export const TOO_SMALL_FILE_ERROR = 'The image size is too small!';
export const TOO_SMALL_FILE_WIDTH_ERROR =
  'The image width is too small, the minimum width is ';
export const TOO_SMALL_FILE_HEIGHT_ERROR =
  'The image height is too small, the minimum height is ';
export const INVALID_FILE_FORMAT_ERROR = 'The image is the wrong format';
export const DEFAULT_FILE_ERROR = "Couldn't find the file";
export const BLOB_FILE_PARSE_ERROR = 'Failed to parse file';
export const FILE_REMOVE_ERROR = 'Failed to remove file';
export const TOO_MANY_FILES_ERROR = (maxFiles: number) =>
  `The maximum number of images allowed is ${maxFiles}`;
