import FeatherIcon from 'feather-icons-react';
import { Box, Checkbox as NBCheckbox, FormControl } from 'native-base';
import { Controller } from 'react-hook-form';

import { colors } from '../../theme/colors';
import CheckboxItem from './CheckboxItem';
import { ICustomCheckboxProps } from './types';
import { useCheckboxController } from './useCheckboxController';

const Checkbox = (props: ICustomCheckboxProps) => {
  const {
    restProps,
    _formControl,
    control,
    name,
    helperText,
    hasError,
    options,
    isInvalid,
    isDisabled,
    isReadOnly,
    isRequired,
    iconErrorSize,
    errorMessage,
    iconErrorMessage,
    label,
    marginYItem,
  } = useCheckboxController(props);

  if (!options?.length) {
    return null;
  }

  return (
    <Box alignItems="center">
      <FormControl
        isInvalid={hasError || errorMessage ? true : isInvalid}
        isDisabled={isDisabled}
        isRequired={isRequired}
        isReadOnly={isReadOnly}
        {..._formControl}
      >
        {label ? <FormControl.Label>{label}</FormControl.Label> : null}
        <Controller
          control={control}
          name={name}
          render={({ field: { onChange, value } }) => (
            <NBCheckbox.Group
              {...restProps}
              value={value || []}
              onChange={onChange}
            >
              {options.map((item, index) => (
                <CheckboxItem key={index} {...item} my={marginYItem} />
              ))}
            </NBCheckbox.Group>
          )}
        />
        {!hasError && !errorMessage && helperText ? (
          <FormControl.HelperText ml={2}>{helperText}</FormControl.HelperText>
        ) : null}
        {errorMessage ? (
          <FormControl.ErrorMessage
            ml={2}
            leftIcon={
              iconErrorMessage ? (
                <FeatherIcon
                  icon={iconErrorMessage}
                  size={iconErrorSize}
                  color={colors.error[400]}
                />
              ) : undefined
            }
          >
            {errorMessage}
          </FormControl.ErrorMessage>
        ) : null}
      </FormControl>
    </Box>
  );
};

export default Checkbox;
