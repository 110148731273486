import { Box, Flex, HStack, Stack, Text } from 'native-base';
import React from 'react';
import Button from 'ui/components/Button';
import Illustration, { ILLUSTRATIONS_NAME } from 'ui/components/Illustration';

// import { InviteMemberModal } from 'ui/components/InviteMemberModal';
import { useAdminSuccessfullyJoinedController } from './controller';
import { styles } from './styles';

const AdminSuccessfullyJoined: React.FC = () => {
  const {
    organisationName,
    // isInviteModalOpen,
    // inviteLink,
    goToDashBoard,
    // toggleModal,
  } = useAdminSuccessfullyJoinedController();

  return (
    <>
      <Flex minH="100vh" justifyContent="center" bg="gray.100">
        <HStack
          alignItems="center"
          space={24}
          w="min(90%, 1300px)"
          mx="auto"
          flexDirection={styles.flexDirection}
        >
          <Box w="50%">
            <Illustration
              resizeMode="contain"
              w="100%"
              height="600px"
              name={ILLUSTRATIONS_NAME.SUCCESS_COMPLETE_CORPORATE}
              alt="PasswordRecovery"
            />
          </Box>
          <Box flex="1">
            <Box w="80%">
              <Box mt="80px">
                <Text
                  textAlign="initial"
                  fontSize="3xl"
                  fontWeight={700}
                  color="gray.900"
                  maxWidth="460px"
                >
                  {organisationName
                    ? `You are now an administrator at “${organisationName}”!`
                    : 'You are now an administrator!'}
                </Text>
                <Text mt="3" color="gray.900" fontSize="lg">
                  Invite your employees to be part of your company.
                </Text>
              </Box>
              <Stack width="100%" mt="4">
                <Box mb={4} />
              </Stack>
              {/* TODO: link to volunteer's invite flow */}
              {/* <Stack mt={4} mb={3}>
                <Button onPress={toggleModal} p={6} variant="solid">
                  <Text fontSize="medium" color="white" fontWeight="medium">
                    Invite members
                  </Text>
                </Button>
              </Stack> */}
              <Stack mb={6}>
                <Button p={6} variant="solid" onPress={goToDashBoard}>
                  <Text fontSize="medium" color="white" fontWeight="medium">
                    Go to dashboard
                  </Text>
                </Button>
              </Stack>
            </Box>
          </Box>
        </HStack>
      </Flex>
      {/* TODO: link to volunteer's invite flow */}
      {/* <InviteMemberModal
        isOpen={isInviteModalOpen}
        link={inviteLink}
        onPressPrimaryButton={toggleModal}
        onPressSecondaryButton={goToDashBoard}
      /> */}
    </>
  );
};

export default AdminSuccessfullyJoined;
