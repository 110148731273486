import { VStack } from 'native-base';

import { LogsSummaryTileContent } from './components/LogsSummaryTileContent';
import { LogsSummaryTileHeader } from './components/LogsSummaryTileHeader';
import { LogsSummaryTileProps } from './types';

export const LogsSummaryTile = ({
  appName,
  organizationName,
  activityTitle,
  measurementSummaries,
}: LogsSummaryTileProps) => {
  return (
    <VStack
      padding={4}
      borderRadius="xl"
      borderWidth={1}
      borderColor="gray.300"
      bgColor="gray.100"
      space={4}
    >
      <LogsSummaryTileHeader
        appName={appName}
        activityTitle={activityTitle}
        organizationName={organizationName}
      />

      {measurementSummaries.map((measurement, index) => {
        return (
          <LogsSummaryTileContent
            key={index}
            measurementUnit={measurement.measurementUnit}
            measurementsTotal={measurement.amount}
            measurementsTarget={measurement.target}
          />
        );
      })}
    </VStack>
  );
};
