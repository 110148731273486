import { ICreateEventDTO } from '~/types/dtos';

export interface MultipleLocationsProps {
  handleContinue: (data: ICreateEventDTO) => void;
  handleBack: () => void;
  defaultValues?: object;
  defaultLocationGroupsByDate?: Record<number, LocationData[]>;
  isLoading?: boolean;
  isTeamEvent?: boolean;
}

export enum LocationDataStatus {
  OK = 'OK',
  ZERO_RESULTS = 'ZERO_RESULTS',
}
export interface LocationData {
  returnedAddress: string;
  location:
    | {
        lat: number;
        lng: number;
      }
    | undefined;
  sentInput: string;
  status: LocationDataStatus;
  _activityId?: string;
}
