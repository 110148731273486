import { yupResolver } from '@hookform/resolvers/yup';
import { useCallback, useMemo } from 'react';
import { useFieldArray, useForm } from 'react-hook-form';
import { EActivityAttendanceTypes, EEventApplicationType } from 'ui/enums';

import { ICreateActivityDTO, IDatesDTO } from '~/types/dtos';

import { SingleLocationProps } from './types';
import { formInitialDefaultValues, singleSchemaValidation } from './utils';

export const useSingleLocationController = ({
  handleContinue,
  handleBack,
  isLoading,
  isTeamEvent,
}: SingleLocationProps) => {
  const {
    control,
    handleSubmit,
    watch,
    formState: { isValid, errors },
    setValue,
  } = useForm<ICreateActivityDTO>({
    resolver: yupResolver(singleSchemaValidation),
    mode: 'onChange',
    reValidateMode: 'onBlur',
    defaultValues: {
      ...formInitialDefaultValues,
      eventApplicationType: isTeamEvent
        ? EEventApplicationType.Team
        : EEventApplicationType.Individual,
    },
  });

  const {
    fields: datesFields,
    append: appendDate,
    remove: removeDate,
  } = useFieldArray<ICreateActivityDTO>({
    control,
    name: 'dates',
  });

  const handleSubmitForm = handleSubmit((data: ICreateActivityDTO) => {
    handleContinue(data);
  });

  const attendanceType = watch('attendanceType');

  const { isDisabledPublishButton } = useMemo(() => {
    return {
      isRemoteEvent: attendanceType === EActivityAttendanceTypes.Remote,
      isDisabledPublishButton: !isValid,
    };
  }, [attendanceType, isValid]);

  const addDateForm = useCallback(
    (placeIndex: number, nextIndexPosition: number) => {
      appendDate({
        placeFormIndex: placeIndex,
        indexPosition: nextIndexPosition,
        volunteerNumber: undefined,
        noVolunteerLimit: false,
        startDate: undefined,
        endDate: undefined,
        dueDate: undefined,
        teamsNumber: undefined,
        teamsMaxSize: undefined,
        teamsMinSize: undefined,
      } as IDatesDTO);
    },
    [appendDate],
  );

  const removeDateForm = useCallback(
    (dateIndex: number) => {
      removeDate(dateIndex);
    },
    [removeDate],
  );

  return {
    datesFields,
    setValue,
    appendDate,
    addDateForm,
    removeDateForm,
    handleBack,
    handleSubmitForm,
    control,
    isLoading,
    isDisabledPublishButton,
    errors,
    isTeamEvent,
  };
};
