import { useCallback, useRef, useState } from 'react';
import { toast } from 'react-toastify';
import { IFilter } from 'ui/components/DataTable/types';
import { IModalRefProps } from 'ui/components/Modals/Modal/types';

import { PAGES } from '~/constants/pages.constants';
import { useLoadAppPermissionGroupCountByOrganization } from '~/hooks/counts/useLoadAppGroupPermissionCountByOrg';
import { useAppSelector } from '~/hooks/useAppSelector';
import { useLoadAllAppSharingsByOrganization } from '~/hooks/useLoadAllAppSharingsByOrganization';
import { useLoadAppPermissionsGroupByOrganization } from '~/hooks/useLoadAppPermissionsGroupByOrganization';
import { useRouter } from '~/hooks/useRouter';
import { AppPermissionGroupService } from '~/services/resources/appPermissionGroup';
import { QueryParams } from '~/services/resources/types';

export const usePermissionsController = () => {
  const [
    publishedPermissionsGroupQueryParams,
    setPermissionsGroupPublishedQueryParams,
  ] = useState<QueryParams | undefined>(undefined);
  const [
    draftPermissionsGroupQueryParams,
    setDraftPermissionsGroupQueryParams,
  ] = useState<QueryParams | undefined>(undefined);

  const [isPublishingPermissionGroup, setIsPublishingPermissionGroup] =
    useState(false);

  const [isDeletingPermissionGroup, setIsDeletingPermissionGroup] =
    useState(false);

  const [appPermissionGroupId, setAppPermissionGroupId] = useState('');

  const [currentTab, setCurrentTab] = useState(0);

  const modalRef = useRef<IModalRefProps>(null);
  const publishDraftModalRef = useRef<IModalRefProps>(null);
  const deleteModalRef = useRef<IModalRefProps>(null);

  const { organizationSelectedId } = useAppSelector(({ auth }) => auth);
  const { goToRoute } = useRouter();

  const {
    data: permissionsGroupPublished,
    isLoading: isLoadingAppPermissionsGroup,
    refetch: refetchPermissionsGroupPublished,
  } = useLoadAppPermissionsGroupByOrganization(
    organizationSelectedId,
    publishedPermissionsGroupQueryParams,
  );

  const {
    data: draftPermissionsGroup,
    isLoading: isLoadingDraftPermissionsGroup,
    refetch: refetchDraftPermissionsGroup,
  } = useLoadAppPermissionsGroupByOrganization(
    organizationSelectedId,
    draftPermissionsGroupQueryParams,
  );

  const {
    data: totalDraftPermissionsGroup,
    isLoading: isLoadingTotalDraftPermissionsGroup,
  } = useLoadAppPermissionGroupCountByOrganization(
    organizationSelectedId || '',
  );

  const { appsConnectedToOrganization, isLoadingAppsConnectedToOrganization } =
    useLoadAllAppSharingsByOrganization(organizationSelectedId || '');

  const openModalInformation = () => modalRef.current?.open();

  const loadPermissionsPublished = useCallback(
    async (skip: number, limit: number, filter: IFilter) => {
      setPermissionsGroupPublishedQueryParams({
        skip,
        limit,
        filter: JSON.stringify({ isDraft: false, ...filter }),
      });
      if (permissionsGroupPublished?.data) {
        const data = permissionsGroupPublished?.data;
        const count = permissionsGroupPublished?.count;
        return { data, count };
      }
      return { data: [], count: 0 };
    },
    [permissionsGroupPublished],
  );

  const loadDraftPermissions = useCallback(
    async (skip: number, limit: number, filter: IFilter) => {
      setDraftPermissionsGroupQueryParams({
        skip,
        limit,
        filter: JSON.stringify({ isDraft: true, ...filter }),
      });
      if (draftPermissionsGroup?.data) {
        const data = draftPermissionsGroup?.data;
        const count = draftPermissionsGroup?.count;
        return { data, count };
      }
      return { data: [], count: 0 };
    },
    [draftPermissionsGroup],
  );

  const handleGoToNewPermission = () => {
    goToRoute(PAGES.NEW_PERMISSION_GROUP);
  };

  const onEditPress = (id: string) => {
    goToRoute(`/permissions/${id}/edit`);
  };

  const onAssignPress = (id: string) => {
    goToRoute(`/settings/permissions/${id}/assign`);
  };

  const onDeletePress = (id: string) => {
    setAppPermissionGroupId(id);
    deleteModalRef?.current?.open();
  };

  const closeDeleteModal = () => {
    deleteModalRef?.current?.close();
  };

  const handleChangeTab = (index: number) => {
    setCurrentTab(index);
  };

  const onPublishDraft = async (id: string) => {
    setAppPermissionGroupId(id);
    publishDraftModalRef?.current?.open();
  };

  const closePublishDraftModal = () => {
    publishDraftModalRef?.current?.close();
  };

  const handlePublishDraftGroup = async () => {
    try {
      if (!appPermissionGroupId) return;
      setIsPublishingPermissionGroup(true);
      await AppPermissionGroupService.updateDraft(appPermissionGroupId, {
        isDraft: false,
      });
      refetchDraftPermissionsGroup();
      refetchPermissionsGroupPublished();
      setAppPermissionGroupId('');
      publishDraftModalRef?.current?.close();
      toast.success(`Group published successfully.`);
    } catch (error) {
      toast.error(`Error on trying to publish group.`);
    } finally {
      setIsPublishingPermissionGroup(false);
    }
  };

  const handleDeleteGroup = async () => {
    try {
      setIsDeletingPermissionGroup(true);
      if (!appPermissionGroupId) return;
      await AppPermissionGroupService.delete(appPermissionGroupId);

      refetchDraftPermissionsGroup();
      refetchPermissionsGroupPublished();

      setAppPermissionGroupId('');
      deleteModalRef?.current?.close();
      toast.success(`Group deleted successfully.`);
    } catch (error) {
      toast.error(`Error on trying to delete group.`);
    } finally {
      setIsDeletingPermissionGroup(false);
    }
  };

  const isLoading =
    isLoadingAppPermissionsGroup ||
    isLoadingAppsConnectedToOrganization ||
    isLoadingTotalDraftPermissionsGroup;

  return {
    publishDraftModalRef,
    modalRef,
    currentTab,
    isLoading,
    draftPermissionsGroup,
    isLoadingDraftPermissionsGroup,
    loadPermissionsPublished,
    loadDraftPermissions,
    openModalInformation,
    appsConnectedToOrganization,
    handleGoToNewPermission,
    onEditPress,
    onAssignPress,
    handleChangeTab,
    totalDraftPermissionsGroup,
    onPublishDraft,
    handlePublishDraftGroup,
    isPublishingPermissionGroup,
    closePublishDraftModal,
    onDeletePress,
    closeDeleteModal,
    handleDeleteGroup,
    deleteModalRef,
    isDeletingPermissionGroup,
  };
};
