import { HStack, Text, VStack } from 'native-base';
import { useForm } from 'react-hook-form';
import Button from 'ui/components/Button';
import Modal from 'ui/components/Modals/Modal';
import TextArea from 'ui/components/TextArea';

import { AddLocationsModalProps } from './types';

export const AddLocationsModal = ({
  modalRef,
  onAddAddresses,
  onCloseModal,
  existingAddress,
}: AddLocationsModalProps) => {
  const { control, getValues } = useForm<{ addresses: string }>({
    values: {
      addresses: existingAddress as string,
    },
  });
  return (
    <Modal headerText="Add Address or Postcode" ref={modalRef} size="xl">
      <VStack>
        <Text my={4} color="gray.600" fontSize="md">
          Type or paste address or postcodes. You can add multiple locations at
          once by separating them with a semicolon ( ; ).
        </Text>

        <VStack>
          <TextArea
            control={control}
            name="addresses"
            label="Address or Postcode"
            placeholder="Type or paste address or postcodes"
            h="150px"
          />
        </VStack>
        <HStack mt={12} bg="gray.50" w="full">
          <Button variant="link" w="50%" onPress={onCloseModal}>
            <Text fontSize="16px" fontWeight={500} color="singletons.black">
              Cancel
            </Text>
          </Button>
          <Button
            colorScheme="error"
            w="50%"
            bgColor="primary.400"
            onPress={() => onAddAddresses?.(getValues('addresses'))}
          >
            <Text fontSize="16px" fontWeight={500} color="singletons.white">
              Add
            </Text>
          </Button>
        </HStack>
      </VStack>
    </Modal>
  );
};
