export enum PAGES {
  REDIRECT = '/redirect',

  //Unauthenticated routes
  SIGNIN = '/sign-in',
  SIGN_UP = '/sign-up',
  SIGN_UP_EMAIL_CONFIRMATION = '/sign-up/confirm-email',
  SIGN_UP_CREATE_ORGANISATION = '/sign-up/create-organisation',
  SIGN_UP_CREATE_COMPANY_SUCCESSFULLY = '/sign-up/create-company/successful',
  SIGN_UP_INCOMPLETE = '/signup/incomplete',
  FORGOT_PASSWORD = '/forgot-password',
  RECOVERY_PASSWORD = '/password-recovery',
  CONFIRM_EMAIL = '/register/confirm-email',

  //Authenticated routes
  ROOT = '/',
  HOME = '/home',
  ACTIVITIES = '/activities',
  INSIGHTS = '/insights',

  // Event Screens
  ADD_EVENT = '/activities/events/new',
  EDIT_EVENT = '/activities/events/edit',
  ADD_EVENT_SUCCESS = '/activities/events/new/success',
  EDIT_EVENT_SUCCESS = '/activities/events/edit/success',
  VIEW_EVENT_ACTIVITY = '/activities/event',

  // Action Screens
  ADD_ACTION = '/activities/actions/new',
  EDIT_ACTION = '/activities/actions/edit',
  ADD_ACTION_SUCCESS = '/activities/actions/new/success',
  EDIT_ACTION_SUCCESS = '/activities/actions/edit/success',
  VIEW_ACTION_ACTIVITY = '/activities/action',

  // Ongoing Opportunity Screens
  ADD_ONGOING_OPPORTUNITY = '/activities/opportunities/new',
  ADD_ONGOING_OPPORTUNITY_SUCCESS = '/activities/opportunities/success',
  EDIT_ONGOING_OPPORTUNITY = '/activities/opportunities/edit',
  EDIT_ONGOING_OPPORTUNITY_SUCCESS = '/activities/opportunities/edit/success',
  VIEW_ONGOING_ACTIVITY = '/activities/opportunity',

  // Networking
  MEMBERS = '/members',
  ADD_ORG_MEMBER = '/members/add/member',
  ADD_ORG_MEMBER_SUCCESS = '/members/add/member/success',
  ADD_ORG_PARTNER = '/members/add/partner',
  ADD_ORG_PARTNER_SUCCESS = '/members/add/partner/success',
  ADD_CHILD_ORG = '/members/add/child-org',
  ADD_CHILD_ORG_SUCCESS = '/members/add/child-org/success',
  VIEW_MEMBER = '/members/:id',
  VIEW_TEAM = '/teams',
  PARTNERS = '/members/partners',
  VIEW_PARTNER = '/members/partners/:organizationId/:connectionId',
  CHILD_ORG = '/members/child-org',
  VIEW_CHILD_ORG = '/members/child-org/:id',
  ACCOUNT_SETTINGS = '/account-settings',
  COMPANY_SETTINGS = '/company-settings',
  CHANGE_PASSWORD = '/change-password',
  DELETE_ACCOUNT = '/delete-account',
  ACCOUNT_DELETED = '/account-deleted',
  SETTINGS = '/settings',
  ECOSYSTEM_SETTINGS = '/settings/ecosystems',
  APP_SETTINGS = '/settings/apps',
  APP_GROUP_PERMISSION_EDIT = '/app-permissions/:id',
  MANAGE_ORGANIZATION_ACCESS = '/organization/:id/manage-access',
  NEW_PERMISSION_GROUP = '/permissions/new',
  EDIT_PERMISSION_GROUP = '/permissions/:id/edit',
  CHANGE_ORG = '/change-organization',
  SWITCH_ECOSYSTEM = '/switch-ecosystem',

  /** -- INVITE ROUTES -- **/

  // 1.0 Invites slug
  INVITE_JOIN_AS_PARTNER_SLUG = '/:slug/invite/partner/:code',
  INVITE_JOIN_AS_PARTNER_SIGN_UP_SLUG = '/:slug/invite/partner/:code/signup',
  INVITE_JOIN_AS_PARTNER_SIGN_IN_SLUG = '/:slug/invite/partner/:code/sign-in',
  INVITE_JOIN_AS_PARTNER_DECLINED_SLUG = '/:slug/invite/partner/:code/declined',

  // 1.1 Invites without slug
  INVITE_JOIN_AS_PARTNER = '/invite/partner/:code',
  INVITE_JOIN_AS_PARTNER_SIGN_UP = '/invite/partner/:code/signup',
  INVITE_JOIN_AS_PARTNER_SIGN_IN = '/invite/partner/:code/sign-in',
  INVITE_JOIN_AS_PARTNER_DECLINED = '/invite/partner/:code/declined',

  // 2.0 Admin invites
  INVITE_JOIN_ADMIN = '/invite/:code/admin',
  INVITE_JOIN_ADMIN_SUCCESS = '/invite/admin/success',
  INVITE_JOIN_ADMIN_DECLINED = '/invite/admin/declined',

  /** -- ECOSYSTEM -- **/
  VIEW_ECOSYSTEM = '/ecosystem/:id',
  CREATE_ECOSYSTEM = '/ecosystem/create',
  EDIT_ECOSYSTEM = '/ecosystem/edit',

  //SHARED SCREENS
  PAGE_404 = '/404',
  GENERATING_REPORT = '/generating-report',

  // BULK UPLOADS
  UPLOADS = '/uploads',
  UPLOADS_ACTIVITIES = '/uploads/activity-file',
  UPLOADS_PLACES = '/uploads/places-file',

  // ACTIVITY APPLICATION
  VIEW_TEAM_ACTIVITY_APPLICATION = '/activity-applications/:id/team',
}
