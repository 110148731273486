import { Checkbox, Flex, Input, Stack, Text } from 'native-base';
import { Controller } from 'react-hook-form';

import { Loading } from '~/components/Loading';
import { colors } from '~/theme/colors';

import { usePermissionsController } from './controller';
import { PermissionsProps } from './types';

export const PermissionsSection = ({
  control,
  errors,
  getValues,
  appPermissionGroup,
  selectedNetworkAppSharing,
  setValue,
}: PermissionsProps) => {
  const { isLoading, fields, isCheckboxDisabled, isInputDisabled } =
    usePermissionsController(
      getValues,
      control,
      appPermissionGroup,
      selectedNetworkAppSharing,
    );

  if (isLoading) {
    return <Loading containerHeight="50vh" spinnerSize="sm" />;
  }

  return (
    <Stack mt={8} minH="50vh">
      <Stack space={2}>
        <Text fontWeight={500} fontSize="xl" color="gray.600">
          Permissions
        </Text>
        <Text fontSize="sm" color="gray.600">
          Select all the permissions applied to this group
        </Text>
      </Stack>
      {fields.map((item, index) => (
        <Stack
          key={item.category.displayName}
          mt={8}
          borderRadius="xl"
          borderWidth="1"
          padding={4}
          borderColor="gray.300"
        >
          <Flex
            flexDir="row"
            maxW="620px"
            width="100%"
            justifyContent="space-between"
          >
            <Stack maxW="80%" space={2}>
              <Text
                fontWeight={500}
                fontSize="lg"
                color={colors.singletons.darkText}
              >
                {item.category.displayName}
              </Text>
              <Text fontSize="xs" color="gray.600">
                {item.category.description}
              </Text>
            </Stack>
            {/* <Stack>
              <SelectAllPermissionsCheckbox
                control={control}
                index={index}
                getValues={getValues}
                permissions={item.permissions}
              />
            </Stack> */}
          </Flex>

          {item.permissions.map((permissionCard, pIndex) => (
            <Stack
              key={permissionCard._id}
              width="100%"
              alignItems="flex-start"
              mt={6}
            >
              <Controller
                control={control}
                name={`groupedPermissions.${index}.configurations.${pIndex}`}
                render={({ field: { onChange, value } }) => (
                  <>
                    <Checkbox
                      isDisabled={isCheckboxDisabled(value)}
                      value={permissionCard.permissionTemplateId || ''}
                      isChecked={
                        value?.templateId ===
                        permissionCard?.permissionTemplateId
                      }
                      onChange={(v) => {
                        setValue(
                          `groupedPermissions.${index}.configurations.${pIndex}.templateId`,
                          v ? permissionCard.permissionTemplateId || '' : '',
                        );
                      }}
                      accessibilityLabel="Check permission"
                      accessibilityState={{ disabled: true }}
                    >
                      <Text>{permissionCard.description}</Text>
                      {permissionCard.restrictionType === 'Quantitative' &&
                        permissionCard.conditions?.map((condition, cIndex) => (
                          <Input
                            key={condition.field}
                            height={10}
                            width={16}
                            onChange={(v) => {
                              setValue(
                                `groupedPermissions.${index}.configurations.${pIndex}.conditions.${cIndex}.value`,
                                v.nativeEvent.text,
                              );
                            }}
                            value={value?.conditions[cIndex].value}
                            isDisabled={isInputDisabled(
                              value?.templateId ===
                                permissionCard?.permissionTemplateId,
                              value,
                            )}
                          />
                        ))}
                    </Checkbox>
                  </>
                )}
              />
            </Stack>
          ))}
        </Stack>
      ))}

      {errors?.error?.message ? (
        <Text ml={2} mt={2} fontSize="xs" color="error.500">
          {errors?.error?.message}
        </Text>
      ) : null}
    </Stack>
  );
};
