import { Divider, HStack, Stack, Text, VStack } from 'native-base';
import DataTable from 'ui/components/DataTable';
import { TableSizeClass } from 'ui/components/DataTable/@types/basicTypes';
import { TeamMemberList } from 'ui/components/Teams/TeamMemberList';

import { ContactInfoItem } from '~/components/ContactInfoItem';
import { Loading } from '~/components/Loading';
import Scaffold from '~/components/Scaffold';
import { tableActivitiesColumns } from '~/pages/Authenticated/Teams/ViewTeam/activitiesTableConfig';
import { TeamMember } from '~/pages/Authenticated/Teams/ViewTeam/components/TeamMember';
import { useViewTeamController } from '~/pages/Authenticated/Teams/ViewTeam/controller';
import { renderActivitesQuantityTitle } from '~/pages/Authenticated/Teams/ViewTeam/utils';

export const ViewTeam = () => {
  const {
    teamInfo,
    leaderAndMembersByStatus: { leader, members },
    isLoading,
    loadApplications,
    totalApplications,
    handleViewActivity,
    groupedMembers,
  } = useViewTeamController();
  return isLoading ? (
    <Stack w="full" h="full" alignItems="center" justifyContent="center">
      <Loading />
    </Stack>
  ) : (
    <Scaffold
      title={teamInfo?.name}
      isViewTeam
      teamMembersQuantity={teamInfo?.membersSubDocument.length}
      withBg={false}
    >
      <Stack w="full" mt={10} space={6}>
        <Stack p={6} background="white" borderRadius="3xl" space={5}>
          <VStack space={3}>
            <Text color="gray.600" fontSize="xl" fontWeight={500}>
              Team details
            </Text>
            <Divider />
          </VStack>
          <VStack space={8}>
            <HStack flex={1} justifyContent="space-between">
              <VStack flex={0.5} space={2}>
                <Text fontWeight="medium" color="gray.600">
                  Team Leader
                </Text>
                <TeamMember
                  name={leader?.name}
                  avatarImage={leader?.avatarUrl}
                />
              </VStack>
              {/*TODO: Add this when we have organizationSubDocument from Leader */}
              {/* <VStack flex={0.5} space={2}>
                <Text fontWeight="medium" color="gray.600">
                  Corporate
                </Text>
                <HStack space={2} alignItems="center">
                  <Image
                    source={{
                      uri: teamInfo?.ecosystem.logo,
                    }}
                    height="40px"
                    width="40px"
                    borderRadius="8px"
                  />
                  <Text color={colors.singletons.darkText}>
                    {teamInfo?.ecosystem.name}
                  </Text>
                </HStack>
              </VStack> */}
            </HStack>

            <TeamMemberList
              title="Teammates"
              membersData={groupedMembers?.accepted ?? []}
              emptyMessage="Currently no members in the team."
              showTopBar={false}
            />

            {groupedMembers?.pending && (
              <TeamMemberList
                title="Pending teammates"
                membersData={groupedMembers?.pending ?? []}
                emptyMessage="Currently no pending members in the team."
              />
            )}

            {groupedMembers?.rejected && (
              <TeamMemberList
                title="Declined invitations"
                membersData={groupedMembers?.rejected ?? []}
                emptyMessage="Currently no declined members in the team."
              />
            )}

            <VStack mt={8}>
              <VStack space={3}>
                <Text color="gray.600" fontSize="xl" fontWeight={500}>
                  Contact information
                </Text>
                <Divider />
              </VStack>
              <HStack flex={1} mt={6} justifyContent="space-between">
                <Stack flex={0.5}>
                  <ContactInfoItem
                    infoName="Email address"
                    infoValue={
                      teamInfo?.leaderSubDocument.userSummary.email || ''
                    }
                    icon="mail"
                  />
                </Stack>
                {/* <Stack flex={0.5}>
                    <ContactInfoItem
                      infoName="Phone number"
                      infoValue="(425) 234-0480"
                      icon="phone"
                    />
                  </Stack> */}
              </HStack>
            </VStack>
          </VStack>
        </Stack>
        <Stack p={6} background="white" borderRadius="3xl" space={5}>
          <Stack space={2}>
            <Text fontWeight={500} fontSize="2xl" color="gray.600">
              {renderActivitesQuantityTitle(totalApplications)}
            </Text>
            <Text fontWeight={400} fontSize="sm" color="gray.600">
              Total number of activities
            </Text>
          </Stack>
          <DataTable
            loadData={loadApplications as any}
            columns={tableActivitiesColumns({
              handleViewActivity,
            })}
            className={TableSizeClass.Small}
            pageSizes={[5]}
          />
        </Stack>
      </Stack>
    </Scaffold>
  );
};
