import { Stack, Text, VStack } from 'native-base';
import { forwardRef } from 'react';
import Button from 'ui/components/Button';
import Modal from 'ui/components/Modals/Modal';
import { IModalRefProps } from 'ui/components/Modals/Modal/types';
import { Loading } from 'ui/components/v2/Loading';
import { colors } from 'ui/theme/colors';

import { useDeleteDepartmentModalController } from './controller';
import { DeleteDepartmentModalProps } from './types';

export const DeleteDepartmentModal = forwardRef(function ModalNewActivity(
  props: DeleteDepartmentModalProps,
  ref,
) {
  const { handleSubmit, handleCancel, isLoading, tabIndex } =
    useDeleteDepartmentModalController(
      props,
      ref as React.MutableRefObject<IModalRefProps>,
    );

  const modalTabs = [
    <>
      <Text fontSize={'md'} fontWeight={400} color={'gray.600'}>
        Are you sure you want to delete this group?
      </Text>
    </>,
    <>
      <Text fontSize={'md'} fontWeight={400} color={'gray.600'}>
        Are you sure you want to delete this group? There are employees
        associated with this group in their profiles.
      </Text>

      <Text fontSize={'md'} fontWeight={400} color={'gray.600'}>
        This action is irreversible and the group will be immediately removed
        from the list.
      </Text>
    </>,
  ];

  return (
    <Modal
      ref={ref}
      headerText={'Delete group'}
      onClose={handleCancel}
      modalContentMaxW="620px"
    >
      <VStack>
        <VStack py={6} space={6}>
          {isLoading && (
            <Loading
              backgroundColor="#ffffff55"
              showSpinner={false}
              height={'100%'}
              float
            />
          )}

          {modalTabs[tabIndex]}
        </VStack>

        <Stack
          my={4}
          w="full"
          flexDirection={{ base: 'column', md: 'row' }}
          style={{ gap: 3 }}
        >
          <Stack flex={1}>
            <Button
              variant={'ghost'}
              onPress={handleCancel}
              isDisabled={isLoading}
            >
              <Text fontSize={'sm'} fontWeight={500}>
                No, go back
              </Text>
            </Button>
          </Stack>

          <Stack flex={1}>
            <Button
              onPress={() => handleSubmit(tabIndex === 1)}
              backgroundColor={colors.error[600]}
              isLoading={isLoading}
            >
              <Text fontSize={'sm'} fontWeight={500} color={'white'}>
                Delete
              </Text>
            </Button>
          </Stack>
        </Stack>
      </VStack>
    </Modal>
  );
});

export default DeleteDepartmentModal;
