import { VStack } from 'native-base';

import { LoadingSkeletonItem } from './LoadingSkeletonItem';

export const LoadingSkeleton = ({ rows }: { rows: number }) => {
  const items = Array(rows).fill(0);
  return (
    <VStack space={4} mb={4}>
      {items.map((_, index) => {
        return <LoadingSkeletonItem key={index} />;
      })}
    </VStack>
  );
};
