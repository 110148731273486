import { useMemo } from 'react';

import { IScaffoldProps } from './types';

export const useScaffoldController = ({
  title,
  titleIcon,
  actionComponents,
  showActivityTypeIcon,
  activityType,
  isViewTeam,
  teamMembersQuantity,
}: Partial<IScaffoldProps>) => {
  const {
    isTitleOnlyHeader,
    isHeaderWithIcon,
    isActivityHeader,
    isTeamHeader,
    showActionComponents,
  } = useMemo(() => {
    return {
      isTitleOnlyHeader:
        !titleIcon && !showActivityTypeIcon && !isViewTeam && title,
      isHeaderWithIcon: !!titleIcon && title,
      isActivityHeader: showActivityTypeIcon && activityType,
      isTeamHeader: isViewTeam && teamMembersQuantity,
      showActionComponents: actionComponents,
    };
  }, [
    title,
    titleIcon,
    actionComponents,
    showActivityTypeIcon,
    activityType,
    isViewTeam,
    teamMembersQuantity,
  ]);
  return {
    isTitleOnlyHeader,
    isHeaderWithIcon,
    isActivityHeader,
    isTeamHeader,
    showActionComponents,
  };
};
