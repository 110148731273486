import { TypeColumn } from '@inovua/reactdatagrid-community/types';
import { Box, Divider, HStack, Stack, Text } from 'native-base';
import DataTable from 'ui/components/DataTable';

import { AppsAccessCard } from '~/components/AppsAccessCard';
import { Loading } from '~/components/Loading';

import { UserProfilePopulated } from '../../../../../../types/interfaces/userProfile';
import { defaultFiltersValues } from '../../../utils';
import { useMemberAccessController } from './controller';
import { styles } from './styles';
import { tableColumns } from './tableColums';

export const MemberAccess = ({ member }: { member?: UserProfilePopulated }) => {
  const {
    uniqueAppsConnectedToOrganization,
    apps,
    isLoading,
    isLoadingAppPermissionsGroup,
    loadPermissions,
  } = useMemberAccessController(member);

  if (isLoading) {
    return <Loading containerHeight="100vh" spinnerSize="sm" />;
  }

  return (
    <Stack
      bgColor="white"
      borderRadius="3xl"
      width="100%"
      space={3}
      padding={6}
    >
      <HStack alignItems="center" justifyContent="space-between">
        <Stack space={2}>
          <Text fontSize="lg" fontWeight={500} color="gray.600">
            Access
          </Text>
          <Text fontSize="sm" fontWeight={400} color="gray.600">
            View and manage the apps this member can access
          </Text>
        </Stack>
        {/* <Button
          leftIconName="sliders"
          leftIconColor={colors.primary[400]}
          maxWidth="168px"
          width="100%"
          variant="outline"
          borderColor="primary.400"
        >
          Manage access
        </Button> */}
      </HStack>
      <Divider />

      <HStack
        mt={6}
        space={6}
        width="100%"
        flexWrap="wrap"
        style={styles.appsCard}
      >
        {apps?.map((app) => (
          <Box width="23%" key={app._id}>
            <AppsAccessCard app={app} />
          </Box>
        ))}
      </HStack>

      <HStack mt={10} alignItems="center" justifyContent="space-between">
        <Stack space={2}>
          <Text fontSize="lg" fontWeight={500} color="gray.600">
            Permissions
          </Text>
          <Text fontSize="sm" fontWeight={400} color="gray.600">
            View and manage permissions
          </Text>
        </Stack>
      </HStack>
      <Divider mb={6} />
      <DataTable
        loadData={loadPermissions}
        columns={
          tableColumns({
            connectedApps: uniqueAppsConnectedToOrganization,
          }) as TypeColumn[]
        }
        isLoading={isLoadingAppPermissionsGroup}
        defaultFilterValues={defaultFiltersValues}
      />
    </Stack>
  );
};
