import { Box, HStack, Text } from 'native-base';
import { ColorType } from 'native-base/lib/typescript/components/types';
import Icon from 'ui/components/Icon';
import { colors } from 'ui/theme/colors';

import { TrendingBaseElement } from './TrendingBaseElement';

type TrendingElementIconProps = {
  icon: string;
  bgColor: ColorType;
  color: ColorType;
};

export const TrendingElementIcon = ({
  icon,
  bgColor,
  color,
}: TrendingElementIconProps) => {
  return (
    <Box borderRadius={'full'} bg={bgColor} p={2}>
      <Icon enableAutoCustom icon={icon} size={'16px'} color={color} />
    </Box>
  );
};

type TrendingElementProps = {
  trendingType: string;
  label: string;
  icon: string;
  iconColor: ColorType;
  iconBgColor: ColorType;
  emptyText?: string;
  isLoading?: boolean;
};

export const TrendingElement = ({
  trendingType,
  label,
  icon,
  iconBgColor,
  iconColor,
  emptyText = 'N/A',
  isLoading = false,
}: TrendingElementProps) => {
  const isEmpty = !label;

  return (
    <TrendingBaseElement
      heading={`Trending ${trendingType}`}
      isLoading={isLoading}
    >
      <HStack space={2} alignItems="center">
        <TrendingElementIcon
          icon={icon}
          bgColor={isEmpty ? colors.gray[200] : iconBgColor}
          color={isEmpty ? colors.gray[600] : iconColor}
        />
        <Text
          fontSize={{ base: 'md', md: 'lg', lg: 'xl' }}
          fontWeight={500}
          color={colors.gray[800]}
        >
          {label || emptyText}
        </Text>
      </HStack>
    </TrendingBaseElement>
  );
};
