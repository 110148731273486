import * as yup from 'yup';

import { MemberTypeEnum } from '~/enums';

export const addOrgMembersSchema = [
  yup.object().shape({
    ecosystems: yup
      .object()
      .test(
        'at-least-one-item',
        'At least one of the attributes must contain at least one item',
        (value: Record<string, string[]>) => {
          const attributeKeys = Object.keys(value || {});
          return attributeKeys.some((key) => value[key]?.length > 0);
        },
      ),
  }),
  yup.object().shape({
    memberType: yup.string().oneOf(Object.values(MemberTypeEnum)),
    membersPermissions: yup.array().of(yup.string().required()),
    partnersPermissions: yup.array().when('memberType', {
      is: MemberTypeEnum.Partner,
      then: yup.array().of(yup.string().required()),
    }),
  }),
  yup.object().shape({
    title: yup.string().required().max(85),
    message: yup.string().required().max(500),
    logo: yup.mixed(),
    backgroundImage: yup.mixed(),
    brandColor: yup.string().min(4).max(7),
  }),
  yup.object().shape({
    employeeEmail: yup.string().email(),
    userConfigurations: yup.array().of(
      yup.object({
        email: yup.string().email().required(),
        externalId: yup.string(),
      }),
    ),
  }),
];
