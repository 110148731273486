import { HStack, Text, VStack } from 'native-base';
import Tag from 'ui/components/Tags/Tag';

import { RequirementsSectionProps } from './types';

export const RequirementsSection = ({
  requirementOptions,
}: RequirementsSectionProps) => {
  return (
    <VStack space={2} pb={8}>
      <Text color="gray.600" fontSize="xl" fontWeight={500}>
        Requirements
      </Text>
      <HStack space={2} flexWrap="wrap">
        {requirementOptions.map((requirement) => (
          <Tag
            key={requirement._id}
            text={requirement.displayName}
            tagBgColor="info.100"
            tagTextColor="info.800"
          />
        ))}
      </HStack>
    </VStack>
  );
};
