import { useEffect, useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import { PAGES } from '~/constants/pages.constants';
import { useAppDispatch } from '~/hooks/useAppDispatch';
import { useAppSelector } from '~/hooks/useAppSelector';
import { useLoadOrganizationEcosystems } from '~/hooks/useLoadOrganizationEcosystems';
import { useSwitchNotifyOrg } from '~/hooks/useNotify/switchNotifyOrgNotify';
import { useRouter } from '~/hooks/useRouter';
import { changeOrgByIdAction } from '~/store/common/actions/organizations/changeOrgAction';

export const useChangeOrgController = () => {
  const { organizations, ecosystem } = useAppSelector(({ auth }) => auth);
  const [isChangingOrg, setIsChangingOrg] = useState(false);
  const { goBack, replaceRoute } = useRouter();
  const dispatch = useAppDispatch();

  const [searchParams] = useSearchParams();

  const { ecosystems } = useLoadOrganizationEcosystems();

  const { handleOrgSwitch } = useSwitchNotifyOrg();

  const onChangeOrg = (orgId: string) => {
    setIsChangingOrg(true);
    dispatch(changeOrgByIdAction(orgId))
      .then(() => {
        const currentOrg = organizations?.filter((org) => org._id === orgId)[0];
        setIsChangingOrg(false);
        handleOrgSwitch(currentOrg);
        if (ecosystems && ecosystems?.length > 1) {
          replaceRoute(PAGES.SWITCH_ECOSYSTEM);
          return;
        }
        replaceRoute(PAGES.ROOT);
      })
      .catch(() => {
        toast.error('Error changing organization', { autoClose: 3000 });
        setIsChangingOrg(false);
      });
  };

  useEffect(() => {
    const defaultOrganizationId = searchParams.get('organization') || '';
    if (defaultOrganizationId) {
      onChangeOrg(defaultOrganizationId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams]);

  return {
    organizations,
    ecosystem,
    goBack,
    onChangeOrg,
    isChangingOrg,
  };
};
