import { Checkbox, HStack, Text, VStack } from 'native-base';
import { Controller } from 'react-hook-form';

import { colors } from '../../../../theme/colors';
import Button from '../../../Button';
import { useAppSectionController } from './controller';
import { SelectAppItem } from './SelectAppItem';
import { AppSectionProps } from './types';

export const AppSection = (props: AppSectionProps) => {
  const {
    name,
    control,
    appsList,
    disabledValues = [],
    isMultiple,
    onChangeValue,
  } = props;
  const {
    isOpen,
    showTriggerButton,
    toggleViewPortTrigger,
    viewportMaxHeight,
  } = useAppSectionController(props);

  return (
    <VStack w="full" space={4}>
      <Controller
        name={name}
        control={control}
        render={({ field: { value = [], onChange } }) => {
          return (
            <Checkbox.Group
              value={value}
              onChange={onChange}
              _checkbox={{
                borderColor: 'gray.300',
                _hover: { borderColor: 'gray.300' },
                _checked: {
                  _hover: { borderColor: 'primary.500' },
                  borderColor: 'primary.400',
                  backgroundColor: 'primary.400',
                },
              }}
            >
              <VStack
                width={'100%'}
                maxHeight={viewportMaxHeight}
                padding={0}
                overflow={'hidden'}
              >
                <HStack
                  height={'fit-content'}
                  flex={1}
                  w="full"
                  flexWrap="wrap"
                >
                  {appsList.map((app) => {
                    const isChecked = value.some(
                      (v: string) => v === app.value,
                    );

                    let disabled =
                      !isMultiple &&
                      value?.length > 0 &&
                      app.value !== value[0];

                    if (
                      disabledValues.length > 0 &&
                      disabledValues.includes(app.value)
                    ) {
                      disabled = true;
                    }

                    return (
                      <SelectAppItem
                        key={app.name}
                        name={app.name}
                        value={app.value}
                        logo={app.logo || ''}
                        onChangeValue={onChangeValue}
                        disabled={disabled}
                        isChecked={isChecked}
                      />
                    );
                  })}
                </HStack>
              </VStack>

              {showTriggerButton && (
                <Button
                  h={9}
                  mt={2}
                  onPress={toggleViewPortTrigger}
                  bgColor={colors.gray[100]}
                  rightIconSize={16}
                  rightIconName={!!isOpen ? 'chevron-up' : 'chevron-down'}
                  rightIconColor={colors.singletons.black}
                  variant="ghost"
                  colorScheme="darkText"
                >
                  <Text
                    color="singleton.black"
                    fontSize={12}
                    fontWeight="medium"
                  >
                    {isOpen ? ' Show less' : 'View all apps'}
                  </Text>
                </Button>
              )}
            </Checkbox.Group>
          );
        }}
      />
    </VStack>
  );
};
