import { useCallback, useEffect, useState } from 'react';
import { FileRejection, useDropzone } from 'react-dropzone';
import { toast } from 'react-toastify';

import { CustomFile, FILES_ZONE_ERRORS } from '../types';
import {
  DEFAULT_FILE_ERROR,
  FILE_REMOVE_ERROR,
  INVALID_FILE_FORMAT_ERROR,
  TOO_LARGE_FILE_ERROR,
  TOO_MANY_FILES_ERROR,
  TOO_SMALL_FILE_ERROR,
} from '../utils';
import { IFileZone } from './types';

export const useFileZoneController = ({
  files,
  errors,
  register,
  setValue,
  maxFiles = 1,
  multiple = false,
  showTitle = true,
  handleDropFile,
  handleRemoveFile,
  acceptedSize = 5,
  filesField = 'files',
  filesAccepted = {
    // @mycatdoitbetter FIXME: On windows OS, we cannot upload an tsv file... So i've oppened to all files
    '*': [],
  },
}: Partial<IFileZone>) => {
  const [filesInZone, setFilesInZone] = useState<CustomFile[]>([]);
  const onDropAccepted = useCallback(
    async (droppedContent) => {
      for (const content of droppedContent) {
        if (content.size > acceptedSize * 1024 * 1024) {
          toast.error(TOO_LARGE_FILE_ERROR);
          return;
        }
      }

      if (!multiple) {
        setFilesInZone([...droppedContent]);
        if (handleDropFile) {
          handleDropFile(droppedContent);
        }
      } else {
        setFilesInZone((file) => [...file, ...droppedContent]);
        if (handleDropFile) {
          handleDropFile([...filesInZone, ...droppedContent]);
        }
      }
    },
    [handleDropFile, multiple, acceptedSize, filesInZone],
  );

  const onDropRejected = useCallback(
    (rejections: FileRejection[]) => {
      const errors = rejections[0]?.errors;
      const errorMessage = errors[0]?.code;
      let errMessage = DEFAULT_FILE_ERROR;

      switch (errorMessage) {
        case FILES_ZONE_ERRORS.TOO_SMALL_FILE:
          errMessage = TOO_SMALL_FILE_ERROR;
          break;
        case FILES_ZONE_ERRORS.TOO_LARGE_FILE:
          errMessage = TOO_LARGE_FILE_ERROR;
          break;
        case FILES_ZONE_ERRORS.TOO_MANY_FILES:
          errMessage = TOO_MANY_FILES_ERROR(maxFiles);
          break;
        case FILES_ZONE_ERRORS.INVALID_FILE_FORMAT:
          errMessage = INVALID_FILE_FORMAT_ERROR;
          break;
      }
      toast.error(errMessage);
    },
    [maxFiles],
  );

  const { getRootProps, getInputProps } = useDropzone({
    useFsAccessApi: false,
    multiple,
    maxFiles,
    onDropAccepted,
    onDropRejected,
  });

  const handleDeleteFilesInZone = (fileToDelete: string) => {
    const fileIndex = filesInZone.findIndex(
      (file) => file.image === fileToDelete || file.name === fileToDelete,
    );
    const updatedFiles = [...filesInZone];
    if (fileIndex >= 0) {
      updatedFiles.splice(fileIndex, 1);
      setFilesInZone(updatedFiles);

      if (handleRemoveFile) {
        handleRemoveFile(updatedFiles, fileIndex);
      }
    }
  };

  useEffect(() => {
    if (files) setFilesInZone(files as CustomFile[]);
  }, [files]);

  useEffect(() => {
    if (register) register(filesField);
  }, [register, filesField]);

  return {
    files,
    errors,
    register,
    setValue,
    maxFiles,
    multiple,
    showTitle,
    handleDropFile,
    handleRemoveFile,
    acceptedSize,
    filesField,
    filesAccepted,
    filesInZone,
    handleDeleteFilesInZone,
    getRootProps,
    getInputProps,
    onDropAccepted,
    onDropRejected,
  };
};
