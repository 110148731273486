import { MemberTypeEnum } from '~/enums';
import { AddOrgMembersForm } from '~/pages/Authenticated/AddOrgMember/components/Form';

import { useAddPartnerController } from './controller';

export const AddPartner = () => {
  const {
    handleCreatePublicInvite,
    handleInvitePartner,
    isSubmittingPublicInvite,
    isSubmitting,
  } = useAddPartnerController();

  return (
    <AddOrgMembersForm
      title="Add partner"
      isSubmitting={isSubmitting}
      memberType={MemberTypeEnum.Partner}
      onSubmitInviteMember={handleInvitePartner}
      isSubmittingPublicInvite={isSubmittingPublicInvite}
      onSubmitPublicInviteMember={handleCreatePublicInvite}
    />
  );
};
