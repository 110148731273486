import { HStack, Text } from 'native-base';
import Button from 'ui/components/Button';
import Icon from 'ui/components/Icon';

import { colors } from '../../theme/colors';
import { DownloadReportButtonProps } from './types';

export const DownloadReportButton = ({
  onPress,
  title,
}: DownloadReportButtonProps) => {
  return (
    <Button variant="outline" p={5} width={'fit-content'} onPress={onPress}>
      <HStack space={2} alignItems={'center'}>
        <Icon icon="download" color={colors.primary[300]} size={16} />

        <Text color={colors.primary[300]} fontSize="sm" fontWeight={500}>
          {title}
        </Text>
      </HStack>
    </Button>
  );
};
