import { yupResolver } from '@hookform/resolvers/yup';
import { Auth } from 'aws-amplify';
import { useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';

import {
  COMMON_MESSAGES,
  RECOVERY_PASSWORD,
} from '~/constants/messages.constants';
import { PAGES } from '~/constants/pages.constants';
import { useRouter } from '~/hooks/useRouter';
import AuthService from '~/services/resources/auth';
import { IChangePassword } from '~/types/dtos';
import { CognitoErrorCodeMessages } from '~/types/interfaces/cognito-errors';

import { schemaValidation } from './constants';

export const useChangePasswordController = () => {
  const { goToRoute } = useRouter();

  const {
    control,
    handleSubmit,
    formState: { errors, isValid, isSubmitting, isDirty },
  } = useForm<IChangePassword>({
    resolver: yupResolver(schemaValidation),
    mode: 'all',
    defaultValues: {
      currentPassword: '',
      newPassword: '',
    },
  });

  const canSubmit = useMemo(() => !isValid || !isDirty, [isValid, isDirty]);

  const onSubmit = async ({
    currentPassword,
    newPassword,
  }: IChangePassword) => {
    try {
      const user = await Auth.currentAuthenticatedUser();
      await AuthService.changePassword(user, {
        currentPassword,
        newPassword,
      });
      toast.success('Password changed successfully!');
      goToRoute(PAGES.ACCOUNT_SETTINGS);
    } catch (error) {
      if (error instanceof Error) {
        if (error.message === CognitoErrorCodeMessages.OldPasswordIncorrect) {
          return toast.error(RECOVERY_PASSWORD.CURRENT_PASSWORD_INCORRECT);
        }
        if (
          error.message === CognitoErrorCodeMessages.ChangePasswordLimitAttempts
        ) {
          return toast.error(error.message);
        }
      }
      toast.error(COMMON_MESSAGES.UNKNOWN_ERROR);
    }
  };

  return {
    isValid,
    control,
    isDirty,
    canSubmit,
    errors,
    isSubmitting,
    onSubmit: handleSubmit(onSubmit),
  };
};
