import { Auth } from 'aws-amplify';
import { useRef } from 'react';
import { IModalRefProps } from 'ui/components/Modals/Modal/types';

import { IPopoverOption } from '~/components/MenuDropdown/types';
import { PAGES } from '~/constants/pages.constants';
import { useAppDispatch } from '~/hooks/useAppDispatch';
import { useRouter } from '~/hooks/useRouter';
import { authSliceActions } from '~/store/slices/auth';
import { ecosystemSliceActions } from '~/store/slices/ecosystem';

export const useMenuDropdownController = () => {
  // --States
  const modalRef = useRef<IModalRefProps>(null);

  const { goToRoute, replaceRoute } = useRouter();

  const dispatch = useAppDispatch();

  const handleCloseSignOutModal = () => {
    modalRef.current?.close();
  };

  const handleOpenSignOutModal = () => {
    modalRef.current?.open();
  };

  const handleOptionPress = (options: IPopoverOption) => {
    if (options.action === 'external') {
      window.open(options.path, '_blank');
      return;
    } else if (options.action === 'custom') {
      if (options.key === 'sign_out') handleOpenSignOutModal();
      return;
    }
    goToRoute(options.path);
  };

  const handleSignOut = () => {
    Auth.signOut();
    dispatch(authSliceActions.logout());
    dispatch(ecosystemSliceActions.resetEcosystem(undefined));
    replaceRoute(PAGES.ROOT);
  };

  return {
    modalRef,
    goToRoute,
    handleOptionPress,
    handleOpenSignOutModal,
    handleCloseSignOutModal,
    handleSignOut,
  };
};
