import { Stack } from 'native-base';

import { ActivitySuccessModal } from '~/components/ActivitySuccessModal';
import { PAGES } from '~/constants/pages.constants';
import { ActivityTypeEnum } from '~/enums';

import { useOpportunitySuccessfulController } from './controller';
import { OpportunitySuccessfulProps } from './types';
export const OpportunitySuccessful = ({
  pageTitle,
}: OpportunitySuccessfulProps) => {
  const { activity, activityUrl, selectedEcosystem, navigate } =
    useOpportunitySuccessfulController();

  return (
    <Stack
      w="100%"
      h="100%"
      position="fixed"
      left={0}
      right={0}
      overflowY="scroll"
      justifyContent="center"
      alignItems="center"
      bg="rgba(0,0,0,0.5)"
    >
      <ActivitySuccessModal
        title={pageTitle}
        activityName={activity.title}
        activityType={ActivityTypeEnum.OngoingOpportunity}
        // TODO: Mock, should add the correct logic to get all dates for the activity. Need to align UX/UI.
        activityDate={activity.createdAt}
        activityEcosystem={selectedEcosystem?.name}
        ecosystemLogo={selectedEcosystem?.logo}
        // TODO: Mock, should add the correct activity link here when we have the new volunteer. BHO link will not work as they expect an Activity ID and not a Activity Definition ID.
        activityUrl={activityUrl}
        onClose={() => navigate(PAGES.ACTIVITIES)}
        onViewActivity={() =>
          navigate(`${PAGES.VIEW_ONGOING_ACTIVITY}/${activity._id}`)
        }
      />
    </Stack>
  );
};
