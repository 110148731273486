import { api } from '~/services/api';

const MODULE_URL = 'user';

export default class UserService {
  static async me() {
    return await api.get(`${MODULE_URL}/me`);
  }

  static async get(id: string) {
    return await api.get(`${MODULE_URL}/${id}`);
  }

  static async deleteById(id: string) {
    return await api.delete(`${MODULE_URL}/${id}`);
  }
}
