import { useAppSelector } from '~/hooks/useAppSelector';
import { useQuery } from '~/hooks/useQuery';

import { IOrganization } from '../../types/interfaces/organization';

export const useLoadOrganization = (organizationId: string) => {
  const { isAuthenticated } = useAppSelector(({ auth }) => auth);

  const { data, isLoading, isRefetching } = useQuery<IOrganization>(
    `organization/${organizationId}`,
    {
      queryOptions: { enabled: !!isAuthenticated && !!organizationId },
      key: `organization-${organizationId}`,
    },
  );

  return { data, isLoading, isRefetching };
};
