import {
  TableOperatorNames,
  TableOperatorType,
} from 'ui/components/DataTable/types';

export const defaultFiltersValues = [
  {
    name: 'organization',
    operator: TableOperatorNames.StartsWith,
    type: TableOperatorType.String,
    value: '',
  },
  {
    name: 'location',
    operator: TableOperatorNames.StartsWith,
    type: TableOperatorType.String,
    value: '',
  },
];
