import {
  Avatar,
  Box,
  Divider,
  HStack,
  Image,
  Pressable,
  Stack,
  Text,
  VStack,
} from 'native-base';
import Button from 'ui/components/Button';
import Icon from 'ui/components/Icon';
import { getNameInitials } from 'ui/utils/getNameInititals';

import { PAGES } from '~/constants/pages.constants';
import { EcosystemVisibility } from '~/enums';
import { useRouter } from '~/hooks/useRouter';
import { colors } from '~/theme/colors';

import { IEcosystemCardProps } from './types';

export const EcosystemCard = ({
  ecosystem: { _id, logo, name, description, visibility },
  apps,
  showEditButton = false,
}: IEcosystemCardProps) => {
  const { goToRoute } = useRouter();

  return (
    <Stack
      bg={colors.singletons.lightText}
      p="6"
      rounded="2xl"
      borderWidth="1"
      borderColor="gray.300"
    >
      <HStack justifyContent="space-between">
        <VStack space={2}>
          <Avatar
            borderWidth="1px"
            borderColor="gray.300"
            height={12}
            width={12}
            source={{ uri: logo }}
            _image={{
              rounded: 12,
            }}
            rounded={12}
          >
            {getNameInitials(name)}
          </Avatar>
        </VStack>
        <Box>
          <HStack space="1" alignItems="center">
            {visibility === EcosystemVisibility.Private ? (
              <>
                <Icon icon="lock" size="16" color={colors.gray[500]} />
                <Text color="gray.500">Private</Text>
              </>
            ) : (
              <>
                <Icon icon="eye" size="16" color={colors.gray[500]} />
                <Text color="gray.500">Public</Text>
              </>
            )}
          </HStack>
        </Box>
      </HStack>

      <Stack flex={1} mt={4}>
        <Text fontSize="lg" numberOfLines={2} height="3.4rem">
          {name}
        </Text>
      </Stack>

      <Stack space={2} mt={4}>
        <Text mt={2} color="gray.600" numberOfLines={2} height="2.7rem">
          {description}
        </Text>
        {/* TODO: This is not working well, ecosystem that should have a app is not showing */}
        {/* {apps.length > 0 ? (
          <VStack>
            <Text color="gray.600" fontSize="sm">
              Apps
            </Text>
            <Stack
              direction="row"
              space={2}
              alignItems="center"
              flexWrap="wrap"
            >
              {apps.map((app) => (
                <>
                  <Image
                    source={{ uri: app.logo }}
                    width="40px"
                    height="40px"
                    rounded="8px"
                    borderWidth="1px"
                    borderColor="gray.300"
                  />
                </>
              ))}
            </Stack>
          </VStack>
        ) : null} */}

        {showEditButton ? (
          <Button
            w="container"
            variant="outline"
            alignItems="flex-end"
            leftIconName="edit"
            borderColor="primary.200"
            leftIconColor={colors.primary[400]}
            onPress={() => goToRoute(`${PAGES.EDIT_ECOSYSTEM}/${_id}`)}
          >
            Edit
          </Button>
        ) : (
          <Pressable
            alignItems="flex-end"
            onPress={() => goToRoute(`/settings/ecosystems/${_id}`)}
          >
            <HStack alignItems="center" space="3">
              <Text color="primary.400">View</Text>
              <Icon icon="arrow-right" size="16" color={colors.primary[400]} />
            </HStack>
          </Pressable>
        )}
      </Stack>
    </Stack>
  );
};
