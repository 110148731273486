import { yupResolver } from '@hookform/resolvers/yup';
import { useCallback, useState } from 'react';
import { useFieldArray, useForm } from 'react-hook-form';

import { ICreateEventDTO, IDatesDTO } from '~/types/dtos';

import {
  LocationData,
  LocationDataStatus,
  MultipleLocationsProps,
} from './types';
import { schemaValidation } from './utils';

export const useMultipleLocationsController = ({
  handleContinue,
  handleBack,
  isLoading,
  isTeamEvent,
  defaultLocationGroupsByDate,
  defaultValues,
}: MultipleLocationsProps) => {
  const {
    control,
    handleSubmit,
    setValue,
    formState: { isValid, errors },
  } = useForm<ICreateEventDTO>({
    resolver: yupResolver(schemaValidation),
    mode: 'onChange',
    reValidateMode: 'onBlur',
    defaultValues,
  });

  const [locationsByGroup, setLocationsByGroup] = useState<
    Record<number, LocationData[]> | undefined
  >(defaultLocationGroupsByDate);

  const {
    fields: locationsGroupsFields,
    append: appendLocationGroup,
    remove: removeLocationGroup,
  } = useFieldArray<ICreateEventDTO>({
    control,
    name: 'locationsGroups',
  });

  const handleSubmitForm = handleSubmit((data: ICreateEventDTO) => {
    handleContinue({ ...data, locationsByGroup });
  });

  const verifyIfExistsNotFoundLocation = useCallback(() => {
    if (!locationsByGroup) return false;

    const allLocations = Object.values(locationsByGroup).flat();

    const existsNotFoundLocation = allLocations.find(
      (location) => location.status === LocationDataStatus.ZERO_RESULTS,
    );

    if (existsNotFoundLocation) {
      return true;
    }

    return false;
  }, [locationsByGroup]);

  const verifyIfAllLocationsAreNotFound = useCallback(() => {
    if (!locationsByGroup) return true;

    const allLocations = Object.values(locationsByGroup).flat();

    const isEveryLocationNotFound = allLocations.every(
      (location) => location.status === LocationDataStatus.ZERO_RESULTS,
    );

    return isEveryLocationNotFound;
  }, [locationsByGroup]);

  const verifyIfAllGroupsAreNotEmpty = useCallback(() => {
    if (!locationsByGroup) return true;

    const groupIndexes = Object.keys(locationsByGroup);

    let isAllGroupsNotEmpty = false;
    groupIndexes.every((groupIndex) => {
      isAllGroupsNotEmpty = locationsByGroup[Number(groupIndex)].length > 0;
      return isAllGroupsNotEmpty;
    });

    return isAllGroupsNotEmpty;
  }, [locationsByGroup]);

  const isDisabledPublishButton =
    verifyIfAllLocationsAreNotFound() ||
    verifyIfExistsNotFoundLocation() ||
    !verifyIfAllGroupsAreNotEmpty() ||
    !isValid;

  const addLocationGroup = useCallback(() => {
    appendLocationGroup({
      volunteerNumber: undefined,
      noVolunteerLimit: false,
      startDate: undefined,
      endDate: undefined,
      dueDate: undefined,
      teamsNumber: undefined,
      teamsMaxSize: undefined,
      teamsMinSize: undefined,
    } as IDatesDTO);

    handleUpdateLocationGroup(locationsGroupsFields.length, []);
  }, [appendLocationGroup, locationsGroupsFields]);

  const deleteLocationGroup = useCallback(
    (groupIndex: number) => {
      removeLocationGroup(groupIndex);

      // Reorder the dictionary with correct indexes
      if (locationsByGroup) {
        const newLocationsByGroup = locationsByGroup;

        delete newLocationsByGroup[groupIndex];

        const keys = Object.keys(newLocationsByGroup);

        const orderedNewLocationsByGroup = keys.reduce(
          (
            accValue: Record<number, LocationData[]>,
            currValue: string,
            currIndex,
          ) => {
            const locationData = newLocationsByGroup[Number(currValue)];
            return { ...accValue, [currIndex]: locationData };
          },

          {} as Record<number, LocationData[]>,
        );
        setLocationsByGroup(orderedNewLocationsByGroup);
      }
    },

    [removeLocationGroup, locationsByGroup],
  );

  const handleUpdateLocationGroup = (
    index: number,
    locations: LocationData[],
  ) => {
    setLocationsByGroup((prevState) => {
      const locationGroups = prevState;
      return { ...locationGroups, [index]: locations };
    });
  };

  const handleDeleteAllLocationsFromGroup = (index: number) => {
    setLocationsByGroup((prevState) => {
      const locationGroups = prevState;
      if (locationGroups && locationGroups[index]) {
        const updatedLocationGroup: Record<number, LocationData[]> = {};

        for (const [groupIndex] of Object.values(locationGroups).entries()) {
          if (groupIndex !== index) {
            updatedLocationGroup[groupIndex] = locationGroups[groupIndex];
          }
        }
        return updatedLocationGroup;
      }
    });
  };

  const handleDeleteLocationFromGroup = (
    groupIndex: number,
    locationIndexToRemove: number,
  ) => {
    setLocationsByGroup((prevState) => {
      const locationGroups = prevState;
      if (locationGroups && locationGroups[groupIndex]) {
        const locationGroup = locationGroups[groupIndex];
        const newLocations = locationGroup.filter(
          (location, index) => locationIndexToRemove !== index,
        );
        return { ...locationGroups, [groupIndex]: newLocations };
      }
    });
  };

  return {
    locationsGroupsFields,
    appendLocationGroup,
    addLocationGroup,
    deleteLocationGroup,
    handleBack,
    handleSubmitForm,
    control,
    isLoading,
    isDisabledPublishButton,
    errors,
    isTeamEvent,
    locationsByGroup,
    handleUpdateLocationGroup,
    handleDeleteAllLocationsFromGroup,
    handleDeleteLocationFromGroup,
    setValue,
  };
};
