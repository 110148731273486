import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { ESliceNames } from '~/store/types';
import { IAuthResponseDTO } from '~/types/dtos';

import { IAuthState } from './types';

const initialState: IAuthState = {
  accessToken: undefined,
  refreshToken: undefined,
  isLoading: false,
  hasError: false,
  isAuthenticated: false,
  user: undefined,
  hasOrganization: false,
  organizationSelectedId: undefined,
  organizations: [],
  _id: '',
};

export const authSlice = createSlice({
  name: ESliceNames.AUTH,
  initialState,
  reducers: {
    signIn: (state, { payload }: PayloadAction<IAuthResponseDTO>) => {
      return {
        ...state,
        accessToken: payload.signInUserSession.accessToken.jwtToken,
        refreshToken: payload.signInUserSession.refreshToken.token,
        isAuthenticated: false,
        user: {
          email: payload.username,
          name: payload.attributes?.name || 'No name',
          email_verified: payload.attributes.email_verified,
          sub: payload.attributes.sub,
        },
      };
    },
    setOrganization: (state, { payload }: PayloadAction<string>) => {
      return {
        ...state,
        hasOrganization: true,
        organizationSelectedId: payload,
      };
    },
    redirectTo: (
      state,
      { payload: redirectTo }: PayloadAction<string | undefined>,
    ) => ({
      ...state,
      redirectTo,
    }),
    logout: (
      _state,
      { payload: redirectTo }: PayloadAction<string | undefined>,
    ) => ({
      ...initialState,
      redirectTo,
    }),
    update: (state, { payload }: PayloadAction<Partial<IAuthState>>) => {
      return {
        ...state,
        ...payload,
      };
    },
  },
});

export const { reducer: authSliceReducer, actions: authSliceActions } =
  authSlice;
