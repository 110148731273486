import { Box, Divider, HStack, Stack, Text, VStack } from 'native-base';
import { Control } from 'react-hook-form';
import Button from 'ui/components/Button';
import DataTable from 'ui/components/DataTable';
import { SelectedRows } from 'ui/components/DataTable/@types/basicTypes';
import { BaseModel } from 'ui/components/DataTable/types';
import ReportCards from 'ui/components/ReportCards';

import { IApplicationListProps } from '~/pages/Authenticated/Activities/ApplicationsList/types';
import { ModalDeclineApplication } from '~/pages/Authenticated/Activities/components/ModalDeclineApplication';
import { colors } from '~/theme/colors';

import { applicationsColumns } from './applicationsColumns';
import { useApplicationListController } from './controller';
import { defaultApplicationsListFilters } from './utils';

export const ApplicationList = ({
  setPendingApplications,
}: IApplicationListProps) => {
  const {
    loadApplications,
    isLoadingActivities,
    onSelectionChange,
    selectedApplications,
    isSelectedApplicationsEmpty,
    selectedApplicationsCount,
    applicationsCount,
    handleAcceptApplication,
    handleDeclineSingleApplication,
    handleDeclineApplications,
    handleBatchAcceptApplications,
    declineModalRef,
    handleOpenDeclineModal,
    handleCloseDeclineModal,
    control,
    setTableRef,
    isHandlingApplications,
    applicationStatusCount,
    isLoadingApplicationStatusCount,
    applicationAttendanceCount,
    isLoadingApplicationAttendanceCount,
    handleViewMemberApplication,
    handleViewTeamApplication,
  } = useApplicationListController({ setPendingApplications });

  return (
    <Stack w="full">
      <Stack p={6} background="white" borderRadius="3xl" space={5}>
        <VStack space={2}>
          <Text color="gray.600" fontSize="2xl" fontWeight={500}>
            Applications report
          </Text>
          <Text color="gray.600" fontSize="sm" fontWeight={500}>
            Keep track of who is applying to your activities
          </Text>
        </VStack>
        <Divider />
        <HStack space={6} width="100%" justifyContent="space-between" mb={6}>
          <Box flex={0.333}>
            <ReportCards
              withBorder
              icon="clock"
              value={applicationStatusCount?.Pending || 0}
              valueInfo="Applications"
              title="Pending applications"
              dividerColor="orange.500"
              iconColor={colors.orange['500']}
              paddingTitle={0}
              cardHeight="40px"
              isLoading={isLoadingApplicationStatusCount}
              // actionButtonName="View all"
              actionButtonIconPosition="right"
              actionButtonIcon="chevron-right"
              action={() => {
                return;
              }}
              direction="column"
            />
          </Box>
          <Box flex={0.333}>
            <ReportCards
              withBorder
              icon="check"
              value={applicationStatusCount?.Approved || 0}
              valueInfo="Applications"
              title="Accepted applications"
              dividerColor="lime.500"
              iconColor={colors.lime['500']}
              isIconCustom
              paddingTitle={0}
              cardHeight="40px"
              isLoading={isLoadingApplicationStatusCount}
              // actionButtonName="View all"
              actionButtonIconPosition="right"
              actionButtonIcon="chevron-right"
              action={() => {
                return;
              }}
              direction="column"
            />
          </Box>

          <Box flex={0.333}>
            <ReportCards
              withBorder
              icon="check-square"
              value={applicationAttendanceCount?.count || 0}
              valueInfo="Attendances"
              title="Attendances registered"
              dividerColor="info.500"
              iconColor={colors.info['500']}
              isIconCustom
              paddingTitle={0}
              cardHeight="40px"
              isLoading={isLoadingApplicationAttendanceCount}
              // actionButtonName="View all"
              actionButtonIconPosition="right"
              actionButtonIcon="chevron-right"
              action={() => {
                return;
              }}
              direction="column"
            />
          </Box>
          <Box flex={0.333}>
            <ReportCards
              withBorder
              icon="x"
              value={applicationStatusCount?.Rejected || 0}
              valueInfo="Applications"
              title="Rejected applications"
              dividerColor="error.500"
              iconColor={colors.error['500']}
              paddingTitle={0}
              cardHeight="40px"
              isLoading={isLoadingApplicationStatusCount}
              // actionButtonName="View all"
              actionButtonIconPosition="right"
              actionButtonIcon="chevron-right"
              action={() => {
                return;
              }}
              direction="column"
            />
          </Box>
        </HStack>

        <Stack space={8} w="full">
          <Stack space={2} w="full">
            <HStack justifyContent="space-between">
              <Text fontWeight={500} fontSize="2xl" color="gray.600">
                {applicationsCount} Applications
              </Text>

              <HStack space={2}>
                <Button
                  variant="outline"
                  isDisabled={isSelectedApplicationsEmpty}
                  minW="3/6"
                  w="3/6"
                  leftIconName="x"
                  leftIconColor={
                    !isSelectedApplicationsEmpty
                      ? colors.error['600']
                      : 'gray.600'
                  }
                  bg="transparent"
                  borderColor="error.600"
                  _disabled={{
                    borderColor: 'gray.500',
                  }}
                  onPress={handleOpenDeclineModal}
                >
                  <Text
                    color={
                      !isSelectedApplicationsEmpty ? 'error.600' : 'gray.600'
                    }
                  >
                    Decline all selected
                  </Text>
                </Button>
                <Button
                  isDisabled={isSelectedApplicationsEmpty}
                  minW="3/6"
                  w="3/6"
                  leftIconName="check"
                  leftIconColor={
                    !isSelectedApplicationsEmpty ? 'white' : 'gray.900'
                  }
                  bg="success.600"
                  _disabled={{
                    bg: 'gray.400',
                  }}
                  onPress={handleBatchAcceptApplications}
                >
                  <Text
                    color={!isSelectedApplicationsEmpty ? 'white' : 'gray.900'}
                  >
                    Accept all selected
                  </Text>
                </Button>
              </HStack>
            </HStack>

            <Text fontWeight={400} fontSize="sm" color="gray.600">
              Review and manage all applications for your activities
            </Text>
          </Stack>
          {/*
          <HStack w="full" space={4}>
            <VStack flex={0.5} space={2}>
              <Text fontWeight={500} fontSize="sm">
                Filter by app
              </Text>
              <Select name={'Filter by app'} control={control} />
            </VStack>
          </HStack>  */}

          <DataTable
            defaultFilterValues={defaultApplicationsListFilters}
            //TODO: Improve this type in the future
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            loadData={loadApplications as any}
            columns={applicationsColumns({
              onAcceptApplication: handleAcceptApplication,
              onRejectApplication: handleDeclineSingleApplication,
              isHandlingApplications,
              handleViewMemberApplication,
              handleViewTeamApplication,
            })}
            checkboxColumn
            onSelectionChange={
              onSelectionChange as (
                selectedRows: SelectedRows<BaseModel>,
              ) => void
            }
            //TODO: Improve this type in the future
            onReady={setTableRef as () => void}
            selected={selectedApplications}
            remoteFilter
            checkboxOnlyRowSelect
            isLoading={isLoadingActivities || isHandlingApplications}
          />
        </Stack>
      </Stack>
      <ModalDeclineApplication
        modalRef={declineModalRef}
        headerText="Decline Application"
        handleCloseModal={handleCloseDeclineModal}
        handleDecline={handleDeclineApplications}
        selectedApplicationsCount={selectedApplicationsCount}
        control={control as unknown as Control}
      ></ModalDeclineApplication>
    </Stack>
  );
};
