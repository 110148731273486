import { IOrganization } from '~/types/interfaces/organization';

export enum TabsPages {
  Members = 0,
  Partners = 1,
  ChildOrg = 2,
  PendingInvites = 3,
}

export type OrganizationData = {
  id: string;
  name: string;
  avatar: string;
};

export type ChildOrgTableData = {
  organization: OrganizationData;
  location: string;
  amount: number;
  ecosystem: string[];
};

export type ChildOrgTableDataPromise = {
  data: ChildOrgTableData[];
  count: number;
};

export interface MembersListProps {
  organization?: IOrganization;
}
