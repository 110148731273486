import { Divider, HStack, Stack, Text } from 'native-base';
import Button from 'ui/components/Button';
import Icon from 'ui/components/Icon';

import { Loading } from '~/components/Loading';
import { colors } from '~/theme/colors';

import { usePartnerDetailsController } from './controller';
import { styles } from './styles';
import { PartnerDetailsProps } from './types';

export const PartnerDetails = ({
  organization,
  isLoadingOrganization,
}: PartnerDetailsProps) => {
  const { handleCopy } = usePartnerDetailsController();

  if (isLoadingOrganization) {
    return <Loading containerHeight="100vh" spinnerSize="sm" />;
  }

  return (
    <Stack
      bgColor="white"
      borderRadius="3xl"
      width="100%"
      space={5}
      padding={6}
      minHeight="80vh"
    >
      <Stack space={2} width="100%">
        <Text fontSize="lg" fontWeight={500} color="gray.600">
          Basic information
        </Text>
        <Divider />

        <HStack justifyContent="space-between" width="100%" space={10}>
          <Stack space={2} width="50%">
            <HStack
              maxW="352px"
              width="100%"
              justifyContent="space-between"
              mt={6}
              alignItems="center"
            >
              <Stack space={2}>
                <Text fontSize="sm" fontWeight={500} color="gray.600">
                  Contact phone number
                </Text>
                <HStack space={2} alignItems="center">
                  <Icon icon="phone" color={colors.gray[400]} size={16} />
                  <Text fontSize="sm" fontWeight={400} color="darkText">
                    {organization?.contactPhoneNumber || '-'}
                  </Text>
                </HStack>
              </Stack>
              <Button
                leftIconName="copy"
                leftIconColor={colors.primary[600]}
                leftIconSize={16}
                maxWidth="75px"
                minWidth="75px"
                bgColor="primary.50"
                _text={styles.buttonCopy}
                onPress={() =>
                  handleCopy(organization?.contactPhoneNumber || '')
                }
              >
                Copy
              </Button>
            </HStack>

            <HStack
              maxW="352px"
              width="100%"
              justifyContent="space-between"
              mt={6}
              alignItems="center"
            >
              <Stack space={2}>
                <Text fontSize="sm" fontWeight={500} color="gray.600">
                  Contact email address
                </Text>
                <HStack space={2} alignItems="center">
                  <Icon icon="mail" color={colors.gray[400]} size={16} />
                  <Text fontSize="sm" fontWeight={400} color="darkText">
                    {organization?.contactEmail || '-'}
                  </Text>
                </HStack>
              </Stack>
              <Button
                leftIconName="copy"
                leftIconColor={colors.primary[600]}
                leftIconSize={16}
                maxWidth="75px"
                minWidth="75px"
                bgColor="primary.50"
                _text={styles.buttonCopy}
                onPress={() => handleCopy(organization?.contactEmail || '')}
              >
                Copy
              </Button>
            </HStack>

            <HStack
              maxW="352px"
              width="100%"
              justifyContent="space-between"
              mt={6}
              alignItems="center"
            >
              <Stack space={2}>
                <Text fontSize="sm" fontWeight={500} color="gray.600">
                  Full address
                </Text>
                <HStack space={2} alignItems="center">
                  <Icon icon="map-pin" color={colors.gray[400]} size={16} />
                  <Text fontSize="sm" fontWeight={400} color="darkText">
                    {organization?.fullAddress?.street || '-'}
                  </Text>
                </HStack>
              </Stack>
            </HStack>

            {organization?.websiteLink ? (
              <HStack
                maxW="352px"
                width="100%"
                justifyContent="space-between"
                mt={6}
                alignItems="center"
              >
                <Stack space={2}>
                  <Text fontSize="sm" fontWeight={500} color="gray.600">
                    Website URL
                  </Text>
                  <HStack space={2} alignItems="center">
                    <Icon icon="globe" color={colors.gray[400]} size={16} />
                    <Text fontSize="sm" fontWeight={400} color="darkText">
                      {organization.websiteLink}
                    </Text>
                  </HStack>
                </Stack>
              </HStack>
            ) : null}

            {organization?.domainSlug ? (
              <HStack
                maxW="352px"
                width="100%"
                justifyContent="space-between"
                mt={6}
                alignItems="center"
              >
                <Stack space={2}>
                  <Text fontSize="sm" fontWeight={500} color="gray.600">
                    URL
                  </Text>
                  <HStack space={2} alignItems="center">
                    <Icon icon="globe" color={colors.gray[400]} size={16} />
                    <Text fontSize="sm" fontWeight={400} color="darkText">
                      www.doit.life/{organization.domainSlug}
                    </Text>
                  </HStack>
                </Stack>
              </HStack>
            ) : null}
          </Stack>
          <Stack space={2} width="50%">
            <Stack space={2} mt={6}>
              <Text fontSize="sm" fontWeight={500} color="gray.600">
                Description
              </Text>
              <Text
                maxWidth="524px"
                width="100%"
                fontSize="sm"
                fontWeight={400}
                color="darkText"
                flex="auto"
              >
                {organization?.description || '-'}
              </Text>
            </Stack>
            {organization?.termsOfServicesLink ? (
              <Stack space={2} mt={6}>
                <Text fontSize="sm" fontWeight={500} color="gray.600">
                  Terms of services
                </Text>
                <a
                  href={organization.termsOfServicesLink}
                  target="_blank"
                  rel="noreferrer"
                >
                  <Text
                    width="100%"
                    fontSize="sm"
                    fontWeight={500}
                    color="primary.600"
                    flex="auto"
                  >
                    terms-and-cond.pdf
                  </Text>
                </a>
              </Stack>
            ) : null}
          </Stack>
        </HStack>
      </Stack>
    </Stack>
  );
};
