import { Box, Stack } from 'native-base';
import SwipeableViews from 'react-swipeable-views';

import { PasswordReseted } from '~/pages/Unauthenticated/RecoveryPassword/PasswordReseted';
import { ResetPassword } from '~/pages/Unauthenticated/RecoveryPassword/ResetPassword';
import { VerifyCodePage } from '~/pages/Unauthenticated/RecoveryPassword/VerifyCode';

import { useRecoveryPasswordController } from './controller';
import { styles } from './styles';

export const RecoveryPassword = () => {
  const { currentStep } = useRecoveryPasswordController();

  return (
    <Box w="full" minH="100vh" background="singletons.mediumWhite">
      <Stack px={styles.container.px} py="10" space={10}>
        <SwipeableViews index={currentStep}>
          <VerifyCodePage />
          <ResetPassword />
          <PasswordReseted />
        </SwipeableViews>
      </Stack>
    </Box>
  );
};
