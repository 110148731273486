import { useMemo } from 'react';
import { LogTileMeasurementSummary } from 'ui/components/v2/Tiles/LogsSummaryTile/types';
import { formatMeasurementAmount } from 'ui/utils/measurements/format';

import { makeActivityTypeBadgeTitleNode } from '~/components/Cards/NotificationCard/utils';

import { userUserNotificationCardController } from '../controller';
import { MeasurementCreatedNotificationCardProps } from './types';

export const useMeasurementCreatedNotificationCardController = (
  props: MeasurementCreatedNotificationCardProps,
) => {
  const {
    activityType,
    measurementUnit,
    measurementsAmount,
    measurementsTarget,
  } = props;

  const { date, profilePictureUrl, authorTitleNode } =
    userUserNotificationCardController(props);

  const titleNodes = useMemo(() => {
    const amount = formatMeasurementAmount(measurementsAmount, measurementUnit);
    return [
      authorTitleNode,
      { text: 'logged' },
      { text: amount, fontWeight: 500 },
      { text: 'on an activity' },
      makeActivityTypeBadgeTitleNode(activityType),
    ];
  }, [activityType, measurementUnit, measurementsAmount, authorTitleNode]);

  const measurementSummary: LogTileMeasurementSummary = {
    measurementUnit,
    amount: measurementsAmount,
    target: measurementsTarget,
  };

  return { titleNodes, date, profilePictureUrl, measurementSummary };
};
