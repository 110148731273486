import { VStack } from 'native-base';
import { AppSelector } from 'ui/components/v2/AppInputs/AppSelector';
import { TabNavigator } from 'ui/components/v2/TabNavigator';

import { UPDATES_FEED_TAB_ELEMENTS } from '~/pages/Authenticated/Dashboard/components/UpdatesFeed/fragments/UpdatesFeedHeader/constants';
import { useUpdatesFeedHeaderController } from '~/pages/Authenticated/Dashboard/components/UpdatesFeed/fragments/UpdatesFeedHeader/controller';
import { UpdatesFeedHeaderProps } from '~/pages/Authenticated/Dashboard/components/UpdatesFeed/fragments/UpdatesFeedHeader/types';

export const UpdatesFeedHeader = (props: UpdatesFeedHeaderProps) => {
  const { selectedAppIds, apps } = props;

  const { handleAppChange, handleTabChange, tabsRef } =
    useUpdatesFeedHeaderController(props);

  return (
    <VStack space={6}>
      <AppSelector
        apps={apps}
        values={selectedAppIds}
        onChange={handleAppChange}
      />

      <TabNavigator
        ref={tabsRef}
        tabs={UPDATES_FEED_TAB_ELEMENTS}
        onChange={handleTabChange}
      />
    </VStack>
  );
};
