import { useEffect, useState } from 'react';
import { EEventApplicationType } from 'ui/enums';
import { ActivityType } from 'ui/types/activities';
import { getPublishPermissionForActivityType } from 'ui/utils/permissions';

import { AboutActivityProps } from '~/pages/Authenticated/Activities/ActivityDetails/AboutActivity/types';
import { getMeasurementUnitNameAndCategory } from '~/utils/functions';

export const useAboutActivityController = ({
  measurementUnitObject,
  activityDefinition,
}: Partial<AboutActivityProps>) => {
  const [measurmentUnitLabels, setMeasurementUnitLabels] = useState<
    | {
        measurementUnitName: string;
        measurementUnitCategory: string;
      }
    | undefined
  >();

  useEffect(() => {
    if (measurementUnitObject && activityDefinition) {
      const measurementUnitInfo = getMeasurementUnitNameAndCategory(
        measurementUnitObject,
        activityDefinition.targetAmount as number,
      );

      setMeasurementUnitLabels(measurementUnitInfo);
    }
  }, [measurementUnitObject, activityDefinition]);

  return { measurmentUnitLabels };
};
