import { HStack, Text, VStack } from 'native-base';
import { ActivityDefinitionTile } from 'ui/components/v2/Tiles/ActivityDefinitionTile';

import { CorpSideCard } from '../BaseCorpSideCard';
import { CorpFeaturedActivitySideCardProps } from './types';

const placeHolderLabel =
  'You don’t have a featured activity yet. \n Please, click to set one.';

export const CorpFeaturedActivitySideCard = ({
  activityDefinition,
  measurementUnit,
  applicantsCount,
  isLoading = false,
  onChangeFeaturedActivityButtonPress,
  onSetFeaturedActivityButtonPress,
}: CorpFeaturedActivitySideCardProps) => {
  const labelText = `employee${applicantsCount !== 1 ? 's' : ''} are doing it`;

  return (
    <CorpSideCard
      titleIcon="flag"
      isLoading={isLoading}
      title="Featured Activity"
      primaryActionLabel="Set featured activity"
      onPrimaryAction={onSetFeaturedActivityButtonPress}
      secondaryActionLabel="Update featured activity"
      onSecondaryAction={onChangeFeaturedActivityButtonPress}
      showPrimaryAction={!activityDefinition}
      showSecondaryAction={!!activityDefinition}
    >
      {activityDefinition ? (
        <VStack space={4}>
          <ActivityDefinitionTile
            activityDefinition={activityDefinition}
            measurementUnit={measurementUnit}
          />

          <HStack
            space={2}
            background={'white'}
            p={3}
            borderRadius={8}
            borderColor={'gray.200'}
            borderWidth={2}
            alignItems={'center'}
          >
            <Text fontSize={'1.25rem'} fontWeight={500} color={'gray.800'}>
              {applicantsCount}
            </Text>
            <Text fontSize={'sm'} fontWeight={400} color={'gray.500'}>
              {labelText}
            </Text>
          </HStack>
        </VStack>
      ) : (
        <VStack>
          <Text
            textAlign={'center'}
            fontWeight={400}
            color="gray.600"
            fontSize={'sm'}
            margin={'auto'}
            py={16}
          >
            {placeHolderLabel}
          </Text>
        </VStack>
      )}
    </CorpSideCard>
  );
};
