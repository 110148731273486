import { Box, Flex, Text, VStack } from 'native-base';
import Button from 'ui/components/Button';
import Illustration, { ILLUSTRATIONS_NAME } from 'ui/components/Illustration';

import { BottomButton } from '~/components/BottomButton';
import { styles } from '~/pages/Common/JoinAdmin/JoinAsAdminInviteDeclined/styles';
import { useJoinAsAdminInviteDeclinedController } from '~/pages/Common/JoinAdmin/JoinAsAdminInviteDeclined/useJoinAsAdminInviteDeclinedController';
import { colors } from '~/theme/colors';

export const JoinAsPartnerInviteDeclined = () => {
  const { goToHome } = useJoinAsAdminInviteDeclinedController();

  return (
    <>
      <Flex
        pb="24"
        minH="100vh"
        backgroundColor={colors.singletons.lightText}
        justifyContent="center"
      >
        <Flex
          w="min(90%, 900px)"
          mx="auto"
          alignItems="center"
          flexDirection={styles.flexDirection}
          style={{ gap: 96 }}
        >
          <Box>
            <Illustration
              name={ILLUSTRATIONS_NAME.INVITATION_DECLINED}
              alt="Logo refused application"
              w="450px"
              h="450px"
              rounded="full"
              resizeMode="cover"
            />
          </Box>
          <Box width="100%" flex="1" mt={styles.boxMt}>
            <Text
              color="gray.900"
              fontWeight={500}
              fontSize="2xl"
              textAlign={styles.titleAlignment}
            >
              {`We're sorry to hear that you declined the invitation.`}
            </Text>
            <Text
              mt="4"
              color="gray.500"
              fontSize="sm"
              textAlign={styles.subTitleAlignment}
            >
              We appreciate your consideration and hope to have the opportunity
              to work with you in the future.
            </Text>
            <VStack
              width="100%"
              alignItems="center"
              space={2}
              mt="12"
              display={styles.buttonContainerDisplay}
            >
              <Button p={4} fontWeight={500} fontSize="md" onPress={goToHome}>
                Back to home screen
              </Button>
            </VStack>
          </Box>
        </Flex>
      </Flex>
      <BottomButton
        text="Back to home screen"
        primaryButtonVariant="solid"
        secondaryButtonTextColor="red.600"
        onSubmit={goToHome}
      />
    </>
  );
};
