import { useMemo } from 'react';
import { StylesConfig } from 'react-select';

import { theme } from '../../theme';
import { IOptionsItem, ISelectControllerProps } from './types';

const getStyle = ({
  errorMessage,
  hasError,
  fontSize,
}: ISelectControllerProps): StylesConfig => ({
  control: (baseStyles) => ({
    ...baseStyles,
    fontFamily: theme.fontConfig.default,
    fontSize: fontSize,
    padding: 5.8,
    borderColor: errorMessage || hasError ? theme.colors.error[600] : '#d4d4d4',
    borderRadius: 12,
  }),
  menuList: (baseStyles) => ({
    ...baseStyles,
    fontFamily: theme.fontConfig.default,
    fontSize: fontSize,
  }),
  menuPortal: (baseStyles) => ({
    ...baseStyles,
    fontFamily: theme.fontConfig.default,
    fontSize: fontSize,
    Index: 9999,
  }),
  indicatorSeparator: (baseStyles) => ({
    ...baseStyles,
    display: 'none',
  }),
  option: (baseStyles) => ({
    ...baseStyles,
    fontSize: fontSize,
  }),
});

export const useSelectController = ({
  isMulti,
  options,
  errorMessage,
  hasError,
  hasOptionsGroup,
  fontSize,
}: ISelectControllerProps) => {
  const defaultStyle = useMemo(
    () => getStyle({ errorMessage, hasError, fontSize }),
    [errorMessage, hasError, fontSize],
  );

  const handleGetValue = (value: string | string[]) => {
    if (isMulti && Array.isArray(value) && !value.length) {
      return [];
    }

    // TODO: Refactor and add typing for the options parameter
    if (hasOptionsGroup) {
      const section = options?.find((op: any) =>
        op.options.find((op: any) => op.value === value),
      ) as any;

      return section?.options?.find((op: any) => op.value === value) ?? [];
    }

    return isMulti && Array.isArray(value)
      ? options?.filter((item) => value.includes((item as IOptionsItem).value))
      : options?.filter((item) => (item as IOptionsItem).value === value);
  };

  const handleOnChange = (val: IOptionsItem | IOptionsItem[]) => {
    if (isMulti && Array.isArray(val) && !val.length) {
      return [];
    }

    return isMulti && Array.isArray(val)
      ? val?.map((c) => c.value)
      : (val as IOptionsItem)?.value;
  };

  return { handleGetValue, handleOnChange, defaultStyle };
};
