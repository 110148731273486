import { colors } from 'ui/theme/colors';
import { InsightsActivityType } from 'ui/types/activities';

export const DEFAULT_ACTIVITY_TYPE_CONFIG = {
  label: '',
  icon: 'calendar',
  iconColor: colors.gray[500],
  bgColor: colors.gray[100],
};

class ActivityTypeConfigMap<V> extends Map<InsightsActivityType, V> {
  getOrDefault(key: InsightsActivityType) {
    return this.has(key) ? this.get(key)! : DEFAULT_ACTIVITY_TYPE_CONFIG;
  }
}

export const activityTypeConfigMap = new ActivityTypeConfigMap<{
  label: string;
  bgColor: string;
  icon: string;
  iconColor: string;
}>([
  [
    InsightsActivityType.TeamEvent,
    {
      label: 'Team Event',
      icon: 'users',
      iconColor: colors.tertiary[400],
      bgColor: colors.activity['event'],
    },
  ],
  [
    InsightsActivityType.Event,
    {
      label: 'Event',
      icon: 'calendar',
      iconColor: colors.tertiary[400],
      bgColor: colors.activity['event'],
    },
  ],
  [
    InsightsActivityType.Action,
    {
      label: 'Action',
      icon: 'hand-pointer',
      iconColor: colors.singletons.darkText,
      bgColor: colors.activity['action'],
    },
  ],
  [
    InsightsActivityType.OngoingOpportunity,
    {
      label: 'Ongoing Opportunity',
      icon: 'repeat',
      iconColor: colors.singletons.darkText,
      bgColor: colors.activity['ongoing'],
    },
  ],
]);
