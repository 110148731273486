import { Box, HStack, Stack, Text } from 'native-base';
import { forwardRef } from 'react';
import Modal from 'ui/components/Modals/Modal';

import ModalNAButton from '~/components/ModalAddActivity/ModalNAButton';

interface IModalNewFileActivityUploadProps {
  newActivitiesUpload: () => void;
  newPlacesUpload: () => void;
}

const ModalNewFileActivityUpload = forwardRef(function ModalNewActivity(
  { newActivitiesUpload, newPlacesUpload }: IModalNewFileActivityUploadProps,
  ref,
) {
  return (
    <Box>
      <Modal headerText="Import file" ref={ref} modalContentMaxW="800">
        <Stack py="2" space={6} alignSelf="center" width={'full'}>
          <Stack>
            <Text color="gray.600" fontWeight={400} fontSize={16}>
              What type of file do you want to upload?
            </Text>
          </Stack>

          <Stack
            flexDirection={{ base: 'column', md: 'row' }}
            style={{ gap: 4 }}
          >
            <ModalNAButton
              label="New activities upload"
              description="Upload events and actions from a TSV file."
              action={newActivitiesUpload}
            />
            <ModalNAButton
              label="New places upload"
              description="Upload places for your events from a TSV file."
              action={newPlacesUpload}
            />
          </Stack>
        </Stack>
      </Modal>
    </Box>
  );
});

export default ModalNewFileActivityUpload;
