import { yupResolver } from '@hookform/resolvers/yup';
import { useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import { MEASUREMENT_UNIT_MESSAGES } from '~/constants/messages.constants';
import { useAppSelector } from '~/hooks/useAppSelector';
import MeasurementUnitsService from '~/services/resources/measurmentUnits';
import { CreateCustomUnitPayload } from '~/types/dtos';

import { schemaValidation } from './utils';

export const useCustomUnitModalController = (closeModal: () => void) => {
  const { organizationSelectedId } = useAppSelector(({ auth }) => auth);

  const navigate = useNavigate();

  const {
    control,
    handleSubmit,
    formState: { errors, isDirty, isValid, isSubmitting },
  } = useForm<CreateCustomUnitPayload>({
    resolver: yupResolver(schemaValidation),
  });

  const onSubmit = async (data: CreateCustomUnitPayload) => {
    try {
      if (!organizationSelectedId) return;
      await MeasurementUnitsService.createCustomUnit({
        pluralLabel: data.pluralLabel,
        singularLabel: data.singularLabel,
        organization: organizationSelectedId,
      });

      toast.success(MEASUREMENT_UNIT_MESSAGES.SUCCESSFULY_CREATED);
      closeModal();
      //TODO: dispatch to load custom units after create
      navigate(0);
    } catch (error) {
      toast.error(MEASUREMENT_UNIT_MESSAGES.ERROR_ON_CREATE);
    }
  };

  const isDisabled = useMemo(() => !isDirty || !isValid, [isDirty, isValid]);

  return {
    control,
    errors,
    isDisabled,
    onSubmit,
    handleSubmit,
    isSubmitting,
  };
};
