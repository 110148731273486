import { VStack } from 'native-base';
import FormBuilderV2 from 'ui/components/FormBuilderV2';
import { FormDataIsLoading } from 'ui/components/FormBuilderV2/components/FormDataIsLoading';

import { ModalInformation } from '~/components/ModalInformation';
import { TARGET_AMOUNT_MESSAGE } from '~/constants/messages.constants';

import { useAboutFormController } from './controller';
import { AboutFormProps } from './types';

export const AboutForm = (props: AboutFormProps) => {
  const {
    handleContinue,
    defaultValues,
    formSchema,
    isLoading,
    appsForSelector,
    modalRef,
    formBuilderImperativeRef,
  } = useAboutFormController(props);

  return (
    <VStack w="100%" space={4}>
      {appsForSelector.length === 0 ? (
        <FormDataIsLoading />
      ) : (
        <VStack w="full">
          <FormBuilderV2
            options={formSchema}
            onSubmit={handleContinue}
            defaultValues={defaultValues}
            isLoadingSubmit={isLoading}
            formBuilderImperativeRef={formBuilderImperativeRef}
          />
        </VStack>
      )}
      <ModalInformation
        description={TARGET_AMOUNT_MESSAGE}
        headerText="Target amount"
        modalRef={modalRef}
      />
    </VStack>
  );
};
