import { useQuery } from 'react-query';
import { AdvertiseRegionOptionsEnum } from 'ui/enums';
import { ActivityRegion } from 'ui/types/activities';

import ActivityRegionService from '~/services/resources/activityRegion';

export const useActivityRegions = () => {
  const { data: regionResponse, isLoading: isLoadingRegions } = useQuery({
    queryFn: async () => ActivityRegionService.findAll(),
  });

  return { regionResponse, isLoadingRegions };
};
