import { yupResolver } from '@hookform/resolvers/yup';
import { useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';

import {
  COMMON_MESSAGES,
  RECOVERY_PASSWORD,
} from '~/constants/messages.constants';
import { useAppSelector } from '~/hooks/useAppSelector';
import AuthService from '~/services/resources/auth';
import { forgotPasswordSliceActions } from '~/store/slices/forgotPassword';
import { IResetPasswordDTO } from '~/types/dtos';

import { recoveryPasswordSchemaValidation } from './utils';

export const useResetPasswordController = () => {
  //States
  const { email, accessCode } = useAppSelector(
    ({ forgotPassword }) => forgotPassword,
  );

  //Hooks
  const dispatch = useDispatch();

  const {
    control,
    handleSubmit,
    formState: { errors, isValid, isDirty, isSubmitting },
  } = useForm<IResetPasswordDTO>({
    resolver: yupResolver(recoveryPasswordSchemaValidation),
  });

  //Memos
  const isDisableResetPassword = useMemo(
    () => !isValid || !isDirty || isSubmitting,
    [isValid, isDirty, isSubmitting],
  );

  //Functions
  const onResetPassword = handleSubmit(async ({ password }) => {
    try {
      if (!email || !accessCode) return;
      await AuthService.confirmForgotPassword({
        email,
        newPassword: password,
        code: accessCode,
      });
      toast.success(COMMON_MESSAGES.PASSWORD_RESETED);
      dispatch(forgotPasswordSliceActions.nextStep());
    } catch (error) {
      if (error instanceof Error) {
        if (error?.message === RECOVERY_PASSWORD.INVALID_CODE) {
          toast.error(RECOVERY_PASSWORD.INVALID_CODE);
          dispatch(forgotPasswordSliceActions.backStep());
        }
      } else {
        toast.error(COMMON_MESSAGES.UNKNOWN_ERROR);
      }
    }
  });

  return {
    control,
    errors,
    isSubmitting,
    onResetPassword,
    isDisableResetPassword,
  };
};
