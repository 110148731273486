import { useAppSelector } from '~/hooks/useAppSelector';
import { useQuery } from '~/hooks/useQuery';
import { IEcosystem } from '~/types/interfaces/ecosystem';

export const useLoadOrganizationEcosystems = () => {
  const { organizationSelectedId } = useAppSelector(({ auth }) => auth);

  const { data: ecosystems, isLoading: isLoadingOrganizationEcosystems } =
    useQuery<IEcosystem[]>(
      `ecosystem/organizations/${organizationSelectedId}`,
      {
        key: `ecosystems-${organizationSelectedId}`,
        queryOptions: {
          enabled: !!organizationSelectedId,
        },
      },
    );

  return { ecosystems, isLoadingOrganizationEcosystems };
};
