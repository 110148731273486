import { useCallback, useMemo, useState } from 'react';
import { IFilter } from 'ui/components/DataTable/types';
import { PermissionType } from 'ui/enums';

import { useAppSelector } from '~/hooks/useAppSelector';
import { useLoadAllAppSharingsByOrganization } from '~/hooks/useLoadAllAppSharingsByOrganization';
import { useLoadAppPermissionsGroupByOrganization } from '~/hooks/useLoadAppPermissionsGroupByOrganization';
import { useLoadAppsByIds } from '~/hooks/useLoadAppsByIds';
import { QueryParams } from '~/services/resources/types';
import { UserProfilePopulated } from '~/types/interfaces/userProfile';

export const useMemberAccessController = (member?: UserProfilePopulated) => {
  const [queryParams, setQueryParams] = useState<QueryParams | undefined>(
    undefined,
  );

  const appIds = member?.appPermissionGroups?.map((group) => group.app);

  const { apps, isLoadingApps } = useLoadAppsByIds(appIds || []);

  const { organizationSelectedId } = useAppSelector(({ auth }) => auth);
  const { selectedEcosystem } = useAppSelector(({ ecosystem }) => ecosystem);

  const uniqueAppIdKeys = useMemo(() => new Set(), []);

  const { appsConnectedToOrganization, isLoadingAppsConnectedToOrganization } =
    useLoadAllAppSharingsByOrganization(organizationSelectedId || '');

  const uniqueAppsConnectedToOrganization = useMemo(() => {
    return appsConnectedToOrganization?.filter((app) => {
      const isUnique = !uniqueAppIdKeys.has(app.app._id);
      uniqueAppIdKeys.add(app.app._id);
      return isUnique;
    });
  }, [appsConnectedToOrganization, uniqueAppIdKeys]);

  const { data: appPermissionsGroup, isLoading: isLoadingAppPermissionsGroup } =
    useLoadAppPermissionsGroupByOrganization(
      organizationSelectedId,
      queryParams,
    );

  const loadPermissions = useCallback(
    async (skip: number, limit: number, filter: IFilter) => {
      if (!member || !selectedEcosystem) return { data: [], count: 0 };

      const permissionIds = [];
      for (const permission of member.appPermissionGroups) {
        permissionIds.push(permission._id);
      }

      setQueryParams({
        skip,
        limit,
        filter: JSON.stringify({
          type: PermissionType.MEMBER,
          ecosystem: selectedEcosystem._id,
          ...filter,
        }),
      });

      if (appPermissionsGroup?.data) {
        const data = appPermissionsGroup?.data;
        const count = appPermissionsGroup?.count;
        return { data, count };
      }

      return { data: [], count: 0 };
    },
    [member, appPermissionsGroup, selectedEcosystem],
  );

  const isLoading = isLoadingAppsConnectedToOrganization || isLoadingApps;

  return {
    uniqueAppsConnectedToOrganization,
    apps,
    isLoading,
    isLoadingAppPermissionsGroup,
    loadPermissions,
  };
};
