import { ActivityDefinitionGetters } from 'ui/utils/activity/activity-definition-getters';

import { ActivityDefinitionTileProps } from './types';

export const useActivityDefinitionTileController = ({
  activityDefinition,
  measurementUnit,
}: ActivityDefinitionTileProps) => {
  const activityDefinitionGetters = ActivityDefinitionGetters(
    activityDefinition,
    { measurementUnit },
  );

  const title = activityDefinition.title || '';

  const coverImage = activityDefinition.coverImage || '';

  const organizationName =
    activityDefinition.organizationSubDocument.name || '';

  const semanticType = activityDefinitionGetters.getSemanticType();

  const isFromHome = activityDefinitionGetters.isFromHome();

  const formattedTargetAmount = activityDefinition.targetAmount
    ? activityDefinitionGetters.getFormattedTargetAmount()
    : null;

  return {
    title,
    coverImage,
    organizationName,
    semanticType,
    isFromHome,
    formattedTargetAmount,
  };
};
