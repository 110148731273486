import { SelectAppItemProps } from './types';

export const useSelectAppItemController = ({
  isChecked,
  disabled,
  onChangeValue,
  value,
}: Pick<SelectAppItemProps, 'onChangeValue' | 'disabled' | 'isChecked'> & {
  value: string;
}) => {
  const handleOnChange = () => {
    if (disabled) return null;
    const newValue = !isChecked ? value : '';
    onChangeValue && onChangeValue(newValue);
  };
  return { handleOnChange };
};
