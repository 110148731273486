import { useForm } from 'react-hook-form';

import { ICreateEventDTO } from '~/types/dtos';

export const useWhenWhereFormController = () => {
  const { control, watch } = useForm<Pick<ICreateEventDTO, 'locationOption'>>({
    mode: 'onChange',
    reValidateMode: 'onBlur',
  });

  const currentLocationType = watch('locationOption');

  return {
    control,
    currentLocationType,
  };
};
