import { TypeComputedProps as DataGridProps } from '@inovua/reactdatagrid-community/types';
import { AxiosResponse } from 'axios';
import { MutableRefObject, RefObject, useState } from 'react';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import {
  BaseModel,
  SelectedRows,
} from 'ui/components/DataTable/@types/basicTypes';
import { IModalRefProps } from 'ui/components/Modals/Modal/types';
import { EEventApplicationType } from 'ui/enums';

import { IActivityApplicationWithID } from '~/pages/Authenticated/Activities/ApplicationsList/types';
import ActivityService from '~/services/resources/activity';
import {
  ActivityApplicationEnum,
  IActivityApplication,
} from '~/types/interfaces/activity';
import { ITeam } from '~/types/interfaces/team';
import { UserProfile } from '~/types/interfaces/userProfile';

import { useCurrentOrganization } from '../organization/useCurrentOrganization';

export const useHandleActivityApplications = (
  declineModalRef?: RefObject<IModalRefProps>,
  applicationsTableRef?: MutableRefObject<DataGridProps | null>,
) => {
  const { currentOrganization } = useCurrentOrganization();
  const { control, watch, reset } = useForm({
    defaultValues: {
      description: `Dear volunteer,
Thank you for your interest in the opportunity. We appreciate your enthusiasm and dedication. However, we wanted to inform you that the role has already been filled by another volunteer. We encourage you to explore other available opportunities, and hope to work with you in the future.

Best Regards, 
${currentOrganization?.name}`,
    },
  });

  const rejectDescription = watch('description');
  const [selectedApplications, setSelectedApplications] = useState<
    SelectedRows<BaseModel>
  >({});
  const [isHandlingApplications, setIsHandlingApplications] =
    useState<boolean>(false);

  const isSelectedApplicationsEmpty = selectedApplications
    ? Object.keys(selectedApplications).length === 0
    : true;

  const selectedApplicationsCount =
    selectedApplications && Object.keys(selectedApplications).length;

  const setTableRef = (
    computedPropsRef: React.MutableRefObject<DataGridProps | null>,
  ) => {
    if (applicationsTableRef) {
      applicationsTableRef.current = computedPropsRef.current;
    }
  };

  const onSelectionChange = (
    selectedRows: SelectedRows<IActivityApplicationWithID>,
  ) => {
    const isObjectEmpty = Array.from(Object.keys(selectedRows)).length === 0;

    const arrayFromSelection: IActivityApplicationWithID[] = Array.from(
      Object.values(selectedRows),
    );

    const selectedPendingOnly = arrayFromSelection.reduce(
      (
        accumulator: SelectedRows<IActivityApplicationWithID>,
        current: IActivityApplicationWithID,
      ) => {
        if (
          current.status === ActivityApplicationEnum.Pending ||
          current.status === ActivityApplicationEnum.Approved ||
          current.status === ActivityApplicationEnum.Rejected
        ) {
          accumulator[current._id] = current;
        }

        return accumulator;
      },
      {} as SelectedRows<IActivityApplicationWithID>,
    );

    const hasPendingApplications = Object.keys(selectedPendingOnly).length > 0;

    if (!isObjectEmpty && hasPendingApplications) {
      setSelectedApplications(selectedPendingOnly);
    } else {
      // Needs to be a empty object to clear the selected applications, null doesn't uncheck the box.
      setSelectedApplications({});
    }
  };

  const handleCloseDeclineModal = () => {
    declineModalRef && declineModalRef.current?.close();
  };

  const handleOpenDeclineModal = () => {
    declineModalRef && declineModalRef.current?.open();
  };

  const handleBatchAcceptApplications = async () => {
    try {
      setIsHandlingApplications(true);
      const acceptPayloads: AxiosResponse<IActivityApplication>[] = [];

      const applicationsToAccept: IActivityApplicationWithID[] = Array.from(
        Object.values(selectedApplications),
      ) as IActivityApplicationWithID[];

      for (const application of applicationsToAccept) {
        if (
          application.applicationType === EEventApplicationType.Team &&
          application.teamSubDocument
        ) {
          const teamPayload = {
            team: application.teamSubDocument?._id,
            members: application.teamSubDocument?.members,
          };
          acceptPayloads.push(
            await ActivityService.approveTeam(
              application.activitySubDocument._id,
              application.teamSubDocument?._id,
              teamPayload,
            ),
          );
        } else {
          if (application.userProfileSubDocument) {
            acceptPayloads.push(
              await ActivityService.approve(
                application.activitySubDocument._id,
                application.userProfileSubDocument.user,
              ),
            );
          }
        }
      }

      await Promise.allSettled(applicationsToAccept);

      setSelectedApplications({});
      applicationsTableRef?.current?.deselectAll();
      // Refreshes the table, not the best way to this, but the tableRef.current.reload() method does not work.
      applicationsTableRef?.current?.setColumnFilterValue('status', '');
      setIsHandlingApplications(false);
      showSuccessToaster('Applications accepted!');
    } catch (error: any) {
      setIsHandlingApplications(false);
    }
  };

  const handleAcceptApplication = async ({
    activityId,
    userProfile,
    teamProfile,
    isTeamApplication,
  }: {
    activityId: string;
    userProfile?: UserProfile;
    teamProfile?: ITeam;
    isTeamApplication?: boolean;
  }) => {
    try {
      setIsHandlingApplications(true);

      let response;
      if (isTeamApplication && teamProfile) {
        const teamPayload = {
          team: teamProfile?._id,
          members: teamProfile?.members,
        };
        response = await ActivityService.approveTeam(
          activityId,
          teamProfile?._id,
          teamPayload,
        );
      } else {
        if (userProfile) {
          response = await ActivityService.approve(
            activityId,
            userProfile.user,
          );
        }
      }

      if (response && response.status === 201) {
        setIsHandlingApplications(false);
        // Refreshes the table, not the best way to this, but the tableRef.current.reload() method does not work.
        applicationsTableRef &&
          applicationsTableRef?.current?.setColumnFilterValue('status', '');
        showSuccessToaster('Application accepted!');
      }
    } catch (error: any) {
      setIsHandlingApplications(false);
    }
  };

  const handleDeclineApplications = async () => {
    try {
      setIsHandlingApplications(true);
      handleCloseDeclineModal();
      reset();

      const rejectionPayloads: AxiosResponse<IActivityApplication>[] = [];

      const applicationsToReject: IActivityApplicationWithID[] = Array.from(
        Object.values(selectedApplications),
      ) as IActivityApplicationWithID[];

      for (const application of applicationsToReject) {
        if (
          application.applicationType === EEventApplicationType.Team &&
          application.teamSubDocument
        ) {
          const teamPayload = {
            team: application.teamSubDocument?._id,
            members: application.teamSubDocument?.members,
          };
          rejectionPayloads.push(
            await ActivityService.rejectTeam(
              application.activitySubDocument._id,
              application.teamSubDocument?._id,
              teamPayload,
            ),
          );
        } else {
          if (application.userProfileSubDocument) {
            rejectionPayloads.push(
              await ActivityService.reject(
                application.activitySubDocument._id,
                application.userProfileSubDocument.user,
                {
                  // Replace new lines with <br /> to display as HTML
                  // in the email template
                  description: rejectDescription.replace(/\n/g, '<br />'),
                },
              ),
            );
          }
        }
      }

      await Promise.allSettled(rejectionPayloads);
      setSelectedApplications({});
      applicationsTableRef?.current?.deselectAll();
      // Refreshes the table, not the best way to this, but the tableRef.current.reload() method does not work.
      applicationsTableRef?.current?.setColumnFilterValue('status', '');
      setIsHandlingApplications(false);
      showSuccessToaster('Applications declined!');
    } catch (e) {
      setIsHandlingApplications(false);
    }
  };

  const handleDeclineSingleApplication = (
    selectedRow: IActivityApplication,
  ) => {
    const application = {
      [selectedRow._id]: { id: selectedRow._id, ...selectedRow },
    } as SelectedRows<BaseModel>;

    // Handle it on the decline function
    setSelectedApplications({ ...application });

    handleOpenDeclineModal();
  };

  const showSuccessToaster = (message: string) => {
    toast.success(message, {
      position: 'top-center',
    });
  };

  return {
    setSelectedApplications,
    onSelectionChange,
    selectedApplications,
    isSelectedApplicationsEmpty,
    selectedApplicationsCount,
    handleAcceptApplication,
    handleDeclineSingleApplication,
    handleDeclineApplications,
    handleBatchAcceptApplications,
    declineModalRef,
    applicationsTableRef,
    handleCloseDeclineModal,
    handleOpenDeclineModal,
    control,
    setTableRef,
    isHandlingApplications,
  };
};
