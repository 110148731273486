import './bar.css';

import { Skeleton, Text, VStack } from 'native-base';
import Chart from 'react-apexcharts';
import { colors } from 'ui/theme/colors';

import { InsightsGenericEmptyState } from '~/pages/Authenticated/Insights/components/EmptyState';

export type BarChartDataParams = {
  series: {
    name: string;
    data: number[];
  }[];
  labels: string[];
};

type BarChartProps = BarChartDataParams & {
  isLoading?: boolean;
  colors?: string[];
  yaxis: ApexCharts.ApexOptions['yaxis'];
  xaxis: ApexCharts.ApexOptions['xaxis'];
};

const defaultColors = [
  colors.tertiary[400],
  colors.orange[400],
  colors.primary[200],
  colors.tertiary[300],
];

const BarChartSkeleton = () => <Skeleton height="180px" width="100%" />;

const BarChartEmptyState = () => (
  <VStack>
    <InsightsGenericEmptyState />
  </VStack>
);

export const BarChart = ({
  labels,
  series,
  isLoading,
  colors = defaultColors,
  xaxis,
  yaxis,
}: BarChartProps) => {
  if (isLoading) {
    return <BarChartSkeleton />;
  }

  if (!labels.length) {
    return <BarChartEmptyState />;
  }

  return (
    <Chart
      series={series}
      type="bar"
      height={350}
      width={'100%'}
      options={{
        chart: {
          id: 'my-custom-bar',
          toolbar: {
            show: false,
          },
        },
        fill: {
          opacity: 1,
        },
        legend: {
          fontSize: '12px',
          horizontalAlign: 'center',
          markers: {
            shape: 'circle',
          },
        },
        colors,
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: '55%',
            borderRadius: 4,
            borderRadiusApplication: 'end',
          },
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          show: true,
          width: 2,
          colors: ['transparent'],
        },
        xaxis,
        yaxis,
      }}
    />
  );
};
