import {
  Box,
  Divider,
  Flex,
  HStack,
  Pressable,
  Spinner,
  Stack,
  Text,
  VStack,
} from 'native-base';
import Illustration, { ILLUSTRATIONS_NAME } from 'ui/components/Illustration';

import { IOrganization } from '../../../../types/interfaces/organization';
import { AppCard } from './components/AppCard';
import { AppSharingCard } from './components/AppSharingCard';
import { styles } from './styles';
import { useAppsController } from './useAppsController';

export const Apps = () => {
  const {
    apps,
    isLoadingApps,
    organization,
    handleNewApp,
    handleGoToAppDetails,
    appsConnectedToOrganization,
    isLoadingAppsConnectedToOrganization,
  } = useAppsController();

  return (
    <>
      <HStack
        justifyContent="space-between"
        borderBottomColor="gray.300"
        borderBottomWidth="1"
        pb="4"
      >
        <VStack flex="1" space="2">
          <Text fontSize="xl" fontWeight="500" color="gray.600">
            Your apps
          </Text>
          <Text color="gray.600">Apps owned by your organisation</Text>
        </VStack>
        <Box>
          {/* <Button leftIconName="plus-circle" onPress={handleNewApp}>
            New app
          </Button> */}
        </Box>
      </HStack>
      {isLoadingApps || !organization ? (
        <Spinner mt="10" />
      ) : (
        <>
          {(apps && apps?.length > 0 && (
            <Flex
              mt="6"
              flexDirection="row"
              flexWrap="wrap"
              style={{ gap: 16 }}
            >
              {apps?.map((app) => (
                <Pressable
                  flex="0 0 32%"
                  key={app._id}
                  onPress={() => handleGoToAppDetails(app._id)}
                >
                  <AppCard app={app} />
                </Pressable>
              ))}
            </Flex>
          )) || (
            <HStack
              alignItems="center"
              space="2"
              justifyContent="center"
              flex={1}
            >
              <Illustration
                width="120px"
                height="120px"
                name={ILLUSTRATIONS_NAME.NO_SIGNAL_CONNECTION_ERROR_BLACK}
              />
              <Text color="gray.500">{"You're not connected to any app."}</Text>
            </HStack>
          )}
        </>
      )}

      <HStack
        mt="10"
        space="6"
        alignItems="center"
        justifyContent="space-between"
      >
        <Stack space="2">
          <Text color="gray.600" fontSize="lg" fontWeight={400}>
            Connected Apps
          </Text>
          <Text color="gray.500">Apps obtained from your network</Text>
        </Stack>
        {/* <Button
          leftIconName="search"
          variant="outline"
          leftIconColor={colors.primary[600]}
          width="150px"
          // onPress={handleManageApps}
        >
          Find Apps
        </Button> */}
      </HStack>

      <Divider mt="3" />

      <HStack mt="5" flexWrap="wrap" style={styles.gap}>
        {isLoadingAppsConnectedToOrganization ? (
          <Stack alignItems={'center'} justifyContent={'center'} flex={1}>
            <Spinner alignItems="center" mt="10" />
          </Stack>
        ) : !appsConnectedToOrganization?.length ? (
          <HStack
            alignItems="center"
            space="2"
            justifyContent="center"
            flex={1}
          >
            <Illustration
              width="120px"
              height="120px"
              name={ILLUSTRATIONS_NAME.NO_SIGNAL_CONNECTION_ERROR_BLACK}
            />
            <Text color="gray.500">{"You're not connected to any app."}</Text>
          </HStack>
        ) : (
          <>
            {appsConnectedToOrganization?.map((appSharing) => (
              <Box key={appSharing.app._id} w="32%">
                <AppSharingCard
                  app={appSharing.app}
                  sharerOrganization={
                    appSharing.sharerOrganization as unknown as IOrganization
                  }
                  sharerEcosystem={appSharing.sharerEcosystem}
                  receiverEcosystem={appSharing.receiverEcosystem}
                />
              </Box>
            ))}
          </>
        )}
      </HStack>
    </>
  );
};
