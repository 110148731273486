import SelectFilter from '@inovua/reactdatagrid-community/SelectFilter';
import { IColumn } from '@inovua/reactdatagrid-community/types';
import { Stack } from 'native-base';
import DataTableActions from 'ui/components/DataTableActions';
import { TableCellWithImage } from 'ui/components/TableCellWithImage';
import Tag from 'ui/components/Tags/Tag';
import { EEventApplicationType, LocationOptionsEnum } from 'ui/enums';
import { ActivityType } from 'ui/types/activities';
import { getPublishPermissionForActivityType } from 'ui/utils/permissions';

import { ActivityTypeEnum } from '~/enums';
import { colors } from '~/theme/colors';
import {
  iconToShowByActivityType,
  labelTypeEventBgColorByActivityType,
  showCustomIconByActivityTypeAction,
  textToShowByActivityType,
} from '~/utils/functions';

import { IActivitiesColumnsData, IActivitiesColumnsProps } from './types';

export const tableActivitiesColumns = ({
  onEditActivity,
  onViewActivity,
  connectedApps,
  validateIfAppHasPermission,
  validateIfOrganizationIsAppOwner,
}: IActivitiesColumnsProps): IColumn[] => [
  {
    filterable: true,
    name: 'title',
    header: 'Activity',
    defaultFlex: 1,
    render: ({ data }: IActivitiesColumnsData) => data.title || '-',
  },

  {
    filterable: true,
    name: 'app',
    header: 'App',
    defaultFlex: 1,
    filterEditor: SelectFilter,
    filterEditorProps: {
      multiple: false,
      wrapMultiple: false,
      dataSource:
        connectedApps?.length &&
        connectedApps.map((connectedApp) => {
          return {
            id: connectedApp.app._id,
            label: connectedApp.app.name,
          };
        }),
    },
    render: ({ data }: IActivitiesColumnsData) => (
      <TableCellWithImage
        name={data.appSummary?.name || data.app?.name}
        logo={data.appSummary?.logo || data.app?.logo}
      />
    ),
  },
  {
    name: 'type',
    header: 'Type',
    defaultFlex: 1,
    filterEditor: SelectFilter,
    filterEditorProps: {
      multiple: false,
      wrapMultiple: false,
      dataSource: Object.values(ActivityTypeEnum).map((activityType) => {
        return {
          id: activityType,
          label:
            activityType === ActivityTypeEnum.OngoingOpportunity
              ? 'Ongoing'
              : activityType,
        };
      }),
    },
    render: ({ data }: IActivitiesColumnsData) => (
      // Stack is used to avoid full width tag.
      <Stack>
        <Tag
          text={textToShowByActivityType(
            data.type as unknown as ActivityTypeEnum,
            data.eventApplicationType,
          )}
          type="actions"
          icon={iconToShowByActivityType(
            data.type as unknown as ActivityTypeEnum,
            data.eventApplicationType,
          )}
          tagTextColor={colors.singletons.darkText}
          tagBgColor={labelTypeEventBgColorByActivityType(
            data.type as unknown as ActivityTypeEnum,
          )}
          showCustomIcon={showCustomIconByActivityTypeAction(
            data.type as unknown as ActivityTypeEnum,
          )}
          showIcon
        />
      </Stack>
    ),
  },

  {
    filterable: true,
    name: 'locationOption',
    header: 'Location Option',
    defaultFlex: 1,
    filterEditor: SelectFilter,
    filterEditorProps: {
      multiple: false,
      wrapMultiple: false,
      dataSource: Object.values(LocationOptionsEnum).map((locationOption) => {
        return {
          id: locationOption,
          label: locationOption,
        };
      }),
    },
    render: ({ data }: IActivitiesColumnsData) => data.locationOption,
  },
  {
    name: 'actions',
    header: 'Actions',
    defaultFlex: 0.5,
    render: ({ data }: IActivitiesColumnsData) => {
      const isTeamEvent =
        data.eventApplicationType === EEventApplicationType.Team;
      const activityPermission = getPublishPermissionForActivityType({
        activityType: data.type as ActivityType,
        isTeamEvent,
      });

      const basePopoverOptions = [
        {
          icon: {
            color: colors.gray[600],
            icon: 'eye',
            size: 22,
          },
          title: 'View activity',
          action: () =>
            onViewActivity(
              data.type as unknown as ActivityTypeEnum,
              data._id as string,
            ),
        },
      ];

      const validatedOptions = [];

      /* 
        -- PERMISSION VALIDATION DISABLED TEMPORARY --
        if (
        validateIfOrganizationIsAppOwner(data.app._id) ||
        validateIfAppHasPermission(data.app._id, activityPermission)
      ) { */
      validatedOptions.push({
        icon: {
          color: colors.gray[600],
          icon: 'edit',
          size: 22,
        },
        title: 'Edit activity',
        action: () =>
          onEditActivity(
            data.type as unknown as ActivityTypeEnum,
            data._id as string,
          ),
      });
      /* } */

      return (
        <DataTableActions
          popoverActions
          popoverTextColor={colors.primary[400]}
          popoverIconColor={colors.primary[400]}
          popoverOptions={[...basePopoverOptions, ...validatedOptions]}
        />
      );
    },
  },
];
