import React from 'react';

import { EcosystemForm } from '~/components/EcosystemForm';
import Scaffold from '~/components/Scaffold';

import { useEditEcosystemController } from './controller';

export const EditEcosystem: React.FC = () => {
  const { ecosystemIdToEdit, handleOnSubmit } = useEditEcosystemController();

  return (
    <Scaffold title="Edit ecosystem" alignItemsChildren="center">
      <EcosystemForm
        ecosystemIdToEdit={ecosystemIdToEdit}
        onSubmit={handleOnSubmit}
      />
    </Scaffold>
  );
};
