export const activitiesTabElements = [
  {
    title: 'Applications',
    component: () => <></>,
  },
  {
    title: 'Activities',
    component: () => <></>,
  },
];
