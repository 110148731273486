import { AxiosResponse } from 'axios';
import { IPopulatedNetworkAppSharing } from 'ui/types/interfaces/network-app-sharing';

import { api } from '~/services/api';

import { QueryParams } from './types';

interface UpdateNetworkAppSharingPayload {
  isEnabled: boolean;
}

export class NetworkAppSharingService {
  static MODULE_URL = '/network-app-sharing';
  static async updateStatus(
    id: string,
    payload: Partial<UpdateNetworkAppSharingPayload>,
  ) {
    return await api.put(`${this.MODULE_URL}/${id}`, payload);
  }

  static async findByApp(id: string, params?: QueryParams) {
    const options = {
      params,
    };
    return await api.get(`${this.MODULE_URL}/app/${id}`, options);
  }
  static async loadAppsConnectedToEcosystem(
    ecosystemId: string,
  ): Promise<AxiosResponse<IPopulatedNetworkAppSharing[]>> {
    return await api.get(`${this.MODULE_URL}/ecosystem/${ecosystemId}`);
  }
}
