import { VStack } from 'native-base';
import Tabs from 'ui/components/Tabs';
import { LocationOptionsEnum } from 'ui/enums';

import { ActivityFormContainer } from '~/components/ActivityFormBuilderPresets/components/ActivityFormContainer';
import { MediaForm } from '~/components/MediaForm';
import Scaffold from '~/components/Scaffold';
import { AlertImportedActivity } from '~/pages/Authenticated/Activities/components/AlertImportedActivity';
import { colors } from '~/theme/colors';

import { eventFormTabsElements } from '../../tabsElements';
import { EventFormTabsPages } from '../../types';
import { AboutForm } from './AboutForm';
import { useEventFormController } from './controller';
import { WhenWhereForm } from './WhenWhereForm';

export const EditEventForm = () => {
  const {
    tabsRef,
    currentTab,
    causeOptions,
    requirementOptions,
    formData,
    isLoading,
    handleBack,
    handleContinue,
    isTeamEvent,
    isLoadingDefaultValues,
    filteredAppsByPermissions,
    appIdToValidatePermisssions,
    requiredPagePermissions,
    isAppOwner,
    defaultLocationGroupsByDate,
    currentLocationOption,
  } = useEventFormController();

  const renderTabs = () => {
    return {
      [EventFormTabsPages.About]: (
        <AboutForm
          handleContinue={handleContinue}
          handleBack={handleBack}
          causeOptions={causeOptions}
          requirementOptions={requirementOptions}
          defaultValues={formData}
          isLoading={isLoading}
          isLoadingDefaultValues={isLoadingDefaultValues}
          connectedApps={filteredAppsByPermissions}
        />
      ),
      [EventFormTabsPages.Media]: (
        <MediaForm
          handleBack={handleBack}
          handleContinue={handleContinue}
          defaultValues={formData}
          isLoading={isLoading}
        />
      ),
      [EventFormTabsPages.WhenWhere]: (
        <WhenWhereForm
          handleBack={handleBack}
          handleContinue={handleContinue}
          isTeamEvent={isTeamEvent}
          defaultValues={formData}
          isLoading={isLoading}
          isLoadingDefaultValues={isLoadingDefaultValues}
          defaultLocationGroupsByDate={defaultLocationGroupsByDate}
          currentLocationOption={currentLocationOption as LocationOptionsEnum}
        />
      ),
    }[currentTab];
  };
  return (
    // <Permissions.ValidateApp
    //   disablePermissionsValidation={!formData?.selectedApp || isAppOwner}
    //   appIdToValidate={appIdToValidatePermisssions}
    //   requiredPermissions={requiredPagePermissions}
    //   fallbackComponent={<Page403 />}
    // >
    <Scaffold title="Edit event" alignItemsChildren="center">
      <ActivityFormContainer>
        <VStack w="100%" alignItems="center" pb={10}>
          {formData.externalId && formData.externalProvider && (
            <AlertImportedActivity mb={10} />
          )}
          <Tabs
            ref={tabsRef}
            tabBgColor="transparent"
            tabWith="100%"
            tabTextColor={colors.primary['600']}
            tabIndicatorColor={colors.primary['600']}
            tabfocusedTextColor={colors.primary['600']}
            swipeEnabled={false}
            elements={eventFormTabsElements}
            currentTab={currentTab as number}
          />
        </VStack>
        {renderTabs()}
      </ActivityFormContainer>
    </Scaffold>
    // </Permissions.ValidateApp>
  );
};
