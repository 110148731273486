import { Box, Divider, HStack, Stack, Text, VStack } from 'native-base';
import { HelpButton } from 'ui/components/v2/Buttons/HelpButton';

export type SettingsSectionHeaderProps = {
  title: string;
  subTitle?: string;
  actionSlot?: React.ReactNode;
  showTitleHelpButton?: boolean;
  onTitleHelpButtonPress?: VoidFunction;
  primary?: boolean;
};

export const SettingsSectionHeader = ({
  title,
  subTitle,
  actionSlot,
  primary = false,
  showTitleHelpButton,
  onTitleHelpButtonPress,
}: SettingsSectionHeaderProps) => {
  return (
    <VStack space={2}>
      <Stack
        w="full"
        alignItems={{ base: 'flex-start', lg: 'center' }}
        justifyContent={'space-between'}
        flexDir={{ base: 'column', lg: 'row' }}
        space={2}
      >
        <VStack space={2} flex={1}>
          {/* Title */}
          <HStack alignItems={'center'} space={2}>
            <Text
              fontWeight="500"
              fontSize={{
                base: primary ? 'md' : 'sm',
                md: primary ? 'lg' : 'md',
              }}
              color="gray.600"
            >
              {title}
            </Text>

            {showTitleHelpButton ? (
              <HelpButton onPress={onTitleHelpButtonPress} />
            ) : null}
          </HStack>

          {/* Sub title */}
          {subTitle && (
            <Text fontWeight="400" fontSize="xs" color="gray.600">
              {subTitle}
            </Text>
          )}
        </VStack>

        {actionSlot && (
          <Box w={{ base: 'full', lg: 'unset' }}>{actionSlot}</Box>
        )}
      </Stack>

      {primary && <Divider width="100%" />}
    </VStack>
  );
};
