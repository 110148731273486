/* eslint-disable import/namespace */
import { Flex, Hidden, Stack } from 'native-base';
import { RedirectPage } from 'ui/components/RedirectPage';
import { SignUpBranding } from 'ui/components/SignUpBranding';

import { Loading } from '~/components/Loading';
import ModalPrivacyPolicy from '~/components/ModalPrivacyPolicy';
import ModalTermsOfService from '~/components/ModalTermsOfService';
import { BHO_ORGANIZATION } from '~/config';
import { ConfirmEmailPage } from '~/pages/Common/JoinAsPartnerSignup/ConfirmEmailPage';
import { CreateAccountPage } from '~/pages/Common/JoinAsPartnerSignup/CreateAccountPage';
import { colors } from '~/theme/colors';

import { useJoinAsPartnerSignUpController } from './controller';

export const JoinAsPartnerSignUp = () => {
  const {
    control,
    errors,
    inviteEcosystem,
    inviteCode,
    isLoading,
    organization,
    isDisabled,
    isResendCodeDisabled,
    coolDownTime,
    isSubmittingCreateAccount,
    isSubmittingVerifyCode,
    email,
    currentPage,
    modalPrivacyPolicyRef,
    modalTermsOfServiceRef,
    handleOpenPrivacyPolicy,
    handleOpenTermsOfService,
    handleCreateAccount,
    handleResendCode,
    onVerifyCode,
    onSignInPress,
  } = useJoinAsPartnerSignUpController();

  if (isLoading) {
    return <Loading />;
  }

  if (!inviteCode) {
    return (
      <RedirectPage
        title="Invite not found"
        text="We couldn't find your invitation; please contact the partner organization for assistance."
        onButtonPress={onSignInPress}
        buttonColor={colors.gray[900]}
      />
    );
  }

  const tabComponents: Record<number, React.ReactNode> = {
    0: (
      <CreateAccountPage
        onSubmit={handleCreateAccount}
        control={control}
        errors={errors}
        isSubmitting={isSubmittingCreateAccount}
        onOpenPrivacyPolicy={handleOpenPrivacyPolicy}
        onOpenTermsOfService={handleOpenTermsOfService}
        isDisabled={isDisabled}
        onSignInPress={onSignInPress}
      />
    ),
    1: (
      <ConfirmEmailPage
        control={control}
        errors={errors}
        coolDownTime={coolDownTime}
        handleResendCode={handleResendCode}
        isResendCodeDisabled={isResendCodeDisabled}
        isSubmitting={isSubmittingVerifyCode}
        email={email}
        onVerifyCode={onVerifyCode}
        isDisabled={isDisabled}
      />
    ),
  };

  return (
    <Flex
      minH="100vh"
      height="100%"
      width="100%"
      flexDir={{ base: 'column', lg: 'row' }}
      overflowX="hidden"
    >
      <Hidden till="md">
        <Stack width="55%">
          <SignUpBranding
            ecosystemName={inviteEcosystem?.name || ''}
            logo={inviteCode?.composeSettings?.logo || organization?.logo}
            organizationName={organization?.name || ''}
            backgroundImage={inviteCode?.composeSettings?.backgroundImage}
            brandColor={inviteCode?.composeSettings?.brandColor}
            height="100%"
            isBigHelpOutBranded={
              inviteCode?.sharerOrganization === BHO_ORGANIZATION
            }
          />
        </Stack>
      </Hidden>
      {tabComponents[currentPage]}
      <ModalPrivacyPolicy modalRef={modalPrivacyPolicyRef} />
      <ModalTermsOfService modalRef={modalTermsOfServiceRef} />
    </Flex>
  );
};
