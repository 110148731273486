import { PopulatedAppPermissionGroup } from 'ui/types/interfaces/app-permissions-group';

import { useQuery } from '~/hooks/useQuery';

export const useLoadAppGroupPermissionById = (id: string) => {
  const { data, isLoading, refetch } = useQuery<PopulatedAppPermissionGroup>(
    `/app-permission-group/${id}`,
    {
      queryOptions: {
        enabled: !!id,
      },
      key: `app-permission-group-${id}`,
    },
  );

  return {
    data,
    isLoading,
    refetch,
  };
};
