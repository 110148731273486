import { colors } from '../../theme/colors';
import { ICustomRadioProps } from './types';

export const useRadioController = ({
  label,
  _formControl,
  hasError,
  options,
  control,
  name,
  isInvalid,
  isDisabled,
  isRequired,
  isReadOnly,
  errorMessage,
  helperText,
  checkedColor = colors.primary[600],
  iconErrorMessage,
  iconErrorSize,
  onChangeValue,
  hasDescriptions,
  ...restProps
}: ICustomRadioProps) => {
  return {
    _formControl,
    control,
    name,
    helperText,
    hasError,
    isInvalid,
    isDisabled,
    isReadOnly,
    options,
    isRequired,
    errorMessage,
    iconErrorSize,
    iconErrorMessage,
    label,
    restProps,
    checkedColor,
    onChangeValue,
    hasDescriptions,
  };
};
