import { Box, Flex, Text, VStack } from 'native-base';
import Button from 'ui/components/Button';
import Illustration, { ILLUSTRATIONS_NAME } from 'ui/components/Illustration';

import { PAGES } from '~/constants/pages.constants';
import { colors } from '~/theme/colors';

import { styles } from './styles';
import { useAccountDeletedFeedbackController } from './useAccountDeletedFeedbackController';

export const AccountDeletedFeedback = () => {
  const { goToRoute } = useAccountDeletedFeedbackController();
  return (
    <>
      <Flex
        pb="24"
        minH="100vh"
        backgroundColor={colors.singletons.lightText}
        justifyContent="center"
      >
        <Flex
          w="min(90%, 900px)"
          mx="auto"
          alignItems="center"
          flexDirection={styles.flexDirection}
        >
          <Box w={styles.imageContainerW}>
            <Illustration
              name={ILLUSTRATIONS_NAME.INVITATION_DECLINED}
              alt="Logo refused application"
              w={styles.imageW}
              h={styles.imageH}
              rounded="full"
              resizeMode="cover"
            />
          </Box>
          <Box flex="1" mt={styles.boxMt}>
            <Text
              color="gray.700"
              fontWeight="700"
              fontSize={styles.titleFontSize}
              textAlign={styles.titleAlignment}
            >
              {`We are sorry that you have left us!`}
            </Text>
            <Text
              mt="4"
              color="gray.500"
              fontSize="md"
              textAlign={styles.subTitleAlignment}
            >
              Hope to see you again.
            </Text>
            <VStack
              width="100%"
              alignItems="center"
              space={2}
              mt="12"
              display={styles.buttonContainerDisplay}
            >
              <Button
                p={6}
                fontWeight={500}
                fontSize="md"
                onPress={() => goToRoute(PAGES.SIGNIN)}
              >
                Go to home page
              </Button>
            </VStack>
          </Box>
        </Flex>
      </Flex>
    </>
  );
};
