import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { useAppSelector } from '~/hooks/useAppSelector';
import { useRouter } from '~/hooks/useRouter';
import { authSliceActions } from '~/store/slices/auth';

export const useAccountDeletedFeedbackController = () => {
  const { isAuthenticated } = useAppSelector(({ auth }) => auth);

  const dispatch = useDispatch();
  const { goToRoute } = useRouter();

  useEffect(() => {
    if (isAuthenticated) {
      dispatch(authSliceActions.logout());
    }
  }, [isAuthenticated, dispatch]);

  return { goToRoute };
};
