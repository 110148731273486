import { NotificationCard } from '../../BaseNotificationCard';
import { useBulkActivitiesCreatedNotificationCardController } from './controller';
import { BulkActivitiesCreatedNotificationCardProps } from './types';

export const BulkActivitiesCreatedNotificationCard = (
  props: BulkActivitiesCreatedNotificationCardProps,
) => {
  const { titleNodes, date, organizationLogoUrl } =
    useBulkActivitiesCreatedNotificationCardController(props);

  return (
    <NotificationCard
      authorImageUrl={organizationLogoUrl}
      titleNodes={titleNodes}
      date={date}
    />
  );
};
