export const useChildOrgAccessController = () => {
  const childOrgPermissions = [
    {
      title: 'Members',
      accesses: [
        'Can add members to the ecosystem',
        'Can remove members from the ecosystem',
        'Can add partners to the ecosystem',
        'Can remove partners from the ecosystem',
        'Can view members activities',
        'Can manage members access and permissions',
      ],
    },
    {
      title: 'Activities',
      accesses: [
        'Can publish events',
        'Can publish actions',
        'Can publish ongoing opportunities',
        'Receive report of members’ applications',
      ],
    },
    {
      title: 'Log activity',
      accesses: ['Receive report of members’ participation'],
    },
  ];

  return { childOrgPermissions };
};
