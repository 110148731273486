/* eslint-disable react/display-name */
import './styles.css';

import enGB from 'date-fns/locale/en-GB';
import { useRef, useState } from 'react';
import ReactDatePicker, {
  CalendarContainerProps,
  ReactDatePickerProps,
  registerLocale,
  setDefaultLocale,
} from 'react-datepicker';

import Button from '../../Button';

registerLocale('enUK', enGB);
setDefaultLocale(`enUK`);

type DateRangePickerProps = {
  initialStartDate?: Date;
  initialEndDate?: Date;
  onSelectDates(
    dates: [Date, Date],
    datePickerRef: React.RefObject<ReactDatePicker>,
  ): void;
  shouldCloseOnSelect?: boolean;
  customInput?: (
    datePickerRef: React.RefObject<ReactDatePicker>,
  ) => ReactDatePickerProps['customInput'];
};

export const DateRangePicker = ({
  initialStartDate,
  initialEndDate,
  shouldCloseOnSelect = true,
  customInput,
  onSelectDates,
}: DateRangePickerProps) => {
  const datepickerRef = useRef<ReactDatePicker>(null);
  const [startDate, setStartDate] = useState(initialStartDate || new Date());
  const [endDate, setEndDate] = useState<Date | null>(initialEndDate ?? null);
  const onChange = (dates: [Date, Date]) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
    if (shouldCloseOnSelect && start && end) {
      onSelectDates(dates, datepickerRef);
    }
  };

  const handleSelect = () => {
    onSelectDates([startDate, endDate!], datepickerRef);
  };

  const CustomContainer = ({ children }: CalendarContainerProps) => {
    return (
      <div className={'custom-calendar-container'}>
        <div className={'custom-calendar-container-children'}>{children}</div>
        {!shouldCloseOnSelect && (
          <Button onPress={handleSelect}>Confirmar</Button>
        )}
      </div>
    );
  };

  const CustomInput = customInput ? customInput(datepickerRef) : undefined;

  return (
    <ReactDatePicker
      monthsShown={2}
      ref={datepickerRef}
      startOpen={false}
      closeOnScroll={false}
      shouldCloseOnSelect={shouldCloseOnSelect}
      selected={startDate}
      onChange={onChange}
      startDate={startDate}
      endDate={endDate}
      selectsRange
      calendarContainer={!shouldCloseOnSelect ? CustomContainer : undefined}
      customInput={CustomInput}
      // ref: apps/org-admin/index.html
      portalId="modal-root"
    />
  );
};
