import { isValid as isValidDate } from 'date-fns';
import { useMemo } from 'react';
import { formatDateByEnglishType } from 'ui/utils/formatter';
import { getNameInitials } from 'ui/utils/getNameInititals';

import { colors } from '../../../../theme/colors';
import TeamMemberCard from '../TeamMemberCardBase';
import { TeamAcceptedMemberCardProps } from './types';

export const TeamAcceptedMemberCard = (props: TeamAcceptedMemberCardProps) => {
  const { name, email, joinedAt, profilePicture } = props;

  const description = useMemo(() => {
    if (!joinedAt || !isValidDate(new Date(joinedAt))) return '';
    return `Member since ${formatDateByEnglishType(joinedAt, 'british')}`;
  }, [joinedAt]);

  const title = name || email || 'Unnamed';
  const avatarUrl = profilePicture || getNameInitials(title);

  return (
    <TeamMemberCard
      title={title}
      descriptionColor={colors.gray[500]}
      descriptionWeight={400}
      description={description}
      avatarUrl={avatarUrl}
      showAvatar={true}
    />
  );
};

export default TeamAcceptedMemberCard;
