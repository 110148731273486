import { useAppDispatch } from '~/hooks/useAppDispatch';
import AuthService from '~/services/resources/auth';
import UserService from '~/services/resources/user';
import { authSliceActions } from '~/store/slices/auth';

/* This might be use as a default to Org-admin authentication */

export const useAuthentication = () => {
  const dispatch = useAppDispatch();

  const signIn = async (email: string, password: string) => {
    const response = await AuthService.signInWithEmailAndPassword({
      email,
      password,
    });

    dispatch(authSliceActions.signIn(response));

    const { data } = await UserService.me();

    dispatch(authSliceActions.update({ _id: data._id, isAuthenticated: true }));
  };

  return { signIn };
};
