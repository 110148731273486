import { HStack, Skeleton } from 'native-base';

export const TrendingElementSkeleton = () => {
  return (
    <HStack space={2} alignItems={'center'} flex={1}>
      <Skeleton h={'32px'} w={'32px'} rounded="full" />
      <Skeleton.Text
        fontSize={'xl'}
        fontWeight={500}
        lines={1}
        w="full"
        maxW={'200px'}
        flex={1}
      />
    </HStack>
  );
};
