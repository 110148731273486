import { ORG_HELP_CENTER_URL } from 'ui/constants/commonUrl.constants';
import { colors } from 'ui/theme/colors';

import { IPopoverOption } from '~/components/MenuDropdown/types';
import { VITE_DOIT_VOLUNTEER_URL } from '~/config';
import { PAGES } from '~/constants/pages.constants';

export const getOptionGroups = ({
  isMobile = false,
  isCompany = false,
}: {
  isMobile?: boolean;
  isCompany?: boolean;
}): Array<IPopoverOption[]> => {
  const groups: Array<IPopoverOption[]> = [
    [
      {
        key: 'switchEcosystem',
        icon: 'switch',
        label: 'Switch ecosystem',
        path: PAGES.SWITCH_ECOSYSTEM,
        action: 'router',
      },
      {
        key: 'changeOrganization',
        label: 'Change organisation',
        icon: 'refresh-cw',
        path: PAGES.CHANGE_ORG,
        action: 'router',
      },
      {
        key: 'OrganizationSettings',
        label: 'Organisation settings',
        icon: 'settings',
        path: PAGES.SETTINGS,
        action: 'router',
      },
      {
        key: 'accountSettings',
        icon: 'user',
        label: 'Account settings',
        path: PAGES.ACCOUNT_SETTINGS,
        action: 'router',
      },
      {
        key: 'go_to_my_volunteer_area',
        icon: 'external-link',
        label: 'Go to my volunteer area',
        path: VITE_DOIT_VOLUNTEER_URL,
        action: 'external',
      },
      {
        key: 'help_centre',
        icon: 'question',
        label: 'Help centre',
        path: ORG_HELP_CENTER_URL,
        action: 'external',
      },
      {
        key: 'sign_out',
        icon: 'log-out',
        iconColor: colors.error[600],
        label: 'Sign out',
        path: '',
        action: 'custom',
      },
    ],
  ];

  if (!!isMobile) {
    const pagesGroup: IPopoverOption[] = [
      {
        key: 'activities',
        icon: 'calendar',
        label: 'Activities',
        path: PAGES.ACTIVITIES,
        action: 'router',
      },
      {
        key: 'members',
        icon: 'users',
        label: 'Members',
        path: PAGES.MEMBERS,
        action: 'router',
      },
    ];

    if (isCompany) {
      pagesGroup.unshift({
        key: 'home',
        icon: 'home',
        label: 'Home',
        path: PAGES.HOME,
        action: 'router',
      });

      pagesGroup.push({
        key: 'Insights',
        icon: 'bar-chart-2',
        label: 'Insights',
        path: PAGES.INSIGHTS,
        action: 'router',
      });
    }

    groups.unshift(pagesGroup);
  }

  return groups;
};
