import { Stack } from 'native-base';
import Modal from 'ui/components/Modals/Modal';

import { colors } from '~/theme/colors';

import { ModalDeleteProps } from './types';

const ModalDelete = ({
  modalRef,
  handleCloseModal,
  handleDelete,
  headerText,
  children,
  rightBtnLoading,
  rightBtnText = 'Yes, delete',
}: ModalDeleteProps) => (
  <Modal
    headerText={headerText}
    ref={modalRef}
    modalContentMaxW="600"
    leftBtnAction={handleCloseModal}
    leftBtnText="No, go back"
    rightBtnAction={handleDelete}
    rightBtnText={rightBtnText}
    rightBtnBgColor={colors.error[600]}
    rightBtnLoading={rightBtnLoading}
  >
    <Stack py="2" space={6}>
      <Stack>{children}</Stack>
    </Stack>
  </Modal>
);

export default ModalDelete;
