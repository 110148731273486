import { HStack, Text, VStack } from 'native-base';
import React from 'react';
import Icon from 'ui/components/Icon';

import { TrendingElementSkeleton } from '~/pages/Authenticated/Insights/components/Trending/TrendingElementSkeleton';
import { colors } from '~/theme/colors';

type TrendingBaseElementProps = React.PropsWithChildren<{
  heading: string;
  isLoading?: boolean;
}>;

export const TrendingBaseElement = ({
  heading,
  children,
  isLoading,
}: TrendingBaseElementProps) => (
  <VStack p={2} space={2} flex={[undefined, undefined, 1]}>
    <HStack space={1} alignItems="center">
      <Icon icon="trending-up" size={16} color={colors.gray[500]} />
      <Text fontWeight={500} fontSize={'xs'} color={colors.gray[500]}>
        {heading}
      </Text>
    </HStack>
    {isLoading ? <TrendingElementSkeleton /> : children}
  </VStack>
);
