import { EActivityLocationTypeEnum, EEventApplicationType } from 'ui/enums';

import { EVENT_MESSAGES } from '~/constants/messages.constants';
import { IEditActivityDTO } from '~/types/dtos';

export const mountEventPayload = ({
  activityData,
  activityDefinitionId,
  isFromHome,
  organizationId,
  ecosystemId,
}: {
  activityData: IEditActivityDTO;
  activityDefinitionId: string;
  isFromHome: boolean;
  organizationId: string;
  ecosystemId: string;
}) => {
  if (!activityData.app) {
    throw EVENT_MESSAGES.CREATE_EVENT_ERROR;
  }

  const volunteerNumber =
    typeof activityData.volunteerNumber === 'number'
      ? Number(activityData.volunteerNumber)
      : null;

  const isVolunteerNumberLimited = !activityData.noVolunteerLimit;

  const eventActivityPayload: IEditActivityDTO = {
    activityDefinition: activityDefinitionId,
    eventType: EActivityLocationTypeEnum.National,
    startDate: new Date(`${activityData.startDate}`),
    endDate: new Date(`${activityData.endDate}`),
    dueDate: activityData.dueDate
      ? new Date(`${activityData.dueDate}`)
      : new Date(`${activityData.endDate}`),
    volunteerNumber: isVolunteerNumberLimited ? volunteerNumber : null,
    organization: organizationId || '',
    app: activityData.app,
    ecosystem: ecosystemId,
    isVolunteerNumberLimited,
    publishedApps: activityData.publishedApps,
    regions: activityData.regions,
    externalApplyLink:
      encodeURI(activityData.externalApplyLink || '') || undefined,
  };

  if (activityData.eventApplicationType === EEventApplicationType.Team) {
    eventActivityPayload.teamsNumber = activityData.teamsNumber;
    eventActivityPayload.teamsMinSize = activityData.teamsMinSize;
    eventActivityPayload.teamsMaxSize = activityData.teamsMaxSize;
    eventActivityPayload.isVolunteerNumberLimited = false;
  } else {
    eventActivityPayload.isVolunteerNumberLimited = isVolunteerNumberLimited;
    eventActivityPayload.volunteerNumber = isVolunteerNumberLimited
      ? volunteerNumber
      : null;
  }

  if (isFromHome) {
    // Return early to avoid unnecessary address mapping logc
    if (activityData.meetingLink) {
      return { ...eventActivityPayload, meetingLink: activityData.meetingLink };
    }

    return eventActivityPayload;
  }

  let address = {};

  if (activityData.address?.lat) {
    address = {
      street: activityData.address.rawLocation || '',
      location: {
        type: 'Point',
        coordinates: [activityData.address.lng, activityData.address.lat],
      },
    };
  } else {
    address = {
      street: activityData.address?.street || '',
      location: {
        type: activityData.address?.location?.type || 'Point',
        coordinates:
          [
            activityData.address?.location?.coordinates[0],
            activityData.address?.location?.coordinates[1],
          ] || [],
      },
    };
  }

  return { ...eventActivityPayload, address };
};
