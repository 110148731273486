import { Route, Routes } from 'react-router-dom';

import { PAGES } from '~/constants/pages.constants';
import AdminSuccessfullyJoined from '~/pages/Common/JoinAdmin/AdminSuccessfullyJoined';
import JoinAsAdminInvite from '~/pages/Common/JoinAdmin/JoinAsAdminInvite';
import JoinAsAdminInviteDeclined from '~/pages/Common/JoinAdmin/JoinAsAdminInviteDeclined';
import { JoinAsPartner } from '~/pages/Common/JoinAsPartner';
import { JoinAsPartnerInviteDeclined } from '~/pages/Common/JoinAsPartner/JoinAsAdminInviteDeclined';
import { JoinAsPartnerSignUp } from '~/pages/Common/JoinAsPartnerSignup';
import { JoinAsPartnerSignIn } from '~/pages/Unauthenticated/JoinAsPartnerSignIn';

const normalizeInvitePath = (path: string) => {
  return path.replace(/\/invite/g, '');
};

export const InvitesRoutes = () => {
  return (
    <Routes location={location}>
      <Route
        path={normalizeInvitePath(PAGES.INVITE_JOIN_AS_PARTNER)}
        element={<JoinAsPartner />}
      />

      <Route
        path={normalizeInvitePath(PAGES.INVITE_JOIN_AS_PARTNER_SLUG)}
        element={<JoinAsPartner />}
      />

      {/* PARTNER INVITE DECLINED */}
      <Route
        path={normalizeInvitePath(PAGES.INVITE_JOIN_AS_PARTNER_DECLINED)}
        element={<JoinAsPartnerInviteDeclined />}
      />

      <Route
        path={normalizeInvitePath(PAGES.INVITE_JOIN_AS_PARTNER_DECLINED_SLUG)}
        element={<JoinAsPartnerInviteDeclined />}
      />

      {/* PARTNER INVITE SIGN_UP */}

      <Route
        path={normalizeInvitePath(PAGES.INVITE_JOIN_AS_PARTNER_SIGN_UP)}
        element={<JoinAsPartnerSignUp />}
      />
      <Route
        path={normalizeInvitePath(PAGES.INVITE_JOIN_AS_PARTNER_SIGN_UP_SLUG)}
        element={<JoinAsPartnerSignUp />}
      />

      {/* PARTNER INVITE SIGN_IN */}
      <Route
        path={normalizeInvitePath(PAGES.INVITE_JOIN_AS_PARTNER_SIGN_IN)}
        element={<JoinAsPartnerSignIn />}
      />
      <Route
        path={normalizeInvitePath(PAGES.INVITE_JOIN_AS_PARTNER_SIGN_IN_SLUG)}
        element={<JoinAsPartnerSignIn />}
      />

      {/* ADMIN INVITES */}

      <Route
        path={normalizeInvitePath(PAGES.INVITE_JOIN_ADMIN)}
        element={<JoinAsAdminInvite />}
      />

      <Route
        path={normalizeInvitePath(PAGES.INVITE_JOIN_ADMIN_SUCCESS)}
        element={<AdminSuccessfullyJoined />}
      />

      <Route
        path={normalizeInvitePath(PAGES.INVITE_JOIN_ADMIN_DECLINED)}
        element={<JoinAsAdminInviteDeclined />}
      />
    </Routes>
  );
};
