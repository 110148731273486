import { HStack, Image } from 'native-base';

import { TableCellWithImageProps } from './types';

export const TableCellWithImage = ({
  name,
  logo,
  round,
}: TableCellWithImageProps) => {
  return (
    <HStack space={2} alignItems="center">
      {logo ? (
        <Image
          width="24px"
          height="24px"
          borderRadius={round ? '100%' : 'sm'}
          source={{ uri: logo }}
        />
      ) : null}
      {name}
    </HStack>
  );
};
