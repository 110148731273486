import { Box, HStack, Stack, Text } from 'native-base';
import { forwardRef } from 'react';
import Modal from 'ui/components/Modals/Modal';

import ModalNAButton from './ModalNAButton';
import { ModalAddActivityProps } from './types';
import { useModalAddActivityController } from './useModalAddActivityController';

const ModalAddActivity = forwardRef(function ModalNewActivity(
  props: ModalAddActivityProps,
  ref,
) {
  const { filteredButtonCards } = useModalAddActivityController(props);
  return (
    <Box>
      <Modal headerText="Add activity" ref={ref} modalContentMaxW="650">
        <Stack py="2" space={6} alignSelf="center">
          <Stack>
            <Text color="gray.600" fontWeight={400} fontSize={16}>
              What do you want to add?
            </Text>
          </Stack>

          {/*TODO: Check width as appointed by Andre*/}
          <HStack space={2} flexWrap="wrap">
            {filteredButtonCards.map((button, index) => (
              <Stack key={index} w="12rem">
                <ModalNAButton
                  label={button.label}
                  description={button.description}
                  action={button.action}
                />
              </Stack>
            ))}
          </HStack>
        </Stack>
      </Modal>
    </Box>
  );
});

export default ModalAddActivity;
