import { Stack } from 'native-base';

import { ActivitySuccessModal } from '~/components/ActivitySuccessModal';
import { PAGES } from '~/constants/pages.constants';
import { ActivityTypeEnum } from '~/enums';

import { useActionSuccessfulController } from './controller';
import { ActionSuccessfulProps } from './types';

export const ActionSuccessful = ({ pageTitle }: ActionSuccessfulProps) => {
  const { activity, activityUrl, selectedEcosystem, goToRoute } =
    useActionSuccessfulController();

  return (
    <Stack
      w="100%"
      h="100%"
      position="fixed"
      left={0}
      right={0}
      overflowY="scroll"
      justifyContent="center"
      alignItems="center"
      bg="rgba(0,0,0,0.5)"
    >
      <ActivitySuccessModal
        title={pageTitle}
        activityName={activity.title}
        activityType={ActivityTypeEnum.Action}
        // TODO: Mock, should add the correct logic to get all dates for the activity. Need to align UX/UI.
        activityDate={activity.createdAt}
        activityEcosystem={selectedEcosystem?.name}
        ecosystemLogo={selectedEcosystem?.logo}
        // TODO: Mock, should add the correct activity link here when we have the new volunteer. BHO link will not work as they expect an Activity ID and not a Activity Definition ID.
        activityUrl={activityUrl}
        onClose={() => goToRoute(PAGES.ACTIVITIES)}
        onViewActivity={() =>
          goToRoute(`${PAGES.VIEW_ACTION_ACTIVITY}/${activity._id}`)
        }
      />
    </Stack>
  );
};
