import { HStack, Popover, Pressable, Text, VStack } from 'native-base';
import { forwardRef, useImperativeHandle, useRef, useState } from 'react';
import Icon from 'ui/components/Icon';

import { colors } from '../../theme/colors';

export type IMenuItem<T = any> = {
  label?: string;
  value: string;
  icon?: string;
  payload?: T;
};

interface MenuItemProps extends IMenuItem {
  onPress: () => void;
  isSelected?: boolean;
}

export const MenuItem = (props: MenuItemProps) => {
  const { label, value, onPress, icon, isSelected } = props;
  return (
    <Pressable onPress={onPress} _hover={{ bg: 'gray.100' }}>
      <HStack alignItems={'center'} space={2} px={3}>
        {icon && <Icon icon={icon} size={16} color={colors.gray[400]} />}
        <Text
          py={3}
          fontSize={'sm'}
          lineHeight={'150%'}
          fontWeight={isSelected ? 500 : 400}
          color={isSelected ? colors.primary[500] : colors.darkText}
        >
          {label || value}
        </Text>
        {isSelected && (
          <Icon icon="check" size={16} color={colors.primary[500]} />
        )}
      </HStack>
    </Pressable>
  );
};

type DropdownMenuProps = {
  selectedValue?: string;
  closeOnSelect?: boolean;
  options: IMenuItem[];
  onSelect: (item: IMenuItem) => void;
  buttonProps: {
    label: string;
    leftIcon: string;
  };
};

type DropdownMenuRef = {
  open: () => void;
  close: () => void;
};

export const useDropdownMenuRef = () => useRef<DropdownMenuRef>(null);

export const DropdownMenu = forwardRef<DropdownMenuRef, DropdownMenuProps>(
  (
    {
      options,
      onSelect,
      buttonProps,
      closeOnSelect = true,
      selectedValue,
    }: DropdownMenuProps,
    ref,
  ) => {
    const [showPopOver, setShowPopOver] = useState(false);
    const triggerRef = useRef<HTMLDivElement | null>(null);
    const [popoverViewport, setPopoverViewport] = useState({
      top: 0,
      left: 0,
      width: 0,
    });

    useImperativeHandle(
      ref,
      () => {
        return {
          close: () => setShowPopOver(false),
          open: () => setShowPopOver(true),
        };
      },
      [],
    );

    const handleOpenPopover = () => {
      if (triggerRef.current) {
        const rect = triggerRef.current.getBoundingClientRect();
        setPopoverViewport({
          top: rect.bottom + window.scrollY,
          left: rect.left + window.scrollX,
          width: rect.width,
        });
        setShowPopOver(true);
      }
    };

    const handleSelect = (item: IMenuItem) => () => {
      onSelect(item);
      setShowPopOver(!closeOnSelect);
    };

    return (
      <Popover
        isOpen={showPopOver}
        trigger={(triggerOptions) => (
          <Pressable
            {...triggerOptions}
            onPress={handleOpenPopover}
            width={'fit-content'}
          >
            <HStack
              space={2}
              alignItems="center"
              backgroundColor="white"
              borderRadius={'md'}
              borderColor={colors.gray[100]}
              borderWidth={1}
              p={3}
              ref={triggerRef}
            >
              <Icon
                icon={buttonProps.leftIcon}
                size={16}
                color={colors.muted[400]}
              />
              <Text
                fontWeight={400}
                color={colors.gray[500]}
                fontSize={'sm'}
                marginRight={1}
              >
                {buttonProps.label}
              </Text>
              <Icon icon="chevron-down" size={16} color={colors.muted[400]} />
            </HStack>
          </Pressable>
        )}
        onClose={() => setShowPopOver(false)}
      >
        <VStack
          top={0}
          w={popoverViewport.width}
          left={`${popoverViewport.left}px`}
          borderBottomRadius={10}
          overflow={'hidden'}
          borderWidth={1}
          borderTopWidth={0}
          borderColor={colors.gray[300]}
          // @ts-expect-error - style is a valid prop
          style={{
            transform: `translateY(${popoverViewport.top}px)`,
            boxShadow: '0 1px 5px rgba(0, 0, 0, 0.05)',
          }}
        >
          <Popover.Body p={0} py={2} width={'full'} bg="white">
            {options.map((option) => (
              <MenuItem
                key={option.value}
                label={option.label}
                value={option.value}
                onPress={handleSelect(option)}
                isSelected={selectedValue === option.value}
              />
            ))}
          </Popover.Body>
        </VStack>
      </Popover>
    );
  },
);

DropdownMenu.displayName = 'DropdownMenu';
