import { NotificationCard } from '../../BaseNotificationCard';
import { userUserNotificationCardController } from '../controller';
import { DEFAULT_BADGE, DEFAULT_TITLE } from './constants';
import { UserProfileCreatedNotificationCardProps } from './types';

export const UserProfileCreatedNotificationCard = (
  props: UserProfileCreatedNotificationCardProps,
) => {
  const { ecosystemName } = props;
  const { date, profilePictureUrl, authorTitleNode } =
    userUserNotificationCardController(props);

  return (
    <NotificationCard
      authorImageUrl={profilePictureUrl}
      titleNodes={[
        authorTitleNode,
        { text: DEFAULT_TITLE },
        { text: ecosystemName, fontWeight: 500 },
      ]}
      badge={DEFAULT_BADGE}
      date={date}
    />
  );
};
