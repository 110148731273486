import Input from 'ui/components/Input';

import { colors } from '~/theme/colors';

import { DefaultInputMetricsProps } from './types';

export const DefaultInputMetrics = ({
  control,
  helperText,
  label,
  placeholder,
  isEditing,
  handleOpenHelpIcon,
}: DefaultInputMetricsProps) => {
  return (
    <Input
      name="targetAmount"
      control={control}
      errorMessage={
        control._formState.errors['targetAmount']?.message as string
      }
      isNumeric={true}
      isDisabled={isEditing}
      placeholder={placeholder}
      label={label}
      helperText={helperText}
      helpIcon={true}
      helpIconColor={colors.primary['600']}
      handleOpenHelpIcon={handleOpenHelpIcon}
    />
  );
};
