import { HStack, Pressable, Stack, Text } from 'native-base';
import Icon from 'ui/components/Icon';
import { colors } from 'ui/theme/colors';

import { BackButtonProps } from '~/pages/Authenticated/SwitchEcosystem/components/BackButton/types';

export const BackButton = ({ handleGoBack }: BackButtonProps) => {
  return (
    <Stack paddingY={4}>
      <Pressable
        onPress={handleGoBack}
        paddingY={2}
        paddingX={3}
        variant="unstyled"
      >
        <HStack alignItems="center">
          <HStack space={2} alignItems="center">
            <Icon
              icon="chevron-left"
              size={16}
              color={colors.singletons.darkText}
            />
            <Text
              fontSize={{ base: 'xs' }}
              fontWeight={500}
              color={colors.singletons.darkText}
            >
              Back
            </Text>
          </HStack>
        </HStack>
      </Pressable>
    </Stack>
  );
};
