import { PartnerActivityPermissions } from 'ui/constants/permission.constants';
import { IPermission } from 'ui/types/interfaces/permission';

//----- Activities Permissions -----//
export const NewActivityButtonPermissions: Partial<IPermission>[] = [
  PartnerActivityPermissions.PublishActions,
  PartnerActivityPermissions.PublishEvents,
  PartnerActivityPermissions.PublishOngoingOpportunities,
  PartnerActivityPermissions.PublishTeamEvents,
];

export const NewActivityModalEventCardPermissions: Partial<IPermission>[] = [
  PartnerActivityPermissions.PublishEvents,
  PartnerActivityPermissions.PublishTeamEvents,
];
export const NewActivityModalActionCardPermissions: Partial<IPermission>[] = [
  PartnerActivityPermissions.PublishActions,
];
export const NewActivityModalOngoingCardPermissions: Partial<IPermission>[] = [
  PartnerActivityPermissions.PublishOngoingOpportunities,
];

export const ActivitiesPermissions = {
  NewActivityButton: NewActivityButtonPermissions,
  NewActivityModal: {
    Event: NewActivityModalEventCardPermissions,
    Action: NewActivityModalActionCardPermissions,
    Ongoing: NewActivityModalOngoingCardPermissions,
  },
};

//----- Create/Edit Activity Permissions -----//

export const CreateEditEventPermissions: Partial<IPermission>[] = [
  PartnerActivityPermissions.PublishEvents,
];

export const CreateEditTeamEventPermissions: Partial<IPermission>[] = [
  PartnerActivityPermissions.PublishTeamEvents,
];

export const CreateEditActionPermissions: Partial<IPermission>[] = [
  PartnerActivityPermissions.PublishActions,
];

export const CreateEditOngoingOpportunityPermissions: Partial<IPermission>[] = [
  PartnerActivityPermissions.PublishOngoingOpportunities,
];

export const CreateEditActivityPermissions = {
  Event: CreateEditEventPermissions,
  TeamEvent: CreateEditTeamEventPermissions,
  Action: CreateEditActionPermissions,
  Ongoing: CreateEditOngoingOpportunityPermissions,
  BothEventsPermissions: [
    ...CreateEditTeamEventPermissions,
    ...CreateEditEventPermissions,
  ],
};
