import { api } from '~/services/api';

const MODULE_URL = 'activity-report';

export default class ActivityReportService {
  static async eventCount(id: string) {
    return await api.get(`${MODULE_URL}/event-count/${id}`);
  }

  static async actionCount(id: string) {
    return await api.get(`${MODULE_URL}/action-count/${id}`);
  }

  static async ongoingCount(id: string) {
    return await api.get(`${MODULE_URL}/ongoing-count/${id}`);
  }

  static async volunteersCount(id: string) {
    return await api.get(`${MODULE_URL}/volunteer-count/${id}`);
  }

  static async volunteersCountDownload(id: string) {
    return await api.get(`${MODULE_URL}/volunteer-count/download/csv/${id}`);
  }

  static async childOrgReport(organizationId: string) {
    return await api.get(
      `${MODULE_URL}/child-org/download/csv/${organizationId}`,
    );
  }
}
