import { Stack, Text } from 'native-base';
import { forwardRef } from 'react';
import { StyleProp, ViewStyle } from 'react-native';
import { TabBar, TabView } from 'react-native-tab-view';

import useTabsController from './controller';
import { styleConfig } from './styleConfig';
import { ITabsRefProps, TabsProps } from './types';

const Tabs = forwardRef<ITabsRefProps, TabsProps>(function Tabs(props, ref) {
  const {
    routes,
    renderScene,
    tabfocusedTextColor,
    tabTextColor,
    _tabBarStyle,
    styles,
    index,
    swipeEnabled,
    isClickableTab,
    handleChangeIndex,
    alignTabBar,
    showInMobile,
  } = useTabsController(props, ref);

  return (
    <TabView
      navigationState={{ index: props.currentTab ?? index, routes }}
      renderScene={renderScene}
      onIndexChange={handleChangeIndex}
      swipeEnabled={swipeEnabled}
      initialLayout={styles.initialLayout}
      renderTabBar={(props) => (
        <Stack
          display={showInMobile ? null : styleConfig.tabBar}
          flexDirection="row"
          justifyContent={alignTabBar}
        >
          <TabBar
            {...props}
            style={_tabBarStyle}
            indicatorStyle={styles.indicatorStyle}
            onTabPress={({ preventDefault, route }) => {
              if (!isClickableTab) {
                preventDefault();
                return;
              }

              handleChangeIndex(Number(route.key));
              return;
            }}
            renderLabel={({ route, focused }) => (
              <Text
                fontSize="sm"
                fontWeight="medium"
                color={focused ? tabfocusedTextColor : tabTextColor}
              >
                {route.title}
              </Text>
            )}
          />
        </Stack>
      )}
      style={styles.tabsStyles as StyleProp<ViewStyle>}
    />
  );
});

export default Tabs;
