import { Stack } from 'native-base';

import { EditOpportunityForm } from './EditOpportunityForm';

export const EditOpportunity = () => {
  return (
    <Stack>
      <EditOpportunityForm />
    </Stack>
  );
};
