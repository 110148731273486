import { ValidationsRegex } from 'ui/utils/validations/validations';

import { organizationTypes } from '~/pages/Unauthenticated/SignUpPage/RegisterOrganisation/types';
import Yup from '~/utils/validations/yup';

export const schemaValidation = Yup.object({
  name: Yup.string().required(),
  type: Yup.string()
    .required()
    .oneOf(organizationTypes.map((item) => item.value)),
  causeOptions: Yup.array().min(1).required(),
  purpose: Yup.string().required(),
  websiteLink: Yup.string(),
  description: Yup.string(),
  currentStep: Yup.number().required(),
  adminEmail: Yup.string(),
  adminEmailList: Yup.array().of(
    Yup.object({
      email: Yup.string()
        .transform((value: string) => value?.trim() || '')
        .email(),
    }),
  ),
  domainSlug: Yup.string()
    .min(5)
    .max(32)
    .matches(
      ValidationsRegex.FullDomainSlug,
      'Only alphanumeric and hyphen characters are accepted. Cannot start or end with a hyphen. Cannot have consecutive hyphens.',
    )
    .when('currentStep', { is: 1, then: (field) => field.required() }),
  email: Yup.string()
    .email()
    .when('currentStep', { is: 1, then: (field) => field.required() }),
  phone: Yup.string().when('currentStep', {
    is: 1,
    then: (field) => field.required(),
  }),
});

export const queryFilter = {
  causeOptions: {
    filter: JSON.stringify({ relatedTo: ['causeOptions'] }),
  },
};

export const defaultValues = {
  currentStep: 0,
  adminEmailList: [],
};
