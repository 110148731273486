import { Divider, HStack, Stack, Text, VStack } from 'native-base';
import Button from 'ui/components/Button';
import DataTable from 'ui/components/DataTable';
import { TableSizeClass } from 'ui/components/DataTable/@types/basicTypes';
import Icon from 'ui/components/Icon';
import { GenericFormTabs } from 'ui/enums';

import ModalDelete from '~/components/ModalDelete';
import { Permissions } from '~/components/Permissions';
import { colors } from '~/theme/colors';

import { useLocationController } from './controller';
import { columnsByActivityType, defaultFilterValues } from './tableConfig';
import { LocationSectionProps } from './types';

export const LocationSection = ({
  activityType,
  loadActivitiesList,
  handleEditActivity,
  attendanceType,
  isOrganizationOwnerOfActivity,
  appId,
  activityPermissions,
  isAppAdmin,
  locationOption,
}: LocationSectionProps) => {
  const {
    isLoadingChangeCloseApplication,
    changeApplicationModalRef,
    handleCloseApplication,
    handleCancelCloseApplication,
    handleChangeApplicationStatus,
    refetchData,
    closeApplicationData,
    renderLocationsTableTitle,
    renderLocationsTableDescription,
  } = useLocationController({ activityType, locationOption });
  return (
    <>
      <Stack>
        <HStack justifyContent="space-between" alignItems="center" mb={2}>
          <VStack space={2}>
            <HStack alignItems="center" space={2}>
              <Icon
                icon={'map-pin'}
                color={colors.gray[900]}
                alt="map pin icon"
                size={20}
              />
              <Text color="gray.900" fontSize="2xl" fontWeight={500}>
                {renderLocationsTableTitle()}
              </Text>
            </HStack>
            <Text color="gray.900" fontSize="sm" fontWeight={400}>
              {renderLocationsTableDescription()}
            </Text>
          </VStack>
          {isOrganizationOwnerOfActivity ? (
            // <Permissions.ValidateApp
            //   appIdToValidate={appId}
            //   requiredPermissions={activityPermissions}
            //   disablePermissionsValidation={isAppAdmin}
            // >
            <Button
              w="76px"
              borderRadius={12}
              py={2}
              px={3}
              minW="auto"
              leftIconName="edit"
              alignItems="center"
              leftIconColor={colors.primary[400]}
              variant="outline"
              onPress={() => handleEditActivity(GenericFormTabs.WhenWhere)}
            >
              <Text color={colors.primary[400]}>Edit</Text>
            </Button>
          ) : // </Permissions.ValidateApp>
          null}
        </HStack>
        <Divider mb={6} />
        <DataTable
          refetchQuantity={refetchData}
          columns={columnsByActivityType(
            activityType,
            handleCloseApplication,
            attendanceType,
            locationOption,
          )}
          defaultFilterValues={defaultFilterValues}
          loadData={loadActivitiesList as any}
          defaultLimit={5}
          pageSizes={[5]}
          className={TableSizeClass.Small}
          limit={5}
        />
      </Stack>
      <ModalDelete
        modalRef={changeApplicationModalRef}
        headerText="Close applications"
        rightBtnText="Yes, close"
        handleCloseModal={handleCancelCloseApplication}
        // Keep with arrow function to not forward the events
        handleDelete={() =>
          handleChangeApplicationStatus(
            closeApplicationData.id,
            closeApplicationData.isToggled,
            closeApplicationData.endDate,
          )
        }
        rightBtnLoading={isLoadingChangeCloseApplication}
      >
        <Stack space="5">
          <Text>Are you sure that you want to close this activity?</Text>
          <Text>The activity will immediately close for new applications.</Text>
        </Stack>
      </ModalDelete>
    </>
  );
};
