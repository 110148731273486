import { NotificationCard as BaseBaseNotificationCard } from './BaseNotificationCard';
import { ActivityCreatedNotificationCard } from './OrgNotificationCards/ActivityCreated';
import { BulkActivitiesCreatedNotificationCard } from './OrgNotificationCards/BulkActivitiesCreated';
import { ActivityApplicationNotificationCard } from './UserNotificationCards/ActivityApplication';
import { ActivityApplicationCanceledNotificationCard } from './UserNotificationCards/ActivityApplicationCanceled';
import { MeasurementCreatedNotificationCard } from './UserNotificationCards/MeasurementCreated';
import { MeasurementDeletedNotificationCard } from './UserNotificationCards/MeasurementDeleted';
import { MultipleMeasurementsCreatedNotificationCard } from './UserNotificationCards/MultipleMeasurementsCreated';
import { UserProfileCreatedNotificationCard } from './UserNotificationCards/UserProfileCreated';

export const NotificationCard = {
  Base: BaseBaseNotificationCard,
  ActivityApplicationCreated: ActivityApplicationNotificationCard,
  ActivityApplicationCanceled: ActivityApplicationCanceledNotificationCard,
  MeasurementCreated: MeasurementCreatedNotificationCard,
  MeasurementDeleted: MeasurementDeletedNotificationCard,
  MultipleMeasurementsCreated: MultipleMeasurementsCreatedNotificationCard,
  UserProfileCreated: UserProfileCreatedNotificationCard,
  ActivityCreated: ActivityCreatedNotificationCard,
  BulkActivitiesCreated: BulkActivitiesCreatedNotificationCard,
};
