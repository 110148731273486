import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { ESliceNames } from '~/store/types';

import { Redirect } from './types';

const initialState: Redirect = {
  redirectURL: undefined,
};

export const RedirectSlice = createSlice({
  name: ESliceNames.REDIRECT,
  initialState,
  reducers: {
    updateURL: (state, { payload }: PayloadAction<string>) => {
      return { ...state, redirectURL: payload };
    },
    clear: () => initialState,
  },
});

export const { reducer: redirectSliceReducer, actions: redirectSliceActions } =
  RedirectSlice;
