import { Box, HStack, Pressable, Spinner, Stack, Text } from 'native-base';
import React from 'react';
import Button from 'ui/components/Button';
import Icon from 'ui/components/Icon';

import ModalDelete from '~/components/ModalDelete';

import { IAppsFormProps } from '../types';
import { AddAppModal } from './components/AddAppModal';
import { SelectedAppCard } from './components/SelectedAppCard';
import { styleConfig } from './styleConfig';
import { useAppsFormController } from './useAppsFormController';

const AppsForm = ({
  control,
  isDisabledButton,
  isSubmitting,
  isEditing = false,
  onSubmit,
  handleBack,
}: IAppsFormProps) => {
  const {
    modalRef,
    apps,
    pickedApps,
    uniqueAppsConnectedToOrganization,
    isLoading,
    publicApps,
    myAppSharings,
    removeMyAppModalRef,
    isRemovingApp,
    ecosystemId,
    currentTab,
    onChangeTab,
    handleOnChangeText,
    onChangeSwitchMyApps,
    handleOnGetApp,
    handleToggleModal,
    handleOnRemoveApp,
    handleOnDone,
    handleCloseRemoveMyAppModal,
    handleOpenRemoveMyAppModal,
    handleOnRemoveMyAppSharing,
    handleOnCloseModalPickedApps,
  } = useAppsFormController(control);

  return (
    <>
      {isLoading ? (
        <HStack height="450px" justifyContent="center">
          <Spinner size="lg" />
        </HStack>
      ) : (
        <Stack space={5} mt={8} p={2} w="100%">
          <Stack w="100%" space={2}>
            <Text fontSize="xl" color="gray.600" fontWeight={500}>
              Your apps
            </Text>
            <Text color="gray.600" fontWeight={400} fontSize="sm">
              Add apps to the ecosystem
            </Text>
          </Stack>

          <div style={styleConfig.grid}>
            <Pressable onPress={handleToggleModal}>
              <Box
                borderStyle="dashed"
                borderWidth="1"
                borderColor="gray.300"
                p="4"
                pb="12"
                rounded="2xl"
                minH="213px"
              >
                <HStack space="3">
                  <Icon icon="plus-circle" size="22" color="gray.500" />
                  <Box flex="1">
                    <Text fontSize="lg">Add app</Text>
                    <Text color="gray.600" fontSize="sm" mt="2">
                      Add app Search an app from the list and add to the
                      ecosystem to make it available to all the members.
                    </Text>
                  </Box>
                </HStack>
              </Box>
            </Pressable>

            {myAppSharings?.map((appSharing, index) => (
              <SelectedAppCard
                key={appSharing._id}
                app={appSharing.app}
                showToggle={isEditing}
                isAppSharingEnabled={appSharing.isEnabled}
                onDelete={() =>
                  handleOpenRemoveMyAppModal(index, appSharing._id)
                }
                onChangeSwitch={(value) =>
                  onChangeSwitchMyApps(appSharing._id, value)
                }
              />
            ))}
            {apps.map((app, index) => (
              <SelectedAppCard
                key={app._id}
                app={app}
                onDelete={() => handleOnRemoveApp(index)}
              />
            ))}
          </div>

          <HStack
            w="full"
            flexDirection={styleConfig.buttonStackDirection}
            alignItems="center"
            space={2}
            mt="10"
          >
            <Button
              w={styleConfig.buttonWidth}
              variant="ghost"
              onPress={handleBack}
            >
              Back
            </Button>

            <Button
              w={styleConfig.buttonWidth}
              isDisabled={isDisabledButton || isSubmitting}
              isLoading={isSubmitting}
              onPress={onSubmit}
            >
              {isEditing ? 'Save changes' : 'Create ecosystem'}
            </Button>
          </HStack>
        </Stack>
      )}

      <AddAppModal
        ref={modalRef}
        pickedApps={pickedApps}
        onGetApp={handleOnGetApp}
        onDone={handleOnDone}
        uniqueAppsConnectedToOrganization={uniqueAppsConnectedToOrganization}
        isLoading={isLoading}
        publicApps={publicApps?.data}
        ecosystemId={ecosystemId}
        onChangeText={handleOnChangeText}
        onChangeTab={onChangeTab}
        currentTab={currentTab}
        onClose={handleOnCloseModalPickedApps}
      />

      <ModalDelete
        modalRef={removeMyAppModalRef}
        headerText="Remove app"
        handleCloseModal={handleCloseRemoveMyAppModal}
        handleDelete={handleOnRemoveMyAppSharing}
        rightBtnLoading={isRemovingApp}
        rightBtnText="Remove"
      >
        <Stack space={5}>
          <Text fontWeight={400} fontSize="md" color="gray.500">
            Are you sure that you want to remove the app from this ecosystem?
          </Text>

          <Text fontWeight={400} fontSize="md" color="gray.500">
            Members will lose access to the app and therefore all of its
            features and upcoming opportunities. This action is irreversible and
            you will lose all data associated with that app.
          </Text>
        </Stack>
      </ModalDelete>
    </>
  );
};

export default React.memo(AppsForm);
