import { Avatar, Checkbox, HStack, Text } from 'native-base';
import { getAvatarText } from 'ui/utils/getAvatarText';

import { SelectAppItemProps } from './types';
import { useSelectAppItemController } from './useSelectAppItemController';

export const SelectAppItem = ({
  name,
  value,
  logo,
  disabled,
  isChecked,
  onChangeValue,
}: SelectAppItemProps) => {
  const { handleOnChange } = useSelectAppItemController({
    onChangeValue,
    disabled,
    isChecked,
    value,
  });
  return (
    <HStack space={2} alignItems="center" w="50%" my={3}>
      <Checkbox
        name={name}
        value={value}
        isDisabled={disabled}
        defaultIsChecked={isChecked}
        onChange={handleOnChange}
        isChecked={isChecked}
      >
        <Avatar
          bgColor="muted.300"
          height="32px"
          width="32px"
          _text={{ color: 'darkText' }}
          source={{ uri: logo }}
          _image={{
            borderRadius: 'sm',
          }}
        >
          {getAvatarText(name)}
        </Avatar>
        <Text color="darkText" fontWeight={500} fontSize={'xs'}>
          {name}
        </Text>
      </Checkbox>
    </HStack>
  );
};
