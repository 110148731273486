import {
  TableOperatorNames,
  TableOperatorType,
} from 'ui/components/DataTable/@types/basicTypes';

import { IApplicationsCountState } from '~/pages/Authenticated/Activities/ApplicationsList/types';

export const initialApplicationsCount: IApplicationsCountState = {
  total: 0,
  approved: 0,
  pending: 0,
  rejected: 0,
};

export const defaultApplicationsListFilters = [
  {
    name: 'applicationType',
    operator: TableOperatorNames.Equals,
    type: TableOperatorType.String,
    value: '',
  },
  {
    name: 'userProfileSubDocument.userSummary.name',
    operator: TableOperatorNames.Or,
    type: TableOperatorType.String,
    value: '',
  },
  {
    name: 'organization.name',
    operator: TableOperatorNames.Equals,
    type: TableOperatorType.String,
    value: '',
  },
  {
    name: 'applicationDate',
    operator: TableOperatorNames.AfterOrOn,
    type: TableOperatorType.Date,
    value: '',
  },
  {
    name: 'activitySubDocument.activityDefinitionSubDocument.title',
    operator: TableOperatorNames.Contains,
    type: TableOperatorType.String,
    value: '',
  },
  {
    name: 'status',
    operator: TableOperatorNames.Equals,
    type: TableOperatorType.String,
    value: '',
  },
  {
    name: 'activitySubDocument.address.street',
    operator: TableOperatorNames.Equals,
    type: TableOperatorType.String,
    value: '',
  },
  {
    name: 'activitySubDocument.startDate',
    operator: TableOperatorNames.AfterOrOn,
    type: TableOperatorType.Date,
    value: '',
  },
];
