import { HStack, Text, VStack } from 'native-base';
import Button from 'ui/components/Button';
import Icon from 'ui/components/Icon';
import Tabs from 'ui/components/Tabs';

import ModalAddActivity from '~/components/ModalAddActivity';
import Scaffold from '~/components/Scaffold';
import { ActivityTypeEnum } from '~/enums';
import { useActivitiesReportController } from '~/pages/Authenticated/Activities/useActivitiesReport.controller';
import { colors } from '~/theme/colors';

import { DownloadReportButton } from '../../../components/DownloadReportButton';
import { ModalReportProcessing } from '../../../components/ModalReportProcessing';
import { ActivitiesList } from './ActivitiesList';
import { ApplicationList } from './ApplicationsList';
import { useActivitiesController } from './controller';
import { activitiesTabElements } from './tabElements';
import { ActivitiesTabPages } from './types';

export const Activities = () => {
  const {
    tabsRef,
    modalRef,
    handleChangeTab,
    currentTab,
    handleNavigateToActivtyForm,
    handleOpenAddActivityModal,
    pendingApplications,
    setPendingApplications,
    handleImportButton,
  } = useActivitiesController();
  const { handleExportVolunteersReport, reportModalRef, openModal } =
    useActivitiesReportController();

  const renderTabs = () => {
    return {
      [ActivitiesTabPages.Applications]: (
        <ApplicationList setPendingApplications={setPendingApplications} />
      ),
      [ActivitiesTabPages.Activities]: (
        <ActivitiesList
          onViewPendingApplications={() => handleChangeTab(0)}
          pendingApplicationsCount={pendingApplications}
        />
      ),
    }[currentTab];
  };

  return (
    <>
      <Scaffold
        title="Activities"
        titleIcon="calendar"
        titleIconSize={32}
        actionLabel="New activity"
        withBg={false}
        actionComponents={
          <HStack space={4}>
            <DownloadReportButton
              title="Export org report"
              onPress={openModal}
            />
            <Button
              variant="outline"
              p={5}
              width={'fit-content'}
              onPress={handleImportButton}
            >
              <HStack space={2} alignItems={'center'}>
                <Icon
                  isCustom
                  icon="folder-arrow-up"
                  color={colors.primary[300]}
                  size={4}
                />

                <Text
                  color={colors.primary[300]}
                  fontSize="sm"
                  fontWeight={500}
                >
                  Upload activities
                </Text>
              </HStack>
            </Button>

            {/* <Permissions.ValidateAll
            requiredPermissions={ActivitiesPermissions.NewActivityButton}
            > */}
            <Button
              variant="solid"
              p={5}
              width={'fit-content'}
              leftIconName="plus-circle"
              leftIconColor={colors.white[500]}
              onPress={handleOpenAddActivityModal}
            >
              New activity
            </Button>
            {/* </Permissions.ValidateAll> */}
          </HStack>
        }
      >
        <VStack w={'full'} width="100%" alignItems="center" pb={10}>
          <Tabs
            ref={tabsRef}
            tabBgColor="transparent"
            onChangeTab={handleChangeTab}
            tabWith="100%"
            tabTextColor={colors.primary['600']}
            tabIndicatorColor={colors.primary['600']}
            tabfocusedTextColor={colors.primary['600']}
            isClickableTab
            swipeEnabled={false}
            elements={activitiesTabElements}
            currentTab={currentTab}
          />
        </VStack>
        {renderTabs()}
        <ModalAddActivity
          ref={modalRef}
          onAddEvent={() => handleNavigateToActivtyForm(ActivityTypeEnum.Event)}
          onAddAction={() =>
            handleNavigateToActivtyForm(ActivityTypeEnum.Action)
          }
          onAddOngoingOpportunity={() =>
            handleNavigateToActivtyForm(ActivityTypeEnum.OngoingOpportunity)
          }
        />
        <ModalReportProcessing
          onExportPress={handleExportVolunteersReport}
          modalRef={reportModalRef}
        />
      </Scaffold>
    </>
  );
};
