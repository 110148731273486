import chroma from 'chroma-js';

const getColorFromScale = (index: number, palette: string[]) => {
  const colorScale = chroma.scale(palette).mode('lab');
  return colorScale(index / (palette.length - 1)).hex();
};

export function generateRandomColors(count: number, palette: string[]) {
  const colors = [];
  for (let i = 0; i < count; i++) {
    colors.push(getColorFromScale(i, palette));
  }
  return colors;
}
