import { api } from '~/services/api';

const MODULE_URL = 'measurement-report';

export default class MeasurementReportService {
  static async membersLogHoursDownloadReport(ecosystemId: string) {
    return await api.get(
      `${MODULE_URL}/members-log-hours/download/csv/${ecosystemId}`,
    );
  }
}
