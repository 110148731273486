import { Stack } from 'native-base';

import { EditEventForm } from './EditEventForm';

export const EditEvent = () => {
  return (
    <Stack>
      <EditEventForm />
    </Stack>
  );
};
