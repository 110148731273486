import { Avatar, HStack, Stack, Text } from 'native-base';
import { getNameInitials } from 'ui/utils/getNameInititals';

import { useAppDispatch } from '~/hooks/useAppDispatch';
import { useAppSelector } from '~/hooks/useAppSelector';
import { notificationSliceActions } from '~/store/slices/notification';
import { IEcosystem } from '~/types/interfaces/ecosystem';

export const useSwitchNotifyPersona = () => {
  const dispatch = useAppDispatch();

  const { user, organizations } = useAppSelector((state) => state.auth);

  const handleEcosystemSwitch = (ecosystem: IEcosystem | undefined) => {
    const organizationName =
      organizations?.find((org) => org._id === ecosystem?.organization)?.name ||
      '';

    dispatch(
      notificationSliceActions.notify({
        content: (
          <Stack maxW={300} width="100%">
            <Text fontSize="sm" color="gray.500" fontWeight={500}>
              Hi, {user?.name || ''}! Welcome back to
            </Text>
            <HStack space={3} width="100%">
              <Avatar source={{ uri: ecosystem?.logo }} zIndex={1}>
                {getNameInitials(ecosystem?.name as string)}
              </Avatar>
              <Stack width="100%" space={1}>
                <Text
                  numberOfLines={1}
                  fontSize="md"
                  fontWeight={500}
                  color="darkText"
                >
                  {ecosystem?.name}
                </Text>
                <Text numberOfLines={1} fontWeight={500} color="gray.500">
                  By {organizationName}
                </Text>
              </Stack>
            </HStack>
          </Stack>
        ),
      }),
    );
  };

  return { handleEcosystemSwitch };
};
