import { Box, Divider, HStack, Stack } from 'native-base';
import React from 'react';
import { Outlet } from 'react-router-dom';

import Scaffold from '~/components/Scaffold';
import { SettingsSideBar } from '~/pages/Authenticated/Settings/components/SettingsSidebar';
import { colors } from '~/theme/colors';

import { useSettingsController } from './useSettingsController';

const Settings: React.FC = () => {
  const { sideBarList } = useSettingsController();

  return (
    <Scaffold
      title="Organisation Settings"
      titleIcon="settings"
      titleIconSize={32}
      withBg={false}
    >
      <Stack
        width="100%"
        bgColor="white"
        padding={6}
        borderRadius="3xl"
        space={3}
        minH="100vh"
      >
        <HStack w="full" bg="white" space={10}>
          <SettingsSideBar sideBarList={sideBarList} />
          <Divider
            orientation="vertical"
            width="1px"
            color={colors.gray[200]}
          />
          <Box flex="1">
            <Outlet />
          </Box>
        </HStack>
      </Stack>
    </Scaffold>
  );
};

export default Settings;
