import { IMenuItem } from 'ui/components/DropdownMenu';

import { InsightsPeriodType } from '~/services/resources/types';

export const periodTypeToDateFilterType = {
  [InsightsPeriodType.Today]: 'Today',
  [InsightsPeriodType.ThisWeek]: 'This week',
  [InsightsPeriodType.ThisMonth]: 'This month',
  [InsightsPeriodType.Last7Days]: 'Last 7 days',
  [InsightsPeriodType.Last30Days]: 'Last 30 days',
  [InsightsPeriodType.Last3Months]: 'Last 3 months',
  [InsightsPeriodType.Last6Months]: 'Last 6 months',
  [InsightsPeriodType.Last12Months]: 'Last 12 months',
  [InsightsPeriodType.Custom]: 'Custom',
};

export const periodDropdownOptions: IMenuItem[] = [
  ...Object.keys(periodTypeToDateFilterType).map((key) => ({
    value: key,
    label: periodTypeToDateFilterType[key as InsightsPeriodType],
  })),
];
