import { yupResolver } from '@hookform/resolvers/yup';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useFieldArray, useForm } from 'react-hook-form';
import { useFormImperativeHandle } from 'ui/components/FormBuilderV2/hooks/useFormImperativeHandle';

import { useActivityFormCommonController } from '~/components/ActivityFormBuilderPresets/Controllers/useActivityFormCommonController';
import { MeasurementUnitsCategory } from '~/enums';
import { useGetMeasurementUnits } from '~/hooks/useGetMeasurementUnits';
import { ICreateActivityDTO } from '~/types/dtos';
import { getMeasurementUnit } from '~/utils/functions';

import { AboutFormProps, AppsForSelector } from './types';
import {
  activityMetricsOptions,
  createFormSchema,
  schemaValidation,
} from './utils';

export const useAboutFormController = ({
  handleContinue,
  defaultValues,
  causeOptions,
  isLoading,
  connectedApps,
  onAddedCustomUnit,
  handleBack,
}: AboutFormProps) => {
  const [appsForSelector, setAppsForSelector] = useState<AppsForSelector[]>([]);

  const {
    control,
    handleSubmit,
    watch,
    formState: {
      errors,
      //TODO: Fix isDirty being false with defaultValues
      isDirty,
      isValid,
    },
    setValue,
    getValues,
  } = useForm<ICreateActivityDTO>({
    resolver: yupResolver(schemaValidation),
    defaultValues,
    mode: 'all',
    reValidateMode: 'onBlur',
  });

  const commonController = useActivityFormCommonController({});

  /* 
    Custom imperative handler since Action requires custom control 
    beyond what is provided by createFormSchema 
  */

  useFormImperativeHandle({
    ref: commonController.formBuilderImperativeRef,
    getValues,
    setValue,
  });

  const formSchema = useMemo(() => {
    return createFormSchema({
      causeOptions,
      customControl: control,
      customValidation: schemaValidation,
      connectedApps: appsForSelector,
      commonController,
      handleBack,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [causeOptions, control, appsForSelector, handleBack, commonController]);

  const { fields, append, remove } = useFieldArray<ICreateActivityDTO>({
    control,
    name: 'steps',
  });

  const { measurementUnits } = useGetMeasurementUnits();

  const clearMeasurementUnit = () => {
    setValue('measurementUnit', undefined);
  };

  const onChangeMeasurementUnit = (selectOption: {
    label: string;
    value: string;
  }) => {
    const measurementUnit = getMeasurementUnit(
      measurementUnits,
      selectOption.value,
    );
    if (measurementUnit?.category === MeasurementUnitsCategory.Completion) {
      addOneStep();
    } else {
      remove();
    }
    setValue('targetAmount', '');
  };

  const addOneStep = useCallback(() => {
    append({
      title: '',
      description: '',
    });
  }, [append]);

  useEffect(() => {
    if (connectedApps && connectedApps?.length > 0) {
      const mappedApps = connectedApps.map((conenctedApp) => ({
        name: conenctedApp.name,
        value: conenctedApp._id,
        logo: conenctedApp.logo,
      }));

      setAppsForSelector(mappedApps);
    }
  }, [connectedApps]);

  const isDisabled = useMemo(() => !isValid, [isValid]);

  return {
    //---FormBuilder--//
    causeOptions,
    formSchema,
    defaultValues,
    handleContinue,
    isLoading,
    //---Activity Metrics---//
    activityMetricsOptions,
    measurementUnits,
    control,
    watch,
    errors,
    clearMeasurementUnit,
    onChangeMeasurementUnit,
    addOneStep,
    fields,
    handleSubmit,
    isDisabled,
    appsForSelector,
    onAddedCustomUnit,
    handleBack,
  };
};
