import { Box, Divider, HStack, Stack, Text } from 'native-base';
import { Controller } from 'react-hook-form';
import Input from 'ui/components/Input';
import { Select } from 'ui/components/Select';
import Switch from 'ui/components/Switch';
import TextArea from 'ui/components/TextArea';
import { ImagePicker } from 'ui/components/v2/ImageInputs/ImagePicker';
import LocationAutoComplete from 'ui/components/v2/LocationInputs/LocationAutoComplete';
import { ILocation } from 'ui/types/interfaces';
import { Organization } from 'ui/types/interfaces/organization';

import { CompanyDetailsSection } from '~/pages/Authenticated/Settings/General/components/Sections/CompanyDetails';
import { GooglePlacesAutoCompleteAdapter } from '~/services/google/google-places-auto-auto-complete';
import { colors } from '~/theme/colors';

import { EditGeneralSettingsProps } from './types';
const googlePlacesAutoComplete = GooglePlacesAutoCompleteAdapter.getSingleton();

export const EditGeneralSettings = ({
  control,
  errors,
  causeOptions,
  organization,
  onRefetch,
}: EditGeneralSettingsProps) => {
  return (
    <HStack space={10}>
      <Box>
        <Controller
          name="logo"
          control={control}
          render={({ field: { onChange, value } }) => {
            return (
              <ImagePicker.Base
                src={value as string}
                circularCrop
                aspectRatio={1}
                onChange={onChange}
                cropModalTitle="Adjust your logo Image"
              />
            );
          }}
        />
      </Box>

      <Stack width="65%" space={6}>
        <Input
          control={control}
          name="name"
          label="Organisation*"
          errorMessage={errors?.name?.message}
        />
        <Select
          control={control}
          name="causeOptions"
          label="Causes*"
          options={causeOptions}
          errorMessage={errors?.causeOptions?.message}
          isMulti
        />
        <TextArea
          name="purpose"
          control={control}
          label="Purpose*"
          errorMessage={errors?.purpose?.message}
        />
        <Input
          name="contactPhoneNumber"
          isPhoneInput
          control={control}
          label="Contact phone number*"
          errorMessage={errors?.contactPhoneNumber?.message}
        />
        <Input
          control={control}
          name="contactEmail"
          label="Contact email address*"
          leftIconName="mail"
          leftIconSize={16}
          leftIconColor={colors.muted[400]}
          errorMessage={errors?.contactEmail?.message}
        />
        <Stack flex={1} zIndex={9999}>
          <Controller
            name="fullAddress"
            control={control}
            render={({ field: { onChange, value } }) => (
              <LocationAutoComplete
                isRequired
                label="Full address"
                onChange={onChange}
                defaultValue={value as ILocation}
                errorMessage={errors?.fullAddress?.message}
                provider={googlePlacesAutoComplete}
              />
            )}
          />
        </Stack>
        <TextArea name="description" control={control} label="Description" />
        <HStack justifyContent="space-between">
          <Stack width="48%">
            <Input control={control} name="websiteLink" label="Website link" />
          </Stack>
          <Stack width="48%">
            <Input
              control={control}
              name="termsOfServicesLink"
              label="Terms of services page link"
            />
          </Stack>
        </HStack>

        <CompanyDetailsSection
          control={control}
          organization={organization as Organization}
          onRefetch={onRefetch}
          editMode={true}
        />

        <Stack width="100%" mt={2} space={4}>
          <Stack space={2}>
            <Text fontSize="lg" fontWeight="500" color="gray.600">
              Settings
            </Text>
            <Divider width="100%" />
          </Stack>
          <HStack space={2}>
            <Text fontWeight={500} color="gray.500">
              External ID
            </Text>
            <Switch control={control} name="hasExternalId" />
          </HStack>
        </Stack>
      </Stack>
    </HStack>
  );
};
