import { Auth } from 'aws-amplify';
import { useCallback, useRef, useState } from 'react';
import { toast } from 'react-toastify';
import { IModalRefProps } from 'ui/components/Modals/Modal/types';

import { ACCOUNT_MESSAGES } from '~/constants/messages.constants';
import { PAGES } from '~/constants/pages.constants';
import { useAppSelector } from '~/hooks/useAppSelector';
import { useRouter } from '~/hooks/useRouter';
import { OrganizationService } from '~/services/resources/organization';
import UserService from '~/services/resources/user';

export const useAccountSettingsController = () => {
  const { organizationSelectedId, user, _id } = useAppSelector(
    ({ auth }) => auth,
  );

  const modalTermsOfServiceRef = useRef<IModalRefProps>(null);
  const modalPrivacyPolicyRef = useRef<IModalRefProps>(null);
  const modalDeleteAccountRef = useRef<IModalRefProps>(null);

  const [isDeletingAccount, setIsDeletingAccount] = useState(false);
  const { goToRoute } = useRouter();

  const handleConfirmDeleteAccount = async () => {
    if (!organizationSelectedId) return;

    try {
      setIsDeletingAccount(true);
      if (!_id) throw new Error('User id not found');
      await OrganizationService.deleteAdmin(organizationSelectedId, _id);
      await UserService.deleteById(_id);
      await Auth.deleteUser();
      toast.success(ACCOUNT_MESSAGES.DELETED_SUCCESSFULLY);
      goToRoute(PAGES.ACCOUNT_DELETED);
    } catch (err) {
      toast.error(ACCOUNT_MESSAGES.ERROR_DELETE_ACCOUNT);
    } finally {
      setIsDeletingAccount(false);
    }
  };

  const handleOpenDeleteAccount = useCallback(() => {
    modalDeleteAccountRef.current?.open();
  }, []);

  const handleCloseDeleteAccount = () => {
    modalDeleteAccountRef.current?.close();
  };

  const handleChangePassword = () => goToRoute(PAGES.CHANGE_PASSWORD);

  const handleOpenTermsOfService = () => {
    modalTermsOfServiceRef.current?.open();
  };

  const handleOpenPrivacyPolicy = () => {
    modalPrivacyPolicyRef.current?.open();
  };

  return {
    user,
    isDeletingAccount,
    modalTermsOfServiceRef,
    modalPrivacyPolicyRef,
    modalDeleteAccountRef,
    handleOpenTermsOfService,
    handleOpenPrivacyPolicy,
    handleOpenDeleteAccount,
    handleCloseDeleteAccount,
    handleChangePassword,
    handleConfirmDeleteAccount,
  };
};
