import DataTableActions from 'ui/components/DataTableActions';
import { TableCellWithAvatar } from 'ui/components/TableCellWithAvatar';
import { TableCellWithImage } from 'ui/components/TableCellWithImage';

import { colors } from '~/theme/colors';
import { OrganizationSettings } from '~/types/interfaces/organization';

import { TableColumnsFunctions } from '../../../../../types';
import { EcosystemMembersTable } from '../../../../../types/interfaces/table';

export const tableColumns = (
  { onDelete, onView, onEdit }: TableColumnsFunctions<string>,
  organizationSettings?: OrganizationSettings,
) => {
  const organizationHasExternalId = !!organizationSettings?.hasExternalId;
  const columns = [
    {
      filterable: true,
      name: 'userSummary.name',
      header: 'Name',
      defaultFlex: 1,
      render: ({ data }: EcosystemMembersTable) => (
        <TableCellWithAvatar
          name={data.user?.name || 'Unnamed'}
          avatar={data.user?.profileImage}
        />
      ),
    },
    {
      filterable: true,
      name: 'userSummary.email',
      header: 'Email',
      defaultFlex: 1,
      render: ({ data }: EcosystemMembersTable) => data.user?.email,
    },
    {
      filterable: true,
      name: 'organizationSubDocument.name',
      header: 'Organisation / Corporation',
      defaultFlex: 1,
      render: ({ data }: EcosystemMembersTable) => (
        <TableCellWithAvatar
          name={data.organization?.name || '-'}
          avatar={data.organization?.logo}
          isOrganization={true}
        />
      ),
    },
    {
      name: 'actions',
      header: 'Actions',
      defaultFlex: 0.5,
      render: ({ data }: EcosystemMembersTable) => {
        const popoverOptions = [
          {
            icon: {
              color: colors.gray[600],
              icon: 'eye',
              size: 22,
            },
            title: 'View member',
            action: () =>
              typeof onView === 'function' ? onView(data._id) : void 0,
          },
          {
            icon: {
              color: colors.error[600],
              icon: 'x-circle',
              size: 22,
            },
            title: 'Remove member',
            action: () => onDelete(data.user?._id),
          },
        ];
        if (organizationHasExternalId) {
          popoverOptions.splice(1, 0, {
            icon: {
              color: colors.gray[600],
              icon: 'edit',
              size: 22,
            },
            title: 'Edit external ID',
            action: () => onEdit && onEdit(data._id),
          });
        }

        return (
          <DataTableActions
            popoverActions
            popoverTextColor={colors.primary[400]}
            popoverIconColor={colors.primary[400]}
            popoverOptions={popoverOptions}
          />
        );
      },
    },
  ];

  if (organizationHasExternalId) {
    columns.splice(3, 0, {
      filterable: true,
      name: 'externalId',
      header: 'External ID',
      defaultFlex: 1,
      render: ({ data }: EcosystemMembersTable) => data.externalId || '-',
    });
  }

  return columns;
};
