import { useMemo } from 'react';

import { useQuery } from '~/hooks/useQuery';
import { AppsQuery } from '~/types/interfaces/app';
import { PopulatedNetworkAppSharing } from '~/types/interfaces/networkAppSharing';

export const useLoadAppsConnectedToEcosystem = (
  organizationId?: string,
  ecosystemId?: string,
  searchText?: string,
) => {
  const connectedAppsQuery = useMemo(() => {
    const params: Partial<AppsQuery> = {};

    if (searchText) {
      params.name = { $regex: searchText, $options: 'i' };
    }

    return params;
  }, [searchText]);

  const {
    data: connectedApps,
    isLoading: isLoadingConnectedApps,
    isRefetching: isRefetchingConnectedApps,
  } = useQuery<PopulatedNetworkAppSharing[]>(
    `network-app-sharing/ecosystem/${ecosystemId}`,
    {
      requestOptions: {
        params: {
          filter: JSON.stringify(connectedAppsQuery),
        },
      },
      queryOptions: {
        enabled: !!organizationId && !!ecosystemId,
      },
      key: `connected-apps-${organizationId}-${ecosystemId}-${searchText}`,
    },
  );

  return { connectedApps, isLoadingConnectedApps, isRefetchingConnectedApps };
};
