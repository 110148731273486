import { useRef } from 'react';
import { IModalRefProps } from 'ui/components/Modals/Modal/types';

import { useAppSelector } from '~/hooks/useAppSelector';
import { useQuery } from '~/hooks/useQuery';
import { useRouter } from '~/hooks/useRouter';
import { IOrganization } from '~/types/interfaces/organization';

import { useLoadAppsSharedToOrganization } from '../../../../hooks/useLoadAppsSharedToOrganization';
import { App } from '../../../../types/interfaces/app';

export const useAppsController = () => {
  const modalRef = useRef<IModalRefProps>(null);

  const { goToRoute } = useRouter();
  const { organizationSelectedId } = useAppSelector(({ auth }) => auth);

  const { data: organization } = useQuery<IOrganization>(
    `/organization/${organizationSelectedId}`,
  );

  const { data: apps, isLoading: isLoadingApps } = useQuery<App[]>(
    `/organization/${organizationSelectedId}/apps`,
  );

  const { appsConnectedToOrganization, isLoadingAppsConnectedToOrganization } =
    useLoadAppsSharedToOrganization(organizationSelectedId);

  const handleNewApp = () => {
    //TODO:
    // goToRoute(PAGES.CREATE_ECOSYSTEM);
  };

  const handleGoToAppDetails = (appId: string) => {
    goToRoute(`/settings/apps/${appId}`);
  };

  return {
    apps,
    modalRef,
    organization,
    isLoadingApps,
    handleNewApp,
    handleGoToAppDetails,
    appsConnectedToOrganization,
    isLoadingAppsConnectedToOrganization,
  };
};
