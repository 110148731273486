import { useCallback, useRef } from 'react';
import { ITabsRefProps } from 'ui/components/Tabs/types';

import { App } from '~/types/interfaces/app';
import { OrganizationConnectedNetworkAppSharing } from '~/types/interfaces/networkAppSharing';

export const useAppsListController = (ecosystemId?: string) => {
  const tabRef = useRef<ITabsRefProps>(null);

  const isPickedApp = useCallback(
    (
      appId: string,
      pickedApps: App[],
      uniqueAppsConnectedToOrganization?: OrganizationConnectedNetworkAppSharing[],
    ) => {
      let isPicked = false;
      if (pickedApps.length > 0) {
        isPicked = !!pickedApps.find((item) => item._id === appId);
      }

      let isConnectedToEcosystem = false;
      if (
        uniqueAppsConnectedToOrganization &&
        uniqueAppsConnectedToOrganization.length > 0 &&
        ecosystemId
      ) {
        isConnectedToEcosystem = !!uniqueAppsConnectedToOrganization.find(
          (appConnected) =>
            appConnected.app._id === appId &&
            ecosystemId === appConnected.receiverEcosystem._id,
        );
      }
      return isPicked || isConnectedToEcosystem;
    },
    [ecosystemId],
  );

  return {
    isPickedApp,
    tabRef,
  };
};
