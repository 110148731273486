import {
  Alert,
  Divider,
  HStack,
  Spacer,
  Spinner,
  Stack,
  Text,
  VStack,
} from 'native-base';
import React from 'react';
import Button from 'ui/components/Button';
import Icon from 'ui/components/Icon';
import Input from 'ui/components/Input';
import Modal from 'ui/components/Modals/Modal';

import { colors } from '~/theme/colors';

import { useIntegrationsController } from './controller';

const Integrations = () => {
  const {
    apiKey,
    control,
    errors,
    modalRef,
    isModalReady,
    isWebhookEditing,
    isSubmitting,
    organizationApiKeyIsLoading,
    organizationIsLoading,
    hasOrganizationWebhook,
    handleGetWebhookButtonProps,
    handlePressEditWebhook,
    closeModal,
    handleCopyApiKey,
    handleCopyWebhookEndpoint,
    setModalOpen,
  } = useIntegrationsController();

  if (organizationApiKeyIsLoading || organizationIsLoading) {
    return (
      <Stack height="100%" alignItems="center" justifyContent="center">
        <Spinner />
      </Stack>
    );
  }

  const {
    webhookButtonLeftIconColor,
    webhookButtonLeftIconName,
    webhookButtonText,
    webhookButtonTextColor,
    webhookButtonVariant,
    webhookButtonBgColor,
  } = handleGetWebhookButtonProps();

  return (
    <Stack space={5} mt={4} maxW="650px" width="100%">
      <Stack space="2">
        <Text fontSize="xl" color="gray.600" fontWeight={500}>
          Integrations
        </Text>
        <Text fontWeight={400} fontSize="sm" color="gray.600" mb="8">
          View and manage your API key and Webhook
        </Text>
        <Alert
          borderLeftWidth="6px"
          borderLeftColor="info.600"
          status="info"
          borderRadius="lg"
          mb={10}
        >
          <VStack space={2} flexShrink={1} w="100%">
            <HStack flexShrink={1} space={2} justifyContent="space-between">
              <HStack space={2} flexShrink={1} alignItems="center">
                <Icon icon="life-buoy" color={colors.info[600]} size="24px" />
                <Text fontSize={14} fontWeight={500}>
                  Need help with integrations?
                </Text>
              </HStack>
            </HStack>
            <Text marginLeft={8} fontSize="sm" fontWeight={400}>
              <a
                href="https://documenter.getpostman.com/view/7931814/2s93CNNth6"
                target="_blank"
                rel="noopener noreferrer"
                style={{ color: colors.gray[800] }}
              >
                Click here and check out our API and Webhook documentation.
              </a>
            </Text>
          </VStack>
        </Alert>
      </Stack>
      <Stack space="2" mb={10}>
        <Text fontSize={16} color="gray.600" fontWeight={500}>
          API Key
        </Text>
        <Divider />
        <Text fontSize="sm" color="gray.600" mt={4} mb={8}>
          You can check below the last digits of your API key. If your key has
          been compromised, or you need to generate a new one, click the button
          below.
        </Text>

        <Spacer />
        <Text fontSize={14} color="gray.600" fontWeight={500}>
          Your API key
        </Text>
        <HStack alignItems="center" justifyContent="space-between" space={4}>
          <HStack flexGrow={1} space={2}>
            <Icon color={colors.muted[400]} size={18} icon="key" />
            <Text fontSize="sm" color="darkText" fontWeight={400}>
              {apiKey || 'No api key generated'}
            </Text>
          </HStack>
          <HStack flexShrink={1}>
            <Button
              onPress={setModalOpen}
              variant="solid"
              w="fit-content"
              height="40px"
              bgColor="primary.50"
              leftIconName="rotate-cw"
              leftIconColor="primary.600"
            >
              <HStack alignItems="center">
                <Text fontSize="sm" color="primary.600" ml={2} fontWeight={500}>
                  Generate new key
                </Text>
              </HStack>
            </Button>
          </HStack>
        </HStack>
      </Stack>
      <Stack space="2">
        <Text fontSize={16} color="gray.600" fontWeight={500}>
          Webhook configuration
        </Text>
        <Divider />
        <Text fontSize="sm" color="gray.600" mt={4}>
          You can add, check and alter your own back-end endpoint address on the
          input below. We&apos;ll use it to push notifications regarding your
          volunteers and activities in real time.
        </Text>
        <Spacer />
        <Text mt={10} fontSize={14} color="gray.600" fontWeight={500}>
          Your Webhook endpoint
        </Text>
        <HStack alignItems="center" justifyContent="space-between" space={4}>
          <HStack flexGrow={1}>
            <Input
              leftIconName="globe"
              leftIconColor={colors.muted[400]}
              name="webhookEndpoint"
              control={control}
              placeholder="Type your webhook endpoint url"
              errorMessage={errors?.webhookEndpoint?.message}
              h="container"
              isFullWidth
              isReadOnly={hasOrganizationWebhook && !isWebhookEditing}
            />
          </HStack>
          <HStack flexShrink={1} space={2}>
            <Button
              onPress={handleCopyWebhookEndpoint}
              variant="solid"
              maxW="88px"
              minW="88px"
              height="40px"
              bgColor="primary.50"
            >
              <HStack alignItems="center">
                <Icon color={colors.primary[600]} size={13} icon="copy" />
                <Text fontSize="sm" color="primary.600" ml={2} fontWeight={500}>
                  Copy
                </Text>
              </HStack>
            </Button>
            <Button
              isLoading={isSubmitting}
              onPress={handlePressEditWebhook}
              variant={webhookButtonVariant}
              maxW="88px"
              minW="88px"
              height="40px"
              bgColor={webhookButtonBgColor}
            >
              <HStack alignItems="center">
                <Icon
                  color={webhookButtonLeftIconColor}
                  size={13}
                  icon={webhookButtonLeftIconName}
                />
                <Text
                  fontSize="sm"
                  color={webhookButtonTextColor}
                  ml={2}
                  fontWeight={500}
                >
                  {webhookButtonText}
                </Text>
              </HStack>
            </Button>
          </HStack>
        </HStack>
      </Stack>
      <Modal ref={modalRef} headerText="New API key" size="xl">
        {!isModalReady ? (
          <Spinner />
        ) : (
          <VStack space={4}>
            <Alert
              borderLeftWidth="6px"
              borderLeftColor="warning.600"
              status="warning"
              borderRadius="lg"
              mb={10}
            >
              <VStack space={2} flexShrink={1} w="100%">
                <HStack flexShrink={1} space={2} justifyContent="space-between">
                  <HStack space={2} flexShrink={1} alignItems="center">
                    <Icon
                      icon="alert-triangle"
                      color={colors.warning[600]}
                      size="24px"
                    />
                    <Text fontSize={14} fontWeight={500}>
                      This key will only be shown once
                    </Text>
                  </HStack>
                </HStack>
                <Text marginLeft={8} fontSize="sm" fontWeight={400}>
                  Be sure to keep it in a safe place to avoid security issues.
                </Text>
              </VStack>
            </Alert>
            <HStack
              space={2}
              alignItems="center"
              justifyContent="space-between"
            >
              <Stack space={2}>
                <Text fontSize="16" color={colors.coolGray[600]}>
                  Your API key
                </Text>
                <HStack space={2} alignItems="center">
                  <Icon color={colors.muted[400]} size={18} icon="key" />{' '}
                  <Text fontSize="sm">{apiKey}</Text>
                </HStack>
              </Stack>

              <Button
                onPress={handleCopyApiKey}
                variant="solid"
                w="fit-content"
                height="40px"
                bgColor="primary.50"
              >
                <HStack alignItems="center">
                  <Icon color={colors.primary[600]} size={13} icon="copy" />
                  <Text
                    fontSize="sm"
                    color="primary.600"
                    ml={2}
                    fontWeight={500}
                  >
                    Copy API key
                  </Text>
                </HStack>
              </Button>
            </HStack>
            <Button onPress={closeModal} mt={4} bgColor="primary.400">
              <Text fontWeight={500} color="white">
                Done
              </Text>
            </Button>
          </VStack>
        )}
      </Modal>
    </Stack>
  );
};

export default React.memo(Integrations);
