import { useCallback, useRef, useState } from 'react';
import { toast } from 'react-toastify';
import { ISortInfo } from 'ui/components/DataTable/@types/filterTypes';
import { IFilterValue } from 'ui/components/DataTable/types';
import { IModalRefProps } from 'ui/components/Modals/Modal/types';

import { PAGES } from '~/constants/pages.constants';
import { useRouter } from '~/hooks/useRouter';

import { ORGANIZATION_MESSAGES } from '../../../../../constants/messages.constants';
import { useAppSelector } from '../../../../../hooks/useAppSelector';
import { EcosystemService } from '../../../../../services/resources/ecosystem';
import MeasurementReportService from '../../../../../services/resources/measurementReport';

export const useMembersListController = () => {
  const { goToRoute } = useRouter();
  const { selectedEcosystem } = useAppSelector(({ ecosystem }) => ecosystem);
  const [membersCount, setMembersCount] = useState(0);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const modalRef = useRef<IModalRefProps>(null);
  const modalExternalIdRef = useRef<IModalRefProps>(null);
  const [refetchQuantity, setRefetchQuantity] = useState(0);

  const [selectedUserToRemove, setSelectedUserToRemove] = useState<
    string | null
  >(null);
  const [memberId, setMemberId] = useState<string>('');

  const reportModalRef = useRef<IModalRefProps>(null);
  const handleOpenReportModal = () => {
    reportModalRef.current?.open();
  };

  const handleExportMembersLogHoursReport = async () => {
    if (!selectedEcosystem) return;
    await MeasurementReportService.membersLogHoursDownloadReport(
      selectedEcosystem?._id,
    );
  };

  const handleGoToMember = (memberId: string) => {
    goToRoute(`${PAGES.MEMBERS}/${memberId}`);
  };

  const loadMembers = useCallback(
    async (skip: number, limit: number, filter: IFilterValue | null) => {
      try {
        if (!selectedEcosystem) return { data: [], count: 0 };
        const {
          data: { data, count },
        } = await EcosystemService.findMembers(selectedEcosystem._id, {
          skip,
          limit,
          filter: JSON.stringify({ ...filter }),
        });

        setMembersCount(count);
        return { data, count };
      } catch (error) {
        toast.error(ORGANIZATION_MESSAGES.ERROR_ON_LOAD_PARTNERS);
        return { data: [], count: 0 };
      }
    },
    [selectedEcosystem],
  );

  const refetchMembers = () => {
    setRefetchQuantity((prev) => prev + 1);
  };

  const handleViewMember = (memberId: string) => {
    goToRoute(`${PAGES.MEMBERS}/${memberId}`);
  };

  const handleOpenModalRemoveMember = (userId: string) => {
    modalRef.current?.open();
    setSelectedUserToRemove(userId);
  };

  const handleOpenModalEditExternalId = (memberId: string) => {
    modalExternalIdRef.current?.open();
    setMemberId(memberId);
  };

  const handleCloseModal = () => {
    modalRef.current?.close();
  };

  const handleRemoveMember = async () => {
    try {
      if (!selectedUserToRemove || !selectedEcosystem) return;
      setIsSubmitting(true);
      await EcosystemService.disconnectMember({
        ecosystemId: selectedEcosystem._id,
        userId: selectedUserToRemove,
      });
      toast.success(ORGANIZATION_MESSAGES.REMOVE_MEMBER_SUCCESS);
      handleCloseModal();
      setRefetchQuantity(refetchQuantity + 1);
    } catch (error) {
      toast.error(ORGANIZATION_MESSAGES.ERROR_ON_REMOVE_MEMBER);
    } finally {
      setIsSubmitting(false);
    }
  };

  return {
    memberId,
    membersCount,
    loadMembers,
    handleGoToMember,
    handleViewMember,
    handleRemoveMember,
    handleOpenModalRemoveMember,
    handleCloseModal,
    handleOpenModalEditExternalId,
    handleExportMembersLogHoursReport,
    handleOpenReportModal,
    reportModalRef,
    modalRef,
    modalExternalIdRef,
    isSubmitting,
    refetchQuantity,
    refetchMembers,
  };
};
