import { yupResolver } from '@hookform/resolvers/yup';
import { useEffect, useRef } from 'react';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { IModalRefProps } from 'ui/components/Modals/Modal/types';
import { ITabsRefProps } from 'ui/components/Tabs/types';
import { IEcosystem } from 'ui/types/interfaces/ecosystem';

import { PAGES } from '~/constants/pages.constants';
import { EcosystemVisibility } from '~/enums';
import { useQuery } from '~/hooks/useQuery';
import { useRouter } from '~/hooks/useRouter';

import { defaultValues, schemaValidation } from './constants';
import { ICreateEcosystemForm } from './types';

export const useEcosystemFormController = (
  onSubmit: (values: ICreateEcosystemForm) => void,
  ecosystemIdToEdit?: string,
) => {
  const tabsRef = useRef<ITabsRefProps>(null);
  const modalRef = useRef<IModalRefProps>(null);
  const { replaceRoute, goBack } = useRouter();

  const { data: ecosystem, isLoading: isLoadingEcosystem } =
    useQuery<IEcosystem>(`/ecosystem/${ecosystemIdToEdit}`, {
      queryOptions: { enabled: !!ecosystemIdToEdit },
    });

  const {
    control,
    formState: { isSubmitting, isValid, errors },
    reset,
    getValues,
    watch,
    setValue,
    handleSubmit,
  } = useForm<ICreateEcosystemForm>({
    mode: 'onBlur',
    defaultValues,
    resolver: yupResolver(schemaValidation),
  });
  const currentTab = watch('currentStep');
  const isPrivate = watch('isPrivate');

  const handleContinue = () => {
    const totalSteps = ecosystemIdToEdit ? 1 : 2;
    const currentStep = getValues('currentStep');
    if (currentStep < totalSteps) {
      setValue('currentStep', currentStep + 1, { shouldValidate: true });
      tabsRef.current?.nextStep();
    }
  };

  const handleBack = () => {
    const currentStep = getValues('currentStep');
    if (currentStep >= 1) {
      setValue('currentStep', currentStep - 1, { shouldValidate: true });
      tabsRef.current?.backStep();
      return;
    }
    goBack();
  };

  useEffect(() => {
    if (!ecosystemIdToEdit || isLoadingEcosystem || !ecosystem) return;
    reset({
      ...ecosystem,
      isPrivate: ecosystem.visibility === EcosystemVisibility.Private,
      logo: ecosystem.logo,
      currentStep: 0,
    });
  }, [ecosystemIdToEdit, ecosystem, isLoadingEcosystem, reset, setValue]);

  useEffect(() => {
    if (ecosystemIdToEdit && !isLoadingEcosystem && !ecosystem) {
      toast.error('Ecosystem not found.');
      replaceRoute(PAGES.ROOT);
    }
  }, [ecosystemIdToEdit, ecosystem, isLoadingEcosystem, replaceRoute]);

  return {
    control,
    errors,
    isValid,
    tabsRef,
    currentTab,
    isSubmitting,
    isPrivate,
    modalRef,
    isLoadingEcosystem,
    handleBack,
    handleContinue,
    handleOnSubmit: handleSubmit(onSubmit),
  };
};
