import { useEffect, useMemo, useState } from 'react';
import { colors } from 'ui/theme/colors';

import { ReadMoreProps } from './types';

const useReadMoreController = ({
  text,
  open = false,
  textHeight = '150px',
  btnBgColor = 'primary.50',
  btnTextColor = 'primary.600',
  rightIconColor = colors.primary[600],
  charToShowButton = 150,
  buttonTextShow = 'Read more',
  buttonTextHide = 'Show less',
  ...rest
}: ReadMoreProps) => {
  const [isOpen, setIsOpen] = useState(open);
  const [elementHeight, setElementHeight] = useState(0);
  const showButton = useMemo(() => {
    if (typeof text === 'string') {
      if (!text?.length) return;
      return text.length > charToShowButton;
    }

    return elementHeight > Number(textHeight.replace(/\D/g, ''));
  }, [text, charToShowButton, textHeight, elementHeight]);

  const styles = useMemo(() => {
    return {
      btnText: !isOpen ? buttonTextShow : buttonTextHide,
      btnIcon: !isOpen ? 'chevron-down' : 'chevron-up',
      textHeight: !isOpen ? textHeight : 'full',
      presenceTransition: {
        initial: {
          opacity: 0,
        },
        animate: {
          opacity: 1,
          transition: {
            duration: 250,
          },
        },
      },
    };
  }, [isOpen, textHeight]);

  const handleOnClick = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    if (typeof text !== 'string' && text.props.id) {
      const element = document.getElementById(text.props.id);
      if (!element) return;
      setElementHeight(element.offsetHeight);
    }
  }, [text]);

  return {
    text,
    open,
    styles,
    isOpen,
    btnBgColor,
    btnTextColor,
    rightIconColor,
    handleOnClick,
    showButton,
    ...rest,
  };
};

export default useReadMoreController;
