import './bar.css';

import { Skeleton, VStack } from 'native-base';
import Chart from 'react-apexcharts';
import { colors } from 'ui/theme/colors';

import { InsightsGenericEmptyState } from '~/pages/Authenticated/Insights/components/EmptyState';

export type HorizontalBarChartDataParams = {
  series: {
    name?: string;
    data: number[];
  }[];
  categories: string[];
  maxValue: number;
  total: number;
};

type BarChartProps = HorizontalBarChartDataParams & {
  isLoading?: boolean;
};

export const HorizontalBarChartSkeleton = () => {
  return (
    <VStack
      w="full"
      h="full"
      px={{ base: 6, md: 12 }}
      py={8}
      mx="auto"
      space={4}
    >
      <Skeleton w="full" h="36px" maxW={'60%'} borderRadius={4} />
      <Skeleton w="full" h="36px" maxW={'80%'} borderRadius={4} />
      <Skeleton w="full" h="36px" maxW={'70%'} borderRadius={4} />
    </VStack>
  );
};

const HorizontalBarChartEmptyState = () => <InsightsGenericEmptyState />;

export const HorizontalBarChart = ({
  series,
  categories,
  isLoading,
  maxValue,
  total,
}: BarChartProps) => {
  if (isLoading) {
    return <HorizontalBarChartSkeleton />;
  }

  if (!categories.length) {
    return <HorizontalBarChartEmptyState />;
  }

  return (
    <Chart
      series={series}
      type="bar"
      height={350}
      width={'100%'}
      options={{
        chart: {
          id: 'my-custom-horizontal-bar',
          type: 'bar',
          width: '100%',
          height: '240px',
          toolbar: {
            show: false,
          },
        },
        plotOptions: {
          bar: {
            borderRadius: 4,
            horizontal: true,
            distributed: false,
            columnWidth: '60%',
            barHeight: '50%',
            dataLabels: {
              position: 'right', // Posiciona os rótulos à direita da barra
            },
          },
        },
        dataLabels: {
          enabled: true,
          formatter: function (val: number) {
            const percent = ((val / total) * 100).toFixed(2); // Calcula a porcentagem
            return `\u200b \u200b \u200b \u200b ${percent} %`; // Exibe a porcentagem
          },
          style: {
            fontSize: '14px',
            colors: [colors.gray[600]],
            fontWeight: 500,
          },
          textAnchor: 'start', // Posiciona o texto no início
        },
        yaxis: {
          labels: {
            style: {
              fontSize: '14px',
              fontWeight: 400,
              colors: [colors.singletons.darkText],
            },
          },
          axisBorder: {
            show: false, // Remove a borda do eixo Y
          },
          axisTicks: {
            show: false, // Remove os ticks do eixo Y
          },
        },
        xaxis: {
          categories,
          max: maxValue * 1.5,
          min: 0,
        },
        grid: {
          padding: {
            left: 5,
            right: 5,
          },
          show: false, // Remove a grade de fundo
        },
        colors: [colors.amber[500]],
      }}
    />
  );
};
