import { yupResolver } from '@hookform/resolvers/yup';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useFieldArray, useForm } from 'react-hook-form';
import { useFormImperativeHandle } from 'ui/components/FormBuilderV2/hooks/useFormImperativeHandle';

import { useActivityFormCommonController } from '~/components/ActivityFormBuilderPresets/Controllers/useActivityFormCommonController';
import { MeasurementUnitsCategory } from '~/enums';
import { useGetMeasurementUnits } from '~/hooks/useGetMeasurementUnits';
import { useQuery } from '~/hooks/useQuery';
import { IEditActivityDTO } from '~/types/dtos';
import { MeasurementUnit } from '~/types/interfaces/measurementUnit';
import { getMeasurementUnit, transformSecondsToHours } from '~/utils/functions';

import { AboutFormProps, AppsForSelector } from './types';
import {
  activityMetricsOptions,
  createFormSchema,
  schemaValidation,
} from './utils';

export const useAboutFormController = ({
  handleContinue,
  defaultValues,
  causeOptions,
  isLoading,
  isLoadingDefaultValues,
  connectedApps,
  handleBack,
}: AboutFormProps) => {
  const defaultSelectedApp = (defaultValues as any)?.selectedApp?.[0] || '';

  const [measurementUnitId, setMeasurementUnitId] = useState<string>();
  const [appsForSelector, setAppsForSelector] = useState<AppsForSelector[]>([]);

  const commonController = useActivityFormCommonController({
    defaultSelectedApp,
  });

  const {
    control,
    handleSubmit,
    watch,
    formState: {
      errors,
      //TODO: Fix isDirty being false with defaultValues
      isDirty,
      isValid,
    },
    setValue,
    getValues,
    resetField,
  } = useForm<IEditActivityDTO>({
    resolver: yupResolver(schemaValidation),
    defaultValues,
    mode: 'onChange',
    reValidateMode: 'onBlur',
  });

  useFormImperativeHandle({
    ref: commonController.formBuilderImperativeRef,
    getValues,
    setValue,
  });

  const { data: measurementUnitObject } = useQuery<MeasurementUnit>(
    `/measurement-units/${measurementUnitId}`,
    {
      queryOptions: {
        enabled: !!measurementUnitId,
      },
    },
  );

  const formValues = watch();

  const { measurementUnits } = useGetMeasurementUnits();

  const formSchema = useMemo(() => {
    return createFormSchema({
      causeOptions,
      customControl: control,
      customValidation: schemaValidation,
      connectedApps: appsForSelector,
      commonController,
      handleBack,
    });
  }, [causeOptions, control, appsForSelector, handleBack, commonController]);

  const { fields, append, remove } = useFieldArray<IEditActivityDTO>({
    control,
    name: 'steps',
  });

  const clearMeasurementUnit = () => {
    setValue('measurementUnit', undefined);
  };

  const onChangeMeasurementUnit = (selectOption: {
    label: string;
    value: string;
  }) => {
    const measurementUnit = getMeasurementUnit(
      measurementUnits,
      selectOption.value,
    );
    if (measurementUnit?.category === MeasurementUnitsCategory.Completion) {
      addOneStep();
    } else {
      remove();
    }
    setValue('targetAmount', '');
  };

  const addOneStep = useCallback(() => {
    append({
      title: '',
      description: '',
    });
  }, [append]);

  const initializeDefaultValues = useCallback(() => {
    if (!isDirty && defaultValues) {
      setValue('title', defaultValues.title);
      setValue('description', defaultValues.description);
      setValue('cause', defaultValues.cause);
      setValue('selectedApp', defaultValues.selectedApp);
      setValue('publishedApps', defaultValues.publishedApps);
      setValue('measurementUnit', defaultValues.measurementUnit);

      setMeasurementUnitId(defaultValues.measurementUnit);
    }
  }, [isDirty, defaultValues, setValue]);

  const initializeDefaultSteps = useCallback(() => {
    if (measurementUnitObject) {
      if (
        measurementUnitObject.steps &&
        measurementUnitObject.steps.length > 0
      ) {
        remove();
        append(measurementUnitObject.steps);

        if (
          measurementUnitObject.category ===
            MeasurementUnitsCategory.CustomUnit &&
          measurementUnitObject?.steps?.length
        ) {
          const selectedMeasurementUnit = Object.values(measurementUnits)
            .flatMap((measurement) => measurement)
            .find(
              (measurement) =>
                measurement.category === MeasurementUnitsCategory.Completion,
            );

          setValue('measurementUnit', selectedMeasurementUnit?._id);
          return;
        }

        setValue('measurementUnit', measurementUnitObject?._id);
      } else {
        const targetAmount = defaultValues?.targetAmount;

        if (targetAmount) {
          const measurementUnit = getMeasurementUnit(
            measurementUnits,
            measurementUnitObject._id,
          );

          if (measurementUnit?.category === MeasurementUnitsCategory.Time) {
            resetField('targetAmount', {
              defaultValue:
                typeof formValues?.targetAmount === 'number'
                  ? targetAmount
                  : transformSecondsToHours(Number(targetAmount)),
            });
          } else {
            resetField('targetAmount', { defaultValue: targetAmount });
          }
        }
      }
    }
    // Avoid unnecessary form changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [measurementUnitObject, measurementUnits, defaultValues]);

  useEffect(() => {
    initializeDefaultValues();
    initializeDefaultSteps();
  }, [initializeDefaultValues, initializeDefaultSteps]);

  useEffect(() => {
    if (connectedApps && connectedApps?.length > 0) {
      const mappedApps = connectedApps.map((conenctedApp) => ({
        name: conenctedApp.name,
        value: conenctedApp._id,
        logo: conenctedApp.logo,
      }));

      setAppsForSelector(mappedApps);
    }
  }, [connectedApps]);

  const isDisabled = useMemo(() => !isValid, [isValid]);

  const isFormLoading = isLoadingDefaultValues && !isDirty;

  return {
    //---FormBuilder--//
    causeOptions,
    formSchema,
    defaultValues,
    handleContinue,
    isLoading,
    //---Activity Metrics---//
    activityMetricsOptions,
    measurementUnits,
    control,
    watch,
    errors,
    clearMeasurementUnit,
    onChangeMeasurementUnit,
    addOneStep,
    fields,
    handleSubmit,
    isDisabled,
    isLoadingDefaultValues,
    isDirty,
    isFormLoading,
    formValues,
    handleBack,
  };
};
