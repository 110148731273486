import { useEffect, useRef } from 'react';
import { ITabNavigatorRefProps } from 'ui/components/v2/TabNavigator/types';

import { UpdatesFeedHeaderProps } from '~/pages/Authenticated/Dashboard/components/UpdatesFeed/fragments/UpdatesFeedHeader/types';
import { UpdatesFeedCategory } from '~/pages/Authenticated/Dashboard/components/UpdatesFeed/types';

export const useUpdatesFeedHeaderController = ({
  onAppChange,
  onCategoryChange,
}: UpdatesFeedHeaderProps) => {
  const tabsRef = useRef<ITabNavigatorRefProps | null>(null);

  useEffect(() => {
    tabsRef.current?.jump(0);
    onCategoryChange(UpdatesFeedCategory.AllUpdates);
  }, []);

  const handleTabChange = (index: number, value?: string) => {
    onCategoryChange(value as UpdatesFeedCategory);
  };

  const handleAppChange = (newSelectedAppId: string) => {
    onAppChange(newSelectedAppId);
  };

  return { tabsRef, handleTabChange, handleAppChange };
};
