import { Avatar, Box, HStack, Pressable, Stack, Text } from 'native-base';
import Icon from 'ui/components/Icon';
import { AppVisibility } from 'ui/enums';

import { useRouter } from '~/hooks/useRouter';
import { colors } from '~/theme/colors';

import { IEcosystem } from '../../../../../../types/interfaces/ecosystem';
import { AppCardProps } from './types';

export const AppCard = ({
  app: { _id, name, description, visibility, logo, ecosystem },
}: AppCardProps) => {
  const { goToRoute } = useRouter();

  return (
    <Stack
      bg={colors.singletons.lightText}
      p="6"
      rounded="2xl"
      borderWidth="1"
      borderColor="gray.300"
    >
      <HStack justifyContent="space-between">
        <Avatar rounded={12} _image={{ rounded: 12 }} source={{ uri: logo }} />
        <Box>
          <HStack space="1" alignItems="center">
            {visibility === AppVisibility.Private ? (
              <>
                <Icon icon="lock" size="16" color={colors.gray[500]} />
                <Text color="gray.500">Private</Text>
              </>
            ) : (
              <>
                <Icon icon="eye" size="16" color={colors.gray[500]} />
                <Text color="gray.500">Public</Text>
              </>
            )}
          </HStack>
        </Box>
      </HStack>
      <Stack marginTop={'4'}>
        <Text fontSize="lg" w="1/2" numberOfLines={2} height="3.4rem">
          {name}
        </Text>
      </Stack>

      <Stack space={2}>
        <Text mt={2} color="gray.600" numberOfLines={2} height="2.7rem">
          {description}
        </Text>

        <Text color={'gray.600'} fontSize={'xs'}>
          Ecosystem (Owner)
        </Text>
        <Text color={'black'} height="2.5rem">
          {(ecosystem as IEcosystem)?.name}
        </Text>

        <Pressable
          alignItems="flex-end"
          onPress={() => goToRoute(`/settings/apps/${_id}`)}
        >
          <HStack alignItems="center" space="3">
            <Text color="primary.400">View</Text>
            <Icon icon="arrow-right" size="16" color={colors.primary[400]} />
          </HStack>
        </Pressable>
      </Stack>
    </Stack>
  );
};
