import { Alert, Box, HStack, Progress, Stack, Text, VStack } from 'native-base';
import Button from 'ui/components/Button';
import Loading from 'ui/components/Loading';
import Select from 'ui/components/Select';

import Scaffold from '~/components/Scaffold';
import { PAGES } from '~/constants/pages.constants';
import { ActivitiesUploadContent } from '~/pages/Authenticated/BulkUploads/components/ActivitiesUploadContent';
import { CSVErrorList } from '~/pages/Authenticated/BulkUploads/components/CSVErrorsList';
import { ImportsTable } from '~/pages/Authenticated/BulkUploads/components/ImportsTable';
import { UploadScafoldFooter } from '~/pages/Authenticated/BulkUploads/components/UploadScafoldFooter';
import { usePlacesUploadController } from '~/pages/Authenticated/BulkUploads/PlacesUpload/controller';
import { EImportFileType } from '~/pages/Authenticated/BulkUploads/types';
import { colors } from '~/theme/colors';

export const PlacesUpload = () => {
  const {
    isViewMode,
    defaultImportFile,
    isDefaultImportFileLoading,
    handleDownloadTemplate,
    control,
    refetchQuantity,
    uploadPlaceFile,
    uploadIsLoading,
    csvValidationErrors,
    handleDropFile,
    handleRemoveFile,
    isDropAreaDisabled,
    isActivityDefinitionImportFilesLoading,
    setSelectedImportFileId,
    activityDefinitionOptionList,
    relatedActivityImportFile,
    goToRoute,
    progressValue,
    refreshImports,
  } = usePlacesUploadController();

  if (isViewMode && isDefaultImportFileLoading) {
    return (
      <Scaffold title="Places file">
        <Stack py="6" justifyContent="center" w="100%">
          <Loading containerHeight="" />
        </Stack>
      </Scaffold>
    );
  }

  return (
    <>
      <Scaffold
        title={isViewMode ? 'Places file' : 'Places Upload'}
        actionComponents={
          <>
            <Button
              variant="solid"
              p={5}
              width={'fit-content'}
              fontWeight={600}
              onPress={() => goToRoute(PAGES.ACTIVITIES)}
            >
              Finish
            </Button>
          </>
        }
      >
        {!defaultImportFile && (
          <Stack space="6">
            <Stack space="1">
              <Text color="gray.600" fontSize={18} fontWeight="medium">
                Upload your places
              </Text>
              <Text color="gray.600" fontSize={14}>
                To upload an places file, you must select one activity file
                already uploaded.
              </Text>
            </Stack>
            <Button
              variant={'outline'}
              leftIconName="file-text"
              maxW="200px"
              leftIconColor={colors.primary['600']}
              onPress={handleDownloadTemplate}
            >
              Download template
            </Button>
          </Stack>
        )}

        <Alert status="warning">
          <Text>
            The bulk upload template was updated on 10/04/24 to cover regional
            work-from-home activities and applications external to our platform.
            If you are using the previously provided template, the bulk upload
            will also work for this model.
          </Text>
        </Alert>

        <Box w={'full'}>
          <Select
            name="place-import-file"
            label="Imported file*"
            placeholder={
              defaultImportFile && relatedActivityImportFile
                ? relatedActivityImportFile.key
                : 'Select at least one imported file to upload your places'
            }
            control={control}
            isLoading={isActivityDefinitionImportFilesLoading}
            options={activityDefinitionOptionList}
            isDisabled={!!defaultImportFile}
            onChange={(option) => {
              setSelectedImportFileId &&
                setSelectedImportFileId((option as { value: string }).value);
            }}
          />
        </Box>

        <ActivitiesUploadContent
          control={control}
          haveImports={!!defaultImportFile}
          uploadIsLoading={uploadIsLoading}
          defaultImportedFile={defaultImportFile}
          handleDropFile={handleDropFile}
          handleRemoveFile={handleRemoveFile}
          importFileType={EImportFileType.Activity}
          isDisabled={isDropAreaDisabled}
        />

        {!defaultImportFile ? (
          <>
            <UploadScafoldFooter
              isLoading={uploadIsLoading}
              isDisabled={!!defaultImportFile}
              onClick={uploadPlaceFile}
            />
            <CSVErrorList errors={csvValidationErrors} />
          </>
        ) : (
          <VStack width="100%" space="2">
            <Alert
              padding={3}
              backgroundColor="warning.100"
              status="warning"
              borderRadius="md"
              width="100%"
            >
              <Text>
                You can leave this page and come back later to check the
                progress.
              </Text>
            </Alert>
            <UploadScafoldFooter showRefreshButton onClick={refreshImports} />
            <HStack
              flex={1}
              alignItems={'center'}
              justifyContent={'space-between'}
              space={2}
            >
              <Progress value={progressValue || 0} flexGrow={1} />
              <Text color="gray.500" fontSize="sm">
                {(progressValue || 0)?.toFixed(2)}%
              </Text>
            </HStack>
            <ImportsTable
              importFileId={defaultImportFile?._id}
              refetchQuantity={refetchQuantity}
            />
          </VStack>
        )}
      </Scaffold>
    </>
  );
};

export default PlacesUpload;
