import { useQuery } from '~/hooks/useQuery';

import { IOrganization } from '../../types/interfaces/organization';

export const useLoadOrganizationPublic = (organizationId: string) => {
  const { data, isLoading, isRefetching } = useQuery<IOrganization>(
    `organization/public/${organizationId}`,
    {
      queryOptions: { enabled: !!organizationId },
      key: `organization-public-${organizationId}`,
      isOpenApi: true,
    },
  );

  return { data, isLoading, isRefetching };
};
