import { AxiosResponse } from 'axios';

import { api } from '~/services/api';
import { QueryParams } from '~/services/resources/types';
import { IActivityApplication } from '~/types/interfaces/activity';

const MODULE_URL = 'activity-application';

export default class ActivityApplicationService {
  static async findAll(
    params?: QueryParams,
  ): Promise<AxiosResponse<{ data: IActivityApplication[]; count: number }>> {
    const options = {
      params,
    };

    return await api.get(`${MODULE_URL}/org`, options);
  }
  static async findOne(id: string) {
    return await api.get(`${MODULE_URL}/org/${id}`);
  }

  static async deleteOne(id: string) {
    return await api.delete(`${MODULE_URL}/${id}`);
  }

  static async get(id: string) {
    return await api.get(`${MODULE_URL}/${id}`);
  }
}
