import { StatusCodes } from 'http-status-codes';
import { useRef, useState } from 'react';
import { toast } from 'react-toastify';
import { IModalRefProps } from 'ui/components/Modals/Modal/types';
import { LocationOptionsEnum } from 'ui/enums';

import { EVENT_MESSAGES } from '~/constants/messages.constants';
import { ActivityTypeEnum } from '~/enums';
import { useAppSelector } from '~/hooks/useAppSelector';
import ActivityService from '~/services/resources/activity';

import { LocationSectionProps } from './types';

const defaultCloseApplicationData = {
  id: '',
  isToggled: false,
  endDate: new Date(),
};
export const useLocationController = ({
  activityType,
  locationOption,
}: Pick<LocationSectionProps, 'activityType' | 'locationOption'>) => {
  const changeApplicationModalRef = useRef<IModalRefProps>(null);

  const { organizationSelectedId } = useAppSelector(({ auth }) => auth);

  // Used to store data when opening modal for closing applications
  const [closeApplicationData, setCloseApplicationData] = useState(
    defaultCloseApplicationData,
  );

  const [isLoadingChangeCloseApplication, setIsLoadingChangeCloseApplication] =
    useState(false);

  const [refetchData, setRefetchData] = useState(0);

  const handleChangeApplicationStatus = async (
    id?: string,
    isToggled?: boolean,
    endDate?: Date,
  ) => {
    if (isLoadingChangeCloseApplication) return;
    try {
      setIsLoadingChangeCloseApplication(true);

      const currentId = id || closeApplicationData?.id;

      const dueDate = isToggled ? new Date(Date.now()) : endDate;

      const response = await ActivityService.editEvent(currentId, {
        dueDate,
        organization: organizationSelectedId || '',
      });

      if (response.status === StatusCodes.OK) {
        changeApplicationModalRef.current?.close();
        // TODO: Improve as to table refresh
        setRefetchData(Math.random() * 1000);
        toast.success(EVENT_MESSAGES.EDIT_EVENT_SUCCESS);
      }
    } catch {
      toast.error(EVENT_MESSAGES.EDIT_EVENT_ERROR);
    } finally {
      setIsLoadingChangeCloseApplication(false);
    }
  };

  const handleCloseApplication = (
    id: string,
    isToggled: boolean,
    endDate: Date,
  ) => {
    setCloseApplicationData({ id, isToggled, endDate });
    if (!isToggled) {
      handleChangeApplicationStatus(id, isToggled, endDate);
      return;
    }
    changeApplicationModalRef.current?.open();
  };

  const handleCancelCloseApplication = () => {
    setCloseApplicationData(defaultCloseApplicationData);
    changeApplicationModalRef.current?.close();
  };

  const renderLocationsTableTitle = () => {
    if (locationOption === LocationOptionsEnum.FromHome) {
      if (activityType === ActivityTypeEnum.OngoingOpportunity) {
        return 'Participation';
      }
      return 'Dates';
    }
    return 'Location';
  };
  const renderLocationsTableDescription = () => {
    if (locationOption === LocationOptionsEnum.FromHome) {
      if (activityType === ActivityTypeEnum.OngoingOpportunity) {
        return 'View this opportunity applications and attendances';
      }
      return 'View when this opportunity will be available';
    }
    return 'View where this opportunity takes place';
  };

  return {
    isLoadingChangeCloseApplication,
    changeApplicationModalRef,
    handleChangeApplicationStatus,
    handleCancelCloseApplication,
    handleCloseApplication,
    refetchData,
    closeApplicationData,
    renderLocationsTableTitle,
    renderLocationsTableDescription,
  };
};
