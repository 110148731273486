import { Stack, VStack } from 'native-base';
import FormHeader from 'ui/components/FormBuilderV2/components/FormHeader';
import { ActivityType } from 'ui/types/activities';

import { FromHome } from '~/components/FromHome';

import { WhenWhereFormProps } from './types';

export const WhenWhereForm = ({
  handleContinue,
  isLoading,
  handleBack,
  defaultValues,
}: WhenWhereFormProps) => {
  return (
    <VStack w="100%" space={4}>
      <FormHeader
        title="From home"
        subTitle="Actions don’t have specific locations, but you can specify regions and online link. This step is optional."
      />

      <VStack>
        <Stack minH="40vh">
          <FromHome
            handleContinue={handleContinue}
            handleBack={handleBack}
            activityType={ActivityType.Action}
            isLoading={isLoading}
            defaultValues={defaultValues}
            isEditing={true}
          />
        </Stack>
      </VStack>
    </VStack>
  );
};
