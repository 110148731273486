import { ActivityType } from 'ui/types/activities';
import { App } from 'ui/types/interfaces/app';
import { IPermission } from 'ui/types/interfaces/permission';

import { useAppDispatch } from '~/hooks/useAppDispatch';
import { updatePartnerPermissionsAction } from '~/store/common/actions/permissions/updatePartnerPermissionsAction';

import { useAppSelector } from '../useAppSelector';

export const usePermissions = () => {
  const dispatch = useAppDispatch();

  const { ecosystemApps } = useAppSelector(({ ecosystem }) => ecosystem);
  const { partnerPermissionsGroupsByAppId } = useAppSelector(
    ({ permissions }) => permissions,
  );

  const handleUpdatePartnerPermissions = async (ecosystemId: string) => {
    await dispatch(updatePartnerPermissionsAction(ecosystemId));
  };

  const validateIfOrganizationIsAppOwner = (appId: string) => {
    return true;
    // disabled permissions validation for now
    // const adminApp = networkAppSharings?.find((networkAppSharing) => {
    //   if (networkAppSharing.app._id === appId) {
    //     if (
    //       networkAppSharing.isSelfOrganizationSharing &&
    //       networkAppSharing.isSelfSharing
    //     ) {
    //       return networkAppSharing.app;
    //     }
    //   }
    // });

    // if (adminApp) {
    //   return true;
    // }
    // return false;
  };

  const validateIfAppHasPermission = (
    appId: string,
    permissions: Partial<IPermission>[],
  ) => {
    // disabled permissions validation for now
    return true;
    // if (!appId) return false;
    // const hasPermission = (partnerPermissionsGroupsByAppId[appId] ?? []).some(
    //   (appPermissionGroup) => {
    //     return appPermissionGroup.permissions.some((appPermission) =>
    //       permissions.some(
    //         (permission) => permission.name === appPermission.name,
    //       ),
    //     );
    //   },
    // );
    // return hasPermission;
  };

  /* this is a temporary solution for app permission */
  const filterAppsForActivityCreation = (
    activityType: ActivityType,
    isForEvent: boolean,
  ) => {
    const availableApps = Object.values(ecosystemApps);
    const filteredApps: App[] = [];

    if (!availableApps.length) return filteredApps;

    for (const app of availableApps) {
      const permissionGroups = partnerPermissionsGroupsByAppId[app?._id] || [];
      if (!permissionGroups.length) continue;

      for (const permissionGroup of permissionGroups) {
        for (const permission of permissionGroup.permissions) {
          if (permission?.name.startsWith('activity')) {
            filteredApps.push(app);
            break;
          }
        }
      }
    }
    return filteredApps;
  };

  // Enable this later when we activiate permissions again
  // const filterAppsForActivityCreation = (
  //   activityType: ActivityType,
  //   isForEvent: boolean,
  // ) => {
  //   const appsListFromDictionary = Object.values(ecosystemApps);

  //   let activityPermissions: Partial<IPermission>[] = [];

  //   if (isForEvent) {
  //     activityPermissions = [
  //       PartnerActivityPermissions.PublishEvents,
  //       PartnerActivityPermissions.PublishTeamEvents,
  //     ];
  //   } else {
  //     activityPermissions = getPublishPermissionForActivityType({
  //       activityType,
  //       isTeamEvent: false,
  //     });
  //   }

  //   if (appsListFromDictionary.length === 0) return [];
  //   if (Object.keys(partnerPermissionsGroupsByAppId).length === 0) return [];

  //   const filteredApps: App[] = [];

  //   appsListFromDictionary.forEach((app) => {
  //     const isAppAdmin = validateIfOrganizationIsAppOwner(app._id);

  //     const hasAppInPermissionsGroups =
  //       !!partnerPermissionsGroupsByAppId[app._id];

  //     if (!hasAppInPermissionsGroups) {
  //       if (isAppAdmin) {
  //         filteredApps.push(app);
  //       }
  //     } else {
  //       partnerPermissionsGroupsByAppId[app._id].forEach(
  //         (appPermissionGroup) => {
  //           activityPermissions.forEach((activityPermission) => {
  //             appPermissionGroup.permissions.forEach((permission) => {
  //               if (activityPermission.name === permission.name) {
  //                 const appExistsInList = filteredApps.find(
  //                   (filteredApp) => filteredApp._id === app._id,
  //                 );
  //                 if (!appExistsInList) {
  //                   filteredApps.push(app);
  //                 }
  //               }
  //             });
  //           });
  //         },
  //       );
  //     }
  //   });

  //   return filteredApps;
  // };

  const getAvailableAppEventPermissions = (
    appId: string,
    eventPermissions: Partial<IPermission>[],
  ) => {
    const appPermissions: Partial<IPermission>[] = [];

    const isAppAdmin = validateIfOrganizationIsAppOwner(appId);

    if (isAppAdmin) {
      return eventPermissions;
    }
    partnerPermissionsGroupsByAppId[appId].forEach((appPermissionGroup) => {
      appPermissionGroup.permissions.forEach((permission) => {
        eventPermissions.forEach((eventPermission) => {
          if (permission.name === eventPermission.name) {
            appPermissions.push(permission);
          }
        });
      });
    });
    return appPermissions;
  };

  return {
    handleUpdatePartnerPermissions,
    validateIfOrganizationIsAppOwner,
    validateIfAppHasPermission,
    filterAppsForActivityCreation,
    getAvailableAppEventPermissions,
  };
};
