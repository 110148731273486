import { LocationOptionsEnum } from 'ui/enums';

export const getActivityDefinitionLocationOption = (
  locationOption: LocationOptionsEnum,
  activitiesLength: number,
) => {
  // Temporary fix to avoid breaking activities that has been created or updated with In Person locationOption
  let activityLocationOption = locationOption;

  if (activityLocationOption === LocationOptionsEnum.InPerson) {
    if (activitiesLength > 1) {
      activityLocationOption = LocationOptionsEnum.MultipleLocations;
    } else {
      activityLocationOption = LocationOptionsEnum.SingleLocation;
    }
  }

  return activityLocationOption;
};
