import { INPUT_COMPONENT_TYPES } from 'ui/components/FormBuilderV2';

import { AppInfoAlert } from '~/components/AppInfoAlert';

import { ActivityRadioAppSectionProps } from './types';

export const ActivityRadioAppSection = ({
  appsList,
  onChangeValue,
  helpLink = '',
  isDisabled = false,
}: ActivityRadioAppSectionProps) => {
  return {
    key: 'app-section',
    sectionCustomHeaderSlot: isDisabled
      ? AppInfoAlert({
          title: 'Apps Editing Disabled',
          description:
            'Be aware that after a volunteer application for a specific app, editing will be disabled.',
        })
      : null,
    sectionInputRows: [
      {
        key: 'app-host-row',
        inputs: [
          {
            id: 'selectedApp',
            name: 'selectedApp',
            isRequired: true,
            component: INPUT_COMPONENT_TYPES.RADIO_APP_SELECT,
            inputProps: {
              title: 'Which app will host the activity?',
              subtitle:
                'Select the app that hosts the activity being published',
              isMultiple: false,
              appsList,
              helpLink,
              isDisabled,
              onChangeValue: onChangeValue,
            },
          },
        ],
      },
    ],
  };
};
