import DateFilter from '@inovua/reactdatagrid-community/DateFilter';
import { Avatar, HStack } from 'native-base';
import DataTableActions from 'ui/components/DataTableActions';
import { TableCellToolTip } from 'ui/components/TableCellToolTip';
import { InviteCodeVisibilityEnum } from 'ui/enums';
import { safeDateFormat } from 'ui/utils/formatDates';

import { MemberTypeEnum } from '~/enums';
import { colors } from '~/theme/colors';
import { App } from '~/types/interfaces/app';
import { InviteMembersPopulate } from '~/types/interfaces/invite-member';
import { InviteTableMembers } from '~/types/interfaces/table';

interface TablePendingMemberInvitesProps {
  openResendInviteModal: (inviteId: string, inviteType: string) => void;
  copyInviteLink: (link: string) => void;
  openModalInviteDetails: (data: Partial<InviteMembersPopulate>) => void;
}

export const tablePendingMemberInviteColumns = ({
  openResendInviteModal,
  copyInviteLink,
  openModalInviteDetails,
}: TablePendingMemberInvitesProps) => [
  {
    defaultFlex: 1,
    name: 'receiverEmail',
    header: 'E-mail',
    filterable: true,
    render: ({ data }: InviteTableMembers) => {
      return data?.receiverEmail || '-';
    },
  },
  {
    name: 'createdAt',
    header: 'Date of invite',
    filterable: true,
    defaultFlex: 0.6,
    filterEditor: DateFilter,
    filterEditorProps: () => ({
      dateFormat: 'YYYY-MM-DD',
      cancelButton: false,
      highlightWeekends: false,
    }),
    render: ({ data }: InviteTableMembers) => {
      return safeDateFormat(data.createdAt, 'dd/MM/yyyy') || '-';
    },
  },

  {
    name: 'visibility',
    header: 'Type',
    defaultFlex: 0.5,
    render: ({ data }: InviteTableMembers) => {
      return data?.visibility || InviteCodeVisibilityEnum.Private;
    },
  },

  {
    name: 'configurations.ecosystemId',
    header: 'Ecosystems',
    defaultFlex: 1,

    render: ({ data }: InviteTableMembers) => {
      const text = `${data?.configurations
        .map((configuration) => configuration?.ecosystemId?.name)
        .join(', ')}`;
      return <TableCellToolTip text={text || ''} />;
    },
  },

  {
    name: 'configurations.appsData',
    header: 'Apps',

    defaultFlex: 0.5,
    cellProps: {
      style: { flexDirection: 'row' },
    },
    render: ({ data }: InviteTableMembers) => {
      const uniqueApps: App[] = [];

      (data?.configurations || []).forEach((configuration) => {
        if (!configuration?.appsData) return;

        configuration.appsData.forEach((appData) => {
          const appId = appData?.appId?._id;
          if (!appId) return;

          const hasApp = uniqueApps.some(
            (uniqueApp) => uniqueApp?._id === appId,
          );

          if (!hasApp) uniqueApps.push(appData?.appId as App);
        });
      });

      return (
        <HStack space={1}>
          {uniqueApps.map((app) => (
            <Avatar
              size={'sm'}
              borderRadius={'sm'}
              bg={'white'}
              key={app._id}
              source={{ uri: app.logo }}
            />
          ))}
        </HStack>
      );
    },
  },

  {
    name: 'actions',
    header: 'Actions',
    defaultFlex: 0.6,
    render: ({ data }: InviteTableMembers) => {
      const popoverOptions = [
        {
          icon: {
            color: colors.gray[600],
            icon: 'send',
            size: 22,
          },
          title: 'Resend invite',
          action: () => openResendInviteModal(data._id, MemberTypeEnum.Member),
        },
        {
          icon: {
            color: colors.gray[600],
            icon: 'copy',
            size: 22,
          },
          title: 'Copy link',
          action: () => copyInviteLink(data.inviteURL || ''),
        },
        {
          icon: {
            color: colors.gray[600],
            icon: 'eye',
            size: 22,
          },
          title: 'Details',
          action: () => openModalInviteDetails(data),
        },
      ];
      return (
        <DataTableActions
          popoverActions
          popoverTextColor={colors.primary[400]}
          popoverIconColor={colors.primary[400]}
          popoverOptions={popoverOptions}
        />
      );
    },
  },
];
