import { Divider, HStack, Text, VStack } from 'native-base';
import Button from 'ui/components/Button';
import Icon from 'ui/components/Icon';
import { GenericFormTabs } from 'ui/enums';

import { colors } from '~/theme/colors';

import { ActivityMetricsSectionProps } from './types';

export const ActivityMetricsSection = ({
  metricsCategory,
  metricsName,
  targetAmount,
  handleEditActivity,
  isOrganizationOwnerOfActivity,
}: ActivityMetricsSectionProps) => {
  return (
    <VStack space={2} pb={8}>
      <HStack justifyContent="space-between" alignItems="center" mb={2}>
        <VStack space={2}>
          <Text color="gray.600" fontSize="2xl" fontWeight={500}>
            Activity Metrics
          </Text>
          <Text color="gray.900" fontSize="sm" fontWeight={400}>
            This is how you will measure the success of this activity.
          </Text>
        </VStack>
        {isOrganizationOwnerOfActivity ? (
          <Button
            w="76px"
            borderRadius={12}
            py={2}
            px={3}
            minW="auto"
            leftIconName="edit"
            alignItems="center"
            leftIconColor={colors.primary[400]}
            variant="outline"
            onPress={() => handleEditActivity(GenericFormTabs.About)}
          >
            <Text color={colors.primary[400]}>Edit</Text>
          </Button>
        ) : null}
      </HStack>
      <Divider mb={6} />
      <HStack space={2} justifyContent="space-between" alignItems="center">
        <VStack flex={0.5} space={2}>
          <Text color="gray.600" fontSize="sm" fontWeight={500}>
            Unit of measurement
          </Text>
          <Text
            color={colors.singletons.darkText}
            fontSize="sm"
            fontWeight={400}
          >
            <Text color="gray.400" fontSize="sm" fontWeight={500}>
              {metricsCategory.toUpperCase()}
            </Text>{' '}
            {metricsName}
          </Text>
        </VStack>
        <VStack flex={0.5} space={2}>
          <Text color="gray.600" fontSize="sm" fontWeight={500}>
            Target Amount
          </Text>
          <HStack space={2}>
            <Icon icon="target" color={colors.gray[600]} size={18} />
            <Text
              color={colors.singletons.darkText}
              fontSize="sm"
              fontWeight={400}
            >
              {targetAmount} {metricsName}
            </Text>
          </HStack>
        </VStack>
      </HStack>
    </VStack>
  );
};
