import { HStack, Text, VStack } from 'native-base';
import CopyButton from 'ui/components/CopyButton';
import Icon from 'ui/components/Icon';

import { colors } from '~/theme/colors';

import { ContactInfoItemProps } from './types';

export const ContactInfoItem = ({
  infoName,
  infoValue,
  icon,
}: ContactInfoItemProps) => {
  return (
    <VStack>
      <Text fontWeight="medium" color="gray.600">
        {infoName}
      </Text>
      <HStack space={10} alignItems="flex-end">
        <HStack w="50%" space={2} alignItems="center">
          <Icon icon={icon} color={colors.gray[400]} size={16} />
          <Text color="singletons.darkText" numberOfLines={1}>
            {infoValue}
          </Text>
        </HStack>

        <CopyButton value={infoValue} />
      </HStack>
    </VStack>
  );
};
