import { Alert, HStack, IAlertProps, Text, VStack } from 'native-base';

export const AlertImportedActivity = (props: IAlertProps) => {
  return (
    <Alert {...props} w="100%" colorScheme="warning" status="warning">
      <VStack space={2} flexShrink={1} w="100%">
        <HStack
          flexShrink={1}
          space={2}
          alignItems="center"
          justifyContent="space-between"
        >
          <HStack space={2} flexShrink={1} alignItems="center">
            <Alert.Icon />
            <Text>
              You are viewing an activity that was imported from an external
              platform. To edit it,{' '}
              <strong>you must access the platform where it was created</strong>
              .
            </Text>
          </HStack>
        </HStack>
      </VStack>
    </Alert>
  );
};
