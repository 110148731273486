import { differenceInDays, parseISO } from 'date-fns';

import { InsightPageFilters } from '~/pages/Authenticated/Insights/useInsightFilters';

export const INSIGHT_TABS = {
  BY_TYPE: 'By type',
  TOTAL: 'Total',
};

export const insightTabList = Object.values(INSIGHT_TABS).map((tabItem) => ({
  value: tabItem,
}));

export const dateFilterIsMoreThan30Days = (
  filters: Pick<InsightPageFilters, 'from' | 'to'>,
) => {
  return differenceInDays(parseISO(filters.to!), parseISO(filters.from!)) > 30;
};
