import { TableCellWithAvatar } from 'ui/components/TableCellWithAvatar';

import { EcosystemMembersTable } from '~/types/interfaces/table';

export const tableAssignGroupColumns = () => [
  {
    defaultFlex: 1,
    name: 'userSummary.name',
    header: 'Name',
    filterable: true,
    render: ({ data }: EcosystemMembersTable) => {
      return (
        <TableCellWithAvatar
          name={data?.user?.name || ''}
          avatar={data?.user?.profileImage}
        />
      );
    },
  },
];
