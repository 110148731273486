import { VStack } from 'native-base';
import { useMemo } from 'react';
import { useQuery } from 'react-query';
import { InsightsActivityType } from 'ui/types/activities';

import {
  DonutChart,
  DonutChartDataParams,
} from '~/pages/Authenticated/Insights/charts/DonutChart';
import { InsightScaffold } from '~/pages/Authenticated/Insights/components/InsightScaffold';
import { activityTypeConfigMap } from '~/pages/Authenticated/Insights/components/Trending/utils';
import {
  InsightPageFilters,
  useInsightFilters,
} from '~/pages/Authenticated/Insights/useInsightFilters';
import InsightActivityApplicationService from '~/services/resources/insightActivityApplication';
import { BaseInsightQueryParams } from '~/services/resources/types';

import { InsightsActivityColors } from '../charts/constants';

type PreferableActivitiesProps = {
  className: string;
};

const buildQueryKey = (filters: InsightPageFilters) => {
  const params: BaseInsightQueryParams = {
    apps: filters.apps,
    ecosystem: filters.selectedEcosystemId,
    organization: filters.selectedOrganizationId,
    startDate: filters.from!,
    endDate: filters.to!,
    granularity: 'Daily',
  };
  return [
    InsightActivityApplicationService.FAVORITE_CAUSES_KEY +
      'preferable-activities',
    params,
  ];
};

export const PreferableActivities = ({
  className,
}: PreferableActivitiesProps) => {
  const { filters, hasFilterSetted } = useInsightFilters();

  const {
    data: response,
    isFetching,
    isLoading,
  } = useQuery({
    queryKey: buildQueryKey(filters),
    queryFn: async ({ queryKey }) => {
      const [, params] = queryKey;

      return await InsightActivityApplicationService.getPreferableActivies(
        params as BaseInsightQueryParams,
      );
    },
    staleTime: 1000 * 60 * 5, // 5 mins
    enabled: hasFilterSetted,
  });

  const chartData = useMemo(() => {
    const data = (response ?? []).reduce(
      (acc, item) => {
        const activityTypeLabel =
          activityTypeConfigMap.get(item.activityType)?.label ??
          item.activityType;

        acc.series.push(item.total);
        acc.labels.push(activityTypeLabel);
        return acc;
      },
      {
        series: [],
        labels: [],
      } as DonutChartDataParams,
    );

    return data;
  }, [response]);

  const colors = useMemo(() => {
    return chartData.labels.map(
      (label) => InsightsActivityColors[label as InsightsActivityType],
    );
  }, [chartData.labels]);

  return (
    <InsightScaffold
      className={className}
      chartData={response ?? []}
      title={{
        icon: 'star',
        name: 'Preferable Activities',
      }}
    >
      <VStack px={6} pb={6}>
        <DonutChart
          colors={colors}
          isLoading={isLoading || isFetching}
          labels={chartData.labels}
          series={chartData.series}
          title="Activities"
        />
      </VStack>
    </InsightScaffold>
  );
};
