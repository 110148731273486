import { COMMON_MESSAGES } from '~/constants/messages.constants';
import { SignUpFormFields } from '~/pages/Common/JoinAsPartnerSignup/types';
import Yup from '~/utils/validations/yup';

export const defaultValues: SignUpFormFields = {
  firstName: '',
  lastName: '',
  phoneNumber: '',
  email: '',
  password: '',
  termsOfService: false,
  accessCode: '',
};

export const schemaValidation = [
  Yup.object({
    firstName: Yup.string().required(),
    lastName: Yup.string().required(),
    phoneNumber: Yup.string().required(),
    email: Yup.string()
      .transform((value: string) => value?.trim() || '')
      .email()
      .required(),
    password: Yup.string().password().required(),
    termsOfService: Yup.boolean()
      .required()
      .oneOf([true], COMMON_MESSAGES.ACCEPT_TERMS),
  }),
  Yup.object({
    accessCode: Yup.string().required(),
  }),
];
