import { VStack } from 'native-base';
import FormBuilderV2 from 'ui/components/FormBuilderV2';
import { FormDataIsLoading } from 'ui/components/FormBuilderV2/components/FormDataIsLoading';

import { useAboutFormController } from './controller';
import { AboutFormProps } from './types';

export const AboutForm = (props: AboutFormProps) => {
  const {
    handleContinue,
    defaultValues,
    formSchema,
    isLoading,
    isLoadingDefaultValues,
    formBuilderImperativeRef,
  } = useAboutFormController(props);

  return (
    <VStack w="100%" space={4}>
      <VStack w="full">
        {isLoadingDefaultValues ? (
          <FormDataIsLoading />
        ) : (
          <FormBuilderV2
            options={formSchema}
            onSubmit={handleContinue}
            defaultValues={defaultValues}
            isLoadingSubmit={isLoading}
            formBuilderImperativeRef={formBuilderImperativeRef}
          />
        )}
      </VStack>
    </VStack>
  );
};
