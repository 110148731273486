import CompanyProfileBannerImage from 'ui/assets/images/company-profile-banner.jpg';

export const DEFAULT_TITLE = 'Build your company profile!';

export const DEFAULT_DESCRIPTION =
  'Get matched with local charities and provide more volunteer opportunities for your staff. Promote your organisation profile on our network and highlight your volunteer leave day.';

export const DEFAULT_ACTION_LABEL = 'Update company profile';

export const DEFAULT_COVER_IMAGE = CompanyProfileBannerImage;
