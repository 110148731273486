import Icon from 'feather-icons-react';
import { Center, Divider, HStack, Stack, Text, VStack } from 'native-base';
import Button from 'ui/components/Button';

import { ModalDeleteAccount } from '~/components/ModalDeleteAccount';
import ModalPrivacyPolicy from '~/components/ModalPrivacyPolicy';
import ModalTermsOfService from '~/components/ModalTermsOfService';
import Scaffold from '~/components/Scaffold';
import { useAccountSettingsController } from '~/pages/Authenticated/AccountSettings/useAccountSettingsController';
import { colors } from '~/theme/colors';

import { EmailPreferences } from './EmailPreferences';

export const AccountSettings = () => {
  const {
    user,
    isDeletingAccount,
    handleChangePassword,
    modalPrivacyPolicyRef,
    modalTermsOfServiceRef,
    modalDeleteAccountRef,
    handleOpenDeleteAccount,
    handleOpenPrivacyPolicy,
    handleOpenTermsOfService,
    handleConfirmDeleteAccount,
    handleCloseDeleteAccount,
  } = useAccountSettingsController();

  return (
    <Scaffold
      title="Account settings"
      alignItemsChildren="center"
      withBg={false}
    >
      <HStack space={12} justifyContent="space-between" width="full">
        <Stack width="50%" bgColor="white" p={6} borderRadius="3xl">
          <HStack w="100%" justifyContent="space-between" alignItems="center">
            <Text fontSize="xl" fontWeight="500" color="gray.600">
              Account
            </Text>
          </HStack>
          <Text
            fontSize="sm"
            fontWeight="400"
            color="gray.600"
            mt={3}
            mb={8}
            w="100%"
          >
            General account settings.
          </Text>
          <Stack space={3}>
            <Text fontSize="lg" fontWeight="500" color="gray.600">
              Email and Password
            </Text>
            <Divider />
          </Stack>
          <HStack
            w="100%"
            justifyContent="space-between"
            alignItems="center"
            mb="24px"
            mt={8}
          >
            <VStack justifyContent="center">
              <Text color="gray.600" fontSize="sm" fontWeight={500}>
                Email address
              </Text>
              <HStack alignItems="center" mt={2}>
                <Icon icon="mail" size={16} color={colors.grey[600]} />
                <Text
                  color={colors.singletons.darkText}
                  fontSize="sm"
                  fontWeight={400}
                  ml="8px"
                >
                  {user?.email || ''}
                </Text>
              </HStack>
            </VStack>
          </HStack>
          <HStack
            w="100%"
            justifyContent="space-between"
            alignItems="center"
            mt={4}
          >
            <VStack justifyContent="center">
              <Text color="gray.600" fontSize="sm" fontWeight={500}>
                Password
              </Text>
              <HStack alignItems="center">
                <Icon icon="lock" size={16} color={colors.grey[600]} />
                <Text
                  color={colors.singletons.darkText}
                  fontSize="sm"
                  fontWeight={400}
                  ml="8px"
                >
                  *****
                </Text>
              </HStack>
            </VStack>
            <Button
              variant="outline"
              borderColor={colors.primary[200]}
              w="154px"
              onPress={handleChangePassword}
            >
              Change password
            </Button>
          </HStack>
          <Stack space={6} mt="100px" borderRadius={4}>
            <Stack width="100%" space={3}>
              <Text fontSize="lg" fontWeight="500" color="gray.600">
                Delete account
              </Text>
              <Divider />
            </Stack>
            <Stack
              height="94px"
              width="100%"
              bgColor="warning.100"
              padding={3}
              space={1}
              borderRadius="md"
            >
              <HStack space={4} alignItems="center">
                <Icon
                  icon="alert-triangle"
                  size={24}
                  color={colors.warning[700]}
                />
                <Text fontWeight="500" fontSize="sm" color="gray.800">
                  Important notice!
                </Text>
              </HStack>
              <Text maxWidth="500px" paddingLeft={10}>
                Deleting your account will result in losing access to all
                registered activities, bookings and volunteers.
              </Text>
            </Stack>
            <Button
              width="157px"
              variant="outline"
              borderColor="error.600"
              leftIconName="trash"
              leftIconColor={colors.error[600]}
              leftIconSize={16}
              mt={4}
              onPress={handleOpenDeleteAccount}
            >
              <Text color="error.600">Delete account</Text>
            </Button>
          </Stack>
          <HStack
            mt="60px"
            marginBottom={1}
            w="93%"
            justifyContent="space-around"
            alignItems="center"
          >
            <Button
              width="100px"
              variant="link"
              fontSize="12px"
              fontWeight={500}
              onPress={handleOpenTermsOfService}
            >
              <Text color="singleton.black" fontWeight={500} underline>
                Terms of service
              </Text>
            </Button>
            <Button
              width="100px"
              variant="link"
              fontSize="12px"
              fontWeight={500}
              onPress={handleOpenPrivacyPolicy}
            >
              <Text color="singleton.black" fontWeight={500} underline>
                Privacy Policy
              </Text>
            </Button>
          </HStack>
        </Stack>
        <Stack
          justifyContent="space-between"
          width="50%"
          bgColor="white"
          p={6}
          borderRadius="xl"
          minHeight="60vh"
        >
          <EmailPreferences />
        </Stack>
      </HStack>
      <ModalTermsOfService modalRef={modalTermsOfServiceRef} />
      <ModalPrivacyPolicy modalRef={modalPrivacyPolicyRef} />
      <ModalDeleteAccount
        isFetching={isDeletingAccount}
        closeModal={handleCloseDeleteAccount}
        modalRef={modalDeleteAccountRef}
        confirmAction={handleConfirmDeleteAccount}
      />
    </Scaffold>
  );
};
