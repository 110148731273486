import { Alert, Button, Stack, Text } from 'native-base';
import { FieldErrors } from 'react-hook-form';

import { IDatesDTO } from '~/types/dtos';

import { MultipleLocationsOngoingForm } from '../components/MultipleLocationsOngoingForm';
import { MultipleLocationsOngoingProps } from './types';
import { useMultipleLocationsOngoingController } from './useMultipleLocationsOngoingController';

export const MultipleLocationsOngoing = (
  props: MultipleLocationsOngoingProps,
) => {
  const {
    control,
    locationsGroupsFields,
    addLocationGroup,
    deleteLocationGroup,
    appendLocationGroup,
    isDisabledPublishButton,
    errors,
    handleSubmitForm,
    handleBack,
    isLoading,
    locationsByGroup,
    handleUpdateLocationGroup,
    handleDeleteAllLocationsFromGroup,
    handleDeleteLocationFromGroup,
    updateNoVolunteerLimitValue,
    setValue,
    isEditing,
  } = useMultipleLocationsOngoingController(props);

  return (
    <Stack>
      <Alert
        padding={3}
        backgroundColor="warning.100"
        status="warning"
        borderRadius="md"
        mt={6}
      >
        <Text>
          If there are duplicated addresses or postcodes, only one will be shown
          on the list and used to create an activity
        </Text>
      </Alert>
      <MultipleLocationsOngoingForm
        control={control as any}
        locationGroupsFields={locationsGroupsFields as any}
        appendLocationGroup={appendLocationGroup as any}
        addLocationGroup={addLocationGroup}
        removeLocationGroup={deleteLocationGroup}
        errors={errors as FieldErrors<{ [key: string]: IDatesDTO[] }>}
        locationTagsByGroupIndex={locationsByGroup}
        handleUpdateLocationGroup={handleUpdateLocationGroup}
        handleDeleteAllLocationsFromGroup={handleDeleteAllLocationsFromGroup}
        handleDeleteLocationFromGroup={handleDeleteLocationFromGroup}
        updateNoVolunteerLimitValue={updateNoVolunteerLimitValue}
        setValue={setValue}
        isEditing={isEditing}
      />

      <Stack
        my={4}
        w="100%"
        flexDirection="row"
        justifyContent="space-between"
        alignItems="center"
        space={3}
      >
        <Stack flex={1} ml={4}>
          <Button onPress={handleBack} flex={1} variant="ghost">
            <Text color="primary.600">Back</Text>
          </Button>
        </Stack>
        <Stack flex={1} ml={4}>
          <Button
            onPress={handleSubmitForm}
            flex={1}
            isDisabled={isDisabledPublishButton}
            isLoading={isLoading}
          >
            <Text color="white">Publish</Text>
          </Button>
        </Stack>
      </Stack>
    </Stack>
  );
};
