import { Box, HStack, Input, Stack, Text, VStack } from 'native-base';
import React from 'react';
import { Controller } from 'react-hook-form';
import CircularUser from 'ui/assets/icons/circular-user.svg';
import Button from 'ui/components/Button';
import Icon from 'ui/components/Icon';

import { HelpCentreRedirectLink } from '~/components/HelpCentre';
import { colors } from '~/theme/colors';

import { IInviteAdminFormProps } from '../types';
import { styleConfig } from './styleConfig';
import { isValidEmail } from './utils';

const InviteAdminForm = ({
  control,
  isDisabledButton,
  isSubmitting,
  adminEmails,
  onAddEmail,
  onRemoveEmail,
  onSubmit,
  handleBack,
}: IInviteAdminFormProps) => {
  return (
    <Stack space={5} mt={8} p={2} w="100%">
      <Stack paddingRight={6}>
        <HelpCentreRedirectLink />
      </Stack>
      <Stack w="100%" space={2}>
        <Text fontSize="20px" color="gray.600" fontWeight={500}>
          Admins
        </Text>
        <Text color="gray.600" fontWeight={400} fontSize="sm">
          {"Invite people to be your organisation's admins."}
        </Text>
      </Stack>

      <Box mt={6}>
        <Text color="gray.600" fontWeight={500} mb="2">
          Add Admins
        </Text>
        <Controller
          control={control}
          name="adminEmail"
          render={({ field: { value, onChange } }) => (
            <HStack alignItems="center" space={3} w="full">
              <Input
                w="full"
                flexShrink="1"
                placeholder="Enter admin’s email address"
                value={value}
                onChange={onChange}
              />
              <Button
                flex="1"
                py="6"
                borderWidth="1"
                borderColor="primary.600"
                leftIconName="plus-circle"
                variant="outline"
                leftIconColor={colors.primary[600]}
                isDisabled={!value || !isValidEmail(value)}
                onPress={onAddEmail(value)}
              >
                <Text color="primary.600" fontSize={16} fontWeight="500">
                  Add
                </Text>
              </Button>
            </HStack>
          )}
        />
        <VStack w="100%" mt={8} space="4" px={2} maxH="256px" overflowY="auto">
          {adminEmails.map(({ id, email }, index) => (
            <HStack
              key={id}
              pb="4"
              borderBottomWidth="1"
              borderBottomColor="gray.300"
              justifyContent="space-between"
              alignItems="baseline"
            >
              <HStack alignItems="center" space="3">
                <img src={CircularUser} />
                <Text color="gray.600">{email}</Text>
              </HStack>
              <HStack space={3} alignItems="center">
                <Icon icon="x-circle" color={colors.error[600]} size="18" />
                <Text
                  color="red.600"
                  fontWeight="500"
                  onPress={onRemoveEmail(index)}
                >
                  Remove
                </Text>
              </HStack>
            </HStack>
          ))}
        </VStack>
      </Box>

      <HStack
        w="full"
        flexDirection={styleConfig.buttonStackDirection}
        alignItems="center"
        space={2}
        mt="40px"
      >
        <Button
          w={styleConfig.buttonWidth}
          variant="ghost"
          onPress={handleBack}
        >
          Back
        </Button>

        <Button
          w={styleConfig.buttonWidth}
          isDisabled={isDisabledButton || isSubmitting}
          isLoading={isSubmitting}
          onPress={onSubmit}
        >
          Register organisation
        </Button>
      </HStack>
    </Stack>
  );
};

export default React.memo(InviteAdminForm);
