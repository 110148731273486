import { Box, Divider, HStack, Skeleton, Stack, Text } from 'native-base';
import DataTable from 'ui/components/DataTable';
import { ReportCards } from 'ui/components/ReportCards';

import { ModalActionReport } from '~/components/ModalActionReport';
import { colors } from '~/theme/colors';
import { secondsToHoursMinutesString } from '~/utils/functions';

import { useChildOrgActivitiesController } from './controller';
import { tableActivitiesColumns } from './tableActivitiesColumns';
import { defaultChildOrgActivitiesFilterValue } from './utils';

export const ChildOrgActivities = () => {
  const {
    isLoadingReports,
    actionReportModalRef,
    openModal,
    loadChildOrgActivities,
  } = useChildOrgActivitiesController();

  return (
    <Stack
      bgColor="white"
      borderRadius="3xl"
      width="100%"
      space={5}
      padding={6}
    >
      <Stack space={2}>
        <Text fontSize="lg" fontWeight="500" color="gray.600">
          Activities Report
        </Text>
        <Text fontSize="sm" fontWeight={400} color="gray.600">
          {"Keep track of this organisation's activities"}
        </Text>
        <Divider />
      </Stack>
      <HStack space={6} width="100%" justifyContent="space-between">
        <Box width="23.2%">
          <ReportCards
            withBorder
            icon="clock"
            title="Hours logged"
            value="264h 00m"
            valueInfo="Total hours achieved"
            dividerColor={colors.singletons.darkBlue}
            iconColor={colors.singletons.darkBlue}
            paddingTitle={0}
            cardHeight="40px"
            isLoading={false}
          />
        </Box>
        <Box width="23.2%">
          <ReportCards
            withBorder
            icon="calendar"
            value={72}
            valueInfo="Applications"
            title="Events"
            dividerColor="lime.500"
            iconColor={colors.lime['500']}
            paddingTitle={0}
            cardHeight="40px"
            isLoading={isLoadingReports}
            direction="column"
            contentChildren={
              <HStack space={2} alignItems="baseline">
                {isLoadingReports ? (
                  <Box w="50%" fontSize="2xl">
                    <Skeleton />
                  </Box>
                ) : (
                  <>
                    <Text fontSize="xl" fontWeight="700" color="gray.900">
                      108h 00m
                    </Text>
                    <HStack alignItems="center" space={1}>
                      <Text fontSize="xs" color="gray.500" fontWeight="500">
                        time in events
                      </Text>
                    </HStack>
                  </>
                )}
              </HStack>
            }
          />
        </Box>
        <Box width="23.2%">
          <ReportCards
            withBorder
            icon="puzzle-tertiary"
            value={108}
            valueInfo="Applications"
            title="Actions"
            dividerColor="orange.500"
            iconColor={colors.orange['500']}
            isIconCustom
            paddingTitle={0}
            cardHeight="40px"
            isLoading={isLoadingReports}
            actionButtonName="More"
            action={openModal}
            direction="column"
            contentChildren={
              <HStack space={2} alignItems="baseline">
                {isLoadingReports ? (
                  <Box w="50%" fontSize="2xl">
                    <Skeleton />
                  </Box>
                ) : (
                  <>
                    <Text fontSize="xl" fontWeight="700" color="gray.900">
                      36h 00m
                    </Text>
                    <HStack alignItems="center" space={1}>
                      <Text fontSize="xs" color="gray.500" fontWeight="500">
                        time in actions
                      </Text>
                    </HStack>
                  </>
                )}
              </HStack>
            }
          />
        </Box>
        <Box width="23.2%">
          <ReportCards
            withBorder
            icon="repeat"
            value={60}
            valueInfo="Applications"
            title="Ongoing opportunities"
            dividerColor="purple.500"
            iconColor={colors.purple['500']}
            paddingTitle={0}
            cardHeight="40px"
            isLoading={isLoadingReports}
            direction="column"
            contentChildren={
              <HStack space={2} alignItems="baseline">
                {isLoadingReports ? (
                  <Box w="50%" fontSize="2xl">
                    <Skeleton />
                  </Box>
                ) : (
                  <>
                    <Text fontSize="xl" fontWeight="700" color="gray.900">
                      120h 00m
                    </Text>
                    <HStack alignItems="center" space={1}>
                      <Text fontSize="xs" color="gray.500" fontWeight="500">
                        time in ongoing op.
                      </Text>
                    </HStack>
                  </>
                )}
              </HStack>
            }
          />
        </Box>
      </HStack>

      <Stack space={8}>
        <Stack space={2}>
          <Text fontWeight={500} fontSize="2xl" color="gray.600">
            25 activities
          </Text>
          <Text fontWeight={400} fontSize="sm" color="gray.600">
            Total number of activities.
          </Text>
        </Stack>
        <DataTable
          defaultFilterValues={defaultChildOrgActivitiesFilterValue}
          loadData={loadChildOrgActivities}
          columns={tableActivitiesColumns}
        />
      </Stack>

      <ModalActionReport
        modalRef={actionReportModalRef}
        headerText="Actions"
        totalTimeLoggedInAction={secondsToHoursMinutesString(250)}
        totalApplications={43}
      />
    </Stack>
  );
};
