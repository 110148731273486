import { ActivitySearchTile } from 'ui/components/v2/Tiles/ActivitySearchTile';
import { ActivityType } from 'ui/types/activities';

import { NotificationCard } from '../../BaseNotificationCard';
import { useActivityCreatedNotificationCardController } from './controller';
import { ActivityCreatedNotificationCardProps } from './types';

export const ActivityCreatedNotificationCard = (
  props: ActivityCreatedNotificationCardProps,
) => {
  const {
    activityType,
    activityTitle,
    activityStartDate,
    activityCoverImageUrl,
    activityOrganizationName,
    activityOrganizationLogoUrl,
    activityTargetAmount,
    activityMeasurementUnit,
  } = props;

  const { titleNodes, date, organizationLogoUrl, onActivityPress } =
    useActivityCreatedNotificationCardController(props);

  return (
    <NotificationCard
      authorImageUrl={organizationLogoUrl}
      titleNodes={titleNodes}
      date={date}
    >
      {activityType === ActivityType.Ongoing ? (
        <ActivitySearchTile.Ongoing
          title={activityTitle}
          organizationName={activityOrganizationName}
          coverImageUrl={activityOrganizationLogoUrl}
          onPress={onActivityPress}
        />
      ) : (
        <ActivitySearchTile.Common
          title={activityTitle}
          activityType={activityType}
          activityStartDate={activityStartDate}
          organizationName={activityOrganizationName}
          activityTargetAmount={activityTargetAmount}
          measurementUnit={activityMeasurementUnit}
          coverImageUrl={activityCoverImageUrl}
          onPress={onActivityPress}
        />
      )}
    </NotificationCard>
  );
};
