import { useCallback, useEffect, useMemo, useState } from 'react';
import { FileRejection, useDropzone } from 'react-dropzone';
import { toast } from 'react-toastify';

import { CustomFile, DropzoneProps, IMAGES_ZONE_ERROR } from './types';
import {
  DEFAULT_FILE_ERROR,
  FILE_REMOVE_ERROR,
  INVALID_FILE_FORMAT_ERROR,
  TOO_LARGE_FILE_ERROR,
  TOO_MANY_FILES_ERROR,
  TOO_SMALL_FILE_ERROR,
  TOO_SMALL_FILE_HEIGHT_ERROR,
  TOO_SMALL_FILE_WIDTH_ERROR,
} from './utils';

const useDropzoneController = ({
  files,
  deleteImageFn,
  errors,
  register,
  setValue,
  maxFiles = 1,
  multiple = false,
  showTitle = true,
  defaultImage,
  handleDropFile,
  handleRemoveFile,
  acceptedSize = 5,
  filesField = 'images',
  filesAccepted,
  thumbnailImages,
  acceptedHeight = 430,
  acceptedWidth = 1200,
  resetState,
}: DropzoneProps) => {
  const [filesInZone, setFilesInZone] = useState<CustomFile[]>([]);
  const [thumbnails, setThumbnails] = useState<string[]>(thumbnailImages ?? []);

  const onDropAccepted = useCallback(
    async (droppedContent) => {
      for (const content of droppedContent) {
        if (content.size > acceptedSize * 1024 * 1024) {
          toast.error(TOO_LARGE_FILE_ERROR);
          return;
        }

        // Validate Image Dimensions
        const image = new Image();
        image.src = URL.createObjectURL(content);
        await image.decode();

        if (image.width < acceptedWidth) {
          toast.error(`${TOO_SMALL_FILE_WIDTH_ERROR}${acceptedWidth}px!`);
          return;
        }

        if (image.height < acceptedHeight) {
          toast.error(`${TOO_SMALL_FILE_HEIGHT_ERROR}${acceptedHeight}px!`);
          return;
        }
      }
      const filesOnDrop = droppedContent.map((file: File) =>
        Object.assign(file, {
          image: URL.createObjectURL(file),
          isCover: false,
        }),
      );

      if (!multiple) {
        setFilesInZone([...filesOnDrop]);
        if (handleDropFile) {
          handleDropFile(filesOnDrop);
        }
      } else {
        setFilesInZone((file) => [...file, ...filesOnDrop]);
        setThumbnails([]);
        if (handleDropFile) {
          handleDropFile([...filesInZone, ...filesOnDrop]);
        }
      }
    },
    [
      handleDropFile,
      multiple,
      acceptedSize,
      filesInZone,
      acceptedHeight,
      acceptedWidth,
    ],
  );

  const onDropRejected = useCallback(
    (rejections: FileRejection[]) => {
      const errors = rejections[0]?.errors;
      const errorMessage = errors[0]?.code;
      let errMessage = DEFAULT_FILE_ERROR;

      switch (errorMessage) {
        case IMAGES_ZONE_ERROR.TOO_SMALL_FILE:
          errMessage = TOO_SMALL_FILE_ERROR;
          break;
        case IMAGES_ZONE_ERROR.TOO_LARGE_FILE:
          errMessage = TOO_LARGE_FILE_ERROR;
          break;
        case IMAGES_ZONE_ERROR.TOO_MANY_FILES:
          errMessage = TOO_MANY_FILES_ERROR(maxFiles);
          break;
        case IMAGES_ZONE_ERROR.INVALID_FILE_FORMAT:
          errMessage = INVALID_FILE_FORMAT_ERROR;
          break;
      }
      toast.error(errMessage);
    },
    [maxFiles],
  );

  const { getRootProps, getInputProps } = useDropzone({
    useFsAccessApi: false,
    multiple,
    maxFiles,
    onDropAccepted,
    onDropRejected,
  });

  const deleteImage = (imageId: number) => {
    try {
      if (deleteImageFn) deleteImageFn(imageId);
    } catch (err) {
      toast.error(FILE_REMOVE_ERROR);
    }
  };

  const handleDeleteFile = (fileToDelete: string) => {
    const fileIndex = filesInZone.findIndex(
      (file) => file.image === fileToDelete || file.name === fileToDelete,
    );
    if (fileIndex >= 0) {
      const updatedFiles = [...filesInZone];
      const isNewImage = !Object.keys(updatedFiles[fileIndex]).includes('id');
      if (!isNewImage) {
        const imageId = updatedFiles[fileIndex]?.id;
        if (imageId && deleteImageFn) {
          deleteImage(imageId);
        } else {
          toast.error(FILE_REMOVE_ERROR);
        }
      }
      updatedFiles.splice(fileIndex, 1);
      setFilesInZone(updatedFiles);

      if (handleRemoveFile) {
        handleRemoveFile(updatedFiles, fileIndex);
      }
    } else {
      typeof resetState === 'function' && resetState();
    }
  };

  useEffect(() => {
    if (files) setFilesInZone(files as CustomFile[]);
  }, [files]);

  useEffect(() => {
    if (register) register(filesField);
  }, [register, filesField]);

  useEffect(() => {
    // Verify if we have an defaultImage (this is the input value) and verify if it's a File type, so when we change the cover image
    // we can preview the cover image. For image returned from the api (string), it's another type and we handle on the previewImage below.
    if (
      typeof defaultImage !== 'string' &&
      defaultImage?.length &&
      defaultImage[0]?.image
    ) {
      setFilesInZone([...defaultImage]);
    }
  }, [defaultImage, setFilesInZone]);

  const previewImage = useMemo(() => {
    if (filesInZone?.length && filesInZone[0]?.image) {
      return filesInZone[0]?.image;
    } else {
      if (typeof defaultImage === 'string') {
        return defaultImage;
      }
    }
  }, [filesInZone, defaultImage]);

  return {
    files,
    deleteImageFn,
    errors,
    register,
    setValue,
    maxFiles,
    multiple,
    showTitle,
    handleDropFile,
    handleRemoveFile,
    acceptedSize,
    filesField,
    filesAccepted,
    filesInZone,
    handleDeleteFile,
    getRootProps,
    previewImage,
    getInputProps,
    onDropAccepted,
    onDropRejected,
    thumbnails,
    acceptedHeight,
    acceptedWidth,
  };
};

export default useDropzoneController;
