import { Image, Stack } from 'native-base';
import Button from 'ui/components/Button';
import Icon from 'ui/components/Icon';
import { colors } from 'ui/theme/colors';

import { ThumbnailMiniItemProps } from './types';
export const ThumbnailMiniItem = ({
  key,
  imageUrl,
  handleDeleteFile,
  imageItem,
}: ThumbnailMiniItemProps) => {
  return (
    <Stack
      key={key}
      m={1}
      borderWidth={1}
      borderRadius={12}
      borderColor="primary.100"
      overflow="hidden"
    >
      <Button
        bgColor="white"
        onPress={() => handleDeleteFile(imageItem)}
        position="absolute"
        right={1}
        top={1}
        zIndex={10}
        maxW="1.5rem"
        minW="1.5rem"
        w="1.5rem"
        h="1.5rem"
      >
        <Icon icon="trash" size={18} color={colors.error[600]} />
      </Button>
      <Image src={imageUrl} width="8rem" height="8rem" />
    </Stack>
  );
};
