import {
  TableOperatorNames,
  TableOperatorType,
} from 'ui/components/DataTable/types';

import { colors } from '~/theme/colors';

export const REPORT_INITIAL_STATE = {
  events: {
    totalPublishedEvents: '-',
    totalConfirmedAttendees: '-',
    totalBookings: '-',
  },
  actions: {
    totalPublishedActions: '-',
    totalJoins: '-',
  },
  ongoing: {
    totalPublishedOngoings: '-',
    totalApplications: '-',
  },
};

export const defaultActivitiesListFilterValue = [
  {
    name: 'title',
    operator: TableOperatorNames.Contains,
    type: TableOperatorType.String,
    value: '',
  },
  {
    name: 'app',
    operator: TableOperatorNames.Equals,
    type: TableOperatorType.String,
    value: '',
  },
  {
    name: 'type',
    operator: TableOperatorNames.Equals,
    type: TableOperatorType.String,
    value: '',
  },
  {
    name: 'locationOption',
    operator: TableOperatorNames.Equals,
    type: TableOperatorType.String,
    value: '',
  },
  {
    name: 'applications',
    operator: TableOperatorNames.GreaterThanOrEquals,
    type: TableOperatorType.Number,
    value: '',
  },
];
