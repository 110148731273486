import { useMemo } from 'react';
import { formatMeasurementAmount } from 'ui/utils/measurements/format';

import { MeasurementCategoryIconMap } from './constants';
import { BaseLogProgressTileProps } from './types';

export const useBaseLogProgressTileController = ({
  measurementUnit,
  progressValue,
  targetValue,
}: BaseLogProgressTileProps) => {
  const { category } = measurementUnit;

  const formattedValues = useMemo(() => {
    const amountStr = formatMeasurementAmount(progressValue, measurementUnit);

    const targetStr = targetValue
      ? formatMeasurementAmount(targetValue, measurementUnit)
      : '';

    return { amountStr, targetStr };
  }, [targetValue, progressValue, measurementUnit]);

  const measurementIcon = MeasurementCategoryIconMap[category] || 'clock';

  return {
    formattedValues,
    measurementIcon,
    targetValue,
    progressValue,
  };
};
