import { useState } from 'react';

import { AppSectionProps } from './types';

export const useAppSectionController = ({ appsList }: AppSectionProps) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleViewPortTrigger = () => {
    setIsOpen((prev) => !prev);
  };

  const viewportMaxHeight = isOpen ? 'unset' : '112px';
  const showTriggerButton = appsList.length > 4;
  return {
    viewportMaxHeight,
    showTriggerButton,
    toggleViewPortTrigger,
    isOpen,
  };
};
