import { Divider, Stack, Text } from 'native-base';
import DataTable from 'ui/components/DataTable';

import { useChildOrgMembersController } from './controller';
import { tableMembersColumns } from './tableMembersColumns';
import { defaultChildOrgMembersFilterValue } from './utils';

export const ChildOrgMembers = () => {
  const { loadChildOrgMembers } = useChildOrgMembersController();

  return (
    <Stack
      bgColor="white"
      borderRadius="3xl"
      width="100%"
      space={5}
      padding={6}
    >
      <Stack space={2}>
        <Text fontSize="lg" fontWeight="500" color="gray.600">
          500 Members
        </Text>
        <Text fontSize="sm" fontWeight={400} color="gray.600">
          Total number of members
        </Text>
        <Divider />
      </Stack>

      <Stack space={8}>
        <Stack space={2}>
          <Text fontWeight={500} fontSize="2xl" color="gray.600">
            25 activities
          </Text>
          <Text fontWeight={400} fontSize="sm" color="gray.600">
            Total number of activities.
          </Text>
        </Stack>
        <DataTable
          defaultFilterValues={defaultChildOrgMembersFilterValue}
          loadData={loadChildOrgMembers}
          columns={tableMembersColumns}
        />
      </Stack>
    </Stack>
  );
};
