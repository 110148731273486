import { HStack, Stack, Text, VStack } from 'native-base';
import Button from 'ui/components/Button';
import CardPropInfo from 'ui/components/Cards/CardPropInfo';
import Icon from 'ui/components/Icon';
import Tabs from 'ui/components/Tabs';
import { EEventApplicationType, LocationOptionsEnum } from 'ui/enums';

import { Loading } from '~/components/Loading';
import ModalDelete from '~/components/ModalDelete';
import Scaffold from '~/components/Scaffold';
import { ActivityTypeEnum } from '~/enums';
import { colors } from '~/theme/colors';

import { AboutActivity } from './AboutActivity';
import { ActivityApplications } from './ActivityApplications';
import { useActivityDetailsController } from './controller';
import { styles } from './styles';
import { ActivityDetailsDetailsTabPages, ActivityDetailsProps } from './types';
import { renderCategoryAndCauseString } from './utils';

export const ActivityDetails = ({ activityType }: ActivityDetailsProps) => {
  const {
    tabsRef,
    handleChangeTab,
    tabElements,
    currentTab,
    isLoadingScreen,
    isLoadingActivity,
    activityDefinition,
    activities,
    handleEditActivity,
    loadActivitiesList,
    measurementUnitObject,
    activityId,
    modalRef,
    handleDelete,
    handleCloseModal,
    handleOpenModal,
    activityTypeText,
    handleShare,
    isOrganizationOwnerOfActivity,
    activityUrl,
    appId,
    activityPermissions,
    isAppAdmin,
    locationOption,
    mappedRegionsForLocationDetails,
    meetingLink,
    shouldShowLocationInfo,
    advertiseRegionOption,
    hasExternalLink,
  } = useActivityDetailsController({ activityType });

  const renderTabs = () => {
    return {
      [ActivityDetailsDetailsTabPages.About]: (
        <AboutActivity
          appId={appId}
          activityPermissions={activityPermissions}
          isAppAdmin={isAppAdmin}
          activityType={activityType}
          activityDefinition={activityDefinition}
          loadActivitiesList={loadActivitiesList}
          measurementUnitObject={measurementUnitObject}
          handleEditActivity={handleEditActivity}
          locationOption={locationOption}
          mappedRegionsForLocationDetails={mappedRegionsForLocationDetails}
          advertiseRegionOption={advertiseRegionOption}
        />
      ),
      [ActivityDetailsDetailsTabPages.Applications]: (
        <ActivityApplications
          activityId={activityId as string}
          eventApplicationType={
            activityDefinition.eventApplicationType as EEventApplicationType
          }
          hasExternalLink={hasExternalLink}
        />
      ),
    }[currentTab];
  };

  return isLoadingScreen ? (
    <Loading />
  ) : (
    <Scaffold
      title={activityDefinition.title}
      showActivityTypeIcon
      activityType={activityType}
      eventApplicationType={activityDefinition.eventApplicationType}
      actionLabel="New activity"
      alignItemsChildren="center"
      withBg={false}
      actionComponents={
        <HStack space={2}>
          {isOrganizationOwnerOfActivity ? (
            // disable permissions validation for now
            // <Permissions.ValidateApp
            //   appIdToValidate={appId}
            //   requiredPermissions={activityPermissions}
            //   disablePermissionsValidation={isAppAdmin}
            // >
            <Button
              w="155px"
              borderRadius={12}
              py={2}
              px={3}
              minW="auto"
              leftIconName="x-circle"
              alignItems="center"
              leftIconColor={colors.error[600]}
              variant="outline"
              borderColor={colors.error[400]}
              onPress={handleOpenModal}
            >
              <Text color={colors.error[600]}>Cancel activity</Text>
            </Button>
          ) : // </Permissions.ValidateApp>
          null}
          <Button
            w="81px"
            borderRadius={12}
            py={2}
            px={3}
            minW="auto"
            leftIconName="share-2"
            alignItems="center"
            leftIconColor={colors.primary[400]}
            variant="outline"
            onPress={handleShare}
          >
            <Text color={colors.primary[400]}>Share</Text>
          </Button>
          {isOrganizationOwnerOfActivity ? (
            // <Permissions.ValidateApp
            //   appIdToValidate={appId}
            //   requiredPermissions={activityPermissions}
            // >
            <Button
              w="81px"
              borderRadius={12}
              py={2}
              px={3}
              minW="auto"
              leftIconName="edit"
              alignItems="center"
              leftIconColor={colors.white}
              onPress={() => handleEditActivity()}
            >
              <Text color={colors.white}>Edit</Text>
            </Button>
          ) : // </Permissions.ValidateApp>
          null}
        </HStack>
      }
    >
      <HStack w={'full'} space={5} mb={10}>
        <CardPropInfo
          justifyContent="flex-start"
          icon="heart"
          info={renderCategoryAndCauseString(
            'Volunteering',
            activityDefinition.causeOptions,
          )}
          iconColor={colors.gray[500]}
          textColor={colors.gray[500]}
          maxWidth={activities.length > 1 ? '75%' : '40%'}
        />

        <CardPropInfo
          justifyContent="flex-start"
          icon="map-pin"
          info={locationOption}
          iconColor={colors.gray[500]}
          textColor={colors.gray[500]}
          maxW={'15%'}
        />
        {shouldShowLocationInfo() ? (
          <CardPropInfo
            justifyContent="flex-start"
            icon="map"
            info={
              locationOption === LocationOptionsEnum.FromHome
                ? meetingLink
                : activities[0].address.street || ''
            }
            iconColor={colors.gray[500]}
            textColor={colors.gray[500]}
            maxWidth="40%"
            isLink
            link={
              locationOption === LocationOptionsEnum.FromHome
                ? meetingLink
                : `https://www.google.com/maps/search/${activities[0].address.street}`
            }
          />
        ) : null}

        <HStack space={2} justifyContent="space-between">
          <Icon icon="eye" size={20} color={colors.gray[500]} />
          <a
            href={activityUrl}
            target="_blank"
            style={styles.volunteerLink}
            rel="noreferrer"
          >
            <Text color="primary.500">View on volunteer</Text>
          </a>
        </HStack>
      </HStack>
      <VStack w="full" alignItems="center" pb={10}>
        <Tabs
          ref={tabsRef}
          tabBgColor="transparent"
          onChangeTab={handleChangeTab}
          tabWith="100%"
          tabTextColor={colors.primary['600']}
          tabIndicatorColor={colors.primary['600']}
          tabfocusedTextColor={colors.primary['600']}
          isClickableTab
          swipeEnabled={false}
          elements={tabElements}
          currentTab={currentTab}
        />
      </VStack>
      <VStack w="full">{renderTabs()}</VStack>
      <ModalDelete
        modalRef={modalRef}
        handleCloseModal={handleCloseModal}
        handleDelete={handleDelete}
        headerText={`Cancel ${activityTypeText}`}
        rightBtnLoading={isLoadingActivity}
        rightBtnText="Yes, cancel"
      >
        <Stack space="5">
          <Text>
            Are you sure that you want to cancel this {activityTypeText}?
          </Text>
          {[
            ActivityTypeEnum.Event,
            ActivityTypeEnum.OngoingOpportunity,
          ].includes(activityType) ? (
            <Text>
              If you proceed with this action, all Places associated with this{' '}
              {activityTypeText} will be canceled. You will not be able to undo
              this.
            </Text>
          ) : (
            <Text>You will not be able to undo this action.</Text>
          )}
        </Stack>
      </ModalDelete>
    </Scaffold>
  );
};
