import { useQuery } from '~/hooks/useQuery';
import { QueryParams } from '~/services/resources/types';
import { IPaginatedResponse } from '~/types';
import { UserProfile } from '~/types/interfaces/userProfile';

export const useLoadMembersByEcosystem = (
  ecosystemId: string,
  params?: QueryParams,
) => {
  const { data, isLoading } = useQuery<IPaginatedResponse<UserProfile[]>>(
    `/ecosystem/${ecosystemId}/members`,
    {
      queryOptions: {
        enabled: !!ecosystemId && !!params,
      },
      requestOptions: {
        params,
      },
      key: `load-members-by-ecosystem-${ecosystemId}-${params?.filter}`,
    },
  );

  return { data, isLoading };
};
