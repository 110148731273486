import { useQuery } from '~/hooks/useQuery';

import { EcosystemNetworkConnectionsPopulated } from '../../types/interfaces/ecosystem-network-connections';

export const useLoadConnection = (connectionId: string) => {
  const { data, isLoading, isRefetching } =
    useQuery<EcosystemNetworkConnectionsPopulated>(
      `ecosystem-network-connection/${connectionId}`,
      {
        key: `ecosystem-network-connection-${connectionId}`,
      },
    );

  return { data, isLoading, isRefetching };
};
