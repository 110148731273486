import { Control, useController } from 'react-hook-form';

export const useExternalApplyLinkInputController = (
  name: string,
  control: Control,
) => {
  const { field } = useController({
    name,
    control,
  });

  return { field };
};
