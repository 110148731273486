import {
  AdvertiseRegionOptionsEnum,
  EActivityAttendanceTypes,
  EActivityCategories,
  ESpaceOptionsKeys,
  ETypeOfWorkKeys,
  LocationOptionsEnum,
} from 'ui/enums';
import { ValidationsRegex } from 'ui/utils/validations/validations';

//---- Dictionaries ----//

export const attendanceTypeOptionsDictionary = {
  [EActivityAttendanceTypes.Remote]: 'Online',
  [EActivityAttendanceTypes.InPerson]: 'In person',
};

export const spaceOptionsDictionary = {
  [ESpaceOptionsKeys.Indoor]: 'Indoor',
  [ESpaceOptionsKeys.Outdoor]: 'Outdoor',
  [ESpaceOptionsKeys.Both]: 'Both',
};

export const typeOfWorkOptionsDictionary = {
  [ETypeOfWorkKeys.Alone]: 'Alone',
  [ETypeOfWorkKeys.Group]: 'Group',
  [ETypeOfWorkKeys.Both]: 'Both',
};

export const activityCategoryDictionary = {
  [EActivityCategories.Volunteering]: 'Volunteering',
};

export const locationOptionsDictionary = {
  [LocationOptionsEnum.SingleLocation]: 'Single Location',
  [LocationOptionsEnum.MultipleLocations]: 'Multiple Locations',
};

//---- Select Objects ----//

export const attendanceTypeOptions = [
  {
    text: attendanceTypeOptionsDictionary[EActivityAttendanceTypes.InPerson],
    value: EActivityAttendanceTypes.InPerson,
  },
  {
    text: attendanceTypeOptionsDictionary[EActivityAttendanceTypes.Remote],
    value: EActivityAttendanceTypes.Remote,
  },
];

export const spaceOptions = [
  {
    text: spaceOptionsDictionary[ESpaceOptionsKeys.Indoor],
    value: ESpaceOptionsKeys.Indoor,
  },
  {
    text: spaceOptionsDictionary[ESpaceOptionsKeys.Outdoor],
    value: ESpaceOptionsKeys.Outdoor,
  },
  {
    value: ESpaceOptionsKeys.Both,
    text: spaceOptionsDictionary[ESpaceOptionsKeys.Both],
  },
];

export const typeOfWorkOptions = [
  {
    text: typeOfWorkOptionsDictionary[ETypeOfWorkKeys.Alone],
    value: ETypeOfWorkKeys.Alone,
  },
  {
    text: typeOfWorkOptionsDictionary[ETypeOfWorkKeys.Group],
    value: ETypeOfWorkKeys.Group,
  },
  {
    text: typeOfWorkOptionsDictionary[ETypeOfWorkKeys.Both],
    value: ETypeOfWorkKeys.Both,
  },
];

export const activityCategoryOptions = [
  {
    label: activityCategoryDictionary[EActivityCategories.Volunteering],
    value: 'volunteering',
  },
];

export const locationOptions = [
  {
    text: LocationOptionsEnum.SingleLocation,
    value: LocationOptionsEnum.SingleLocation,
    description: 'If the activity will happen in a specific location.',
  },
  {
    text: LocationOptionsEnum.MultipleLocations,
    value: LocationOptionsEnum.MultipleLocations,
    description: 'If the activity will happen in more than one location.',
  },
  {
    text: LocationOptionsEnum.FromHome,
    value: LocationOptionsEnum.FromHome,
    description: 'If the activity don’t have a specific location',
  },
];

export const activityRegionOptions = [
  {
    text: AdvertiseRegionOptionsEnum.AnywhereInUK,
    value: AdvertiseRegionOptionsEnum.AnywhereInUK,
  },
  {
    text: AdvertiseRegionOptionsEnum.SelectRegions,
    value: AdvertiseRegionOptionsEnum.SelectRegions,
  },
];

export const dateAndLocationOptions = [
  { value: 'true', text: 'Yes' },
  { value: 'false', text: 'No' },
];

export const noLimitOptionsCheck = [
  { value: false, text: 'No volunteer limit' },
];

export const eventApprovalRequiredOptions = [
  {
    text: 'Yes',
    value: 'yes',
  },
  {
    text: 'No',
    value: 'no',
  },
];

// Regexes for Yup Match

export const meetingLinkRegex = ValidationsRegex.Url;
