export enum MongoOperators {
  Regex = '$regex',
  Equals = '$eq',
  NotEquals = '$ne',
  Exists = '$exists',
  NotContains = '$not',
  GreaterThan = '$gt',
  GreaterThanOrEquals = '$gte',
  LowerThan = '$lt',
  LowerThanOrEquals = '$lte',
  Options = '$options',
  Or = '$or',
}

export enum MongoOptions {
  CaseInsensitive = 'i',
}
