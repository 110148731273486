import 'react-phone-number-input/style.css';

import { Box, HStack, Stack, Text } from 'native-base';
import Button from 'ui/components/Button';

import { HelpCentreRedirectLink } from '~/components/HelpCentre';
import ModalPrivacyPolicy from '~/components/ModalPrivacyPolicy';
import ModalTermsOfService from '~/components/ModalTermsOfService';
import { CreateAccount } from '~/pages/Unauthenticated/SignUpPage/components/CreateAccount';
import { styles } from '~/pages/Unauthenticated/SignUpPage/styles';
import useSignUpController from '~/pages/Unauthenticated/SignUpPage/useSignUpController';

const SignUpPage = () => {
  const {
    isValid,
    isSubmitting,
    control,
    errors,
    modalTermsOfServiceRef,
    modalPrivacyPolicyRef,
    goBack,
    handleGoToSignIn,
    handleOnSubmit,
    handleOpenTermsOfService,
    handleOpenPrivacyPolicy,
  } = useSignUpController();

  return (
    <Box minH="100vh" bgColor="light.100">
      <Stack
        space="10"
        width="min(90%, 1280px)"
        mx="auto"
        py={styles.containerPaddingY}
      >
        <Stack>
          <Text color="gray.500" fontSize="24" fontWeight="700">
            Sign up
          </Text>
        </Stack>

        <Box
          bgColor="white"
          py={styles.cardPaddingY}
          borderRadius="xl"
          minH="700px"
          shadow="1"
        >
          <Box width="min(90%, 550px)" mx="auto">
            <HelpCentreRedirectLink />
            <Box w="100%">
              <Stack space="2" w="full" mb={6}>
                <Text color="gray.600" fontSize="20px" fontWeight="500">
                  Create your account
                </Text>

                <Text color="gray.600" fontSize="14" fontWeight="400">
                  Fill in email and password information to create your account.
                </Text>
              </Stack>
              <CreateAccount
                control={control}
                errors={errors}
                onGoToSignIn={handleGoToSignIn}
                onOpenPrivacyPolicy={handleOpenPrivacyPolicy}
                onOpenTermsOfService={handleOpenTermsOfService}
              />
              <HStack w="full" space={4} mt={8}>
                <Button flex="1" bgColor="transparent" onPress={goBack}>
                  <Text color="gray.500" fontSize={16} fontWeight="500">
                    Back
                  </Text>
                </Button>
                <Button
                  flex="1"
                  bgColor="primary.600"
                  onPress={handleOnSubmit}
                  isLoading={isSubmitting}
                  isDisabled={!isValid}
                >
                  <Text color="white" fontSize={16} fontWeight="500">
                    Continue
                  </Text>
                </Button>
              </HStack>
            </Box>
          </Box>
        </Box>
      </Stack>
      <ModalPrivacyPolicy modalRef={modalPrivacyPolicyRef} />
      <ModalTermsOfService modalRef={modalTermsOfServiceRef} />
    </Box>
  );
};

export default SignUpPage;
