import { HStack, Text, VStack } from 'native-base';
import Button from 'ui/components/Button';
import Illustration, { ILLUSTRATIONS_NAME } from 'ui/components/Illustration';

import { usePasswordResetedController } from './controller';

export const PasswordReseted = () => {
  const { handleGoToLogin } = usePasswordResetedController();
  return (
    <HStack
      minH="90vh"
      height="100%"
      width="100%"
      justifyContent="center"
      alignItems="center"
      space={8}
    >
      <Illustration
        width="496px"
        height="496px"
        name={ILLUSTRATIONS_NAME.SUCCESS_COMPLETE_CORPORATE}
        alt="PasswrdRecovery"
      />

      <VStack space={8}>
        <Text
          textAlign="initial"
          fontSize="2xl"
          fontWeight={700}
          color="gray.900"
          width="393px"
        >
          Now you can sign in with your new password!
          <br />
        </Text>

        <Button onPress={handleGoToLogin} color="primary.600" p={6}>
          <Text fontSize="medium" color="white" fontWeight="medium">
            Sign in
          </Text>
        </Button>
      </VStack>
    </HStack>
  );
};
