import { Divider, Stack, Text } from 'native-base';

import { IHeaderForm } from './types';

const FormHeader = ({ title, subTitle, isShowDivider }: IHeaderForm) => {
  if (!title) {
    return null;
  }

  return (
    <Stack>
      <Text fontSize="xl" fontWeight={500} color="gray.600">
        {title}
      </Text>
      {subTitle ? (
        <Text fontSize="sm" mt={2} fontWeight={400} color="gray.600">
          {subTitle}
        </Text>
      ) : null}
      {isShowDivider ? <Divider my={4} /> : null}
    </Stack>
  );
};

export default FormHeader;
