import { Box, Image, Stack, Text, VStack } from 'native-base';
import Button from 'ui/components/Button';
import Input from 'ui/components/Input';
import { transformInputValueToLowerCase } from 'ui/utils/formatter';

import loginBackground from '~/assets/images/login-background.svg';
import { HelpCentreRedirectLink } from '~/components/HelpCentre';

import { styles } from './styles';
import { useLoginController } from './useLogincontroller';

export const LoginPage = () => {
  const {
    isSubmitting,
    canSignIn,
    control,
    errors,
    onSubmit,
    onKeyDown,
    handleGoToSignUp,
    handleGoToForgotPassword,
  } = useLoginController();

  return (
    <Box
      flex={1}
      flexDirection={styles.container}
      minHeight="100vh"
      width="100%"
      background="singletons.mediumWhite"
      alignSelf="center"
    >
      <Stack
        position="relative"
        overflow="hidden"
        justifyContent="center"
        alignItems="center"
        w={styles.wrapper}
      >
        <Image
          src={loginBackground}
          width="100%"
          height="100%"
          alt="Doit for organisations"
        />
      </Stack>

      <Stack
        w={styles.backgroundContainerW}
        justifyContent="center"
        px={styles.backgroundContainerPaddingX}
        py={styles.backgroundContainerPaddingY}
        background="singletons.mediumWhite"
        overflow="auto"
        maxH="100%"
        paddingTop={6}
      >
        <VStack maxWidth="550px" w="100%" maxH="100%">
          <Box>
            <HelpCentreRedirectLink />
            <Text textAlign="initial" fontSize="2xl">
              Welcome to <br />
              DoIt
              <Text fontWeight="bold"> for organisations!</Text>
            </Text>
            <Text mt="16px" maxWidth="417px" fontSize="medium">
              If you have already registered your organisation, sign in with
              your account.
            </Text>
          </Box>
          <Stack width="100%">
            <Input
              name="email"
              isRequired
              control={control}
              placeholder="Type your email"
              rightIconName="mail"
              label="Email"
              errorMessage={errors.email?.message}
              iconErrorMessage="info"
              isFullWidth
              backgroundColor="white"
              borderColor="muted.200"
              onChangeTransform={transformInputValueToLowerCase}
            />
            <Box mb={4} />
            <Input
              name="password"
              control={control}
              isRequired
              placeholder="Type your password"
              type="password"
              label="Password"
              errorMessage={errors.password?.message}
              iconErrorMessage="info"
              isFullWidth
              backgroundColor="white"
              borderColor="muted.200"
              onKeyPress={onKeyDown}
            />
            <Button variant="unstyled" mt="25px">
              <Text
                textDecorationLine="underline"
                textAlign="center"
                onPress={handleGoToForgotPassword}
              >
                I forgot my password
              </Text>
            </Button>
          </Stack>
          <Stack mt={4} mb={6}>
            <Button
              onPress={onSubmit}
              color="primary.600"
              isDisabled={canSignIn}
              isLoading={isSubmitting}
              p={6}
            >
              <Text fontSize="medium" color="white" fontWeight="medium">
                Sign in
              </Text>
            </Button>
          </Stack>
          <VStack alignItems="center" justifyContent="center">
            <Text color="black" fontSize="md" mb={4}>
              {`You don't have an account?`}
            </Text>
            <Button variant="unstyled" onPress={handleGoToSignUp}>
              <Text
                color="primary.600"
                fontWeight="medium"
                fontSize="md"
                textDecoration="none"
                pr={3}
              >
                Sign Up
              </Text>
            </Button>
          </VStack>
        </VStack>
      </Stack>
    </Box>
  );
};
