import { Box, Image, Stack, Text, VStack } from 'native-base';
import Dropzone from 'react-dropzone';
import Button from 'ui/components/Button';
import Icon from 'ui/components/Icon';
import CommonInputWrapper from 'ui/components/v2/CommonInputWrapper';
import { Loading } from 'ui/components/v2/Loading';
import { colors } from 'ui/theme/colors';

import { ImageTransformModal } from '../../Modals/ImageTransformModal';
import { filesAccepted } from '../constants';
import { useDropAreaController } from './controller';
import { ImageDropAreaProps } from './types';

export const DropArea = (props: ImageDropAreaProps) => {
  const {
    circularCrop,
    cropModalTitle,
    label,
    helpText,
    isDisabled,
    isRequired,
    errorMessage,
    submitLabel = 'change image',
    buttonFontColor = colors.primary[300],
    buttonBackgroundColor = colors.primary[50],
    aspectRatio = 1,
  } = props;

  const {
    imgSrc,
    modalRef,
    isLoading,
    aspectRatioLabel,
    handleSubmit,
    handleClear,
    onDropAccepted,
    onDropRejected,
  } = useDropAreaController(props);

  return (
    <CommonInputWrapper
      label={label}
      helpText={helpText}
      isRequired={isRequired}
      isDisabled={isDisabled}
      errorMessage={errorMessage}
    >
      <VStack w="full">
        <Dropzone
          accept={filesAccepted}
          onDropAccepted={onDropAccepted}
          onDropRejected={onDropRejected}
          multiple={false}
          maxFiles={1}
        >
          {({ getRootProps, getInputProps, open }) => (
            <div {...getRootProps()} style={{ position: 'relative' }}>
              <input {...getInputProps()} style={{ display: 'none' }} />

              <Box
                {...getRootProps}
                position={'relative'}
                borderWidth={1}
                borderRadius={16}
                borderColor={'gray.200'}
                w="100%"
                height="0px"
                paddingBottom={`${(1 / aspectRatio) * 100}%`}
                justifyContent="center"
                alignItems="center"
                bgColor="white"
              >
                {/* Image preview */}
                {imgSrc ? (
                  <>
                    <Image
                      position={'absolute'}
                      top={0}
                      left={0}
                      key={imgSrc}
                      borderRadius="2xl"
                      width="100%"
                      height="100%"
                      src={imgSrc as string}
                    />

                    <Button
                      bgColor="white"
                      onPress={handleClear}
                      position="absolute"
                      right={4}
                      top={4}
                      zIndex={10}
                      minW={'unset'}
                      w={10}
                      h={10}
                      borderRadius={'3rem'}
                    >
                      <Icon icon="trash" size={16} color={colors.error[600]} />
                    </Button>
                  </>
                ) : null}

                {/* Actions */}
                <VStack
                  top={0}
                  bottom={0}
                  position={'absolute'}
                  space={4}
                  justifyContent={'center'}
                  margin={'auto'}
                  w="full"
                >
                  <Stack alignItems="center" space={2}>
                    <Stack
                      alignItems="center"
                      bgColor={'rgba(255,255,255,0.7)'}
                      borderRadius={6}
                      p={2}
                      px={3}
                    >
                      {!imgSrc && (
                        <VStack
                          alignItems={'center'}
                          justifyContent="center"
                          space={2}
                          mb={2}
                        >
                          <Icon
                            color={colors.gray[300]}
                            size="48"
                            icon="image"
                          />
                        </VStack>
                      )}

                      <Text color="gray.600" fontSize={'sm'} fontWeight="500">
                        Aspect Ratio: {aspectRatioLabel}{' '}
                        {`(1200 x ${(1 / aspectRatio) * 1200}px)`}
                      </Text>
                      <Text color="gray.600" fontSize={'xs'} fontWeight="400">
                        (Max {3}MB)
                      </Text>
                    </Stack>

                    <Stack mt={1}>
                      <Button
                        leftIconName="upload"
                        leftIconSize={16}
                        leftIconColor={buttonFontColor}
                        bgColor={buttonBackgroundColor}
                        onPress={open}
                      >
                        <Text
                          color={buttonFontColor}
                          fontSize="14"
                          fontWeight="500"
                        >
                          {!!imgSrc ? submitLabel : 'Upload image'}
                        </Text>
                      </Button>
                    </Stack>
                  </Stack>
                </VStack>
                {isLoading && <Loading float height={'100%'} />}
              </Box>
            </div>
          )}
        </Dropzone>

        <ImageTransformModal
          src={imgSrc}
          title={cropModalTitle}
          modalRef={modalRef}
          onSubmit={handleSubmit}
          aspectRatio={aspectRatio}
          circularCrop={circularCrop}
        />
      </VStack>
    </CommonInputWrapper>
  );
};

export default DropArea;
