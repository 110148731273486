import { Avatar, Box, HStack, Stack, Text, VStack } from 'native-base';
import Icon from 'ui/components/Icon';
import { AppVisibility } from 'ui/enums';

import { colors } from '~/theme/colors';

import { AppSharingCardProps } from './types';

export const AppSharingCard = ({
  app,
  sharerOrganization,
  sharerEcosystem,
  receiverEcosystem,
}: AppSharingCardProps) => (
  <VStack
    bg={colors.singletons.lightText}
    p="6"
    rounded="2xl"
    borderWidth="1"
    borderColor="gray.300"
    position="relative"
    w="full"
    space={2}
  >
    <Stack space="3">
      <HStack justifyContent={'space-between'}>
        <Avatar
          rounded={12}
          _image={{ rounded: 12 }}
          source={{ uri: app.logo }}
        />
        <Box>
          <HStack space="1" alignItems="center">
            {app?.visibility === AppVisibility.Private ? (
              <>
                <Icon icon="lock" size="16" color={colors.gray[500]} />
                <Text color="gray.500">Private</Text>
              </>
            ) : (
              <>
                <Icon icon="eye" size="16" color={colors.gray[500]} />
                <Text color="gray.500">Public</Text>
              </>
            )}
          </HStack>
        </Box>
      </HStack>

      <Text
        numberOfLines={2}
        color="gray.800"
        fontSize="lg"
        fontWeight={500}
        height="3.4rem"
      >
        {app?.name || ''}
      </Text>
    </Stack>
    <VStack justifyContent="start">
      <Text color="gray.500">Shared by </Text>
      <HStack space={'2'}>
        <Avatar
          rounded={12}
          _image={{ rounded: 12 }}
          source={{ uri: sharerOrganization.logo }}
        />
        <Text
          numberOfLines={2}
          color="gray.500"
          fontSize="sm"
          alignSelf={'center'}
        >
          {sharerOrganization?.name || ''}
        </Text>
      </HStack>
    </VStack>

    <Stack space={4} flex="1" mt={6}>
      <Stack space={2}>
        <Text color="gray.600" fontSize="xs" fontWeight={400}>
          Ecosystem (receiver)
        </Text>
        <Text color={'black'} height="2.5rem">
          {receiverEcosystem.name || ''}
        </Text>
      </Stack>
      <Stack space={2}>
        <Text color="gray.600" fontSize="xs" fontWeight={400}>
          Ecosystem (owner)
        </Text>
        <Text color={'black'} height="2.5rem">
          {sharerEcosystem.name || ''}
        </Text>
      </Stack>
    </Stack>

    {/* <HStack justifyContent="space-between" mt="6"> */}
    {/* <Pressable>
        <HStack alignItems="center" space="3">
          <Text color="primary.400">View app</Text>
          <Icon icon="arrow-right" size="16" color={colors.primary[400]} />
        </HStack>
      </Pressable> */}
    {/* </HStack> */}
  </VStack>
);
