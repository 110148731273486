import { useMemo, useState } from 'react';
import { useController } from 'react-hook-form';

import { useAppDispatch } from '~/hooks/useAppDispatch';
import { useAppSelector } from '~/hooks/useAppSelector';
import { addMemberActions } from '~/store/slices/addMember';

import { IEcosystemItemProps } from './types';

export const useEcosystemItemController = ({
  name,
  _id,
  appsConnectedToOrganization,
  control,
  setValue,
}: IEcosystemItemProps) => {
  const [controlledFieldName] = useState(
    `ecosystems.${name.toLowerCase().replaceAll(' ', '_')}`,
  );

  const { field } = useController({
    control,
    name: controlledFieldName as never,
  });

  const { ecosystemsToShare } = useAppSelector(({ addMember }) => addMember);

  const dispatch = useAppDispatch();

  const filteredAppsByEcosystem = appsConnectedToOrganization?.filter(
    (appConnectedToOrganization) =>
      appConnectedToOrganization.receiverEcosystem === _id,
  );

  const handleOnChange = (values: string[]) => {
    setValue(controlledFieldName as any, values, {
      shouldValidate: true,
    } as never);
    dispatch(
      addMemberActions.setEcosystemToShare({
        ecosystemId: _id,
        appsData: values
          .filter((value) => value !== 'all')
          .map((value) => {
            return {
              appId: value,
              appPermissionGroupsData: [],
            };
          }),
      }),
    );
  };

  const ecosystem = useMemo(() => {
    return ecosystemsToShare?.find((e) => e.ecosystemId === _id);
  }, [ecosystemsToShare, _id]);

  return {
    filteredAppsByEcosystem,
    controlledFieldName,
    ecosystem,
    field,
    handleOnChange,
  };
};
