import { HStack, Stack, Text } from 'native-base';

import { colors } from '../../theme/colors';
import Icon from '../Icon';
import { PermissionsActiveProps } from './types';

export const PermissionsActive = ({
  title,
  titleColor = colors.gray[600],
  descriptionColor = colors.gray[600],
  iconColor = colors.success[500],
  iconSize = '20px',
  permissions = [],
}: PermissionsActiveProps) => {
  return (
    <Stack space={3}>
      <Text fontWeight={500} color={titleColor}>
        {title}
      </Text>
      {permissions.map((permission) => (
        <HStack key={permission._id} space={2}>
          <Icon icon="check" color={iconColor} size={iconSize} />
          <Text fontSize="xs" color={descriptionColor}>
            {permission.description}
          </Text>
        </HStack>
      ))}
    </Stack>
  );
};
