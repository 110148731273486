import { HStack, Stack, Text, VStack } from 'native-base';
import React from 'react';
import Button from 'ui/components/Button';
import Icon from 'ui/components/Icon';
import { colors } from 'ui/theme/colors';

import { IEmailListProps } from './types';

export const EmailListComponent = ({
  emails,
  onRemoveEmail,
}: IEmailListProps) => (
  <VStack mt={8} space={6} maxH="200px" overflowY="auto" width="104%">
    {emails.map(({ email, externalId }, index) => (
      <HStack
        key={email}
        pb="3"
        borderBottomWidth="1"
        borderBottomColor="gray.300"
        justifyContent="space-between"
        alignItems="center"
      >
        <Stack space={2}>
          <HStack space={2} alignItems="center">
            <Text color="gray.600" fontWeight={500}>
              Email:
            </Text>
            <Text color="gray.600">{email}</Text>
          </HStack>

          {externalId ? (
            <HStack space={2} alignItems="center">
              <Text color="gray.600" fontWeight={500}>
                External ID:
              </Text>
              <Text color="gray.600">{externalId}</Text>
            </HStack>
          ) : null}
        </Stack>
        <Button
          w="container"
          variant="ghost"
          onPress={() => onRemoveEmail(index)}
        >
          <HStack space={3} alignItems="center">
            <Icon icon="x-circle" color={colors.error[600]} size="18" />
            <Text color="red.600" fontWeight="500">
              Remove
            </Text>
          </HStack>
        </Button>
      </HStack>
    ))}
  </VStack>
);
