import { useCallback, useMemo, useState } from 'react';
import { IFilter } from 'ui/components/DataTable/types';
import { PermissionType } from 'ui/enums';

import { useAppSelector } from '~/hooks/useAppSelector';
import { useLoadAllAppSharingsByOrganization } from '~/hooks/useLoadAllAppSharingsByOrganization';
import { useLoadAppPermissionsGroupByOrganization } from '~/hooks/useLoadAppPermissionsGroupByOrganization';
import { useQuery } from '~/hooks/useQuery';
import { useRouter } from '~/hooks/useRouter';
import { QueryParams } from '~/services/resources/types';
import { PopulatedNetworkAppSharing } from '~/types/interfaces/networkAppSharing';

import { EcosystemNetworkConnectionsPopulated } from '../../../../../../types/interfaces/ecosystem-network-connections';
import { IOrganization } from '../../../../../../types/interfaces/organization';
import { getPartnerEcosystem } from '../../../../../../utils/functions';

export interface usePartnerAccessControllerProps {
  connection: EcosystemNetworkConnectionsPopulated;
  organization: IOrganization;
}

export const usePartnerAccessController = ({
  connection,
  organization,
}: usePartnerAccessControllerProps) => {
  const [queryParams, setQueryParams] = useState<QueryParams | undefined>(
    undefined,
  );

  const { goToRoute } = useRouter();

  const { organizationSelectedId } = useAppSelector(({ auth }) => auth);
  const { selectedEcosystem } = useAppSelector(({ ecosystem }) => ecosystem);

  const [partnerEcosystemId] = useState<string>(
    getPartnerEcosystem(connection, organization)?._id,
  );

  const { data: appsShared, isLoading: isLoadingAppsShared } = useQuery<
    PopulatedNetworkAppSharing[]
  >(`network-app-sharing/network-ecosystem-connection/${connection?._id}`, {
    key: `apps-shared-by-organizationSharer-and-receiverEcosystem`,
    queryOptions: {
      enabled: !!organizationSelectedId && !!partnerEcosystemId,
    },
  });

  const uniqueAppIdKeys = useMemo(() => new Set(), []);

  const { appsConnectedToOrganization, isLoadingAppsConnectedToOrganization } =
    useLoadAllAppSharingsByOrganization(organizationSelectedId || '');

  const { data: appPermissionsGroup, isLoading: isLoadingAppPermissionsGroup } =
    useLoadAppPermissionsGroupByOrganization(
      organizationSelectedId,
      queryParams,
    );

  const uniqueAppsConnectedToOrganization = useMemo(() => {
    return appsConnectedToOrganization?.filter((app) => {
      const isUnique = !uniqueAppIdKeys.has(app.app._id);
      uniqueAppIdKeys.add(app.app._id);
      return isUnique;
    });
  }, [appsConnectedToOrganization, uniqueAppIdKeys]);

  const handleGoToManageAccess = () => {
    goToRoute(`/organization/${organization?._id}/manage-access`);
  };

  const loadPermissions = useCallback(
    async (skip: number, limit: number, filter: IFilter) => {
      if (!appsShared?.length || !selectedEcosystem)
        return { data: [], count: 0 };

      const appPermissionGroupIds = [];
      for (const appShared of appsShared) {
        for (const appPermissionGroup of appShared.appPermissionGroups) {
          appPermissionGroupIds.push(appPermissionGroup);
        }
      }
      setQueryParams({
        skip,
        limit,
        filter: JSON.stringify({
          type: PermissionType.PARTNER,
          ecosystem: selectedEcosystem._id,
          ...filter,
        }),
      });

      if (appPermissionsGroup?.data) {
        const data = appPermissionsGroup?.data;
        const count = appPermissionsGroup?.count;
        return { data, count };
      }
      return { data: [], count: 0 };
    },
    [appPermissionsGroup, appsShared, selectedEcosystem],
  );

  const isLoading = isLoadingAppsConnectedToOrganization || isLoadingAppsShared;

  return {
    appsShared,
    uniqueAppsConnectedToOrganization,
    isLoading,
    isLoadingAppPermissionsGroup,
    loadPermissions,
    handleGoToManageAccess,
  };
};
