import { QueryFunction, useInfiniteQuery } from 'react-query';

import { api } from '~/services/api';

/* This is a generic adapter fo react query useInfiniteQuery */

const DEFAULT_ITEMS_PER_PAGE = 16;

export type UseInfinityLoadProps = {
  url: string;
  queryKey: string;
  disabled?: boolean;
  props: Record<string, unknown>;
  itemsPerPage?: number;
};

export default function useInfinityLoad({
  url,
  props,
  queryKey,
  disabled = false,
  itemsPerPage = DEFAULT_ITEMS_PER_PAGE,
}: UseInfinityLoadProps) {
  const handleQueryFunction: QueryFunction = async ({ pageParam = 0 }) => {
    if (disabled) return { responseData: [], skip: 0 };

    const params: Record<string, string | number> = {
      skip: pageParam,
      limit: itemsPerPage,
    };

    Object.keys(props).forEach((key: string) => {
      const value = props[key];

      if (value === undefined || value === '') return;

      if (value instanceof Date) {
        params[key] = value.toISOString();
        return;
      }

      if (Array.isArray(value) || typeof value === 'object') {
        params[key] = JSON.stringify(value);
        return;
      }
      params[key] = String(value);
    });

    const { data: responseData } = await api.get(url, { params });

    return {
      responseData,
      skip: pageParam + itemsPerPage,
    };
  };

  return useInfiniteQuery([queryKey, props], handleQueryFunction, {
    getNextPageParam: (lastPage: any) => {
      if ((lastPage.responseData ?? []).length < itemsPerPage) return undefined;
      return lastPage.skip;
    },
  });
}
