import { AppPermissionGroup } from 'ui/types/interfaces/app-permissions-group';

import { useQuery } from '~/hooks/useQuery';
import { QueryParams } from '~/services/resources/types';
import { IPaginatedResponse } from '~/types';

export const useLoadAppPermissionsGroupByOrganization = (
  organizationId = '',
  params?: QueryParams,
) => {
  const { data, isLoading, refetch } = useQuery<
    IPaginatedResponse<AppPermissionGroup[]>
  >(`/app-permission-group/organization/${organizationId}`, {
    queryOptions: {
      enabled: !!organizationId && !!params,
    },
    requestOptions: {
      params,
    },
    key: `app-permissions-group-${organizationId}-${params?.skip}-${params?.limit}-${params?.filter}`,
  });

  return {
    data,
    isLoading,
    refetch,
  };
};
