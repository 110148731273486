import {
  PermissionCondition,
  PermissionConditionErrorMessages,
  PermissionConflictResolutionMethodType,
  TeamEventsConditionFields,
} from 'ui/types/interfaces/app-permissions-group';

export function resolveEditPermissionConflictResolutionMethod(
  conditions: PermissionCondition[],
  sharingConflitResolution: PermissionConflictResolutionMethodType | undefined,
) {
  if (!sharingConflitResolution)
    return conditions.map(() => ({ message: null }));

  return conditions.map((condition) => {
    const errorMessage =
      PermissionConditionErrorMessages[
        condition.field as TeamEventsConditionFields
      ];

    switch (sharingConflitResolution) {
      case 'UseLowestValue':
        if (Number(condition.value) > Number(condition.defaultValue)) {
          return {
            message: errorMessage.UseLowestValue(condition.defaultValue),
          };
        }
        break;
      case 'UseHighestValue':
        if (Number(condition.value) < Number(condition.defaultValue)) {
          return {
            message: errorMessage.UseHighestValue(condition.defaultValue),
          };
        }
        break;
    }
    return { message: null };
  });
}
