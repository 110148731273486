import { Box, PresenceTransition, Stack, Text } from 'native-base';
import Skeleton from 'react-loading-skeleton';
import Button from 'ui/components/Button';

import useReadMoreController from './controller';
import { ReadMoreProps } from './types';

const ReadMore = (props: ReadMoreProps) => {
  const {
    text,
    rightIconColor,
    btnTextColor,
    btnBgColor,
    handleOnClick,
    styles,
    showButton,
    isLoading,
    ...rest
  } = useReadMoreController(props);

  return (
    <>
      {isLoading ? (
        <Skeleton count={4} />
      ) : (
        <Box {...rest}>
          <Stack space="2">
            <PresenceTransition
              visible={true}
              initial={styles.presenceTransition.initial}
              animate={styles.presenceTransition.animate}
            >
              <Text
                color={props.textColor || 'dark.500'}
                //Makes it responsive for sizes less than the defined height
                maxH={styles.textHeight}
                overflow="hidden"
              >
                {text}
              </Text>
            </PresenceTransition>
            {showButton ? (
              <Button
                onPress={handleOnClick}
                bgColor={btnBgColor}
                rightIconName={styles.btnIcon}
                rightIconSize={15}
                rightIconColor={rightIconColor}
                h="35px"
                mt={2}
              >
                <Text color={btnTextColor} fontSize={12} fontWeight="medium">
                  {styles.btnText}
                </Text>
              </Button>
            ) : null}
          </Stack>
        </Box>
      )}
    </>
  );
};

export default ReadMore;
