import Yup from 'ui/utils/validations/yup';

import { DepartmentFormFields } from '~/pages/Authenticated/Settings/General/components/Modals/DepartmentFormModal/types';

export const schemaValidation = Yup.object({
  name: Yup.string().required('Department name is required'),
});

export const INITIAL_FORM_DATA: DepartmentFormFields = {
  name: '',
};
