import { Stack, Text, VStack } from 'native-base';
import DataTable from 'ui/components/DataTable';
import { TableSizeClass } from 'ui/components/DataTable/@types/basicTypes';
import { ModalInformation } from 'ui/components/ModalInformation';
import Modal from 'ui/components/Modals/Modal';

import { InviteDetails } from '~/pages/Authenticated/Members/PendingInvites/components/InviteDetails';

import { usePendingInvitesController } from './controller';
import { tablePendingMemberInviteColumns } from './tablePendingMemberInviteColumns';
import { tablePendingPartnerInviteColumns } from './tablePendingPartnerInviteColumns';
import { defaultFiltersValues } from './utils';

export const PendingInvites = () => {
  const {
    totalMemberInvitesPending,
    totalPartnerInvitesPending,
    modalRef,
    modalInviteDetailsRef,
    isSubmitting,
    closeResendInviteModal,
    openResendInviteModal,
    openModalInviteDetails,
    copyInviteLink,
    onResendInvite,
    loadPendingPartnerInvites,
    loadPendingMemberInvites,
    inviteDetails,
  } = usePendingInvitesController();

  return (
    <Stack w="full">
      <Stack background="white" borderRadius="2xl">
        <Stack p={6}>
          <VStack space={2}>
            <Text color="gray.600" fontSize="2xl" fontWeight={500}>
              {totalMemberInvitesPending} member invites pending
            </Text>
            <Text color="gray.600" fontSize="sm" fontWeight={500}>
              View and manage pending member invites
            </Text>
          </VStack>
        </Stack>
        <Stack width="100%" p={6}>
          <DataTable
            defaultFilterValues={defaultFiltersValues}
            loadData={loadPendingMemberInvites}
            columns={tablePendingMemberInviteColumns({
              openResendInviteModal,
              copyInviteLink,
              openModalInviteDetails,
            })}
            className={TableSizeClass.Small}
          />
        </Stack>

        <Stack p={6}>
          <VStack space={2}>
            <Text color="gray.600" fontSize="2xl" fontWeight={500}>
              {totalPartnerInvitesPending} partner invites pending
            </Text>
            <Text color="gray.600" fontSize="sm" fontWeight={500}>
              View and manage pending partner invites
            </Text>
          </VStack>
        </Stack>
        <Stack width="100%" p={6}>
          <DataTable
            loadData={loadPendingPartnerInvites}
            columns={tablePendingPartnerInviteColumns({
              openResendInviteModal,
              copyInviteLink,
              openModalInviteDetails,
            })}
            defaultFilterValues={defaultFiltersValues}
            className={TableSizeClass.Small}
          />
        </Stack>
      </Stack>

      <Modal
        ref={modalRef}
        onClose={closeResendInviteModal}
        leftBtnText="Cancel"
        leftBtnAction={closeResendInviteModal}
        rightBtnAction={onResendInvite}
        rightBtnText="Resend invite"
        modalContentMaxW="600px"
        rightBtnLoading={isSubmitting}
        headerText="Resend invite"
      >
        <Stack mt={2} space="5">
          <Text>Do you want to resend the invitation?</Text>
        </Stack>
      </Modal>

      <ModalInformation
        modalRef={modalInviteDetailsRef}
        headerText="Invite details"
      >
        <InviteDetails
          copyInviteLink={copyInviteLink}
          inviteDetails={inviteDetails}
        />
      </ModalInformation>
    </Stack>
  );
};
