import { Box, Button, HStack, Stack, Text, VStack } from 'native-base';
import Illustration, { ILLUSTRATIONS_NAME } from 'ui/components/Illustration';
import Input from 'ui/components/Input';
import { colors } from 'ui/theme/colors';

import { styleConfig } from './styles';
import { useChangePasswordController } from './useChangePasswordController';

export const ChangePassword = () => {
  const { control, errors, canSubmit, isSubmitting, onSubmit } =
    useChangePasswordController();

  return (
    <>
      <VStack
        backgroundColor={colors.singletons.lightText}
        pt={styleConfig.containerPadding}
        pb={24}
        minHeight="90vh"
        width="100%"
        justifyContent={styleConfig.containerJustifyContent}
      >
        <HStack
          flexDirection={styleConfig.horizontalStackDirection}
          alignItems="center"
          w="min(90%, 1000px)"
          mx="auto"
        >
          <Illustration
            width={styleConfig.illustrationSize}
            height={styleConfig.illustrationSize}
            name={ILLUSTRATIONS_NAME.UPDATE_CONSTRUCTION_SETTINGS_GREEN}
            alt="Enter password"
          />

          <VStack
            flex="1"
            height="100%"
            space={8}
            marginLeft={styleConfig.vStackMargin}
          >
            <Stack space={styleConfig.stackSpace}>
              <Text
                fontWeight={styleConfig.pageTitleWeight}
                fontSize={styleConfig.pageTitleFontSize}
                textAlign="left"
                color={styleConfig.pageTextSubTitleColor}
              >
                Change password
              </Text>

              <Text
                fontWeight={400}
                fontSize="md"
                textAlign="left"
                color={styleConfig.pageTextSubTitleColor}
              >
                Confirm your current password and create <br />a new one.
              </Text>
            </Stack>

            <Box>
              <VStack space={3}>
                <Input
                  name="currentPassword"
                  control={control}
                  placeholder="Enter your current password"
                  label="Current password"
                  leftIconName="lock"
                  type="password"
                  leftIconColor="gray.400"
                  isInvalid={!!errors.currentPassword?.message}
                  isRequired
                  errorMessage={errors.currentPassword?.message}
                  iconErrorMessage="info"
                />

                <Input
                  name="newPassword"
                  control={control}
                  placeholder="Create a new password"
                  label="New password"
                  leftIconName="lock"
                  type="password"
                  leftIconColor="gray.400"
                  isInvalid={!!errors.newPassword?.message}
                  isRequired
                  errorMessage={errors.newPassword?.message}
                  iconErrorMessage="info"
                  showPasswordRules
                />
              </VStack>

              <Button
                mt="8"
                display={styleConfig.buttonContinueDisplay}
                fontWeight={500}
                fontSize="md"
                variant="outline"
                isDisabled={canSubmit}
                isLoading={isSubmitting}
                onPress={onSubmit}
              >
                Save password
              </Button>
            </Box>
          </VStack>
        </HStack>
      </VStack>
    </>
  );
};
