import { yupResolver } from '@hookform/resolvers/yup';
import { useClipboard } from 'native-base';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { IModalRefProps } from 'ui/components/Modals/Modal/types';

import {
  COMMON_MESSAGES,
  ORGANIZATION_MESSAGES,
} from '~/constants/messages.constants';
import { useAppDispatch } from '~/hooks/useAppDispatch';
import { useAppSelector } from '~/hooks/useAppSelector';
import { useQuery } from '~/hooks/useQuery';
import { OrganizationService } from '~/services/resources/organization';
import { authSliceActions } from '~/store/slices/auth';
import { IOrganization } from '~/types/interfaces/organization';
import Yup from '~/utils/validations/yup';

import { WebhookConfigurationForm } from './types';

const schemaValidation = Yup.object({
  webhookEndpoint: Yup.string().required(),
});

export const useIntegrationsController = () => {
  const [isModalReady, setIsModalReady] = useState(false);
  const [isWebhookEditing, setIsWebhookEditing] = useState(false);
  const [apiKey, setApiKey] = useState<string | null>(null);
  const modalRef = useRef<IModalRefProps>(null);

  const { onCopy } = useClipboard();
  const dispatch = useAppDispatch();

  const { organizationSelectedId } = useAppSelector(({ auth }) => auth);

  const {
    control,
    formState: { errors, isSubmitting },
    handleSubmit,
    setValue,
  } = useForm<WebhookConfigurationForm>({
    mode: 'onChange',
    resolver: yupResolver(schemaValidation),
  });

  const {
    data: organization,
    isLoading: organizationIsLoading,
    refetch: refetchOrganization,
  } = useQuery<IOrganization>(`organization/${organizationSelectedId}`, {
    key: `organization/${organizationSelectedId}`,
    queryOptions: {
      enabled: !!organizationSelectedId,
    },
  });

  const { data: organizationApiKey, isLoading: organizationApiKeyIsLoading } =
    useQuery<string>(`organization/api-key/${organizationSelectedId}`, {
      key: `organization-${organizationSelectedId}-api-key`,
      queryOptions: {
        enabled: !!organizationSelectedId && !!organization,
      },
    });

  const hasOrganizationWebhook = useMemo(() => {
    return !!organization?.webhookEndpoint;
  }, [organization]);

  const handlePressEditWebhook = () => {
    if (isWebhookEditing || !hasOrganizationWebhook) {
      handleSubmitWebhookConfiguration();
      return;
    }
    setIsWebhookEditing(true);
  };

  const handleCopyWebhookEndpoint = () => {
    if (!organization?.webhookEndpoint) return;

    onCopy(organization.webhookEndpoint);
    toast.success(COMMON_MESSAGES.LINK_COPIED);
  };

  const setModalOpen = async () => {
    modalRef.current?.open();
    await createApiKey();
  };

  const closeModal = () => {
    modalRef?.current?.close();
  };

  const handleGetWebhookButtonProps = () => {
    if (isWebhookEditing || !hasOrganizationWebhook) {
      return {
        webhookButtonVariant: 'solid',
        webhookButtonLeftIconName: 'save',
        webhookButtonLeftIconColor: 'white',
        webhookButtonText: 'Save',
        webhookButtonTextColor: 'white',
        webhookButtonBgColor: 'primary.600',
      };
    }

    return {
      webhookButtonVariant: 'outline',
      webhookButtonLeftIconName: 'edit',
      webhookButtonLeftIconColor: 'primary.600',
      webhookButtonText: 'Edit',
      webhookButtonTextColor: 'primary.600',
      webhookButtonBgColor: 'white',
    };
  };

  const createApiKey = useCallback(async () => {
    setIsModalReady(false);
    try {
      if (!organizationSelectedId) return;
      const { data } = await OrganizationService.createApiKey(
        organizationSelectedId,
      );
      setApiKey(data);
    } catch {
      setApiKey(ORGANIZATION_MESSAGES.API_KEY_CREATE_ERROR);
    } finally {
      setIsModalReady(true);
    }
  }, [organizationSelectedId]);

  const handleSubmitWebhookConfiguration = handleSubmit(
    async ({ webhookEndpoint }: WebhookConfigurationForm) => {
      try {
        if (!organization) return;
        await OrganizationService.updateOne(organization._id, {
          webhookEndpoint,
        });
        dispatch(
          authSliceActions.update({
            organizations: [{ ...organization, webhookEndpoint }],
          }),
        );
        toast.success(ORGANIZATION_MESSAGES.WEBHOOK_URL_UPDATE_SUCCESS);
        setIsWebhookEditing(false);
        refetchOrganization();
      } catch (error) {
        toast.error(ORGANIZATION_MESSAGES.WEBHOOK_URL_UPDATE_ERROR);
      }
    },
  );

  const handleCopyApiKey = () => {
    if (!apiKey) return;

    onCopy(apiKey);
    toast.success(COMMON_MESSAGES.API_KEY_COPIED);
  };

  useEffect(() => {
    if (organizationApiKey) {
      setApiKey(organizationApiKey);
    }
  }, [organizationApiKey]);

  useEffect(() => {
    if (organization?.webhookEndpoint) {
      setValue('webhookEndpoint', organization.webhookEndpoint);
    }
  }, [organization, setValue]);

  return {
    organizationApiKeyIsLoading,
    organizationIsLoading,
    apiKey,
    control,
    errors,
    modalRef,
    isModalReady,
    hasOrganizationWebhook,
    isSubmitting,
    isWebhookEditing,
    setModalOpen,
    closeModal,
    handlePressEditWebhook,
    handleCopyWebhookEndpoint,
    handleCopyApiKey,
    handleGetWebhookButtonProps,
  };
};
