import { Box, VStack } from 'native-base';
import FormAction from 'ui/components/FormBuilder/components/FormAction';
import Tabs from 'ui/components/Tabs';
import { capitalize } from 'ui/utils/capitalize';

import Scaffold from '~/components/Scaffold';
import { ReviewSection } from '~/pages/Authenticated/Settings/Permissions/AddPermissionsGroup/ReviewSection';
import { colors } from '~/theme/colors';

import { useAddPermissionsGroupController } from './controller';
import { GroupDetailsSection } from './GroupDetailsSection';
import { PermissionsSection } from './PermissionsSection';
import { tabsElements } from './tabElements';
import { AddPermissionGroupTab, AddPermissionsGroupProps } from './types';

export const AddPermissionsGroup = ({
  isEditing,
}: AddPermissionsGroupProps) => {
  const {
    formActions,
    appsConnectedToOrganizationOptions,
    isLoading,
    tabsRef,
    currentTab,
    formState,
    control,
    onSubmit,
    getValues,
    selectedApp,
    isSubmitting,
    appPermissionGroup,
    typeOfUserOptions,
    setValue,
  } = useAddPermissionsGroupController(isEditing);

  return (
    <Scaffold title={`${isEditing ? 'Edit' : 'New'} permission group`}>
      <VStack maxWidth="1250px" width="100%" alignItems="center">
        <Tabs
          ref={tabsRef}
          currentTab={currentTab}
          tabIndicatorColor={colors.primary[600]}
          swipeEnabled={false}
          tabfocusedTextColor={colors.primary[600]}
          elements={tabsElements}
        />
        <Box w="38.75rem">
          {currentTab === AddPermissionGroupTab.GroupDetails ? (
            <GroupDetailsSection
              control={control}
              errors={formState.errors}
              appsConnectedToOrganizationOptions={
                appsConnectedToOrganizationOptions
              }
              isLoading={isLoading}
              typeOfUserOptions={typeOfUserOptions}
            />
          ) : null}

          {currentTab === AddPermissionGroupTab.Permissions ? (
            <PermissionsSection
              control={control}
              errors={formState.errors}
              getValues={getValues}
              appPermissionGroup={appPermissionGroup}
              selectedNetworkAppSharing={selectedApp}
              setValue={setValue}
            />
          ) : null}

          {currentTab === AddPermissionGroupTab.Review ? (
            <ReviewSection
              permissionGroupName={getValues('name')}
              typeOfPermission={capitalize(getValues('type'))}
              permissionGroupDescription={getValues('description')}
              appLogo={selectedApp?.app.logo}
              appName={selectedApp?.app.name}
              getValues={getValues}
            />
          ) : null}

          <FormAction
            options={{
              alignItems: 'center',
              justifyContent: 'space-around',
              spacingBetweenItems: 2,
            }}
            items={formActions}
            formState={formState}
            handleSubmitForm={onSubmit}
            isLoadingSubmit={isSubmitting}
          />
        </Box>
      </VStack>
    </Scaffold>
  );
};
