import { VStack } from 'native-base';
import { useEffect, useMemo, useState } from 'react';
import { useQuery } from 'react-query';
import { SimpleDataTable } from 'ui/components/DataTable';

import {
  InsightPageFilters,
  useInsightFilters,
} from '~/pages/Authenticated/Insights/useInsightFilters';
import { InsightCacheConfig } from '~/pages/Authenticated/Insights/utils';
import InsightActivityApplicationService from '~/services/resources/insightActivityApplication';
import {
  CharitiesReportResponse,
  InsightByPeriodTypeQueryParams,
} from '~/services/resources/types';

import {
  CharitiesHelpedFilters,
  ICharitiesHelpedFilters,
} from './CharitiesHelpedFilters';
import { applyLocalCharitiesHelpedFilters, tableColumns } from './utils';

const buildQueryKey = (filters: InsightPageFilters) => {
  const params: InsightByPeriodTypeQueryParams = {
    apps: filters.apps,
    ecosystem: filters.selectedEcosystemId,
    organization: filters.selectedOrganizationId,
    startDate: filters.from!,
    endDate: filters.to!,
    periodType: filters.periodType,
    granularity: 'Daily',
  };
  return [
    InsightActivityApplicationService.APPLICATIONS_CHARITIES_HELPED_REPORT_KEY,
    params,
  ];
};

type CharitiesHelpedApplicationsProps = {
  onSetPeriodData: (data?: CharitiesReportResponse) => void;
};

export const CharitiesHelpedApplications = ({
  onSetPeriodData,
}: CharitiesHelpedApplicationsProps) => {
  const { filters, hasFilterSetted } = useInsightFilters();
  const [charitiesFilter, setCharitiesFilter] =
    useState<ICharitiesHelpedFilters>({
      option: null,
      text: '',
    });

  const {
    data: response,
    isFetching,
    isLoading,
  } = useQuery({
    queryKey: buildQueryKey(filters),
    queryFn: async ({ queryKey }) => {
      const [, params] = queryKey;

      return await InsightActivityApplicationService.getApplicationsCharitiesHelpedReport(
        params as InsightByPeriodTypeQueryParams,
      );
    },
    enabled: hasFilterSetted,
    ...InsightCacheConfig,
  });

  useEffect(() => {
    if (response) {
      onSetPeriodData(response);
    }

    return () => {
      onSetPeriodData();
    };
  }, [response]);

  const dataSource = useMemo(() => {
    const _dataSource = (response?.currentPeriodInsights ?? []).map((item) => ({
      name: item.activityOwnerOrganizationSummary.name,
      logo: item.activityOwnerOrganizationSummary.logo,
      value: item.total,
    }));

    if (charitiesFilter.text) {
      return applyLocalCharitiesHelpedFilters(_dataSource, {
        text: charitiesFilter.text,
      });
    }

    return _dataSource;
  }, [charitiesFilter.text, response]);

  return (
    <VStack space={4}>
      <CharitiesHelpedFilters
        measurementUnits={[]}
        onFilterChange={setCharitiesFilter}
        isMeasurementFilterEnabled={false}
      />

      <SimpleDataTable
        showHeader={false}
        style={{ borderWidth: 0, borderRadius: 0 }}
        columns={tableColumns}
        dataSource={dataSource}
        isLoading={isLoading || isFetching}
      />
    </VStack>
  );
};
