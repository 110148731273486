import { useQuery } from '~/hooks/useQuery';

import { ActivityTypeEnum } from '../../../../enums';

export const useLoadEcosystemLogHoursReportByType = (ecosystemId: string) => {
  const { data, isLoading, isRefetching } = useQuery<
    Record<ActivityTypeEnum, number>
  >(
    `measurement-insights/ecosystem/${ecosystemId}/total-time-logged-by-activity-type`,
    {
      key: `measurement-insights/ecosystem/${ecosystemId}/total-time-logged-by-activity-type`,
    },
  );

  return { data, isLoading, isRefetching };
};
