import { yupResolver } from '@hookform/resolvers/yup';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { IActionsItems } from 'ui/components/FormBuilder/components/FormAction/types';
import { ITabsRefProps } from 'ui/components/Tabs/types';
import { PermissionType } from 'ui/enums';
import {
  PermissionCondition,
  PermissionConditionValueTypes,
} from 'ui/types/interfaces/app-permissions-group';
import { capitalize } from 'ui/utils/capitalize';

import { useAppSelector } from '~/hooks/useAppSelector';
import { useLoadAllAppSharingsByOrganization } from '~/hooks/useLoadAllAppSharingsByOrganization';
import { useLoadAppGroupPermissionById } from '~/hooks/useLoadAppGroupPermissionById';
import { useRouter } from '~/hooks/useRouter';
import {
  AppPermissionGroupService,
  CreateAppGroupPermissionDTO,
  EditAppGroupPermissionDTO,
  EditPermissionsDTO,
  PermissionsDTO,
} from '~/services/resources/appPermissionGroup';

import { resolveEditPermissionConflictResolutionMethod } from '../../../../../utils/validations/edit-permission-conflict-resolution-method';
import { yupSchema } from './schema';
import { AddPermissionGroupFormValues, AddPermissionGroupTab } from './types';

export const useAddPermissionsGroupController = (isEditing?: boolean) => {
  const tabsRef = useRef<ITabsRefProps>(null);

  const [currentTab, setCurrentTab] = useState(0);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const {
    formState,
    control,
    watch,
    setError,
    getValues,
    setValue,
    trigger,
    clearErrors,
  } = useForm<AddPermissionGroupFormValues>({
    resolver: yupResolver(yupSchema),
    reValidateMode: 'onSubmit',
  });

  const {
    goToRoute,
    goBack,
    params: { id },
  } = useRouter();

  // Alerta de gambiarra pra limpar os erros após submit
  useEffect(() => {
    if (currentTab === AddPermissionGroupTab.Permissions) {
      watch((value, { name, type }) => {
        clearErrors('error');
        trigger();
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watch, currentTab]);

  const appId = getValues('app');

  const { organizationSelectedId } = useAppSelector(({ auth }) => auth);

  const { data: appPermissionGroup, isLoading: isLoadingAppPermissionGroup } =
    useLoadAppGroupPermissionById(id || '');

  const { appsConnectedToOrganization, isLoadingAppsConnectedToOrganization } =
    useLoadAllAppSharingsByOrganization(organizationSelectedId);

  const selectedApp = appsConnectedToOrganization?.find(
    (appConnected) => appConnected._id === appId,
  );

  const isOrganizationOwnerOfApp =
    selectedApp?.sharerOrganization === organizationSelectedId &&
    selectedApp?.receiverOrganization === organizationSelectedId;

  const appsConnectedToOrganizationOptions =
    appsConnectedToOrganization
      ?.filter((filteredAppConnected) => {
        if (isEditing) {
          if (
            filteredAppConnected.app._id === appPermissionGroup?.app &&
            filteredAppConnected.receiverEcosystem?._id ===
              appPermissionGroup?.ecosystem
          ) {
            return filteredAppConnected;
          }
        } else {
          return filteredAppConnected;
        }
      })
      .map((appConnected) => {
        return {
          label: `${appConnected.app.name} - ${appConnected.receiverEcosystem?.name}`,
          value: appConnected._id,
        };
      }) || [];

  const getSelectedPermissions = () => {
    const permissions = [];
    const selectedPermissions = getValues('groupedPermissions');
    if (selectedPermissions) {
      for (const selectedPermission of selectedPermissions) {
        if (selectedPermission?.configurations) {
          for (const configuration of selectedPermission.configurations) {
            if (configuration.templateId) {
              permissions.push(configuration);
            }
          }
        }
      }
    }

    return permissions;
  };
  const handleNextTab = () => {
    if (currentTab < AddPermissionGroupTab.Review) {
      const selectedPermissions = getSelectedPermissions();
      const errorMessages: Record<'message', string | null>[] = [];

      if (currentTab === AddPermissionGroupTab.Permissions) {
        if (selectedPermissions.length === 0) {
          errorMessages.push({
            message: 'Please select at least one permission',
          });
        }
        for (const permissionConfiguration of selectedPermissions) {
          if (
            permissionConfiguration.restrictionType === 'Quantitative' &&
            permissionConfiguration.sharingSettings.isShared
          ) {
            const _errorMessages =
              resolveEditPermissionConflictResolutionMethod(
                permissionConfiguration.conditions,
                permissionConfiguration.sharingSettings
                  .editConflictResolutionMethod,
              ).filter((error) => error.message !== null);
            errorMessages.push(..._errorMessages);
          }
        }

        console.log('errorMessages', errorMessages);
        if (errorMessages.length > 0) {
          setError(`error`, {
            message: errorMessages
              .map((error) => error.message)
              .filter((m) => m)
              .join('\n'),
          });
          return;
        }
        setCurrentTab(currentTab + 1);
      }
      setCurrentTab(currentTab + 1);
      trigger();
    }
  };

  const handlePreviousTab = useCallback(() => {
    if (currentTab > AddPermissionGroupTab.GroupDetails) {
      setCurrentTab(currentTab - 1);
      trigger();
    } else {
      goBack();
    }
  }, [currentTab, trigger, goBack]);

  const formActions: IActionsItems[] = useMemo(() => {
    const cancelButtonProps = {
      variant: 'ghost',
      onPress: handlePreviousTab,
    };

    const submitButtonProps = {
      backgroundColor:
        currentTab === AddPermissionGroupTab.Review
          ? 'primary.400'
          : 'primary.50',
    };

    const submitButtonTextProps = {
      color:
        currentTab === AddPermissionGroupTab.Review ? 'white' : 'primary.600',
      fontWeight: 500,
    };

    return [
      {
        id: '1',
        name: 'cancel',
        text: 'Back',
        buttonProps: cancelButtonProps,
      },
      {
        id: '2',
        name: 'continue',
        text:
          currentTab === AddPermissionGroupTab.Review
            ? `${isEditing ? 'Edit' : 'Create'} group`
            : 'Continue',
        isSubmitButton: true,
        buttonProps: submitButtonProps,
        textProps: submitButtonTextProps,
      },
    ];
  }, [currentTab, handlePreviousTab, isEditing]);

  const onSubmit = async () => {
    if (currentTab < AddPermissionGroupTab.Review) {
      handleNextTab();
      return;
    }

    if (isEditing) {
      await handleUpdateAppPermissionGroup();
    } else {
      await handleCreateAppPermissionGroup();
    }
  };

  const handleUpdateAppPermissionGroup = async () => {
    try {
      if (!organizationSelectedId || !selectedApp || !id) return;

      setIsSubmitting(true);
      const data = watch();
      const permissions = [];

      for (const groupedPermission of data.groupedPermissions) {
        if (groupedPermission.configurations) {
          for (const configuration of groupedPermission.configurations) {
            if (configuration.templateId) {
              permissions.push({
                permissionTemplateId: configuration.templateId,
                conditions:
                  configuration.conditions.map((c) =>
                    parseConditionFields(c),
                  ) || [],
                sharingSettings: configuration.sharingSettings,
              });
            }
          }
        }
      }

      const updateAppGroupPermissionDTO: EditAppGroupPermissionDTO = {
        name: data.name,
        description: data.description || '',
        organization: organizationSelectedId,
        app: selectedApp.app._id,
        ecosystem: selectedApp.receiverEcosystem._id,
        permissions: permissions as EditPermissionsDTO[],
      };

      await AppPermissionGroupService.update(id, updateAppGroupPermissionDTO);

      toast.success('Permission group edited successfully');
      goToRoute(`/settings/permissions`);
    } catch (error) {
      toast.error('Error editing permission group');
    } finally {
      setIsSubmitting(false);
    }
  };

  const parseConditionFields = (condition: PermissionCondition) => {
    const parse = (value: any, valueType: PermissionConditionValueTypes) => {
      switch (valueType) {
        case PermissionConditionValueTypes.NUMBER:
          return Number(value);
        case PermissionConditionValueTypes.BOOLEAN:
          return Boolean(value);
        case PermissionConditionValueTypes.STRING:
          return String(value);
        default:
          return value;
      }
    };

    return {
      ...condition,
      value: parse(condition.value, condition.valueType),
    };
  };

  const typeOfUserOptions = Object.values(PermissionType)
    .filter((permission) => {
      const permissionType = getValues('type');
      if (isEditing) {
        if (permission === permissionType) {
          return permission;
        }
      } else {
        return permission;
      }
    })
    .map((mapPermission) => {
      return {
        label: capitalize(mapPermission),
        value: mapPermission,
      };
    });

  const handleCreateAppPermissionGroup = async () => {
    try {
      if (!organizationSelectedId || !selectedApp) return;

      setIsSubmitting(true);
      const data = watch();
      const permissions = [];

      for (const groupedPermission of data.groupedPermissions) {
        if (groupedPermission.permissions) {
          if (groupedPermission.configurations) {
            for (const configuration of groupedPermission.configurations) {
              if (configuration.templateId) {
                permissions.push({
                  permissionTemplateId: configuration.templateId,
                  app: selectedApp.app._id,
                  conditions:
                    configuration.conditions.map((c) =>
                      parseConditionFields(c),
                    ) || [],
                });
              }
            }
          }
        }
      }

      const createAppGroupPermissionDTO: CreateAppGroupPermissionDTO = {
        name: data.name,
        description: data.description || '',
        type: data.type,
        organization: organizationSelectedId,
        app: selectedApp.app._id,
        ecosystem: selectedApp.receiverEcosystem._id,
        permissions: permissions as PermissionsDTO[],
      };

      await AppPermissionGroupService.create(createAppGroupPermissionDTO);
      toast.success('Permission group created successfully');
      goToRoute(`/settings/permissions`);
    } catch (error) {
      toast.error('Error creating permission group');
    } finally {
      setIsSubmitting(false);
    }
  };

  const isLoading = useMemo(() => {
    return isLoadingAppPermissionGroup || isLoadingAppsConnectedToOrganization;
  }, [isLoadingAppPermissionGroup, isLoadingAppsConnectedToOrganization]);

  useEffect(() => {
    if (
      isEditing &&
      appPermissionGroup &&
      appsConnectedToOrganization &&
      appsConnectedToOrganization.length > 0
    ) {
      const networkAppSharing = appsConnectedToOrganization?.find(
        (appConnected) =>
          appConnected.app._id === appPermissionGroup.app &&
          appConnected.receiverEcosystem?._id === appPermissionGroup.ecosystem,
      );
      if (!networkAppSharing) {
        console.error('Something is wrong. Network app sharing not found.');
        return;
      }
      setValue('name', appPermissionGroup.name);
      setValue('type', appPermissionGroup.type);
      setValue('description', appPermissionGroup.description);
      setValue('app', networkAppSharing._id);
      trigger();
    }
  }, [
    isEditing,
    appPermissionGroup,
    setValue,
    trigger,
    appsConnectedToOrganization,
  ]);

  return {
    appsConnectedToOrganizationOptions,
    isLoading,
    formActions,
    tabsRef,
    currentTab,
    formState,
    control,
    onSubmit,
    getValues,
    selectedApp,
    isSubmitting,
    appPermissionGroup,
    setValue,
    typeOfUserOptions,
    isOrganizationOwnerOfApp,
  };
};
