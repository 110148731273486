import { NotificationCard } from '../../BaseNotificationCard';
import { useActivityApplicationNotificationCardController } from './controller';
import { ActivityApplicationNotificationCardProps } from './types';

export const ActivityApplicationNotificationCard = (
  props: ActivityApplicationNotificationCardProps,
) => {
  const { titleNodes, date, profilePictureUrl } =
    useActivityApplicationNotificationCardController(props);

  return (
    <NotificationCard
      authorImageUrl={profilePictureUrl}
      titleNodes={titleNodes}
      date={date}
    />
  );
};
