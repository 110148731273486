import { useAppSelector } from '~/hooks/useAppSelector';
import { useQuery } from '~/hooks/useQuery';
import { PopulatedInvitePartners } from '~/types/interfaces/invite-partner';

export const useLoadPartnerInviteCode = (code: string) => {
  const { isAuthenticated } = useAppSelector(({ auth }) => auth);

  const { data, isLoading } = useQuery<PopulatedInvitePartners>(
    `invite-partners/code/${code}`,
    {
      queryOptions: { enabled: !!code && !!isAuthenticated },
      key: `invite-partners-code-${code}`,
    },
  );

  return { data, isLoading };
};
