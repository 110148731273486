import { TypeComputedProps as DataGridProps } from '@inovua/reactdatagrid-community/types';
import { useCallback, useRef, useState } from 'react';
import { toast } from 'react-toastify';
import { IFilterValue } from 'ui/components/DataTable/@types/filterTypes';
import { IModalRefProps } from 'ui/components/Modals/Modal/types';

import { COMMON_MESSAGES } from '~/constants/messages.constants';
import { useHandleActivityApplications } from '~/hooks/useHandleActivityApplications';
import { useRedirectToViewDetailsPage } from '~/hooks/useRedirectToViewDetailsPage';
import ActivityApplicationService from '~/services/resources/activityApplication';

import { useLoadEcosystemApplicationsAttendanceReport } from '../../../../../hooks/reports/ecosystems/useLoadEcosystemApplicationsAttendanceReport';
import { useLoadEcosystemApplicationsStatusReport } from '../../../../../hooks/reports/ecosystems/useLoadEcosystemApplicationsStatusReport';
import { useAppSelector } from '../../../../../hooks/useAppSelector';
import { ActivityApplicationsProps } from './types';

export const useApplicationListController = ({
  activityId,
  eventApplicationType,
}: ActivityApplicationsProps) => {
  const modalRef = useRef<IModalRefProps>(null);
  // Ref will be auto-assigned on onReady function of the table, as according to the docs, so we need let in this case and to type correctly
  // https://reactdatagrid.io/docs/api-reference#props-onReady
  // eslint-disable-next-line prefer-const
  let tableRef = useRef<DataGridProps | null>(null);

  const { handleViewMemberApplication, handleViewTeamApplication } =
    useRedirectToViewDetailsPage();
  const {
    declineModalRef,
    setTableRef,
    handleAcceptApplication,
    handleBatchAcceptApplications,
    handleCloseDeclineModal,
    handleOpenDeclineModal,
    isHandlingApplications,
    isSelectedApplicationsEmpty,
    handleDeclineApplications,
    handleDeclineSingleApplication,
    onSelectionChange,
    selectedApplications,
    selectedApplicationsCount,
    setSelectedApplications,
    control,
  } = useHandleActivityApplications(modalRef, tableRef);

  const [isLoadingActivities, setIsLoadingActivities] =
    useState<boolean>(false);

  const [totalApplications, setTotalApplications] = useState<number>(0);

  const { selectedEcosystem } = useAppSelector(({ ecosystem }) => ecosystem);
  const {
    data: applicationStatusCount,
    isLoading: isLoadingApplicationStatusCount,
  } = useLoadEcosystemApplicationsStatusReport(
    selectedEcosystem?._id || '',
    activityId,
  );

  const {
    data: applicationAttendanceCount,
    isLoading: isLoadingApplicationAttendanceCount,
  } = useLoadEcosystemApplicationsAttendanceReport(
    selectedEcosystem?._id || '',
    activityId,
  );

  const loadApplications = useCallback(
    async (skip: number, limit: number, filter: IFilterValue) => {
      try {
        setIsLoadingActivities(true);
        const { data: responseActivityApplicationData } =
          await ActivityApplicationService.findAll({
            filter: JSON.stringify({
              ...filter,
              'activitySubDocument.activityDefinition': activityId,
              applicationType: eventApplicationType,
            }),
            skip,
            limit,
            populate: JSON.stringify(['organization', 'userProfile']),
            sort: JSON.stringify({ applicationDate: -1 }),
          });

        setTotalApplications(responseActivityApplicationData?.count);

        setIsLoadingActivities(false);

        return {
          data: responseActivityApplicationData?.data,
          count: responseActivityApplicationData?.count,
        };
      } catch (error) {
        toast.error(COMMON_MESSAGES.ERROR_FETCHING_APPLICATIONS, {
          position: 'bottom-center',
        });
        return [];
      }
    },
    [],
  );

  return {
    loadApplications,
    isLoadingActivities,
    setSelectedApplications,
    onSelectionChange,
    selectedApplications,
    isSelectedApplicationsEmpty,
    selectedApplicationsCount,
    totalApplications,
    handleAcceptApplication,
    handleDeclineSingleApplication,
    handleDeclineApplications,
    handleBatchAcceptApplications,
    declineModalRef,
    handleCloseDeclineModal,
    handleOpenDeclineModal,
    control,
    setTableRef,
    isHandlingApplications,
    applicationStatusCount,
    isLoadingApplicationStatusCount,
    applicationAttendanceCount,
    isLoadingApplicationAttendanceCount,
    handleViewMemberApplication,
    handleViewTeamApplication,
  };
};
