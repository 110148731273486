import { useClipboard } from 'native-base';
import { toast } from 'react-toastify';

export const usePartnerDetailsController = () => {
  const { onCopy } = useClipboard();

  const handleCopy = (itemToCopy: string) => {
    onCopy(itemToCopy);
    toast.success('Copied to clipboard');
  };

  return { handleCopy };
};
