export const tabsElements = [
  {
    title: 'Access',
    component: () => <></>,
  },
  {
    title: 'Permissions',
    component: () => <></>,
  },
  {
    title: 'Compose',
    component: () => <></>,
  },
  {
    title: 'Invitation',
    component: () => <></>,
  },
];
