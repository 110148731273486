import { Control, FieldValues, FormState } from 'react-hook-form';

import { ICustomInputProps } from '../../../Input/types';
import { ISelectProps } from '../../../Select/types';

export enum INPUT_COMPONENT_TYPES {
  INPUT = 'input',
  TEXT_AREA = 'text_area',
  SELECT = 'select',
  DYNAMIC_INPUT = 'dynamic-input',
  RADIO = 'radio',
  CHECKBOX = 'checkbox',
  LOCALE = 'locale',
  DROPZONE_AREA = 'dropzone_area',
  UPLOAD_FILES = 'upload_files',
  ACTIVITY_METRICS = 'activity_metrics',
  APP_SELECT = 'app_select',
  RADIO_APP_SELECT = 'radio_app_select',
}

export type InputTypes = ICustomInputProps | ISelectProps;

export interface IInputs {
  inputsSections?: IInputSections[];
  options?: IInputOptions;
}

export interface IInputsProps extends IInputs {
  control: Control<FieldValues, unknown>;
  formState: FormState<FieldValues>;
}

export interface IInputOptions {
  direction: string;
  spacingBetweenItems: number;
}

export interface IInputItems {
  id: string;
  component?: INPUT_COMPONENT_TYPES;
  name: string;
  isRequired?: boolean;
  isDisabled?: boolean;
  maxLength?: number;
  helperText?: string;
  //FIXME: improve this by taking the typing as per the type. (Ex: input, select, radio)...
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  inputProps: object | any;
}

export interface IInputRows {
  key: string;
  rowStyling?: any;
  inputs: IInputItems[];
}

export interface IInputSections {
  key: string;
  sectionTitle?: string;
  sectionSubtitle?: string;
  sectionCustomHeaderSlot?: JSX.Element | null;
  sectionInputRows: IInputRows[];
}

export interface IInputItemsRender extends IInputItems {
  control: Control<FieldValues, unknown>;
  formState: FormState<FieldValues>;
}
