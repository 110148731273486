import { Stack } from 'native-base';
import Tabs from 'ui/components/Tabs';

import AppsForm from '~/components/EcosystemForm/AppsForm';
import Scaffold from '~/components/Scaffold';
import { colors } from '~/theme/colors';

import { tabElements } from './tabElements';
import { useManageAppsController } from './useManageAppsController';

export const ManageApps = () => {
  const {
    errors,
    control,
    isValid,
    isSubmitting,
    tabsRef,
    currentTab,
    handleBack,
    onSubmit,
  } = useManageAppsController();

  return (
    <Scaffold title="Edit ecosystem" alignItemsChildren="center">
      <Tabs
        ref={tabsRef}
        elements={tabElements}
        swipeEnabled={false}
        tabTextColor={colors.black}
        tabIndicatorColor={colors.primary['600']}
        tabfocusedTextColor={colors.primary['600']}
        defaultTabIndex={currentTab}
      />
      <Stack w="100%" maxW="562px">
        <AppsForm
          errors={errors}
          control={control}
          isEditing={true}
          isDisabledButton={!isValid}
          tabsRef={tabsRef}
          isSubmitting={isSubmitting}
          handleBack={handleBack}
          onSubmit={onSubmit}
        />
      </Stack>
    </Scaffold>
  );
};
