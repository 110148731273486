/**
 * Enum defining the actions that trigger validation in the change org middleware.
 */
export enum OrgActionGuardMiddlewareEnum {
  /**
   * Action triggered when the authentication is updated.
   */
  AUTH_UPDATE = 'auth/update',

  /**
   * Action triggered when the user signs in.
   */
  AUTH_SIGN = 'auth/signIn',

  /**
   * Action triggered to clear the join as admin state.
   */
  JOINS_AS_ADMIN_CLEAR = 'joinAsAdmin/clear',

  /**
   * Action triggered to next tab the join as admin state.
   */
  JOIN_AS_ADMIN_NEXT_TAB = 'joinAsAdmin/nextTab',
}
