import { Center, HStack, Modal, Text, VStack } from 'native-base';
import { forwardRef } from 'react';
import Button from 'ui/components/Button';

import { AppDetails } from '../AppDetails';
import { AppsList } from '../AppsList';
import { AddAppModalProps } from './types';
import { useAddAppModalController } from './useAddAppModalController';

export const AddAppModal = forwardRef(function AddAppModal(
  {
    pickedApps,
    uniqueAppsConnectedToOrganization,
    isLoading,
    publicApps,
    ecosystemId,
    currentTab = 0,
    onChangeTab,
    onChangeText,
    onGetApp,
    onDone,
    onClose,
  }: AddAppModalProps,
  ref,
) {
  const {
    isOpen,
    selectedApp,
    handleOnClose,
    handleOnDone,
    handleGoBack,
    handleOnViewApp,
    isPickedAppDetails,
  } = useAddAppModalController(ref, onClose, onDone, pickedApps);

  return (
    <Center>
      <Modal isOpen={isOpen} onClose={handleOnClose}>
        <Modal.Content minW="980">
          <Modal.CloseButton />
          <Modal.Header borderBottomWidth="0">
            <VStack space="3">
              <Text fontSize="2xl">Add app</Text>
              <Text color="gray.600" fontSize="lg" fontWeight={400}>
                Find apps and add them into your ecosystem.
              </Text>
            </VStack>
          </Modal.Header>
          <Modal.Body>
            {selectedApp ? (
              <AppDetails
                app={selectedApp}
                isPicked={!!isPickedAppDetails}
                onGoBack={handleGoBack}
                onGetApp={onGetApp}
              />
            ) : (
              <AppsList
                pickedApps={pickedApps}
                onViewApp={handleOnViewApp}
                onGetApp={onGetApp}
                uniqueAppsConnectedToOrganization={
                  uniqueAppsConnectedToOrganization
                }
                isLoading={isLoading}
                publicApps={publicApps}
                ecosystemId={ecosystemId}
                onChangeText={onChangeText}
                onChangeTab={onChangeTab}
                currentTab={currentTab}
              />
            )}
          </Modal.Body>
          <Modal.Footer>
            <HStack w="full" space="3">
              <Button flex="1" variant="ghost" onPress={handleOnClose}>
                Cancel
              </Button>
              <Button
                flex="1"
                isDisabled={!pickedApps.length}
                onPress={handleOnDone}
              >
                Done
              </Button>
            </HStack>
          </Modal.Footer>
        </Modal.Content>
      </Modal>
    </Center>
  );
});
