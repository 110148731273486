import {
  TableOperatorNames,
  TableOperatorType,
} from 'ui/components/DataTable/types';

export const defaultFiltersValues = [
  {
    name: 'name',
    operator: TableOperatorNames.Contains,
    type: TableOperatorType.String,
    value: '',
  },
  {
    name: 'app',
    operator: TableOperatorNames.Equals,
    type: TableOperatorType.String,
    value: '',
  },
  {
    name: 'type',
    operator: TableOperatorNames.Equals,
    type: TableOperatorType.String,
    value: '',
  },
];

export const assignGroupDefaultFiltersValues = [
  {
    name: 'userSummary.name',
    operator: TableOperatorNames.Contains,
    type: TableOperatorType.String,
    value: '',
  },
];
