import { Stack } from 'native-base';
import { PoweredByDoit } from 'ui/components/v2/Labels/PoweredByDoit';

export const SwitchEcosystemFooter = () => {
  return (
    <Stack
      width="100%"
      alignItems="center"
      justifyContent="center"
      marginTop="auto"
      my={10}
    >
      <PoweredByDoit />
    </Stack>
  );
};
