import { useQuery } from '~/hooks/useQuery';

import { UserProfilePopulated } from '../../types/interfaces/userProfile';

export const useLoadMember = (userProfileId: string) => {
  const {
    data: member,
    isLoading: isLoadingMember,
    isRefetching: isRefetchingMember,
  } = useQuery<UserProfilePopulated>(`user-profile/${userProfileId}`, {
    key: `user-profile-${userProfileId}`,
    queryOptions: {
      enabled: !!userProfileId,
    },
  });

  return { member, isLoadingMember, isRefetchingMember };
};
