import { createAsyncThunk } from '@reduxjs/toolkit';

import { queryClient } from '~/config/react-query.config';
import { api } from '~/services/api';
import {
  SwitchEcosystemActionParams,
  SwitchEcosystemActionPayload,
} from '~/store/common/actions/ecosystem/types';
import { updatePartnerPermissionsAction } from '~/store/common/actions/permissions/updatePartnerPermissionsAction';
import { ecosystemSlice } from '~/store/slices/ecosystem';
import { redirectSliceActions } from '~/store/slices/redirect';

export const switchEcosystemAction = createAsyncThunk(
  'switchEcosystemAction',
  async (
    { organizationSelectedId, ecosystemId }: SwitchEcosystemActionParams,
    { dispatch },
  ): Promise<SwitchEcosystemActionPayload> => {
    try {
      const { data } = await queryClient.fetchQuery(
        ['ecosystem', organizationSelectedId],
        () =>
          api.get<SwitchEcosystemActionPayload['data']>(
            `ecosystem/organizations/${organizationSelectedId}`,
          ),
      );

      if (ecosystemId) {
        const ecosystem = data?.find(
          (ecosystems) => ecosystems._id === ecosystemId,
        );

        if (!ecosystem) {
          throw new Error(`Ecosystem not found`);
        }

        dispatch(ecosystemSlice.actions.selectEcosystem(ecosystem));
        await dispatch(updatePartnerPermissionsAction(ecosystem._id));
        dispatch(redirectSliceActions.clear());

        return { data, ecosystem };
      }

      return { data };
    } catch (error) {
      throw error;
    }
  },
);
