import { VStack } from 'native-base';
import { useState } from 'react';

import { ApplicationsByType } from '~/pages/Authenticated/Insights/components/Applications/ApplicationsByType';
import { ApplicationsTotal } from '~/pages/Authenticated/Insights/components/Applications/ApplicationsTotal';
import {
  InsightScaffold,
  InsightScaffoldChildrenReactComponent,
} from '~/pages/Authenticated/Insights/components/InsightScaffold';
import {
  InsightTabOption,
  InsightTabs,
} from '~/pages/Authenticated/Insights/components/InsightTabs';

import { ApplicationsProps } from './types';
import { INSIGHT_TABS, insightTabList } from './utils';

export const ApplicationsTabs: InsightScaffoldChildrenReactComponent = ({
  onSetChart,
}) => {
  const [selectedTab, setSelectedTab] = useState<InsightTabOption>(
    insightTabList?.[0],
  );

  const renderTabs = () => {
    switch (selectedTab.value) {
      case INSIGHT_TABS.BY_TYPE:
        return <ApplicationsByType onSetChart={onSetChart} />;
      case INSIGHT_TABS.TOTAL:
      default:
        return <ApplicationsTotal onSetChart={onSetChart} />;
    }
  };

  return (
    <VStack space={4} mx={6} mb={6}>
      <InsightTabs
        onChange={setSelectedTab}
        options={insightTabList}
        mb={6}
        w={['100%', '100%', '50%']}
      />

      {renderTabs()}
    </VStack>
  );
};

export const Applications = ({ className }: ApplicationsProps) => {
  return (
    <InsightScaffold
      className={className}
      title={{
        icon: 'user-check',
        name: 'Applications',
      }}
    >
      {ApplicationsTabs}
    </InsightScaffold>
  );
};
