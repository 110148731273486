import { Box, Hidden, Link, Stack, Text } from 'native-base';
import Button from 'ui/components/Button';
import Icon from 'ui/components/Icon';
import Input from 'ui/components/Input';
import { ORG_HELP_CENTER_URL } from 'ui/constants/commonUrl.constants';
import { colors } from 'ui/theme/colors';

import { HelpCentreRedirectLink } from '~/components/HelpCentre';

import { SignInPageProps } from '../types';

export const SignInPage = ({
  control,
  isDisabled,
  isSubmitting,
  errors,
  onForgotPasswordPress,
  onSubmit,
  onKeyDown,
  onSignUpPress,
}: SignInPageProps) => {
  return (
    <Stack
      width="100%"
      justifyContent={{ base: 'initial', lg: 'center' }}
      paddingLeft={{ base: 5, lg: '6.3rem' }}
      paddingRight={{ base: 5, lg: '6.3rem' }}
      padding={{ base: 2, lg: 0 }}
      bgColor={{ base: 'none', lg: colors.singletons.lightText }}
    >
      <Stack paddingTop={6}>
        <HelpCentreRedirectLink />
      </Stack>
      <Stack space={2} width="100%">
        <Text fontSize={{ base: 'xl', lg: '2xl' }} fontWeight={500}>
          Sign in to your account
        </Text>
        <Text fontSize={{ base: 'sm', lg: 'md' }}>
          or create a new account to join
        </Text>
      </Stack>

      <Stack mt={{ base: 6, lg: 10 }} space={{ base: 4, lg: 6 }} width="100%">
        <Input
          name="email"
          isRequired
          control={control}
          placeholder="Type your email"
          label="Email"
          errorMessage={errors.email?.message}
          iconErrorMessage="info"
          isFullWidth
          backgroundColor="white"
          borderColor="muted.200"
          leftIconName="user"
          leftIconColor={colors.gray[400]}
        />
        <Input
          name="password"
          control={control}
          isRequired
          placeholder="Type your password"
          type="password"
          label="Password"
          errorMessage={errors.password?.message}
          iconErrorMessage="info"
          isFullWidth
          backgroundColor="white"
          borderColor="muted.200"
          onKeyPress={onKeyDown}
          leftIconName="lock"
          leftIconColor={colors.gray[400]}
          rightIconColor={colors.gray[400]}
        />
        <Stack alignItems="center" justifyContent="center">
          <Link onPress={onForgotPasswordPress}>
            <Text underline fontWeight={500} color={colors.singletons.black}>
              I forgot my password
            </Text>
          </Link>
        </Stack>
      </Stack>
      <Button
        borderColor="gray.900"
        padding={6}
        mt={10}
        width={'100%'}
        _text={{
          fontWeight: 500,
        }}
        onPress={onSubmit}
        isLoading={isSubmitting}
        isDisabled={isDisabled}
        bgColor="gray.900"
      >
        Sign in
      </Button>
      <Stack
        space={{ base: 1, lg: 3 }}
        width={'100%'}
        mt={8}
        alignItems="center"
      >
        <Text fontSize="sm" fontWeight={400} color={colors.singletons.black}>
          You don’t have an account?
        </Text>

        <Button
          variant="unstyled"
          _text={{
            color: colors.singletons.black,
            fontWeight: 500,
            fontSize: 'md',
          }}
          onPress={onSignUpPress}
        >
          Sign up now
        </Button>
      </Stack>

      <Hidden from="sm">
        <Box h={10} />
      </Hidden>
    </Stack>
  );
};
