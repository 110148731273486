import { differenceInDays, isBefore, isSameDay, isValid } from 'date-fns';
import { EEventApplicationType } from 'ui/enums';
import { object, TestContext } from 'yup';

import { volunteerNumberSchemaValidation } from '~/pages/Authenticated/Events/schemaValidations';
import { ICreateEventDTO } from '~/types/dtos';
import { IAddress } from '~/types/interfaces/activity';
import Yup from '~/utils/validations/yup';

interface TestContextExtended {
  from: {
    value: ICreateEventDTO;
  }[];
}

export const datesSchemaValidation = Yup.object().shape(
  {
    startDate: Yup.date()
      .required()
      .nullable()
      .transform((_, originalValue) =>
        isValid(originalValue) ? originalValue : null,
      )
      .required()
      .test(
        'startDate',
        "The End Date can't be more that 3 days apart from the Start Date",
        (value, context) => {
          const { endDate } = context.parent;
          if (!value || !endDate) return true;
          const differenceBetweenStartAndEnd = differenceInDays(endDate, value);
          if (differenceBetweenStartAndEnd > 2) {
            return false;
          } else return true;
        },
      ),
    endDate: Yup.date()
      .nullable()
      .transform((_, originalValue) =>
        isValid(originalValue) ? originalValue : null,
      )
      .required()
      .test(
        'endDate',
        'The end date must be after the start date',
        (value, context) => {
          const { startDate } = context.parent;
          if (!value || !startDate) return true;

          return isBefore(startDate, value) || isSameDay(value, startDate);
        },
      ),
    dueDate: Yup.date().when('dueDate', {
      is: (value: Date | undefined) => isValid(value),
      then: Yup.date().test(
        'dueDate',
        'The due date must be within the event date',
        (value, context) => {
          const { endDate } = context.parent;
          if (!value || !endDate) return true;
          return isBefore(value, endDate) || isSameDay(value, endDate);
        },
      ),
      otherwise: Yup.date()
        .nullable()
        .transform((_, originalValue) =>
          isValid(originalValue) ? originalValue : null,
        ),
    }),
    noVolunteerLimit: Yup.boolean(),
    // @mycatdoitbetter TODO: Change this value tu number when the Input component can receive a number
    volunteerNumber: volunteerNumberSchemaValidation,
    teamsNumber: Yup.number().test('eventApplicationType', (value, context) => {
      const { from } = context as TestContext & TestContextExtended;
      const [, parent] = from;

      const teamsNumber = value;

      if (parent.value.eventApplicationType === EEventApplicationType.Team) {
        if (teamsNumber) {
          return true;
        }
        return false;
      } else {
        return true;
      }
    }),
    teamsMaxSize: Yup.number().test(
      'eventApplicationType',
      (value, context) => {
        const { from } = context as TestContext & TestContextExtended;
        const [, parent] = from;

        const teamsMaxSize = value;

        if (parent.value.eventApplicationType === EEventApplicationType.Team) {
          if (teamsMaxSize) {
            return true;
          }
          return false;
        } else {
          return true;
        }
      },
    ),
    teamsMinSize: Yup.number().test(
      'eventApplicationType',
      (value, context) => {
        const { from } = context as TestContext & TestContextExtended;
        const [currentTree, parent] = from;

        const teamsMinSize = value;
        const teamsMaxSize = currentTree.value.teamsMaxSize;

        if (parent.value.eventApplicationType === EEventApplicationType.Team) {
          if (teamsMinSize && teamsMaxSize) {
            if (teamsMaxSize > teamsMinSize) {
              return true;
            } else {
              return false;
            }
          }
          return false;
        } else {
          return true;
        }
      },
    ),
  },
  [
    ['dueDate', 'dueDate'],
    ['endDate', 'endDate'],
  ],
);

export const singleSchemaValidation = Yup.object().shape({
  address: Yup.object().test('attendanceType', async (value, context) => {
    const { from } = context as TestContext & TestContextExtended;
    const [, , parent] = from;

    const address = value;

    if (!address) return false;
    const isValid = await object()
      .test({
        name: 'address',
        test: (value: IAddress) => value && value.rawLocation !== null,
      })
      .validate(address);

    return !!isValid;
  }),
  dates: Yup.array().of(datesSchemaValidation),
});

export const formInitialDefaultValues = {
  address: undefined,
  dates: [
    {
      indexPosition: 0,
      placeFormIndex: 0,
      volunteerNumber: undefined,
      noVolunteerLimit: false,
      startDate: undefined,
      endDate: undefined,
      dueDate: undefined,
      teamsNumber: undefined,
      teamsMaxSize: undefined,
      teamsMinSize: undefined,
    },
  ],
};
