import { api } from '~/services/api';
import { ORGANIZATION_ORG_ADMIN_MODULE_URL } from '~/services/resources/organization/constants';
import {
  DepartmentsServiceCreateDTO,
  DepartmentsServiceUpdateDTO,
} from '~/services/resources/organization/departments/types';

export default class OrganizationDepartmentsService {
  static async create({ name, organizationId }: DepartmentsServiceCreateDTO) {
    const body = { name };
    return await api.post(
      `${ORGANIZATION_ORG_ADMIN_MODULE_URL}/${organizationId}/department`,
      body,
    );
  }

  static async update({
    name,
    organizationId,
    departmentId,
  }: DepartmentsServiceUpdateDTO) {
    const body = { name };
    return await api.put(
      `${ORGANIZATION_ORG_ADMIN_MODULE_URL}/${organizationId}/department/${departmentId}`,
      body,
    );
  }

  static async delete(
    organizationId: string,
    departmentId: string,
    force = false,
  ) {
    return await api.delete(
      `${ORGANIZATION_ORG_ADMIN_MODULE_URL}/${organizationId}/department/${departmentId}?force=${force}`,
    );
  }
}
