import { yupResolver } from '@hookform/resolvers/yup';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';

import { IFormBuilderProps } from './types';
import { schemeValidationGenerator } from './utils';

export const useFormBuilderController = ({
  options,
  defaultValues,
  onSubmit,
  isLoadingSubmit,
}: IFormBuilderProps) => {
  const { control, handleSubmit, formState, reset, watch } = useForm({
    mode: 'onBlur',
    defaultValues,
    resolver: yupResolver(
      options.customValidations
        ? options.customValidations
        : schemeValidationGenerator(options.inputs.items),
    ),
  });

  const handleSubmitForm = handleSubmit((data) => {
    if (onSubmit) {
      onSubmit(data);
    }
  });

  useEffect(() => {
    reset(defaultValues);
  }, [defaultValues, reset]);
  return {
    control,
    watch,
    options,
    handleSubmitForm,
    formState,
    isLoadingSubmit,
  };
};
