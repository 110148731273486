import { ActivityRegion } from 'ui/types/activities';

import { IAxiosResponse } from '~/types';

import { api } from '../api';

export default class ActivityRegionService {
  static PUBLIC_URL = 'activity-region-public';
  static async findAll(): Promise<IAxiosResponse<ActivityRegion[]>> {
    const response = await api.get(this.PUBLIC_URL, { params: { limit: 100 } });
    return response.data;
  }
}
