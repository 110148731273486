import { useEffect, useRef } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import Loading from 'ui/components/Loading';

import { PAGES } from '~/constants/pages.constants';

export const RedirectManager = () => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const isRedirectingRef = useRef(false);
  useEffect(() => {
    if (isRedirectingRef.current === true) return;
    isRedirectingRef.current = true;

    const redirectURL = searchParams.get('redirect');
    navigate(redirectURL || PAGES.ROOT);
    isRedirectingRef.current = false;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams]);

  return <Loading containerHeight="100vh" />;
};
