import { useRef, useState } from 'react';
import { ITabsRefProps } from 'ui/components/Tabs/types';

import { AdminsTabs } from '~/pages/Authenticated/Settings/AdminsSettings/types';

export const useAdminsSettinsController = () => {
  const [currentTab, setCurrentTab] = useState<AdminsTabs>(AdminsTabs.ADMINS);
  const tabsRef = useRef<ITabsRefProps>(null);
  const handleChangeTab = (tab: number) => {
    setCurrentTab(tab);
  };

  return {
    currentTab,
    tabsRef,
    handleChangeTab,
  };
};
