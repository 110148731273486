import {
  Avatar,
  Box,
  Divider,
  HStack,
  Pressable,
  Spinner,
  Stack,
  Text,
} from 'native-base';
import Button from 'ui/components/Button';
import Icon from 'ui/components/Icon';
import Illustration, { ILLUSTRATIONS_NAME } from 'ui/components/Illustration';
import ReadMore from 'ui/components/ReadMore';
import { getNameInitials } from 'ui/utils/getNameInititals';

import { EcosystemVisibility } from '~/enums';
import { colors } from '~/theme/colors';

import { AppCard } from './components/AppCard';
import { styles } from './styles';
import { useEcosystemsController } from './useEcosystemsController';

export const EcosystemDetails = () => {
  const {
    ecosystem,
    isLoadingEcosystem,
    connectedApps,
    isLoadingConnectedApps,
    goBack,
    handleEditEcosystem,
    handleManageApps,
    handleBackToEcosystems,
  } = useEcosystemsController();

  if (isLoadingEcosystem || isLoadingConnectedApps) {
    return <Spinner mt="6" />;
  }

  return (
    <Stack w="full">
      <Pressable
        flexDir="row"
        alignItems="center"
        onPress={handleBackToEcosystems}
      >
        <Icon icon="chevron-left" size="18" color={colors.black} />
        <Text ml="1">Back to Ecosystems</Text>
      </Pressable>

      <HStack mt="6" space="6">
        <Text color="gray.500" fontSize="2xl" fontWeight={700}>
          {ecosystem?.name}
        </Text>
        <HStack alignItems="center" space="1">
          <Icon
            size="18"
            color={colors.gray[400]}
            icon={
              ecosystem?.visibility === EcosystemVisibility.Private
                ? 'lock'
                : 'eye'
            }
          />
          <Text color="gray.400">
            {ecosystem?.visibility === EcosystemVisibility.Private
              ? 'Private'
              : 'Public'}
          </Text>
        </HStack>
      </HStack>

      <HStack
        mt="10"
        space="6"
        alignItems="center"
        justifyContent="space-between"
      >
        <Stack space="2">
          <Text color="gray.600" fontSize="lg" fontWeight={400}>
            About the ecosystem
          </Text>
          <Text color="gray.500">Basic information about this ecosystem</Text>
        </Stack>
        <Box>
          <Button
            leftIconName="edit"
            variant="outline"
            leftIconColor={colors.primary[600]}
            onPress={handleEditEcosystem}
          >
            Edit
          </Button>
        </Box>
      </HStack>

      <Divider mt="3" />

      <HStack mt="8" space="10" w="full">
        <Stack space="4">
          <Text color="gray.600" fontSize="md" fontWeight={400}>
            Logo image
          </Text>
          <Avatar source={{ uri: ecosystem?.logo }} w="130px" height="130px">
            {getNameInitials(ecosystem?.name || '')}
          </Avatar>
        </Stack>
        <Stack space="2" flex="1">
          <Text color="gray.600" fontSize="md" fontWeight={400}>
            Description
          </Text>

          <ReadMore
            textHeight="70px"
            btnBgColor="white"
            btnTextColor="darkText"
            rightIconColor="darkText"
            text={ecosystem?.description ?? ''}
          />
        </Stack>
      </HStack>

      <HStack
        mt="10"
        space="6"
        alignItems="center"
        justifyContent="space-between"
      >
        <Stack space="2">
          <Text color="gray.600" fontSize="lg" fontWeight={400}>
            Manage apps
          </Text>
          <Text color="gray.500">
            Enabled, disable, add or remove apps in this ecosystem
          </Text>
        </Stack>
        <Button
          leftIconName="edit"
          variant="outline"
          leftIconColor={colors.primary[600]}
          width="150px"
          onPress={handleManageApps}
        >
          Edit apps
        </Button>
      </HStack>

      <Divider mt="3" />

      <HStack mt="5" flexWrap="wrap" style={styles.gap}>
        {!connectedApps?.length ? (
          <HStack
            alignItems="center"
            justifyContent="center"
            space="2"
            w="100%"
          >
            <Illustration
              width="120px"
              height="120px"
              name={ILLUSTRATIONS_NAME.NO_SIGNAL_CONNECTION_ERROR_BLACK}
            />
            <Text color="gray.500" w="45%" textAlign="center">
              There are no apps in this ecosystem. To add apps, select{' '}
              <Text color="singletons.darkText" fontWeight="medium" w="45%">
                Edit apps.
              </Text>
            </Text>
          </HStack>
        ) : (
          <>
            {connectedApps?.map((appSharing) => (
              <Box key={appSharing.app._id} w="32%">
                <AppCard app={appSharing.app} />
              </Box>
            ))}
          </>
        )}
      </HStack>
    </Stack>
  );
};
