import Yup from '~/utils/validations/yup';

export const schemaValidation = Yup.object({
  name: Yup.string().when('currentStep', {
    is: 0,
    then: Yup.string().required(),
  }),
  description: Yup.string().when('currentStep', {
    is: 0,
    then: Yup.string().required().max(500),
  }),
  logo: Yup.mixed().when('currentStep', {
    is: 1,
    then: Yup.mixed().required(),
  }),
  apps: Yup.array(Yup.mixed()),
  isPrivate: Yup.boolean(),
  currentStep: Yup.number().required(),
});

export const defaultValues = {
  currentStep: 0,
  isPrivate: false,
};
