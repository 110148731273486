import { Spinner, Stack } from 'native-base';

import { LoadingProps } from './types';

export const Loading = ({
  containerHeight = '100vh',
  spinnerSize = 'lg',
}: LoadingProps) => {
  return (
    <Stack minH={containerHeight} justifyContent="center">
      <Spinner size={spinnerSize} />
    </Stack>
  );
};
