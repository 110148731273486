export const styleConfig: any = {
  containerPadding: {
    base: 4,
    md: 24,
  },
  containerJustifyContent: {
    base: 'initial',
    lg: 'center',
  },
  horizontalStackDirection: {
    base: 'column',
    lg: 'row',
  },
  illustrationSize: { base: '168px', md: '300px', lg: '496px' },
  vStackMargin: { lg: '20' },
  vStackWidth: { base: '100%', md: '70%', lg: '70%' },
  progressMargin: { base: 12, md: 0 },
  pageTitleFontSize: { base: 'xl', md: '2xl' },
  pageTextTitleColor: { base: 'gray.600', md: 'gray.900' },
  pageTextSubTitleColor: { base: 'gray.500', md: 'gray.900' },
  buttonContinueDisplay: { base: 'none', md: 'flex' },
  pageTitleWeight: { base: 700, md: 500 },
  pageSubTitleWeight: { base: 500, md: 400 },
  stackSpace: { md: 2 },
  progressBar: {
    bg: 'primary.200',
  },
};
