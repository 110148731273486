import { Box, Divider, HStack, Stack, Text, VStack } from 'native-base';
import { Controller } from 'react-hook-form';
import Button from 'ui/components/Button';
import DataTable from 'ui/components/DataTable';
import { TableSizeClass } from 'ui/components/DataTable/@types/basicTypes';
import Icon from 'ui/components/Icon';
import { ModalInformation } from 'ui/components/ModalInformation';
import { LogInput } from 'ui/components/v2/inputs/LogInput';
import { colors } from 'ui/theme/colors';

import { SettingsSectionHeader } from '~/pages/Authenticated/Settings/components/SectionHeaders/SettingsSectionHeader';
import { DeleteDepartmentModal } from '~/pages/Authenticated/Settings/General/components/Modals/DeleteDepartmentModal';
import { DepartmentFormModal } from '~/pages/Authenticated/Settings/General/components/Modals/DepartmentFormModal';

import { useCompanyDetailsSectionController } from './controller';
import { CompanyDetailsSectionProps } from './types';
import { MapOrganizationDepartmentsToTableColumn } from './utils';

export const CompanyDetailsSection = (props: CompanyDetailsSectionProps) => {
  const { editMode = false, control } = props;
  const {
    isNFP,
    selectedDepartment,
    departmentModalRef,
    companyGoalHelpModalRef,
    deleteDepartmentModalRef,
    companyMeasurementGoal,
    handleOpenDeleteDepartmentModal,
    handleOpenDepartmentModal,
    openCompanyGoalHelpModal,
    handleSubmitSuccess,
    handleLoadData,
  } = useCompanyDetailsSectionController(props);

  if (!!isNFP) return null;

  return (
    <>
      <VStack mt={12} space={6}>
        {/* Header */}
        <SettingsSectionHeader title="Company details" primary />

        {/* Body */}
        <VStack space={8}>
          {/* Company Goals section */}
          <VStack space={2}>
            <SettingsSectionHeader
              title="Company Goal"
              showTitleHelpButton
              onTitleHelpButtonPress={openCompanyGoalHelpModal}
            />

            {editMode && control ? (
              <Stack maxW={32}>
                <Controller
                  control={control}
                  name={'measurementGoal'}
                  render={({ field: { onChange, value } }) => (
                    <LogInput.Time
                      isDisabled={!editMode}
                      value={value}
                      onChange={onChange}
                      showMinutes={false}
                    />
                  )}
                />
              </Stack>
            ) : (
              <Stack space={2}>
                <Text
                  fontSize="sm"
                  fontWeight="400"
                  color={colors.singletons.darkText}
                >
                  {companyMeasurementGoal}
                </Text>
              </Stack>
            )}
          </VStack>

          {/* Departments Section */}
          {!editMode && (
            <VStack space={6}>
              <SettingsSectionHeader
                title="Groups"
                subTitle="Here you can list groups within your company, like departments, units, teams or segments."
                actionSlot={
                  <Button
                    backgroundColor={'#EBF3F2'}
                    onPress={() => handleOpenDepartmentModal()}
                    variant={'subtle'}
                    py={5}
                  >
                    <HStack
                      justifyContent={'center'}
                      alignItems={'center'}
                      space="0.65rem"
                    >
                      <Icon
                        enableAutoCustom
                        color={colors.primary[400]}
                        icon={'plus-circle'}
                        size={'22px'}
                      />
                      <Text
                        numberOfLines={1}
                        fontSize={{ base: 'sm', md: 'md' }}
                        fontWeight={500}
                        color={colors.primary[400]}
                      >
                        Add group
                      </Text>
                    </HStack>
                  </Button>
                }
              />

              <VStack w="full" overflow={'hidden'}>
                <DataTable
                  style={{ borderColor: colors.gray[300] }}
                  pagination={false}
                  className={TableSizeClass.Small}
                  emptyText="Add groups to view them here."
                  loadData={handleLoadData as any}
                  columns={MapOrganizationDepartmentsToTableColumn({
                    onDeleteAction: handleOpenDeleteDepartmentModal,
                    onEditAction: handleOpenDepartmentModal,
                  })}
                />
              </VStack>
            </VStack>
          )}
        </VStack>
      </VStack>
      {/* Modals */}
      <DepartmentFormModal
        ref={departmentModalRef}
        defaultDepartmentData={selectedDepartment}
        onSuccess={handleSubmitSuccess}
      />

      <DeleteDepartmentModal
        ref={deleteDepartmentModalRef}
        department={selectedDepartment}
        onSuccess={handleSubmitSuccess}
      />

      <ModalInformation
        headerText="Company Goal"
        modalRef={companyGoalHelpModalRef}
      >
        <VStack pt={4}>
          <Text mt={2} fontSize="md" color="gray.600">
            The company goal is the amount of volunteering hours your company
            wishes to achieve annually.
          </Text>
          <Box h={12} />
        </VStack>
      </ModalInformation>
    </>
  );
};
