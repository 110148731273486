import { IAxiosResponse } from '~/types';
import {
  ICreateActivityDTO,
  ICreateEventDTO,
  ICreateOpportunityDTO,
} from '~/types/dtos';
import { IActivity } from '~/types/interfaces/activity';

import { api } from '../api';

const MODULE_URL = '/activity';

// FIXME: Merge with ActivityService (no clue why there are 2 services for the same thing)
export default class ActivitiesService {
  static async findAll(data: object): Promise<IAxiosResponse<IActivity>> {
    const response = await api.get(`${MODULE_URL}`, {
      params: {
        ...data,
      },
    });
    return response;
  }

  static async findOne(id: string): Promise<IAxiosResponse<IActivity>> {
    const response = await api.get(`${MODULE_URL}-definition/${id}`);
    return response;
  }

  static async updateOne(
    id: string,
    data: Partial<IActivity>,
  ): Promise<IAxiosResponse<IActivity>> {
    const response = await api.put(`${MODULE_URL}-definition/${id}`, data);
    return response;
  }

  static async createActivityEvent(
    data: Partial<ICreateEventDTO>,
  ): Promise<IAxiosResponse<IActivity>> {
    return await api.post(`${MODULE_URL}/event`, data);
  }

  static async createActivityOpportunity(
    data: Partial<ICreateOpportunityDTO>,
  ): Promise<IAxiosResponse<IActivity>> {
    return await api.post(`${MODULE_URL}/ongoing-opportunity`, data);
  }

  static async editActivityEvent(
    id: string,
    data: Partial<ICreateEventDTO>,
  ): Promise<IAxiosResponse<IActivity>> {
    return await api.put(`${MODULE_URL}/event/${id}`, data);
  }

  static async createActivityAction(
    activityData: Partial<ICreateActivityDTO>,
  ): Promise<IAxiosResponse<IActivity>> {
    const response = await api.post(`${MODULE_URL}/action`, activityData);
    return response;
  }
}
