import { AnimatePresence, motion } from 'framer-motion';
import { Button as UIButton, Text } from 'native-base';
import Icon from 'ui/components/Icon';

export type RefetchButtonProps = {
  label?: string;
  onPress?: VoidFunction;
};

export const RefetchButton = ({
  label = 'New updates',
  onPress,
}: RefetchButtonProps) => {
  return (
    <AnimatePresence>
      <motion.div
        initial={{ opacity: 0, y: -96 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ type: 'spring', stiffness: 100, duration: 1 }}
      >
        <UIButton
          p={6}
          w="fit-content"
          borderRadius={100}
          onPress={onPress}
          variant="solid"
          backgroundColor="black"
          shadow="4"
          leftIcon={
            <Icon size={16} enableAutoCustom icon="arrow-up" color="white" />
          }
        >
          <Text fontWeight={500} fontSize={'sm'} color={'white'}>
            {label}
          </Text>
        </UIButton>
      </motion.div>
    </AnimatePresence>
  );
};
