import { HStack, VStack } from 'native-base';
import { useMemo } from 'react';
import { App } from 'ui/types/interfaces/app';

import { AppSelectItem } from './AppSelectItem';
import { AppSelectorProps } from './types';

export const AppSelector = (params: AppSelectorProps) => {
  return (
    <VStack
      w={'full'}
      flex={1}
      overflow={'hidden'}
      borderWidth={1}
      p={3}
      background={'white'}
      borderRadius={8}
      borderColor={'gray.200'}
    >
      <AppSelectorFilter {...params} />
    </VStack>
  );
};

export const AppSelectorFilter = ({
  apps,
  onChange,
  values,
}: AppSelectorProps) => {
  const handleItemChange = (app?: App) => {
    onChange(app?._id || '');
  };

  const isAllSelected = useMemo(() => {
    return values.length === apps.length;
  }, [apps, values]);

  return (
    <HStack space={2} alignItems={'center'} overflowX={'auto'} flex={1}>
      <AppSelectItem
        key={'all'}
        label={'All Apps'}
        isActive={isAllSelected}
        onPress={() => handleItemChange()}
        showIcon
        showLabel
      />
      {apps.map((app) => {
        const isActive = !isAllSelected && values.includes(app._id);
        return (
          <AppSelectItem
            key={app._id}
            label={app.name}
            imageUrl={app.logo}
            isActive={isActive}
            showLabel={isActive}
            onPress={() => handleItemChange(app)}
          />
        );
      })}
    </HStack>
  );
};
