import { PresenceTransition, Stack, VStack } from 'native-base';
import FormHeader from 'ui/components/FormBuilderV2/components/FormHeader';
import Radio from 'ui/components/Radio';
import { LocationOptionsEnum } from 'ui/enums';
import { colors } from 'ui/theme/colors';
import { ActivityType } from 'ui/types/activities';

import { FromHome } from '~/components/FromHome';
import { locationOptions } from '~/constants/forms.constants';

import { MultipleLocationsOngoing } from '../../../Forms/MultipleLocationsOngoing';
import { SingleLocationOngoing } from '../../../Forms/SingleLocationOngoing';
import { useWhenWhereFormController } from './controller';
import { WhenWhereFormProps } from './types';

export const WhenWhereForm = ({
  handleContinue,
  handleUpdateLocationOption,
  isLoading,
  handleBack,
}: WhenWhereFormProps) => {
  const { control, currentLocationType } = useWhenWhereFormController();

  const formsByLocationType = () => {
    return {
      [LocationOptionsEnum.SingleLocation]: (
        <PresenceTransition
          visible={currentLocationType === LocationOptionsEnum.SingleLocation}
          style={{
            width: '100%',
          }}
          initial={{ opacity: 0, scaleX: 0.9 }}
          animate={{ opacity: 1, scaleX: 1, transition: { duration: 300 } }}
        >
          <SingleLocationOngoing
            handleBack={handleBack}
            handleContinue={handleContinue}
            handleUpdateLocationOption={handleUpdateLocationOption}
            isLoading={isLoading}
          />
        </PresenceTransition>
      ),
      [LocationOptionsEnum.MultipleLocations]: (
        <PresenceTransition
          visible={
            currentLocationType === LocationOptionsEnum.MultipleLocations
          }
          style={{
            width: '100%',
          }}
          initial={{ opacity: 0, scaleX: 0.9 }}
          animate={{ opacity: 1, scaleX: 1, transition: { duration: 300 } }}
        >
          <MultipleLocationsOngoing
            handleBack={handleBack}
            handleContinue={handleContinue}
            isLoading={isLoading}
          />
        </PresenceTransition>
      ),
      [LocationOptionsEnum.FromHome]: (
        <PresenceTransition
          visible={currentLocationType === LocationOptionsEnum.FromHome}
          style={{
            width: '100%',
          }}
          initial={{ opacity: 0, scaleX: 0.9 }}
          animate={{ opacity: 1, scaleX: 1, transition: { duration: 300 } }}
        >
          <FromHome
            handleContinue={handleContinue}
            handleBack={handleBack}
            activityType={ActivityType.Ongoing}
            isLoading={isLoading}
          />
        </PresenceTransition>
      ),
      [LocationOptionsEnum.InPerson]: null, // Avoid Lint error for now
    }[currentLocationType];
  };
  return (
    <VStack w="100%" space={4}>
      <FormHeader
        title="When and where"
        subTitle="Specify date and place of the event"
      />

      <VStack>
        <Stack space={6} pt="6">
          <Radio
            label="Location options"
            isRequired
            control={control}
            name="locationOption"
            flexDirection="row"
            checkedColor={colors.primary[600]}
            options={locationOptions}
            onChangeValue={(value) =>
              handleUpdateLocationOption(value as LocationOptionsEnum)
            }
          />
        </Stack>

        <Stack minH="40vh">{formsByLocationType()}</Stack>
      </VStack>
    </VStack>
  );
};
