import { LocationDataStatus } from 'ui/enums';
import { LocationData } from 'ui/types/interfaces/activity-forms';

import { IDatesDTO } from '~/types/dtos';

export const queryFilter = {
  causeOptions: {
    filter: JSON.stringify({ relatedTo: ['causeOptions'] }),
  },
  requirementOptions: {
    filter: JSON.stringify({ relatedTo: ['requirementOptions'] }),
  },
};

export const parseActivitiesData = (allActivitiesByDefinitonData: any) => {
  // Single Location and From Home Opportunities share the same regions and meetingLink accross all places/dates, so we need only to get the first value on the activities list.
  const activityRegions = allActivitiesByDefinitonData?.[0]?.regions || [];
  const activityMeetingLink =
    allActivitiesByDefinitonData?.[0]?.meetingLink || null;

  const [lng, lat] = allActivitiesByDefinitonData?.[0]?.address?.location
    ?.coordinates || [0, 0];

  const rawLocation = allActivitiesByDefinitonData?.[0]?.address?.street || '';

  const address = {
    lat,
    lng,
    placeID: null,
    rawLocation: rawLocation,
  };

  const parsedActivity = {
    address,
    noVolunteerLimit:
      !!allActivitiesByDefinitonData?.[0]?.isVolunteerNumberLimited,
    volunteerNumber: allActivitiesByDefinitonData?.[0]?.volunteerNumber || 0,
    _activityId: allActivitiesByDefinitonData?.[0]?._id,
    externalApplyLink: allActivitiesByDefinitonData?.[0]?.externalApplyLink,
    regions: activityRegions,
    meetingLink: activityMeetingLink,
  };

  return parsedActivity;
};
export const mapMultipleLocationsFormValue = (
  allActivitiesByDefinitionData: any,
) => {
  const locationGroups: Partial<IDatesDTO>[] = [];

  let locationGroupsByVolunteerLimit: Record<number, LocationData[]> = {};

  try {
    for (const [index, activity] of allActivitiesByDefinitionData.entries()) {
      const alreadyExistsLocationGroupIndex = locationGroups?.findIndex(
        (locationGroup) => {
          const isEqualVolunteerNumber =
            locationGroup.volunteerNumber && activity.volunteerNumber
              ? locationGroup.volunteerNumber === activity.volunteerNumber
              : true;

          const isEqualNoLimitVolunteer =
            !locationGroup.noVolunteerLimit ===
            activity.isVolunteerNumberLimited;

          return isEqualVolunteerNumber && isEqualNoLimitVolunteer;
        },
      );
      const [lng, lat] = activity.address?.location?.coordinates;

      const locationData: LocationData = {
        returnedAddress: activity.address.street,
        sentInput: activity.address.street,
        status: LocationDataStatus.OK,
        location: { lat, lng },
        _activityId: activity._id,
      };

      if (alreadyExistsLocationGroupIndex === -1) {
        const locationGroup: IDatesDTO = {
          noVolunteerLimit: !activity.isVolunteerNumberLimited,
          volunteerNumber: activity.volunteerNumber ?? '',
          _activityId: activity._id,
          externalApplyLink: activity?.externalApplyLink,
        };

        locationGroups.push(locationGroup);

        const locationsGroupsKeys = Object.keys(locationGroupsByVolunteerLimit);

        locationGroupsByVolunteerLimit = {
          ...locationGroupsByVolunteerLimit,
          [locationsGroupsKeys.length]: [{ ...locationData }],
        };
      } else {
        locationGroupsByVolunteerLimit = {
          ...locationGroupsByVolunteerLimit,
          [alreadyExistsLocationGroupIndex]: [
            ...locationGroupsByVolunteerLimit[alreadyExistsLocationGroupIndex],
            locationData,
          ],
        };
      }
    }
    return { locationGroups, locationGroupsByVolunteerLimit };
  } catch (error) {
    throw new Error('Error Loading Places');
  }
};
