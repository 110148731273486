import { Box, HStack, Stack, Text } from 'native-base';
import { forwardRef } from 'react';
import Modal from 'ui/components/Modals/Modal';

import ModalNAButton from './ModalNAButton';
import { ModalAddMembersProps } from './types';

const ModalAddMembers = forwardRef(function ModalNewActivity(
  { onAddOrgMember, onAddPartner, onAddChildOrg }: ModalAddMembersProps,
  ref,
) {
  const addButtonData = [
    {
      label: 'Add Org Member',
      description: 'Invite new members to your organisation.',
      action: onAddOrgMember,
    },
    {
      label: 'Add Child Org',
      description: 'Invite a child organisation.',
      action: onAddChildOrg,
    },
    {
      label: 'Add Partner',
      description: 'Invite a partner and grow your network.',
      action: onAddPartner,
    },
  ];

  return (
    <Box>
      <Modal headerText="Add member" ref={ref} modalContentMaxW="640">
        <Stack py="2" space={6} alignSelf="center">
          <Stack>
            <Text color="gray.600" fontWeight={400} fontSize={16}>
              What do you want to add?
            </Text>
          </Stack>

          {/*TODO: Check width as appointed by Andre*/}
          <HStack space={2} justifyContent={'center'} flexWrap="wrap">
            {addButtonData.map((button, index) => (
              <Stack key={index} w="12rem" my={1}>
                <ModalNAButton
                  label={button.label}
                  description={button.description}
                  action={button.action}
                />
              </Stack>
            ))}
          </HStack>
        </Stack>
      </Modal>
    </Box>
  );
});

export default ModalAddMembers;
