import { Tooltip, VStack } from 'native-base';
import { useCallback } from 'react';
import { IFilterValue } from 'ui/components/DataTable/types';
import Tag from 'ui/components/Tags/Tag';

import { getTabStyle } from '~/pages/Authenticated/BulkUploads/components/ImportsTable/utils';
import {
  IImport,
  IImportParsedData,
} from '~/pages/Authenticated/BulkUploads/types';
import { ImportFilesServices } from '~/services/resources/import-files';

interface ITableCell {
  data: IImport<IImportParsedData>;
}

export const useImportsTableController = (importFileId: string) => {
  const activityColumns = [
    {
      name: 'data',
      defaultFlex: 0.4,
      header: 'ID',
      render: ({ data: { data } }: ITableCell) => data?.identifier || '',
    },
    {
      name: 'data',
      defaultFlex: 1,
      header: 'Title',
      render: ({ data: { data } }: ITableCell) => data?.title || '',
    },
    {
      name: 'data',
      defaultFlex: 1,
      header: 'Type',
      render: ({ data: { data } }: ITableCell) => data?.type || '',
    },
    {
      name: 'status',
      defaultFlex: 1,
      header: 'status',
      render: ({ data }: ITableCell) => {
        const { backgroudColor, icon, textColor } = getTabStyle(data.status);
        return (
          <Tooltip label={data.error} openDelay={300}>
            <VStack>
              <Tag
                text={data.status}
                tagTextColor={textColor}
                showIcon
                tagBgColor={backgroudColor}
                icon={icon}
              />
            </VStack>
          </Tooltip>
        );
      },
    },
  ];

  const placesColumns = [
    {
      name: 'data',
      defaultFlex: 0.4,
      header: 'ID',
      render: ({ data: { data } }: ITableCell) =>
        data?.activity_identifier || '',
    },
    {
      name: 'data',
      defaultFlex: 1,
      header: 'Address',
      render: ({ data: { data } }: ITableCell) => data?.address || '',
    },
    {
      name: 'status',
      defaultFlex: 1,
      header: 'status',
      render: ({ data }: ITableCell) => {
        const { backgroudColor, icon, textColor } = getTabStyle(data.status);

        return (
          <Tooltip label={data.error} openDelay={300}>
            <VStack>
              <Tag
                text={data.status}
                tagTextColor={textColor}
                showIcon
                tagBgColor={backgroudColor}
                icon={icon}
              />
            </VStack>
          </Tooltip>
        );
      },
    },
  ];

  const loadData = useCallback(
    async (skip: number, limit: number, filter: IFilterValue) => {
      const params = {
        filter: JSON.stringify(filter),
        skip,
        limit,
      };

      const { data, count } = await ImportFilesServices.getImports(
        importFileId,
        params,
      );

      const parsedData = data.map((responseData: IImport<string>) => {
        return {
          ...responseData,
          data: JSON.parse(responseData.data),
        };
      });

      return {
        data: parsedData,
        count,
      };
    },
    [importFileId],
  );

  return {
    activityColumns,
    placesColumns,
    loadData,
  };
};
