import { UserNotificationCardProps } from './types';

const USER_PROFILE_BASE_ROUTE = '/members/:id';

export const userUserNotificationCardController = ({
  profilePictureUrl,
  userProfileId,
  userName,
  date,
}: UserNotificationCardProps) => {
  const userProfileUrl = USER_PROFILE_BASE_ROUTE.replace(':id', userProfileId);

  const authorTitleNode = {
    text: userName || 'Unnamed',
    linkUrl: userProfileUrl,
  };

  return {
    authorTitleNode,
    profilePictureUrl,
    date,
  };
};
