import { useRef, useState } from 'react';
import { toast } from 'react-toastify';
import { IModalRefProps } from 'ui/components/Modals/Modal/types';

import { useLoadOrganization } from '~/hooks/useLoadOrganization';
import { colors } from '~/theme/colors';

import { ORGANIZATION_MESSAGES } from '../../../../../constants/messages.constants';
import { useAppSelector } from '../../../../../hooks/useAppSelector';
import { useLoadMember } from '../../../../../hooks/useLoadMember';
import { useRouter } from '../../../../../hooks/useRouter';
import { EcosystemService } from '../../../../../services/resources/ecosystem';

export const useMemberController = () => {
  const [tabIndex, setTabIndex] = useState(0);
  const modalRef = useRef<IModalRefProps>(null);
  const { selectedEcosystem } = useAppSelector(({ ecosystem }) => ecosystem);

  const { params } = useRouter();
  const userProfileId = params.id as string;
  const { member, isLoadingMember } = useLoadMember(userProfileId);
  const [isRemoveMemberLoading, setIsRemoveMemberLoading] = useState(false);

  const { data: organization, isLoading: isLoadingOrganization } =
    useLoadOrganization(selectedEcosystem?.organization || '');

  const handleRemoveMember = async () => {
    if (!selectedEcosystem?._id || !member?.user?._id) {
      handleCloseModal();
      return;
    }
    try {
      setIsRemoveMemberLoading(true);
      await EcosystemService.disconnectMember({
        ecosystemId: selectedEcosystem._id,
        userId: member?.user?._id as string,
      });
      toast.success(ORGANIZATION_MESSAGES.REMOVE_MEMBER_SUCCESS);
    } catch (error) {
      toast.error(ORGANIZATION_MESSAGES.ERROR_ON_REMOVE_MEMBER);
    } finally {
      setIsRemoveMemberLoading(false);
      handleCloseModal();
    }
  };

  // const handleResendInvitation = () => {
  //   console.log('resend invitation');
  // };

  const handleChangeTab = (index: number) => {
    setTabIndex(index);
  };

  const handleOpenModal = () => {
    modalRef.current?.open();
  };

  const handleCloseModal = () => {
    modalRef.current?.close();
  };

  const popOverOptions = [
    // {
    //   icon: {
    //     color: colors.gray[600],
    //     icon: 'send',
    //     size: 22,
    //   },
    //   title: 'Resend invitation',
    //   action: handleResendInvitation,
    // },
    {
      icon: {
        color: colors.error[600],
        icon: 'x-circle',
        size: 22,
      },
      title: 'Remove member',
      action: handleOpenModal,
    },
  ];

  return {
    organization,
    isLoadingOrganization,
    member,
    popOverOptions,
    tabIndex,
    modalRef,
    isLoadingMember,
    isRemoveMemberLoading,
    handleRemoveMember,
    handleOpenModal,
    handleChangeTab,
    handleCloseModal,
  };
};
