import { useState } from 'react';
import { Area, Point } from 'react-easy-crop';

import { ImageCropperProps } from './types';
import { getCroppedImg, mirrorImage } from './utils';

export const ImageCropperController = ({
  src,
  onSubmit,
}: ImageCropperProps) => {
  const [imageSrc, setImageSrc] = useState(src);
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [croppedImage, setCroppedImage] = useState<string | null>(null);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState<Area | null>(null);
  const [zoom, setZoom] = useState(1);

  const onCropComplete = (croppedArea: any, croppedAreaPixels: Area) => {
    setCroppedAreaPixels(croppedAreaPixels);
  };

  const handleSubmit = async () => {
    try {
      const croppedImage = await getCroppedImg(imageSrc, croppedAreaPixels, 0);
      setCroppedImage(croppedImage);
      onSubmit?.(croppedImage);
    } catch (e) {
      console.error(e);
    }
  };

  const handleHorizontalFlip = async () => {
    const mirroredImage = await mirrorImage(imageSrc);
    if (!!mirroredImage) {
      setImageSrc(mirroredImage);
    }
  };

  const handleCropChange = (location: Point) => {
    setCrop(location);
  };

  const handleZoomChange = (zoomValue: number) => {
    setZoom(zoomValue);
  };

  return {
    crop,
    zoom,
    imageSrc,
    croppedImage,
    handleHorizontalFlip,
    handleSubmit,
    onCropComplete,
    handleCropChange,
    handleZoomChange,
  };
};
