import { useImperativeHandle, useState } from 'react';

import { ITabNavigatorRefProps, TabNavigatorProps } from './types';

function resolveLimits(index: number, total: number) {
  return Math.max(0, Math.min(index, total - 1));
}

export const useTabNavigatorController = ({
  ref,
  onChange,
  tabs,
}: TabNavigatorProps) => {
  const [selectedIndex, setSelectedIndex] = useState(0);
  const total = tabs?.length || 0;

  useImperativeHandle(
    ref,
    (): ITabNavigatorRefProps => {
      return {
        next: () => setSelectedIndex((prev) => resolveLimits(prev + 1, total)),
        prev: () => setSelectedIndex((prev) => resolveLimits(prev - 1, total)),
        jump: (index) => setSelectedIndex(resolveLimits(index, total)),
      };
    },
    [total],
  );

  const handleTabPress = (index: number, value?: string) => {
    setSelectedIndex(index);
    onChange?.(index, value);
  };

  return { selectedIndex, handleTabPress };
};
