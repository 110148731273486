import { useAppSelector } from '~/hooks/useAppSelector';
import { useQuery } from '~/hooks/useQuery';
import { PopulatedInvitePartners } from '~/types/interfaces/invite-partner';

export const useLoadUsedPartnerInvite = (code = '', email = '') => {
  const { isAuthenticated } = useAppSelector(({ auth }) => auth);

  const { data, isLoading } = useQuery<PopulatedInvitePartners>(
    `invite-partners/used`,
    {
      requestOptions: {
        params: {
          code,
          email,
        },
      },
      queryOptions: { enabled: !!code && !!isAuthenticated && !!email },
      key: ['used-partner-invite', code, email],
    },
  );

  return { data, isLoading };
};
