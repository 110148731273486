/* Applications Insights */

export const INSIGHT_APPLICATIONS_BASE_URL =
  'org-admin/insights/:organizationId/applications';

export const INSIGHT_SPACE_OPTIONS_BASE_URL = INSIGHT_APPLICATIONS_BASE_URL;

export const INSIGHT_TRENDING_BASE_URL = `${INSIGHT_APPLICATIONS_BASE_URL}/trending`;

export const INSIGHT_MOST_COMPLETED_ACTIVITIES_URL = `${INSIGHT_APPLICATIONS_BASE_URL}/most-completed-activities`;

/* User profiles Insights */

export const INSIGHT_USER_PROFILE_BASE_URL =
  '/org-admin/insights/:organizationId/user-profiles';

export const INSIGHT_USER_PROFILE_ENGAGEMENT = `${INSIGHT_USER_PROFILE_BASE_URL}/employee-engagement`;

/*  Measurements Insights */

export const INSIGHT_MEASUREMENT_BASE_URL =
  '/org-admin/insights/:organizationId/measurements';
