import { debounce } from 'lodash';
import { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';

import { PAGES } from '~/constants/pages.constants';
import { useAppDispatch } from '~/hooks/useAppDispatch';
import { useAppSelector } from '~/hooks/useAppSelector';
import { useSwitchNotifyPersona } from '~/hooks/useNotify/switchNotifyEcosystemNotify';
import { usePermissions } from '~/hooks/usePermissions';
import { useRouter } from '~/hooks/useRouter';
import { useSetEcosystemsToSelectOptions } from '~/hooks/useSetEcosystemsToSelectOptions';
import { switchEcosystemAction } from '~/store/common/actions/ecosystem/switchEcosystem';
import { SwitchEcosystemActionPayload } from '~/store/common/actions/ecosystem/types';
import { IEcosystem } from '~/types/interfaces/ecosystem';

const DEBOUNCE_TIME = 500;

export const useSwitchEcosystemController = () => {
  const { organizations, ecosystem, organizationSelectedId } = useAppSelector(
    ({ auth }) => auth,
  );
  const [searchText, setSearchText] = useState('');
  const [isChangingEcosystem, setIsChangingEcosystem] = useState(false);

  const [isSearching, setIsSearching] = useState(false);
  const { handleUpdatePartnerPermissions } = usePermissions();

  const {
    ecosystemLogo,
    ecosystems,
    selectedEcosystem,
    onChangeSelectedEcosystem,
  } = useSetEcosystemsToSelectOptions();

  const { control } = useForm();

  const { goBack, replaceRoute } = useRouter();

  const { handleEcosystemSwitch } = useSwitchNotifyPersona();

  const dispatch = useAppDispatch();

  const handleOnChangeText = debounce((searchText: string) => {
    setSearchText(searchText);
  }, DEBOUNCE_TIME);

  const filteredEcosystems = useMemo(() => {
    if (searchText === '') {
      return ecosystems || [];
    }
    const filteredResults = ecosystems?.filter((ecosystem) => {
      return (
        ecosystem._id.toLowerCase().includes(searchText) ||
        ecosystem.name.toLowerCase().includes(searchText.toLowerCase())
      );
    });
    return filteredResults;
  }, [searchText, ecosystems]);

  useEffect(() => {
    const debouncedSetIsSearching = debounce(
      () => setIsSearching(false),
      DEBOUNCE_TIME,
    );
    setIsSearching(true);
    debouncedSetIsSearching();
    return () => debouncedSetIsSearching.cancel();
  }, [searchText]);

  const handleChangeEcosystem = async (ecosystemId: string) => {
    setIsChangingEcosystem(true);
    await dispatch(
      switchEcosystemAction({ organizationSelectedId, ecosystemId }),
    )
      .then((result) => {
        const { ecosystem } = result.payload as SwitchEcosystemActionPayload;
        handleEcosystemSwitch(ecosystem);
      })
      .catch(() => {
        toast.error(`Error switch ecosystem`, {
          autoClose: 3000,
        });
      })
      .finally(() => {
        setIsChangingEcosystem(false);
        replaceRoute(PAGES.ROOT);
      });
  };

  const currentOrganization = organizations?.find(
    (org) => org._id === organizationSelectedId,
  );

  return {
    organizations,
    ecosystem,
    filteredEcosystems,
    handleOnChangeText,
    isSearching,
    control,
    ecosystemLogo,
    ecosystems,
    selectedEcosystem,
    handleChangeEcosystem,
    goBack,
    isChangingEcosystem,
    currentOrganization,
  };
};
