import { volunteerNumberSchemaValidation } from '~/pages/Authenticated/Events/schemaValidations';
import Yup from '~/utils/validations/yup';

export const locationGroupsSchemaValidation = Yup.object().shape({
  noVolunteerLimit: Yup.boolean(),
  // @mycatdoitbetter TODO: Change this value tu number when the Input component can receive a number
  volunteerNumber: volunteerNumberSchemaValidation,
});
export const schemaValidation = Yup.object().shape({
  locationsGroups: Yup.array().of(locationGroupsSchemaValidation),
});

export const formInitialDefaultValues = {
  locationsGroups: [
    {
      noVolunteerLimit: false,
    },
  ],
};
