import { Box, HStack, Image } from 'native-base';
import DataTableActions from 'ui/components/DataTableActions';
import Icon from 'ui/components/Icon';

import { colors } from '~/theme/colors';
import { TableColumnsFunctions } from '~/types';
import { AppConnectedEcosystemsTable } from '~/types/interfaces/table';

import { EcosystemVisibility } from '../../../../enums';
import { useRouter } from '../../../../hooks/useRouter';

type AvatarColumnsProps = {
  logo?: string;
  name: string;
};

const ActionsColumn = ({ ecosystemId }: { ecosystemId: string }) => {
  const { goToRoute } = useRouter();

  return (
    <DataTableActions
      viewButton
      color={colors.primary['600']}
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      onPressViewButton={() => {}}
    />
  );
};

const AvatarColumn = ({ logo, name }: AvatarColumnsProps) => (
  <HStack space={2} alignItems="center">
    {(logo && (
      <Image
        width="24px"
        height="24px"
        borderRadius="sm"
        source={{ uri: logo }}
      />
    )) || (
      <Box
        width="24px"
        height="24px"
        borderRadius="sm"
        backgroundColor="gray.200"
      />
    )}
    {name}
  </HStack>
);

const VisibilityColumn = ({
  visibility,
}: {
  visibility?: EcosystemVisibility;
}) => (
  <HStack space={2} alignItems="center">
    <Icon
      size="18"
      color={colors.gray[400]}
      icon={visibility === EcosystemVisibility.Private ? 'lock' : 'eye'}
    />
    {visibility === EcosystemVisibility.Private ? 'Private' : 'Public'}
  </HStack>
);

export const tableColumns = () => [
  {
    name: 'ecosystem',
    header: 'Ecosystem',
    defaultFlex: 1,
    render: ({ data }: AppConnectedEcosystemsTable) => {
      return (
        <AvatarColumn
          logo={data?.receiverEcosystem?.logo}
          name={data?.receiverEcosystem?.name}
        />
      );
    },
  },
  {
    name: 'receiverOrganization',
    header: 'Owner',
    defaultFlex: 1,
    render: ({ data }: AppConnectedEcosystemsTable) => {
      return (
        <AvatarColumn
          logo={data?.receiverOrganization?.logo}
          name={data?.receiverOrganization?.name}
        />
      );
    },
  },
  {
    name: 'visibility',
    header: 'Type',
    defaultFlex: 1 / 2,
    render: ({ data }: AppConnectedEcosystemsTable) => {
      return (
        <VisibilityColumn visibility={data.receiverEcosystem.visibility} />
      );
    },
  },
  {
    name: 'actions',
    header: 'Actions',
    defaultFlex: 1 / 3,
    render: ({ data }: any) => (
      <ActionsColumn ecosystemId={data.receiverEcosystem._id} />
    ),
  },
];
