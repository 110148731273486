import { PAGES } from '~/constants/pages.constants';
import { useRouter } from '~/hooks/useRouter';

export const useSettingsController = () => {
  const { searchParams, location, goToRoute } = useRouter();

  const defaultSideBarIndex = searchParams.get('sidebarIndex');

  const sideBarList = [
    {
      title: 'General',
      isSelected: location.pathname === PAGES.SETTINGS,
      onPress: () => goToRoute(PAGES.SETTINGS),
    },
    {
      title: 'Ecosystems',
      isSelected: location.pathname.includes(PAGES.ECOSYSTEM_SETTINGS),
      onPress: () => goToRoute(PAGES.ECOSYSTEM_SETTINGS),
    },
    {
      title: 'Apps',
      isSelected: location.pathname.includes(PAGES.APP_SETTINGS),
      onPress: () => goToRoute(`${PAGES.APP_SETTINGS}`),
    },
    {
      title: 'Admins',
      isSelected: location.pathname === '/settings/admins',
      onPress: () => goToRoute(`${PAGES.SETTINGS}/admins`),
    },
    {
      title: 'Permissions',
      isSelected: location.pathname === '/settings/permissions',
      onPress: () => goToRoute(`${PAGES.SETTINGS}/permissions`),
    },
    {
      title: 'Integrations',
      isSelected: location.pathname === '/settings/integrations',
      onPress: () => goToRoute(`${PAGES.SETTINGS}/integrations`),
    },
  ];

  return {
    defaultSideBarIndex: defaultSideBarIndex ? +defaultSideBarIndex : 0,
    sideBarList,
    goToRoute,
  };
};
