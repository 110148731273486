import { InsightsActivityType } from 'ui/types/activities';

export const apexChartsDefaultColors = [
  '#EF4444', // Red
  '#FFC633', // Yellow
  '#9E45F2', // Purple
  '#00D58D', // Teal
  '#FB923C', // Orange
  '#2E93FA', // Blue
  '#ED4B82', // Pink
  '#87D90A', // Lime Green
  '#3625FF', // Dark Blue
];

export const InsightsActivityColors: { [key in InsightsActivityType]: string } =
  {
    [InsightsActivityType.Event]: '#E65D39',
    [InsightsActivityType.TeamEvent]: '#FB923C',
    // @ts-expect-error - valid mapper
    'Team Event': '#FB923C',
    [InsightsActivityType.Action]: '#6666FF',
    [InsightsActivityType.OngoingOpportunity]: '#33AC61',
    'Ongoing Opportunity': '#33AC61',
  };
