import { useCallback, useEffect, useRef, useState } from 'react';
import { toast } from 'react-toastify';
import { IFilterValue } from 'ui/components/DataTable/types';
import { IModalRefProps } from 'ui/components/Modals/Modal/types';

import { useAppSelector } from '~/hooks/useAppSelector';
import { useQuery } from '~/hooks/useQuery';
import { useRouter } from '~/hooks/useRouter';

import { COMMON_MESSAGES } from '../../../../constants/messages.constants';
import { NetworkAppSharingService } from '../../../../services/resources/networkAppSharing';
import { App } from '../../../../types/interfaces/app';

export const useAppController = () => {
  const modalRef = useRef<IModalRefProps>(null);

  const { goToRoute, goBack, params } = useRouter();
  const appId = params.id as string;

  const { organizationSelectedId } = useAppSelector(({ auth }) => auth);
  const [totalConnections, setTotalConnections] = useState(0);

  const loadAppSharings = useCallback(
    async (skip: number, limit: number, filter: IFilterValue | null) => {
      try {
        const { data: response } = await NetworkAppSharingService.findByApp(
          appId,
          {
            filter: JSON.stringify({
              ...filter,
            }),
            skip,
            limit,
          },
        );
        setTotalConnections(response?.count || 0);
        return {
          data: response?.data,
          count: response?.count,
        };
      } catch (error) {
        toast.error(COMMON_MESSAGES.ERROR_FETCHING_APP_CONNECTIONS, {
          position: 'bottom-center',
        });
      }
    },
    [],
  );

  const {
    data: app,
    isLoading: isLoadingApp,
    isError,
  } = useQuery<App>(`/app/${appId}`);

  const handleLeftButtonAction = () => modalRef.current?.close();

  const handleEditApp = () => goToRoute(`/app/edit/${appId}`);

  useEffect(() => {
    if (isError) {
      toast.error('App not found.');
      goBack();
    }
  }, [isError, goBack]);

  return {
    app,
    loadAppSharings,
    modalRef,
    isLoadingApp,
    goBack,
    handleEditApp,
    handleLeftButtonAction,
    totalConnections,
  };
};
