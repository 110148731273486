import { Link, Stack, Text } from 'native-base';

import { NotificationTitleNodeProps } from './types';

export const NotificationTitleNode = ({
  text = '',
  linkUrl,
  customRender,
  fontWeight = 400,
  fontColor = 'darkText',
}: NotificationTitleNodeProps) => {
  if (!!customRender) return <>{customRender}</>;

  return (
    <Stack overflow={'hidden'}>
      <Text
        numberOfLines={1}
        fontSize={'sm'}
        color={fontColor}
        fontWeight={linkUrl ? 500 : fontWeight}
      >
        {linkUrl ? (
          <Link
            href={linkUrl}
            isExternal
            color={fontColor}
            _hover={{ textDecorationLine: 'underline' }}
          >
            <Text numberOfLines={1}>{text}</Text>
          </Link>
        ) : (
          text
        )}
      </Text>
    </Stack>
  );
};
