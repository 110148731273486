import { useCallback, useRef } from 'react';
import { IFilterValue } from 'ui/components/DataTable/types';
import { IModalRefProps } from 'ui/components/Modals/Modal/types';

import { ActivityTypeEnum } from '~/enums';

export const useChildOrgActivitiesController = () => {
  const isLoadingReports = false;

  const actionReportModalRef = useRef<IModalRefProps>(null);

  const loadChildOrgActivities = useCallback(
    async (skip: number, limit: number, filter: IFilterValue | null) => {
      return {
        data: [
          {
            activity: 'Animal Friends National Day',
            organization: 'Fareshare (You)',
            type: ActivityTypeEnum.Event,
            applications: 100,
            members_amount: '1h 30m',
          },
          {
            activity: 'Fareshare Food Donation at your local Tesco',
            organization: 'Fareshare Devon',
            type: ActivityTypeEnum.Action,
            applications: 100,
            members_amount: '5 kilograms',
          },
          {
            activity: 'Nursing Home Volunteering',
            organization: 'Fareshare (You)',
            type: ActivityTypeEnum.OngoingOpportunity,
            applications: 100,
            members_amount: '1h 30m',
          },
          {
            activity: 'Help us increase deaf awereness',
            organization: 'Fareshare Devon',
            type: ActivityTypeEnum.Action,
            applications: 100,
            members_amount: '1h 30m',
          },
        ],
        count: 1,
      };
    },
    [],
  );

  const openModal = () => {
    actionReportModalRef.current?.open();
  };

  return {
    isLoadingReports,
    actionReportModalRef,
    loadChildOrgActivities,
    openModal,
  };
};
