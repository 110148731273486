export enum EFileImportStatus {
  Queued = 'Queued',
  Processing = 'Processing',
  Completed = 'Completed',
  Failed = 'Failed',
}

export enum EImportFileType {
  Activity = 'Activity',
  ActivityDefinition = 'ActivityDefinition',
}

export interface IImport<D> {
  key: string;
  error: string;
  data: D;
  status: EFileImportStatus;
}

export interface IImportFile {
  _id: string;
  key: string;
  error: string;
  organization: string;
  app: string;
  publishedApps: string[];
  ecosystem: string;
  importFileType: EImportFileType;
  activityDefinitionImportFile: string;
  createdAt: string;
  updatedAt: string;
}

export interface IImportParsedData {
  identifier: string;
  title: string;
  type: string;
  activity_identifier?: string;
  address?: string;
}
