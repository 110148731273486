import { useMediaQuery } from 'native-base';
import { useRef } from 'react';
import { IModalRefProps } from 'ui/components/Modals/Modal/types';

export const useDetailsFormController = () => {
  const modalRef = useRef<IModalRefProps>(null);

  const [isMobile] = useMediaQuery({ maxWidth: 600 });

  const openModal = () => modalRef.current?.open();

  return { isMobile, modalRef, openModal };
};
