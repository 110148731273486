import { useRef, useState } from 'react';
import { geocodeByAddress, getLatLng } from 'react-google-places-autocomplete';
import { toast } from 'react-toastify';
import { IModalRefProps } from 'ui/components/Modals/Modal/types';
import { colors } from 'ui/theme/colors';

import { useScript } from '~/hooks/useScript';
import {
  LocationData,
  LocationDataStatus,
} from '~/pages/Authenticated/Events/AddEvent/EventForm/WhenWhereForm/MultipleLocations/types';

import { MultipleLocationsOngoingFormProps } from './types';

export const useMultipleLocationsOngoingForm = ({
  handleUpdateLocationGroup,
  locationTagsByGroupIndex,
}: Pick<
  MultipleLocationsOngoingFormProps,
  'handleUpdateLocationGroup' | 'locationTagsByGroupIndex'
>) => {
  const modalRef = useRef<IModalRefProps>(null);
  const googleApiKey = import.meta.env.VITE_GOOGLE_PLACES_API_KEY;

  const [isLoadingLocations, setIsLoadingLocations] = useState(false);
  const [loadingProgress, setLoadingProgress] = useState(0);
  const [maxLoadingProgress, setMaxLoadingProgress] = useState(0);
  const [addressWithError, setAddressWithError] = useState(false);
  const [currentGroupForModal, setCurrenGroupForModal] = useState(0);

  const scriptStatus = useScript(
    `https://maps.googleapis.com/maps/api/js?language=en&key=${googleApiKey}&libraries=places`,
  );

  const fetchLocation = async (location: string) => {
    try {
      const addressResponse = await geocodeByAddress(location);
      const locationResponse = await getLatLng(addressResponse[0]);

      const locationData: LocationData = {
        returnedAddress: addressResponse[0].formatted_address,
        sentInput: location,
        status: LocationDataStatus.OK,
        location: locationResponse,
        placeId: addressResponse[0].place_id,
      };

      setLoadingProgress((prevState) => prevState + 1);

      return locationData;
    } catch (error: any) {
      const locationDataError: LocationData = {
        returnedAddress: '',
        sentInput: location,
        status: LocationDataStatus.ZERO_RESULTS,
        location: undefined,
      };
      if (!addressWithError) {
        setAddressWithError(true);
      }
      setLoadingProgress((prevState) => prevState + 1);
      return locationDataError;
    }
  };

  const fetchAllLocations = async (index: number, addresses: string) => {
    if (scriptStatus !== 'ready') return;
    try {
      const addressesArray: string[] = addresses.split(/\s*;\s*/);
      const maxProgress = addressesArray.length;
      setMaxLoadingProgress(maxProgress);
      setIsLoadingLocations(true);
      const promises = addressesArray.map(
        async (address) => await fetchLocation(address),
      );
      const promisesAllResponse = await Promise.all(promises);
      // Remove duplicates
      const uniqueLocations = promisesAllResponse.reduce((acc, current) => {
        const existsLocation = acc.find(
          (accLocation) => accLocation.placeId === current.placeId,
        );

        if (!existsLocation) {
          acc.push(current);
        }
        return acc;
      }, [] as LocationData[]);
      handleUpdateLocationGroup(index, uniqueLocations);
    } catch (error: any) {
      toast.error('Error uploading the locations!');
      // Maybe we use this for production log?
      console.log({ error });
    } finally {
      setIsLoadingLocations(false);
      setMaxLoadingProgress(0);
      setLoadingProgress(0);
      if (addressWithError) {
        toast.error('Some locations were not uploaded.', {
          position: 'top-center',
        });
        setAddressWithError(false);
      }
    }
  };

  const groupByIndexExistsLocation = (index: number) => {
    return (
      locationTagsByGroupIndex && locationTagsByGroupIndex?.[index]?.length > 0
    );
  };

  const openModal = () => modalRef.current?.open();
  const closeModal = () => modalRef.current?.close();

  const handleOpenModal = (groupIndex: number) => {
    setCurrenGroupForModal(groupIndex);
    openModal();
  };

  const onAddAddresses = (addresses: string) => {
    fetchAllLocations(currentGroupForModal, addresses);
    closeModal();
  };

  const getLocationGroupModalAddresses = () => {
    const hasLocationsForGroup =
      locationTagsByGroupIndex &&
      locationTagsByGroupIndex?.[currentGroupForModal]?.length > 0;

    if (hasLocationsForGroup) {
      return locationTagsByGroupIndex[currentGroupForModal]
        .map((locationData) => locationData.sentInput)
        .join('; ');
    }
    return '';
  };

  const loadingPercentage = (
    (loadingProgress / maxLoadingProgress) * 100 || 0
  ).toFixed(0);

  const currentModalAddresses = getLocationGroupModalAddresses();

  const tagStyleByStatus = {
    [LocationDataStatus.OK]: {
      bg: 'primary.50',
      color: 'primary.400',
      icon: colors.primaryAdmin[400],
    },
    [LocationDataStatus.ZERO_RESULTS]: {
      bg: 'error.100',
      color: 'error.600',
      icon: colors.error[600],
    },
  };

  return {
    fetchAllLocations,
    groupByIndexExistsLocation,
    isLoadingLocations,
    loadingProgress,
    maxLoadingProgress,
    loadingPercentage,
    modalRef,
    handleOpenModal,
    closeModal,
    onAddAddresses,
    currentModalAddresses,
    tagStyleByStatus,
  };
};
