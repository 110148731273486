import { useMemo } from 'react';
import { mapToActivitySemanticType } from 'ui/utils/activity/mappers';
import { formatDateByEnglishType } from 'ui/utils/formatter';
import { formatMeasurementAmount } from 'ui/utils/measurements/format';

import { MeasurementCategoryIconMap } from '../constants';
import { CommonActivitySearchTileProps } from './types';

export const useCommonActivitySearchTileController = ({
  activityType,
  measurementUnit,
  activityStartDate,
  activityTargetAmount,
}: CommonActivitySearchTileProps) => {
  const formattedLabels = useMemo(() => {
    let startDateStr = '';
    let targetAmountStr = '';
    let targetAmountIcon = 'target_and_arrow';

    if (activityStartDate) {
      startDateStr = `${formatDateByEnglishType(activityStartDate, 'british')}`;
    }

    if (measurementUnit) {
      targetAmountIcon =
        MeasurementCategoryIconMap[measurementUnit?.category] ||
        'target_and_arrow';

      if (activityTargetAmount) {
        targetAmountStr = formatMeasurementAmount(
          activityTargetAmount,
          measurementUnit,
        );
      }
    }

    return {
      startDateStr,
      targetAmountStr,
      targetAmountIcon,
    };
  }, [measurementUnit, activityStartDate, activityTargetAmount]);

  const activitySemanticType = useMemo(
    () => mapToActivitySemanticType(activityType),
    [activityType],
  );

  return {
    formattedLabels,
    activitySemanticType,
  };
};
