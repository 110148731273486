import {
  Box,
  HStack,
  Image,
  Pressable,
  Stack,
  Text,
  VStack,
} from 'native-base';
import Icon from 'ui/components/Icon';
import { TableCellWithAvatar } from 'ui/components/TableCellWithAvatar';

import useDrawerController from './controller';

const Drawer = () => {
  const { menuItems, currentOrganization, goToRoute, getStyles } =
    useDrawerController();

  return (
    <>
      <Stack
        w="200px"
        zIndex={1}
        background="white"
        alignItems="start"
        justifyContent="space-between"
      >
        <VStack w="full" mb={5} pt={12}>
          <Stack px={6}>
            <TableCellWithAvatar avatar={currentOrganization?.logo} name={''} />
          </Stack>
          <Stack mt={1} px={6}>
            <Text
              color="gray.500"
              fontWeight={500}
              fontSize="lg"
              textAlign={'start'}
            >
              {currentOrganization?.name}
            </Text>
          </Stack>
          <Stack w="full">
            {menuItems?.length
              ? menuItems.map((item) => {
                  const styles = getStyles(item.path);
                  return (
                    <Stack key={item.path} position="relative" mr="5">
                      <Box
                        pl="-1"
                        bgColor={styles.bgColor}
                        h="45"
                        w="full"
                        top={31}
                        borderRightRadius="3xl"
                      />
                      <Pressable
                        onPress={() => {
                          item.customNavigationAction &&
                            item.customNavigationAction();
                          goToRoute(item.path);
                        }}
                        pl={6}
                      >
                        <HStack space={2}>
                          {!item.isCustom ? (
                            <Icon
                              icon={item.icon}
                              color={styles.icon}
                              size={20}
                            />
                          ) : (
                            <Image source={{ uri: item.icon }} size={5} />
                          )}
                          <Text
                            color={styles.text}
                            fontSize="sm"
                            fontWeight={500}
                          >
                            {item.label}
                          </Text>
                        </HStack>
                      </Pressable>
                    </Stack>
                  );
                })
              : null}
          </Stack>
        </VStack>
      </Stack>
    </>
  );
};

export default Drawer;
