import { Stack, Text } from 'native-base';
import Button from 'ui/components/Button';

import { JoinAsPartnerSuccessProps } from '~/pages/Common/JoinAsPartner/types';
import { colors } from '~/theme/colors';

export const JoinAsPartnerSuccess = ({
  handleGoToHome,
  ecosystemName,
}: JoinAsPartnerSuccessProps) => {
  return (
    <Stack
      space={6}
      width="100%"
      height="100%"
      justifyContent="center"
      bgColor={colors.singletons.lightText}
      alignItems={{ base: 'center', lg: 'initial' }}
    >
      <Stack space={3} alignItems={{ base: 'center', lg: 'initial' }}>
        <Text
          fontWeight={500}
          fontSize={{ base: 'lg', lg: '2xl' }}
          textAlign={{ base: 'center', lg: 'left' }}
          color="gray.900"
          mb={2}
          maxW="357px"
          width="100%"
        >
          You are now a partner with{' '}
          <Text fontWeight={700}>“{ecosystemName}”!</Text>
        </Text>

        <Text
          fontWeight={400}
          fontSize={{ base: 'xs', lg: 'md' }}
          textAlign={{ base: 'center', lg: 'left' }}
          color="gray.900"
          maxW="357px"
          width="100%"
        >
          Thank you for making positive impact.
        </Text>
      </Stack>

      <Stack
        space={3}
        mt={{ base: 20, lg: 8 }}
        width="100%"
        maxW={{ base: '100%', lg: '500px' }}
      >
        <Button
          padding={6}
          variant="solid"
          onPress={handleGoToHome}
          bgColor="gray.900"
          _text={{ fontWeight: 500 }}
        >
          Go to Home screen
        </Button>
      </Stack>
    </Stack>
  );
};
