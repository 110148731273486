import { AxiosError } from 'axios';

import { CreateInviteCodeError } from '~/errors';
import { api } from '~/services/api';
import { QueryParams } from '~/services/resources/types';
import { IPaginatedResponse } from '~/types';
import { ICreateInviteCodeDto } from '~/types/dtos';
import { InviteCode } from '~/types/interfaces/inviteCode';

const MODULE_URL = '/invite-code';

export class InviteCodeService {
  static async findAll(params?: QueryParams) {
    return await api.get<IPaginatedResponse<InviteCode>>(MODULE_URL, {
      params,
    });
  }

  static async findAllByOrganization(id: string, params?: QueryParams) {
    return await api.get<IPaginatedResponse<InviteCode>>(
      `${MODULE_URL}/organization/${id}`,
      {
        params,
      },
    );
  }

  static async findByOrganization(id: string, params?: QueryParams) {
    return await api.get<IPaginatedResponse<InviteCode>>(
      `${MODULE_URL}/organization/${id}`,
      {
        params,
      },
    );
  }

  static async create({
    organizationId,
    emails,
    reason,
  }: ICreateInviteCodeDto): Promise<void> {
    try {
      await api.post(`${MODULE_URL}/admin`, {
        organizationId,
        emails,
        reason,
      });
    } catch (err) {
      const error = err as AxiosError;
      throw new CreateInviteCodeError(error.message);
    }
  }

  static async delete(id: string, organizationId: string): Promise<void> {
    return await api.delete(`${MODULE_URL}/delete/${organizationId}/${id}`);
  }

  static async resend(id: string): Promise<void> {
    return await api.post(`${MODULE_URL}/${id}/resend`);
  }
}
