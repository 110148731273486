import {
  Box,
  Button as NBButton,
  FormControl,
  HStack,
  Stack,
  Text,
} from 'native-base';
import React from 'react';
import Dropzone from 'react-dropzone';
import Icon from 'ui/components/Icon';

import { CustomFile } from '../types';
import { EImportFileType, IFileZone } from './types';
import { useFileZoneController } from './useFileZoneController';

const GRAY_400 = '#A1A1AA';

interface IThumbProps {
  file: CustomFile;
  isPlaceFile: boolean;
  onDelete: (fileName: string) => void;
  canRemoveFile?: boolean;
  defaultImportedFile: IFileZone['defaultImportedFile'];
}

const Thumb = ({
  file,
  isPlaceFile,
  onDelete,
  canRemoveFile,
  defaultImportedFile,
}: IThumbProps) => {
  if (file || defaultImportedFile) {
    return (
      <HStack
        space="3"
        alignItems={'center'}
        w="100%"
        px="16"
        justifyContent={'space-between'}
      >
        <HStack alignItems={'center'}>
          <Icon color={GRAY_400} size="34" isCustom icon="csv-sheet" />
          <Text color="gray.600" size="md" fontWeight="400" ml="6">
            {file?.name || defaultImportedFile?.key}
          </Text>
        </HStack>

        {canRemoveFile ? (
          <NBButton
            w="2"
            ml="6"
            variant={'ghost'}
            onPress={() => onDelete(file.name)}
          >
            <Icon color={GRAY_400} size="20" icon="x" />
          </NBButton>
        ) : null}
      </HStack>
    );
  }

  return (
    <Box bgColor="white" width="100%" height="100%" alignItems="center" py="6">
      <Icon color={GRAY_400} size="36" icon="upload" />
      <Box alignItems="center" mt="7" px="10">
        <Text color="gray.600" size="md" fontWeight="400" textAlign="center">
          drag and drop or choose file to upload{' '}
          <Text fontWeight="700">{isPlaceFile ? 'places' : 'activities'}</Text>
        </Text>
      </Box>
    </Box>
  );
};

const MemoThunb = React.memo(Thumb);

const FileZone = ({
  defaultImportedFile,
  fileUploadType,
  title,
  errorMessage,
  isRequired,
  isDisabled,
  isInvalid,
  isReadOnly,
  hasError,
  canRemoveFile,
  ...restProps
}: IFileZone) => {
  const {
    filesInZone,
    onDropAccepted,
    onDropRejected,
    handleDeleteFilesInZone,
    filesAccepted,
  } = useFileZoneController(restProps);

  const [selectedFile] = filesInZone;

  return (
    <Dropzone
      disabled={isDisabled}
      accept={filesAccepted}
      onDropAccepted={onDropAccepted}
      onDropRejected={onDropRejected}
    >
      {({ getRootProps, getInputProps }) => (
        <div
          {...getRootProps()}
          style={{ cursor: isDisabled ? 'default' : 'pointer' }}
        >
          <input {...getInputProps()} disabled={isDisabled} />
          <Stack space={3}>
            <FormControl
              isInvalid={hasError || errorMessage ? true : isInvalid}
              isDisabled={isDisabled}
              isRequired={isRequired}
              isReadOnly={isReadOnly}
            >
              {title ? (
                <FormControl.Label>
                  <Text color="gray.600" fontSize={14} fontWeight="500">
                    {title}
                  </Text>
                </FormControl.Label>
              ) : null}

              <Box
                borderColor={GRAY_400}
                borderWidth={1}
                borderStyle="dashed"
                overflow={'hidden'}
                p="5"
                justifyContent="center"
                alignItems="center"
                borderRadius="24px"
                minH="202px"
                w="100%"
              >
                <MemoThunb
                  defaultImportedFile={defaultImportedFile}
                  file={selectedFile}
                  isPlaceFile={fileUploadType === EImportFileType.Activity}
                  onDelete={handleDeleteFilesInZone}
                  canRemoveFile={canRemoveFile}
                />
              </Box>
            </FormControl>
          </Stack>
        </div>
      )}
    </Dropzone>
  );
};

export default FileZone;
