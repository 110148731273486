import { Spinner, Stack, Text } from 'native-base';
import { ILLUSTRATIONS_NAME } from 'ui/components/Illustration';

import { ListEmptyState } from '~/components/EmptyState/ListEmptyState';
import { BackButton } from '~/pages/Authenticated/SwitchEcosystem/components/BackButton';
import { useSwitchEcosystemController } from '~/pages/Authenticated/SwitchEcosystem/components/controller';
import { SwitchEcosystemFooter } from '~/pages/Authenticated/SwitchEcosystem/components/SwitchEcosystemFooter';
import { SwitchEcosystemList } from '~/pages/Authenticated/SwitchEcosystem/components/SwitchEcosystemList';
import { SwitchEcosystemNavBar } from '~/pages/Authenticated/SwitchEcosystem/components/SwitchEcosystemNavBar';

export const SwitchEcosystem = () => {
  const {
    filteredEcosystems,
    handleOnChangeText,
    isSearching,
    control,
    selectedEcosystem,
    handleChangeEcosystem,
    goBack,
    isChangingEcosystem,
    currentOrganization,
  } = useSwitchEcosystemController();

  if (isChangingEcosystem) {
    return (
      <Stack
        space={4}
        alignItems="center"
        justifyContent="center"
        width="100%"
        height="100vh"
      >
        <Text>Changing your ecosystem</Text>
        <Spinner color="gray.900" size="lg" />
      </Stack>
    );
  }

  return (
    <Stack minH="100vh" paddingX={{ base: 4, lg: 24 }}>
      <BackButton handleGoBack={goBack} />
      <SwitchEcosystemNavBar
        control={control}
        handleOnChangeText={handleOnChangeText}
      />
      <Stack flex={1} width="100%" alignItems="center">
        <Stack
          maxW={{ base: '100%', md: '34rem', lg: '60.125rem' }}
          width="100%"
          alignItems="center"
        >
          <SwitchEcosystemList
            currentOrganization={currentOrganization}
            ecosystems={filteredEcosystems}
            handleChangeEcosystem={handleChangeEcosystem}
            isSearching={isSearching}
            selectedEcosystem={selectedEcosystem}
          />
          {filteredEcosystems?.length === 0 && !isSearching ? (
            <Stack>
              <ListEmptyState
                text={`Sorry, no results found`}
                illustrationName={ILLUSTRATIONS_NAME.ERROR_SORRY_STATE_BROKEN}
              />
            </Stack>
          ) : null}
        </Stack>
      </Stack>
      <SwitchEcosystemFooter />
    </Stack>
  );
};
