import { useCallback, useMemo, useState } from 'react';
import { geocodeByPlaceId } from 'react-google-places-autocomplete';
import { StylesConfig } from 'react-select';
import { colors } from 'ui/theme/colors';

import MapPin from '../../assets/icons/map-pin.svg';
import { GOOGLE_PLACE_MAPS_API_KEY } from '../../config/maps';
import { theme } from '../../theme';
import { IInputSearchLocation } from './types';

const apiOptions = {
  language: 'en',
  region: 'uk',
};

export const useInputSearchLocationController = ({
  onChangeValue,
  label,
  placeholder = 'Select location',
  isRequired,
  inputSearchStyleConfig,
  fontSize,
}: IInputSearchLocation) => {
  const [value, setValue] = useState();

  const defaultStyleConfig: StylesConfig = {
    dropdownIndicator: (baseStyles) => ({
      ...baseStyles,
      display: 'none',
      width: 0,
      height: 0,
    }),
    container: (baseStyles) => ({
      ...baseStyles,
      flex: 1,
      border: 0,
      alignItems: 'center',
    }),
    input: (baseStyles) => ({
      ...baseStyles,
      backgroundImage: `url(${MapPin})`,
      backgroundSize: '20px',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: '0 center',
      flex: 1,
      padding: '6px 0px',
      borderRadius: 12,
      paddingLeft: '25px',
    }),
    control: (baseStyles) => ({
      ...baseStyles,
      flex: 1,
      fontFamily: theme.fontConfig.default,
      fontSize: fontSize,
      border: 0,
      boxShadow: 'none',
      padding: '6px 0px',
      borderRadius: 12,
      background: 'white',
    }),
    option: (baseStyles) => ({
      ...baseStyles,
    }),
    menu: (baseStyles) => ({
      ...baseStyles,
      zIndex: 10000,
    }),
    menuList: (baseStyles) => ({
      ...baseStyles,
      zIndex: 10000,
      fontFamily: theme.fontConfig.default,
      fontSize: 12,
    }),
    menuPortal: (baseStyles) => ({
      ...baseStyles,
      fontFamily: theme.fontConfig.default,
      fontSize: 12,
      zIndex: 10000,
    }),
    singleValue: (baseStyles) => ({
      ...baseStyles,
      paddingLeft: '25px',
    }),
    indicatorSeparator: (baseStyles) => ({
      ...baseStyles,
      display: 'none',
    }),
    placeholder: (defaultStyles) => {
      return {
        ...defaultStyles,
        color: colors.muted[400],
        paddingLeft: '25px',
      };
    },
  };

  const handleChangeValue = useCallback(
    async (newValue) => {
      setValue(newValue);
      if (!newValue && !newValue?.value?.place_id) {
        onChangeValue(undefined);
        return;
      }
      const data = await geocodeByPlaceId(newValue.value.place_id);
      if (data) {
        const lat = data[0].geometry.location.lat();
        const lng = data[0].geometry.location.lng();

        onChangeValue({
          lat,
          lng,
          placeID: newValue.value.place_id,
          rawLocation: newValue.label,
        });
      }
    },
    [onChangeValue],
  );

  const handleClearValue = () => {
    setValue(undefined);
  };

  const selectProps = useMemo(() => {
    return {
      value,
      onChange: handleChangeValue,
      clearValue: handleClearValue,
      isClearable: true,
      noOptionsMessage: () => 'Search by your location',
      placeholder,
      styles: { ...defaultStyleConfig, ...inputSearchStyleConfig },
    };
  }, [value, handleChangeValue, placeholder, inputSearchStyleConfig]);

  return {
    selectProps,
    apiKey: GOOGLE_PLACE_MAPS_API_KEY,
    apiOptions,
    label,
    isRequired,
  };
};
