import { HStack, Skeleton, Text, VStack } from 'native-base';
import { useMemo } from 'react';
import { useQuery } from 'react-query';

import { AreaChart } from '~/pages/Authenticated/Insights/charts/AreaChart';
import { InsightScaffold } from '~/pages/Authenticated/Insights/components/InsightScaffold';
import { PercentageChangeIndicator } from '~/pages/Authenticated/Insights/components/PercentageChangeIndicator';
import {
  InsightPageFilters,
  useInsightFilters,
} from '~/pages/Authenticated/Insights/useInsightFilters';
import { INSIGHT_USER_PROFILE_ENGAGEMENT } from '~/services/resources/insights/constants';
import UserProfileInsightsService from '~/services/resources/insights/UserProfileInsightsService';
import { InsightByPeriodTypeQueryParams } from '~/services/resources/types';

type EmployeesEngagementProps = {
  className: string;
};

const buildQueryKey = (filters: InsightPageFilters) => {
  const params: InsightByPeriodTypeQueryParams = {
    apps: filters.apps,
    ecosystem: filters.selectedEcosystemId,
    organization: filters.selectedOrganizationId,
    startDate: filters.from!,
    endDate: filters.to!,
    granularity: 'Daily',
    periodType: filters.periodType,
  };
  return [INSIGHT_USER_PROFILE_ENGAGEMENT, params];
};

export const EmployeesEngagement = ({
  className,
}: EmployeesEngagementProps) => {
  const { filters, hasFilterSetted } = useInsightFilters();

  const {
    data: response,
    isFetching,
    isLoading,
  } = useQuery({
    queryKey: buildQueryKey(filters),
    queryFn: async ({ queryKey }) => {
      const [, params] = queryKey;

      return await UserProfileInsightsService.getUserProfilesEngagementMetrics(
        params as InsightByPeriodTypeQueryParams,
      );
    },
    staleTime: 1000 * 60 * 5, // 5 mins
    enabled: !!hasFilterSetted,
  });

  const chartData = useMemo(() => {
    const dataMap = new Map<string, number>();

    if (!filters.from || !filters.to) {
      return {
        labels: [],
        series: [],
      };
    }

    const startDate = filters.from.split('T')?.[0];
    const endDate = filters.from.split('T')?.[0];

    const insights = response?.currentPeriodInsights || [];

    // Avoid bugged chart
    if (insights.length == 1) {
      if (insights[0].date !== startDate) {
        dataMap.set(startDate, 0);
      } else if (insights[0].date !== endDate) {
        dataMap.set(endDate, 0);
      }
    }

    for (const item of insights) {
      dataMap.set(item.date, item.total);
    }

    const data = {
      labels: Array.from(dataMap.keys()),
      series: Array.from(dataMap.values()),
    };

    return data;
  }, [response]);

  const renderChartHeader = () => {
    if (isFetching || isLoading) {
      return <ChartHeaderSkeleton />;
    }

    return (
      <HStack px={6} pb={6} space={4} alignItems={'center'}>
        <Text color={'gray.800'} fontSize={'2xl'} fontWeight={500}>
          {response?.totalCurrentPeriod || '-'}
        </Text>
        <PercentageChangeIndicator
          percentage={response?.percentageVariation.toFixed(2) || 0}
          isPositive={(response?.percentageVariation ?? 0) > 0}
        />
      </HStack>
    );
  };

  return (
    <InsightScaffold
      className={className}
      title={{
        icon: 'users',
        name: 'Employees’ engagement',
      }}
      chartData={response?.currentPeriodInsights ?? []}
    >
      <VStack justifyContent={'end'} flex={1}>
        {renderChartHeader()}
        <AreaChart
          isLoading={isFetching || isLoading}
          series={[
            {
              name: 'Engaged users',
              data: chartData.series,
            },
          ]}
          labels={chartData.labels}
        />
      </VStack>
    </InsightScaffold>
  );
};

const ChartHeaderSkeleton = () => {
  return (
    <HStack px={6} pb={6} space={4} alignItems={'center'}>
      <Skeleton.Text lines={1} w="50px" />
      <Skeleton h="25px" w="50px" />
    </HStack>
  );
};
