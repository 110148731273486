export enum CognitoErrorCode {
  UserNotConfirmed = 'UserNotConfirmedException',
  UsernameExistsException = 'UsernameExistsException',
  ExpiredCode = 'ExpiredCodeException',
  NotAuthorized = 'NotAuthorizedException',
  CodeMismatch = 'CodeMismatchException',
}
export enum CognitoErrorCodeMessages {
  UserNotConfirmed = `We've sent you a confirmation code to your email address`,
  UsernameExistsException = `An account with the given email already exists.`,
  ExpiredCode = `Code expired. Please request a new one.`,
  NotAuthorized = `Incorrect email or password.`,
  CodeMismatch = `Invalid verification code provided, please try again.`,
  OldPasswordIncorrect = 'Incorrect username or password.',
  ChangePasswordLimitAttempts = 'Attempt limit exceeded, please try after some time.',
}

export const CognitoErrorMessageMap: Record<
  CognitoErrorCode,
  CognitoErrorCodeMessages
> = {
  [CognitoErrorCode.UserNotConfirmed]:
    CognitoErrorCodeMessages.UserNotConfirmed,
  [CognitoErrorCode.ExpiredCode]: CognitoErrorCodeMessages.ExpiredCode,
  [CognitoErrorCode.UsernameExistsException]:
    CognitoErrorCodeMessages.UsernameExistsException,
  [CognitoErrorCode.NotAuthorized]: CognitoErrorCodeMessages.NotAuthorized,
  [CognitoErrorCode.CodeMismatch]: CognitoErrorCodeMessages.CodeMismatch,
};

export interface CognitoError {
  code: CognitoErrorCode;
  message: string;
}
