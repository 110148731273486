import { Navigate, Outlet, Route, Routes } from 'react-router-dom';
import { GeneratingReport } from 'ui/components/GeneratingReport';

import { HeaderLayout } from '~/components/HeaderLayout';
import Page404 from '~/components/Page404';
import { PAGES } from '~/constants/pages.constants';
import { useFirebaseRoutesAnalytics } from '~/hooks/useFirebaseAnalytics';
import { RedirectManager } from '~/pages/Common/RedirectManager';
import { AccountDeletedFeedback } from '~/pages/Unauthenticated/AccountDeletedFeedback';
import { LoginPage } from '~/pages/Unauthenticated/Login';
import { RecoveryPassword } from '~/pages/Unauthenticated/RecoveryPassword';
import { ForgotPassword } from '~/pages/Unauthenticated/RecoveryPassword/ForgotPassword';
import SignUpPage from '~/pages/Unauthenticated/SignUpPage';
import ConfirmEmailPage from '~/pages/Unauthenticated/SignUpPage/ConfirmEmailPage';

export const UnauthenticatedRoutes = () => {
  useFirebaseRoutesAnalytics();

  return (
    <Routes>
      <Route
        path={PAGES.ROOT}
        element={<Navigate to={PAGES.SIGNIN} replace />}
      />

      <Route path="/redirect" element={<RedirectManager />} />

      <Route
        element={
          <HeaderLayout>
            <Outlet />
          </HeaderLayout>
        }
      >
        <Route path={PAGES.SIGN_UP} element={<SignUpPage />} />

        <Route path={PAGES.RECOVERY_PASSWORD} element={<RecoveryPassword />} />
        <Route
          path={PAGES.SIGN_UP_EMAIL_CONFIRMATION}
          element={<ConfirmEmailPage />}
        />
      </Route>
      <Route path={PAGES.SIGNIN} element={<LoginPage />} />
      <Route path={PAGES.FORGOT_PASSWORD} element={<ForgotPassword />} />
      <Route
        path={PAGES.ACCOUNT_DELETED}
        element={<AccountDeletedFeedback />}
      />
      <Route path={PAGES.PAGE_404} element={<Page404 />} />
      <Route path={PAGES.GENERATING_REPORT} element={<GeneratingReport />} />
      <Route path="*" element={<Navigate to={PAGES.PAGE_404} replace />} />
    </Routes>
  );
};
