import { HStack, Text } from 'native-base';
import React, { useMemo } from 'react';
import Button from 'ui/components/Button';

import useTimer from '~/hooks/useTimer';
import { colors } from '~/theme/colors';

interface IUploadScafoldFooterProps {
  isLoading?: boolean;
  isDisabled?: boolean;
  showRefreshButton?: boolean;
  onClick: () => void;
}

export const _UploadScafoldFooter = ({
  isLoading = false,
  isDisabled = false,
  showRefreshButton = false,
  onClick,
}: IUploadScafoldFooterProps) => {
  const { isActiveCountdown, seconds, setIsActiveCountdown } = useTimer({
    timeout: 5,
  });

  const handleClick = () => {
    if (showRefreshButton) {
      setIsActiveCountdown(true);
    }
    onClick();
  };

  const isFreezed = useMemo(() => {
    return isLoading || isDisabled || isActiveCountdown;
  }, [isLoading, isActiveCountdown, isDisabled]);

  return (
    <HStack
      px="6"
      alignItems="center"
      justifyContent="flex-end"
      w="100%"
      space="10"
    >
      {showRefreshButton && (
        <Text color="gray.500">
          Click in &apos;Refresh&apos; to visualize the upload progress.
        </Text>
      )}

      <Button
        key={isFreezed ? 'disabled' : 'enabled'}
        disabled={isFreezed}
        isLoading={isLoading}
        w="container"
        minW="275px"
        leftIconName={showRefreshButton ? 'rotate-cw' : ''}
        bg={isFreezed ? colors.primary['100'] : colors.primary['500']}
        onPress={handleClick}
      >
        {showRefreshButton
          ? `Refresh ${isActiveCountdown ? `in ${seconds} seconds` : ''}`
          : 'Start upload'}
      </Button>
    </HStack>
  );
};

export const UploadScafoldFooter = React.memo(_UploadScafoldFooter);
