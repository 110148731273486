import { Avatar, HStack, Text, VStack } from 'native-base';
import { colors } from 'ui/theme/colors';

import Button from '../../../Button';
import Icon from '../../../Icon';
import { TeamMemberCardBaseProps } from './types';

export const TeamMemberCardBase = (props: TeamMemberCardBaseProps) => {
  const {
    title,
    description,
    descriptionColor,
    descriptionWeight = 500,
    avatarUrl,
    showAvatar,
    showDeleteMemberButton,
    onDeleteMember,
  } = props;
  return (
    <HStack alignItems="center" space={2}>
      {showAvatar && (
        <Avatar source={{ uri: avatarUrl }} height="40px" width="40px">
          {String(title).charAt(0)}
        </Avatar>
      )}

      <VStack space={1} flex={1}>
        <HStack space={4} alignItems="center">
          <Text
            fontSize={'sm'}
            fontWeight={400}
            color={colors.singletons.darkText}
            numberOfLines={3}
            overflow={'hidden'}
          >
            {title}
          </Text>
          {showDeleteMemberButton && onDeleteMember ? (
            <Button
              colorScheme="error"
              variant="ghost"
              minW="40px"
              maxW="40px"
              width="40px"
              borderRadius="full"
              onPress={onDeleteMember}
            >
              <Icon icon="trash" size={14} color={colors.error['500']} />
            </Button>
          ) : null}
        </HStack>
        <Text
          fontSize={'10px'}
          fontWeight={descriptionWeight}
          color={descriptionColor}
          numberOfLines={1}
        >
          {description}
        </Text>
      </VStack>
    </HStack>
  );
};

export default TeamMemberCardBase;
