import { useCallback, useRef, useState } from 'react';
import { toast } from 'react-toastify';
import {
  BaseModel,
  SelectedRows,
} from 'ui/components/DataTable/@types/basicTypes';
import { IFilter } from 'ui/components/DataTable/types';
import { IModalRefProps } from 'ui/components/Modals/Modal/types';

import { useLoadAppGroupPermissionById } from '~/hooks/useLoadAppGroupPermissionById';
import { useLoadMembersByEcosystem } from '~/hooks/useLoadMembersByEcosystem';
import { useRouter } from '~/hooks/useRouter';
import { AppPermissionGroupService } from '~/services/resources/appPermissionGroup';
import { QueryParams } from '~/services/resources/types';
import { UserProfile } from '~/types/interfaces/userProfile';

interface SelectedMembersTable extends UserProfile {
  id: string | number;
}

export const useAssignPermissionController = () => {
  const [queryParams, setQueryParams] = useState<QueryParams | undefined>(
    undefined,
  );
  const [selectedMembers, setSelectedMembers] = useState<
    SelectedRows<BaseModel>
  >({});
  const [isSubmitting, setIsSubmitting] = useState(false);

  const modalRef = useRef<IModalRefProps>(null);

  const isSelectedMembers = Object.keys(selectedMembers).length > 0;

  const {
    goBack,
    params: { id },
  } = useRouter();

  const { data: appPermissionGroup, isLoading: isLoadingAppPermissionsGroup } =
    useLoadAppGroupPermissionById(id || '');

  const { isLoading: isLoadingMembers, data: responseData } =
    useLoadMembersByEcosystem(appPermissionGroup?.ecosystem || '', queryParams);

  const handleBack = () => {
    goBack();
  };

  const onSelectionChange = (
    selectedRows: SelectedRows<SelectedMembersTable>,
  ) => {
    const isObjectEmpty = Array.from(Object.keys(selectedRows)).length === 0;

    const arrayFromSelection: SelectedMembersTable[] = Array.from(
      Object.values(selectedRows),
    );

    const selectedMembers = arrayFromSelection.reduce(
      (
        accumulator: SelectedRows<SelectedMembersTable>,
        current: SelectedMembersTable,
      ) => {
        if (current._id) {
          accumulator[current._id] = current;
        }

        return accumulator;
      },
      {} as SelectedRows<SelectedMembersTable>,
    );

    if (!isObjectEmpty) {
      setSelectedMembers(selectedMembers);
    } else {
      setSelectedMembers({});
    }
  };

  const onCancelPress = () => {
    setSelectedMembers({});
  };

  const openModal = () => {
    modalRef?.current?.open();
  };

  const closeModal = () => {
    modalRef?.current?.close();
  };

  const onAssignToGroup = async () => {
    try {
      if (!selectedMembers || !appPermissionGroup) return;
      setIsSubmitting(true);
      const userProfileIds = Object.keys(selectedMembers);
      await AppPermissionGroupService.bulkAssignMember({
        userProfileIds,
        appPermissionGroupId: appPermissionGroup._id,
      });
      toast.success('Members assigned to permission group');
      handleBack();
    } catch (error) {
      toast.error('Error assigning members to permission group');
    } finally {
      setIsSubmitting(false);
    }
  };

  const loadMembers = useCallback(
    async (skip: number, limit: number, filter: IFilter) => {
      setQueryParams({ skip, limit, filter: JSON.stringify({ ...filter }) });
      if (responseData?.data) {
        const data = responseData?.data;
        const count = responseData?.count;
        return { data, count };
      }
      return { data: [], count: 0 };
    },
    [responseData],
  );

  return {
    onSelectionChange,
    appPermissionGroup,
    isLoadingAppPermissionsGroup,
    isLoadingMembers,
    handleBack,
    loadMembers,
    selectedMembers,
    isSelectedMembers,
    onCancelPress,
    onAssignToGroup,
    isSubmitting,
    modalRef,
    closeModal,
    openModal,
  };
};
