import { useRef, useState } from 'react';
import { toast } from 'react-toastify';
import { IModalRefProps } from 'ui/components/Modals/Modal/types';

import { useAppSelector } from '~/hooks/useAppSelector';
import {
  AdminInvitesControllerAttributes,
  InviteNewAdminControllerAttributes,
} from '~/pages/Authenticated/Settings/AdminsSettings/types';
import { InviteCodeService } from '~/services/resources/inviteCode';

export const useInviteNewAdminController = ({
  refetchInvites,
}: Pick<
  AdminInvitesControllerAttributes,
  'refetchInvites'
>): InviteNewAdminControllerAttributes => {
  const { organizationSelectedId } = useAppSelector(({ auth }) => auth);
  const modalInviteNewRef = useRef<IModalRefProps>(null);
  const [isSendingNewAdminInvites, setIsSendingNewAdminInvites] =
    useState(false);
  const openInviteNewAdminModal = () => {
    modalInviteNewRef.current?.open();
  };
  const closeInviteNewAdminModal = () => {
    modalInviteNewRef.current?.close();
  };
  const onSendNewAdminInvites = async () => {
    setIsSendingNewAdminInvites(true);
    try {
      if (!organizationSelectedId) {
        throw new Error('Cannot send invites - no organization selected.');
      }
      await InviteCodeService.create({
        organizationId: organizationSelectedId,
        emails: adminEmailsList,
      });
      setAdminEmailsList([]);
      await refetchInvites();
      toast.success('Invites sent successfully.');
    } catch (error) {
      toast.error(
        'We encountered an issue trying to send the invites. Please try again later.',
      );
    } finally {
      setIsSendingNewAdminInvites(false);
      modalInviteNewRef.current?.close();
    }
  };

  const [adminEmailsList, setAdminEmailsList] = useState<string[]>([]);
  const [adminEmailInput, setAdminEmailInput] = useState('');
  const addAdminEmail = (email: string) => {
    if (!email) {
      toast.warning('Please enter an email.');
      return;
    }
    if (adminEmailsList.includes(email)) {
      toast.warning('You already added this email.');
      return;
    }
    setAdminEmailsList((prev) => [...prev, email]);
    setAdminEmailInput('');
  };
  const removeAdminEmail = (email: string) => {
    setAdminEmailsList((prev) => prev.filter((e) => e !== email));
  };
  const onChangeAdminEmailInput = (value: string) => {
    setAdminEmailInput(value);
  };

  return {
    modalInviteNewAdminRef: modalInviteNewRef,
    isSendingNewAdminInvites,
    openInviteNewAdminModal,
    closeInviteNewAdminModal,
    onSendNewAdminInvites,
    adminEmailsList,
    addAdminEmail,
    removeAdminEmail,
    adminEmailInput,
    setAdminEmailInput,
    onChangeAdminEmailInput,
  };
};
