import { useQuery } from '~/hooks/useQuery';

import { ActivityTypeEnum } from '../../../../enums';

export const useLoadMemberLogHoursReportByType = (
  userProfileId: string,
  activityType: ActivityTypeEnum,
) => {
  const { data, isLoading, isRefetching } = useQuery<{ count: number }>(
    `measurement-insights/member/${userProfileId}/total-time-logged-by-activity-type`,
    {
      requestOptions: {
        params: {
          activityType: ActivityTypeEnum[activityType],
        },
      },
      key: `user-profile-activities-report/${userProfileId}/total-time-logged-by-activity-type`,
    },
  );

  return { data, isLoading, isRefetching };
};
