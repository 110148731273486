import { useClipboard } from 'native-base';
import { useCallback, useRef, useState } from 'react';
import { toast } from 'react-toastify';
import { IFilterValue } from 'ui/components/DataTable/types';
import { IModalRefProps } from 'ui/components/Modals/Modal/types';

import { COMMON_MESSAGES } from '~/constants/messages.constants';
import { MemberTypeEnum } from '~/enums';
import { useAppSelector } from '~/hooks/useAppSelector';
import { InviteMemberService } from '~/services/resources/inviteMember';
import { InvitePartnerService } from '~/services/resources/invitePartner';
import { InviteMembersPopulate } from '~/types/interfaces/invite-member';
import { InvitePartnersPopulate } from '~/types/interfaces/invite-partner';

export const usePendingInvitesController = () => {
  const { organizationSelectedId } = useAppSelector(({ auth }) => auth);
  const { selectedEcosystem } = useAppSelector(({ ecosystem }) => ecosystem);
  const { onCopy } = useClipboard();

  const modalRef = useRef<IModalRefProps>(null);
  const modalInviteDetailsRef = useRef<IModalRefProps>(null);

  const [totalPartnerInvitesPending, setTotalPartnerInvitesPending] =
    useState(0);
  const [totalMemberInvitesPending, setTotalMemberInvitesPending] = useState(0);
  const [invite, setInvite] = useState<{
    id: string;
    type: string;
  } | null>(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [inviteDetails, setInviteDetails] =
    useState<Partial<InviteMembersPopulate | InvitePartnersPopulate>>();

  const openResendInviteModal = (inviteId: string, inviteType: string) => {
    setInvite({ id: inviteId, type: inviteType });
    modalRef.current?.open();
  };

  const closeResendInviteModal = () => {
    setInvite(null);
    modalRef.current?.close();
  };

  const copyInviteLink = (link: string) => {
    onCopy(link);
    toast.success(COMMON_MESSAGES.LINK_COPIED);
  };

  const openModalInviteDetails = (
    data: Partial<InviteMembersPopulate | InvitePartnersPopulate>,
  ) => {
    setInviteDetails(data);
    modalInviteDetailsRef?.current?.open();
  };

  const onResendInvite = async () => {
    try {
      if (!invite) return;
      setIsSubmitting(true);
      if (invite.type === MemberTypeEnum.Member) {
        await InviteMemberService.resendInvite(invite.id);
      } else {
        await InvitePartnerService.resendInvite(invite.id);
      }

      toast.success('Invite resent');
    } catch (error) {
      toast.error('Error on trying to resend invite');
    } finally {
      setIsSubmitting(false);
      closeResendInviteModal();
    }
  };

  const loadPendingPartnerInvites = useCallback(
    async (skip: number, limit: number, filter: IFilterValue | null) => {
      try {
        if (!selectedEcosystem || !organizationSelectedId) {
          return { data: [], count: 0 };
        }
        const {
          data: { data, count },
        } = await InvitePartnerService.findPendingInvites(
          organizationSelectedId,
          {
            skip,
            limit,
            filter: JSON.stringify({
              ...filter,
              isUsed: false,
              sharerEcosystem: selectedEcosystem._id,
            }),

            populate: JSON.stringify([
              {
                path: 'configurations.ecosystemId',
                model: 'Ecosystem',
              },
              {
                path: 'configurations.appsData.appId',
                model: 'App',
              },
              {
                path: 'configurations.appsData.appPermissionGroupsData.appPermissionGroupId',
                model: 'AppPermissionGroup',
              },
            ]),
          },
        );
        setTotalPartnerInvitesPending(count);
        return { data, count };
      } catch (error) {
        toast.error('Error loading pending partner invites');
        return { data: [], count: 0 };
      }
    },
    [organizationSelectedId, selectedEcosystem],
  );

  const loadPendingMemberInvites = useCallback(
    async (skip: number, limit: number, filter: IFilterValue | null) => {
      try {
        if (!selectedEcosystem || !organizationSelectedId) {
          return { data: [], count: 0 };
        }
        const {
          data: { data, count },
        } = await InviteMemberService.findPendingInvites(
          organizationSelectedId,
          {
            skip,
            limit,
            filter: JSON.stringify({
              ...filter,
              isUsed: false,
              sharerEcosystem: selectedEcosystem._id,
            }),
            populate: JSON.stringify([
              {
                path: 'configurations.ecosystemId',
                model: 'Ecosystem',
              },
              {
                path: 'configurations.appsData.appId',
                model: 'App',
              },
              {
                path: 'configurations.appsData.appPermissionGroupsData.appPermissionGroupId',
                model: 'AppPermissionGroup',
              },
            ]),
          },
        );
        setTotalMemberInvitesPending(count);
        return { data, count };
      } catch (error) {
        toast.error('Error loading pending member invites');
        return { data: [], count: 0 };
      }
    },
    [organizationSelectedId, selectedEcosystem],
  );

  return {
    totalPartnerInvitesPending,
    totalMemberInvitesPending,
    modalRef,
    modalInviteDetailsRef,
    isSubmitting,
    closeResendInviteModal,
    openResendInviteModal,
    onResendInvite,
    loadPendingPartnerInvites,
    loadPendingMemberInvites,
    copyInviteLink,
    openModalInviteDetails,
    inviteDetails,
  };
};
