import { TypeColumn } from '@inovua/reactdatagrid-community/types';
import DataTableActions from 'ui/components/DataTableActions';

import { TableColumnsFunctions } from '~/types';
import { AdminProfileTable } from '~/types/interfaces/table';
import { formatDateLong } from '~/utils/date';

export const adminProfilesColumns = ({
  onDelete,
}: TableColumnsFunctions<string>): TypeColumn[] => [
  {
    defaultFlex: 1,
    name: 'email',
    header: 'Email',
    render: ({ data }: AdminProfileTable) => {
      return data.user.email || 'Unknown';
    },
  },
  {
    defaultFlex: 1,
    name: 'name',
    header: 'Name',
    render: ({ data }: AdminProfileTable) => {
      return data.user.name || 'Unknown';
    },
  },
  {
    defaultFlex: 1,
    name: 'since',
    header: 'Admin since',
    render: ({ data }: AdminProfileTable) => {
      return formatDateLong(data.createdAt) || 'Unknown';
    },
  },
  {
    name: 'actions',
    header: 'Actions',
    render: ({ data }: AdminProfileTable) => {
      return (
        <DataTableActions
          deleteButton
          onPressDeleteButton={() => onDelete(data.user._id)}
        />
      );
    },
  },
];
