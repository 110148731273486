import { AxiosResponse } from 'axios';
import { StatusCodes } from 'http-status-codes';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { ImageZoneImageItem } from 'ui/components/Dropzone/ImageZoneMini/types';
import { ITabsRefProps } from 'ui/components/Tabs/types';
import {
  EActivityLocationTypeEnum,
  EEventApplicationType,
  ESpaceOptionsKeys,
  LocationOptionsEnum,
} from 'ui/enums';
import { ActivityType } from 'ui/types/activities';
import { getActivityDefinitionLocationOption } from 'ui/utils/activities';
import { convertCarouselImagesToImageZone } from 'ui/utils/formatter';
import { ValidationsRegex } from 'ui/utils/validations/validations';

import { DEFAULT_MEASUREMENT_UNIT_TIME_ID } from '~/config';
import { COMMON_ERRORS } from '~/constants/error.constants';
import { EVENT_MESSAGES } from '~/constants/messages.constants';
import { PAGES } from '~/constants/pages.constants';
import { CreateEditActivityPermissions } from '~/constants/permissions.org.constants';
import { ActivityTypeEnum, EventTypeEnum } from '~/enums';
import { useActivityCategoryOptions } from '~/hooks/useActivityCategoriesOptions';
import { useAppSelector } from '~/hooks/useAppSelector';
import { usePermissions } from '~/hooks/usePermissions';
import { useRouter } from '~/hooks/useRouter';
import ActivitiesService from '~/services/resources/activities';
import ActivityService from '~/services/resources/activity';
import ActivityDefinitionService from '~/services/resources/activityDefinition';
import {
  IActivityCategoryDTO,
  IActivityDefinitionDTO,
  IAddressDTO,
  ICreateEventDTO,
  IEditActivityDTO,
} from '~/types/dtos';
import { IActivity } from '~/types/interfaces/activity';
import {
  handleActivityCoverImage,
  handleCarouselImagesForActivityEdit,
} from '~/utils/activitiesImages';
import { mountEventPayload } from '~/utils/activityCreationAndEdit';
import {
  transformHoursToSeconds,
  transformSecondsToHours,
} from '~/utils/functions';

import { EventFormTabsPages } from '../../types';
import {
  mapFromHomeLocationFormValue,
  mapMultipleLocationsFormValue,
  mapSingleLocationFormValues,
} from '../../utils';
import { LocationData } from './WhenWhereForm/MultipleLocations/types';
export const useEventFormController = () => {
  const tabsRef = useRef<ITabsRefProps>(null);
  const [formData, setFormData] = useState<IEditActivityDTO>({
    eventApplicationType: EEventApplicationType.Individual,
  } as IEditActivityDTO);

  const [defaultLocationGroupsByDate, setDefaultLocationGroupsByDate] =
    useState<Record<number, LocationData[]>>({});

  const { organizationSelectedId } = useAppSelector(({ auth }) => auth);
  const { selectedEcosystem } = useAppSelector(({ ecosystem }) => ecosystem);

  const {
    replaceRoute,
    params: { id: eventId },
    searchParams,
  } = useRouter();
  const tabId = Number(searchParams.get('tabId'));

  const [currentTab, setCurrentTab] = useState(
    tabId || EventFormTabsPages.About,
  );
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingDefaultValues, setIsLoadingDefaultValues] = useState(true);
  const [activitiesIds, setActivitiesIds] = useState<string[]>([]);

  const { filterAppsForActivityCreation, validateIfOrganizationIsAppOwner } =
    usePermissions();

  const location = useLocation();
  const navigate = useNavigate();

  const { control } = useForm<ICreateEventDTO>({
    mode: 'onChange',
    reValidateMode: 'onBlur',
  });

  const {
    requirementOptions,
    causeOptions,
    requirementOptionsData,
    causeOptionsData,
  } = useActivityCategoryOptions();

  const isTeamEvent = useMemo(() => {
    return formData.eventApplicationType === EEventApplicationType.Team;
  }, [formData]);

  const filteredAppsByPermissions = filterAppsForActivityCreation(
    ActivityType.Event,
    true,
  );

  const appIdToValidatePermisssions =
    formData.selectedApp && formData?.selectedApp.length > 0
      ? (formData?.selectedApp[0] as string)
      : '';

  const requiredPagePermissions =
    formData.eventApplicationType === EEventApplicationType.Team
      ? CreateEditActivityPermissions.TeamEvent
      : CreateEditActivityPermissions.Event;

  const isAppOwner = validateIfOrganizationIsAppOwner(
    appIdToValidatePermisssions,
  );

  const handleEditActivitiesForSingleLocation = async (
    data: IEditActivityDTO,
    app: string,
    activityDefinitionData: IActivityDefinitionDTO,
  ) => {
    const payloads: Promise<AxiosResponse<IActivity>>[] = [];

    for (const activityId of activitiesIds.values()) {
      const activityIdStillExists = data.dates?.find(
        (date) => date._activityId && date._activityId === activityId,
      );

      if (!activityIdStillExists) {
        payloads.push(ActivityService.deleteOne(activityId));
      }
    }

    if (data.dates) {
      for (const date of data.dates?.values()) {
        try {
          const address = data.address;
          const activityData = {
            eventType: date.eventType,
            startDate: date.startDate,
            endDate: date.endDate,
            dueDate: date.dueDate ? date.dueDate : date.endDate,
            address,
            volunteerNumber: date.volunteerNumber
              ? Number(date.volunteerNumber)
              : undefined,
            noVolunteerLimit: date.noVolunteerLimit,
            app,
            eventApplicationType: data.eventApplicationType,
            teamsNumber: date.teamsNumber,
            teamsMinSize: date.teamsMinSize,
            teamsMaxSize: date.teamsMaxSize,
          } as IEditActivityDTO;

          const normalizedPayloadForActivity = mountEventPayload({
            activityData,
            activityDefinitionId: activityDefinitionData._id as string,
            ecosystemId: selectedEcosystem?._id as string,
            organizationId: organizationSelectedId as string,
            isFromHome: false,
          });
          const dateIsBeingEdited = !!date._activityId;

          let payload;

          if (dateIsBeingEdited) {
            payload = ActivitiesService.editActivityEvent(
              date._activityId as string,
              normalizedPayloadForActivity,
            );
          } else {
            payload = ActivitiesService.createActivityEvent(
              normalizedPayloadForActivity,
            );
          }

          payloads.push(payload);
        } catch (error) {
          toast.error(EVENT_MESSAGES.CREATE_EVENT_ERROR);
        }
      }
    } else {
      throw EVENT_MESSAGES.CREATE_EVENT_ERROR;
    }

    await Promise.all(payloads);
  };

  const handleEditActivitiesForMultipleLocations = async (
    data: IEditActivityDTO,
    app: string,
    activityDefinitionData: IActivityDefinitionDTO,
  ) => {
    const payloads: Promise<AxiosResponse<IActivity>>[] = [];

    for (const activityId of activitiesIds.values()) {
      const allLocations =
        data.locationsByGroup && Object.values(data.locationsByGroup).flat();
      const activityIdStillExists = allLocations?.find(
        (date) => date._activityId && date._activityId === activityId,
      );

      if (!activityIdStillExists) {
        payloads.push(ActivityService.deleteOne(activityId));
      }
    }

    if (data.locationsGroups) {
      for (const [index, date] of data.locationsGroups?.entries()) {
        if (data.locationsByGroup && data.locationsByGroup[index]) {
          for (const locationData of data.locationsByGroup[index]?.values()) {
            if (!locationData.location) return;
            try {
              const address: IAddressDTO = {
                street: locationData.returnedAddress,
                rawLocation: locationData.returnedAddress,
                location: {
                  type: 'Point',
                  coordinates: [
                    locationData.location.lng,
                    locationData.location.lat,
                  ],
                },
              };

              const activityData = {
                eventType: EventTypeEnum.National,
                startDate: date.startDate,
                endDate: date.endDate,
                dueDate: date.dueDate ? date.dueDate : date.endDate,
                address,
                volunteerNumber: date.volunteerNumber
                  ? Number(date.volunteerNumber)
                  : undefined,
                noVolunteerLimit: date.noVolunteerLimit,
                app,
                attendanceType: data.attendanceType,
                eventApplicationType: data.eventApplicationType,
                teamsNumber: date.teamsNumber,
                teamsMinSize: date.teamsMinSize,
                teamsMaxSize: date.teamsMaxSize,
              } as Partial<ICreateEventDTO>;

              const normalizedPayloadForActivity = mountEventPayload({
                activityData,
                activityDefinitionId: activityDefinitionData._id as string,
                ecosystemId: selectedEcosystem?._id as string,
                organizationId: organizationSelectedId as string,
                isFromHome: false,
              });

              const dateIsBeingEdited = !!locationData._activityId;

              let payload;

              if (dateIsBeingEdited) {
                payload = ActivitiesService.editActivityEvent(
                  locationData._activityId as string,
                  normalizedPayloadForActivity,
                );
              } else {
                payload = ActivitiesService.createActivityEvent(
                  normalizedPayloadForActivity,
                );
              }

              payloads.push(payload);
            } catch (error) {
              toast.error(EVENT_MESSAGES.CREATE_EVENT_ERROR);
            }
          }
        }
      }
    } else {
      throw EVENT_MESSAGES.CREATE_EVENT_ERROR;
    }

    await Promise.all(payloads);
  };
  const handleEditActivitiesFromHome = async (
    data: IEditActivityDTO,
    app: string,
    activityDefinitionData: IActivityDefinitionDTO,
  ) => {
    const payloads: Promise<AxiosResponse<IActivity>>[] = [];

    for (const activityId of activitiesIds.values()) {
      const activityIdStillExists = data.dates?.find(
        (date) => date._activityId && date._activityId === activityId,
      );

      if (!activityIdStillExists) {
        payloads.push(ActivityService.deleteOne(activityId));
      }
    }

    if (data.dates) {
      for (const date of data.dates?.values()) {
        try {
          const activityData = {
            eventType: date.eventType,
            startDate: date.startDate,
            endDate: date.endDate,
            dueDate: date.dueDate ? date.dueDate : date.endDate,
            volunteerNumber: date.volunteerNumber
              ? Number(date.volunteerNumber)
              : undefined,
            noVolunteerLimit: date.noVolunteerLimit,
            app,
            attendanceType: data.attendanceType,
            eventApplicationType: data.eventApplicationType,
            teamsNumber: date.teamsNumber,
            teamsMinSize: date.teamsMinSize,
            teamsMaxSize: date.teamsMaxSize,
            regions: data.regions,
            meetingLink: data.meetingLink,
          } as IEditActivityDTO;

          const normalizedPayloadForActivity = mountEventPayload({
            activityData,
            activityDefinitionId: activityDefinitionData._id as string,
            ecosystemId: selectedEcosystem?._id as string,
            organizationId: organizationSelectedId as string,
            isFromHome: true,
          });

          const dateIsBeingEdited = !!date._activityId;

          let payload;

          if (dateIsBeingEdited) {
            payload = ActivitiesService.editActivityEvent(
              date._activityId as string,
              normalizedPayloadForActivity,
            );
          } else {
            payload = ActivitiesService.createActivityEvent(
              normalizedPayloadForActivity,
            );
          }

          payloads.push(payload);
        } catch (error) {
          toast.error(EVENT_MESSAGES.CREATE_EVENT_ERROR);
        }
      }
    } else {
      throw EVENT_MESSAGES.CREATE_EVENT_ERROR;
    }

    await Promise.all(payloads);
  };
  const editEvent = async (data: IEditActivityDTO) => {
    try {
      setIsLoading(true);
      const causeOptions = causeOptionsData?.data.filter(
        (cause: IActivityCategoryDTO) => data.cause?.includes(cause._id),
      );

      let requirementOptions: IActivityCategoryDTO[] | undefined;

      if (data.requirementOptions?.length) {
        requirementOptions = requirementOptionsData?.data?.filter(
          ({ _id }: { _id: string }) => {
            // @mycatdoitbetter TODO: Fix this, this is a really bad way to do this
            // the data.requirementOptions is a string[] and the _id is a string...
            const requirementOptions =
              data.requirementOptions as unknown as string[];

            return requirementOptions.find(
              (requirementOption) => requirementOption === _id,
            );
          },
        );
      }

      const app = Array.isArray(data.selectedApp) ? data.selectedApp[0] : '';
      if (!app) {
        throw EVENT_MESSAGES.EDIT_EVENT_ERROR;
      }

      const isApprovalRequired =
        data.eventApprovalRequired === 'yes' ? true : false;

      const { coverImageURL, thumbnailImageURL } =
        await handleActivityCoverImage(data.coverImage, data.thumbnailImage);

      const carouselImagesUrls = await handleCarouselImagesForActivityEdit(
        data.carouselImages as ImageZoneImageItem[],
      );

      const activityDefinitionPayload: IEditActivityDTO = {
        ...data,
        title: data.title,
        description: data.description,
        // TODO: Remove this once it is not required anymore
        // All will be national for now.
        eventType: EActivityLocationTypeEnum.National,
        coverImage: coverImageURL,
        carouselImages: carouselImagesUrls as string[],
        app: app as string,
        ecosystem: selectedEcosystem?._id,
        volunteerRewards: data.volunteerRewards,
        volunteerRequirements: data.volunteerRequirements,
        type: ActivityTypeEnum.Event,
        organization: organizationSelectedId,
        attendanceType: data.attendanceType,
        spaceOptions: data.spaceOptions,
        typeOfWork: data.typeOfWork,
        causeOptions,
        requirementOptions: requirementOptions,
        publishedApps: data.publishedApps,
        eventApplicationType: data.eventApplicationType,
        isApprovalRequired,
        locationOption: data.locationOption,
        isOnline: data.meetingLink ? true : false,
        thumbnailImage: thumbnailImageURL,
      };

      if (data.targetAmount) {
        activityDefinitionPayload['targetAmount'] = transformHoursToSeconds(
          Number(data.targetAmount),
        );
        activityDefinitionPayload['measurementUnit'] =
          DEFAULT_MEASUREMENT_UNIT_TIME_ID;
      }

      if (
        data.locationOption === LocationOptionsEnum.FromHome &&
        data.meetingLink
      ) {
        if (!ValidationsRegex.Url.test(encodeURI(data.meetingLink))) {
          toast.error(
            'Meeting link should be a valid full url! Only https is allowed. E.g: https://example.com',
          );
          throw Error();
        }
      }

      const { data: activityDefinitionData } =
        await ActivityDefinitionService.editEventActivityDefinition(
          activityDefinitionPayload._id as string,
          activityDefinitionPayload,
        );

      const locationOption = data.locationOption;

      switch (locationOption) {
        case LocationOptionsEnum.SingleLocation: {
          await handleEditActivitiesForSingleLocation(
            data,
            app,
            activityDefinitionData,
          );
          break;
        }
        case LocationOptionsEnum.MultipleLocations: {
          await handleEditActivitiesForMultipleLocations(
            data,
            app,
            activityDefinitionData,
          );
          break;
        }
        case LocationOptionsEnum.FromHome: {
          await handleEditActivitiesFromHome(data, app, activityDefinitionData);
          break;
        }
      }

      replaceRoute(`${PAGES.ACTIVITIES}`);
      setTimeout(() => {
        replaceRoute(`${PAGES.EDIT_EVENT_SUCCESS}`, {
          state: {
            locationForActivitySuccessful: location,
            activity: activityDefinitionData,
          },
        });
      }, 500);
    } catch (error) {
      toast.error(EVENT_MESSAGES.CREATE_EVENT_ERROR);
    } finally {
      setIsLoading(false);
    }
  };

  const handleContinue = (data: IEditActivityDTO) => {
    setFormData((prev: IEditActivityDTO) => ({ ...prev, ...data }));

    if (tabsRef.current?.currentTabIndex !== 2) {
      tabsRef.current?.nextStep();
      setCurrentTab((prev) => prev + 1);
    } else {
      editEvent({
        ...formData,
        ...data,
      });
    }
  };

  const handleBack = () => {
    if (
      tabsRef.current?.currentTabIndex &&
      tabsRef.current?.currentTabIndex > 0
    ) {
      tabsRef.current?.backStep();
      setCurrentTab((prev) => prev - 1);
    } else {
      navigate(-1);
    }
  };

  const loadData = useCallback(async () => {
    try {
      setIsLoadingDefaultValues(true);
      if (!eventId) return;
      const { data: activityDefinition, status: activityDefinitionStatus } =
        await ActivityDefinitionService.get(eventId);

      // TODO: Improve this request to have pagination?
      const {
        data: allActivitiesByDefinition,
        status: allActivitiesByDefinitionStatus,
      } = await ActivityService.getAllByDefinition(
        activityDefinition._id,
        activityDefinition.activitiesSummary.length,
      );

      if (
        activityDefinitionStatus === StatusCodes.OK &&
        allActivitiesByDefinitionStatus === StatusCodes.OK
      ) {
        const idsToSave = allActivitiesByDefinition.data.map(
          (activity: IEditActivityDTO) => {
            return activity._id;
          },
        );

        setActivitiesIds(idsToSave);

        const activityLocationOption = getActivityDefinitionLocationOption(
          activityDefinition.locationOption,
          allActivitiesByDefinition.data.length,
        );

        const eventApprovalRequired = activityDefinition.isApprovalRequired
          ? 'yes'
          : 'no';

        const spaceOption = (
          activityDefinition.spaceOptions || ESpaceOptionsKeys.Indoor
        ).toLowerCase() as ESpaceOptionsKeys;

        const parsedFormData: IEditActivityDTO = {
          ...activityDefinition,
          // @mycatdoitbetter TODO: Fix this type
          cause: activityDefinition.causeOptions.map(
            (cause: { _id: string }) => cause._id,
          ),
          volunteerRequirements: activityDefinition.volunteerRequirements,
          spaceOptions: spaceOption,
          typeOfWork: activityDefinition.typeOfWork,
          attendanceType: activityDefinition.attendanceType,
          eventConfiguration: 'Individual',
          selectedApp: [activityDefinition.app],
          eventApprovalRequired,
          // @mycatdoitbetter  TODO: Fix this type
          requirementOptions: activityDefinition.requirementOptions.map(
            (option: { _id: string }) => option._id,
          ),
          locationOption: activityLocationOption,
          carouselImages: convertCarouselImagesToImageZone(
            activityDefinition.carouselImages,
          ),
        };

        // Populate the form data early with activity definition data.
        setFormData(parsedFormData);

        if (activityDefinition.targetAmount) {
          parsedFormData['targetAmount'] = transformSecondsToHours(
            activityDefinition.targetAmount,
          );
        }

        switch (activityLocationOption) {
          case LocationOptionsEnum.SingleLocation: {
            const { dates, address } = mapSingleLocationFormValues(
              allActivitiesByDefinition.data,
            );

            setFormData({
              ...parsedFormData,
              dates,
              address,
            });
            break;
          }

          case LocationOptionsEnum.MultipleLocations: {
            const { locationGroups, locationGroupsByDate } =
              mapMultipleLocationsFormValue(allActivitiesByDefinition.data);
            setDefaultLocationGroupsByDate(locationGroupsByDate);

            setFormData({
              ...parsedFormData,
              locationsGroups: locationGroups,
            });
            break;
          }
          case LocationOptionsEnum.FromHome: {
            const { dates } = mapFromHomeLocationFormValue(
              allActivitiesByDefinition.data,
            );

            // From Home Activities share the same regions and meetingLink accross all places/dates, so we need only to get the first value on the activities list.
            const activityRegions =
              allActivitiesByDefinition?.data?.[0]?.regions || [];
            const activityMeetingLink =
              allActivitiesByDefinition?.data?.[0]?.meetingLink || null;

            setFormData({
              ...parsedFormData,
              regions: activityRegions,
              meetingLink: activityMeetingLink,
              dates,
            });
            break;
          }
          default:
            setFormData(parsedFormData);
            throw new Error(COMMON_ERRORS.ERROR_ON_LOAD_ACTIVITIES);
        }
      } else {
        throw new Error(COMMON_ERRORS.ERROR_ON_LOAD_EVENT);
      }
    } catch (error: any) {
      toast.error(error.message);
    } finally {
      setIsLoadingDefaultValues(false);
    }
  }, [eventId]);
  useEffect(() => {
    loadData();
  }, [loadData]);

  const currentLocationOption = formData.locationOption;
  return {
    tabsRef,
    currentTab,
    causeOptions,
    requirementOptions,
    handleContinue,
    handleBack,
    isLoading,
    formData,
    control,
    isTeamEvent,
    isLoadingDefaultValues,
    filteredAppsByPermissions,
    appIdToValidatePermisssions,
    requiredPagePermissions,
    isAppOwner,
    defaultLocationGroupsByDate,
    currentLocationOption,
  };
};
