import { Text } from 'native-base';

import { colors } from '../../theme/colors';
import Button from '../Button';
import { GoBackButtonProps } from './types';

export const GoBackButton = ({
  onPress,
  color = colors.singletons.black,
  title = 'Back',
}: GoBackButtonProps) => {
  return (
    <Button
      variant="unstyled"
      maxWidth="140px"
      width="100%"
      leftIconName="chevron-left"
      leftIconColor={color}
      onPress={onPress}
      fontWeight={500}
    >
      <Text fontSize="sm" color={color}>
        {title}
      </Text>
    </Button>
  );
};
