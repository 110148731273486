import { PermissionType } from 'ui/enums';
import {
  Permission,
  PermissionCategory,
  PermissionCondition,
  PermissionRestrictionType,
  PermissionSharingSettings,
} from 'ui/types/interfaces/app-permissions-group';

export enum AddPermissionGroupTab {
  GroupDetails = 0,
  Permissions = 1,
  Review = 2,
}

export interface PermissionConfiguration {
  templateId: string;
  conditions: PermissionCondition[];
  sharingSettings: PermissionSharingSettings;
  restrictionType: PermissionRestrictionType;
}

export interface GroupedPermissions {
  category: PermissionCategory;
  permissions: Permission[];
  configurations: PermissionConfiguration[];
}

export interface AddPermissionGroupFormValues {
  app: string;
  name: string;
  type: PermissionType;
  description: string;
  groupedPermissions: GroupedPermissions[];
  error: string;
}

export interface AddPermissionsGroupProps {
  isEditing?: boolean;
}
