import { Navigate, Route } from 'react-router-dom';

import { PAGES } from '~/constants/pages.constants';

export const NFPRoutes = () => {
  return (
    <>
      {/* TODO: Change it when we had NFP Home page  */}
      <Route
        path={PAGES.HOME}
        element={<Navigate to={PAGES.ACTIVITIES} replace />}
      />
    </>
  );
};
