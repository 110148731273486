import { PermissionType } from 'ui/enums';
import {
  PermissionCondition,
  PermissionSharingSettings,
} from 'ui/types/interfaces/app-permissions-group';

import { api } from '~/services/api';

const MODULE_URL = '/app-permission-group';

export interface PermissionsDTO {
  permissionTemplateId: string;
  app: string;
  conditions: PermissionCondition[];
  sharingSettings: PermissionSharingSettings;
}

export interface EditPermissionsDTO {
  permissionTemplateId: string;
  conditions: PermissionCondition[];
  sharingSettings: PermissionSharingSettings;
}

export interface CreateAppGroupPermissionDTO {
  name: string;
  description?: string;
  type: PermissionType;
  organization: string;
  app: string;
  ecosystem: string;
  permissions: PermissionsDTO[];
}

export interface AppUpdateDraftDTO {
  isDraft: boolean;
}

export interface BulkAssignMemberDTO {
  userProfileIds: string[];
  appPermissionGroupId: string;
}

export interface EditAppGroupPermissionDTO
  extends Omit<CreateAppGroupPermissionDTO, 'permissions' | 'type'> {
  permissions: EditPermissionsDTO[];
}

export class AppPermissionGroupService {
  static async create(payload: Partial<CreateAppGroupPermissionDTO>) {
    return await api.post(`${MODULE_URL}`, payload);
  }

  static async update(id: string, payload: Partial<EditAppGroupPermissionDTO>) {
    return await api.put(`${MODULE_URL}/${id}`, payload);
  }

  static async updateDraft(id: string, payload: AppUpdateDraftDTO) {
    return await api.post(`${MODULE_URL}/${id}/update-draft`, payload);
  }

  static async bulkAssignMember(payload: BulkAssignMemberDTO) {
    return await api.post(`${MODULE_URL}/bulk-assign-member`, payload);
  }

  static async delete(id: string) {
    return await api.delete(`${MODULE_URL}/${id}`);
  }

  static async findAppSharingTemplatePermissions(
    ids: string[],
    type: PermissionType,
  ) {
    return await api.get(`${MODULE_URL}`, {
      params: {
        filter: JSON.stringify({
          _id: { $in: ids },
          type,
        }),
        populate: JSON.stringify([
          { model: 'AppPermission', path: 'permissions' },
        ]),
      },
    });
  }

  static async findAllTemplatePermissions(type: PermissionType) {
    return await api.get(`app-permission/templates`, {
      params: {
        type,
      },
    });
  }
}
