import { FeedNotificationsInfinityLoadProps } from '~/hooks/feed-notification/types';
import { useQuery } from '~/hooks/useQuery';

export const useLoadFeedNotificationCount = (
  filter: FeedNotificationsInfinityLoadProps,
  isEnabled = false,
  refetchInterval = 15000,
) => {
  const {
    ecosystemId,
    organizationId,
    appIds,
    types,
    startDate,
    notificationTargets,
  } = filter;
  const url = `org-admin/feed-notifications/${organizationId}/count`;

  const { data, isLoading, refetch, remove } = useQuery<{
    totalCount: number;
  }>(url, {
    queryOptions: {
      cacheTime: 0,
      enabled: isEnabled,
      retry: false,
      initialData: 0,
      // Prevent refetch  while their tab is not focused
      refetchIntervalInBackground: false,
      refetchInterval,
    },
    requestOptions: {
      params: {
        startDate,
        ecosystemId,
        apps: JSON.stringify(appIds),
        types: JSON.stringify(types),
        notificationTargets: JSON.stringify(notificationTargets),
      },
    },
  });

  return {
    data: data?.totalCount || 0,
    isLoading,
    refetch,
    remove,
  };
};
