import { HStack, Text } from 'native-base';

import { LogsSummaryTileHeaderProps } from './types';

export const LogsSummaryTileHeader = ({
  activityTitle,
  organizationName,
  appName,
}: LogsSummaryTileHeaderProps) => {
  return (
    <HStack w="full" alignItems={'center'} space={3}>
      <Text
        fontSize={{ base: 'sm', lg: 'md' }}
        fontWeight={'bold'}
        noOfLines={1}
      >
        {activityTitle}
      </Text>

      <HStack flex={1} space={3} alignItems={'center'}>
        {!!appName && (
          <>
            <Text fontSize={'md'} color={'gray.600'} fontWeight={500}>
              •
            </Text>

            <Text
              fontSize={{ base: 'sm', lg: 'md' }}
              color={'singletons.darkText'}
              fontWeight={500}
              noOfLines={1}
            >
              {appName}
            </Text>
          </>
        )}

        {!!organizationName && (
          <>
            <Text fontSize={'md'} color={'gray.600'} fontWeight={500}>
              •
            </Text>

            <Text
              fontSize={{ base: 'sm', lg: 'md' }}
              color={'singletons.darkText'}
              noOfLines={1}
            >
              by {organizationName}
            </Text>
          </>
        )}
      </HStack>
    </HStack>
  );
};
