import {
  TableOperatorNames,
  TableOperatorType,
} from 'ui/components/DataTable/types';

export const defaultFiltersValues = [
  {
    name: 'userSummary.name',
    operator: TableOperatorNames.Contains,
    type: TableOperatorType.String,
    value: '',
  },
  {
    name: 'userSummary.email',
    operator: TableOperatorNames.Contains,
    type: TableOperatorType.String,
    value: '',
  },
  {
    name: 'organizationSubDocument.name',
    operator: TableOperatorNames.Contains,
    type: TableOperatorType.String,
    value: '',
  },
  {
    name: 'externalId',
    operator: TableOperatorNames.Contains,
    type: TableOperatorType.String,
    value: '',
  },
];
