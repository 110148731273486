import { Stack } from 'native-base';
import Button from 'ui/components/Button';

import { useSideBarWithContentController } from './controller';
import { SideBarWithContentProps } from './types';

export const SettingsSideBar = ({ sideBarList }: SideBarWithContentProps) => {
  const { sideBarButtonStyle } = useSideBarWithContentController();

  return (
    <Stack bgColor="white" borderRadius="3xl" minH="100vh">
      <Stack padding={0}>
        {sideBarList?.map((item, index) => (
          <Stack key={item.title} padding={1}>
            <Button
              borderRadius="3xl"
              width="100%"
              bgColor={
                sideBarButtonStyle(sideBarList[index].isSelected).bgColor
              }
              justifyContent="flex-start"
              _hover={sideBarButtonStyle(sideBarList[index].isSelected).hover}
              _text={sideBarButtonStyle(sideBarList[index].isSelected).text}
              onPress={sideBarList[index].onPress}
            >
              {item.title}
            </Button>
          </Stack>
        ))}
      </Stack>
    </Stack>
  );
};
