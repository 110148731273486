import { useQuery } from '~/hooks/useQuery';

import { ActivityTypeEnum } from '../../../../enums';

export const useLoadPartnerApplicationsReport = (connectionId: string) => {
  const { data, isLoading, isRefetching } = useQuery<{
    [key in ActivityTypeEnum]: number;
  }>(`activity-application-insights/partner/${connectionId}/count`, {
    key: `partner-activities-report-${connectionId}-count`,
  });

  return { data, isLoading, isRefetching };
};
