import { useCallback, useEffect, useState } from 'react';
import { FileRejection, useDropzone } from 'react-dropzone';
import { toast } from 'react-toastify';

import { CustomFile, IMAGES_ZONE_ERROR } from '../types';
import {
  DEFAULT_FILE_ERROR,
  FILE_REMOVE_ERROR,
  INVALID_FILE_FORMAT_ERROR,
  TOO_LARGE_FILE_ERROR,
  TOO_SMALL_FILE_ERROR,
} from '../utils';
import { ImageZoneImageItem, ImageZoneMiniProps } from './types';

export const useImageZoneMiniController = ({
  files,
  deleteImageFn,
  errors,
  register,
  setValue,
  showTitle = true,
  handleDropFile,
  handleRemoveFile,
  acceptedSize = 5,
  filesField = 'images',
  filesAccepted,
  defaultImages,
}: ImageZoneMiniProps) => {
  const [imageZoneMiniImages, setImageZoneMiniImages] = useState<
    ImageZoneImageItem[]
  >([]);

  const onDropAccepted = useCallback(
    (droppedContent) => {
      for (const content of droppedContent) {
        if (content.size > acceptedSize * 1024 * 1024) {
          toast.error(TOO_LARGE_FILE_ERROR);
          return;
        }
      }
      const filesOnDrop = droppedContent.map((file: File, index: number) => {
        const normalizedFile: ImageZoneImageItem = {
          isFile: true,
          imageFile: Object.assign(file, {
            id: index,
            image: URL.createObjectURL(file),
            isCover: false,
          }),
        };

        return normalizedFile;
      });

      setImageZoneMiniImages((previousImages) => {
        const newImages = [...previousImages, ...filesOnDrop];
        const imagesWithIds = newImages.map((image, index) => ({
          ...image,
          id: index,
        }));

        if (handleDropFile) {
          handleDropFile(imagesWithIds);
        }
        return imagesWithIds;
      });
    },
    [handleDropFile, acceptedSize],
  );

  const onDropRejected = (rejections: FileRejection[]) => {
    const errors = rejections[0]?.errors;
    const errorMessage = errors[0]?.code;
    let errMessage = DEFAULT_FILE_ERROR;

    switch (errorMessage) {
      case IMAGES_ZONE_ERROR.TOO_SMALL_FILE:
        errMessage = TOO_SMALL_FILE_ERROR;
        break;
      case IMAGES_ZONE_ERROR.TOO_LARGE_FILE:
        errMessage = TOO_LARGE_FILE_ERROR;
        break;
      case IMAGES_ZONE_ERROR.INVALID_FILE_FORMAT:
        errMessage = INVALID_FILE_FORMAT_ERROR;
        break;
    }
    toast.error(errMessage);
  };

  const { getRootProps, getInputProps } = useDropzone({
    useFsAccessApi: false,
    multiple: true,
    onDropAccepted,
    onDropRejected,
  });

  const handleDeleteFile = (fileToDelete?: ImageZoneImageItem) => {
    if (!fileToDelete) return;
    const updatedFiles = [...imageZoneMiniImages];

    const isNumberId = typeof fileToDelete.id === 'number';
    if (isNumberId) {
      updatedFiles.splice(fileToDelete.id as number, 1);
      if (handleRemoveFile) {
        const normalizedUpdatedFiles = updatedFiles.map(
          (updatedFile) => updatedFile.imageFile,
        ) as CustomFile[];
        handleRemoveFile(normalizedUpdatedFiles, fileToDelete?.id as number);
      }

      const reorderIds = updatedFiles.map((updatedFile, index) => ({
        ...updatedFile,
        id: index,
      }));

      setImageZoneMiniImages(reorderIds);
      if (handleDropFile) {
        handleDropFile(reorderIds);
      }
    }
  };

  useEffect(() => {
    if (defaultImages && defaultImages.length > 0) {
      setImageZoneMiniImages(defaultImages);
    }
  }, [defaultImages]);

  useEffect(() => {
    if (register) register(filesField);
  }, [register, filesField]);

  return {
    imageZoneMiniImages,
    files,
    deleteImageFn,
    errors,
    register,
    setValue,
    showTitle,
    handleDropFile,
    handleRemoveFile,
    acceptedSize,
    filesField,
    filesAccepted,
    handleDeleteFile,
    getRootProps,
    getInputProps,
    onDropAccepted,
    onDropRejected,
  };
};
