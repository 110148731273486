import { useNavigate } from 'react-router-dom';
import { App } from 'ui/types/interfaces/app';

import { PAGES } from '~/constants/pages.constants';
import { useEcosystemAvailableApps } from '~/hooks/apps/useEcosystemAvailableApps';
import { useLoadEcosystemUserProfilesCount } from '~/hooks/ecosystem/useLoadEcosystemUserProfilesCount';
import { useAppSelector } from '~/hooks/useAppSelector';
import { TabsPages as MembersTabPages } from '~/pages/Authenticated/Members/types';

export const useDashboardController = () => {
  const navigate = useNavigate();

  const { selectedEcosystem } = useAppSelector(({ ecosystem }) => ecosystem);

  const { data: userProfilesCount, isLoading: isUserProfilesCountLoading } =
    useLoadEcosystemUserProfilesCount(selectedEcosystem?._id || '');

  // Hooks
  const { apps } = useEcosystemAvailableApps();

  // handlers
  const handleInviteButtonPress = () => {
    navigate(`${PAGES.MEMBERS}?tab=${MembersTabPages.PendingInvites}`);
  };

  const handleViewInsightsButtonPress = () => {
    navigate(PAGES.INSIGHTS);
  };

  const handleViewAppsButtonPress = () => {
    navigate(PAGES.APP_SETTINGS);
  };

  const handleAppPress = (app: App) => {
    navigate(`${PAGES.APP_SETTINGS}/${app._id || ''}`);
  };

  const handleUpdateCompanyProfileButtonPress = () => {
    navigate(PAGES.SETTINGS);
  };

  const handleConnectNewAppButtonPress = () => {
    navigate(
      `${PAGES.EDIT_ECOSYSTEM}/${
        selectedEcosystem?._id || ''
      }/manage-apps?a=add-app`,
    );
  };

  return {
    apps,
    userProfilesCount,
    isUserProfilesCountLoading,
    handleAppPress,
    handleInviteButtonPress,
    handleViewAppsButtonPress,
    handleViewInsightsButtonPress,
    handleConnectNewAppButtonPress,
    handleUpdateCompanyProfileButtonPress,
  };
};
