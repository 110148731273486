export const COMMON_MESSAGES = {
  API_KEY_COPIED: 'API Key copied',
  UPDATE_SUCCESS: 'Updated data',
  UNABLE_CHANGE_DATA: 'Unable to change data',
  UNABLE_SEND_REQUEST: 'Unable to send request',
  UNABLE_SEND_ACCESS_CODE: 'Unable to send access code',
  UNABLE_SAVE_DATA: 'Unable to save data',
  PASSWORD_CHANGED: 'Password changed',
  PASSWORD_RESETED: 'Password successfully defined!',
  PASSWORD_RULES:
    'Your password must contain at least 8 characters (uppercase, lowercase, numbers and special characters)',
  ERROR_CHANGING_PASSWORD: 'Error when changing password',
  ACCESS_CODE_SENT: 'Code sent!',
  UNVERIFIED_ACCOUNT:
    'Looks like your account was not verified yet. We sent you another code. Please check your email',
  SESSION_EXPIRED: 'Session expired, sign in again.',
  ACCEPT_TERMS: 'You must accept the terms to proceed.',
  UNKNOWN_ERROR: 'We had a problem please try again',
  SUCCESSFUL_LOGIN: 'successful login',
  LINK_COPIED: 'Link copied',
  EMAIL_CHANGED: 'Email changed',
  EMAIL_SENT: 'Email sent successfully.',
  CHANGE_EMAIL_ERROR:
    'An error ocurred while trying to change email, please try again.',
  CODE_VERIFIED_WITH_SUCCESS: 'Code verified with success',
  ERROR_FETCHING_COMPANY: 'Error fetching the company.',
  ERROR_FETCHING_APPLICATIONS: 'Error fetching applications list.',
  CANCEL_ACTIVITY_SUCCESS: 'Activity successfully cancelled!',
  CANCEL_ACTIVITY_ERROR: 'Error when cancelling the activity.',
  ERROR_FETCHING_APP_CONNECTIONS: 'Error fetching app connected ecosystems.',
  ERROR_FETCHING_PARTNER_CREATED_ACTIVITIES:
    'Error fetching partnes created activities.',
};

export const SIGN_UP_MESSAGES = {
  CREATE_ACCOUNT_ERROR: 'An error occurred while trying to create the account.',
  ACCOUNT_CREATED_SUCCESSFULLY: 'Account created successfully',
  EMAIL_ALREADY_EXISTS: 'Email already exists.',
  EXTERNAL_ID_ALREADY_EXISTS: 'External ID already exists.',
  VERIFY_CODE_ERROR:
    'An error occurred while trying to verify the code, please confirm that the code is correct and try again.',
  ERROR_ON_UPLOAD: `We encountered an issue while uploading your company's image. You can try again in a few minutes or submit without one.`,
  ERROR_CREATING_COMPANY: 'Error when creating a company.',
  ERROR_URL_ALREADY_EXISTS: 'URL already exists. Try a different one!',
  ERROR_JOINING_ECO_SYSTEM:
    'An error occurred while trying to join the EcoSystem.',
  ERROR_ADMIN_INVITE: 'An error ocurred while sending admin invites.',
};

export const RECOVERY_PASSWORD = {
  INVALID_CODE: 'Invalid verification code provided, please try again.',
  CURRENT_PASSWORD_INCORRECT: 'Current password is incorrect.',
};

export const MEMBERS = {
  ERROR_ON_LOAD: 'Error on trying to load members',
  DISCONNECT_FROM_ECOSYSTEM_SUCCESS: 'Member disconnected successfully.',
};

export const ACCOUNT_MESSAGES = {
  ERROR_DELETE_ACCOUNT: 'Error on deleting the account.',
  ERROR_ADMIN_INVITE: 'An error ocurred while sending admin invites.',
  ADMIN_INVITE_SENT: 'Admin invites sent successfully.',
  DELETED_SUCCESSFULLY: 'Account deleted successfully.',
};

export const ECOSYSTEM_INVITATION_MESSAGES = {
  INVITATION_ACCEPTED: 'You have been accepted to this organisation.',
  INVITATION_ERROR: 'An error occurred while trying to accept the invitation.',
  INVITATION_CODE_INVALID: 'This invitation link is not valid anymore.',
};

export const EMAIL_PREFERENCES = {
  ERROR_ON_SAVE: 'Error on trying to save email preferences.',
  SAVE_SUCCESS: 'Email preferences saved successfully.',
};

export const ORGANIZATION_MESSAGES = {
  SUCCESS_ON_CREATE: 'Organisation created with success.',
  ERROR_ON_CREATE:
    'An error occurred while trying to create the organisation. Please check submitted data and try again later.',
  SUCCESS_ON_UPDATE: 'Changes saved successfully!',
  ERROR_ON_UPDATE: 'Error on updated organisation.',
  ERROR_ON_UPLOAD: `We encountered an issue while uploading your organisation's image. You can try again in a few minutes or submit without one.`,
  API_KEY_NOT_FOUND: 'API Key not found.',
  API_KEY_ERROR: 'Unable to retrieve API Key.',
  API_KEY_CREATE_ERROR: 'Unable to create a new API Key.',
  WEBHOOK_URL_UPDATE_ERROR: 'Error on trying to update webhook url.',
  WEBHOOK_URL_UPDATE_SUCCESS: 'Webhook url updated with success.',
  REMOVE_PARTNER_SUCCESS: 'Partner removed successfully',
  REMOVE_MEMBER_SUCCESS: 'Member removed successfully',
  ERROR_ON_LOAD_PARTNERS: 'Error on trying to load partners.',
  ERROR_ON_REMOVE_PARTNER: 'Error on trying to remove partner.',
  ERROR_ON_REMOVE_MEMBER: 'Error on trying to remove member.',
  ERROR_ON_LOAD_MEMBERS: 'Error on trying to load members.',
};

export const ECOSYSTEM_MESSAGES = {
  SUCCESS_ON_CREATE: 'Ecosystem created with success.',
  ERROR_ON_CREATE:
    'An error occurred when trying to create the ecosystem, please check the data and try again.',
  SUCCESS_ON_UPDATE: 'Ecosystem updated with success.',
  ERROR_ON_UPDATE: 'Error on updated ecosystem.',
};

export const REPORT_MESSAGES = {
  REQUESTED:
    'Your report is being processed. You will receive an email with the download link soon.',
  DOWNLOAD_MESSAGE: 'Dowloading report.',
  DOWNLOAD_ERROR: 'An error occurred while trying to download the report.',
  FILE_PROCESSING:
    'Your file is currently being processed and will be sent to you via email once it is completed.',
};

export const ACTION_MESSAGES = {
  DELETED_SUCCESS: 'Action successfully deleted!',
  DELETED_ERROR: 'Error when deleting action.',
  NOT_FOUND: 'Action not found.',
  CREATE_ACTION_ERROR:
    'An error occurred while trying to create the action, please check the data and try again.',
  EDIT_ACTION_ERROR:
    'An error occurred while trying to edit the action, please check the data and try again.',
};

export const EVENT_MESSAGES = {
  NOT_FOUND: 'Event not found.',
  CREATE_EVENT_ERROR:
    'An error ocurred while trying to create the event, please check the data and try again.',
  EDIT_EVENT_SUCCESS: 'Event edited with success.',
  EDIT_EVENT_ERROR:
    'An error ocurred while trying to edit the event, please try again.',
};

export const OPPORTUNITY_MESSAGES = {
  NOT_FOUND: 'Opportunity not found.',
  CREATE_OPPORTUNITY_ERROR:
    'An error ocurred while trying to create the opportunity, please check the data and try again.',
};

export const MEASUREMENT_UNIT_MESSAGES = {
  SUCCESSFULY_CREATED: 'Custom unit created successfully.',
  ERROR_ON_CREATE: 'Error on trying to create custom unit.',
};

export const TARGET_AMOUNT_MESSAGE = `
The target amount refers to the specific quantity (measured in hours, kilograms, or any custom unit) that volunteers need to achieve for a particular action. When filling out this field, you are indicating the desired amount of effort or output required from each volunteer to successfully complete the activity.

For instance, if you enter "6 hours" as the target amount, it means that you expect every volunteer to dedicate 6 hours towards performing the action in question. This ensures that the activity is considered complete only when the participant has contributed at least 6 hours of their time.

By setting a target, you enable a consistent measure of progress and accomplishment. It helps in monitoring and tracking the contributions made by individuals, ensuring that the overall goals of the initiative are met effectively.
`;

export const UPLOAD_IMAGE_ERROR = (fieldName: string) =>
  `We encountered an issue while uploading your ${fieldName}.`;

export const INVITE_COMPOSE_FORM_DEFAULT_VALUES = (ecosystemName: string) => {
  return {
    title: `Welcome to “${ecosystemName}”!`,
    message: `Join us at ${ecosystemName} and connect your business to make a positive impact. Enable your employees to connect to our latest events and volunteering opportunities. If you are a company ready to lend a helping hand, we would love to connect, inspire, and empower you to help us. Together, we can create positive change!

Ready to get started? Join your company today!`,
  };
};

export const IMPORT_FILE_MESSAGES = {
  LOAD_IMPORTED_FILES_ERROR: 'Error on try to load imported files.',
  LOAD_IMPORT_FILE_ERROR: 'Error on try to load imported file.',
  UPLOAD_ERROR: 'An error occurred while trying to upload the file.',
  REFRESH_ERROR: 'An error occurred while trying to refresh the imports.',
  FILE_MUST_BE_TSV_ERROR: 'File must be a tsv file.',
  INPUTS_MUST_NOT_BE_EMPTY: 'Select one app and upload a TSV file.',
  PLACES_INPUTS_MUST_NOT_BE_EMPTY:
    'Select at least one activity definition import file and upload a TSV file.',
  RELATED_UPLOAD_NOT_COMPLETED:
    'Related Activity definition import has not been completed yet.',
};

export const FEATURED_ACTIVITY_MESSAGES = {
  CREATE_FAILURE: 'An error occurred while trying to define Featured Activity.',
  CREATE_SUCCESS: 'Featured Activity successfully created.',
};

export const ORGANIZATION_DEPARTMENT_MESSAGES = {
  CREATE_FAILURE: 'An error occurred while trying to save Department.',
  CREATE_SUCCESS: 'Department created.',
  UPDATE_SUCCESS: 'Department updated.',
  DELETE_SUCCESS: 'Department deleted.',
  DELETE_FAILURE: 'An error occurred while trying to delete Department.',
  DELETE_USERS_RELATED_WARNING:
    'There are employees associated with this group',
};
