import { AxiosResponse } from 'axios';
import { useMemo, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { ImageZoneImageItem } from 'ui/components/Dropzone/ImageZoneMini/types';
import { ITabsRefProps } from 'ui/components/Tabs/types';
import {
  EEventApplicationType,
  ESpaceOptionsKeys,
  LocationOptionsEnum,
} from 'ui/enums';
import { ActivityType } from 'ui/types/activities';
import { ValidationsRegex } from 'ui/utils/validations/validations';

import { DEFAULT_MEASUREMENT_UNIT_TIME_ID } from '~/config';
import { EVENT_MESSAGES } from '~/constants/messages.constants';
import { PAGES } from '~/constants/pages.constants';
import { ActivityTypeEnum, EventTypeEnum } from '~/enums';
import { useActivityCategoryOptions } from '~/hooks/useActivityCategoriesOptions';
import { useAppSelector } from '~/hooks/useAppSelector';
import { usePermissions } from '~/hooks/usePermissions';
import { useRouter } from '~/hooks/useRouter';
import ActivitiesService from '~/services/resources/activities';
import ActivityDefinitionService from '~/services/resources/activityDefinition';
import {
  IActivityCategoryDTO,
  IActivityDefinitionDTO,
  IAddressDTO,
  ICreateActivityDTO,
} from '~/types/dtos';
import { IActivity } from '~/types/interfaces/activity';
import {
  generateCarouselImagesURLsArrayForFiles,
  handleActivityCoverImage,
} from '~/utils/activitiesImages';
import { mountEventPayload } from '~/utils/activityCreationAndEdit';
import { transformHoursToSeconds } from '~/utils/functions';

import { EventFormTabsPages } from '../../types';

export const useEventFormController = () => {
  const tabsRef = useRef<ITabsRefProps>(null);
  // Fix for hiding the field when not having all event permissions. It was always sending a incorrect value.

  const [eventApplicationType, setEventApplicationType] = useState<
    EEventApplicationType | undefined
  >(EEventApplicationType.Individual);

  const [formData, setFormData] = useState<ICreateActivityDTO>({
    // Default Application type to avoid validation errors
    eventApplicationType,
    eventApprovalRequired: 'no',
    spaceOptions: ESpaceOptionsKeys.Indoor,
  } as ICreateActivityDTO);

  const { organizationSelectedId } = useAppSelector(({ auth }) => auth);
  const { selectedEcosystem } = useAppSelector(({ ecosystem }) => ecosystem);

  const { replaceRoute } = useRouter();
  const [currentTab, setCurrentTab] = useState(EventFormTabsPages.About);
  const [isLoading, setIsLoading] = useState(false);

  const { filterAppsForActivityCreation } = usePermissions();

  const location = useLocation();
  const navigate = useNavigate();

  const { control } = useForm<ICreateActivityDTO>({
    mode: 'onChange',
    reValidateMode: 'onBlur',
  });

  const {
    requirementOptions,
    causeOptions,
    requirementOptionsData,
    causeOptionsData,
  } = useActivityCategoryOptions();

  const isTeamEvent = useMemo(() => {
    return formData.eventApplicationType === EEventApplicationType.Team;
  }, [formData]);

  const filteredAppsByPermissions = filterAppsForActivityCreation(
    ActivityType.Event,
    true,
  );

  const handleCreateActivitiesForSingleLocation = async (
    data: ICreateActivityDTO,
    app: string,
    activityDefinitionData: any,
  ) => {
    const payloads: Promise<AxiosResponse<IActivity>>[] = [];
    if (data.dates) {
      for (const date of data.dates?.values()) {
        try {
          const address = data.address;

          if (date.externalApplyLink) {
            if (!ValidationsRegex.Url.test(encodeURI(date.externalApplyLink))) {
              toast.error(
                'External application link should be a valid full url! Only https is allowed. E.g: https://example.com',
              );
              throw Error();
            }
          }

          const activityData = {
            eventType: EventTypeEnum.National,
            startDate: date.startDate,
            endDate: date.endDate,
            dueDate: date.dueDate ? date.dueDate : date.endDate,
            address,
            volunteerNumber: date.volunteerNumber
              ? Number(date.volunteerNumber)
              : undefined,
            noVolunteerLimit: date.noVolunteerLimit,
            app,
            eventApplicationType: data.eventApplicationType,
            teamsNumber: date.teamsNumber,
            teamsMinSize: date.teamsMinSize,
            teamsMaxSize: date.teamsMaxSize,
            externalApplyLink: date.externalApplyLink || undefined,
          } as Partial<ICreateActivityDTO>;

          const normalizedPayloadForActivity = mountEventPayload({
            activityData,
            activityDefinitionId: activityDefinitionData._id as string,
            ecosystemId: selectedEcosystem?._id as string,
            organizationId: organizationSelectedId as string,
            isFromHome: false,
          });

          payloads.push(
            ActivitiesService.createActivityEvent(normalizedPayloadForActivity),
          );
        } catch (error) {
          toast.error(EVENT_MESSAGES.CREATE_EVENT_ERROR);
        }
      }
    } else {
      throw EVENT_MESSAGES.CREATE_EVENT_ERROR;
    }

    await Promise.all(payloads);
  };

  const handleCreateActivitiesForMultipleLocations = async (
    data: ICreateActivityDTO,
    app: string,
    activityDefinitionData: any,
  ) => {
    const payloads: Promise<AxiosResponse<IActivity>>[] = [];
    if (data.locationsGroups) {
      for (const [index, date] of data.locationsGroups?.entries()) {
        if (data.locationsByGroup && data.locationsByGroup[index]) {
          for (const locationData of data.locationsByGroup[index]?.values()) {
            if (!locationData.location) return;

            try {
              const address: IAddressDTO = {
                street: locationData.returnedAddress,
                rawLocation: locationData.returnedAddress,
                location: {
                  type: 'Point',
                  coordinates: [
                    locationData.location.lng,
                    locationData.location.lat,
                  ],
                },
              };

              if (date.externalApplyLink) {
                if (
                  !ValidationsRegex.Url.test(encodeURI(date.externalApplyLink))
                ) {
                  toast.error(
                    'External application link should be a valid full url! Only https is allowed. E.g: https://example.com',
                  );
                  throw Error();
                }
              }
              const activityData = {
                eventType: EventTypeEnum.National,
                startDate: date.startDate,
                endDate: date.endDate,
                dueDate: date.dueDate ? date.dueDate : date.endDate,
                address,
                volunteerNumber: date.volunteerNumber
                  ? Number(date.volunteerNumber)
                  : undefined,
                noVolunteerLimit: date.noVolunteerLimit,
                app,
                eventApplicationType: data.eventApplicationType,
                teamsNumber: date.teamsNumber,
                teamsMinSize: date.teamsMinSize,
                teamsMaxSize: date.teamsMaxSize,
                externalApplyLink: date.externalApplyLink || undefined,
              } as Partial<ICreateActivityDTO>;

              const normalizedPayloadForActivity = mountEventPayload({
                activityData,
                activityDefinitionId: activityDefinitionData._id as string,
                ecosystemId: selectedEcosystem?._id as string,
                organizationId: organizationSelectedId as string,
                isFromHome: false,
              });

              payloads.push(
                ActivitiesService.createActivityEvent(
                  normalizedPayloadForActivity,
                ),
              );
            } catch (error) {
              toast.error(EVENT_MESSAGES.CREATE_EVENT_ERROR);
            }
          }
        }
      }
    } else {
      throw EVENT_MESSAGES.CREATE_EVENT_ERROR;
    }

    await Promise.all(payloads);
  };

  const handleCreateActivitiesFromHome = async (
    data: ICreateActivityDTO,
    app: string,
    activityDefinitionData: IActivityDefinitionDTO,
  ) => {
    const payloads: Promise<AxiosResponse<IActivity>>[] = [];
    if (data.dates) {
      for (const date of data.dates?.values()) {
        try {
          const activityData = {
            eventType: EventTypeEnum.National,
            startDate: date.startDate,
            endDate: date.endDate,
            dueDate: date.dueDate ? date.dueDate : date.endDate,
            volunteerNumber: date.volunteerNumber
              ? Number(date.volunteerNumber)
              : undefined,
            noVolunteerLimit: date.noVolunteerLimit,
            app,
            eventApplicationType: data.eventApplicationType,
            teamsNumber: date.teamsNumber,
            teamsMinSize: date.teamsMinSize,
            teamsMaxSize: date.teamsMaxSize,
            regions: data.regions,
            externalApplyLink: date.externalApplyLink || undefined,
            meetingLink: data.meetingLink || undefined,
          } as Partial<ICreateActivityDTO>;

          const normalizedPayloadForActivity = mountEventPayload({
            activityData,
            activityDefinitionId: activityDefinitionData._id as string,
            ecosystemId: selectedEcosystem?._id as string,
            organizationId: organizationSelectedId as string,
            isFromHome: true,
          });

          payloads.push(
            ActivitiesService.createActivityEvent(normalizedPayloadForActivity),
          );
        } catch (error) {
          toast.error(EVENT_MESSAGES.CREATE_EVENT_ERROR);
        }
      }
    } else {
      throw EVENT_MESSAGES.CREATE_EVENT_ERROR;
    }

    await Promise.all(payloads);
  };

  const createEvent = async (data: ICreateActivityDTO) => {
    try {
      setIsLoading(true);
      const causeOptions = causeOptionsData?.data.filter(
        (cause: IActivityCategoryDTO) => data.cause?.includes(cause._id),
      );

      let requirementOptions: IActivityCategoryDTO[] | undefined;

      if (data.requirementOptions?.length) {
        requirementOptions = requirementOptionsData?.data?.filter(
          ({ _id }: { _id: string }) => {
            // @mycatdoitbetter TODO: Fix this, this is a really bad way to do this
            // the data.requirementOptions is a string[] and the _id is a string...
            const requirementOptions =
              data.requirementOptions as unknown as string[];

            return requirementOptions.find(
              (requirementOption) => requirementOption === _id,
            );
          },
        );
      }

      const { coverImageURL, thumbnailImageURL } =
        await handleActivityCoverImage(data.coverImage, data.thumbnailImage);

      const carouselImagesURLs = await generateCarouselImagesURLsArrayForFiles(
        data.carouselImages as ImageZoneImageItem[],
      );

      const app = Array.isArray(data.selectedApp) ? data.selectedApp[0] : '';
      if (!app) {
        throw EVENT_MESSAGES.CREATE_EVENT_ERROR;
      }

      const isApprovalRequired =
        data.eventApprovalRequired === 'yes' ? true : false;

      const activityDefinitionPayload: Partial<ICreateActivityDTO> = {
        title: data.title,
        description: data.description,
        // TODO: Remove this once it is not required anymore
        // All will be national for now.
        eventType: EventTypeEnum.National,
        coverImage: coverImageURL,
        carouselImages: carouselImagesURLs,
        app: app as string,
        ecosystem: selectedEcosystem?._id,
        volunteerRewards: data.volunteerRewards,
        volunteerRequirements: data.volunteerRequirements,
        type: ActivityTypeEnum.Event,
        organization: organizationSelectedId,
        spaceOptions: data.spaceOptions,
        typeOfWork: data.typeOfWork,
        causeOptions,
        // FIXME: please.
        requirementOptions: requirementOptions,
        meetingLink: data.meetingLink || undefined,
        publishedApps: data.publishedApps,
        eventApplicationType: data.eventApplicationType,
        isApprovalRequired,
        measurementUnit: DEFAULT_MEASUREMENT_UNIT_TIME_ID,
        measurementUnitPluralLabel: 'Hours',
        targetAmount: 0,
        locationOption: data.locationOption,
        thumbnailImage: thumbnailImageURL,
      };

      if (data.targetAmount) {
        activityDefinitionPayload['targetAmount'] = transformHoursToSeconds(
          data.targetAmount as number,
        );
        activityDefinitionPayload['measurementUnit'] =
          DEFAULT_MEASUREMENT_UNIT_TIME_ID;
      }
      if (
        data.locationOption === LocationOptionsEnum.FromHome &&
        !!data.meetingLink
      ) {
        if (!ValidationsRegex.Url.test(encodeURI(data.meetingLink))) {
          toast.error(
            'Meeting link should be a valid full url! Only https is allowed. E.g: https://example.com',
          );
          throw Error();
        }
      }
      if (!!data.externalApplyLink) {
        if (!ValidationsRegex.Url.test(encodeURI(data.externalApplyLink))) {
          toast.error(
            'External application link should be a valid full url! Only https is allowed. E.g: https://example.com',
          );
          throw Error();
        }
      }

      const { data: activityDefinitionData } =
        await ActivityDefinitionService.createEventActivityDefinition({
          ...activityDefinitionPayload,
        });

      const locationOption = data.locationOption;

      switch (locationOption) {
        case LocationOptionsEnum.SingleLocation: {
          await handleCreateActivitiesForSingleLocation(
            data,
            app,
            activityDefinitionData,
          );
          break;
        }
        case LocationOptionsEnum.MultipleLocations: {
          await handleCreateActivitiesForMultipleLocations(
            data,
            app,
            activityDefinitionData,
          );
          break;
        }
        case LocationOptionsEnum.FromHome: {
          await handleCreateActivitiesFromHome(
            data,
            app,
            activityDefinitionData,
          );
          break;
        }
      }

      replaceRoute(`${PAGES.ACTIVITIES}`);
      setTimeout(() => {
        replaceRoute(`${PAGES.ADD_EVENT_SUCCESS}`, {
          state: {
            locationForActivitySuccessful: location,
            activity: activityDefinitionData,
          },
        });
      }, 500);
    } catch (error) {
      toast.error(EVENT_MESSAGES.CREATE_EVENT_ERROR);
    } finally {
      setIsLoading(false);
    }
  };

  const handleContinue = (data: ICreateActivityDTO) => {
    setFormData((prev: ICreateActivityDTO) => ({
      ...prev,
      ...data,
      eventApplicationType: data.eventApplicationType || eventApplicationType,
    }));

    if (tabsRef.current?.currentTabIndex !== 2) {
      tabsRef.current?.nextStep();
      setCurrentTab((prev) => prev + 1);
    } else {
      createEvent({ ...formData, ...data });
    }
  };

  const handleBack = () => {
    if (
      tabsRef.current?.currentTabIndex &&
      tabsRef.current?.currentTabIndex > 0
    ) {
      tabsRef.current?.backStep();
      setCurrentTab((prev) => prev - 1);
    } else {
      navigate(-1);
    }
  };

  const handleUpdateEventApplication = (
    eventApplicationType?: EEventApplicationType | undefined,
  ) => {
    setEventApplicationType(eventApplicationType);
  };

  const handleUpdateLocationOption = (locationOption: LocationOptionsEnum) => {
    setFormData((prev: ICreateActivityDTO) => ({ ...prev, locationOption }));
  };

  const currentLocationOption = formData.locationOption;
  return {
    tabsRef,
    currentTab,
    causeOptions,
    requirementOptions,
    handleContinue,
    handleBack,
    isLoading,
    formData,
    control,
    isTeamEvent,
    filteredAppsByPermissions,
    handleUpdateEventApplication,
    handleUpdateLocationOption,
    currentLocationOption,
  };
};
