import axios from 'axios';

import { EImportFileType } from '~/pages/Authenticated/BulkUploads/types';
import { adminUploadsApi } from '~/services/adminUploadsApi';
import { api } from '~/services/api';
import {
  QueryParams,
  SignedURLResponse,
  UploadsGenerateURLProps,
  UploadsUploadFileToS3Props,
  UploadURLFields,
} from '~/services/resources/types';
import { uploadsApi } from '~/services/uploadsApi';

export class ImportFilesServices {
  static async createImportFile(params: {
    ecosystemId: string;
    organizationId: string;
    app: string;
    publishedApps: string[];
    importFileType: EImportFileType;
    fileName: string;
    activityDefinitionImportFile?: string;
    isLegacy: boolean;
  }) {
    try {
      const timezoneOffset = new Date().getTimezoneOffset();
      const response = await api.post('/import-file', {
        app: params.app,
        publishedApps: params.publishedApps,
        ecosystem: params.ecosystemId,
        organization: params.organizationId,
        importFileType: params.importFileType,
        activityDefinitionImportFile: params.activityDefinitionImportFile,
        key: params.fileName,
        timezoneOffset,
        isLegacy: params.isLegacy,
      });
      return response.data;
    } catch (error: any) {
      if (
        error?.response?.status == 403 &&
        error?.response?.data?.error === 'RELATED_UPLOAD_NOT_COMPLETED'
      ) {
        throw new Error('RELATED_UPLOAD_NOT_COMPLETED');
      }

      throw error;
    }
  }
  static async getImportedFiles(params: QueryParams) {
    const response = await api.get(`/import-file`, {
      params: params,
    });

    return response.data;
  }

  static async getImports(fileImportId: string, params: QueryParams) {
    const response = await api.get(`/import-file/${fileImportId}/imports`, {
      params: params,
    });

    return response.data;
  }

  static async generatePreSignedAdminURL({
    Target,
    FileExtension,
    ImportFileId,
  }: UploadsGenerateURLProps) {
    const response = await adminUploadsApi.get<SignedURLResponse>(
      '/admin-upload',
      {
        params: {
          Target,
          FileExtension,
          ImportFileId,
        },
      },
    );
    return response.data;
  }

  static async generatePreSignedURL({
    Target,
    FileExtension,
  }: UploadsGenerateURLProps) {
    const response = await uploadsApi.get<SignedURLResponse>('', {
      params: {
        Target,
        FileExtension,
      },
    });
    return response.data;
  }

  static async uploadFileToS3({ signedURL, file }: UploadsUploadFileToS3Props) {
    const url = signedURL.uploadURL.url;
    const formData = this.buildFormData(signedURL, file);

    return await axios({
      method: 'POST',
      url,
      data: formData,
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  }

  static buildFormData(signedURL: SignedURLResponse, file: File) {
    const formData = new FormData();
    const fields = signedURL.uploadURL.fields;
    for (const key in fields) {
      formData.append(key, fields[key as keyof UploadURLFields]);
    }
    formData.append('file', file);
    return formData;
  }
}
