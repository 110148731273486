import { Avatar, HStack, Text } from 'native-base';

import { colors } from '~/theme/colors';

import { TeamMemberProps } from './types';

export const TeamMember = ({ name, avatarImage }: TeamMemberProps) => {
  return (
    <HStack space={2} alignItems="center">
      <Avatar
        source={{
          uri: avatarImage,
        }}
        height="40px"
        width="40px"
      >
        {String(name).charAt(0)}
      </Avatar>
      <Text color={colors.singletons.darkText} numberOfLines={2}>
        {name}
      </Text>
    </HStack>
  );
};
