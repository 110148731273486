import { useQuery } from '~/hooks/useQuery';
import { ActivityDefinitionAppsEditingConditionResponse } from '~/types/interfaces/activity-definition/editable-attributes-permission';

export const useLoadActivityDefinitionAppsEditingCondition = (id?: string) => {
  const { data, isLoading, isFetched } =
    useQuery<ActivityDefinitionAppsEditingConditionResponse>(
      `org-admin/activity-definition/${id}/apps-editing-condition`,
      {
        key: `org-admin-activity-definition-apps-editing-condition-${id}`,
        queryOptions: { enabled: !!id },
      },
    );
  return { data: data || null, isLoading, isFetched };
};
