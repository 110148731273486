import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  IAppPermissionGroups,
  IAppPermissionGroupsAppPopulate,
  IPermission,
} from 'ui/types/interfaces/permission';

import { PermissionsState } from '~/store/slices/permissions/types';
import { ESliceNames } from '~/store/types';

const initialState: PermissionsState = {
  partnerPermissionsGroupsByAppId: {},
  allPartnerPermissionsFromAvailableApps: {},
};

export const permissionsSlice = createSlice({
  name: ESliceNames.PERMISSIONS,
  initialState,
  reducers: {
    setUserPermissionsGroupsByAppId: (
      state,
      {
        payload,
      }: PayloadAction<Record<string, IAppPermissionGroupsAppPopulate[]>>,
    ) => {
      return { ...state, partnerPermissionsGroupsByAppId: payload };
    },

    setAvailableActivityPermissionsFromApps: (
      state,
      {
        payload,
      }: PayloadAction<{
        ecosystemId: string;
        permissions: Partial<IPermission>[];
      }>,
    ) => {
      return {
        ...state,
        allPartnerPermissionsFromAvailableApps: {
          ...state.allPartnerPermissionsFromAvailableApps,
          [payload.ecosystemId]: payload.permissions,
        },
      };
    },
  },
});

export const {
  reducer: permissionsSliceReducer,
  actions: permissionsSliceActions,
} = permissionsSlice;
