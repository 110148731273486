import Yup from '~/utils/validations/yup';

export const schema = Yup.object({
  termsOfServiceAccepted: Yup.boolean().oneOf(
    [true],
    'You must accept the terms of service',
  ),
});

export const defaultValues = {
  termsOfServiceAccepted: false,
};
