import { HStack, Text } from 'native-base';
import Button from 'ui/components/Button';
import Icon from 'ui/components/Icon';
import { Tooltip } from 'ui/components/Tooltip';
import { LocationDataStatus } from 'ui/enums';

import { LocationTagProps } from './types';
import { useLocationTagController } from './useLocationTagController';

export const LocationTag = ({ text, status, onRemove }: LocationTagProps) => {
  const { tagStyleByStatus } = useLocationTagController();
  return (
    <Tooltip
      label="Address/Postcode not found"
      isDisabled={status === LocationDataStatus.OK}
    >
      <HStack
        px={4}
        rounded="3xl"
        alignItems="center"
        bg={tagStyleByStatus[status].bg}
        space={2}
        my={2}
      >
        <Text
          fontSize="xs"
          fontWeight={500}
          color={tagStyleByStatus[status].color}
        >
          {text}
        </Text>
        <Button
          variant="ghost"
          maxW="20px"
          w="20px"
          minW="20px"
          onPress={onRemove}
        >
          <Icon icon="x" size={16} color={tagStyleByStatus[status].icon} />
        </Button>
      </HStack>
    </Tooltip>
  );
};
