import { Stack, Text } from 'native-base';
import Modal from 'ui/components/Modals/Modal';

import { AdminInvitesControllerAttributes } from '~/pages/Authenticated/Settings/AdminsSettings/types';

export const ResendInviteModal = ({
  modalResendRef,
  onResendInvite,
  closeResendInviteModal,
  isResendingOrDeleting,
}: Pick<
  AdminInvitesControllerAttributes,
  | 'modalResendRef'
  | 'onResendInvite'
  | 'closeResendInviteModal'
  | 'isResendingOrDeleting'
>) => (
  <Modal
    ref={modalResendRef}
    rightBtnText="Resend Invite"
    leftBtnText="Cancel"
    rightBtnAction={onResendInvite}
    leftBtnAction={closeResendInviteModal}
    rightBtnLoading={isResendingOrDeleting}
    onClose={closeResendInviteModal}
    size="lg"
  >
    <Stack space="5">
      <Text fontWeight={500} fontSize="lg">
        Resend invitation
      </Text>
      <Text>Do you want to resend the invitation?</Text>
    </Stack>
  </Modal>
);
