import { Box, HStack, Text, VStack } from 'native-base';
import { formatDate } from 'ui/utils/formatter';

export const DateDivider = ({ date }: { date: Date }) => {
  const dateStr = formatDate(new Date(date), 'DD MMM, YYYY');
  return (
    <HStack
      mb={4}
      w="full"
      alignItems={'center'}
      justifyContent={'center'}
      position={'relative'}
    >
      <VStack
        px={3}
        py={1}
        borderWidth={1}
        borderColor={'gray.300'}
        borderRadius={100}
        alignItems={'center'}
        justifyContent={'center'}
        backgroundColor={'gray.100'}
        zIndex={9}
      >
        <Text color="gray.600" fontSize="xs" numberOfLines={1} fontWeight={500}>
          {dateStr}
        </Text>
      </VStack>

      <Box
        position={'absolute'}
        top={0}
        left={0}
        bottom={0}
        margin={'auto'}
        w={'full'}
        height={'1px'}
        backgroundColor={'gray.300'}
        zIndex={8}
      />
    </HStack>
  );
};
