import { useEffect, useRef, useState } from 'react';
import { IModalRefProps } from 'ui/components/Modals/Modal/types';

import { PAGES } from '~/constants/pages.constants';
import { useAppSelector } from '~/hooks/useAppSelector';
import { useQuery } from '~/hooks/useQuery';
import { useRouter } from '~/hooks/useRouter';
import { IEcosystem } from '~/types/interfaces/ecosystem';
import { IOrganization } from '~/types/interfaces/organization';

import { App } from '../../../../types/interfaces/app';

export const useEcosystemsController = () => {
  const modalRef = useRef<IModalRefProps>(null);
  const beforeCreatingEcosystemModalRef = useRef<IModalRefProps>(null);

  const { goToRoute } = useRouter();
  const { organizationSelectedId } = useAppSelector(({ auth }) => auth);

  const { data: organization } = useQuery<IOrganization>(
    `/organization/${organizationSelectedId}`,
  );

  const { data: ecosystems, isLoading: isLoadingEcosystems } = useQuery<
    IEcosystem[]
  >(`/ecosystem/organizations/${organizationSelectedId}`);

  const { data: apps, isLoading: isLoadingApps } = useQuery<{ data: App[] }>(
    `/app`,
    {
      requestOptions: {
        params: {
          filter: JSON.stringify({
            organization: organizationSelectedId,
          }),
        },
      },
      // queryOptions: {
      //   enabled: !isLoadingEcosystems,
      // },
    },
  );
  const [ecosystemApps, setEcosystemApps] = useState<Record<string, App[]>>({});

  useEffect(() => {
    if (apps && apps?.data?.length > 0) {
      const appsObj: Record<string, App[]> = {};
      for (const app of apps.data) {
        const ecosystem = app.ecosystem as string;
        if (!appsObj[ecosystem]) {
          appsObj[ecosystem] = [app];
        } else {
          appsObj[ecosystem].push(app);
        }
      }
      setEcosystemApps(appsObj);
    }
  }, [apps]);

  const handleNewEcosystem = () => {
    goToRoute(PAGES.CREATE_ECOSYSTEM);
  };

  const handleLeftButtonAction = () => modalRef.current?.close();

  const handleRightButtonAction = () => {
    if (ecosystems?.length) {
      goToRoute(`${PAGES.EDIT_ECOSYSTEM}/${ecosystems[0]._id}`);
    }
  };

  const handleBeforeCreatingEcosystemModalRightBtnAction = () => {
    beforeCreatingEcosystemModalRef.current?.close();
  };

  const handleGoToEcosystemDetails = (ecosystemId: string) => {
    goToRoute(`/settings/ecosystems/${ecosystemId}`);
  };

  return {
    ecosystems,
    modalRef,
    organization,
    isLoadingEcosystems,
    beforeCreatingEcosystemModalRef,
    handleNewEcosystem,
    handleLeftButtonAction,
    handleRightButtonAction,
    handleBeforeCreatingEcosystemModalRightBtnAction,
    handleGoToEcosystemDetails,
    ecosystemApps,
  };
};
