import {
  QueryObserverResult,
  RefetchOptions,
  RefetchQueryFilters,
} from 'react-query';
import { IModalRefProps } from 'ui/components/Modals/Modal/types';

import { IPaginatedResponse } from '~/types';
import { AdminProfile } from '~/types/interfaces/admin-profile';
import { InviteCode } from '~/types/interfaces/inviteCode';

export enum AdminsTabs {
  ADMINS = 0,
  PENDING = 1,
}

export interface AdminProfilesControllerAttributes {
  areProfilesLoading: boolean;
  loadAdminProfiles: (
    skip: number,
    limit: number,
  ) => Promise<{
    data: AdminProfile[];
    count: number;
  }>;
  refetchProfiles: <TPageData>(
    options?: (RefetchOptions & RefetchQueryFilters<TPageData>) | undefined,
  ) => Promise<
    QueryObserverResult<IPaginatedResponse<AdminProfile[]>, unknown>
  >;
  selectedAdmin: {
    id: string;
  } | null;
  deleteAdminModalRef: React.RefObject<IModalRefProps>;
  openDeleteAdminModal: (adminId: string) => void;
  closeDeleteAdminModal: () => void;
  isDeletingAdmin: boolean;
  onDeleteAdmin: () => void;
}

export interface AdminInvitesControllerAttributes {
  refetchInvites: <TPageData>(
    options?: (RefetchOptions & RefetchQueryFilters<TPageData>) | undefined,
  ) => Promise<QueryObserverResult<IPaginatedResponse<InviteCode[]>, unknown>>;
  invite: {
    id: string;
  } | null;
  modalResendRef: React.RefObject<IModalRefProps>;
  openResendInviteModal: (inviteId: string) => void;
  closeResendInviteModal: () => void;
  modalDeleteRef: React.RefObject<IModalRefProps>;
  openDeleteInviteModal: (inviteId: string) => void;
  closeDeleteInviteModal: () => void;
  isResendingOrDeleting: boolean;
  onResendInvite: () => void;
  onDeleteInvite: () => void;
  loadAdminInvites: (
    skip: number,
    limit: number,
  ) => Promise<{ data: InviteCode[]; count: number }>;
  areInvitesLoading: boolean;
}

export interface InviteNewAdminControllerAttributes {
  modalInviteNewAdminRef: React.RefObject<IModalRefProps>;
  isSendingNewAdminInvites: boolean;
  openInviteNewAdminModal: () => void;
  closeInviteNewAdminModal: () => void;
  onSendNewAdminInvites: () => void;
  adminEmailsList: string[];
  addAdminEmail: (email: string) => void;
  removeAdminEmail: (email: string) => void;
  adminEmailInput: string;
  setAdminEmailInput: (value: string) => void;
  onChangeAdminEmailInput: (value: string) => void;
}
