import { useCallback, useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';

import { useAppSelector } from '~/hooks/useAppSelector';
import {
  InsightFilters,
  InsightFiltersSearchParams,
} from '~/pages/Authenticated/Insights/utils';

export type InsightPageFilters = ReturnType<
  typeof useInsightFilters
>['filters'];

export const useInsightFilters = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const { selectedEcosystemId, selectedOrganizationId } = useAppSelector(
    ({ ecosystem }) => ({
      selectedEcosystemId: ecosystem.selectedEcosystem!._id,
      selectedOrganizationId: ecosystem.selectedEcosystem!.organization,
    }),
  );

  const setInsightFilters = useCallback(
    (
      cb: (
        prevState: InsightFiltersSearchParams,
      ) => Partial<InsightFiltersSearchParams>,
    ) => {
      setSearchParams((_prevState) => {
        const prevState = InsightFilters.get(_prevState) || {};
        const newState = Object.assign(prevState, cb(prevState));
        InsightFilters.set(_prevState, newState);
        return _prevState;
      });
    },
    [setSearchParams],
  );

  const { filters, hasFilterSetted } = useMemo(() => {
    const filters = InsightFilters.get(searchParams);

    return {
      filters: {
        ...filters,
        selectedEcosystemId,
        selectedOrganizationId,
      },
      hasFilterSetted:
        filters.apps.length > 0 && !!filters.from && !!filters.to,
    };
  }, [searchParams, selectedEcosystemId, selectedOrganizationId]);

  return {
    filters,
    setInsightFilters,
    hasFilterSetted,
  };
};
