import { ResponsiveValue } from 'native-base/lib/typescript/components/types';

import { FlexDirection } from '~/types/interfaces/styles';

export const styles = {
  container: { sm: 'column', md: 'row' } as ResponsiveValue<FlexDirection>,
  wrapper: { sm: '100%', md: '40%' },
  backgroundContainerW: { sm: '100%', md: '60%' },
  backgroundContainerPaddingX: { base: 8, md: '150px' },
  backgroundContainerPaddingY: { base: 6, md: 0 },
};
