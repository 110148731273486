import { yupResolver } from '@hookform/resolvers/yup';
import { Auth } from 'aws-amplify';
import { useBreakpointValue } from 'native-base';
import { useMemo, useRef } from 'react';
import { useForm } from 'react-hook-form';
import {
  NativeSyntheticEvent,
  TextInputKeyPressEventData,
} from 'react-native/types';
import { toast } from 'react-toastify';
import { IModalRefProps } from 'ui/components/Modals/Modal/types';
import Yup from 'ui/utils/validations/yup';

import { COGNITO_ERRORS_CODE } from '~/constants/error.constants';
import { COMMON_MESSAGES } from '~/constants/messages.constants';
import { PAGES } from '~/constants/pages.constants';
import { useAppDispatch } from '~/hooks/useAppDispatch';
import { useLoadOrganizationPublic } from '~/hooks/useLoadingOrganizationPublic';
import { useLoadPartnerInviteCodePublic } from '~/hooks/useLoadPartnerInviteCodePublic';
import { useRouter } from '~/hooks/useRouter';
import { usePartnerInviteFromUrl } from '~/pages/Common/JoinAsPartner/hooks/usePartnerInviteFromUrl';
import AuthService from '~/services/resources/auth';
import { OrganizationService } from '~/services/resources/organization';
import UserService from '~/services/resources/user';
import { authSliceActions } from '~/store/slices/auth';
import { IAuthDTO } from '~/types/dtos';

const schemaValidation = Yup.object({
  email: Yup.string()
    .transform((value: string) => value?.trim() || '')
    .email()
    .required(),
  password: Yup.string().password().required(),
});

export const useJoinAsPartnerSignInController = () => {
  const isMobile = useBreakpointValue({ base: true, md: false });

  const modalTermsOfServiceRef = useRef<IModalRefProps>(null);
  const modalPrivacyPolicyRef = useRef<IModalRefProps>(null);

  const dispatch = useAppDispatch();

  const { code, inviteURL } = usePartnerInviteFromUrl();

  const { goToRoute, location } = useRouter();

  const {
    control,
    handleSubmit,
    formState: { errors, isValid, isDirty, isSubmitting },
  } = useForm<IAuthDTO>({
    resolver: yupResolver(schemaValidation),
    mode: 'onChange',
    defaultValues: {
      email: '',
      password: '',
    },
  });

  const { data: inviteCode, isLoading: isLoadingInviteCode } =
    useLoadPartnerInviteCodePublic(code);

  const { data: organization, isLoading: isLoadingOrganization } =
    useLoadOrganizationPublic(inviteCode?.sharerOrganization || '');

  const onSignUpPress = () => {
    goToRoute(location.pathname.replace('sign-in', 'signup'));
  };

  const goToDoitSignIn = () => {
    goToRoute('/');
  };

  const onSubmit = handleSubmit(async (data) => {
    try {
      const response = await AuthService.signInWithEmailAndPassword({
        email: data.email,
        password: data.password.trim(),
      });

      const { data: responseMeData } = await UserService.me();
      const userId = responseMeData._id;

      const { data: organizationData } = await OrganizationService.findAll({
        filter: JSON.stringify({ admins: { $in: [responseMeData._id] } }),
      });

      dispatch(authSliceActions.signIn(response));

      if (organizationData?.data.length) {
        const userData = {
          _id: userId,
          organizations: organizationData.data,
          hasOrganization: true,
          isAuthenticated: true,
          organizationSelectedId: organizationData.data[0]._id,
        };
        dispatch(authSliceActions.update(userData));
      } else {
        dispatch(
          authSliceActions.update({ _id: userId, isAuthenticated: true }),
        );
      }
      goToRoute(inviteURL);
    } catch (error) {
      if (error instanceof Error) {
        if (error?.code === COGNITO_ERRORS_CODE.USER_NOT_CONFIRMED_EXCEPTION) {
          await Auth.resendSignUp(data.email);
          goToRoute(PAGES.SIGN_UP_EMAIL_CONFIRMATION, {
            state: { email: data.email, password: data.password.trim() },
          });
          return;
        }
        toast.error(error.message);
      } else {
        toast.error(COMMON_MESSAGES.UNKNOWN_ERROR);
      }
    }
  });

  const onKeyDown = ({
    nativeEvent,
  }: NativeSyntheticEvent<TextInputKeyPressEventData>): void => {
    if (nativeEvent.key === 'Enter') {
      onSubmit();
    }
  };

  const onForgotPasswordPress = () => {
    goToRoute(PAGES.FORGOT_PASSWORD);
  };

  const isLoading = isLoadingInviteCode || isLoadingOrganization;

  const canSignIn = useMemo(() => !isValid || !isDirty, [isValid, isDirty]);

  return {
    inviteCode,
    isLoading,
    modalTermsOfServiceRef,
    modalPrivacyPolicyRef,
    control,
    canSignIn,
    isSubmitting,
    errors,
    organization,
    isMobile,
    onForgotPasswordPress,
    onSubmit,
    onSignUpPress,
    onKeyDown,
    goToDoitSignIn,
  };
};
