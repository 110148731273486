import { PAGES } from '~/constants/pages.constants';
import { useRouter } from '~/hooks/useRouter';

export const useRedirectToViewDetailsPage = () => {
  const { goToRoute } = useRouter();

  const handleViewTeamApplication = (applicationId: string) => {
    goToRoute(
      PAGES.VIEW_TEAM_ACTIVITY_APPLICATION.replace(':id', applicationId),
    );
  };

  const handleViewMemberApplication = (userId: string) => {
    goToRoute(`${PAGES.MEMBERS}/${userId}`);
  };

  return { handleViewMemberApplication, handleViewTeamApplication };
};
