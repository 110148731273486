export const transformURL = (url: string) => {
  if (!url.startsWith('http://') && !url.startsWith('https://')) {
    return `https://${url}`;
  }
  return url;
};

export function sanitizeUrl(url: string): string {
  return url.replace(/\/$/, '');
}
