import { Stack, Text, VStack } from 'native-base';
import { useNavigate } from 'react-router-dom';
import Button from 'ui/components/Button';
import Illustration, { ILLUSTRATIONS_NAME } from 'ui/components/Illustration';
import { colors } from 'ui/theme/colors';

import { PAGES } from '~/constants/pages.constants';
import { TabsPages as MembersTabPages } from '~/pages/Authenticated/Members/types';

export type InsightsGenericEmptyStateProps = {
  text?: string;
  showActionButton?: boolean;
};

export const InsightsGenericEmptyState = ({
  text = 'Seems like there is no data\nto show this chart yet.',
  showActionButton = false,
}: InsightsGenericEmptyStateProps) => {
  const navigate = useNavigate();

  const handleActionButton = () => {
    navigate(`${PAGES.MEMBERS}?tab=${MembersTabPages.PendingInvites}`);
  };
  return (
    <VStack
      w="full"
      h="full"
      space={8}
      margin={'auto'}
      minH={'240px'}
      alignItems={'center'}
      justifyContent={'center'}
    >
      <Stack space={3} alignItems={'center'} flexDirection={'row'}>
        <Illustration
          name={ILLUSTRATIONS_NAME.NO_SIGNAL_CONNECTION_ERROR_BLACK}
          width={{ base: '64px', md: '72px' }}
          height={{ base: '64px', md: '72px' }}
        />
        <Text
          textAlign={'center'}
          color={'gray.500'}
          fontSize={'sm'}
          fontWeight={400}
          maxW={'280px'}
          pt={4}
          pb={6}
        >
          {text}
        </Text>
      </Stack>

      {showActionButton && (
        <Button
          leftIconSize={24}
          leftIconName={'plus-circle'}
          leftIconColor={colors.primary[400]}
          onPress={handleActionButton}
          backgroundColor={'primary.50'}
          py={'21px'}
          w="fit-content"
        >
          <Text
            numberOfLines={1}
            fontSize={{ base: 'sm', md: 'md' }}
            fontWeight="500"
            color={colors.primary[400]}
          >
            Invite employees
          </Text>
        </Button>
      )}
    </VStack>
  );
};
