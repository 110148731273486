import { useAppSelector } from '~/hooks/useAppSelector';

export const useAccessFormSectionController = () => {
  const { ecosystemsToShare } = useAppSelector(({ addMember }) => addMember);

  const isEcosystemDisabled = (ecosystemId: string) => {
    return !!ecosystemsToShare?.some((e) => e.ecosystemId !== ecosystemId);
  };

  return { isEcosystemDisabled };
};
