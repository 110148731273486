import { useCallback } from 'react';
import { IFilterValue } from 'ui/components/DataTable/types';

import { PAGES } from '~/constants/pages.constants';
import { AccountStatusEnum, MemberTypeEnum } from '~/enums';
import { useRouter } from '~/hooks/useRouter';

import { ChildOrgMembersTableDataPromise } from './types';

export const useChildOrgMembersController = () => {
  const { goToRoute } = useRouter();

  const handleRemoveChildOrgMember = (memberId: string) => {
    console.log('remove child org');
  };

  const handleResendInvitation = (memberId: string) => {
    console.log('resend invitation');
  };

  const handleViewChieldOrgMember = (memberId: string) => {
    goToRoute(`${PAGES.MEMBERS}/${memberId}`);
  };

  const loadChildOrgMembers = useCallback(
    async (
      skip: number,
      limit: number,
      filter: IFilterValue | null,
    ): Promise<ChildOrgMembersTableDataPromise> => {
      return {
        data: [
          {
            member: {
              id: '1',
              displayName: 'Mia Murphy',
              memberStatus: AccountStatusEnum.ACTIVE,
              email: 'miamurphy@farshare.com',
              avatar:
                'https://www.creativefabrica.com/wp-content/uploads/2023/01/30/Bearded-Man-Avatar-Icon-Graphics-59392089-1.jpg',
            },
            type: MemberTypeEnum.ChildOrg,
            organization: {
              name: 'Fareshare North East',
              avatar:
                'https://yt3.googleusercontent.com/ytc/AOPolaRl8KZee4hiiF8na9PQjnM5m6WZnEXQ3bwtR-28=s900-c-k-c0x00ffffff-no-rj',
            },
            ecosystem: 'Fareshare for Partners',
          },
          {
            member: {
              id: '1',
              displayName: 'Amina Milevski',
              memberStatus: AccountStatusEnum.ACTIVE,
              email: 'aminamilevski@farshare.com',
              avatar:
                'https://www.creativefabrica.com/wp-content/uploads/2023/01/30/Bearded-Man-Avatar-Icon-Graphics-59392089-1.jpg',
            },
            type: MemberTypeEnum.ChildOrg,
            organization: {
              name: 'Fareshare North East',
              avatar:
                'https://yt3.googleusercontent.com/ytc/AOPolaRl8KZee4hiiF8na9PQjnM5m6WZnEXQ3bwtR-28=s900-c-k-c0x00ffffff-no-rj',
            },
            ecosystem: 'Fareshare for Partners',
          },
          {
            member: {
              id: '1',
              displayName: 'Zahra Rashid',
              memberStatus: AccountStatusEnum.ACTIVE,
              email: 'zahrarashid@farshare.com',
              avatar:
                'https://www.creativefabrica.com/wp-content/uploads/2023/01/30/Bearded-Man-Avatar-Icon-Graphics-59392089-1.jpg',
            },
            type: MemberTypeEnum.ChildOrg,
            organization: {
              name: 'Fareshare North East',
              avatar:
                'https://yt3.googleusercontent.com/ytc/AOPolaRl8KZee4hiiF8na9PQjnM5m6WZnEXQ3bwtR-28=s900-c-k-c0x00ffffff-no-rj',
            },
            ecosystem: 'Fareshare for Members',
          },
          {
            member: {
              id: '1',

              displayName: 'Anna Bērziņš',
              memberStatus: AccountStatusEnum.ACTIVE,
              email: 'annaberzins@farshare.com',
              avatar:
                'https://www.creativefabrica.com/wp-content/uploads/2023/01/30/Bearded-Man-Avatar-Icon-Graphics-59392089-1.jpg',
            },
            type: MemberTypeEnum.ChildOrg,
            organization: {
              name: 'Fareshare North East',
              avatar:
                'https://yt3.googleusercontent.com/ytc/AOPolaRl8KZee4hiiF8na9PQjnM5m6WZnEXQ3bwtR-28=s900-c-k-c0x00ffffff-no-rj',
            },
            ecosystem: 'Fareshare for Members',
          },
          {
            member: {
              id: '1',
              displayName: 'Leo Korhonne',
              memberStatus: AccountStatusEnum.PENDING,
              email: 'leo.korhonne@gmail.com',
              avatar:
                'https://www.creativefabrica.com/wp-content/uploads/2023/01/30/Bearded-Man-Avatar-Icon-Graphics-59392089-1.jpg',
            },
            type: MemberTypeEnum.ChildOrg,
            organization: {
              name: 'Fareshare North East',
              avatar:
                'https://yt3.googleusercontent.com/ytc/AOPolaRl8KZee4hiiF8na9PQjnM5m6WZnEXQ3bwtR-28=s900-c-k-c0x00ffffff-no-rj',
            },
            ecosystem: 'Fareshare for Partners',
          },
        ],
        count: 1,
      };
    },
    [],
  );

  return {
    handleResendInvitation,
    handleRemoveChildOrgMember,
    handleViewChieldOrgMember,
    loadChildOrgMembers,
  };
};
