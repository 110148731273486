/* eslint-disable @typescript-eslint/no-explicit-any */
export const styleConfig: any = {
  direction: { base: 'column', md: 'row' },
  buttonWidth: { base: '100%', md: '50%' },
  logoContainer: { base: '100%', md: '1/4' },
  inputsContainer: { base: '100%', md: '70%' },
  inputsMargin: { base: 2, md: 0 },
  inputsSpace: { base: 4 },
  buttonMargin: { base: 2, md: 0 },
  optionalDirection: { base: 'column', md: 'row' },
  optionalWidth: { base: '100%', md: '50%' },
  desktopBackButtonDisplay: { base: 'none', md: 'flex' },
  mobileBackButtonDisplay: { base: 'flex', md: 'none' },
};
