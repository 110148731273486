import { LogsSummaryTile } from 'ui/components/v2/Tiles/LogsSummaryTile';

import { NotificationCard } from '../../BaseNotificationCard';
import { useMultipleMeasurementsCreatedNotificationCardController } from './controller';
import { MultipleMeasurementsCreatedNotificationCardProps } from './types';

export const MultipleMeasurementsCreatedNotificationCard = (
  props: MultipleMeasurementsCreatedNotificationCardProps,
) => {
  const {
    activityOrganizationName,
    activityAppName,
    activityTitle,
    measurementSummaries,
  } = props;
  const { titleNodes, date, profilePictureUrl } =
    useMultipleMeasurementsCreatedNotificationCardController(props);

  return (
    <NotificationCard
      authorImageUrl={profilePictureUrl}
      titleNodes={titleNodes}
      date={date}
    >
      <LogsSummaryTile
        appName={activityAppName}
        activityTitle={activityTitle}
        organizationName={activityOrganizationName}
        measurementSummaries={measurementSummaries}
      />
    </NotificationCard>
  );
};
