import { Box, FormControl, HStack, Image, Stack, Text } from 'native-base';
import React from 'react';
import Dropzone from 'react-dropzone';
import Button from 'ui/components/Button';
import FormError from 'ui/components/Form/FormError';
import Icon from 'ui/components/Icon';
import { colors } from 'ui/theme/colors';

import useDropzoneController from '../../Dropzone/controller';
import { CustomFile } from '../types';
import { ImageZoneProps } from './types';

const Thumb = ({ defaultImage }: { defaultImage: string | CustomFile }) => {
  if (defaultImage) {
    return (
      <Image
        borderRadius="2xl"
        width="100%"
        height="100%"
        src={defaultImage as string}
      />
    );
  }

  return <Icon color={colors.gray[300]} size="80" icon="image" />;
};

const MemoThunb = React.memo(Thumb);

const ImageZone = ({
  btnIconColor = colors.primaryAdmin[600],
  btnLabelColor = 'primary.600',
  btnBgColor = 'primary.50',
  btnLabel = 'Select cover image',
  title = 'Cover image',
  titleColor = colors.gray[600],
  errorMessage,
  isRequired,
  isDisabled,
  isInvalid,
  isReadOnly,
  hasError,
  iconErrorMessage,
  iconErrorSize,
  filesAccepted = { 'image/jpeg': [], 'image/png': [], 'image/jpg': [] },
  ...restProps
}: ImageZoneProps) => {
  const {
    showTitle,
    acceptedSize,
    previewImage,
    onDropAccepted,
    onDropRejected,
    acceptedHeight,
    acceptedWidth,
    handleDeleteFile,
  } = useDropzoneController(restProps);

  const notHaveImages = !previewImage;

  return (
    <Dropzone
      accept={filesAccepted}
      onDropAccepted={onDropAccepted}
      onDropRejected={onDropRejected}
    >
      {({ getRootProps, getInputProps, open }) => (
        <div {...getRootProps()}>
          <input {...getInputProps()} />
          <Stack space={3}>
            <FormControl
              isInvalid={hasError || errorMessage ? true : isInvalid}
              isDisabled={isDisabled}
              isRequired={isRequired}
              isReadOnly={isReadOnly}
            >
              {showTitle ? (
                <Box>
                  {title ? (
                    <FormControl.Label>
                      <Text color={titleColor} fontSize={14} fontWeight="500">
                        {title}
                      </Text>
                    </FormControl.Label>
                  ) : null}
                </Box>
              ) : null}

              <Box
                borderColor="muted.200"
                borderWidth={1}
                p={notHaveImages ? '5' : '0'}
                height="250px"
                justifyContent="center"
                alignItems="center"
                borderRadius="24px"
              >
                {!notHaveImages ? (
                  <Button
                    bgColor="white"
                    onPress={() => handleDeleteFile(previewImage as string)}
                    position="absolute"
                    right={4}
                    top={4}
                    zIndex={10}
                    maxW="3rem"
                    minW="3rem"
                    w="3rem"
                    h="3rem"
                    borderRadius={'3rem'}
                  >
                    <Icon icon="trash" size={24} color={colors.error[600]} />
                  </Button>
                ) : null}
                <Box
                  bgColor="white"
                  width="100%"
                  height="100%"
                  alignItems="center"
                >
                  <MemoThunb defaultImage={previewImage as string} />
                  <HStack space={4}>
                    <Stack alignItems="center" space="2">
                      <Stack
                        alignItems="center"
                        bgColor={
                          notHaveImages
                            ? 'transparent'
                            : 'rgba(255,255,255,0.7)'
                        }
                        p={3}
                        mt={notHaveImages ? '2px' : '-150px'}
                        borderRadius={6}
                      >
                        <Text color="gray.600" fontSize={16} fontWeight="500">
                          Minimum dimensions: {acceptedWidth}x{acceptedHeight}
                        </Text>
                        <Text color="gray.600" fontSize={14} fontWeight="400">
                          (Max {acceptedSize}MB)
                        </Text>
                      </Stack>
                      <Stack mt={notHaveImages ? '2px' : '3px'}>
                        <Button
                          leftIconName="upload"
                          leftIconSize={16}
                          leftIconColor={btnIconColor}
                          bgColor={btnBgColor}
                          onPress={open}
                        >
                          <Text
                            color={btnLabelColor}
                            fontSize="14"
                            fontWeight="500"
                          >
                            {notHaveImages ? btnLabel : 'Change cover image'}
                          </Text>
                        </Button>
                      </Stack>
                    </Stack>
                  </HStack>
                </Box>
              </Box>
              <FormError
                errorMessage={errorMessage}
                iconErrorMessage={iconErrorMessage}
                iconErrorSize={iconErrorSize}
              />
            </FormControl>
          </Stack>
        </div>
      )}
    </Dropzone>
  );
};

export default ImageZone;
