import { FormControl, HStack, Stack, Text } from 'native-base';
import GooglePlacesAutocomplete from 'react-google-places-autocomplete';

import { colors } from '../../theme/colors';
import { styles } from './styles';
import { IInputSearchLocation } from './types';
import { useInputSearchLocationController } from './useInputSearchLocationController';

const InputSearchLocation = ({
  defaultValue,
  errorMessage,
  isDisabled,
  helpText,
  hasFormUpdated,
  fontSize = 12,
  ...rest
}: IInputSearchLocation) => {
  const { selectProps, apiKey, apiOptions, label, isRequired } =
    useInputSearchLocationController({ fontSize, ...rest });

  return (
    <Stack w="100%">
      {label ? (
        <FormControl.Label>
          {label}
          {isRequired ? <Text color="error.600">*</Text> : null}
        </FormControl.Label>
      ) : null}

      <HStack
        flex={1}
        width="100%"
        alignContent="center"
        justifyContent="center"
        background="white"
        borderRadius={12}
        zIndex={9999}
        borderWidth={1}
        borderColor={
          errorMessage ? colors.error[500] : colors.singletons.lightGray
        }
        paddingLeft={{ base: defaultValue ? 10 : 0, md: 0 }}
      >
        <GooglePlacesAutocomplete
          apiKey={apiKey}
          apiOptions={apiOptions}
          selectProps={{
            ...selectProps,
            isDisabled,
            // Avoid Google Field to keep default value on attendance change,
            // not a ideal solution, we should change whenever possible.
            defaultInputValue: !hasFormUpdated ? defaultValue : undefined,
          }}
        />
      </HStack>
      {helpText && !errorMessage ? (
        <Text
          mt={2}
          ml={2}
          display={styles.display}
          fontWeight={400}
          fontSize="xs"
          color="muted.500"
        >
          {helpText}
        </Text>
      ) : null}

      {errorMessage ? (
        <Text fontWeight={400} fontSize="xs" color="error.500">
          {errorMessage}
        </Text>
      ) : null}
    </Stack>
  );
};

export default InputSearchLocation;
