import { Route } from 'react-router-dom';

import { PAGES } from '~/constants/pages.constants';
import { Dashboard } from '~/pages/Authenticated/Dashboard';

export const CompanyRoutes = () => {
  return (
    <>
      <Route path={PAGES.HOME} element={<Dashboard />} />
    </>
  );
};
