import { useRef, useState } from 'react';
import { toast } from 'react-toastify';
import { IModalRefProps } from 'ui/components/Modals/Modal/types';
import { ITabsRefProps } from 'ui/components/Tabs/types';

import { PAGES } from '~/constants/pages.constants';
import { ActivityTypeEnum } from '~/enums';
import { useAppSelector } from '~/hooks/useAppSelector';
import { useLoadAppsConnectedToEcosystem } from '~/hooks/useLoadAppsConnectedToEcosystem';
import { useRouter } from '~/hooks/useRouter';

import { ActivitiesTabPages } from './types';

export const useActivitiesController = () => {
  const [currentTab, setCurrentTab] = useState<ActivitiesTabPages>(
    ActivitiesTabPages.Applications,
  );

  const { organizationSelectedId } = useAppSelector(({ auth }) => auth);
  const { selectedEcosystem } = useAppSelector(({ ecosystem }) => ecosystem);

  const { goToRoute } = useRouter();

  const { connectedApps } = useLoadAppsConnectedToEcosystem(
    organizationSelectedId,
    selectedEcosystem?._id,
  );

  const handleNavigateToActivtyForm = (activityType: ActivityTypeEnum) => {
    if (connectedApps && connectedApps.length === 0) {
      toast.error(
        'Please add an app to your ecosystem before creating a activity.',
      );
    } else {
      switch (activityType) {
        case ActivityTypeEnum.Event:
          return goToRoute(PAGES.ADD_EVENT);
        case ActivityTypeEnum.Action:
          return goToRoute(PAGES.ADD_ACTION);
        case ActivityTypeEnum.OngoingOpportunity:
          return goToRoute(PAGES.ADD_ONGOING_OPPORTUNITY);
      }
    }
  };
  const [pendingApplications, setPendingApplications] = useState(0);

  const modalRef = useRef<IModalRefProps>(null);

  const tabsRef = useRef<ITabsRefProps>(null);

  const handleChangeTab = (tab: number) => {
    setCurrentTab(tab);
  };

  const handleOpenAddActivityModal = () => {
    if (connectedApps && connectedApps.length === 0) {
      toast.error(
        'Please add an app to your ecosystem before creating a activity.',
      );
    } else {
      modalRef?.current?.open();
    }
  };

  const handleImportButton = () => {
    return goToRoute(PAGES.UPLOADS);
  };

  return {
    tabsRef,
    modalRef,
    currentTab,
    handleChangeTab,
    handleOpenAddActivityModal,
    handleNavigateToActivtyForm,
    pendingApplications,
    setPendingApplications,
    handleImportButton,
  };
};
