import {
  MAX_SIZE_IN_BYTES,
  VALID_EXTENSIONS,
  VALID_IMAGE_MIME_TYPES,
} from './constants';

const getFileExtension = (fileName: string) =>
  fileName.split('.').pop()?.toLowerCase();

const isImageDimensionsValid = (
  file: File,
  minWidth: number,
  minHeight: number,
) => {
  return new Promise<boolean>((resolve) => {
    const img = new Image();
    img.onload = () => {
      if (img.width < minWidth || img.height < minHeight) {
        return resolve(false);
      }
      return resolve(true);
    };
    img.src = URL.createObjectURL(file);
  });
};

export const validateImageFile = async (
  file: File,
  minWidth: number,
  minHeight: number,
) => {
  // Validate File Size
  if (file.size === 0) {
    return { message: 'File is empty.', isValid: false };
  } else if (file.size > MAX_SIZE_IN_BYTES) {
    return {
      message: 'The file is too large. The maximum allowed size is 2MB.',
      isValid: false,
    };
  }

  // Valid the file mimetype
  const extension = getFileExtension(file.name);
  if (
    !VALID_EXTENSIONS.includes(extension || '') ||
    !VALID_IMAGE_MIME_TYPES.includes(file.type)
  ) {
    return {
      isValid: false,
      message: 'Invalid file type. Please select a JPEG, PNG, or WEBP image.',
    };
  }

  // Validate file dimension
  const isDimValid = await isImageDimensionsValid(file, minWidth, minHeight);
  if (!isDimValid) {
    return {
      isValid: isDimValid,
      message: `Image dimensions must be at least ${minWidth}x${minHeight} pixels.`,
    };
  }

  return { isValid: true, message: '' };
};
