import { VStack } from 'native-base';
import React, { ForwardedRef, forwardRef } from 'react';

import { TabsHeader } from './components/TabsHeader';
import { useTabNavigatorController } from './controller';
import { ITabNavigatorRefProps, TabNavigatorProps } from './types';

export const TabNavigator = forwardRef(function TabNavigator(
  { tabs, children, onChange }: TabNavigatorProps,
  ref: ForwardedRef<ITabNavigatorRefProps>,
) {
  const childrenArray = React.Children.toArray(children);

  const { handleTabPress, selectedIndex } = useTabNavigatorController({
    ref,
    tabs,
    onChange,
  });

  return (
    <VStack w="full" alignItems="center">
      {/* HEADER */}
      <TabsHeader
        tabs={tabs}
        onTabPress={handleTabPress}
        selectedIndex={selectedIndex}
      />

      {/* BODY */}
      {!!childrenArray.length && (
        <VStack w="full">{childrenArray[selectedIndex]}</VStack>
      )}
    </VStack>
  );
});

export default TabNavigator;
