import { IColumn } from '@inovua/reactdatagrid-community/types';
import DataTableActions from 'ui/components/DataTableActions';
import { TableCellWithImage } from 'ui/components/TableCellWithImage';
import { formattedDate } from 'ui/utils/formatter';

import { ActivityTypeEnum } from '~/enums';
import { colors } from '~/theme/colors';

import { IActivitiesColumnsData } from './types';

export const tableActivitiesColumns = ({
  handleViewActivity,
}: {
  handleViewActivity: (activityType: ActivityTypeEnum, id: string) => void;
}): IColumn[] => [
  {
    filterable: false,
    name: 'activitySubDocument.startDate',
    header: 'Date',
    defaultFlex: 1,
    render: ({ data }: IActivitiesColumnsData) =>
      formattedDate(data.activitySubDocument.startDate, 'dd/MM/yyyy'),
  },
  {
    filterable: false,
    name: 'activitySubDocument.activityDefinitionSubDocument.title',
    header: 'Activity',
    defaultFlex: 1,
    render: ({ data }: IActivitiesColumnsData) =>
      data.activitySubDocument.activityDefinitionSubDocument.title || '-',
  },
  {
    filterable: false,
    name: 'activitySubDocument.activityDefinitionSubDocument.organizationSubDocument.name',
    header: 'Organization',
    defaultFlex: 1,
    render: ({ data }: IActivitiesColumnsData) => (
      <TableCellWithImage
        name={
          data.activitySubDocument.activityDefinitionSubDocument
            .organizationSubDocument.name
        }
        logo={
          data.activitySubDocument.activityDefinitionSubDocument
            .organizationSubDocument.logo
        }
      />
    ),
  },
  {
    filterable: false,
    name: 'teamSubDocument.members',
    header: 'Members participating',
    defaultFlex: 1,
    render: ({ data }: IActivitiesColumnsData) =>
      data.teamSubDocument?.members.length +
      (data.teamSubDocument?.leader ? 1 : 0),
  },
  {
    header: 'Actions',
    defaultFlex: 1,
    render: ({ data }: IActivitiesColumnsData) => (
      <DataTableActions
        viewButton
        color={colors.primary['600']}
        onPressViewButton={() =>
          handleViewActivity(
            data.activitySubDocument.activityDefinitionSubDocument
              .type as ActivityTypeEnum,
            data.activitySubDocument.activityDefinitionSubDocument._id,
          )
        }
      />
    ),
  },
];
