import { yupResolver } from '@hookform/resolvers/yup';
import { useCallback, useMemo } from 'react';
import { useFieldArray, useForm } from 'react-hook-form';
import { EActivityAttendanceTypes } from 'ui/enums';

import { IDatesDTO, IEditEventDTO } from '~/types/dtos';

import { SingleLocationProps } from './types';
import { singleSchemaValidation } from './utils';

export const useSingleLocationController = ({
  handleContinue,
  handleBack,
  isLoading,
  isTeamEvent,
  defaultValues,
}: SingleLocationProps) => {
  const {
    control,
    handleSubmit,
    watch,
    setValue,
    formState: { isValid, errors, isDirty },
  } = useForm<IEditEventDTO>({
    resolver: yupResolver(singleSchemaValidation),
    mode: 'onChange',
    reValidateMode: 'onBlur',
    values: defaultValues,
  });

  const {
    fields: datesFields,
    append: appendDate,
    remove: removeDate,
  } = useFieldArray<IEditEventDTO>({
    control,
    name: 'dates',
  });

  const handleSubmitForm = handleSubmit((data: IEditEventDTO) => {
    handleContinue(data);
  });

  const formValues = watch();

  const { isDisabledPublishButton } = useMemo(() => {
    return {
      isRemoteEvent:
        formValues['attendanceType'] === EActivityAttendanceTypes.Remote,
      isDisabledPublishButton: !isValid,
    };
  }, [formValues, isValid]);

  const addDateForm = useCallback(
    (placeIndex: number, nextIndexPosition: number) => {
      appendDate({
        placeFormIndex: placeIndex,
        indexPosition: nextIndexPosition,
        volunteerNumber: undefined,
        noVolunteerLimit: false,
        startDate: undefined,
        endDate: undefined,
        dueDate: undefined,
        teamsNumber: undefined,
        teamsMaxSize: undefined,
        teamsMinSize: undefined,
      } as IDatesDTO);
    },
    [appendDate],
  );

  const removeDateForm = useCallback(
    (dateIndex: number) => {
      removeDate(dateIndex);
    },
    [removeDate],
  );

  return {
    datesFields,
    appendDate,
    addDateForm,
    removeDateForm,
    handleBack,
    handleSubmitForm,
    setValue,
    control,
    isLoading,
    isDisabledPublishButton,
    errors,
    isTeamEvent,
    isDirty,
  };
};
