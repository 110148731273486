import { Text } from 'native-base';
import Modal from 'ui/components/Modals/Modal';

import { REPORT_MESSAGES } from '../../constants/messages.constants';
import { useModalReportProcessingController } from './controller';
import { ModalReportProcessingProps } from './types';

export const ModalReportProcessing = ({
  onExportPress,
  contentText,
  headerText,
  modalRef,
}: ModalReportProcessingProps) => {
  const { isSubmitting, handleCloseModal, handleOnSubmit } =
    useModalReportProcessingController({
      onSubmit: onExportPress,
      modalRef,
    });

  return (
    <Modal
      headerText={headerText ?? 'Export request'}
      ref={modalRef}
      modalContentMaxW="650"
      leftBtnAction={handleCloseModal}
      leftBtnText="Cancel"
      rightBtnText="Export"
      rightBtnLoading={isSubmitting}
      rightBtnAction={handleOnSubmit}
    >
      <Text fontSize="md" color="gray.600" mt="4">
        {contentText ?? REPORT_MESSAGES.FILE_PROCESSING}
      </Text>
    </Modal>
  );
};
