import { HStack, Stack, Text } from 'native-base';
import { Controller } from 'react-hook-form';
import Button from 'ui/components/Button';
import { ImagePicker } from 'ui/components/v2/ImageInputs/ImagePicker';

import { ICustomizationFormProps } from '../types';
import { styleConfig } from './styleConfig';

export const CustomizationForm = ({
  control,
  isDisabledButton,
  ecosystemIdToEdit,
  isSubmitting,
  onSubmit,
  handleBack,
  handleContinue,
}: ICustomizationFormProps) => {
  return (
    <Stack space={5} mt={8} p={2} w="100%">
      <Stack w="100%" space={2}>
        <Text color="gray.600" fontSize="xl">
          Customisation
        </Text>
        <Text color="gray.600" fontWeight={400} fontSize="sm">
          Upload an image and choose a color to customize the ecosystem.
        </Text>
      </Stack>

      <HStack flexDirection={styleConfig.direction} w="100%" space={8} pb="24">
        <Stack w={styleConfig.logoContainer}>
          <Controller
            name="logo"
            control={control}
            render={({ field: { value, onChange } }) => (
              <ImagePicker.Base
                src={value as string}
                circularCrop
                aspectRatio={1}
                onChange={onChange}
                cropModalTitle="Adjust your ecosystem image"
              />
            )}
          />
        </Stack>
      </HStack>

      <HStack
        w="full"
        flexDirection={styleConfig.direction}
        alignItems="center"
        space={2}
        mt="10"
      >
        <Stack
          w={styleConfig.buttonWidth}
          display={styleConfig.desktopBackButtonDisplay}
        >
          <Button variant="ghost" width="100%" onPress={handleBack}>
            Back
          </Button>
        </Stack>
        <Stack w={styleConfig.buttonWidth}>
          <Button
            width="100%"
            isDisabled={isDisabledButton}
            onPress={ecosystemIdToEdit ? onSubmit : handleContinue}
            isLoading={isSubmitting}
          >
            {ecosystemIdToEdit ? 'Update ecosystem' : 'Continue'}
          </Button>
        </Stack>
        <Stack
          w={styleConfig.buttonWidth}
          display={styleConfig.mobileBackButtonDisplay}
        >
          <Button
            variant="ghost"
            width="100%"
            mt={styleConfig.buttonMargin}
            onPress={handleBack}
          >
            Back
          </Button>
        </Stack>
      </HStack>
    </Stack>
  );
};
