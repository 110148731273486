import { Hidden, HStack, Stack, VStack } from 'native-base';
import { NotificationCard } from 'ui/components/NotificationCard';

import { useAuthLayoutController } from '~/components/AuthLayout/controller';
import { AuthLayoutProps } from '~/components/AuthLayout/types';
import Drawer from '~/components/Layout/Drawer';
import { Loading } from '~/components/Loading';
import { PAGES } from '~/constants/pages.constants';
import { HeaderAuthenticated } from '~/HeaderAuthenticated';
import { useCurrentOrganization } from '~/hooks/organization/useCurrentOrganization';
import { useRouter } from '~/hooks/useRouter';
import { useSetEcosystemsToSelectOptions } from '~/hooks/useSetEcosystemsToSelectOptions';

export const AuthLayout = ({ children }: AuthLayoutProps) => {
  const { goToRoute } = useRouter();
  const { currentOrganization, selectedEcosystem } = useCurrentOrganization();
  const { ecosystemName, ecosystemLogo } = useSetEcosystemsToSelectOptions();
  const { content, isNotificationOpen } = useAuthLayoutController();

  const handleLogoPress = () => {
    goToRoute(PAGES.HOME);
  };

  /* AuthLayout routes are exclusive to previous Organization and Ecosystem selection  */
  const shouldRender = !!currentOrganization && !!selectedEcosystem;
  if (!shouldRender) return <Loading containerHeight="100vh"></Loading>;

  return (
    <VStack w={'full'} h={'full'}>
      {/* header */}
      <HeaderAuthenticated
        ecosystemLogo={ecosystemLogo}
        ecosystemName={ecosystemName}
        onLogoPress={handleLogoPress}
      />
      {/* main */}
      <HStack flex={1} w={'full'} h={'full'}>
        {/* Modals */}
        {isNotificationOpen ? (
          <Stack
            position="absolute"
            width={{ base: '100%', md: '95.5%' }}
            alignItems="flex-end"
            padding={{ base: 4 }}
            zIndex={999}
          >
            <NotificationCard>{content}</NotificationCard>
          </Stack>
        ) : null}

        {/* aside */}
        <Hidden till={'lg'}>
          <Drawer />
        </Hidden>

        {/* content */}
        <VStack bgColor="light.100" overflowY="auto" flex={1}>
          {children}
        </VStack>
      </HStack>
    </VStack>
  );
};
