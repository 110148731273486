import { Avatar, HStack, Stack, Text } from 'native-base';
import { PopOverOptions } from 'ui/components/PopOverOptions';
import Tabs from 'ui/components/Tabs';
import Tag from 'ui/components/Tags/Tag';

import PageHeader from '~/components/Layout/PageHeader';
import ModalDelete from '~/components/ModalDelete';
import Scaffold from '~/components/Scaffold';
import { colors } from '~/theme/colors';

import { ChildOrgAccess } from './ChildOrgAccess';
import { ChildOrgActivities } from './ChildOrgActivities';
import { ChildOrgDetails } from './ChildOrgDetails';
import { ChildOrgMembers } from './ChildOrgMembers';
import { useChildOrgController } from './controller';
import { tabsElements } from './tabElements';

export const ViewChildOrg = () => {
  const {
    popOverOptions,
    tabIndex,
    modalRef,
    handleCloseModal,
    handleChangeTab,
    handleRemoveChildOrg,
  } = useChildOrgController();
  return (
    <Scaffold
      title={
        <HStack width="100%" alignItems="center" justifyContent="space-between">
          <HStack space={4} alignItems={'center'}>
            <Avatar
              source={{
                uri: 'https://yt3.googleusercontent.com/ytc/AOPolaRl8KZee4hiiF8na9PQjnM5m6WZnEXQ3bwtR-28=s900-c-k-c0x00ffffff-no-rj',
              }}
              height="40px"
              width="40px"
              borderRadius="sm"
              bgColor="white"
              borderWidth="1"
              borderColor="gray.300"
            />
            <PageHeader title="Fareshare North East" />
            <Tag text="ORGANISATION" type="child org" alignSelf="center" />
          </HStack>
          <PopOverOptions
            popovText="Options"
            popovTextPosition="right"
            popovIcon="more-vertical"
            options={popOverOptions}
          />
        </HStack>
      }
      withBg={false}
    >
      <Stack width="100%">
        <Tabs
          tabWith="100%"
          tabBgColor="gray.200"
          tabIndicatorColor={colors.primary[400]}
          elements={tabsElements}
          onChangeTab={handleChangeTab}
          isClickableTab={true}
        />
        <Stack width="100%" mt={10}>
          {tabIndex === 0 ? <ChildOrgActivities /> : null}
          {tabIndex === 1 ? <ChildOrgMembers /> : null}
          {tabIndex === 2 ? <ChildOrgDetails /> : null}
          {tabIndex === 3 ? <ChildOrgAccess /> : null}
        </Stack>
      </Stack>

      <ModalDelete
        modalRef={modalRef}
        headerText="Remove child org"
        handleCloseModal={handleCloseModal}
        handleDelete={handleRemoveChildOrg}
        rightBtnLoading={false}
        rightBtnText="Remove"
      >
        <Stack space={5}>
          <Text fontWeight={400} fontSize="md" color="gray.500">
            Are you sure that you want to remove this child organisation?
          </Text>

          <Text fontWeight={400} fontSize="md" color="gray.500">
            The members and admins will no longer have access to the ecosystem
            and will lose all permissions associated with it.
          </Text>
        </Stack>
      </ModalDelete>
    </Scaffold>
  );
};
