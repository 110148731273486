import { useImperativeHandle } from 'react';

import { UseFormImperativeHandleProps } from './types';

export const useFormImperativeHandle = ({
  ref,
  getValues,
  setValue,
}: UseFormImperativeHandleProps) => {
  useImperativeHandle(
    ref,
    () => ({
      getValue: (name: string) => getValues(name as never),
      setValue: (name: string, newValue: never) =>
        setValue(name as never, newValue),
    }),
    [getValues, setValue],
  );
};
