import { useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { IModalRefProps } from 'ui/components/Modals/Modal/types';
import { ITabsRefProps } from 'ui/components/Tabs/types';

import { PAGES } from '~/constants/pages.constants';
import { useAppSelector } from '~/hooks/useAppSelector';
import { useLoadOrganization } from '~/hooks/useLoadOrganization';
import { TabsPages } from '~/pages/Authenticated/Members/types';
import { addMemberActions } from '~/store/slices/addMember';

export const useMembersController = () => {
  const modalRef = useRef<IModalRefProps>(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const tabsRef = useRef<ITabsRefProps>(null);
  const { organizationSelectedId } = useAppSelector(({ auth }) => auth);
  const [searchParams, setSearchParams] = useSearchParams();

  const currentTab = useMemo(() => {
    return Number(searchParams.get('tab') || TabsPages.Members);
  }, [searchParams]);

  const { data: organization, isLoading: isLoadingOrganization } =
    useLoadOrganization(organizationSelectedId || '');

  const handleChangeTab = (tab: number) => {
    searchParams.set('tab', String(tab));
    setSearchParams(searchParams);
  };

  const handleOpenAddMembersModal = () => {
    modalRef?.current?.open();
  };

  const handleNavigateToAddMember = () => {
    navigate(PAGES.ADD_ORG_MEMBER);
  };

  const handleNavigateToAddChildOrg = () => {
    navigate(PAGES.ADD_CHILD_ORG);
  };

  const handleNavigateToAddPartner = () => {
    navigate(PAGES.ADD_ORG_PARTNER);
  };

  useEffect(() => {
    dispatch(addMemberActions.reset());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    organization,
    isLoadingOrganization,
    tabsRef,
    currentTab,
    handleChangeTab,
    modalRef,
    handleOpenAddMembersModal,
    handleNavigateToAddMember,
    handleNavigateToAddChildOrg,
    handleNavigateToAddPartner,
  };
};
