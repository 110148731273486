import { HStack } from 'native-base';
import Button from 'ui/components/Button';
import DataTableActions from 'ui/components/DataTableActions';
import Icon from 'ui/components/Icon';
import { Tooltip } from 'ui/components/Tooltip';

import { colors } from '~/theme/colors';

import { ApplicationActionProps } from './types';

export const ApplicationActions = ({
  onPressAccept,
  onPressDecline,
  onPressView,
  isButtonsDisabled,
  showAcceptButton,
  showDeclineButton,
}: ApplicationActionProps) => {
  return (
    <HStack justifyContent="right">
      <HStack space={2} alignItems="center">
        {showAcceptButton && (
          <Tooltip label="Accept application" openDelay={500}>
            <Button
              minW="2rem"
              w="2rem"
              h="2rem"
              bg={colors.success['500']}
              onPress={onPressAccept}
              disabled={isButtonsDisabled}
            >
              <Icon icon="check" size={16} color={colors.white} />
            </Button>
          </Tooltip>
        )}
        {showDeclineButton && (
          <Tooltip label="Decline application" openDelay={500}>
            <Button
              minW="2rem"
              w="2rem"
              h="2rem"
              bg={colors.error['100']}
              onPress={onPressDecline}
              disabled={isButtonsDisabled}
            >
              <Icon icon="x" size={16} color={colors.error['600']} />
            </Button>
          </Tooltip>
        )}
      </HStack>
      <HStack alignItems="center">
        <DataTableActions
          viewButton
          color={colors.primary['600']}
          onPressViewButton={onPressView}
        />
      </HStack>
    </HStack>
  );
};
