import { useMemo } from 'react';
import { AppVisibility } from 'ui/enums';

import { useQuery } from '~/hooks/useQuery';
import { IPaginatedResponse } from '~/types';
import { App, AppsQuery } from '~/types/interfaces/app';

export const useLoadPublicApps = (
  organizationId?: string,
  ecosystemId?: string,
  searchText?: string,
) => {
  const publicAppsQuery = useMemo(() => {
    const params: AppsQuery = { visibility: AppVisibility.Public };

    if (searchText) {
      params.name = { $regex: searchText, $options: 'i' };
    }

    return params;
  }, [searchText]);

  const {
    data: publicApps,
    isLoading: isLoadingPublicApps,
    isRefetching: isRefetchingPublicApps,
  } = useQuery<IPaginatedResponse<App[]>>(`/app`, {
    requestOptions: {
      params: {
        filter: JSON.stringify(publicAppsQuery),
      },
    },
    key: `publicApps${organizationId}-${ecosystemId}-${searchText}`,
  });

  return {
    publicApps,
    isLoadingPublicApps,
    isRefetchingPublicApps,
  };
};
