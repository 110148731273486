import { colors } from '~/theme/colors';

export const useSideBarWithContentController = () => {
  const sideBarButtonStyle = (isSelected?: boolean) => {
    return {
      hover: {
        _text: { color: colors.singletons.darkText },
      },
      text: {
        color: isSelected ? colors.singletons.darkText : 'gray.600',
        fontWeight: '500',
      },
      bgColor: isSelected ? colors.gray[100] : 'white',
    };
  };

  return { sideBarButtonStyle };
};
