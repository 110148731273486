import { HStack, Stack, Text } from 'native-base';
import Button from 'ui/components/Button';
import Modal from 'ui/components/Modals/Modal';

import { IModalSignOutProps } from './types';

const ModalSignOut = ({
  modalRef,
  closeModal,
  signOut,
}: IModalSignOutProps) => (
  <Modal headerText="Sign out" ref={modalRef} modalContentMaxW="669">
    <Stack py="2" space={6}>
      <Text fontSize="16px" color="gray.600">
        Are you sure that you want to sign out?
      </Text>
      <Text fontSize="16px" color="gray.600">
        To sign in again, you will need to enter your email and password.
      </Text>

      <HStack>
        <Button variant="link" w="50%" onPress={closeModal}>
          <Text fontSize="16px" fontWeight={500} color="singletons.black">
            No, go back
          </Text>
        </Button>
        <Button
          colorScheme="tertiary"
          w="50%"
          bgColor="primary.600"
          onPress={signOut}
        >
          <Text fontSize="16px" fontWeight={500} color="singletons.white">
            Sign out
          </Text>
        </Button>
      </HStack>
    </Stack>
  </Modal>
);

export default ModalSignOut;
