import { format, parseISO } from 'date-fns';
import { VStack } from 'native-base';
import { useEffect, useMemo } from 'react';
import { useQuery } from 'react-query';
import { colors } from 'ui/theme/colors';

import { BarChart } from '~/pages/Authenticated/Insights/charts/BarChart';
import { dateFilterIsMoreThan30Days } from '~/pages/Authenticated/Insights/components/Applications/utils';
import { InsightScaffoldChildrenReactComponent } from '~/pages/Authenticated/Insights/components/InsightScaffold';
import {
  InsightPageFilters,
  useInsightFilters,
} from '~/pages/Authenticated/Insights/useInsightFilters';
import { InsightCacheConfig } from '~/pages/Authenticated/Insights/utils';
import InsightActivityApplicationService, {
  GetApplicationsReportQueryParams,
} from '~/services/resources/insightActivityApplication';
import { InsightActivityApplicationGroupedBy } from '~/services/resources/types';

const buildQueryKey = (filters: InsightPageFilters) => {
  const isMoreThan30Days = dateFilterIsMoreThan30Days(filters);

  const params: GetApplicationsReportQueryParams = {
    apps: filters.apps,
    ecosystem: filters.selectedEcosystemId,
    organization: filters.selectedOrganizationId,
    startDate: filters.from!,
    endDate: filters.to!,
    granularity: isMoreThan30Days ? 'Monthly' : 'Daily',
    groupBy: [InsightActivityApplicationGroupedBy.ApplicationDate],
  };
  return [
    InsightActivityApplicationService.APPLICATION_DATE_REPORT_KEY,
    params,
  ];
};

export const ApplicationsTotal: InsightScaffoldChildrenReactComponent = ({
  onSetChart,
}) => {
  const { filters, hasFilterSetted } = useInsightFilters();

  const {
    data: response,
    isFetching,
    isLoading,
  } = useQuery({
    queryKey: buildQueryKey(filters),
    queryFn: async ({ queryKey }) => {
      const [, params] = queryKey;

      return await InsightActivityApplicationService.getApplicationDatesReport(
        params as GetApplicationsReportQueryParams,
      );
    },
    onSuccess(data) {
      onSetChart(null, data);
    },
    enabled: hasFilterSetted,
    ...InsightCacheConfig,
  });

  useEffect(() => {
    onSetChart(null, response ?? []);
    return () => {
      onSetChart(null, []);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [response]);

  const chartData = useMemo(() => {
    const dataMap = new Map<string, number>();
    const isMoreThan30Days = dateFilterIsMoreThan30Days(filters);

    // Agrupando os dados por total e mês/ano
    (response ?? []).forEach((item) => {
      const monthYear = format(
        parseISO(item.date),
        isMoreThan30Days ? 'MMM yyyy' : 'dd MMM',
      );

      if (!dataMap.has(monthYear)) {
        dataMap.set(monthYear, item.total ?? 0);
      } else {
        dataMap.set(monthYear, (dataMap.get(monthYear) ?? 0) + item.total);
      }
    });

    return {
      series: [
        {
          name: 'Applications',
          data: Array.from(dataMap.values()),
        },
      ],
      labels: Array.from(dataMap.keys()),
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [response]);

  return (
    <VStack>
      <BarChart
        isLoading={isLoading || isFetching}
        series={chartData.series}
        labels={chartData.labels}
        colors={[colors.primary[200]]}
        xaxis={{
          categories: chartData.labels,
        }}
        yaxis={{
          title: {
            text: 'Number of Applications',
          },
        }}
      />
    </VStack>
  );
};
