import { Auth } from 'aws-amplify';
import axios, { AxiosHeaders } from 'axios';

import { store } from '~/store';
import { authSlice } from '~/store/slices/auth';

export const api = axios.create({
  baseURL: import.meta.env.VITE_URL_API,
  headers: {
    Accept: 'application/json',
    'Content-type': 'application/json',
  },
});

// Public API to avoid interceptor catch for guest users.
export const publicApi = axios.create({
  baseURL: import.meta.env.VITE_URL_API,
  headers: {
    Accept: 'application/json',
    'Content-type': 'application/json',
  },
});

api.interceptors.request.use(async (config) => {
  try {
    const currentSession = await Auth.currentSession();
    const idToken = currentSession.getIdToken().getJwtToken();
    (config.headers as AxiosHeaders).set('Authorization', `Bearer ${idToken}`);
    return config;
  } catch (error) {
    store.dispatch(authSlice.actions.logout());
    location.replace('/');
    return config;
  }
});
