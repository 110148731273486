import { useQuery } from '~/hooks/useQuery';
import { QueryParams } from '~/services/resources/types';
import { IPaginatedResponse } from '~/types';
import { AdminProfile } from '~/types/interfaces/admin-profile';

export const useLoadAdminProfiles = (
  organizationId = '',
  params?: QueryParams,
) => {
  const {
    data: responseData,
    isLoading,
    isFetching,
    isRefetching,
    refetch,
  } = useQuery<IPaginatedResponse<AdminProfile[]>>(
    `/admin-profile/organization/all/${organizationId}`,
    {
      queryOptions: {
        enabled: !!organizationId && !!params,
      },
      requestOptions: {
        params: {
          ...params,
          populate: JSON.stringify({
            model: 'User',
            path: 'user',
          }),
        },
      },
      key: `app-admin-profile-${organizationId}-${JSON.stringify(params)}`,
    },
  );

  return {
    responseData,
    isLoading,
    isFetching,
    isRefetching,
    refetch,
  };
};
