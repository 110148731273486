import { Circle, HStack, Text } from 'native-base';

import { TrendingBaseElement } from './TrendingBaseElement';

type TrendingDayElementProps = {
  day: string;
  isLoading: boolean;
};

const daysOfWeekInitials = ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa'];

export const TrendingDayElement = ({
  day,
  isLoading,
}: TrendingDayElementProps) => {
  const dayInitial = day.substring(0, 2);

  const renderContent = () => {
    if (!day) {
      return (
        <Text fontSize={'xl'} fontWeight={500} color={'gray.800'}>
          N/A
        </Text>
      );
    }

    return (
      <>
        <Text fontSize={'xl'} fontWeight={500} color={'gray.800'}>
          {day}
        </Text>
        <HStack space={1} alignItems="center">
          {daysOfWeekInitials.map((d) => {
            const selectedDay = dayInitial.toLowerCase() === d.toLowerCase();
            return (
              <Circle
                key={d}
                borderWidth={1}
                size={6}
                bg={selectedDay ? 'primary.400' : undefined}
                borderColor={selectedDay ? 'primary.400' : 'gray.400'}
                p={1}
              >
                <Text
                  fontWeight={selectedDay ? 500 : 400}
                  fontSize={'xs'}
                  color={selectedDay ? 'white' : 'gray.400'}
                >
                  {d}
                </Text>
              </Circle>
            );
          })}
        </HStack>
      </>
    );
  };

  return (
    <TrendingBaseElement
      heading="Trending Day to Volunteer"
      isLoading={isLoading}
    >
      <HStack
        space={2}
        flexWrap={'wrap'}
        alignItems={['flex-start', 'flex-start', 'center']}
        direction={['column', 'column', 'row']}
      >
        {renderContent()}
      </HStack>
    </TrendingBaseElement>
  );
};
