import { Divider, HStack, Stack, Text, VStack } from 'native-base';
import Button from 'ui/components/Button';
import Icon from 'ui/components/Icon';
import ReadMore from 'ui/components/ReadMore';
import Tag from 'ui/components/Tags/Tag';
import {
  AdvertiseRegionOptionsEnum,
  EActivityAttendanceTypes,
  GenericFormTabs,
  LocationOptionsEnum,
} from 'ui/enums';
import { ActivityRegion } from 'ui/types/activities';
import { secondsToHoursAndMinutes } from 'ui/utils/secondsToDuration';

import ImageGallery from '~/components/ImageGallery';
import { Permissions } from '~/components/Permissions';
import { ActivityTypeEnum } from '~/enums';
import { colors } from '~/theme/colors';

import { ActivityMetricsSection } from './components/ActivityMetricsSection';
import { LocationSection } from './components/LocationSection';
import { RequirementsSection } from './components/RequirementsSection';
import { useAboutActivityController } from './controller';
import { AboutActivityProps } from './types';

export const AboutActivity = ({
  activityType,
  activityDefinition,
  loadActivitiesList,
  measurementUnitObject,
  handleEditActivity,
  isOrganizationOwnerOfActivity,
  appId,
  activityPermissions,
  isAppAdmin,
  locationOption,
  mappedRegionsForLocationDetails,
  advertiseRegionOption,
}: AboutActivityProps) => {
  const { measurmentUnitLabels } = useAboutActivityController({
    activityDefinition,
    measurementUnitObject,
  });
  return (
    <Stack w="full" mt={10}>
      <Stack p={6} background="white" borderRadius="3xl" space={5}>
        <VStack space={6}>
          {activityType !== ActivityTypeEnum.Action &&
            activityDefinition.requirementOptions && (
              <RequirementsSection
                requirementOptions={activityDefinition.requirementOptions || []}
                volunteerRequirements={
                  activityDefinition.volunteerRequirements || []
                }
                volunteerRewards={activityDefinition.volunteerRewards || []}
              />
            )}
          {locationOption === LocationOptionsEnum.FromHome ? (
            <VStack space={2} mb={4}>
              <VStack space={2}>
                <HStack alignItems="center" space={2}>
                  <Icon
                    icon={'map-pin'}
                    color={colors.gray[900]}
                    alt="map pin icon"
                    size={20}
                  />
                  <Text color="gray.900" fontSize="2xl" fontWeight={500}>
                    Regions
                  </Text>
                </HStack>
                <Text color="gray.900" fontSize="sm" fontWeight={400}>
                  View where this opportunity takes place
                </Text>
              </VStack>
              {advertiseRegionOption ===
              AdvertiseRegionOptionsEnum.SelectRegions ? (
                mappedRegionsForLocationDetails?.map((mapRegion) => (
                  <VStack key={mapRegion.mainRegionData._id} space={2}>
                    <Text fontSize="xl">
                      {mapRegion.mainRegionData.displayName}
                    </Text>
                    <HStack flexWrap="wrap">
                      {!mapRegion.hasAllRegions ? (
                        mapRegion.relatedRegions.map(
                          (relatedRegion: ActivityRegion) => (
                            <Tag
                              text={relatedRegion.displayName}
                              key={relatedRegion._id}
                            />
                          ),
                        )
                      ) : (
                        <Tag
                          text={`All of ${mapRegion.mainRegionData.displayName}`}
                        />
                      )}
                    </HStack>
                  </VStack>
                ))
              ) : (
                <Tag text="Anywhere in UK" />
              )}
            </VStack>
          ) : null}
          {activityType !== ActivityTypeEnum.Action && (
            <>
              <LocationSection
                appId={appId}
                activityPermissions={activityPermissions}
                isAppAdmin={isAppAdmin}
                loadActivitiesList={loadActivitiesList}
                activityType={activityType}
                handleEditActivity={handleEditActivity}
                attendanceType={
                  activityDefinition.attendanceType as EActivityAttendanceTypes
                }
                locationOption={locationOption}
                mappedRegionsForLocationDetails={
                  mappedRegionsForLocationDetails
                }
              />
              <Divider />
            </>
          )}
          {activityType !== ActivityTypeEnum.Event && measurmentUnitLabels && (
            <ActivityMetricsSection
              metricsCategory={measurmentUnitLabels?.measurementUnitCategory}
              metricsName={measurmentUnitLabels?.measurementUnitName}
              targetAmount={
                secondsToHoursAndMinutes(
                  activityDefinition.targetAmount as number,
                ).hours
              }
              handleEditActivity={handleEditActivity}
              isOrganizationOwnerOfActivity={isOrganizationOwnerOfActivity}
            />
          )}
          <HStack justifyContent="space-between" alignItems="center" mb={2}>
            <VStack space={2}>
              <Text color="gray.600" fontSize="2xl" fontWeight="medium">
                Details
              </Text>
              <Text color="gray.600" fontSize="sm" fontWeight={400}>
                Basic information about this event
              </Text>
            </VStack>
            {isOrganizationOwnerOfActivity ? (
              // <Permissions.ValidateApp
              //   appIdToValidate={appId}
              //   requiredPermissions={activityPermissions}
              //   disablePermissionsValidation={isAppAdmin}
              // >
              <Button
                w="76px"
                borderRadius={12}
                py={2}
                px={3}
                minW="auto"
                leftIconName="edit"
                alignItems="center"
                leftIconColor={colors.primary[400]}
                variant="outline"
                onPress={() => handleEditActivity(GenericFormTabs.About)}
              >
                <Text color={colors.primary[400]}>Edit</Text>
              </Button>
            ) : // </Permissions.ValidateApp>
            null}
          </HStack>
          <Divider />
        </VStack>
        <VStack space={8}>
          <HStack w="full" space={12}>
            <VStack flex={0.5} space={8}>
              <VStack space="2">
                <Text fontSize="md" color="gray.600" fontWeight="medium">
                  Description
                </Text>
                <ReadMore
                  textHeight="310"
                  btnBgColor="transparent"
                  variant="ghost"
                  btnTextColor="darkText"
                  rightIconColor="darkText"
                  buttonTextShow="Read more"
                  buttonTextHide="Hide"
                  open
                  text={
                    <div id="description">
                      <Text color="gray.600">
                        {activityDefinition.description ?? ''}
                      </Text>
                    </div>
                  }
                />
              </VStack>
            </VStack>
            {activityDefinition.coverImage ? (
              <VStack flex={0.5}>
                <ImageGallery
                  coverImage={activityDefinition.coverImage as string}
                  images={activityDefinition.carouselImages as string[]}
                />
              </VStack>
            ) : null}
          </HStack>
          {activityType !== ActivityTypeEnum.Action &&
            activityDefinition.volunteerRequirements &&
            activityDefinition.volunteerRequirements?.length > 0 && (
              <VStack space="2">
                <Text fontSize="md" color="gray.600" fontWeight="medium">
                  Participants need to offer
                </Text>
                <HStack>
                  {activityDefinition.volunteerRequirements.map(
                    (requirement, index) => (
                      <Tag
                        key={`${index}-${requirement}`}
                        text={requirement}
                        tagBgColor="gray.100"
                        tagTextColor="gray.800"
                      />
                    ),
                  )}
                </HStack>
              </VStack>
            )}
          {activityType !== ActivityTypeEnum.Action &&
            activityDefinition.volunteerRewards &&
            activityDefinition.volunteerRewards?.length > 0 && (
              <VStack space="2">
                <Text fontSize="md" color="gray.600" fontWeight="medium">
                  Participants will receive
                </Text>
                <HStack>
                  {activityDefinition.volunteerRewards.map((reward, index) => (
                    <Tag
                      key={`${index}-${reward}`}
                      text={reward}
                      tagBgColor="gray.100"
                      tagTextColor="gray.800"
                    />
                  ))}
                </HStack>
              </VStack>
            )}
        </VStack>
      </Stack>
    </Stack>
  );
};
