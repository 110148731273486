import { useRef } from 'react';
import { IModalRefProps } from 'ui/components/Modals/Modal/types';

export const useViewGeneralSettingsController = () => {
  const modalInformationRef = useRef<IModalRefProps>(null);
  const openModalInformation = () => modalInformationRef.current?.open();

  return {
    modalInformationRef,
    openModalInformation,
  };
};
