import { Flex, HStack, Skeleton, Stack, VStack } from 'native-base';

export const LoadingSkeletonItem = () => {
  return (
    <Flex
      w={'full'}
      borderWidth={1}
      borderColor={'gray.300'}
      padding={{ base: 3, lg: 4 }}
      borderRadius={12}
      bg={'gray.50'}
    >
      <HStack flex={1} justifyContent={'space-between'}>
        <VStack space={3}>
          <VStack space={'2.5'} ml={{ base: -1, lg: 2 }}>
            <HStack space={2}>
              <Stack ml={-2} display={{ base: 'none', lg: 'flex' }}>
                <Skeleton h={12} w={12} rounded="full" />
              </Stack>
              <VStack justifyContent={'space-between'}>
                <HStack space={3} alignItems={'center'}>
                  <Skeleton
                    h="3"
                    w={{ base: 70, md: 100, lg: 150 }}
                    rounded="full"
                  />
                  <Skeleton
                    h="2.5"
                    w={{ base: 30, md: 90, lg: 100 }}
                    rounded="full"
                  />
                  <Skeleton
                    h="2.5"
                    w={{ base: 30, md: 90, lg: 100 }}
                    rounded="full"
                  />
                </HStack>
                <Skeleton
                  h="3"
                  w={140}
                  rounded="full"
                  display={{ base: 'none', lg: 'flex' }}
                />
              </VStack>
            </HStack>
            <HStack space={2} display={{ base: 'flex', lg: 'none' }}>
              <VStack mt={'3'} ml={2}>
                <Skeleton
                  h={{ base: '2', sm: '3' }}
                  w={{ base: '40', md: 100, lg: 150 }}
                  rounded="full"
                />
              </VStack>
            </HStack>
          </VStack>

          <HStack space={6} alignItems={'center'}>
            <Skeleton h="6" w={'20'} rounded="full" />
            <Skeleton h="3" w={{ base: 100, lg: 150 }} rounded="full" />
          </HStack>
        </VStack>
      </HStack>
    </Flex>
  );
};
