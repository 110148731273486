export async function base64ToFile(base64String: string, fileName: string) {
  const mimeTypeMatch = base64String.match(/^data:(.*?);base64,/);
  if (!mimeTypeMatch?.[1]) throw new Error('Invalid file');

  const [_, mimeType] = mimeTypeMatch;

  const file = await fetch(base64String)
    .then((res) => res.blob())
    .then((blob) => {
      return new File([blob], fileName, { type: mimeType });
    });

  return file;
}
