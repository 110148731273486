import { useMemo } from 'react';
import { useQuery } from 'react-query';

import { useAppSelector } from '~/hooks/useAppSelector';
import MeasurementUnitsService from '~/services/resources/measurmentUnits';
import { IPaginatedResponse } from '~/types';
import { MeasurementUnit } from '~/types/interfaces/measurementUnit';

type UseGetMeasurementUnitsProps =
  | {
      enabled?: boolean;
    }
  | undefined;

const DEFAULT_PARAMS: UseGetMeasurementUnitsProps = {
  enabled: true,
};

export const useGetMeasurementUnits = ({ enabled } = DEFAULT_PARAMS) => {
  const { organizationSelectedId } = useAppSelector(({ auth }) => auth);

  const fetchDefaultMeasurementUnits = async () => {
    const response =
      await MeasurementUnitsService.getDefaultMeaasurementUnits();

    return response.data;
  };

  const fetchCustomMeasurementUnits = async () => {
    const response = await MeasurementUnitsService.getCustomMeasurementUnits(
      organizationSelectedId!,
    );
    return response.data;
  };

  const { data: defaultMeasurementUnits } = useQuery<
    IPaginatedResponse<MeasurementUnit[]>
  >('default-units', fetchDefaultMeasurementUnits, {
    enabled,
  });

  const { data: customMeasurementUnits, refetch: refetchCustomUnits } =
    useQuery<IPaginatedResponse<MeasurementUnit[]>>(
      `custom-measurement-units`,
      fetchCustomMeasurementUnits,
      {
        enabled,
      },
    );

  const measurementUnits = useMemo(() => {
    const measurementUnits: MeasurementUnit[] = [];

    if (defaultMeasurementUnits?.data?.length) {
      measurementUnits.push(...defaultMeasurementUnits.data);
    }

    if (customMeasurementUnits?.data?.length) {
      measurementUnits.push(...customMeasurementUnits.data);
    }

    return measurementUnits?.reduce(
      (group: { [key: string]: MeasurementUnit[] }, measurementUnit) => {
        const { category } = measurementUnit;
        group[category] = group[category] ?? [];
        group[category].push(measurementUnit);
        return group;
      },
      {},
    );
  }, [defaultMeasurementUnits, customMeasurementUnits]);

  return { measurementUnits, refetchCustomUnits };
};
