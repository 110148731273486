import {
  TypeColumn,
  TypeDataGridProps,
  TypeDataSource,
} from '@inovua/reactdatagrid-community/types';
import { TypeColWithNameProperty } from '@inovua/reactdatagrid-community/types/TypeColumn';

// ---- Basic Table Types ----

export type BaseModel = {
  id: number | string;
};

export interface IColumn extends TypeColWithNameProperty {
  name?: string;
  header?: string;
  minWidth?: number;
  alignText?: string;
  defaultFlex?: number;
}

export type SelectedRows<Model extends BaseModel = BaseModel> = {
  [key: string | number]: Model | boolean;
};

export enum TableOperatorNames {
  Contains = 'contains',
  NotContains = 'notContains',
  Equals = 'eq',
  NotEquals = 'neq',
  Empty = 'empty',
  NotEmpty = 'notEmpty',
  StartsWith = 'startsWith',
  EndsWith = 'endsWith',
  GreaterThan = 'gt',
  GreaterThanOrEquals = 'gte',
  LowerThan = 'lt',
  LowerThanOrEquals = 'lte',
  After = 'after',
  AfterOrOn = 'afterOrOn',
  Before = 'before',
  BeforeOrOn = 'beforeOrOn',
  InRange = 'inrange',
  NotInRange = 'notinrange',
}

export enum TableOperatorType {
  Date = 'date',
  String = 'string',
  Number = 'number',
  Boolean = 'boolean',
  Select = 'select',
}

// ---- Table Filters ----

export interface IFilterValue {
  name: string;
  operator: TableOperatorNames;
  type: TableOperatorType;
  // Valid types allowed to value
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  value: any;
  filterEditor?: any;
}

export interface IFilter {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [field: string]: { [operator: string]: any };
}

// ---- Main Table Props ----

export interface IDataTableProps<Model extends BaseModel>
  extends Partial<
    Omit<
      TypeDataGridProps,
      | 'columns'
      | 'onFilterValueChange'
      | 'checkboxColumn'
      | 'defaultSelected'
      | 'selected'
      | 'onSelectionChange'
      | 'onReady'
      | 'loadData'
    >
  > {
  columns: TypeColumn[];
  defaultFilterValues?: IFilterValue[];
  isLoading?: boolean;
  loadData: (
    skip: number,
    limit: number,
    filter: IFilterValue | null,
  ) => TypeDataSource;
  refetchQuantity?: number;
  checkboxColumn?: boolean;
  onSelectionChange?: (selectedRows: SelectedRows<BaseModel>) => void;
  selected?: SelectedRows<Model>;
  onReady?: () => void;
}

export type IUseTableControllerProps<Model extends BaseModel> = Pick<
  IDataTableProps<Model>,
  | 'defaultFilterValues'
  | 'loadData'
  | 'refetchQuantity'
  | 'checkboxColumn'
  | 'onSelectionChange'
>;

// ---- Mongo Types ----

export enum MongoOperators {
  Regex = '$regex',
  Equals = '$eq',
  NotEquals = '$ne',
  Exists = '$exists',
  NotContains = '$not',
  GreaterThan = '$gt',
  GreaterThanOrEquals = '$gte',
  LowerThan = '$lt',
  LowerThanOrEquals = '$lte',
  Options = '$options',
}

export enum MongoOptions {
  CaseInsensitive = 'i',
}

// Utils?
export const ROWS_PER_PAGE = [2, 10, 25, 50, 100];
