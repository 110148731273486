import { useState } from 'react';
import { toast } from 'react-toastify';

import { COMMON_ERRORS } from '~/constants/error.constants';
import { REPORT_MESSAGES } from '~/constants/messages.constants';

import { useModalReportProcessingControllerProps } from './types';

export const useModalReportProcessingController = ({
  onSubmit,
  modalRef,
}: useModalReportProcessingControllerProps) => {
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  const handleCloseModal = modalRef.current?.close;
  const handleOnSubmit = async () => {
    try {
      setIsSubmitting(true);
      onSubmit();
      handleCloseModal?.();
      toast.success(REPORT_MESSAGES.REQUESTED);
    } catch (error) {
      toast.error(COMMON_ERRORS.ERROR_ON_LOAD_REPORT);
    } finally {
      setIsSubmitting(false);
    }
  };

  return {
    isSubmitting,
    handleOnSubmit,
    handleCloseModal,
  };
};
