import { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { Team } from 'ui/types/teams';
import {
  getTeamMembersTotal,
  groupTeamMembersByStatus,
  mapUserProfileToTeamMember,
} from 'ui/utils/teams';

import { useLoadActivityApplication } from '~/hooks/useLoadActivityApplication';

export const useViewTeamActivityApplicationController = () => {
  // -- Providers
  const { id: activityApplicationId } = useParams();

  // -- Hooks
  const { data: activityApplication, isLoading } = useLoadActivityApplication(
    String(activityApplicationId),
  );

  const team = useMemo(() => {
    if (!activityApplication) return null;
    return activityApplication.teamSubDocument as unknown as Team;
  }, [activityApplication]);

  // -- Handlers
  const groupedMembers = useMemo(() => {
    if (!team) return null;
    return groupTeamMembersByStatus(team);
  }, [team]);

  const leader = team
    ? mapUserProfileToTeamMember(team?.leaderSubDocument)
    : null;

  const membersTotal = team ? getTeamMembersTotal(team) : 0;

  return {
    activityApplication,
    groupedMembers,
    membersTotal,
    isLoading,
    team,
    leader,
  };
};
