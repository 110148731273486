import { useCallback, useMemo, useRef } from 'react';
import { toast } from 'react-toastify';
import { IFilterValue } from 'ui/components/DataTable/types';
import { IModalRefProps } from 'ui/components/Modals/Modal/types';

import { IMPORT_FILE_MESSAGES } from '~/constants/messages.constants';
import { PAGES } from '~/constants/pages.constants';
import { useAppSelector } from '~/hooks/useAppSelector';
import { useRouter } from '~/hooks/useRouter';
import { makeColumns } from '~/pages/Authenticated/BulkUploads/utils';
import { ImportFilesServices } from '~/services/resources/import-files';

import { EImportFileType, IImportFile } from './types';

export const useUploadsController = () => {
  const { organizationSelectedId } = useAppSelector(({ auth }) => auth);
  const { goToRoute } = useRouter();
  const modalRef = useRef<IModalRefProps>(null);

  const columns = useMemo(() => {
    return makeColumns({
      onPressViewButton: (data: IImportFile) => {
        if (data.importFileType === EImportFileType.ActivityDefinition) {
          goToRoute(`${PAGES.UPLOADS_ACTIVITIES}/${data._id}`);
          return;
        }
        goToRoute(`${PAGES.UPLOADS_PLACES}/${data._id}`);
      },
    });
  }, []);

  const newActivitiesUpload = () => {
    goToRoute(PAGES.UPLOADS_ACTIVITIES);
  };

  const newPlacesUpload = () => {
    goToRoute(PAGES.UPLOADS_PLACES);
  };

  const openModalNewFileActivity = () => {
    modalRef.current?.open();
  };

  const loadData = useCallback(
    async (skip: number, limit: number, filter: IFilterValue | null) => {
      const defaultValue = { data: [], count: 0 };
      try {
        if (!organizationSelectedId) return defaultValue;
        const { data, count } = await ImportFilesServices.getImportedFiles({
          limit,
          skip,
          filter: JSON.stringify({
            organization: organizationSelectedId,
            ...filter,
          }),
        });

        return { data, count };
      } catch (error) {
        toast.error(IMPORT_FILE_MESSAGES.LOAD_IMPORTED_FILES_ERROR);
        return defaultValue;
      }
    },
    [organizationSelectedId],
  );

  return {
    columns,
    newActivitiesUpload,
    newPlacesUpload,
    openModalNewFileActivity,
    loadData,
    modalRef,
  };
};
