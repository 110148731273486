import { useQuery } from '~/hooks/useQuery';

import { ActivityTypeEnum } from '../../../../enums';

export const useLoadMemberApplicationsReportByType = (
  userProfileId: string,
  activityType: ActivityTypeEnum,
) => {
  const { data, isLoading, isRefetching } = useQuery<{ count: number }>(
    `activity-application-insights/member/${userProfileId}/count`,
    {
      requestOptions: {
        params: {
          activityType: activityType as ActivityTypeEnum,
        },
      },
      key: `user-profile-activities-report-${userProfileId}-count-activity-type=${activityType}`,
    },
  );

  return { data, isLoading, isRefetching };
};
