export const fileContainsString = (
  file: File,
  searchString: string,
): Promise<boolean> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.onload = function (e) {
      const content = e.target?.result;
      if (typeof content === 'string') {
        if (content.includes(searchString)) {
          resolve(true);
        } else {
          resolve(false);
        }
      }
    };

    reader.onerror = function (e) {
      reject('Error reading file');
    };

    reader.readAsText(file);
  });
};
