import { Stack, Text } from 'native-base';

import Button from '../../../Button';
import { IActionsProps } from './types';
import { useFormActionController } from './useFormActionController';

const FormAction = (props: IActionsProps) => {
  const {
    items,
    optionsMemo,
    handleSubmitForm,
    isValid,
    isLoadingSubmit,
    isSubmitting,
  } = useFormActionController(props);
  if (!items?.length) {
    return null;
  }

  return (
    <Stack
      my={4}
      w="100%"
      flexDirection={optionsMemo?.direction}
      justifyContent={optionsMemo.justifyContent}
      alignItems={optionsMemo.alignItems}
      space={3}
    >
      {items?.map((button) => (
        <Stack flex={1} key={button.id} ml={4}>
          <Button
            onPress={
              button?.isSubmitButton ? handleSubmitForm : button?.onClick
            }
            isDisabled={button?.isSubmitButton ? !isValid : false}
            isLoading={
              button?.isSubmitButton ? isSubmitting || isLoadingSubmit : false
            }
            {...button.buttonProps}
            flex={1}
          >
            <Text {...button.textProps}>{button.text}</Text>
          </Button>
        </Stack>
      )) || null}
    </Stack>
  );
};

export default FormAction;
