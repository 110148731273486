import { Stack, Text } from 'native-base';
import Modal from 'ui/components/Modals/Modal';

import { AdminInvitesControllerAttributes } from '~/pages/Authenticated/Settings/AdminsSettings/types';
import { colors } from '~/theme/colors';

export const DeleteInviteModal = ({
  modalDeleteRef,
  onDeleteInvite,
  isResendingOrDeleting,
  closeDeleteInviteModal,
}: Pick<
  AdminInvitesControllerAttributes,
  | 'modalDeleteRef'
  | 'onDeleteInvite'
  | 'isResendingOrDeleting'
  | 'closeDeleteInviteModal'
>) => (
  <Modal
    ref={modalDeleteRef}
    rightBtnText="Delete Invite"
    rightBtnAction={onDeleteInvite}
    rightBtnLoading={isResendingOrDeleting}
    rightBtnBgColor={colors.error[600]}
    leftBtnText="Cancel"
    leftBtnAction={closeDeleteInviteModal}
    onClose={closeDeleteInviteModal}
    size="lg"
  >
    <Stack space="5">
      <Text fontWeight={500} fontSize="lg">
        Delete invitation
      </Text>
      <Text>
        Do you want to delete the invitation? This user will no longer be able
        to join as an admin until you send a new invite.
      </Text>
    </Stack>
  </Modal>
);
