import { HStack, Stack, Text } from 'native-base';
import React from 'react';
import Button from 'ui/components/Button';
import Icon from 'ui/components/Icon';

import { colors } from '~/theme/colors';

import { PageHeaderProps } from './types';

/* Add more customization as needed */
const PageHeader = ({
  title,
  action,
  actionLabel,
  actionIcon = 'plus-circle',
  actionsIsDisabled,
  actionsIsLoading,
  titleIcon,
  titleIconSize = 32,
  titleIconColor = colors.gray[500],
  ...restProps
}: PageHeaderProps) => {
  return (
    <Stack {...restProps}>
      <HStack justifyContent="space-between" alignItems="center">
        <HStack space={3} maxWidth="100%">
          {!!titleIcon && (
            <Icon
              icon={titleIcon}
              size={titleIconSize}
              color={titleIconColor}
            />
          )}
          {typeof title === 'string' ? (
            <Text color="gray.500" fontSize="2xl" fontWeight="700">
              {title}
            </Text>
          ) : (
            title
          )}
        </HStack>

        {action ? (
          <Stack>
            <Button
              leftIconName={actionIcon}
              leftIconSize="22"
              h="44px"
              px={5}
              isDisabled={actionsIsDisabled}
              isLoading={actionsIsLoading}
              onPress={action}
              bg="primary.400"
            >
              <Text fontSize="md" fontWeight="500" color="white" ml={1}>
                {actionLabel}
              </Text>
            </Button>
          </Stack>
        ) : null}
      </HStack>
    </Stack>
  );
};

export default PageHeader;
