import { useQuery } from '~/hooks/useQuery';

export const useLoadEcosystemApplicationsAttendanceReport = (
  ecosystem: string,
  activityDefinition?: string,
) => {
  const params: Partial<Record<'activityDefinitionId', string>> = {};

  if (activityDefinition) {
    params['activityDefinitionId'] = activityDefinition;
  }

  const { data, isLoading, isRefetching } = useQuery<{
    count: number;
  }>(`activity-application-insights/ecosystem/${ecosystem}/attendance/count`, {
    requestOptions: {
      params,
    },
    queryOptions: {
      enabled: !!ecosystem,
    },
    key: `ecosystem-activities-report-${ecosystem}-count-attendance-${activityDefinition}`,
  });

  return { data, isLoading, isRefetching };
};
