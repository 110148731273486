import { FieldSchema } from 'csv-file-validator';

export const csvActivityHeadersValidator: FieldSchema[] = [
  { required: true, name: 'identifier', inputName: 'identifier' },
  { required: true, name: 'type', inputName: 'type' },
  { required: true, name: 'title', inputName: 'title' },
  { required: true, name: 'description', inputName: 'description' },
  { required: true, name: 'causes', inputName: 'causes' },

  { required: true, name: 'requirements', inputName: 'requirements' },
  { required: true, name: 'volunteer_offer', inputName: 'volunteer_offer' },
  {
    required: true,
    name: 'volunteer_receive',
    inputName: 'volunteer_receive',
  },
  { required: true, name: 'cover_image_url', inputName: 'cover_image_url' },
  {
    required: true,
    name: 'location_option',
    inputName: 'location_option',
  },
  { required: true, name: 'space', inputName: 'space' },
  { required: true, name: 'event_type', inputName: 'event_type' },
  {
    required: true,
    name: 'event_application_type',
    inputName: 'event_application_type',
  },
  {
    required: true,
    name: 'is_approval_required',
    inputName: 'is_approval_required',
  },
  { required: true, name: 'measurement_unit', inputName: 'measurement_unit' },
  { required: true, name: 'target_amount', inputName: 'target_amount' },
];

export const legacyCsvActivityHeadersValidator: FieldSchema[] = [
  { required: true, name: 'identifier', inputName: 'identifier' },
  { required: true, name: 'type', inputName: 'type' },
  { required: true, name: 'title', inputName: 'title' },
  { required: true, name: 'description', inputName: 'description' },
  { required: true, name: 'causes', inputName: 'causes' },

  { required: true, name: 'requirements', inputName: 'requirements' },
  { required: true, name: 'volunteer_offer', inputName: 'volunteer_offer' },
  {
    required: true,
    name: 'volunteer_receive',
    inputName: 'volunteer_receive',
  },
  { required: true, name: 'cover_image_url', inputName: 'cover_image_url' },
  {
    required: true,
    name: 'type_of_attendance',
    inputName: 'type_of_attendance',
  },
  { required: true, name: 'space', inputName: 'space' },
  { required: true, name: 'work', inputName: 'work' },
  { required: true, name: 'event_type', inputName: 'event_type' },
  {
    required: true,
    name: 'event_application_type',
    inputName: 'event_application_type',
  },
  {
    required: true,
    name: 'is_approval_required',
    inputName: 'is_approval_required',
  },
  { required: true, name: 'measurement_unit', inputName: 'measurement_unit' },
  { required: true, name: 'target_amount', inputName: 'target_amount' },
];

export const ACTIVITY_TEMPLATE_S3_URL =
  'https://s3.eu-west-2.amazonaws.com/doit2.0-production-static/TEMPLATE_ACTIVITY.tsv';

export const ACTIVITY_TEMPLATE_GOOGLE_SHEET_URL =
  'https://docs.google.com/spreadsheets/d/1WZHCqF-MVYwjfUPpLWPj-zAUv44HAruzYnfjot5HIQk/edit#gid=278195267';
