import { api } from '~/services/api';

import { FeaturedActivityDefinitionCreateDTO } from './types';
const MODULE_URL = 'org-admin/featured-activity-definitions';

export default class FeaturedActivityDefinitionService {
  static async create(data: FeaturedActivityDefinitionCreateDTO) {
    const body = { ...data };
    return await api.post(`${MODULE_URL}`, body);
  }
}
