import { Box, Flex, HStack, Progress, Stack, Text } from 'native-base';
import Button from 'ui/components/Button';
import Illustration, { ILLUSTRATIONS_NAME } from 'ui/components/Illustration';
import Input from 'ui/components/Input';
import { colors } from 'ui/theme/colors';

import { HelpCentreRedirectLink } from '~/components/HelpCentre';
import { styles } from '~/pages/Unauthenticated/SignUpPage/ConfirmEmailPage/styles';

import { useConfirmEmailController } from './controller';
const ConfirmEmailPage = () => {
  const {
    control,
    disableVerifyCode,
    email,
    errors,
    isSubmitting,
    isSubmittingResendCode,
    isResendCodeDisabled,
    coolDownTime,
    onVerifyCode,
    handleResendCode,
  } = useConfirmEmailController();

  return (
    <Flex minH="100vh" justifyContent="center" bg="gray.100">
      <HStack
        alignItems="center"
        space={20}
        w="min(90%, 1300px)"
        mx="auto"
        flexDirection={styles.flexDirection}
      >
        <Box w="50%">
          <Illustration
            resizeMode="contain"
            w="100%"
            height="500px"
            name={ILLUSTRATIONS_NAME.NEW_EMAIL_MESSAGE}
            alt="PasswordRecovery"
          />
        </Box>
        <Box flex="1">
          <Box w="75%">
            <Stack paddingTop={6}>
              <HelpCentreRedirectLink />
            </Stack>
            <Box w="40%">
              <Progress value={30} mb="6" />
            </Box>
            <Text
              textAlign="initial"
              fontSize="3xl"
              fontWeight={500}
              color="gray.900"
              maxWidth="460px"
            >
              Enter the code we sent you to confirm your email address.
            </Text>
            <Text mt="3" color="gray.500">
              We sent a code to{' '}
              <Text color="primary.400" fontWeight="500">
                {email}
              </Text>
            </Text>

            <Stack width="100%" mt="4">
              <Input
                name="accessCode"
                control={control}
                placeholder="Type the code we sent to you"
                leftIconName="key"
                keyboardType="numeric"
                leftIconColor={colors.muted[400]}
                label="Code"
                errorMessage={errors.accessCode?.message}
                iconErrorMessage="info"
                p={4}
                isFullWidth
                borderColor="muted.200"
              />
              <Box mb={4} />
            </Stack>
            <Stack mt={4} mb={3}>
              <Button
                onPress={onVerifyCode}
                p={6}
                isDisabled={disableVerifyCode}
                variant="solid"
                isLoading={isSubmitting}
              >
                <Text fontSize="medium" color="white" fontWeight="medium">
                  Confirm email address
                </Text>
              </Button>
            </Stack>

            <Stack mb={6}>
              <Button
                onPress={handleResendCode}
                p={6}
                variant="ghost"
                isDisabled={isSubmittingResendCode || isResendCodeDisabled}
              >
                <Text fontSize="medium" fontWeight="medium">
                  {isResendCodeDisabled ? coolDownTime : 'Resend code'}
                </Text>
              </Button>
            </Stack>
          </Box>
        </Box>
      </HStack>
    </Flex>
  );
};

export default ConfirmEmailPage;
