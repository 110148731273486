import { useState } from 'react';

import { ImageGalleryProps } from './types';

const useImageGalleryController = ({
  coverImage,
  images,
  coverImageLabel = 'Cover Image',
  extraImagesLabel = 'Extra Images',
  imageProps,
}: ImageGalleryProps) => {
  const [currentImage, setCurrentImage] = useState<string | null>(coverImage);

  const handleChangeCurrentImg = (imgPath: string) => {
    setCurrentImage(imgPath);
  };

  return {
    coverImageLabel,
    extraImagesLabel,
    currentImage,
    images,
    imageProps,
    handleChangeCurrentImg,
  };
};

export default useImageGalleryController;
