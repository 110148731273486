import { Divider, HStack, Image, Stack, Text } from 'native-base';
import { toast } from 'react-toastify';
import Button from 'ui/components/Button';
import Icon from 'ui/components/Icon';

import { colors } from '~/theme/colors';
import { IOrganization } from '~/types/interfaces/organization';

import { UserProfilePopulated } from '../../../../../../types/interfaces/userProfile';
import { styles } from './styles';

export const MemberDetails = ({
  member,
  organization,
}: {
  member?: UserProfilePopulated;
  organization?: IOrganization;
}) => {
  return (
    <Stack
      bgColor="white"
      borderRadius="3xl"
      width="100%"
      space={5}
      padding={6}
      minHeight="80vh"
    >
      <HStack justifyContent="space-between" space={10}>
        <Stack space={2} width="46%">
          <Text fontSize="lg" fontWeight={500} color="gray.600">
            Member details
          </Text>
          <Divider />

          {/* <Stack space={2} mt={6}>
            <Text fontSize="sm" fontWeight={500} color="gray.600">
              Member Type
            </Text>
            <Text fontSize="sm" fontWeight={400} color="darkText">
              Org member
            </Text>
          </Stack> */}
          <Stack space={2} mt={6}>
            <Text fontSize="sm" fontWeight={500} color="gray.600">
              Organisation
            </Text>
            <HStack space={3} alignItems="center">
              <Image
                src={member?.organization?.logo}
                height="24px"
                width="24px"
                borderRadius="md"
              />
              <Text fontSize="sm" fontWeight={400} color="darkText">
                {member?.organization?.name}
              </Text>
            </HStack>
            {organization?.organizationSettings?.hasExternalId &&
            member?.externalId ? (
              <Stack space={2} mt={6}>
                <Text fontSize="sm" fontWeight={500} color="gray.600">
                  External ID
                </Text>
                <Text>{member?.externalId || '-'}</Text>
              </Stack>
            ) : null}
          </Stack>
        </Stack>
        <Stack space={2} width="46%">
          <Text fontSize="lg" fontWeight={500} color="gray.600">
            Contact information
          </Text>
          <Divider />

          <HStack
            maxW="352px"
            width="100%"
            justifyContent="space-between"
            mt={6}
            alignItems="center"
          >
            <Stack space={2}>
              <Text fontSize="sm" fontWeight={500} color="gray.600">
                Email address
              </Text>
              <HStack space={2} alignItems="center">
                <Icon icon="mail" color={colors.gray[400]} size={16} />
                <Text fontSize="sm" fontWeight={400} color="darkText">
                  {member?.user?.email}
                </Text>
              </HStack>
            </Stack>
            <Button
              leftIconName="copy"
              leftIconColor={colors.primary[600]}
              leftIconSize={16}
              maxWidth="75px"
              minWidth="75px"
              bgColor="primary.50"
              _text={styles.buttonCopy}
              onPress={() => toast.success('Email copied to clipboard')}
            >
              Copy
            </Button>
          </HStack>

          <HStack
            maxW="352px"
            width="100%"
            justifyContent="space-between"
            mt={6}
            alignItems="center"
          >
            <Stack space={2}>
              <Text fontSize="sm" fontWeight={500} color="gray.600">
                Phone number
              </Text>
              <HStack space={2} alignItems="center">
                <Icon icon="phone" color={colors.gray[400]} size={16} />
                <Text fontSize="sm" fontWeight={400} color="darkText">
                  {member?.user?.phoneNumber}
                </Text>
              </HStack>
            </Stack>
            <Button
              leftIconName="copy"
              leftIconColor={colors.primary[600]}
              leftIconSize={16}
              maxWidth="75px"
              minWidth="75px"
              bgColor="primary.50"
              _text={styles.buttonCopy}
              onPress={() => toast.success('Phone copied to clipboard')}
            >
              Copy
            </Button>
          </HStack>
        </Stack>
      </HStack>
    </Stack>
  );
};
