import { Avatar, Box, HStack, Image, Text, VStack } from 'native-base';
import { useMemo } from 'react';
import { formatDateToTimeCountDown } from 'ui/utils/formatDates';
import { getAvatarText } from 'ui/utils/getAvatarText';

import { NotificationCardBadge } from './components/NotificationCardBadge';
import { NotificationTitleNode } from './components/NotificationTitleNode';
import { NotificationCardProps } from './types';

export const NotificationCard = ({
  date,
  badge,
  children,
  titleNodes,
  authorImageUrl = '',
}: NotificationCardProps) => {
  const dateStr = useMemo(() => {
    if (!date) return '';
    return formatDateToTimeCountDown(date);
  }, [date]);

  const authorName = (titleNodes ?? [])[0]?.text || '';

  return (
    <VStack
      w="full"
      borderWidth={1}
      borderRadius={8}
      overflow={'hidden'}
      borderColor={'gray.200'}
      backgroundColor={'white'}
      space={4}
      p={4}
    >
      {/* Top Content*/}
      {badge && (
        <VStack>
          <NotificationCardBadge {...badge} />
        </VStack>
      )}

      {/* Main */}
      <VStack space={3}>
        {/* Header */}
        <HStack
          alignItems={'center'}
          space="10px"
          justifyContent={'flex-start'}
        >
          <Box
            backgroundColor={'gray.400'}
            w="40px"
            h="40px"
            borderRadius="100%"
            overflow={'hidden'}
          >
            <Avatar
              h="full"
              w="full"
              bgColor="muted.100"
              _text={{ color: 'darkText' }}
              source={{ uri: authorImageUrl || '' }}
              _image={{ borderRadius: 0 }}
              borderRadius={0}
            >
              {getAvatarText(authorName)}
            </Avatar>
          </Box>

          <VStack space={1} flex={1}>
            {/* Title */}
            <HStack space={2} style={{ rowGap: 2 }} flexWrap={'wrap'}>
              {titleNodes.map((node, i) => {
                return <NotificationTitleNode key={i} {...node} />;
              })}
            </HStack>

            <Text
              color={'gray.600'}
              numberOfLines={1}
              fontSize={'12px'}
              fontWeight={400}
            >
              {dateStr}
            </Text>
          </VStack>
        </HStack>

        {/* Body */}
        {children && <VStack> {children} </VStack>}
      </VStack>
    </VStack>
  );
};
