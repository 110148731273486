import { Avatar, Text, VStack } from 'native-base';
import React from 'react';
import Modal from 'ui/components/Modals/Modal';

import { EcosystemForm } from '~/components/EcosystemForm';
import Scaffold from '~/components/Scaffold';

import { useCreateEcosystemController } from './controller';

export const CreateEcosystem: React.FC = () => {
  const {
    modalRef,
    newEcosystem,
    handleRightBtnAction,
    handleCloseModal,
    handleOnSubmit,
  } = useCreateEcosystemController();

  return (
    <Scaffold title="New ecosystem" alignItemsChildren="center">
      <EcosystemForm onSubmit={handleOnSubmit} />

      <Modal
        ref={modalRef}
        headerText="You created a new ecosystem!"
        leftBtnText="Back to Ecosystems"
        rightBtnText="Got it"
        modalContentMaxW="xl"
        onClose={handleCloseModal}
        leftBtnAction={handleCloseModal}
        rightBtnAction={handleRightBtnAction}
      >
        <VStack space="6">
          <Text color="gray.500">
            You have the flexibility to edit the ecosystem at any time, allowing
            you to modify its informations and manage the apps as you see fit.
          </Text>
          <VStack alignItems="center" space="2">
            <Avatar
              width="24"
              height="24"
              source={{ uri: newEcosystem?.logo }}
            />
            <Text fontSize="xl" fontWeight={700} color="gray.600">
              {newEcosystem?.name}
            </Text>
          </VStack>
        </VStack>
      </Modal>
    </Scaffold>
  );
};
