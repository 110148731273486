export const membersTabsElements = [
  {
    title: 'Members',
    component: () => <></>,
  },
  {
    title: 'Partners',
    component: () => <></>,
  },
  {
    title: 'Child Org',
    component: () => <></>,
  },
  {
    title: 'Pending invites',
    component: () => <></>,
  },
];
