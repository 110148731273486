import { useEffect, useMemo, useRef, useState } from 'react';
import { Control, useFieldArray } from 'react-hook-form';
import { useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { IModalRefProps } from 'ui/components/Modals/Modal/types';

import { ICreateEcosystemForm } from '~/components/EcosystemForm/types';
import { useAppSelector } from '~/hooks/useAppSelector';
import { useLoadAllAppSharingsByOrganization } from '~/hooks/useLoadAllAppSharingsByOrganization';
import { useLoadAppsConnectedToEcosystem } from '~/hooks/useLoadAppsConnectedToEcosystem';
import { useLoadPublicApps } from '~/hooks/useLoadPublicApps';
import { useRouter } from '~/hooks/useRouter';
import { EcosystemService } from '~/services/resources/ecosystem';
import { App } from '~/types/interfaces/app';

const DEBOUNCE_TIME = 500;

interface AppToDelete {
  id: string;
  index: number;
}

let debounceTimeout: NodeJS.Timeout | null;

export const useAppsFormController = (
  control: Control<ICreateEcosystemForm>,
) => {
  const { organizationSelectedId } = useAppSelector(({ auth }) => auth);
  const [searchParams] = useSearchParams();
  const {
    params: { id: ecosystemId },
  } = useRouter();

  // States
  const modalRef = useRef<IModalRefProps>(null);
  const removeMyAppModalRef = useRef<IModalRefProps>(null);
  const [pickedApps, setPickedApps] = useState<App[]>([]);
  const [appToDelete, setAppToDelete] = useState<AppToDelete | null>(null);
  const [isRemovingApp, setIsRemovingApp] = useState(false);
  const [searchText, setSearchText] = useState<string | undefined>();
  const [currentTab, setCurrentTab] = useState(0);

  // Hooks
  useEffect(() => {
    const action = searchParams.get('a');
    if (!action && action === 'add-app') return;

    modalRef.current?.open();
  }, [searchParams]);

  const {
    fields: apps,
    replace,
    remove,
  } = useFieldArray({ control, name: 'apps' });

  const {
    fields: myAppSharings,
    replace: replaceMyAppSharings,
    remove: removeMyAppSharings,
  } = useFieldArray({ control, name: 'myAppSharings' });

  const {
    appsConnectedToOrganization,
    isLoadingAppsConnectedToOrganization,
    refetchAppsConnectedToOrganization,
  } = useLoadAllAppSharingsByOrganization(organizationSelectedId);

  const uniqueAppIdKeys = useMemo(() => new Set(), []);

  const { connectedApps, isLoadingConnectedApps, isRefetchingConnectedApps } =
    useLoadAppsConnectedToEcosystem(
      organizationSelectedId,
      ecosystemId,
      searchText,
    );
  const ecosystemAppsConnected = useMemo(() => {
    return (
      appsConnectedToOrganization?.filter(
        (appConnectedToOrganization) =>
          appConnectedToOrganization.receiverEcosystem._id === ecosystemId,
      ) || []
    );
  }, [appsConnectedToOrganization, ecosystemId]);

  const uniqueAppsConnectedToOrganization = useMemo(() => {
    return (
      appsConnectedToOrganization?.filter((item) => {
        const findAppConnected = ecosystemAppsConnected.find(
          (appConnectedToOrganization) =>
            appConnectedToOrganization.app._id === item.app._id,
        );

        if (!findAppConnected) {
          const isUnique = !uniqueAppIdKeys.has(item.app._id);
          uniqueAppIdKeys.add(item.app._id);
          return isUnique;
        }
        return false;
      }) || []
    );
  }, [uniqueAppIdKeys, appsConnectedToOrganization, ecosystemAppsConnected]);

  const { publicApps, isLoadingPublicApps, isRefetchingPublicApps } =
    useLoadPublicApps(organizationSelectedId, ecosystemId, searchText);

  const handleToggleModal = () => modalRef.current?.open();

  const handleOnGetApp = (app: App) =>
    setPickedApps((current) => [...current, { ...app, isEnabled: true }]);

  const handleOnCloseModalPickedApps = () => {
    setPickedApps([]);
  };

  const handleOnRemoveApp = (index: number) => {
    remove(index);
    pickedApps.splice(index, 1);
  };

  const handleOpenRemoveMyAppModal = (index: number, id?: string) => {
    if (!id) return;
    setAppToDelete({ id, index });
    removeMyAppModalRef.current?.open();
  };

  const handleCloseRemoveMyAppModal = () => {
    removeMyAppModalRef.current?.close();
  };

  const handleOnRemoveMyAppSharing = async () => {
    try {
      if (!appToDelete?.id || !ecosystemId) return;
      setIsRemovingApp(true);
      await EcosystemService.disconnectAppsFromEcosystem({
        receiverEcosystem: ecosystemId,
        appSharingIds: [appToDelete.id],
      });
      removeMyAppSharings(appToDelete.index);
      refetchAppsConnectedToOrganization();
      toast.success('App removed with success.');
    } catch (error) {
      toast.error('Error on trying to remove app.');
    } finally {
      setIsRemovingApp(false);
      handleCloseRemoveMyAppModal();
    }
  };

  const handleOnDone = () => {
    replace(pickedApps);
    if (pickedApps.length !== apps.length) {
      toast.success('Apps added successfully.');
    }
  };

  const onChangeSwitchMyApps = (appId: string, value: boolean) => {
    const appToChange = myAppSharings.find((app) => app._id === appId);
    if (appToChange) {
      appToChange.isEnabled = value;
      replaceMyAppSharings(myAppSharings);
    }
  };

  const handleOnChangeText = (searchText: string) => {
    if (debounceTimeout) {
      clearTimeout(debounceTimeout);
    }

    debounceTimeout = setTimeout(() => {
      setSearchText(searchText);
      debounceTimeout = null;
    }, DEBOUNCE_TIME);
  };

  const onChangeTab = (tabIndex: number) => {
    setCurrentTab(tabIndex);
  };

  const isLoading = useMemo(
    () =>
      isLoadingPublicApps ||
      isLoadingConnectedApps ||
      isLoadingAppsConnectedToOrganization ||
      isRefetchingConnectedApps ||
      isRefetchingPublicApps,

    [
      isLoadingPublicApps,
      isLoadingConnectedApps,
      isLoadingAppsConnectedToOrganization,
      isRefetchingConnectedApps,
      isRefetchingPublicApps,
    ],
  );

  useEffect(() => {
    replaceMyAppSharings(connectedApps || []);
  }, [connectedApps, replaceMyAppSharings]);

  return {
    isLoading,
    publicApps,
    pickedApps,
    apps,
    myAppSharings,
    uniqueAppsConnectedToOrganization: [
      ...uniqueAppsConnectedToOrganization,
      ...ecosystemAppsConnected,
    ],
    modalRef,
    removeMyAppModalRef,
    isRemovingApp,
    ecosystemId,
    searchText,
    currentTab,
    onChangeTab,
    onChangeSwitchMyApps,
    handleOnGetApp,
    handleToggleModal,
    handleOnRemoveApp,
    handleOnDone,
    handleCloseRemoveMyAppModal,
    handleOpenRemoveMyAppModal,
    handleOnRemoveMyAppSharing,
    handleOnChangeText,
    handleOnCloseModalPickedApps,
  };
};
