import { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { FeedNotificationTargetType } from 'ui/types/interfaces/feed-notification';

import { PAGES } from '~/constants/pages.constants';
import { OrganizationType } from '~/enums';
import { useFeedNotificationsInfinityLoad } from '~/hooks/feed-notification/useFeedNotificationsInfinityLoad';
import { useLoadFeedNotificationCount } from '~/hooks/feed-notification/useLoadFeedNotificationCount';
import { useCurrentOrganization } from '~/hooks/organization/useCurrentOrganization';
import { useAppSelector } from '~/hooks/useAppSelector';
import { UpdatesFeedCategoryToNotificationTypeMap } from '~/pages/Authenticated/Dashboard/components/UpdatesFeed/constants';
import {
  UpdatesFeedCategory,
  UpdatesFeedProps,
} from '~/pages/Authenticated/Dashboard/components/UpdatesFeed/types';

export const useUpdatesFeedController = ({ apps = [] }: UpdatesFeedProps) => {
  const appsIds = apps?.map((a) => a._id);
  const { selectedEcosystem } = useAppSelector(({ ecosystem }) => ecosystem);
  const { currentOrganization } = useCurrentOrganization();
  const navigate = useNavigate();
  // States
  const [enableCount, setEnableCount] = useState<boolean>(true);
  const [selectedApps, setSelectedApps] = useState(appsIds);
  const [selectedCategory, setSelectedCategory] = useState(
    UpdatesFeedCategory.AllUpdates,
  );

  // Hooks
  const filter = useMemo(() => {
    const { types } =
      UpdatesFeedCategoryToNotificationTypeMap[selectedCategory];

    const notificationTargets = [FeedNotificationTargetType.All];

    if (currentOrganization?.type === OrganizationType.COMPANY) {
      notificationTargets.push(FeedNotificationTargetType.Company);
    }

    return {
      types,
      appIds: selectedApps,
      organizationId: currentOrganization?._id || '',
      notificationTargets,
    };
  }, [selectedCategory, selectedApps, currentOrganization]);

  const {
    data: feedUpdates,
    isLoading,
    isRefetching,
    hasNextPage,
    isFetchingNextPage,
    dataUpdatedAt,
    remove: resetData,
    refetch,
    fetchNextPage,
  } = useFeedNotificationsInfinityLoad(filter, !!selectedEcosystem?._id);

  const { data: count, remove: resetCount } = useLoadFeedNotificationCount(
    {
      ...filter,
      startDate: dataUpdatedAt ? new Date(dataUpdatedAt) : undefined,
    },
    enableCount && !!dataUpdatedAt,
  );

  useEffect(() => {
    if (count == 0) return;
    setEnableCount(false);
  }, [count]);

  // Handlers
  const handleSelectNewApp = (newApp?: string) => {
    if (!newApp) {
      setSelectedApps(appsIds);
      return;
    }
    setSelectedApps([newApp]);
  };

  const handleCategoryChange = (newCategory: UpdatesFeedCategory) => {
    setSelectedCategory(newCategory);
  };

  const handlerInviteNewMember = () => {
    navigate(PAGES.ADD_ORG_MEMBER);
  };

  const handleRefetchData = () => {
    setEnableCount(true);
    resetCount();
    resetData();
    refetch();
  };

  return {
    apps,
    count,
    isLoading,
    hasNextPage,
    selectedApps,
    feedUpdates,
    isRefetching,
    selectedCategory,
    isFetchingNextPage,
    fetchNextPage,
    handleSelectNewApp,
    handleCategoryChange,
    handlerInviteNewMember,
    handleRefetchData,
  };
};
