import { useEffect } from 'react';

import { useAppDispatch } from '~/hooks/useAppDispatch';
import { useAppSelector } from '~/hooks/useAppSelector';
import { notificationSliceActions } from '~/store/slices/notification';

export const useAuthLayoutController = () => {
  const { isNotificationOpen, content } = useAppSelector(
    (state) => state.notifications,
  );

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (isNotificationOpen) {
      const notificationTimeOut = setTimeout(() => {
        dispatch(notificationSliceActions.reset());
      }, 3000);
      return () => clearTimeout(notificationTimeOut);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isNotificationOpen]);

  return { isNotificationOpen, content };
};
