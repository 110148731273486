import { useCallback, useRef, useState } from 'react';
import { toast } from 'react-toastify';
import { IFilterValue } from 'ui/components/DataTable/types';
import { IModalRefProps } from 'ui/components/Modals/Modal/types';

import { ActivityTypeEnum } from '~/enums';

import { COMMON_MESSAGES } from '../../../../../../constants/messages.constants';
import { useLoadMemberApplicationsReportByType } from '../../../../../../hooks/reports/members/useLoadMemberApplicationsReportByType';
import { useLoadMemberLogHoursReportByType } from '../../../../../../hooks/reports/members/useLoadMemberLogHoursReportByType';
import { useLoadMemberLogHoursTotalReport } from '../../../../../../hooks/reports/members/useLoadMemberLogHoursTotalReport';
import ActivityApplicationService from '../../../../../../services/resources/activityApplication';
import { UserProfilePopulated } from '../../../../../../types/interfaces/userProfile';

export const useMemberActivitiesController = (member: UserProfilePopulated) => {
  const actionReportModalRef = useRef<IModalRefProps>(null);

  const [applicationsCount, setApplicationsCount] = useState<number>(0);

  const {
    data: eventApplicationsCount,
    isLoading: isEventApplicationsCountLoading,
  } = useLoadMemberApplicationsReportByType(member._id, ActivityTypeEnum.Event);

  const {
    data: actionApplicationsCount,
    isLoading: isActionApplicationsCountLoading,
  } = useLoadMemberApplicationsReportByType(
    member._id,
    ActivityTypeEnum.Action,
  );

  const {
    data: ongoingApplicationsCount,
    isLoading: isOngoingApplicationsCountLoading,
  } = useLoadMemberApplicationsReportByType(
    member._id,
    ActivityTypeEnum.OngoingOpportunity,
  );

  const { data: eventLogHoursCount, isLoading: isEventLogHoursCountLoading } =
    useLoadMemberLogHoursReportByType(member._id, ActivityTypeEnum.Event);

  const { data: actionLogHoursCount, isLoading: isActionLogHoursCountLoading } =
    useLoadMemberLogHoursReportByType(member._id, ActivityTypeEnum.Action);

  const {
    data: ongoingLogHoursCount,
    isLoading: isOngoingLogHoursCountLoading,
  } = useLoadMemberLogHoursReportByType(
    member._id,
    ActivityTypeEnum.OngoingOpportunity,
  );

  const { data: totalLogHoursCount, isLoading: isTotalLogHoursCountLoading } =
    useLoadMemberLogHoursTotalReport(member._id);

  const [isLoadingApplications, setIsLoadingApplications] =
    useState<boolean>(false);

  const loadApplications = useCallback(
    async (skip: number, limit: number, filter: IFilterValue | null) => {
      try {
        setIsLoadingApplications(true);
        const { data: responseActivityApplicationData } =
          await ActivityApplicationService.findAll({
            filter: JSON.stringify({
              ...filter,
              userProfile: member._id,
            }),
            skip,
            limit,
            sort: JSON.stringify({
              applicationDate: -1,
            }),
          });

        setApplicationsCount(responseActivityApplicationData?.count);

        return {
          data: responseActivityApplicationData?.data,
          count: responseActivityApplicationData?.count,
        };
      } catch (error) {
        toast.error(COMMON_MESSAGES.ERROR_FETCHING_APPLICATIONS, {
          position: 'bottom-center',
        });
        return {
          data: [],
          count: 0,
        };
      } finally {
        setIsLoadingApplications(false);
      }
    },
    [],
  );

  const openModal = () => {
    actionReportModalRef.current?.open();
  };

  return {
    actionReportModalRef,
    loadApplications,
    isLoadingApplications,
    openModal,
    isEventApplicationsCountLoading,
    eventApplicationsCount,
    isActionApplicationsCountLoading,
    actionApplicationsCount,
    isOngoingApplicationsCountLoading,
    ongoingApplicationsCount,
    isEventLogHoursCountLoading,
    eventLogHoursCount,
    isActionLogHoursCountLoading,
    actionLogHoursCount,
    isOngoingLogHoursCountLoading,
    ongoingLogHoursCount,
    totalLogHoursCount,
    isTotalLogHoursCountLoading,
    applicationsCount,
  };
};
