import { Stack, VStack } from 'native-base';
import React from 'react';
import { Navigate } from 'react-router-dom';
import Tabs from 'ui/components/Tabs';

import Scaffold from '~/components/Scaffold';
import { colors } from '~/theme/colors';

import BasicInformationForm from './BasicInformationForm';
import { useRegisterOrganisationController } from './controller';
import { DetailsForm } from './DetailsForm';
import InviteAdminForm from './InviteAdminForm';

const RegisterOrganisation: React.FC = () => {
  const {
    organisationTypes,
    causeSelectOptions,
    control,
    errors,
    isDisabledButton,
    tabsRef,
    isAuthenticated,
    currentTab,
    selectedLocation,
    isSubmitting,
    adminEmails,
    handleAddAdminEmail,
    handleRemoveAdminEmail,
    handleBack,
    handleOnSubmit,
    handleContinue,
    setSelectedLocation,
  } = useRegisterOrganisationController();

  if (!isAuthenticated) {
    return <Navigate to="/" />;
  }

  return (
    <Scaffold title="Register an organisation" alignItemsChildren="center">
      <VStack maxWidth="1250px" width="100%" alignItems="center">
        <Tabs
          ref={tabsRef}
          tabTextColor={colors.primary['600']}
          tabIndicatorColor={colors.primary['600']}
          tabfocusedTextColor={colors.primary['600']}
          swipeEnabled={false}
          elements={[
            {
              title: 'Basic information',
              component: () => <></>,
            },
            {
              title: 'Details',
              component: () => <></>,
            },
            {
              title: 'Admins',
              component: () => <></>,
            },
          ]}
        />
        <Stack w="100%" maxW="562px">
          {currentTab === 0 ? (
            <BasicInformationForm
              organisationTypes={organisationTypes}
              causeOptions={causeSelectOptions}
              control={control}
              errors={errors}
              isDisabledButton={isDisabledButton}
              tabsRef={tabsRef}
              handleBack={handleBack}
              handleContinue={handleContinue}
            />
          ) : currentTab === 1 ? (
            <DetailsForm
              errors={errors}
              control={control}
              isDisabledButton={isDisabledButton}
              tabsRef={tabsRef}
              selectedLocation={selectedLocation}
              handleContinue={handleContinue}
              handleBack={handleBack}
              onLocationChangeHandler={setSelectedLocation}
            />
          ) : currentTab === 2 ? (
            <InviteAdminForm
              errors={errors}
              control={control}
              isDisabledButton={isDisabledButton}
              tabsRef={tabsRef}
              isSubmitting={isSubmitting}
              adminEmails={adminEmails}
              onAddEmail={handleAddAdminEmail}
              onRemoveEmail={handleRemoveAdminEmail}
              handleBack={handleBack}
              onSubmit={handleOnSubmit}
            />
          ) : null}
        </Stack>
      </VStack>
    </Scaffold>
  );
};

export default RegisterOrganisation;
