import { Divider, Stack, Text } from 'native-base';

import { MemberTypeEnum } from '~/enums';
import { PermissionsFormCard } from '~/pages/Authenticated/AddOrgMember/components/Form/PermissionsFormSection/components/PermissionsFormCard';
import { colors } from '~/theme/colors';

import { PermissionsFormListProps } from './types';

export const PermissionsFormList = ({
  control,
  appsPermissionsGroupMemberOptions,
  appsPermissionsGroupPartnerOptions,
  memberType,
  index,
  app,
  selectedMemberPermissions,
  selectedPartnerPermissions,
  ecosystemId,
  handleChangeSelectedMemberPermission,
  handleChangeSelectedPartnerPermission,
}: PermissionsFormListProps) => {
  return (
    <Stack
      bgColor={colors.singletons.lightText}
      padding={4}
      borderRadius={12}
      borderColor="gray.200"
      borderWidth="1px"
      space={5}
    >
      <Stack space={2}>
        <Text fontWeight={500} fontSize="lg" color="gray.600">
          {app?.name || '-'}
        </Text>
        <Divider mb={8} />
      </Stack>

      {memberType === MemberTypeEnum.Partner ||
      memberType === MemberTypeEnum.ChildOrg ? (
        <PermissionsFormCard
          key={`permission-partner-${index}`}
          appsPermissionsGroupOptions={appsPermissionsGroupPartnerOptions(
            app?._id || '',
            ecosystemId,
          )}
          cardTitle="Partner"
          control={control}
          ecosystemId={ecosystemId}
          handleChangeSelectedPermission={handleChangeSelectedPartnerPermission}
          index={index}
          memberType={MemberTypeEnum.Partner}
          selectInputLabel="Permission group for Partners"
          selectInputName={`partnersPermissions[${index}]`}
          selectedPermissions={selectedPartnerPermissions}
          app={app}
        />
      ) : null}

      <PermissionsFormCard
        key={`permission-member-${index}`}
        appsPermissionsGroupOptions={appsPermissionsGroupMemberOptions(
          app?._id || '',
          ecosystemId,
        )}
        cardTitle="Member"
        control={control}
        ecosystemId={ecosystemId}
        handleChangeSelectedPermission={handleChangeSelectedMemberPermission}
        index={index}
        memberType={MemberTypeEnum.Member}
        selectInputLabel="Permission group for Members"
        selectInputName={`membersPermissions[${index}]`}
        selectedPermissions={selectedMemberPermissions}
        app={app}
      />
    </Stack>
  );
};
