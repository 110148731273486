import { Avatar, HStack, Stack, Text } from 'native-base';
import Button from 'ui/components/Button';
import { Tooltip } from 'ui/components/Tooltip';
import { InviteCodeVisibilityEnum } from 'ui/enums';
import { capitalize } from 'ui/utils/capitalize';
import { getNameInitials } from 'ui/utils/getNameInititals';

import { colors } from '~/theme/colors';

import { InviteDetailsProps } from './types';

export const InviteDetails = ({
  inviteDetails,
  copyInviteLink,
}: InviteDetailsProps) => {
  const renderApps = () => {
    return inviteDetails?.configurations?.flatMap((c) => {
      return c.appsData?.flatMap((app) => {
        if (!app?.appId?._id) return null;

        return (
          <Stack key={app?.appId?._id} space={2}>
            <HStack alignItems="center" space={2}>
              <Avatar
                source={{ uri: app?.appId?.logo }}
                width={6}
                height={6}
                rounded={6}
                _image={{ rounded: 6 }}
              >
                {getNameInitials(app?.appId?.name || '')}
              </Avatar>
              <Text fontSize="xs">{app?.appId?.name}</Text>
            </HStack>
          </Stack>
        );
      });
    });
  };

  const renderPermissions = () => {
    return inviteDetails?.configurations?.flatMap((c) =>
      c.appsData?.flatMap((app) => {
        console.log({ app });
        if (!app?.appId?._id || !app.appPermissionGroupsData?.length)
          return null;

        return app.appPermissionGroupsData.flatMap((p) => (
          <Text key={p?.appPermissionGroupId?._id} fontSize="xs">
            {p?.appPermissionGroupId?.name || 'Unnamed'}
            {' - '}
            {capitalize(p.appPermissionGroupId?.type || '')}
          </Text>
        ));
      }),
    );
  };

  return (
    <Stack mt={2}>
      <HStack justifyContent="space-between" width="100%" space={10}>
        <Stack space={5} width="50%">
          <Stack space={2}>
            <Text color="gray.500" fontWeight={500}>
              E-mail
            </Text>
            <Text fontSize="xs">{inviteDetails?.receiverEmail || '-'}</Text>
          </Stack>
          <Stack space={2}>
            <Text color="gray.500" fontWeight={500}>
              Ecosystems
            </Text>
            {inviteDetails?.configurations?.map((c) => (
              <Stack key={c.ecosystemId._id} space={2}>
                <HStack alignItems="center" space={2}>
                  <Avatar
                    source={{ uri: c.ecosystemId.logo }}
                    width={6}
                    height={6}
                    rounded={6}
                    _image={{ rounded: 6 }}
                  >
                    {getNameInitials(c.ecosystemId.name || '')}
                  </Avatar>
                  <Text fontSize="xs">{c.ecosystemId.name}</Text>
                </HStack>
              </Stack>
            ))}
          </Stack>
          <Stack space={2}>
            <Text color="gray.500" fontWeight={500}>
              Permissions
            </Text>
            {renderPermissions()}
          </Stack>
        </Stack>
        <Stack space={5} width="50%">
          <Stack space={2}>
            <Text color="gray.500" fontWeight={500}>
              Type
            </Text>
            <Text fontSize="xs">
              {inviteDetails?.visibility === InviteCodeVisibilityEnum.Public
                ? InviteCodeVisibilityEnum.Public
                : InviteCodeVisibilityEnum.Private}
            </Text>
          </Stack>
          <Stack space={2}>
            <Text color="gray.500" fontWeight={500}>
              Apps
            </Text>
            {renderApps()}
          </Stack>
        </Stack>
      </HStack>
      <HStack
        mt={10}
        width="100%"
        alignItems="center"
        justifyContent="space-between"
      >
        <Stack space={2}>
          <Text color="gray.500" fontWeight={500}>
            Invite link
          </Text>
          <Tooltip label={inviteDetails?.inviteURL || '-'}>
            <Text fontSize="xs" maxW="300px" noOfLines={1}>
              {inviteDetails?.inviteURL || '-'}
            </Text>
          </Tooltip>
        </Stack>
        <Stack space={2}>
          <Button
            leftIconName="copy"
            leftIconSize={16}
            leftIconColor={colors.primary[400]}
            variant="outline"
            width="80px"
            minWidth="80px"
            onPress={() => copyInviteLink(inviteDetails?.inviteURL || '')}
          >
            Copy
          </Button>
        </Stack>
      </HStack>
    </Stack>
  );
};
