import SelectFilter from '@inovua/reactdatagrid-community/SelectFilter';
import { Stack } from 'native-base';
import DataTableActions from 'ui/components/DataTableActions';
import { TableCellWithImage } from 'ui/components/TableCellWithImage';
import Tag from 'ui/components/Tags/Tag';

import { ActivityTypeEnum } from '~/enums';
import { colors } from '~/theme/colors';
import {
  iconToShowByActivityType,
  labelTypeEventBgColorByActivityType,
  showCustomIconByActivityTypeAction,
  textToShowByActivityType,
} from '~/utils/functions';

import { useRouter } from '../../../../../../hooks/useRouter';
import { PartnerEcosystemActivitiesTable } from '../../../../../../types/interfaces/table';

const ActionsColumn = ({
  activityId,
  activityType,
}: {
  activityId: string;
  activityType: ActivityTypeEnum;
}) => {
  const { goToRoute } = useRouter();

  let route = '/activities';

  switch (activityType) {
    case ActivityTypeEnum.Event:
      route += '/event';
      break;
    case ActivityTypeEnum.Action:
      route += '/action';
      break;
    case ActivityTypeEnum.OngoingOpportunity:
      route += '/opportunities';
      break;
  }

  return (
    <DataTableActions
      viewButton
      color={colors.primary['600']}
      onPressViewButton={() => goToRoute(`${route}/${activityId}`)}
    />
  );
};

export const tableActivitiesColumns = [
  {
    filterable: false,
    name: 'activity',
    header: 'Activity',
    defaultFlex: 1,
    render: ({ data }: PartnerEcosystemActivitiesTable) =>
      data.activityDefinitionSubDocument.title,
  },

  {
    filterable: false,
    name: 'organization',
    header: 'Organisation',
    defaultFlex: 1.5,
    render: ({ data }: PartnerEcosystemActivitiesTable) => (
      <TableCellWithImage
        name={
          data.activityDefinitionSubDocument.organizationSubDocument.name || '-'
        }
        logo={data.activityDefinitionSubDocument.organizationSubDocument.logo}
      />
    ),
  },
  {
    filterable: false,
    name: 'type',
    header: 'Type',
    defaultFlex: 1,
    filterEditor: SelectFilter,
    filterEditorProps: {
      multiple: false,
      wrapMultiple: false,
      dataSource: Object.values(ActivityTypeEnum).map((activityType) => {
        return {
          id: activityType,
          label:
            activityType === ActivityTypeEnum.OngoingOpportunity
              ? 'Ongoing'
              : activityType,
        };
      }),
    },
    render: ({ data }: PartnerEcosystemActivitiesTable) => (
      // Stack is used to avoid full width tag.
      <Stack>
        <Tag
          text={textToShowByActivityType(
            data.activityDefinitionSubDocument.type as ActivityTypeEnum,
          )}
          type="actions"
          icon={iconToShowByActivityType(
            data.activityDefinitionSubDocument.type as ActivityTypeEnum,
          )}
          tagTextColor={colors.singletons.darkText}
          tagBgColor={labelTypeEventBgColorByActivityType(
            data.activityDefinitionSubDocument.type as ActivityTypeEnum,
          )}
          showCustomIcon={showCustomIconByActivityTypeAction(
            data.activityDefinitionSubDocument.type as ActivityTypeEnum,
          )}
          showIcon
        />
      </Stack>
    ),
  },
  {
    name: 'actions',
    header: 'Actions',
    defaultFlex: 1,
    render: ({ data }: any) => {
      return (
        <ActionsColumn
          activityId={data.activityDefinition}
          activityType={
            data.activityDefinitionSubDocument.type as ActivityTypeEnum
          }
        />
      );
    },
  },
];
