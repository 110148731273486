import { Box, HStack, Link, Progress, Stack, Text, VStack } from 'native-base';
import Button from 'ui/components/Button';
import Icon from 'ui/components/Icon';
import Illustration, { ILLUSTRATIONS_NAME } from 'ui/components/Illustration';
import Input from 'ui/components/Input';
import { ORG_HELP_CENTER_URL } from 'ui/constants/commonUrl.constants';
import { colors } from 'ui/theme/colors';

import { HelpCentreRedirectLink } from '~/components/HelpCentre';

import { useResetPasswordController } from './controller';

export const ResetPassword = () => {
  const {
    control,
    errors,
    isDisableResetPassword,
    isSubmitting,
    onResetPassword,
  } = useResetPasswordController();

  return (
    <HStack
      minH="90vh"
      height="100%"
      width="100%"
      justifyContent="center"
      alignItems="center"
      space={8}
    >
      <Illustration
        width="450px"
        height="450px"
        name={ILLUSTRATIONS_NAME.UPDATE_CONSTRUCTION_SETTINGS_GREEN}
        alt="PasswrdRecovery"
      />
      <VStack width="100%" maxWidth="450px" space={4}>
        <HelpCentreRedirectLink />
        <Progress value={60} width="151px" />
        <Text
          textAlign="initial"
          fontSize="xl"
          fontWeight={500}
          color="gray.900"
        >
          Almost there! <br />
          <Text fontSize="sm" fontWeight={400} color="gray.900">
            Create a new password.
          </Text>
        </Text>
        <Stack>
          <Input
            name="password"
            control={control}
            placeholder="Create a new password"
            leftIconName="lock"
            leftIconColor={colors.muted[400]}
            type="password"
            label="New password"
            errorMessage={errors.password?.message}
            iconErrorMessage="info"
            p={4}
            isFullWidth
            backgroundColor="white"
            borderColor="muted.200"
            showPasswordRules={true}
          />
          <Box mb={4} />
        </Stack>
        <Stack mt={4} mb={6}>
          <Button
            onPress={onResetPassword}
            color="primary.600"
            p={6}
            isDisabled={isDisableResetPassword}
            isLoading={isSubmitting}
          >
            <Text fontSize="medium" color="white" fontWeight="medium">
              Save new password
            </Text>
          </Button>
        </Stack>
      </VStack>
    </HStack>
  );
};
