import FeatherIcon from 'feather-icons-react';
import { Flex, HStack, Stack, Text, VStack } from 'native-base';
import Button from 'ui/components/Button';

import { colors } from '~/theme/colors';

import { ModalNAButtonProps } from './types';

const ModalNAButton = ({ label, description, action }: ModalNAButtonProps) => {
  return (
    <Button
      variant="outline"
      pb="12"
      px="2"
      onPress={action}
      borderRadius="3xl"
      flex={1}
      flexDirection={'column'}
    >
      <HStack space={2} flexWrap="wrap">
        <Stack alignItems="center">
          <FeatherIcon
            icon="plus-circle"
            size={24}
            color={colors.primary[600]}
          />
        </Stack>
        <VStack>
          <Text fontSize="md" color="primary.600" fontWeight={500}>
            {label}
          </Text>
          <Text
            fontSize="xs"
            mt={1}
            fontWeight={400}
            color="gray.600"
            maxW="3/5"
          >
            {description}
          </Text>
        </VStack>
      </HStack>
    </Button>
  );
};

export default ModalNAButton;
