import { useImperativeHandle, useState } from 'react';

import { ModalImperativeRef, ModalProps } from './types';

const useModalController = ({
  ref,
  onClose,
  preventClose,
}: Pick<ModalProps, 'ref' | 'onClose' | 'preventClose'>) => {
  const [isOpen, setIsOpen] = useState(false);

  useImperativeHandle(
    ref,
    (): ModalImperativeRef => ({
      open: () => setIsOpen(true),
      close: () => handleClose(),
    }),
    [],
  );

  const handleClose = () => {
    if (!!preventClose) return;
    setIsOpen(false);
    onClose?.();
  };

  return {
    isOpen,
    handleClose,
  };
};

export default useModalController;
