import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';

import { MemberTypeEnum } from '~/enums';
import { useAppSelector } from '~/hooks/useAppSelector';
import { useCreatePublicInvite } from '~/hooks/useCreatePublicInvite';
import { UploadComposeSettingsImages } from '~/pages/Authenticated/AddOrgMember/components/Form/types';
import { InvitePartnerService } from '~/services/resources/invitePartner';
import { addMemberActions } from '~/store/slices/addMember';
import { InvitePartnerDTO } from '~/types/interfaces/invite-member';

export const useAddPartnerController = () => {
  const [isSubmitting, setIsSubmitting] = useState(false);

  const dispatch = useDispatch();

  const { organizationSelectedId } = useAppSelector(({ auth }) => auth);
  const { ecosystemsToShare, userConfigurations, composeSettings } =
    useAppSelector(({ addMember }) => addMember);

  const { handleCreatePublicInvite, isSubmittingPublicInvite } =
    useCreatePublicInvite(MemberTypeEnum.Partner);

  const handleInvitePartner = async ({
    backgroundImage,
    logo,
  }: UploadComposeSettingsImages) => {
    try {
      if (!organizationSelectedId || !composeSettings) return;
      setIsSubmitting(true);
      const data: InvitePartnerDTO = {
        emails: userConfigurations.map((configuration) => configuration.email),
        ecosystemsData: ecosystemsToShare,
        sharerOrganization: organizationSelectedId,
        composeSettings: {
          ...composeSettings,
          logo,
          backgroundImage,
        },
      };
      await InvitePartnerService.createInvite(data);
      toast.success('Partner(s) invited successfully.');
      dispatch(addMemberActions.reset());
    } catch (error) {
      toast.error('Error on trying to invite private partner(s).');
    } finally {
      setIsSubmitting(false);
    }
  };

  useEffect(() => {
    dispatch(addMemberActions.reset());
  }, [dispatch]);

  return {
    handleInvitePartner,
    handleCreatePublicInvite,
    isSubmitting,
    isSubmittingPublicInvite,
  };
};
