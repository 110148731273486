import { Modal as NBModal, VStack } from 'native-base';
import { ForwardedRef, forwardRef } from 'react';
import { RootPortal } from 'ui/components/v2/RootPortal';

import useModalController from './controller';
import { ModalImperativeRef, ModalProps } from './types';

const Modal = forwardRef(function Modal(
  {
    width,
    onClose,
    children,
    maxWidth,
    preventClose,
    topOffset = 24,
  }: ModalProps,
  ref: ForwardedRef<ModalImperativeRef>,
) {
  const { isOpen, handleClose } = useModalController({
    ref,
    onClose,
    preventClose,
  });

  return (
    <RootPortal>
      <NBModal
        p={4}
        position={'fixed'}
        isOpen={isOpen}
        onClose={handleClose}
        closeOnOverlayClick
        justifyContent={'flex-start'}
        paddingTop={topOffset}
        overflowY={'auto'}
      >
        <VStack
          w={width}
          maxW={maxWidth}
          maxH={'unset'}
          minH={'fit-content'}
          backgroundColor={'transparent'}
        >
          {children}
        </VStack>
      </NBModal>
    </RootPortal>
  );
});

export default Modal;
