import { useLocation } from 'react-router-dom';

import { VITE_DOIT_VOLUNTEER_URL } from '~/config';
import { useAppSelector } from '~/hooks/useAppSelector';
import { useRouter } from '~/hooks/useRouter';
import { IActivityDefinition } from '~/types/interfaces/activity';

export const useActionSuccessfulController = () => {
  const location = useLocation();

  const { goToRoute } = useRouter();

  const activity: IActivityDefinition = location.state?.activity;

  const activityUrl = `${VITE_DOIT_VOLUNTEER_URL}my-wallet/activities/${activity._id}`;

  const { selectedEcosystem } = useAppSelector(({ ecosystem }) => ecosystem);

  return { activity, activityUrl, selectedEcosystem, goToRoute };
};
