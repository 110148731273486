import SelectFilter from '@inovua/reactdatagrid-community/SelectFilter';
import { Avatar, HStack, Text } from 'native-base';
import { PopOverOptions } from 'ui/components/PopOverOptions';
import Tag from 'ui/components/Tags/Tag';

import { AccountStatusEnum, MemberTypeEnum } from '~/enums';
import { colors } from '~/theme/colors';

import { useChildOrgMembersController } from './controller';
import { ChildOrgMembersData } from './types';

const ActionsColumn = ({ memberId }: { memberId: string }) => {
  const {
    handleRemoveChildOrgMember,
    handleResendInvitation,
    handleViewChieldOrgMember,
  } = useChildOrgMembersController();

  const popOverOptions = [
    {
      icon: {
        color: colors.gray[600],
        icon: 'eye',
        size: 22,
      },
      title: 'View member',
      action: () => handleViewChieldOrgMember(memberId),
    },
    {
      icon: {
        color: colors.gray[600],
        icon: 'send',
        size: 22,
      },
      title: 'Resend invitation',
      action: () => handleResendInvitation(memberId),
    },
    {
      icon: {
        color: colors.error[600],
        icon: 'x-circle',
        size: 22,
      },
      title: 'Remove member',
      action: () => handleRemoveChildOrgMember(memberId),
    },
  ];

  return (
    <PopOverOptions
      popovText="Options"
      popovTextPosition="right"
      popovIcon="more-vertical"
      options={popOverOptions}
      popovIconColor={colors.primary[400]}
      popTextColor={colors.primary[400]}
    />
  );
};

export const tableMembersColumns = [
  {
    name: 'member',
    header: 'Name',
    defaultFlex: 1.6,
    render: ({ data }: { data: ChildOrgMembersData }) => {
      const { member } = data;
      if (member.memberStatus === AccountStatusEnum.PENDING) {
        return (
          <HStack space={2} alignItems="center">
            <Text color="gray.600">{member.email}</Text>
            <Tag text={member.memberStatus} type="pending" showIcon />
          </HStack>
        );
      }

      return (
        <HStack space={2} flexDirection="row" alignItems="center">
          <Avatar
            source={{
              uri: member.avatar,
            }}
            height="30px"
            width="30px"
          />
          <Text color="gray.600">{member.displayName}</Text>
        </HStack>
      );
    },
  },
  {
    name: 'type',
    header: 'Member Type',
    defaultFlex: 0.9,
    filterEditor: SelectFilter,
    filterEditorProps: {
      multiple: false,
      wrapMultiple: false,
      dataSource: Object.values(MemberTypeEnum).map((memberType) => {
        return {
          id: memberType,
          label: memberType,
        };
      }),
    },
  },
  {
    name: 'organization',
    header: 'Organisation',
    defaultFlex: 1.1,
    render: ({ data }: { data: ChildOrgMembersData }) => {
      const { organization } = data;

      return (
        <HStack space={2} alignItems="center">
          <Avatar
            source={{
              uri: organization.avatar,
            }}
            height="30px"
            width="30px"
            borderRadius="sm"
            bgColor="white"
            borderWidth="1"
            borderColor="gray.300"
          />
          <Text color="gray.600">{organization.name}</Text>
        </HStack>
      );
    },
  },
  {
    name: 'ecosystem',
    header: 'Ecosystem',
    defaultFlex: 1,
  },
  {
    name: 'actions',
    header: 'Actions',
    defaultFlex: 0.6,
    render: ({ data }: { data: ChildOrgMembersData }) => (
      <ActionsColumn memberId={data.member.id} />
    ),
  },
];
