import { AxiosError } from 'axios';
import { toast } from 'react-toastify';

import { ECOSYSTEM_INVITATION_MESSAGES } from '~/constants/messages.constants';
import { PAGES } from '~/constants/pages.constants';
import { useAppDispatch } from '~/hooks/useAppDispatch';
import { useRouter } from '~/hooks/useRouter';
import { OrganizationService } from '~/services/resources/organization';
import UserService from '~/services/resources/user';
import { authSliceActions } from '~/store/slices/auth';
import { IAuthState } from '~/store/slices/auth/types';

export const useJoinAsAdmin = () => {
  const dispatch = useAppDispatch();

  const { goToRoute } = useRouter();

  const handleJoinAsAdminAuthenticated = async (
    organizationId: string,
    code: string,
  ) => {
    try {
      if (!code || !organizationId) return;

      const { data: responseMeData } = await UserService.me();
      const userId = responseMeData._id;

      await OrganizationService.joinAsAdmin({
        code,
        organizationId,
      });

      const filter = JSON.stringify({
        admins: { $in: [userId] },
      });

      const { data: organizationData } = await OrganizationService.findAll({
        filter,
      });

      const userData: IAuthState = {
        _id: userId,
        organizations: organizationData.data,
        organizationSelectedId: organizationData.data[0]?._id,
        hasOrganization: organizationData.data.length > 0,
      };

      dispatch(authSliceActions.update({ ...userData }));
      goToRoute(PAGES.INVITE_JOIN_ADMIN_SUCCESS);
      toast.success(ECOSYSTEM_INVITATION_MESSAGES.INVITATION_ACCEPTED);
    } catch (err) {
      if (err instanceof AxiosError) {
        if (err.response?.status === 401) {
          return toast.error(
            ECOSYSTEM_INVITATION_MESSAGES.INVITATION_CODE_INVALID,
          );
        }
        if (err.response?.data?.message) {
          return toast.error(err.response?.data?.message);
        }
      }
    }
  };

  return { handleJoinAsAdminAuthenticated };
};
