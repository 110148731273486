import { HStack, VStack } from 'native-base';
import Button from 'ui/components/Button';
import FormBuilderV2 from 'ui/components/FormBuilderV2';
import { FormDataIsLoading } from 'ui/components/FormBuilderV2/components/FormDataIsLoading';

import { ActivityMetrics } from './ActivityMetrics';
import { useAboutFormController } from './controller';
import { AboutFormProps } from './types';

export const AboutForm = (props: AboutFormProps) => {
  const {
    handleContinue,
    defaultValues,
    formSchema,
    isLoading,
    activityMetricsOptions,
    control,
    clearMeasurementUnit,
    watch,
    addOneStep,
    fields,
    measurementUnits,
    handleSubmit,
    isDisabled,
    appsForSelector,
    onChangeMeasurementUnit,
    onAddedCustomUnit,
    handleBack,
  } = useAboutFormController(props);

  return (
    <VStack w="100%" space={4}>
      {appsForSelector.length === 0 ? (
        <FormDataIsLoading />
      ) : (
        <>
          <VStack w="full">
            <FormBuilderV2
              options={formSchema}
              onSubmit={handleContinue}
              defaultValues={defaultValues}
              isLoadingSubmit={isLoading}
            />
            <ActivityMetrics
              options={activityMetricsOptions(measurementUnits) || []}
              control={control}
              watch={watch}
              clearMeasurementUnit={clearMeasurementUnit}
              onChangeMeasurementUnit={onChangeMeasurementUnit}
              addOneForm={addOneStep}
              fields={fields}
              onAddedCustomUnit={onAddedCustomUnit}
            />
          </VStack>
          <HStack flex={1} mt={5} space={2} justifyContent="space-between">
            <Button variant="outline" flex={0.5} onPress={handleBack}>
              Back
            </Button>
            <Button
              flex={0.5}
              onPress={handleSubmit(handleContinue)}
              isDisabled={isDisabled}
            >
              Continue
            </Button>
          </HStack>
        </>
      )}
    </VStack>
  );
};
