// It is used?
import { FieldValues, Path } from 'react-hook-form';

import { MemberTypeEnum } from '~/enums';

export interface IAppModel {
  _id: string;
  name: string;
  description?: string;
  logo?: string;
  ecosystem: string;
  organization: string;
  features: string[];
  isChecked?: boolean;
  isDisabled?: boolean;
}

export enum AddMemberTab {
  Access = 0,
  Permissions = 1,
  Compose = 2,
  Invitation = 3,
}

export type TabTransitions<FormData extends FieldValues> = {
  [key in AddMemberTab]: {
    nextTab: AddMemberTab | undefined;
    fieldsToTriggerValidation: Array<Path<FormData> | string>;
  };
};

export interface IAddOrgMembersFormProps {
  title: string;
  memberType: MemberTypeEnum;
  isSubmitting: boolean;
  isSubmittingPublicInvite: boolean;
  onSubmitInviteMember: (data: UploadComposeSettingsImages) => void;
  onSubmitPublicInviteMember: (data: UploadComposeSettingsImages) => void;
}

export interface AddOrgMembersFormValues {
  ecosystems: Object;
  memberType: MemberTypeEnum;
  partnersPermissions?: string[];
  membersPermissions: string[];
  userConfigurations: { email: string; externalId?: string }[];
  title: string;
  message: string;
  brandColor: string;
  logo?: string;
  backgroundImage?: string;
}

export interface UploadComposeSettingsImages {
  logo: string;
  backgroundImage: string;
}
