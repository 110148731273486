/* eslint-disable @typescript-eslint/no-explicit-any */
export const styles: any = {
  flexDirection: { lg: 'row' },
  imageContainerW: { lg: '50%' },
  imageW: { base: '200px', lg: '360px' },
  imageH: { base: '128px', lg: '360px' },
  boxMt: { base: 12, lg: 0 },
  titleFontSize: { base: 'xl', lg: '3xl' },
  titleAlignment: { base: 'center', lg: 'left' },
  subTitleAlignment: { base: 'center', lg: 'left' },
  buttonContainerDisplay: { base: 'none', lg: 'flex' },
};
