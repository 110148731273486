import { DropzoneProps } from '../types';

// @mycatdoitbetter // NOTE: This two types bellow (EImportFileType, IImportFile) must not be here,
// I'm duplicating because the FileZone component is not on the same
// package on the mono repo, so I can't import them from the other package...

// TODO: Remove it
export enum EImportFileType {
  Activity = 'Activity',
  ActivityDefinition = 'ActivityDefinition',
}

interface IImportFile {
  _id: string;
  organization: string;
  app: string;
  ecosystem: string;
  importFileType: EImportFileType;
  createdAt: string;
  updatedAt: string;
  key: string;
}

export interface IFileZone extends DropzoneProps {
  defaultImportedFile?: IImportFile;
  fileUploadType: EImportFileType;
  btnLabel?: string;
  btnIconColor?: string;
  btnLabelColor?: string;
  btnBgColor?: string;
  title?: string;
  errorMessage?: string;
  iconErrorMessage?: string;
  iconErrorSize?: string | number;
  isInvalid?: boolean;
  isDisabled?: boolean;
  isRequired?: boolean;
  isReadOnly?: boolean;
  hasError?: boolean;
  canRemoveFile?: boolean;
}
