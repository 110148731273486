import { VStack } from 'native-base';

import { EcosystemItem } from './EcosystemItem';
import { IEcosystemsListProps } from './types';
import { useAccessFormSectionController } from './useAcessFormSectionController';

export const EcosystemsList = ({
  control,
  ecosystems,
  appsConnectedToOrganization,
  memberType,
  setValue,
}: IEcosystemsListProps) => {
  const { isEcosystemDisabled } = useAccessFormSectionController();
  return (
    <VStack space={4}>
      {ecosystems?.map((ecosystem) => (
        <EcosystemItem
          key={ecosystem.name}
          _id={ecosystem._id}
          name={ecosystem.name}
          visibility={ecosystem.visibility}
          appsConnectedToOrganization={appsConnectedToOrganization}
          control={control}
          memberType={memberType}
          setValue={setValue}
          isDisabled={isEcosystemDisabled(ecosystem._id)}
        />
      ))}
    </VStack>
  );
};
