import { yupResolver } from '@hookform/resolvers/yup';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';

import { IInputItems } from './components/FormFieldsRender/types';
import { useFormImperativeHandle } from './hooks/useFormImperativeHandle';
import { IFormBuilderV2Props } from './types';
import { schemeValidationGenerator } from './utils';

export const useFormBuilderController = ({
  options,
  defaultValues,
  onSubmit,
  isLoadingSubmit,
  customControl,
  formBuilderImperativeRef,
}: IFormBuilderV2Props) => {
  // eslint-disable-next-line prefer-const
  let inputItems: IInputItems[] = [];

  options.inputsSections.forEach((inputSection) => {
    inputSection.sectionInputRows.forEach((inputRow) => {
      inputRow.inputs.forEach((input) => inputItems.push(input));
    });
  });

  const {
    control,
    handleSubmit,
    formState,
    reset,
    watch,
    getValues,
    setValue,
  } = useForm({
    mode: 'onBlur',
    defaultValues,
    resolver: yupResolver(
      options.customValidations
        ? options.customValidations
        : schemeValidationGenerator(inputItems),
    ),
  });

  useFormImperativeHandle({
    getValues,
    setValue,
    ref: formBuilderImperativeRef,
  });

  const handleSubmitForm = handleSubmit((data) => {
    if (onSubmit) {
      onSubmit(data);
    }
  });

  useEffect(() => {
    // commented to avoid re-renders for app section checkbox in forms for permissions validation. Need to see if
    // it will not generate side-effects.
    // reset(defaultValues);
  }, [defaultValues, reset]);

  return {
    control,
    watch,
    options,
    handleSubmitForm,
    formState,
    isLoadingSubmit,
    customControl,
  };
};
