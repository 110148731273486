import { HStack, Skeleton, Text, VStack } from 'native-base';
import { useState } from 'react';
import Button from 'ui/components/Button';
import Icon from 'ui/components/Icon';
import { colors } from 'ui/theme/colors';
import { formatMeasurementAmount } from 'ui/utils/measurements/format';

import { PAGES } from '~/constants/pages.constants';
import { useRouter } from '~/hooks/useRouter';
import { CharitiesHelpedApplications } from '~/pages/Authenticated/Insights/components/CharitiesHelped/CharitiesHelpedApplications';
import { CharitiesHelpedLogs } from '~/pages/Authenticated/Insights/components/CharitiesHelped/CharitiesHelpedLogs';
import {
  InsightScaffold,
  InsightScaffoldChildrenReactComponent,
} from '~/pages/Authenticated/Insights/components/InsightScaffold';
import {
  InsightTabOption,
  InsightTabs,
} from '~/pages/Authenticated/Insights/components/InsightTabs';
import { PercentageChangeIndicator } from '~/pages/Authenticated/Insights/components/PercentageChangeIndicator';
import { Routes } from '~/routes';
import { CharitiesReportResponse } from '~/services/resources/types';

import { CharitiesHelpedProps } from './types';
import { INSIGHT_TABS, insightTabList } from './utils';

export const CharitiesHelpedTabs: InsightScaffoldChildrenReactComponent = ({
  onSetChart,
}) => {
  const [periodData, setPeriodData] = useState<CharitiesReportResponse>();
  const [selectedTab, setSelectedTab] = useState<InsightTabOption>(
    insightTabList?.[0],
  );

  const handleChangeTab = (tab: InsightTabOption) => {
    setPeriodData(undefined);
    setSelectedTab(tab);
  };

  const renderTabs = () => {
    switch (selectedTab.value) {
      case INSIGHT_TABS.LOGS:
        return <CharitiesHelpedLogs onSetPeriodData={handleSetPeriodData} />;
      case INSIGHT_TABS.APPLICATIONS:
        return (
          <CharitiesHelpedApplications onSetPeriodData={handleSetPeriodData} />
        );
      default:
        return null;
    }
  };

  const handleSetPeriodData = (data?: CharitiesReportResponse) => {
    if (!data) {
      setPeriodData(undefined);
      onSetChart(null, []);
      return;
    }

    const { currentPeriodInsights } = data;
    const flattenData = currentPeriodInsights.map((item) => ({
      ...item.activityOwnerOrganizationSummary,
      ...item.measurementUnitSummary,
      formattedMeasurementUnit: item.measurementUnitSummary
        ? formatMeasurementAmount(item.total, item.measurementUnitSummary)
        : item.total,
      total: item.total,
    }));

    setPeriodData(data);
    onSetChart(null, flattenData);
  };

  return (
    <VStack space={4}>
      <HStack
        space={[4, 4, 2]}
        alignItems={'center'}
        px={6}
        pb={6}
        justifyContent={['center', 'center', 'space-between']}
        flexGrow={'grow'}
        direction={['column-reverse', 'column-reverse', 'row']}
      >
        <HStack space={4} alignItems="center" flex={1}>
          {!periodData ? (
            <Skeleton.Text lines={2} height={4} width={'40%'} />
          ) : (
            <>
              <Text color="gray.800" fontSize="4xl" fontWeight={500}>
                {String(periodData.totalCurrentPeriod).padStart(2, '0')}
              </Text>
              <PercentageChangeIndicator
                isPositive={periodData.percentageVariation > 0}
                percentage={periodData.percentageVariation.toFixed(2)}
              />
            </>
          )}
        </HStack>
        <InsightTabs
          flex={1}
          onChange={handleChangeTab}
          options={insightTabList}
        />
      </HStack>
      {renderTabs()}
    </VStack>
  );
};

export const CharitiesHelped = ({ className }: CharitiesHelpedProps) => {
  const { goToRoute } = useRouter();

  return (
    <InsightScaffold
      className={className}
      title={{
        icon: 'heart',
        name: 'Charities Helped',
      }}
      renderExtraButtons={() => (
        <Button
          variant="outline"
          width={'fit-content'}
          borderColor={colors.primary[400]}
          px={3}
          py={0}
          height={34}
          onPress={() => {
            goToRoute(`${PAGES.MEMBERS}?tab=3`);
          }}
        >
          <HStack space={2} alignItems={'center'}>
            <Icon icon="plus-circle" color={colors.primary[400]} size={16} />

            <Text color={colors.primary[400]} fontSize="sm" fontWeight={500}>
              Invite charities
            </Text>
          </HStack>
        </Button>
      )}
    >
      {CharitiesHelpedTabs}
    </InsightScaffold>
  );
};
