import { toast } from 'react-toastify';
import { compressImage } from 'ui/utils/compressImage';
import { base64ToFile } from 'ui/utils/transform/file';

import { DEFAULT_AWS_PUBLIC_BUCKET } from '~/config';
import { UPLOAD_IMAGE_ERROR } from '~/constants/messages.constants';
import { UploadsSignedURLObjectTarget } from '~/services/resources/types';
import { UploadsService } from '~/services/resources/uploads';

const DEFAULT_IMAGE_QUALITY = 0.8;

export const uploadImage = async (
  file: File,
  target: UploadsSignedURLObjectTarget,
  fieldName = 'image',
  transform?: { maxWidth: number },
): Promise<string | undefined> => {
  if (!file) return undefined;
  try {
    const compressedImage = await compressImage(
      file,
      DEFAULT_IMAGE_QUALITY,
      transform?.maxWidth,
    );

    const signedUrlResponse = await UploadsService.generatePreSignedURL({
      Target: target,
      FileExtension: file.type.split('/')[1],
    });

    await UploadsService.uploadFileToS3({
      file: compressedImage as File,
      signedURL: signedUrlResponse,
    });

    return `${DEFAULT_AWS_PUBLIC_BUCKET}/${signedUrlResponse.Key}`;
  } catch (err) {
    toast.error(UPLOAD_IMAGE_ERROR(fieldName));
  }
};

export const uploadImageFromBase64 = async (
  base64: string,
  target = UploadsSignedURLObjectTarget.OrganizationPicture,
  fieldName = 'logo',
  transform?: { maxWidth: number },
): Promise<string | undefined> => {
  if (!base64) return;

  const fileName = `${fieldName}_${Date.now()}`;
  const file = await base64ToFile(base64, fileName);

  return uploadImage(file, target, fieldName, transform);
};
