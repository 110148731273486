import { Box, Divider, HStack, Skeleton, Stack, Text } from 'native-base';
import DataTable from 'ui/components/DataTable';
import { ReportCards } from 'ui/components/ReportCards';

import { ModalActionReport } from '~/components/ModalActionReport';
import { colors } from '~/theme/colors';
import { secondsToHoursMinutesString } from '~/utils/functions';

import { UserProfilePopulated } from '../../../../../../types/interfaces/userProfile';
import { useMemberActivitiesController } from './controller';
import { tableActivitiesColumns } from './tableActivitiesColumns';
import { defaultMemberActivitiesFilterValue } from './utils';

export const MemberActivities = ({
  member,
}: {
  member: UserProfilePopulated;
}) => {
  const {
    actionReportModalRef,
    openModal,
    loadApplications,
    applicationsCount,
    isLoadingApplications,
    isEventApplicationsCountLoading,
    eventApplicationsCount,
    isActionApplicationsCountLoading,
    actionApplicationsCount,
    isOngoingApplicationsCountLoading,
    ongoingApplicationsCount,
    isEventLogHoursCountLoading,
    eventLogHoursCount,
    isActionLogHoursCountLoading,
    actionLogHoursCount,
    isOngoingLogHoursCountLoading,
    ongoingLogHoursCount,
    totalLogHoursCount,
    isTotalLogHoursCountLoading,
  } = useMemberActivitiesController(member);

  return (
    <Stack
      bgColor="white"
      borderRadius="3xl"
      width="100%"
      space={5}
      padding={6}
    >
      <Stack space={2}>
        <Text fontSize="lg" fontWeight={500} color="gray.600">
          Activities report
        </Text>
        <Text fontSize="sm" fontWeight={400} color="gray.600">
          {"Keep track of this member's activities"}
        </Text>
        <Divider />
      </Stack>
      <HStack space={6} width="100%" justifyContent="space-between">
        <Box width="23.2%">
          <ReportCards
            withBorder
            icon="clock"
            title="Hours logged"
            value={secondsToHoursMinutesString(totalLogHoursCount?.total || 0)}
            valueInfo="Total hours achieved"
            dividerColor={colors.singletons.darkBlue}
            iconColor={colors.singletons.darkBlue}
            paddingTitle={0}
            cardHeight="40px"
            isLoading={isTotalLogHoursCountLoading}
          />
        </Box>
        <Box width="23.2%">
          <ReportCards
            withBorder
            icon="calendar"
            value={eventApplicationsCount?.count || 0}
            valueInfo="Applications"
            title="Events"
            dividerColor="lime.500"
            iconColor={colors.lime['500']}
            paddingTitle={0}
            cardHeight="40px"
            isLoading={isEventApplicationsCountLoading}
            direction="column"
            contentChildren={
              <HStack space={2} alignItems="baseline">
                {isEventLogHoursCountLoading ? (
                  <Box w="50%" fontSize="2xl">
                    <Skeleton />
                  </Box>
                ) : (
                  <>
                    <Text fontSize="xl" fontWeight="700" color="gray.900">
                      {secondsToHoursMinutesString(
                        eventLogHoursCount?.count || 0,
                      )}
                    </Text>
                    <HStack alignItems="center" space={1}>
                      <Text fontSize="xs" color="gray.500" fontWeight="500">
                        time in events
                      </Text>
                    </HStack>
                  </>
                )}
              </HStack>
            }
          />
        </Box>
        <Box width="23.2%">
          <ReportCards
            withBorder
            icon="puzzle-tertiary"
            value={actionApplicationsCount?.count || 0}
            valueInfo="Applications"
            title="Actions"
            dividerColor="orange.500"
            iconColor={colors.orange['500']}
            isIconCustom
            paddingTitle={0}
            cardHeight="40px"
            isLoading={isActionApplicationsCountLoading}
            actionButtonName="More"
            action={openModal}
            direction="column"
            contentChildren={
              <HStack space={2} alignItems="baseline">
                {isActionLogHoursCountLoading ? (
                  <Box w="50%" fontSize="2xl">
                    <Skeleton />
                  </Box>
                ) : (
                  <>
                    <Text fontSize="xl" fontWeight="700" color="gray.900">
                      {secondsToHoursMinutesString(
                        actionLogHoursCount?.count || 0,
                      )}
                    </Text>
                    <HStack alignItems="center" space={1}>
                      <Text fontSize="xs" color="gray.500" fontWeight="500">
                        time in actions
                      </Text>
                    </HStack>
                  </>
                )}
              </HStack>
            }
          />
        </Box>
        <Box width="23.2%">
          <ReportCards
            withBorder
            icon="repeat"
            value={ongoingApplicationsCount?.count || 0}
            valueInfo="Applications"
            title="Ongoing opportunities"
            dividerColor="purple.500"
            iconColor={colors.purple['500']}
            paddingTitle={0}
            cardHeight="40px"
            isLoading={isOngoingApplicationsCountLoading}
            direction="column"
            contentChildren={
              <HStack space={2} alignItems="baseline">
                {isOngoingLogHoursCountLoading ? (
                  <Box w="50%" fontSize="2xl">
                    <Skeleton />
                  </Box>
                ) : (
                  <>
                    <Text fontSize="xl" fontWeight="700" color="gray.900">
                      {secondsToHoursMinutesString(
                        ongoingLogHoursCount?.count || 0,
                      )}
                    </Text>
                    <HStack alignItems="center" space={1}>
                      <Text fontSize="xs" color="gray.500" fontWeight="500">
                        time in ongoing op.
                      </Text>
                    </HStack>
                  </>
                )}
              </HStack>
            }
          />
        </Box>
      </HStack>

      <Stack space={8}>
        <Stack space={2}>
          <Text fontWeight={500} fontSize="2xl" color="gray.600">
            {applicationsCount}{' '}
            {applicationsCount === 1 ? 'activity' : 'activities'}
          </Text>
          <Text fontWeight={400} fontSize="sm" color="gray.600">
            Total number of activities.
          </Text>
        </Stack>
        <DataTable
          defaultFilterValues={defaultMemberActivitiesFilterValue}
          loadData={loadApplications}
          isLoading={isLoadingApplications}
          columns={tableActivitiesColumns}
        />
      </Stack>

      <ModalActionReport
        modalRef={actionReportModalRef}
        headerText="Actions"
        totalTimeLoggedInAction={secondsToHoursMinutesString(250)}
        totalApplications={43}
      />
    </Stack>
  );
};
