import { Text } from 'native-base';

import { Tooltip } from '../Tooltip';
import { TableCellToolTipProps } from './types';

export const TableCellToolTip = ({ text }: TableCellToolTipProps) => {
  return (
    <Tooltip label={text}>
      <Text noOfLines={1}>{text}</Text>
    </Tooltip>
  );
};
