import { useMemo } from 'react';
import { useQuery } from 'react-query';

import {
  InsightPageFilters,
  useInsightFilters,
} from '~/pages/Authenticated/Insights/useInsightFilters';
import { InsightCacheConfig } from '~/pages/Authenticated/Insights/utils';
import { INSIGHT_SPACE_OPTIONS_BASE_URL } from '~/services/resources/insights/constants';
import InsightService from '~/services/resources/insights/insightsService';
import { GetSpaceOptionsMetricsResponse } from '~/services/resources/insights/types';
import { InsightByPeriodTypeQueryParams } from '~/services/resources/types';

const buildQueryKey = (filters: InsightPageFilters) => [
  INSIGHT_SPACE_OPTIONS_BASE_URL,
  {
    apps: filters.apps,
    ecosystem: filters.selectedEcosystemId,
    organization: filters.selectedOrganizationId,
    startDate: filters.from!,
    endDate: filters.to!,
    granularity: 'Daily',
    periodType: filters.periodType,
  },
];

export const useSpaceOptionsController = () => {
  const { filters, hasFilterSetted: hasFilterSet } = useInsightFilters();

  const {
    data: spaceOptionsMetrics,
    isLoading,
    isFetched,
  } = useQuery({
    queryKey: buildQueryKey(filters),
    queryFn: async ({ queryKey }) => {
      const [, params] = queryKey;
      return InsightService.getSpaceOptionMetrics(
        params as InsightByPeriodTypeQueryParams,
      );
    },
    enabled: !!hasFilterSet,
    ...InsightCacheConfig,
  });

  const chartData = useMemo(() => {
    const dataMap = new Map<string, number>();
    let maxValue = 0;
    let total = 0;

    if (spaceOptionsMetrics?.length) {
      for (const metric of spaceOptionsMetrics as GetSpaceOptionsMetricsResponse[]) {
        if (!metric?.spaceOptions) continue;

        const value = metric?.total || 0;
        total += value;

        maxValue = Math.max(maxValue, value);

        const safeSpaceOption = metric.spaceOptions.toLowerCase();
        if (dataMap.has(safeSpaceOption)) {
          const dataMapValue = dataMap.get(safeSpaceOption) ?? 0;
          dataMap.set(safeSpaceOption, dataMapValue + value);
        } else {
          dataMap.set(safeSpaceOption, value);
        }
      }
    }

    const categories = Array.from(dataMap.keys());
    const data = Array.from(dataMap.values());
    return { series: [{ data, name: 'Total' }], categories, maxValue, total };
  }, [spaceOptionsMetrics]);

  return {
    isLoading,
    isFetched,
    spaceOptionsMetrics,
    chartData,
  };
};
