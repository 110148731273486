import { Text, VStack } from 'native-base';
import Button from 'ui/components/Button';

import { CardCoverImage } from '../components/CardCoverImage';
import {
  DEFAULT_ACTION_LABEL,
  DEFAULT_COVER_IMAGE,
  DEFAULT_DESCRIPTION,
  DEFAULT_TITLE,
} from './constants';
import { CorpCompanyProfileCardProps } from './types';

export const CorpCompanyProfileCard = ({
  title = DEFAULT_TITLE,
  description = DEFAULT_DESCRIPTION,
  coverImageUrl = DEFAULT_COVER_IMAGE,
  actionLabel = DEFAULT_ACTION_LABEL,
  backgroundColor = 'white',
  onAction,
}: CorpCompanyProfileCardProps) => {
  return (
    <VStack
      w="full"
      borderWidth={1}
      borderRadius={16}
      overflow={'hidden'}
      borderColor={'gray.200'}
      backgroundColor={backgroundColor}
    >
      <VStack>
        {/* Cover Image */}
        <CardCoverImage imageUrl={coverImageUrl} ratio={35} />

        {/* Body */}
        <VStack p={4} space={3}>
          <Text
            color={'black'}
            numberOfLines={1}
            fontSize={{ base: 'xl', md: '2xl' }}
            fontWeight={500}
          >
            {title}
          </Text>
          <Text color={'black'} fontSize={'sm'} fontWeight={400}>
            {description}
          </Text>
        </VStack>

        {/* Footer */}
        <VStack p={4}>
          <Button
            onPress={onAction}
            variant={'solid'}
            backgroundColor={'primary.400'}
            py={'21px'}
          >
            <Text
              color={'white'}
              numberOfLines={1}
              fontSize={{ base: 'sm', md: 'md' }}
              fontWeight={500}
            >
              {actionLabel}
            </Text>
          </Button>
        </VStack>
      </VStack>
    </VStack>
  );
};
