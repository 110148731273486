import { VStack } from 'native-base';
import Tabs from 'ui/components/Tabs';

import { ActivityFormContainer } from '~/components/ActivityFormBuilderPresets/components/ActivityFormContainer';
import { MediaForm } from '~/components/MediaForm';
import Scaffold from '~/components/Scaffold';
import { AlertImportedActivity } from '~/pages/Authenticated/Activities/components/AlertImportedActivity';
import { colors } from '~/theme/colors';

import { AboutForm } from './AboutForm';
import { useEditActionFormController } from './controller';
import { actionFormTabsElements } from './tabsElements';
import { ActionFormTabsPages } from './types';
import { WhenWhereForm } from './WhenWhereForm';

export const EditActionForm = () => {
  const {
    tabsRef,
    currentTab,
    causeOptions,
    formData,
    handleBack,
    handleContinue,
    isLoading,
    isLoadingDefaultValues,
    filteredAppsByPermissions,
    appIdToValidatePermisssions,
  } = useEditActionFormController();

  const renderTabs = () => {
    return {
      [ActionFormTabsPages.About]: (
        <AboutForm
          handleContinue={handleContinue}
          handleBack={handleBack}
          causeOptions={causeOptions}
          defaultValues={formData}
          isLoading={isLoading}
          isLoadingDefaultValues={isLoadingDefaultValues}
          connectedApps={filteredAppsByPermissions}
        />
      ),
      [ActionFormTabsPages.Media]: (
        <MediaForm
          handleBack={handleBack}
          handleContinue={handleContinue}
          defaultValues={formData}
          isLoading={isLoading}
        />
      ),
      [ActionFormTabsPages.Where]: (
        <WhenWhereForm
          handleBack={handleBack}
          handleContinue={handleContinue}
          defaultValues={formData}
          isLoading={isLoading}
        />
      ),
    }[currentTab];
  };

  return (
    // <Permissions.ValidateApp
    //   disablePermissionsValidation={true}
    //   appIdToValidate={appIdToValidatePermisssions}
    //   requiredPermissions={CreateEditActivityPermissions.Action}
    //   fallbackComponent={<Page403 />}
    // >
    <Scaffold title="Edit action" alignItemsChildren="center">
      <ActivityFormContainer>
        <VStack w="100%" alignItems="center" pb={10}>
          {formData.externalId && formData.externalProvider && (
            <AlertImportedActivity mb={10} />
          )}
          <Tabs
            ref={tabsRef}
            tabBgColor="transparent"
            tabWith="100%"
            tabTextColor={colors.primary['600']}
            tabIndicatorColor={colors.primary['600']}
            tabfocusedTextColor={colors.primary['600']}
            swipeEnabled={false}
            elements={actionFormTabsElements}
            currentTab={currentTab}
          />
        </VStack>
        {renderTabs()}
      </ActivityFormContainer>
    </Scaffold>
    // </Permissions.ValidateApp>
  );
};
