import { LocationOptionsEnum } from 'ui/enums';

import { ICreateActivityDTO, IEditEventDTO } from '~/types/dtos';

import { LocationData } from './AddEvent/EventForm/WhenWhereForm/MultipleLocations/types';

export enum EventFormTabsPages {
  About = 0,
  Media = 1,
  WhenWhere = 2,
}

export interface WhenWhereFormProps {
  handleContinue: (data: ICreateActivityDTO) => void;
  handleBack: () => void;
  isLoading?: boolean;
  isEventFromHome?: boolean;
  isTeamEvent?: boolean;
  currentLocationOption: LocationOptionsEnum;
  handleUpdateLocationOption: (locationOption: LocationOptionsEnum) => void;
  defaultLocationGroupsByDate?: Record<number, LocationData[]>;
}
export interface EditWhenWhereFormProps
  extends Omit<
    WhenWhereFormProps,
    'handleContinue' | 'handleUpdateLocationOption'
  > {
  handleContinue: (data: IEditEventDTO) => void;
  defaultValues: IEditEventDTO;
  isLoadingDefaultValues?: boolean;
}
