import { Link, Text } from 'native-base';
import Icon from 'ui/components/Icon';
import { ORG_HELP_CENTER_URL } from 'ui/constants/commonUrl.constants';
import { colors } from 'ui/theme/colors';

export const HelpCentreRedirectLink = () => {
  return (
    <Link
      href={ORG_HELP_CENTER_URL}
      style={{ gap: 8 }}
      justifyContent="end"
      alignItems="center"
      width="100%"
      paddingBottom={6}
      isExternal
    >
      <Icon icon="question" color={colors.black} size={6} enableAutoCustom />
      <Text fontSize={14} fontWeight={500} letterSpacing="150%">
        Help Centre
      </Text>
    </Link>
  );
};
