import { LogsSummaryTile } from 'ui/components/v2/Tiles/LogsSummaryTile';

import { NotificationCard } from '../../BaseNotificationCard';
import { useMeasurementDeletedNotificationCardController } from './controller';
import { MeasurementDeletedNotificationCardProps } from './types';

export const MeasurementDeletedNotificationCard = (
  props: MeasurementDeletedNotificationCardProps,
) => {
  const { activityOrganizationName, activityAppName, activityTitle } = props;
  const { titleNodes, date, profilePictureUrl, measurementSummary } =
    useMeasurementDeletedNotificationCardController(props);

  return (
    <NotificationCard
      authorImageUrl={profilePictureUrl}
      titleNodes={titleNodes}
      date={date}
    >
      <LogsSummaryTile
        appName={activityAppName}
        activityTitle={activityTitle}
        organizationName={activityOrganizationName}
        measurementSummaries={[measurementSummary]}
      />
    </NotificationCard>
  );
};
