import { ActivitiesPermissions } from '~/constants/permissions.org.constants';
import { useAppSelector } from '~/hooks/useAppSelector';

import { ModalAddActivityProps } from './types';

export const useModalAddActivityController = ({
  onAddEvent,
  onAddAction,
  onAddOngoingOpportunity,
}: Partial<ModalAddActivityProps>) => {
  const { allPartnerPermissionsFromAvailableApps } = useAppSelector(
    ({ permissions }) => permissions,
  );

  const { selectedEcosystem } = useAppSelector(({ ecosystem }) => ecosystem);
  const addButtonData = [
    {
      label: 'New event',
      description: 'Activities with fixed day and time.',
      action: onAddEvent,
      // permissions: ActivitiesPermissions.NewActivityModal.Event,
    },
    {
      label: 'New action',
      description: 'Smaller activities to help the community.',
      action: onAddAction,
      // permissions: ActivitiesPermissions.NewActivityModal.Action,
    },
    {
      label: 'New ongoing opportunity',
      description: 'Activities with long-term commitment.',
      action: onAddOngoingOpportunity,
      // permissions: ActivitiesPermissions.NewActivityModal.Ongoing,
    },
  ];

  const filteredButtonCards = addButtonData.filter((button) => {
    const ecosystemPermissions = selectedEcosystem?._id
      ? allPartnerPermissionsFromAvailableApps[selectedEcosystem._id] || []
      : [];

    // const hasPermissions = ecosystemPermissions.some((partnerPermission) => {
    //   return button.permissions.some((buttonPermission) => {
    //     return buttonPermission.name === partnerPermission.name;
    //   });
    // });

    return true;
    // return hasPermissions;
  });

  return { allPartnerPermissionsFromAvailableApps, filteredButtonCards };
};
