import { App } from 'ui/types/interfaces/app';

export type UpdatesFeedProps = {
  apps: App[];
};

export enum UpdatesFeedCategory {
  AllUpdates = 'AllUpdates',
  ActivityLogs = 'ActivityLogs',
  Applications = 'Applications',
  NewActivities = 'NewActivities',
  NewMembers = 'NewMembers',
}
