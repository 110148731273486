import { useCallback, useRef, useState } from 'react';
import { toast } from 'react-toastify';
import { ISort } from 'ui/components/DataTable/@types/filterTypes';
import { IFilterValue } from 'ui/components/DataTable/types';
import { IModalRefProps } from 'ui/components/Modals/Modal/types';

import { ORGANIZATION_MESSAGES } from '~/constants/messages.constants';
import { PAGES } from '~/constants/pages.constants';
import { useAppSelector } from '~/hooks/useAppSelector';
import { useRouter } from '~/hooks/useRouter';
import { EcosystemService } from '~/services/resources/ecosystem';

import ActivityReportService from '../../../../../services/resources/activityReport';
import { defaultFiltersValues } from './utils';

export const useChildOrgsListController = () => {
  const [partnerEcosystemToRemove, setPartnerEcosystemToRemove] = useState('');
  const [partnersCount, setPartnersCount] = useState(0);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [refetchQuantity, setRefetchQuantity] = useState(0);
  const modalRef = useRef<IModalRefProps>(null);

  const { organizationSelectedId } = useAppSelector(({ auth }) => auth);
  const { selectedEcosystem } = useAppSelector(({ ecosystem }) => ecosystem);

  const { goToRoute } = useRouter();

  const reportModalRef = useRef<IModalRefProps>(null);
  const handleOpenReportModal = () => {
    reportModalRef.current?.open();
  };

  const handleExportChildOrgReport = async () => {
    if (!organizationSelectedId) return;
    await ActivityReportService.childOrgReport(organizationSelectedId);
  };

  const handleOpenModalRemovePartner = (partnerEcosystemToRemove: string) => {
    modalRef.current?.open();
    setPartnerEcosystemToRemove(partnerEcosystemToRemove);
  };

  const handleCloseModal = () => {
    modalRef.current?.close();
  };

  const handleViewPartner = (organizationId: string, connectionId: string) => {
    goToRoute(`${PAGES.PARTNERS}/${organizationId}/${connectionId}`);
  };

  const handleRemovePartner = async () => {
    try {
      if (!selectedEcosystem) return;
      setIsSubmitting(true);
      await EcosystemService.disconnect({
        ecosystemOne: selectedEcosystem._id,
        ecosystemTwo: partnerEcosystemToRemove,
      });
      toast.success(ORGANIZATION_MESSAGES.REMOVE_PARTNER_SUCCESS);
      handleCloseModal();
      setRefetchQuantity(refetchQuantity + 1);
    } catch (error) {
      toast.error(ORGANIZATION_MESSAGES.ERROR_ON_REMOVE_PARTNER);
    } finally {
      setIsSubmitting(false);
    }
  };

  const showPartnersCountText = () => {
    if (partnersCount == 0) {
      return 'No Child Organisation';
    }
    return `${partnersCount} Child Organisation${partnersCount > 1 ? 's' : ''}`;
  };

  const loadPartners = useCallback(
    async (
      skip: number,
      limit: number,
      filter: Record<string, any> | null,
      sort?: ISort | null,
    ) => {
      try {
        if (!selectedEcosystem) return { data: [], count: 0 };

        if (filter?.organization) {
          filter.organization = filter.organization.$regex;
        }
        if (filter?.location) {
          filter.location = filter.location.$regex;
        }
        const sortBy: any = {};
        if (sort?.organization) {
          sortBy['organizationOne.name'] = sort.organization;
          sortBy['organizationTwo.name'] = sort.organization;
        }
        if (sort?.location) {
          sortBy['organizationOne.fullAddress.street'] = sort.location;
          sortBy['organizationTwo.fullAddress.street'] = sort.location;
        }

        const {
          data: { data, count },
        } = await EcosystemService.findChildPartners(selectedEcosystem._id, {
          skip,
          limit,
          filter: JSON.stringify(filter),
          sort: Object.keys(sortBy).length ? JSON.stringify(sortBy) : undefined,
        });
        setPartnersCount(count);
        return { data, count };
      } catch (error) {
        toast.error(ORGANIZATION_MESSAGES.ERROR_ON_LOAD_PARTNERS);
        return { data: [], count: 0 };
      }
    },
    [selectedEcosystem],
  );

  return {
    modalRef,
    organizationSelectedId,
    partnersCount,
    isSubmitting,
    refetchQuantity,
    showPartnersCountText,
    loadPartners,
    handleCloseModal,
    handleOpenModalRemovePartner,
    handleViewPartner,
    handleRemovePartner,
    handleOpenReportModal,
    reportModalRef,
    handleExportChildOrgReport,
    defaultFilterValues: defaultFiltersValues,
  };
};
