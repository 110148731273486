import { colors } from '~/theme/colors';

export const styles = {
  button: {
    _text: {
      color: 'primary.400',
    },
  },
  badge: {
    _text: {
      color: 'gray.500',
    },
  },
  link: {
    fontFamily: 'poppins, sans-serif',
    fontSize: '14px',
    fontWeight: 500,
    color: colors.primary[400],
  },
};
