import { Skeleton, VStack } from 'native-base';
import Chart from 'react-apexcharts';

import { InsightsGenericEmptyState } from '~/pages/Authenticated/Insights/components/EmptyState';

export type AreaChartDataParams = {
  series: {
    name: string;
    data: number[];
  }[];
  labels: string[];
};

type AreaChartProps = AreaChartDataParams & {
  isLoading?: boolean;
};

const AreaChartSkeleton = () => <Skeleton height="180px" width="100%" />;

const AreaChartEmptyState = () => (
  <VStack>
    <InsightsGenericEmptyState />
  </VStack>
);

export const AreaChart = ({ labels, series, isLoading }: AreaChartProps) => {
  if (isLoading) {
    return <AreaChartSkeleton />;
  }

  if (!labels.length) {
    return <AreaChartEmptyState />;
  }

  return (
    <Chart
      series={series}
      type="area"
      height={160}
      width={'100%'}
      options={{
        labels,
        dataLabels: {
          enabled: false,
        },
        chart: {
          id: 'sparkline3',
          group: 'sparklines',
          sparkline: {
            enabled: true,
          },
        },
        legend: {
          show: false,
        },
        stroke: {
          curve: 'straight',
        },
        fill: {
          opacity: 1,
        },
        xaxis: {
          type: 'datetime',
        },
        yaxis: {
          min: 0,
          labels: {
            show: false,
          },
        },
        colors: ['#018F88'],
      }}
    />
  );
};
