import { Box, Stack, VStack } from 'native-base';
import FormHeader from 'ui/components/FormBuilder/components/FormHeader';

import { Loading } from '~/components/Loading';
import { PermissionsFormList } from '~/pages/Authenticated/AddOrgMember/components/Form/PermissionsFormSection/components/PermissionsFormList';

import { usePermissionsFormSectionController } from './permissionsFormSectionController';
import { IPermissionsFormSectionProps } from './types';

export const PermissionsFormSection = ({
  control,
  memberType,
  appsConnectedToOrganization,
}: IPermissionsFormSectionProps) => {
  const {
    appsMap,
    isLoading,
    selectedMemberPermissions,
    selectedPartnerPermissions,
    appsPermissionsGroupMemberOptions,
    appsPermissionsGroupPartnerOptions,
    appsSelected,
    handleChangeSelectedPartnerPermission,
    handleChangeSelectedMemberPermission,
  } = usePermissionsFormSectionController(
    memberType,
    appsConnectedToOrganization,
  );

  if (isLoading) {
    return <Loading containerHeight="30vh" spinnerSize="sm" />;
  }

  return (
    <Stack justifyContent="center" w="100%" alignItems="center" p={6}>
      <VStack w="620px">
        <FormHeader
          title="Permissions"
          subTitle="Select the member’s group of permissions"
        />
        <Box h="8" />
        <Stack space={10}>
          {appsSelected?.map((app, index) => (
            <PermissionsFormList
              index={index}
              key={app.appId}
              control={control}
              memberType={memberType}
              app={appsMap[app.appId]}
              appsPermissionsGroupMemberOptions={
                appsPermissionsGroupMemberOptions
              }
              appsPermissionsGroupPartnerOptions={
                appsPermissionsGroupPartnerOptions
              }
              handleChangeSelectedPartnerPermission={
                handleChangeSelectedPartnerPermission
              }
              handleChangeSelectedMemberPermission={
                handleChangeSelectedMemberPermission
              }
              selectedMemberPermissions={selectedMemberPermissions}
              selectedPartnerPermissions={selectedPartnerPermissions}
              ecosystemId={app.ecosystemId}
            />
          ))}
        </Stack>
      </VStack>
    </Stack>
  );
};
