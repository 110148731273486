import { VStack } from 'native-base';
import Button from 'ui/components/Button';
import Tabs from 'ui/components/Tabs';

import { Loading } from '~/components/Loading';
import ModalAddMembers from '~/components/ModalAddMembers';
import Scaffold from '~/components/Scaffold';
import ChildOrgsList from '~/pages/Authenticated/Members/ChildOrgs/ChildOrgsList';
import { useMembersController } from '~/pages/Authenticated/Members/controller';
import PartnersList from '~/pages/Authenticated/Members/Partners/PartnersList';
import { PendingInvites } from '~/pages/Authenticated/Members/PendingInvites';
import { TabsPages } from '~/pages/Authenticated/Members/types';
import { colors } from '~/theme/colors';

import MembersList from './Member/MembersList';
import { membersTabsElements } from './tabElements';

const Members = () => {
  const {
    organization,
    isLoadingOrganization,
    tabsRef,
    currentTab,
    handleChangeTab,
    modalRef,
    handleOpenAddMembersModal,
    handleNavigateToAddMember,
    handleNavigateToAddPartner,
    handleNavigateToAddChildOrg,
  } = useMembersController();

  const renderTabs = () => {
    switch (currentTab) {
      case TabsPages.Members:
        return <MembersList organization={organization} />;
      case TabsPages.Partners:
        return <PartnersList />;
      case TabsPages.ChildOrg:
        return <ChildOrgsList />;
      case TabsPages.PendingInvites:
        return <PendingInvites />;
    }
  };

  if (isLoadingOrganization) {
    return <Loading />;
  }

  return (
    <>
      <Scaffold
        title="Members"
        titleIcon="users"
        titleIconSize={32}
        actionComponents={
          <Button
            variant="solid"
            py="5"
            bg="primary.400"
            leftIconName="plus-circle"
            leftIconColor={colors.white[500]}
            onPress={handleOpenAddMembersModal}
            width={'fit-content'}
          >
            Add member
          </Button>
        }
      >
        <VStack w={'full'} width="100%" alignItems="center" pb={10}>
          <Tabs
            ref={tabsRef}
            tabBgColor="transparent"
            onChangeTab={handleChangeTab}
            tabWith="100%"
            tabTextColor={colors.primary['600']}
            tabIndicatorColor={colors.primary['600']}
            tabfocusedTextColor={colors.primary['600']}
            isClickableTab
            swipeEnabled={false}
            elements={membersTabsElements}
            defaultTabIndex={currentTab}
          />
        </VStack>
        {renderTabs()}
      </Scaffold>
      <ModalAddMembers
        ref={modalRef}
        onAddOrgMember={handleNavigateToAddMember}
        onAddChildOrg={handleNavigateToAddChildOrg}
        onAddPartner={handleNavigateToAddPartner}
      />
    </>
  );
};

export default Members;
