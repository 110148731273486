import { HStack, Stack, Text, VStack } from 'native-base';
import { colors } from 'ui/theme/colors';

import { TeamMemberStatus } from '../../../types/teams';
import { TeamAcceptedMemberCard } from '../TeamMemberCard/TeamAcceptedMemberCard';
import { TeamCandidateMemberCard } from '../TeamMemberCard/TeamCandidateMemberCard';
import { TeamMemberListProps } from './types';

export const TeamMemberList = (props: TeamMemberListProps) => {
  const {
    title,
    membersData,
    showTopBar = true,
    emptyMessage = 'Currently no members in the team.',
    onDeleteMember,
    showDeleteMemberButton,
  } = props;

  const isEmpty = membersData.length == 0;

  return (
    <VStack
      space={2}
      borderColor={colors.gray[200]}
      borderTopWidth={!!showTopBar ? 1 : 0}
      paddingTop={showTopBar ? 6 : 0}
      maxH={{ base: 'unset', md: '280px' }}
      overflowY={'auto'}
      overflowX={'hidden'}
    >
      <Text fontWeight={500} fontSize={'sm'} color={colors.gray[600]}>
        {title}
      </Text>
      <HStack
        flex={1}
        flexWrap="wrap"
        justifyContent={'flex-start'}
        alignItems={'flex-start'}
        space={1}
      >
        {isEmpty ? (
          <Text color={colors.singletons.darkText}>{emptyMessage}</Text>
        ) : (
          membersData.map((memberData) => (
            <Stack
              w={{ base: '100%', md: 'calc(25% - .5rem)' }}
              minW={'240px'}
              py={2}
              key={memberData._id}
            >
              {memberData.status === TeamMemberStatus.ACCEPTED ? (
                <TeamAcceptedMemberCard {...memberData} />
              ) : (
                <TeamCandidateMemberCard
                  {...memberData}
                  onDeleteMember={() =>
                    onDeleteMember?.({
                      _id: memberData._id,
                      isInternalMember: memberData.isInternalMember,
                    })
                  }
                  showDeleteMemberButton={showDeleteMemberButton}
                />
              )}
            </Stack>
          ))
        )}
      </HStack>
    </VStack>
  );
};

export default TeamMemberList;
