import { OrgPlusContentViewedFlags } from '~/enums';
import { IOrganization } from '~/types/interfaces/organization';

export const hasSomeAdminEditedEcosystem = (organization: IOrganization) => {
  return (
    organization?.contentViewedFlags &&
    organization.contentViewedFlags &
      OrgPlusContentViewedFlags.firstEditEcosystemModal
  );
};
