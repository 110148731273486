import { Checkbox, HStack, Image, Text } from 'native-base';

import { IAppModel } from '../../types';

export const EcosystemAppItem = ({
  _id,
  name,
  logo,
  isChecked,
  isDisabled,
}: IAppModel) => {
  return (
    <HStack>
      <Checkbox
        isChecked={isChecked}
        isDisabled={isDisabled}
        value={_id}
        my={2}
        accessibilityLabel="App Item Checkbox"
      >
        <Image
          borderWidth={1}
          borderColor="gray.300"
          borderRadius="8"
          h="8"
          w="8"
          source={{ uri: logo }}
          alt="App logo"
        />
        <Text>{name}</Text>
      </Checkbox>
    </HStack>
  );
};
