import { yupResolver } from '@hookform/resolvers/yup';
import { useCallback, useMemo } from 'react';
import { useFieldArray, useForm } from 'react-hook-form';
import { EActivityAttendanceTypes, LocationOptionsEnum } from 'ui/enums';

import { IDatesDTO, IEditEventDTO, IPlacesDTO } from '~/types/dtos';

import { WhenWhereFormProps } from './types';
import { multipleSchemaValidation } from './utils';

export const useWhenWhereFormController = ({
  handleContinue,
  handleBack,
  defaultValues,
  isLoading,
}: WhenWhereFormProps) => {
  const {
    control,
    handleSubmit,
    watch,
    formState: { isValid, errors, isDirty },
  } = useForm<IEditEventDTO>({
    resolver: yupResolver(multipleSchemaValidation),
    mode: 'onChange',
    reValidateMode: 'onBlur',
    // Change to avoid blank form with tabId search param from Activity Details
    values: defaultValues,
  });

  const {
    fields: placesFields,
    append: appendPlace,
    remove: removePlace,
    update: updatePlace,
  } = useFieldArray<IEditEventDTO>({
    control,
    name: 'places',
  });

  const handleSubmitForm = handleSubmit((data: IEditEventDTO) => {
    handleContinue(data);
  });

  const formValues = watch();

  const { isRemoteEvent, isDisabledPublishButton } = useMemo(() => {
    return {
      isRemoteEvent:
        formValues['attendanceType'] === EActivityAttendanceTypes.Remote,
      isDisabledPublishButton: !isValid,
    };
  }, [formValues, isValid]);

  const currentLocationType = watch('locationOption') as LocationOptionsEnum;

  return {
    placesFields,
    appendPlace,
    handleBack,
    handleSubmitForm,
    control,
    isLoading,
    isDisabledPublishButton,
    errors,
    isRemoteEvent,
    defaultValues,
    isDirty,
    currentLocationType,
  };
};
