import { useState } from 'react';

export const useSelectedAppCardController = (
  onChangeSwitch?: (value: boolean) => void,
) => {
  const [on, setOn] = useState(false);

  const handleOnChange = (value: boolean) => {
    setOn(value);
    if (onChangeSwitch) onChangeSwitch(value);
  };

  return { on, handleOnChange };
};
