import { HStack, Stack, Text, VStack } from 'native-base';
import Button from 'ui/components/Button';
import Input from 'ui/components/Input';
import Modal from 'ui/components/Modals/Modal';

import { useCustomUnitModalController } from './controller';
import { CustomUnitModalProps } from './types';

export const CustomUnitModal = ({
  cancelAction,
  modalRef,
  closeModal,
  onAddedCustomUnit,
}: CustomUnitModalProps) => {
  const { control, handleSubmit, errors, onSubmit, isDisabled, isSubmitting } =
    useCustomUnitModalController(closeModal, onAddedCustomUnit);

  return (
    <Modal headerText={'Add custom unit'} modalContentMaxW="600" ref={modalRef}>
      <VStack space={12}>
        <VStack space={3}>
          <Text>Define the new unit of measurement</Text>
        </VStack>
        <HStack w="full" justifyContent="space-around">
          <Stack>
            <Input
              name="singularLabel"
              control={control}
              label="Singular form"
              isRequired
              placeholder="e.g. Plastic bottle"
              errorMessage={errors?.singularLabel?.message}
            />
          </Stack>
          <Stack>
            <Input
              name="pluralLabel"
              control={control}
              label="Plural form"
              isRequired
              placeholder="e.g. Plastic bottles"
              errorMessage={errors?.pluralLabel?.message}
            />
          </Stack>
        </HStack>
        <HStack alignItems="center">
          <Button variant="link" w="50%" onPress={cancelAction}>
            <Text fontSize="md" fontWeight={500} color="singletons.black">
              Cancel
            </Text>
          </Button>
          <Button
            colorScheme="tertiary"
            w="50%"
            bgColor="primary.600"
            onPress={handleSubmit(onSubmit)}
            isDisabled={isDisabled}
            isLoading={isSubmitting}
          >
            <Text fontSize="md" fontWeight={500} color="singletons.white">
              Add unit
            </Text>
          </Button>
        </HStack>
      </VStack>
    </Modal>
  );
};
