import { useEffect, useMemo, useRef, useState } from 'react';
import { IModalRefProps } from 'ui/components/Modals/Modal/types';

import { useActivityFormCommonController } from '~/components/ActivityFormBuilderPresets/Controllers/useActivityFormCommonController';

import { AboutFormProps, AppsForSelector } from './types';
import { createFormSchema } from './utils';

export const useAboutFormController = ({
  handleContinue,
  handleBack,
  defaultValues,
  causeOptions,
  isLoading,
  isLoadingDefaultValues,
  connectedApps,
  requirementOptions,
}: AboutFormProps) => {
  const defaultSelectedApp = (defaultValues as any)?.selectedApp?.[0] || '';
  const modalRef = useRef<IModalRefProps>(null);

  const [appsForSelector, setAppsForSelector] = useState<AppsForSelector[]>([]);

  const commonController = useActivityFormCommonController({
    defaultSelectedApp,
  });

  const handleOpenModal = () => {
    modalRef.current?.open();
  };

  const formSchema = useMemo(() => {
    return createFormSchema({
      causeOptions,
      requirementOptions,
      connectedApps: appsForSelector,
      commonController,
      handleOpenModal,
      handleBack,
    });
  }, [
    causeOptions,
    requirementOptions,
    appsForSelector,
    handleBack,
    commonController,
  ]);

  useEffect(() => {
    if (connectedApps && connectedApps?.length > 0) {
      const mappedApps = connectedApps.map((conenctedApp) => ({
        name: conenctedApp.name,
        value: conenctedApp._id,
        logo: conenctedApp.logo,
      }));

      setAppsForSelector(mappedApps);
    }
  }, [connectedApps]);

  return {
    //---FormBuilder--//
    causeOptions,
    formSchema,
    defaultValues,
    handleContinue,
    isLoading,
    isLoadingDefaultValues,
    appsForSelector,
    modalRef,
    formBuilderImperativeRef: commonController.formBuilderImperativeRef,
  };
};
