import { combineReducers } from '@reduxjs/toolkit';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import { REDUX_KEY, REDUX_WHITELIST } from '~/constants/redux.constants';
import { addMemberReducer } from '~/store/slices/addMember';
import { authSliceReducer } from '~/store/slices/auth';
import { ecosystemSliceReducer } from '~/store/slices/ecosystem';
import { forgotPasswordSliceReducer } from '~/store/slices/forgotPassword';
import { joinAsAdminSliceReducer } from '~/store/slices/joinAsAdmin';
import { joinAsPartnerSliceReducer } from '~/store/slices/joinAsPartner';
import { notificationSliceReducer } from '~/store/slices/notification';
import { redirectSliceReducer } from '~/store/slices/redirect';

import { permissionsSliceReducer } from './slices/permissions';

export const combinedReducers = combineReducers({
  auth: authSliceReducer,
  forgotPassword: forgotPasswordSliceReducer,
  joinAsAdmin: joinAsAdminSliceReducer,
  ecosystem: ecosystemSliceReducer,
  addMember: addMemberReducer,
  joinAsPartner: joinAsPartnerSliceReducer,
  redirect: redirectSliceReducer,
  permissions: permissionsSliceReducer,
  notifications: notificationSliceReducer,
});

export const persistedReducers = persistReducer(
  {
    key: REDUX_KEY,
    storage,
    whitelist: REDUX_WHITELIST,
  },
  combinedReducers,
);
