import { Switch as RNSwitch } from 'native-base';
import { Controller } from 'react-hook-form';

import { SwitchProps } from './types';

const Switch = ({ control, name }: SwitchProps) => {
  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { value, onChange } }) => (
        <RNSwitch value={value} onValueChange={onChange} />
      )}
    />
  );
};

export default Switch;
