import { IDatesDTO } from '~/types/dtos';

import {
  LocationData,
  LocationDataStatus,
} from './EditEvent/EditEventForm/WhenWhereForm/MultipleLocations/types';
import { formInitialDefaultValues } from './EditEvent/EditEventForm/WhenWhereForm/SingleLocation/utils';

export const queryFilter = {
  causeOptions: {
    filter: JSON.stringify({ relatedTo: ['causeOptions'] }),
  },
  requirementOptions: {
    filter: JSON.stringify({ relatedTo: ['requirementOptions'] }),
  },
};

export const mapSingleLocationFormValues = (
  allActivitiesByDefinitionData: any,
) => {
  if (allActivitiesByDefinitionData.length > 0) {
    const dates: Partial<IDatesDTO>[] = [];

    const [lng, lat] = allActivitiesByDefinitionData?.[0]?.address?.location
      ?.coordinates || [0, 0];

    const rawLocation =
      allActivitiesByDefinitionData?.[0]?.address.street || '';

    const address = {
      lat,
      lng,
      placeID: null,
      rawLocation: rawLocation,
    };

    try {
      for (const [index, activity] of allActivitiesByDefinitionData.entries()) {
        const date: IDatesDTO = {
          noVolunteerLimit: !activity.isVolunteerNumberLimited,
          volunteerNumber: activity.volunteerNumber ?? '',
          startDate: new Date(activity.startDate),
          endDate: new Date(activity.endDate),
          dueDate: new Date(activity.dueDate),
          placeFormIndex: 0,
          indexPosition: index,
          teamsMinSize: activity.teamsMinSize,
          teamsMaxSize: activity.teamsMaxSize,
          teamsNumber: activity.teamsNumber,
          _activityId: activity._id,
          externalApplyLink: activity.externalApplyLink || '',
        };

        dates.push(date);
      }
      return { dates, address };
    } catch (error) {
      throw new Error('Error Loading Places');
    }
  } else {
    return formInitialDefaultValues;
  }
};
export const mapMultipleLocationsFormValue = (
  allActivitiesByDefinitionData: any,
) => {
  const locationGroups: Partial<IDatesDTO>[] = [];

  let locationGroupsByDate: Record<number, LocationData[]> = {};

  try {
    for (const [index, activity] of allActivitiesByDefinitionData.entries()) {
      const alreadyExistsDateIndex = locationGroups?.findIndex(
        (locationGroup) => {
          const isEqualStartDate =
            locationGroup.startDate?.toISOString() ===
            new Date(activity.startDate).toISOString();
          const isEqualEndDate =
            locationGroup.endDate?.toISOString() ===
            new Date(activity.endDate).toISOString();

          return isEqualStartDate && isEqualEndDate;
        },
      );

      if (alreadyExistsDateIndex === -1) {
        const locationGroup: IDatesDTO = {
          noVolunteerLimit: !activity.isVolunteerNumberLimited,
          volunteerNumber: activity.volunteerNumber ?? '',
          startDate: new Date(activity.startDate),
          endDate: new Date(activity.endDate),
          dueDate: new Date(activity.dueDate),
          placeFormIndex: 0,
          indexPosition: index,
          teamsMinSize: activity.teamsMinSize,
          teamsMaxSize: activity.teamsMaxSize,
          teamsNumber: activity.teamsNumber,
          _activityId: activity._id,
          externalApplyLink: activity.externalApplyLink || '',
        };

        locationGroups.push(locationGroup);

        const [lng, lat] = activity.address?.location?.coordinates;

        const locationData: LocationData = {
          returnedAddress: activity.address.street,
          sentInput: activity.address.street,
          status: LocationDataStatus.OK,
          location: { lat, lng },
          _activityId: activity._id,
        };

        const locationsGroupsKeys = Object.keys(locationGroupsByDate);

        locationGroupsByDate = {
          ...locationGroupsByDate,
          [locationsGroupsKeys.length]: [{ ...locationData }],
        };
      } else {
        const [lng, lat] = activity.address?.location?.coordinates;

        const locationData: LocationData = {
          returnedAddress: activity.address.street,
          sentInput: activity.address.street,
          status: LocationDataStatus.OK,
          location: { lat, lng },
          _activityId: activity._id,
        };

        locationGroupsByDate = {
          ...locationGroupsByDate,
          [alreadyExistsDateIndex]: [
            ...locationGroupsByDate[alreadyExistsDateIndex],
            locationData,
          ],
        };
      }
    }
    return { locationGroups, locationGroupsByDate };
  } catch (error) {
    throw new Error('Error Loading Places');
  }
};

export const mapFromHomeLocationFormValue = (
  allActivitiesByDefinitionData: any,
) => {
  const dates: Partial<IDatesDTO>[] = [];

  try {
    for (const [index, activity] of allActivitiesByDefinitionData.entries()) {
      const date: IDatesDTO = {
        noVolunteerLimit: !activity.isVolunteerNumberLimited,
        volunteerNumber: activity.volunteerNumber ?? '',
        startDate: new Date(activity.startDate),
        endDate: new Date(activity.endDate),
        dueDate: new Date(activity.dueDate),
        placeFormIndex: 0,
        indexPosition: index,
        teamsMinSize: activity.teamsMinSize,
        teamsMaxSize: activity.teamsMaxSize,
        teamsNumber: activity.teamsNumber,
        _activityId: activity._id,
        externalApplyLink: activity.externalApplyLink || '',
      };

      dates.push(date);
    }
    return { dates };
  } catch (error) {
    throw new Error('Error Loading Places');
  }
};
