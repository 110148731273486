import { VStack } from 'native-base';
import Tabs from 'ui/components/Tabs';

import { ActivityFormContainer } from '~/components/ActivityFormBuilderPresets/components/ActivityFormContainer';
import { MediaForm } from '~/components/MediaForm';
import Scaffold from '~/components/Scaffold';
import { AlertImportedActivity } from '~/pages/Authenticated/Activities/components/AlertImportedActivity';
import { colors } from '~/theme/colors';

import { AboutForm } from './AboutForm';
import { useOpportunityFormController } from './controller';
import { opportunityFormTabsElements } from './tabsElements';
import { OpprtunityFormTabPages } from './types';
import { WhenWhereForm } from './WhenWhereForm';

export const EditOpportunityForm = () => {
  const {
    tabsRef,
    currentTab,
    causeOptions,
    requirementOptions,
    formData,
    handleBack,
    handleContinue,
    isLoading,
    isLoadingDefaultValues,
    filteredAppsByPermissions,
    appIdToValidatePermisssions,
    handleUpdateLocationOption,
    isAppOwner,
    defaultLocationGroupsByDate,
  } = useOpportunityFormController();

  const renderTabs = () => {
    return {
      [OpprtunityFormTabPages.About]: (
        <AboutForm
          handleContinue={handleContinue}
          handleBack={handleBack}
          causeOptions={causeOptions}
          requirementOptions={requirementOptions}
          defaultValues={formData}
          isLoading={isLoading}
          connectedApps={filteredAppsByPermissions}
          isLoadingDefaultValues={isLoadingDefaultValues}
        />
      ),
      [OpprtunityFormTabPages.Media]: (
        <MediaForm
          handleBack={handleBack}
          handleContinue={handleContinue}
          defaultValues={formData}
          isLoading={isLoading}
        />
      ),
      [OpprtunityFormTabPages.WhenWhere]: (
        <WhenWhereForm
          handleBack={handleBack}
          handleContinue={handleContinue}
          isLoading={isLoading}
          isLoadingDefaultValues={isLoadingDefaultValues}
          defaultValues={formData}
          handleUpdateLocationOption={handleUpdateLocationOption}
          defaultLocationGroupsByDate={defaultLocationGroupsByDate}
        />
      ),
    }[currentTab];
  };

  return (
    // disabled permissions validation for now
    // <Permissions.ValidateApp
    //   disablePermissionsValidation={!formData?.selectedApp || isAppOwner}
    //   appIdToValidate={appIdToValidatePermisssions}
    //   requiredPermissions={CreateEditActivityPermissions.Ongoing}
    //   fallbackComponent={<Page403 />}
    // >
    <Scaffold title="Edit ongoing opportunity" alignItemsChildren="center">
      <ActivityFormContainer>
        <VStack w="100%" alignItems="center" pb={10}>
          {formData.externalId && formData.externalProvider && (
            <AlertImportedActivity mb={10} />
          )}
          <Tabs
            ref={tabsRef}
            tabBgColor="transparent"
            tabWith="100%"
            tabTextColor={colors.primary['600']}
            tabIndicatorColor={colors.primary['600']}
            tabfocusedTextColor={colors.primary['600']}
            swipeEnabled={false}
            elements={opportunityFormTabsElements}
            currentTab={currentTab}
          />
        </VStack>
        {renderTabs()}
      </ActivityFormContainer>
    </Scaffold>
    // </Permissions.ValidateApp>
  );
};
