import { Stack, Text } from 'native-base';
import Modal from 'ui/components/Modals/Modal';
import TextArea from 'ui/components/TextArea';

import { colors } from '~/theme/colors';

import { ModalDeclineApplicationProps } from './types';

export const ModalDeclineApplication = ({
  modalRef,
  headerText,
  handleCloseModal,
  handleDecline,
  rightBtnText = 'Yes, decline',
  rightBtnLoading,
  selectedApplicationsCount,
  control,
}: ModalDeclineApplicationProps) => {
  return (
    <Modal
      headerText={headerText}
      ref={modalRef}
      modalContentMaxW="700"
      leftBtnAction={handleCloseModal}
      leftBtnText="No, go back"
      rightBtnAction={handleDecline}
      rightBtnText={rightBtnText}
      rightBtnBgColor={colors.error[600]}
      rightBtnLoading={rightBtnLoading}
    >
      <Stack py="2" space={6}>
        <Stack space={6}>
          <Stack space={8}>
            <Text fontSize="md">
              You are declining{' '}
              <Text fontWeight="medium">
                {selectedApplicationsCount}{' '}
                {selectedApplicationsCount === 1
                  ? 'application'
                  : 'applications'}
              </Text>
            </Text>
            <Text fontSize="md">
              {
                "You can't undo this action. Once you have declined the application, we will notify the participant."
              }
            </Text>
          </Stack>
          <Stack space={4}>
            <Text fontWeight="medium">Custom message for the participant</Text>
            <TextArea
              name="description"
              autoCompleteType="none"
              placeholder="Explain to the participant the reason why their application is being declined..."
              fontSize="md"
              h="12rem"
              control={control}
            />
          </Stack>
        </Stack>
      </Stack>
    </Modal>
  );
};
