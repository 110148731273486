import { motion } from 'framer-motion';
import { Pressable, Text, VStack } from 'native-base';
import { colors } from 'ui/theme/colors';

import { TabItemProps } from './types';

export const TabItem = ({ label, isActive, onPress }: TabItemProps) => {
  const textColor = isActive ? colors.primary[400] : colors.darkText;
  const fontWeight = isActive ? 500 : 400;

  return (
    <Pressable
      flex={1}
      minW={'fit-content'}
      backgroundColor="$white"
      onPress={onPress}
      overflow="hidden"
    >
      <VStack
        position="relative"
        px={{ base: 2, md: 6 }}
        py={1}
        pb={1.5}
        height="full"
        alignItems="center"
        justifyContent="center"
        margin={'auto'}
        overflow="hidden"
      >
        <Text
          fontSize="sm"
          numberOfLines={1}
          color={textColor}
          fontWeight={fontWeight}
        >
          {label}
        </Text>
      </VStack>

      <motion.div
        style={{
          left: 0,
          bottom: 0,
          position: 'absolute',
          backgroundColor: colors.primary[400],
          width: '100%',
          height: 3,
        }}
        animate={{ opacity: isActive ? 1 : 0, scale: isActive ? 1 : 0 }}
        transition={{ duration: 0.25 }}
      />
    </Pressable>
  );
};

export default TabItem;
