export class CreateOrganizationError extends Error {
  constructor(message: string) {
    super(message);
    this.name = 'CreateOrganizationError';
  }
}

export class CreateInviteCodeError extends Error {
  constructor(message: string) {
    super(message);
    this.name = 'CreateInviteCodeError';
  }
}
