import { Flex, Hidden, HStack, Stack, Text } from 'native-base';
import Loading from 'ui/components/Loading';
import { RedirectPage } from 'ui/components/RedirectPage';
import { SignInBranding } from 'ui/components/SignInBranding';
import { PoweredByDoit } from 'ui/components/v2/Labels/PoweredByDoit';

import ModalPrivacyPolicy from '~/components/ModalPrivacyPolicy';
import ModalTermsOfService from '~/components/ModalTermsOfService';
import { BHO_ORGANIZATION } from '~/config';
import { colors } from '~/theme/colors';

import { useJoinAsPartnerSignInController } from './controller';
import { SignInPage } from './SignInPage';

export const JoinAsPartnerSignIn = () => {
  const {
    inviteCode,
    isLoading,
    modalPrivacyPolicyRef,
    modalTermsOfServiceRef,
    control,
    canSignIn,
    errors,
    isSubmitting,
    organization,
    isMobile,
    onForgotPasswordPress,
    onSubmit,
    onKeyDown,
    onSignUpPress,
    goToDoitSignIn,
  } = useJoinAsPartnerSignInController();

  if (isLoading) {
    return <Loading />;
  }

  if (!inviteCode) {
    return (
      <RedirectPage
        title="Invite not found"
        text="We couldn't find your invitation. Please contact the owner of the invitation."
        onButtonPress={goToDoitSignIn}
        buttonColor={colors.gray[900]}
      />
    );
  }

  return (
    <Flex
      minH="100%"
      height="100%"
      width="100%"
      flexDir={{ base: 'column', lg: 'row' }}
    >
      <Stack
        minHeight={{ base: '35vh', lg: 'full' }}
        width={{ base: '100%', lg: '55%' }}
      >
        <SignInBranding
          ecosystemName={inviteCode?.configurations[0]?.ecosystemId?.name || ''}
          logo={inviteCode?.composeSettings?.logo || organization?.logo}
          organizationName={organization?.name || ''}
          backgroundImage={inviteCode?.composeSettings?.backgroundImage}
          brandColor={inviteCode?.composeSettings?.brandColor}
          height={isMobile ? '35vh' : '100vh'}
          isBigHelpOutBranded={
            inviteCode?.sharerOrganization === BHO_ORGANIZATION
          }
        />
      </Stack>

      <Stack
        width={{ base: '100%', lg: '45%' }}
        justifyContent={{ base: 'initial' }}
        padding={{ base: 5, lg: 0 }}
        height={{ base: '70%', lg: '100vh' }}
        bgColor={{ base: 'white', lg: colors.singletons.lightText }}
        borderTopRadius={{ base: 60, md: 0 }}
        mt={{ base: -20, lg: 0 }}
        overflowY="scroll"
      >
        <SignInPage
          control={control}
          isSubmitting={isSubmitting}
          isDisabled={canSignIn || isSubmitting}
          errors={errors}
          onSignUpPress={onSignUpPress}
          onKeyDown={onKeyDown}
          onSubmit={onSubmit}
          onForgotPasswordPress={onForgotPasswordPress}
        />
        <Hidden till="lg">
          <HStack
            width="100%"
            alignItems="center"
            justifyContent="center"
            padding={12}
          >
            <PoweredByDoit />
          </HStack>
        </Hidden>
      </Stack>
      <ModalPrivacyPolicy modalRef={modalPrivacyPolicyRef} />
      <ModalTermsOfService modalRef={modalTermsOfServiceRef} />
    </Flex>
  );
};
