import { GetMeasurementByUserReportResponse } from '~/services/resources/insightMeasurement';
import { GetDepartmentsMeasurementMetricsResponse } from '~/services/resources/insights/types';

export const INSIGHT_TABS = {
  EMPLOYEES: 'Employees',
  DEPARTMENTS: 'Groups',
};

export const insightTabList = Object.values(INSIGHT_TABS).map((tabItem) => ({
  value: tabItem,
}));

export const skeletonData = [
  {
    userProfile: {
      _id: '1',
    },
  },
  {
    userProfile: {
      _id: '2',
    },
  },
  {
    userProfile: {
      _id: '3',
    },
  },
  {
    userProfile: {
      _id: '4',
    },
  },
  {
    userProfile: {
      _id: '5',
    },
  },
] as GetMeasurementByUserReportResponse[];

export const departmentsSkeletonData = Array.from(
  { length: 4 },
  (_, index) => ({
    userProfileDepartment: { _id: (index + 1).toString() },
  }),
) as GetDepartmentsMeasurementMetricsResponse[];
