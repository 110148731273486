import { Spinner, Stack } from 'native-base';
import React from 'react';
import Tabs from 'ui/components/Tabs';

import { colors } from '~/theme/colors';

import AppsForm from './AppsForm';
import { useEcosystemFormController } from './controller';
import { CustomizationForm } from './CustomizationForm';
import DetailsForm from './DetailsForm';
import { IEcosystemFormProps } from './types';

export const EcosystemForm: React.FC<IEcosystemFormProps> = ({
  ecosystemIdToEdit,
  onSubmit,
}) => {
  const {
    control,
    errors,
    isValid,
    tabsRef,
    currentTab,
    isSubmitting,
    isPrivate,
    isLoadingEcosystem,
    handleBack,
    handleOnSubmit,
    handleContinue,
  } = useEcosystemFormController(onSubmit, ecosystemIdToEdit);

  const tabElements = [
    {
      title: 'Ecosystem details',
      component: () => <></>,
    },
    {
      title: 'Customisation',
      component: () => <></>,
    },
  ];

  if (!ecosystemIdToEdit) {
    tabElements.push({
      title: 'Apps',
      component: () => <></>,
    });
  }

  if (ecosystemIdToEdit && isLoadingEcosystem) {
    return (
      <Stack minH="80vh" justifyContent="center">
        <Spinner />
      </Stack>
    );
  }

  return (
    <>
      <Tabs
        ref={tabsRef}
        tabTextColor={colors.primary['600']}
        tabIndicatorColor={colors.primary['600']}
        tabfocusedTextColor={colors.primary['600']}
        swipeEnabled={false}
        elements={tabElements}
      />
      <Stack w="100%" maxW="562px">
        {currentTab === 0 ? (
          <DetailsForm
            isPrivate={isPrivate}
            control={control}
            errors={errors}
            isDisabledButton={!isValid}
            tabsRef={tabsRef}
            handleBack={handleBack}
            handleContinue={handleContinue}
          />
        ) : currentTab === 1 ? (
          <CustomizationForm
            errors={errors}
            control={control}
            isDisabledButton={!isValid}
            tabsRef={tabsRef}
            handleContinue={ecosystemIdToEdit ? handleOnSubmit : handleContinue}
            handleBack={handleBack}
            ecosystemIdToEdit={ecosystemIdToEdit}
            onSubmit={handleOnSubmit}
            isSubmitting={isSubmitting}
          />
        ) : currentTab === 2 && !ecosystemIdToEdit ? (
          <>
            <AppsForm
              errors={errors}
              control={control}
              isEditing={!!ecosystemIdToEdit}
              isDisabledButton={!isValid}
              tabsRef={tabsRef}
              isSubmitting={isSubmitting}
              handleBack={handleBack}
              onSubmit={handleOnSubmit}
            />
          </>
        ) : null}
      </Stack>
    </>
  );
};
