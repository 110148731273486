import { ForwardedRef, useImperativeHandle, useMemo, useState } from 'react';
import { useWindowDimensions } from 'react-native';
import { SceneMap } from 'react-native-tab-view';
import { colors } from 'ui/theme/colors';

import { ITabScene, ITabsRefProps, TabsProps } from './types';

const useTabsController = (
  {
    elements,
    tabBgColor = colors.white,
    tabIndicatorColor = colors.primary[600],
    tabfocusedTextColor = 'primary.600',
    tabTextColor = 'gray.600',
    tabWith = '620px',
    onChangeTab,
    tabHeight = '40px',
    tabBarStyle,
    swipeEnabled = true,
    isClickableTab,
    onPressCallback,
    alignTabBar = 'center',
    showInMobile = false,
    defaultTabIndex = 0,
  }: TabsProps,
  ref: ForwardedRef<ITabsRefProps>,
) => {
  const layout = useWindowDimensions();
  const [index, setIndex] = useState(defaultTabIndex);

  useImperativeHandle(
    ref,
    () => {
      return {
        nextStep() {
          setIndex(index + 1);
          if (onChangeTab) {
            onChangeTab(index + 1);
          }
        },
        backStep() {
          setIndex(index - 1);
          if (onChangeTab) {
            onChangeTab(index - 1);
          }
        },
        jumpTo(jumpIndex: number) {
          if (jumpIndex === index) return;
          setIndex(jumpIndex);
          onChangeTab?.(jumpIndex);
        },
        currentTabIndex: index,
      };
    },
    [index, setIndex, onChangeTab],
  );

  const routes = useMemo(() => {
    return elements.map((el, index) => {
      return {
        key: `${index}`,
        title: el.title,
      };
    });
  }, [elements]);

  const styles = useMemo(() => {
    return {
      indicatorStyle: { backgroundColor: tabIndicatorColor },
      initialLayout: { width: layout.width },
      tabsStyles: { display: 'flex', width: '100%' },
    };
  }, [tabIndicatorColor, layout]);

  const _tabBarStyle = useMemo(() => {
    return tabBarStyle
      ? tabBarStyle
      : {
          backgroundColor: tabBgColor,
          width: tabWith,
          height: tabHeight,
          marginTop: '-10px',
          boxShadow: 'none',
        };
  }, [tabBarStyle, tabBgColor, tabWith, tabHeight]);

  const renderScene = useMemo(() => {
    const scene: ITabScene = {};
    elements.forEach((el, index) => {
      if (el.component) {
        scene[index] = el.component;
      }
    });
    return SceneMap(scene);
  }, [elements]);

  const handleChangeIndex = (index: number) => {
    setIndex(index);
    if (onPressCallback) {
      onPressCallback(index);
    }
    if (onChangeTab) {
      onChangeTab(index);
    }
  };

  return {
    routes,
    renderScene,
    layout,
    tabBgColor,
    tabIndicatorColor,
    tabfocusedTextColor,
    tabTextColor,
    tabWith,
    swipeEnabled,
    tabHeight,
    _tabBarStyle,
    styles,
    index,
    isClickableTab,
    setIndex,
    handleChangeIndex,
    alignTabBar,
    showInMobile,
  };
};

export default useTabsController;
