import { HStack, Image, Stack, Text } from 'native-base';
import { PermissionsActive } from 'ui/components/PermissionsActive';

import { colors } from '~/theme/colors';

import { useReviewSectionController } from './controller';
import { ReviewSectionProps } from './types';

export const ReviewSection = ({
  permissionGroupName,
  typeOfPermission,
  permissionGroupDescription,
  appLogo,
  appName,
  getValues,
}: ReviewSectionProps) => {
  const { groupedPermissions } = useReviewSectionController(getValues);

  return (
    <Stack mt={8} minH="50vh">
      <Stack space={2}>
        <Text fontWeight={500} fontSize="xl" color="gray.600">
          Review
        </Text>
        <Text fontSize="sm" color="gray.600">
          Please review all the information carefully
        </Text>
      </Stack>
      <Stack space={2} mt={8}>
        <Text fontWeight={500} fontSize="md" color={colors.singletons.darkText}>
          {permissionGroupName}
        </Text>
        <Text fontSize="xs" color="gray.600">
          {permissionGroupDescription || '-'}
        </Text>
      </Stack>
      <HStack maxWidth="450px" mt={6} justifyContent="space-between">
        <Stack space={2}>
          <Text fontWeight={500} color="gray.600">
            App
          </Text>
          <HStack space={2} alignItems="center">
            <Image
              source={{
                uri: appLogo,
              }}
              alt="App"
              width="24px"
              height="24px"
              borderRadius="sm"
            />
            <Text>{appName || '-'}</Text>
          </HStack>
        </Stack>
        <Stack space={2}>
          <Text fontWeight={500} color="gray.600">
            Type of user
          </Text>
          <Text>{typeOfPermission}</Text>
        </Stack>
      </HStack>

      <Stack mt={6} space={3}>
        {Object.values(groupedPermissions).map((permissionChosen) => (
          <PermissionsActive
            key={permissionChosen.name}
            title={permissionChosen.displayName}
            permissions={permissionChosen.permissions}
          />
        ))}
      </Stack>
    </Stack>
  );
};
