import { VStack } from 'native-base';
import { FieldErrors } from 'react-hook-form';
import Button from 'ui/components/Button';

import { IDatesDTO } from '~/types/dtos';

import { DateForm } from '../DateForm';
import { DatesFormProps } from './types';

export const DatesForm = ({
  control,
  fields,
  errors,
  addOneForm,
  removeOneForm,
  isTeamEvent,
  isEventActivity,
  isEditing = false,
  setValue,
}: DatesFormProps) => {
  return (
    <VStack w="100%" mb={2}>
      {fields.map((field, index) => {
        return (
          <DateForm
            control={control}
            key={field.id}
            formFieldName={'dates'}
            parentIndex={index}
            isEventActivity={isEventActivity}
            isTeamEvent={isTeamEvent}
            errors={errors as FieldErrors<{ [key: string]: IDatesDTO[] }>}
            dateFormIndex={index}
            dateNumber={index + 1}
            removeOneDateForm={removeOneForm}
            isEditing={isEditing}
            setValue={setValue}
          />
        );
      })}
      <Button leftIconName="plus-circle" onPress={() => addOneForm()} mt={8}>
        New Date
      </Button>
    </VStack>
  );
};
