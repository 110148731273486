import { createAsyncThunk } from '@reduxjs/toolkit';
import { PermissionType } from 'ui/enums';
import { App } from 'ui/types/interfaces/app';
import { IPopulatedNetworkAppSharing } from 'ui/types/interfaces/network-app-sharing';
import { IAppPermissionGroupsAppPopulate } from 'ui/types/interfaces/permission';
import {
  getAvailablePartnerPermissionsFromApps,
  getPartnerPermissionsGroupsByAppId,
} from 'ui/utils/permissions';

import { NetworkAppSharingService } from '~/services/resources/networkAppSharing';
import { ecosystemSliceActions } from '~/store/slices/ecosystem';
import { permissionsSliceActions } from '~/store/slices/permissions';

export const updatePartnerPermissionsAction = createAsyncThunk(
  'updatePartnerPermissionsAction',
  async (ecosystemId: string, { dispatch }) => {
    const { data: networkAppsSharing } =
      await NetworkAppSharingService.loadAppsConnectedToEcosystem(ecosystemId);

    dispatch(ecosystemSliceActions.setNetworkAppSharings(networkAppsSharing));

    const availableApps = networkAppsSharing.reduce(
      (
        acc: Record<string, App>,
        networkAppSharing: IPopulatedNetworkAppSharing,
      ) => ({
        ...acc,
        [networkAppSharing.app._id]: networkAppSharing.app,
      }),
      {} as Record<string, App>,
    );

    const partnerPermissionsGroups: IAppPermissionGroupsAppPopulate[] = [];

    networkAppsSharing.forEach((networkAppSharing) =>
      networkAppSharing.appPermissionGroups.filter((appPermissionGroup) => {
        if (appPermissionGroup.type === PermissionType.PARTNER) {
          const permissionGroupWithApp = {
            ...appPermissionGroup,
            app: networkAppSharing.app,
          };

          partnerPermissionsGroups.push(permissionGroupWithApp);
        }
      }),
    );

    const partnerPermissionsByAppId = getPartnerPermissionsGroupsByAppId(
      partnerPermissionsGroups,
    );

    dispatch(
      permissionsSliceActions.setUserPermissionsGroupsByAppId(
        partnerPermissionsByAppId,
      ),
    );

    const availablePartnerPermissions = getAvailablePartnerPermissionsFromApps(
      partnerPermissionsGroups,
    );

    dispatch(
      permissionsSliceActions.setAvailableActivityPermissionsFromApps({
        ecosystemId,
        permissions: availablePartnerPermissions,
      }),
    );

    dispatch(ecosystemSliceActions.setEcosystemApps(availableApps));
  },
);
