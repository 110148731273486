import { Stack } from 'native-base';
import React from 'react';
import DataTable from 'ui/components/DataTable';

import { useImportsTableController } from '~/pages/Authenticated/BulkUploads/components/ImportsTable/controller';

interface IImportsTable {
  refetchQuantity: number;
  importFileId: string;
  placeColumns?: boolean;
}

export const _ImportsTable = ({
  importFileId,
  placeColumns,
  refetchQuantity,
}: IImportsTable) => {
  const { activityColumns, placesColumns, loadData } =
    useImportsTableController(importFileId);

  return (
    <Stack py="4" w="100%">
      <DataTable
        columns={placeColumns ? placesColumns : activityColumns}
        // @mycatdoitbetter NOTE: We need to find a way to handle this type properly (without this ANY).
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        loadData={loadData as any}
        refetchQuantity={refetchQuantity}
      />
    </Stack>
  );
};

export const ImportsTable = React.memo(_ImportsTable);
