import { yupResolver } from '@hookform/resolvers/yup';
import { useEffect, useMemo, useRef } from 'react';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { IModalRefProps } from 'ui/components/Modals/Modal/types';

import { PAGES } from '~/constants/pages.constants';
import { useAppSelector } from '~/hooks/useAppSelector';
import { useJoinAsAdmin } from '~/hooks/useJoinAsAdmin';
import { useQuery } from '~/hooks/useQuery';
import { useRouter } from '~/hooks/useRouter';
import { InviteCode } from '~/types/interfaces/inviteCode';
import { IOrganization } from '~/types/interfaces/organization';

import { defaultValues, schema } from './constants';

export const useJoinAsAdminInviteController = () => {
  const modalTermsOfServiceRef = useRef<IModalRefProps>(null);
  // Providers
  const { handleJoinAsAdminAuthenticated } = useJoinAsAdmin();
  const { isAuthenticated } = useAppSelector(({ auth }) => auth);

  // Hooks
  const {
    searchParams,
    goToRoute,
    params: { code },
  } = useRouter();

  const {
    control,
    handleSubmit,
    formState: { isValid, isSubmitting },
  } = useForm<{ termsOfServiceAccepted: boolean }>({
    defaultValues,
    resolver: yupResolver(schema),
  });

  const organizationId = useMemo(
    () => searchParams.get('organization'),
    [searchParams],
  );

  // Load Organization
  const { data: organization, isLoading: isLoadingOrganization } =
    useQuery<IOrganization>(`/organization/public/${organizationId}`, {
      queryOptions: { enabled: !!organizationId },
      isOpenApi: true,
    });

  // Loads Invite
  const { data: inviteCode, isLoading: isLoadingInviteCode } =
    useQuery<InviteCode>(
      `invite-code/public/code/${code}/organization/${organizationId}`,
      {
        queryOptions: {
          enabled: !!organizationId && !!code,
          onError: () => {
            toast.info('Unable to load invite link.');
            goToRoute(PAGES.SIGNIN);
          },
          onSuccess: (data: any) => {
            if (!data) {
              toast.info('Unable to load invite link.');
              goToRoute(PAGES.SIGNIN);
              return;
            }
          },
        },
        isOpenApi: true,
      },
    );

  useEffect(() => {
    if (!!inviteCode && !isAuthenticated) {
      const inviteUrl = `${PAGES.INVITE_JOIN_ADMIN.replace(
        ':code',
        inviteCode.code || '',
      )}?organization=${organizationId}`;

      toast.info('Sign in or create a new account to accept this invite');
      const redirectURL = `${PAGES.SIGNIN}?redirect=${inviteUrl}`;
      goToRoute(redirectURL);
    }
  }, [inviteCode, organizationId, isAuthenticated, goToRoute]);

  // Handlers
  const handleOpenTerms = () => modalTermsOfServiceRef.current?.open();

  const handleDeclineInvitation = () => {
    goToRoute(PAGES.INVITE_JOIN_ADMIN_DECLINED);
  };

  const onSubmit = handleSubmit(async ({ termsOfServiceAccepted }) => {
    if (!termsOfServiceAccepted || !organization) return;
    await handleJoinAsAdminAuthenticated(organization._id, code || '');
  });

  const isLoading = isLoadingOrganization || isLoadingInviteCode;

  return {
    control,
    isValid,
    isLoading,
    inviteCode,
    isSubmitting,

    organization,
    isLoadingInviteCode,
    modalTermsOfServiceRef,
    handleDeclineInvitation,
    handleOpenTerms,
    onSubmit,
  };
};
