import { HStack, Stack, Text, VStack } from 'native-base';
import { forwardRef } from 'react';
import Button from 'ui/components/Button';
import DataTable from 'ui/components/DataTable';
import Modal from 'ui/components/Modals/Modal';
import { Select } from 'ui/components/v2/inputs/Select';
import Loading from 'ui/components/v2/Loading';
import { colors } from 'ui/theme/colors';

import { useFeaturedActivitySelectionModalController } from '~/components/ModalTemplates/FeaturedActivitySelectionModal/controller';
import {
  defaultActivityDefinitionToTableFilterValue,
  MapActivityDefinitionToTableColumn,
} from '~/components/ModalTemplates/FeaturedActivitySelectionModal/utils';

import { FeaturedActivitySelectionModalProps } from './types';

const FeaturedActivitySelectionModal = forwardRef(
  function FeaturedActivitySelectionModal(
    props: FeaturedActivitySelectionModalProps,
    ref,
  ) {
    const {
      isLoading,
      appOptions,
      selectedApp,
      partnersOrganizations,
      selectedActivityDefinitionId,
      handleSubmit,
      handleLoadData,
      handleAppSelect,
      handleSelectionChange,
      handleCancelButtonPress,
    } = useFeaturedActivitySelectionModalController({
      ...props,
      ref: ref as any,
    });

    return (
      <Modal headerText="Feature activity" ref={ref} modalContentMaxW="824px">
        <VStack py={6} space={3} alignSelf="center" pb={0}>
          {/* header */}
          <Stack>
            <Text color="gray.600" fontWeight={400} fontSize={16}>
              Featuring an activity gives it greater prominence in the employee
              view. Select one activity from the list below to feature.
            </Text>
          </Stack>

          {/* body */}
          <VStack mt={3} space={4} height={'374px'}>
            {isLoading && <Loading height={'100%'} float />}

            <Select.Base
              label="App"
              placeholder="Select app"
              value={selectedApp}
              options={appOptions}
              onChange={handleAppSelect}
              leadingIcon="filter"
            />
            <VStack overflow={'auto'} h="full" maxH={'full'} flex={1}>
              <DataTable
                checkboxColumn
                loadData={handleLoadData as any}
                defaultFilterValues={
                  defaultActivityDefinitionToTableFilterValue
                }
                columns={MapActivityDefinitionToTableColumn({
                  organizations: partnersOrganizations,
                })}
                enableSelection
                onSelectionChange={handleSelectionChange}
                selected={selectedActivityDefinitionId as any}
              />
            </VStack>
          </VStack>

          {/* footer */}
          <HStack p={3} space={4} background={'white'}>
            <Button
              flex={1}
              onPress={handleCancelButtonPress}
              variant={'ghost'}
            >
              <HStack
                justifyContent={'center'}
                alignItems={'center'}
                space="0.65rem"
              >
                <Text
                  color={colors.black}
                  numberOfLines={1}
                  fontSize={{ base: 'sm', md: 'md' }}
                  fontWeight={500}
                >
                  Cancel
                </Text>
              </HStack>
            </Button>
            <Button
              flex={1}
              py={'21px'}
              leftIconSize={24}
              onPress={handleSubmit}
              backgroundColor={'primary.400'}
              isDisabled={!selectedActivityDefinitionId || isLoading}
            >
              <Text
                numberOfLines={1}
                fontSize={{ base: 'sm', md: 'md' }}
                fontWeight="500"
                color="white"
              >
                Feature activity
              </Text>
            </Button>
          </HStack>
        </VStack>
      </Modal>
    );
  },
);

export default FeaturedActivitySelectionModal;
