import { EEventApplicationType } from 'ui/enums';
import { TestContext } from 'yup';

import { ICreateEventDTO } from '~/types/dtos';
import Yup from '~/utils/validations/yup';

interface TestContextExtended {
  from: {
    value: ICreateEventDTO;
  }[];
}
export const volunteerNumberSchemaValidation = Yup.string().test(
  'eventApplicationType',
  (value, context) => {
    const { from } = context as TestContext & TestContextExtended;
    const [currentTree, parent] = from;

    const volunteerNumber = Number(value);

    const { noVolunteerLimit } = currentTree.value;

    if (
      parent.value.eventApplicationType === EEventApplicationType.Individual
    ) {
      if (!noVolunteerLimit) {
        if (volunteerNumber) {
          if (volunteerNumber > 0) {
            return true;
          }
          return false;
        }
        return false;
      }
      return true;
    }

    if (parent.value.eventApplicationType === EEventApplicationType.Team) {
      return true;
    }

    return true;
  },
);
