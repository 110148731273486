import { Auth } from 'aws-amplify';
import axios, { InternalAxiosRequestConfig } from 'axios';

import { DEFAULT_URL_UPLOADS_API } from '~/config';
export const uploadsApi = axios.create({
  baseURL: DEFAULT_URL_UPLOADS_API,
});

uploadsApi.interceptors.request.use(
  async (config: InternalAxiosRequestConfig) => {
    try {
      const currentSession = await Auth.currentSession();
      const idToken = currentSession.getIdToken().getJwtToken();
      config.headers.set('Authorization', `Bearer ${idToken}`);
      return config;
    } catch (error) {
      return config;
    }
  },
);
