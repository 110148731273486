import { useCallback, useMemo, useState } from 'react';
import { toast } from 'react-toastify';
import {
  BaseModel,
  IFilter,
  SelectedRows,
} from 'ui/components/DataTable/types';
import {
  ISelectOptionsGroup,
  ISelectOptionsItem,
  SelectValue,
} from 'ui/components/v2/inputs/Select/types';

import { FeaturedActivitySelectionModalProps } from '~/components/ModalTemplates/FeaturedActivitySelectionModal/types';
import { FEATURED_ACTIVITY_MESSAGES } from '~/constants/messages.constants';
import { useLoadOrganizationPartners } from '~/hooks/organization/useLoadOrganizationPartners';
import { useAppSelector } from '~/hooks/useAppSelector';
import ActivityDefinitionService from '~/services/resources/activityDefinition';
import FeaturedActivityDefinitionService from '~/services/resources/featured-activity-definition/featuredActivityDefinition.service';
import { App } from '~/types/interfaces/app';

export const useFeaturedActivitySelectionModalController = ({
  ref,
  onSuccess,
}: FeaturedActivitySelectionModalProps) => {
  // Providers
  const { selectedEcosystem, ecosystemApps } = useAppSelector(
    ({ ecosystem }) => ecosystem,
  );

  // States
  const [selectedActivityDefinitionId, setSelectedActivityDefinitionId] =
    useState<string>('');

  const [isLoading, setIsLoading] = useState(false);
  const [selectedApp, setSelectedApp] = useState<SelectValue>(null);

  // Hooks
  const apps = useMemo(() => {
    const list = Object.values(ecosystemApps || {}) as App[];
    return list;
  }, [ecosystemApps]);

  const appOptions = useMemo(() => {
    const optionsGroup: ISelectOptionsGroup = {
      label: 'Apps',
      options: [{ value: '', label: 'All apps' }],
    };

    if (apps.length) {
      const appOptions: ISelectOptionsItem[] = apps.map((app) => ({
        value: app._id,
        label: app?.name || '',
      }));
      optionsGroup.options.push(...appOptions);
    }

    return [optionsGroup];
  }, [apps]);

  const {
    data: partnersOrganizations,
    isLoading: isPartnersOrganizationsLoading,
  } = useLoadOrganizationPartners(selectedEcosystem?.organization || '');

  // handlers
  const handleLoadData = useCallback(
    async (skip: number, limit: number, filter: IFilter) => {
      const appIds = apps.map((app) => app._id);
      const selectedAppId = (selectedApp as ISelectOptionsItem)?.value;
      try {
        const {
          data: { data, count },
        } = await ActivityDefinitionService.findAll({
          skip,
          limit,
          filter: JSON.stringify({
            ...filter,
            app: { $in: selectedAppId ? [selectedAppId] : appIds },
          }),
          populate: JSON.stringify(['app', 'organization']),
          sort: JSON.stringify({ createdAt: -1 }),
        });
        return { data, count };
      } catch (error: unknown) {
        return { data: [], count: 0 };
      }
    },
    [apps, selectedApp],
  );

  const handleSubmit = async () => {
    if (!selectedEcosystem || !selectedActivityDefinitionId) {
      toast.error('Select an Activity to continue');
      return;
    }
    setIsLoading(true);
    try {
      await FeaturedActivityDefinitionService.create({
        activityDefinitionId: selectedActivityDefinitionId,
        ecosystemId: selectedEcosystem?._id || '',
      });

      toast.success(FEATURED_ACTIVITY_MESSAGES.CREATE_SUCCESS);
      setSelectedActivityDefinitionId('');
      onSuccess?.();

      ref?.current?.close();
    } catch (error: unknown) {
      toast.error(FEATURED_ACTIVITY_MESSAGES.CREATE_FAILURE);
    } finally {
      setIsLoading(false);
    }
  };

  const handleSelectionChange = (selected: SelectedRows<BaseModel>) => {
    setSelectedActivityDefinitionId(selected as unknown as string);
  };

  const handleCancelButtonPress = () => {
    ref?.current?.close();
  };

  const handleAppSelect = (value: SelectValue) => {
    const selectedOption = value as ISelectOptionsItem;
    if (!selectedOption?.value) {
      setSelectedApp(null);
      return;
    }
    setSelectedApp(selectedOption);
  };

  return {
    apps,
    isLoading,
    appOptions,
    selectedApp,
    selectedEcosystem,
    partnersOrganizations,
    isPartnersOrganizationsLoading,
    selectedActivityDefinitionId,
    handleSubmit,
    handleLoadData,
    handleSelectionChange,
    handleCancelButtonPress,
    handleAppSelect,
  };
};
