export const tabElements = [
  {
    title: 'About the activity',
    component: () => <></>,
  },
];

export const getTabElements = ({ isOwner = false }: { isOwner?: boolean }) => {
  const _tabElements = [...tabElements];
  if (isOwner) {
    _tabElements.push({
      title: 'Applications',
      component: () => <></>,
    });
  }
  return _tabElements;
};
