import { AttendanceTypes } from '~/enums';
import { IDatesDTO, IPlacesDTO } from '~/types/dtos';

export const queryFilter = {
  causeOptions: {
    filter: JSON.stringify({ relatedTo: ['causeOptions'] }),
  },
  requirementOptions: {
    filter: JSON.stringify({ relatedTo: ['requirementOptions'] }),
  },
};

export const mapPlacesAndDatesFormValues = (
  allActivitiesByDefinitionData: any,
  attendanceType: AttendanceTypes,
) => {
  let places: IPlacesDTO[] = [];
  const dates: Partial<IDatesDTO>[] = [];

  for (const activity of allActivitiesByDefinitionData) {
    let placeIndex = -1;

    if (attendanceType === AttendanceTypes.InPerson) {
      const [lng, lat] = activity.address?.location?.coordinates;
      placeIndex = places.findIndex((place) => {
        if (lng === place.address?.lng && lat === place.address?.lat) {
          return true;
        } else {
          return false;
        }
      });
    } else {
      const meetingLink = activity.meetingLink;
      placeIndex = places.findIndex((place) => {
        if (meetingLink === place.meetingLink) {
          return true;
        } else {
          return false;
        }
      });
    }

    if (placeIndex === -1) {
      const [lng, lat] = activity.address?.location?.coordinates;
      const place: IPlacesDTO = {
        meetingLink: activity.meetingLink,
        address: {
          lat,
          lng,
          placeID: null,
          rawLocation: activity.address.street,
        },
        totalDates: 1,
      };

      const date: IDatesDTO = {
        noVolunteerLimit: !activity.isVolunteerNumberLimited,
        volunteerNumber: activity.volunteerNumber ?? '',
        startDate: new Date(activity.startDate),
        endDate: new Date(activity.endDate),
        dueDate: new Date(activity.dueDate),
        placeFormIndex: places.length,
        indexPosition: 0,
        _activityId: activity._id,
        externalApplyLink: activity.externalApplyLink || '',
      };

      places.push(place);
      dates.push(date);
    } else {
      places = places.map((place, index) => {
        if (index === placeIndex) {
          return { ...place, totalDates: place.totalDates + 1 };
        }
        return place;
      });

      const date: IDatesDTO = {
        noVolunteerLimit: !activity.isVolunteerNumberLimited,
        volunteerNumber: activity.volunteerNumber ?? '',
        startDate: new Date(activity.startDate),
        endDate: new Date(activity.endDate),
        dueDate: new Date(activity.dueDate),
        placeFormIndex: placeIndex,
        indexPosition: places[placeIndex]?.totalDates - 1,
        _activityId: activity._id,
        externalApplyLink: activity.externalApplyLink || '',
      };
      dates.push(date);
    }
  }
  return { places, dates };
};
