import { EActivityAttendanceTypes, LocationOptionsEnum } from 'ui/enums';
import { ActivityRegion, MappedRegions } from 'ui/types/activities';

import { ActivityTypeEnum } from '~/enums';
import { IActivityCategoryDTO } from '~/types/dtos';
import { IActivity } from '~/types/interfaces/activity';
export const renderCategoryAndCauseString = (
  category: string,
  causeOptions?: IActivityCategoryDTO[],
) => {
  const causesToString = causeOptions
    ?.map((cause: IActivityCategoryDTO) => cause.displayName)
    .join(', ');

  return `${category} | ${causesToString}`;
};

export const renderLocationPlaceString = (
  activities: IActivity[],
  locationOption: LocationOptionsEnum,
): string => {
  if (!activities?.length) return '';
  switch (locationOption) {
    case LocationOptionsEnum.SingleLocation:
      return activities[0].address?.street ? activities[0].address?.street : '';
    case LocationOptionsEnum.MultipleLocations:
      return 'Multiple Locations';
    default:
      return '';
  }
};

export const returnActivityTypeTextWithEnumType = (
  activityType: ActivityTypeEnum,
) => {
  return {
    [ActivityTypeEnum.Event]: 'Event',
    [ActivityTypeEnum.Action]: 'Action',
    [ActivityTypeEnum.OngoingOpportunity]: 'Ongoing Opportunity',
  }[activityType];
};
