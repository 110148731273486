import { Control, ErrorOption, UseFormSetValue } from 'react-hook-form';

export interface ImageZone {
  id?: number;
  image: string;
  imageName?: string;
  isCover: boolean;
  type?: string;
  name?: string;
  size?: number;
}

export interface FormController {
  // TODO: remove register when implement others drop zone
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  register: any;
  control: Control;
  errors: ErrorOption;
  setValue: UseFormSetValue<CustomFile>;
}

export interface CustomFile extends File {
  id?: number;
  image: string;
  imageName?: string;
  isCover: boolean;
  path?: string;
}

export interface DropzoneProps extends Partial<FormController> {
  files?: ImageZone[];
  deleteImageFn?: (imageId: number) => void;
  multiple?: boolean;
  showTitle?: boolean;
  maxFiles?: number;
  defaultImage?: string | CustomFile[];
  handleDropFile?: (file: CustomFile[]) => void;
  handleRemoveFile?: (files: CustomFile[], imageId: number) => void;
  acceptedSize?: number;
  filesField: string;
  filesAccepted?: { [key: string]: string[] };
  thumbnailImages?: string[];
  acceptedWidth?: number;
  acceptedHeight?: number;
  resetState?: VoidFunction;
}

export enum IMAGES_ZONE_ERROR {
  TOO_LARGE_FILE = 'file-too-large',
  TOO_SMALL_FILE = 'file-too-small',
  TOO_MANY_FILES = 'too-many-files',
  INVALID_FILE_FORMAT = 'file-invalid-type',
}
export enum FILES_ZONE_ERRORS {
  TOO_LARGE_FILE = 'file-too-large',
  TOO_SMALL_FILE = 'file-too-small',
  TOO_MANY_FILES = 'too-many-files',
  INVALID_FILE_FORMAT = 'file-invalid-type',
}
