import { Text, VStack } from 'native-base';
import React from 'react';
import Button from 'ui/components/Button';

import { styleConfig } from './styleConfig';
import { IBottomButtonProps } from './types';

export const BottomButton = ({
  onSubmit,
  text,
  isDisabled,
  isLoading,
  hasSecondaryButton,
  secondaryButtonIsDisabled,
  secondaryButtonOnPress,
  secondaryButtonText,
  secondaryButtonIsLoading,
  secondaryButtonTextColor,
  primaryButtonVariant = 'outline',
}: IBottomButtonProps) => {
  return (
    <VStack
      backgroundColor="white"
      p={5}
      width="100%"
      position="fixed"
      bottom="0"
      alignItems="center"
      display={styleConfig.display}
      space={2}
    >
      <Button
        fontWeight={500}
        fontSize="md"
        onPress={onSubmit}
        isDisabled={isDisabled}
        isLoading={isLoading}
        p={6}
        variant={primaryButtonVariant}
      >
        {text}
      </Button>
      {hasSecondaryButton ? (
        <Button
          variant="ghost"
          fontWeight={500}
          fontSize="md"
          onPress={secondaryButtonOnPress}
          isDisabled={secondaryButtonIsDisabled}
          isLoading={secondaryButtonIsLoading}
        >
          <Text color={secondaryButtonTextColor || 'singletons.black'}>
            {secondaryButtonText}
          </Text>
        </Button>
      ) : null}
    </VStack>
  );
};
