import { toast } from 'react-toastify';
import { useImagePickerSharedController } from 'ui/components/v2/ImageInputs/ImagePicker/controller';

import { ImageDropAreaProps } from './types';

export const useDropAreaController = ({
  src,
  aspectRatio,
  onChange,
}: ImageDropAreaProps) => {
  const {
    imgSrc,
    modalRef,
    fileInputRef,
    isLoading,
    aspectRatioLabel,
    handleNewFile,
    handleSubmit,
    handleClear,
  } = useImagePickerSharedController({ src, aspectRatio, onChange });

  const onDropAccepted = async (files: File[]) => {
    if (!files.length) return;
    handleNewFile(files[0]);
  };

  const onDropRejected = () => {
    toast.error('Invalid File');
  };

  return {
    imgSrc,
    modalRef,
    isLoading,
    fileInputRef,
    aspectRatioLabel,
    handleClear,
    handleNewFile,
    handleSubmit,
    onDropAccepted,
    onDropRejected,
  };
};
