import { Organization } from 'ui/types/interfaces/organization';

import { useQuery } from '~/hooks/useQuery';

export const useLoadOrganizationPartners = (organizationId?: string) => {
  const url = `organization/${organizationId}/partners`;

  const { data, isLoading, refetch } = useQuery<Organization[]>(url, {
    key: `organization-${organizationId}-partners`,
    queryOptions: { enabled: !!organizationId },
    requestOptions: {
      params: {},
    },
  });

  return {
    data: data || [],
    isLoading,
    refetch,
  };
};
