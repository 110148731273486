import FeatherIcon from 'feather-icons-react';
import { Checkbox as NBCheckbox, Text } from 'native-base';

import { colors } from '../../../theme/colors';
import { ICheckboxOptionsItem } from '../types';

const CheckboxItem = ({
  value,
  iconName,
  text,
  iconSize,
  iconColor,
  mt = 1,
  ...itemRest
}: ICheckboxOptionsItem) => {
  return (
    <NBCheckbox
      value={value}
      {...itemRest}
      icon={
        iconName ? (
          <FeatherIcon
            icon={iconName}
            size={iconSize || 16}
            color={iconColor || colors.primary[400]}
          />
        ) : undefined
      }
    >
      <Text mx={2} mt={mt}>
        {text}
      </Text>
    </NBCheckbox>
  );
};

export default CheckboxItem;
