import { Permission } from '../types/interfaces/app-permissions-group';

interface PermissionGrouped {
  [categoryName: string]: {
    name: string;
    displayName: string;
    description: string;
    permissions: Permission[];
  };
}

export const groupPermissionsByCategory = (permissions: Permission[]) => {
  const groupedPermissions: PermissionGrouped = {};
  permissions.forEach((permission) => {
    const { category } = permission;

    if (!groupedPermissions[category.name]) {
      groupedPermissions[category.name] = {
        ...category,
        permissions: [],
      };
    }

    groupedPermissions[category.name].permissions.push({
      ...permission,
    });
  });

  return groupedPermissions;
};
