import '../radio.css';

import { Text, VStack } from 'native-base';
import { ReactNode } from 'react';

import { IRadioOptionsItem } from '../types';

export const CustomRadioItem = ({
  name,
  value,
  label,
  isChecked = false,
  onValueChange,
  isDisabled,
  customLabelRender,
}: {
  name: string;
  label: string;
  customLabelRender?: ReactNode | any;
  value: string;
  isChecked?: boolean;
  isDisabled?: boolean;
  onValueChange?: (value: string) => void;
}) => {
  return (
    <Text>
      <div className="custom-radio">
        <input
          id={`radio-${name}`}
          name={name}
          value={value}
          type="radio"
          checked={isChecked}
          disabled={isDisabled}
          onChange={(e) => {
            onValueChange?.(e.target.value);
          }}
        />
        <label htmlFor={`radio-${name}`} className="custom-radio-label">
          {customLabelRender || label}
        </label>
      </div>
    </Text>
  );
};

const RadioItem = ({
  value,
  text,
  description,
  onChange,
  checked,
  isDisabled,
}: IRadioOptionsItem) => {
  return (
    <VStack
      flex={description ? 1 : 'initial'}
      maxW={description ? '100%' : 'auto'}
      width={'auto'}
    >
      <CustomRadioItem
        onValueChange={onChange}
        value={value}
        name={text || value}
        label={text || ''}
        isChecked={checked}
        isDisabled={isDisabled}
      />
      {description ? (
        <Text flex={1} fontSize="xs" color="gray.600" mt={2}>
          {description}
        </Text>
      ) : null}
    </VStack>
  );
};

export default RadioItem;
