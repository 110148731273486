import { api } from '~/services/api';
import { CreateCustomUnitPayload } from '~/types/dtos';

const MODULE_URL = 'measurement-units';

export default class MeasurementUnitsService {
  static async getDefaultMeaasurementUnits() {
    return await api.get(`${MODULE_URL}/default-units`);
  }

  static async getCustomMeasurementUnits(organizationId: string) {
    return await api.get(`${MODULE_URL}/organization/${organizationId}`);
  }

  static async createCustomUnit(data: CreateCustomUnitPayload) {
    return await api.post(`${MODULE_URL}/custom-unit`, data);
  }
}
