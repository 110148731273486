import { Alert, HStack, Link, Text, VStack } from 'native-base';

export type AppInfoAlertProps = {
  helpLink?: string;
  title?: string;
  description?: string;
};

export const AppInfoAlert = ({
  helpLink,
  title = 'Didn’t find the app you’re looking for?',
  description = 'Click here to find and connect to new apps',
}: AppInfoAlertProps) => {
  const handleExternalLink = () => {
    window.open(helpLink, '_blank');
  };

  return (
    <Alert status="info" backgroundColor={'info.100'} mt={2}>
      <HStack alignItems={'center'} w="full" space={2}>
        <Alert.Icon size={6} variant={'outline'} />
        <VStack>
          <Text fontSize={'sm'} fontWeight={500} color={'gray.800'}>
            {title}
          </Text>
          {helpLink ? (
            <Text fontSize={14} fontWeight={500} letterSpacing="150%">
              <Link isExternal onPress={handleExternalLink}>
                {description}
              </Link>
            </Text>
          ) : (
            <Text
              fontSize={14}
              fontWeight={400}
              color={'gray.800'}
              letterSpacing="150%"
            >
              {description}
            </Text>
          )}
        </VStack>
      </HStack>
    </Alert>
  );
};
