import {
  Box,
  Flex,
  HStack,
  Pressable,
  Spinner,
  Text,
  VStack,
} from 'native-base';
import Button from 'ui/components/Button';
import Modal from 'ui/components/Modals/Modal';

import { EcosystemCard } from './components/EcosystemCard';
import { useEcosystemsController } from './useEcosystemsController';

export const Ecosystems = () => {
  const {
    ecosystems,
    isLoadingEcosystems,
    modalRef,
    organization,
    beforeCreatingEcosystemModalRef,
    handleNewEcosystem,
    handleLeftButtonAction,
    handleRightButtonAction,
    handleBeforeCreatingEcosystemModalRightBtnAction,
    handleGoToEcosystemDetails,
    ecosystemApps,
  } = useEcosystemsController();

  return (
    <>
      <HStack
        justifyContent="space-between"
        borderBottomColor="gray.300"
        borderBottomWidth="1"
        pb="4"
      >
        <VStack flex="1" space="2">
          <Text fontSize="xl" fontWeight="500" color="gray.600">
            Your ecosystems
          </Text>
          <Text color="gray.600">Ecosystems owned by your organisation</Text>
        </VStack>
        <Box>
          <Button leftIconName="plus-circle" onPress={handleNewEcosystem}>
            New ecosystem
          </Button>
        </Box>
      </HStack>
      {isLoadingEcosystems || !organization ? (
        <Spinner mt="10" />
      ) : (
        <Flex mt="6" flexDirection="row" flexWrap="wrap" style={{ gap: 10 }}>
          {ecosystems?.map((ecosystem) => (
            <Pressable
              flex="0 0 32%"
              key={ecosystem._id}
              onPress={() => handleGoToEcosystemDetails(ecosystem._id)}
            >
              <EcosystemCard
                ecosystem={ecosystem}
                apps={ecosystemApps[ecosystem?._id] || []}
              />
            </Pressable>
          ))}
        </Flex>
      )}

      <Modal
        ref={modalRef}
        headerText="Welcome to your ecosystems!"
        modalContentMaxW="570px"
        leftBtnText="Do it later"
        rightBtnText="Edit ecosystem"
        leftBtnAction={handleLeftButtonAction}
        rightBtnAction={handleRightButtonAction}
      >
        <VStack space="4">
          <Text color="gray.600">
            We have already created the first ecosystem for your organisation.
            The ecosystem is where you can group apps to make them available to
            your members and network.
          </Text>
          <Text color="gray.600">
            To make it your own, feel free to edit its information and customize
            it according to your preferences. If you would like to create a new
            ecosystem from scratch, simply click on{' '}
            <strong>New ecosystem.</strong>
          </Text>
        </VStack>
      </Modal>

      <Modal
        ref={beforeCreatingEcosystemModalRef}
        headerText="Before creating a new ecosystem"
        modalContentMaxW="570px"
        rightBtnText="Got it"
        rightBtnWidth="full"
        rightBtnAction={handleBeforeCreatingEcosystemModalRightBtnAction}
      >
        <VStack space="4">
          <Text color="gray.600">
            We have already established the initial ecosystem for your
            organisation. Please revise the pre-existing ecosystem to align with
            your organisation&apos;s specific information.
          </Text>
        </VStack>
      </Modal>
    </>
  );
};
