import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { ESliceNames } from '~/store/types';

import { JoinAsPartnerState } from './types';

const initialState: JoinAsPartnerState = {
  code: undefined,
  organizationName: undefined,
  currentTab: 0,
  isJoiningAsPartner: false,
  inviteURL: '',
  isJoiningAsPartnerRedirecting: false,
};

export const joinAsPartnerSlice = createSlice({
  name: ESliceNames.JOIN_AS_ADMIN,
  initialState,
  reducers: {
    update: (
      state,
      { payload }: PayloadAction<Partial<JoinAsPartnerState>>,
    ) => {
      return { ...state, ...payload };
    },
    nextTab: (state) => {
      if (state.currentTab === 2) state;
      return { ...state, currentTab: state.currentTab + 1 };
    },
    previousTab: (state) => {
      if (state.currentTab === 0) return state;
      return { ...state, currentTab: state.currentTab - 1 };
    },
    clear: () => initialState,
  },
});

export const {
  reducer: joinAsPartnerSliceReducer,
  actions: joinAsPartnerSliceActions,
} = joinAsPartnerSlice;
