import {
  ITeam,
  ITeamPopulateEcosystem,
  TeamMemberStatus,
} from 'doit-volunteer/src/types/interfaces/team';
import { getLeaderAndMembersByStatus } from 'doit-volunteer/src/utils/activityTeamMembers';
import { useCallback, useMemo, useState } from 'react';
import { toast } from 'react-toastify';
import { IFilterValue } from 'ui/components/DataTable/@types/filterTypes';
import { EEventApplicationType } from 'ui/enums';
import { Team } from 'ui/types/teams';
import { groupTeamMembersByStatus } from 'ui/utils/teams';

import { COMMON_MESSAGES } from '~/constants/messages.constants';
import { PAGES } from '~/constants/pages.constants';
import { ActivityTypeEnum } from '~/enums';
import { useAppSelector } from '~/hooks/useAppSelector';
import { useQuery } from '~/hooks/useQuery';
import { useRouter } from '~/hooks/useRouter';
import ActivityApplicationService from '~/services/resources/activityApplication';
import { IAxiosResponse } from '~/types';
export const useViewTeamController = () => {
  const [totalApplications, setTotalApplications] = useState(0);
  const {
    goToRoute,
    params: { id: teamId },
  } = useRouter();

  const { selectedEcosystem } = useAppSelector(({ ecosystem }) => ecosystem);

  const { data: response, isLoading } = useQuery<IAxiosResponse<ITeam[]>>(
    'team',
    {
      requestOptions: {
        params: {
          filter: JSON.stringify({
            _id: teamId,
          }),
          populate: JSON.stringify(['ecosystem']),
        },
      },
      key: `team-${teamId}`,
    },
  );

  const loadApplications = useCallback(
    async (skip: number, limit: number, filter: IFilterValue) => {
      try {
        const { data: responseActivityApplicationData } =
          await ActivityApplicationService.findAll({
            filter: JSON.stringify({
              ...filter,
              team: teamId,
              applicationType: EEventApplicationType.Team,
              ecosystem: selectedEcosystem?._id,
            }),
            skip,
            limit: 5,
            sort: JSON.stringify({ applicationDate: -1 }),
          });

        setTotalApplications(responseActivityApplicationData?.count);

        return {
          data: responseActivityApplicationData?.data,
          count: responseActivityApplicationData?.count,
        };
      } catch (error) {
        toast.error(COMMON_MESSAGES.ERROR_FETCHING_APPLICATIONS, {
          position: 'bottom-center',
        });
        return [];
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [teamId],
  );

  const handleViewActivity = (activityType: ActivityTypeEnum, id: string) => {
    switch (activityType) {
      case ActivityTypeEnum.Event:
        goToRoute(`${PAGES.VIEW_EVENT_ACTIVITY}/${id}`);
        break;
      case ActivityTypeEnum.OngoingOpportunity:
        goToRoute(`${PAGES.VIEW_ONGOING_ACTIVITY}/${id}`);
        break;
      case ActivityTypeEnum.Action:
        goToRoute(`${PAGES.VIEW_ACTION_ACTIVITY}/${id}`);
        break;
      default:
        break;
    }
  };

  const leaderAndMembersByStatus = useMemo(() => {
    if (!response?.data?.[0]) {
      return {
        leader: {
          name: '',
          avatarUrl: '',
        },
        members: [],
      };
    }

    return getLeaderAndMembersByStatus(
      response?.data?.[0] as ITeamPopulateEcosystem,
      TeamMemberStatus.ACCEPTED,
    );
  }, [response?.data]);

  const groupedMembers = useMemo(() => {
    if (!response?.data[0]) return null;
    return groupTeamMembersByStatus(response?.data[0] as unknown as Team);
  }, [response]);

  return {
    teamInfo: response?.data[0],
    leaderAndMembersByStatus,
    loadApplications,
    totalApplications,
    isLoading,
    handleViewActivity,
    groupedMembers,
  };
};
