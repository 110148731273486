import { yupResolver } from '@hookform/resolvers/yup';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useFieldArray, useForm } from 'react-hook-form';
import { AdvertiseRegionOptionsEnum } from 'ui/enums';
import { ActivityRegion, ActivityType } from 'ui/types/activities';
import { getActivityAdvertiseOption } from 'ui/utils/activityRegions';

import { useActivityRegions } from '~/hooks/useActivityRegions';
import { ICreateActivityDTO, IDatesDTO } from '~/types/dtos';

import { CheckboxesRegions, FromHomeProps } from './types';
import {
  fromHomeSchemaValidation,
  fromHomeSchemaValidationEvent,
  getFormDefaultValues,
  makeCheckboxesFields,
} from './utils';

export const useFromHomeController = ({
  activityType,
  handleContinue,
  handleBack,
  isLoading,
  isTeamEvent,
  defaultValues,
}: FromHomeProps) => {
  const { regionResponse, isLoadingRegions } = useActivityRegions();

  const [regionCheckboxFields, setRegionCheckboxFields] = useState<
    CheckboxesRegions[]
  >([]);

  const handleSelectMainRegion = (value: string) => {
    const updatedRegionCheckboxFields = [...regionCheckboxFields];

    updatedRegionCheckboxFields.map((parentRegion) => {
      if (parentRegion.mainRegionData.displayName === value) {
        parentRegion.isAllChecked = !parentRegion.isAllChecked;

        parentRegion.isPartialSelected = false;

        const newChildren = parentRegion.childrenData.map((childrenRegion) => {
          childrenRegion.checked = parentRegion.isAllChecked;
          return childrenRegion;
        });

        return {
          ...parentRegion,
          childrenData: newChildren,
        };
      }
      return parentRegion;
    });

    setRegionCheckboxFields(updatedRegionCheckboxFields);
  };

  const handleSelectSubRegion = (value: string) => {
    const updatedRegionCheckboxFields = [...regionCheckboxFields];

    updatedRegionCheckboxFields.map((parentRegion) => {
      const newChildren = parentRegion.childrenData.map((childrenRegion) => {
        if (childrenRegion.value === value) {
          childrenRegion.checked = !childrenRegion.checked;
        }
        return childrenRegion;
      });

      const relatedRegion = newChildren.find(
        (relatedRegion) => value === relatedRegion.value,
      );

      const isRelatedToParent =
        relatedRegion?.data.relatedTo ===
        parentRegion.mainRegionData.displayName;

      if (isRelatedToParent) {
        const isAllChildrenSelected =
          newChildren.length > 0 &&
          newChildren.every((newChild) => newChild.checked);

        const isAllChildrenDeselected =
          newChildren.length > 0 &&
          newChildren.every((newChild) => !newChild.checked);

        parentRegion.isPartialSelected =
          newChildren.length > 0 && !isAllChildrenDeselected
            ? !isAllChildrenSelected
            : false;

        parentRegion.isAllChecked = parentRegion.isPartialSelected
          ? false
          : isAllChildrenSelected;

        return {
          ...parentRegion,
          childrenData: newChildren,
        };
      }
      return parentRegion;
    });

    setRegionCheckboxFields(updatedRegionCheckboxFields);
  };

  const [advertiseRegions, setAdvertiseRegions] =
    useState<AdvertiseRegionOptionsEnum>(
      AdvertiseRegionOptionsEnum.AnywhereInUK,
    );

  const {
    control,
    handleSubmit,
    formState: { isValid, errors },
    resetField,
    setValue,
  } = useForm<ICreateActivityDTO>({
    resolver:
      activityType === ActivityType.Event
        ? yupResolver(fromHomeSchemaValidationEvent)
        : yupResolver(fromHomeSchemaValidation),
    mode: 'onChange',
    reValidateMode: 'onBlur',
    defaultValues: getFormDefaultValues(isTeamEvent, defaultValues),
  });

  const {
    fields: datesFields,
    append: appendDate,
    remove: removeDate,
  } = useFieldArray<ICreateActivityDTO>({
    control,
    name: 'dates',
  });

  const handleSubmitForm = handleSubmit((data: ICreateActivityDTO) => {
    if (advertiseRegions === AdvertiseRegionOptionsEnum.SelectRegions) {
      const regions = regionCheckboxFields.reduce(
        (
          activityRegions: ActivityRegion[],
          currentValue: CheckboxesRegions,
        ) => {
          if (currentValue.isAllChecked || currentValue.isPartialSelected) {
            activityRegions.push(currentValue.mainRegionData);
            currentValue.childrenData.forEach(
              (childrenRegion) =>
                childrenRegion.checked &&
                activityRegions.push(childrenRegion.data),
            );
          }
          return activityRegions;
        },
        [] as ActivityRegion[],
      );
      handleContinue({ ...data, regions });
    } else {
      handleContinue({ ...data, regions: regionResponse?.data });
    }
  });

  const addDateForm = useCallback(() => {
    appendDate({
      placeFormIndex: 0,
      indexPosition: datesFields.length,
      volunteerNumber: undefined,
      noVolunteerLimit: false,
      startDate: undefined,
      endDate: undefined,
      dueDate: undefined,
      teamsNumber: undefined,
      teamsMaxSize: undefined,
      teamsMinSize: undefined,
    } as IDatesDTO);
  }, [appendDate, datesFields]);

  const removeDateForm = useCallback(
    (dateIndex: number) => {
      removeDate(dateIndex);
    },
    [removeDate],
  );

  useEffect(() => {
    if (
      regionResponse &&
      regionResponse?.data?.length > 0 &&
      !isLoadingRegions
    ) {
      const checkboxFields = makeCheckboxesFields(
        regionResponse?.data,
        defaultValues?.regions,
      );
      setRegionCheckboxFields(checkboxFields);
    }
  }, [regionResponse, isLoadingRegions, defaultValues]);

  useEffect(() => {
    if (regionResponse?.data) {
      const advertiseOption = getActivityAdvertiseOption(
        regionResponse?.data,
        defaultValues?.regions,
      );
      setAdvertiseRegions(advertiseOption);
      resetField('advertiseRegion', {
        defaultValue: advertiseOption,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [regionResponse]);

  const isDisabledPublishButton = useMemo(() => {
    if (advertiseRegions === AdvertiseRegionOptionsEnum.SelectRegions) {
      const hasCheckedMainRegion = regionCheckboxFields.find(
        (regionCheckbox) =>
          regionCheckbox.isAllChecked || regionCheckbox.isPartialSelected,
      );
      return !isValid || !hasCheckedMainRegion;
    }

    return !isValid;
  }, [isValid, advertiseRegions, regionCheckboxFields]);

  return {
    datesFields,
    appendDate,
    addDateForm,
    removeDateForm,
    handleBack,
    handleSubmitForm,
    control,
    isLoading,
    isTeamEvent,
    advertiseRegions,
    setAdvertiseRegions,
    regionCheckboxFields,
    handleSelectMainRegion,
    handleSelectSubRegion,
    errors,
    isDisabledPublishButton,
    isLoadingRegions,
    setValue,
  };
};
