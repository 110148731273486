import Yup from '~/utils/validations/yup';

export const emailPreferencesOptions = [
  {
    title: 'Daily digests',
    name: 'dailyDigest',
    description:
      "You will receive daily emails containing a summary of the volunteers' activity.",
  },
  {
    title: 'Weekly digests',
    name: 'weeklyDigest',
    description:
      "You will receive weekly emails containing a summary of the volunteers' activity.",
  },
];

export const schema = Yup.object().shape({
  dailyDigest: Yup.boolean(),
  weeklyDigest: Yup.boolean(),
});
