import {
  Box,
  Checkbox as NBCheckbox,
  Divider,
  FormControl,
  HStack,
  Stack,
  Text,
  VStack,
} from 'native-base';
import { Controller } from 'react-hook-form';
import Button from 'ui/components/Button';
import { CustomDatePicker } from 'ui/components/DateTimePickers/DatePicker';
import Input from 'ui/components/Input';
import { colors } from 'ui/theme/colors';

import { ExternalApplyLinkInput } from '~/components/ExternalApplyLinkInput';
import { noLimitOptionsCheck } from '~/constants/forms.constants';

import { DateFormProps } from './types';
import { useDateFormController } from './useDateFormController';

export const DateForm = ({
  control,
  errors,
  isEventActivity,
  isTeamEvent,
  parentIndex,
  formFieldName,
  dateFormIndex,
  dateNumber,
  removeOneDateForm,
  isEditing = false,
  setValue,
}: DateFormProps) => {
  const {
    maxDate,
    currentNoVolunteerLimit,
    externalApplyLink,
    onChangeStartDate,
    onChangeNoVolunteerLimit,
    onExternalApplyLinkChange,
  } = useDateFormController({
    isEditing,
    control,
    parentIndex,
    formFieldName,
    setValue,
  });

  return (
    <Stack
      bgColor={colors.white}
      borderRadius="xl"
      borderWidth={'1'}
      borderColor="gray.200"
      p={6}
      mt={10}
    >
      <VStack justifyContent="space-between" space={4}>
        <Stack flex={1} w="full">
          <HStack w="full" justifyContent="space-between" alignItems="center">
            <Text color="gray.600" fontWeight="500" fontSize="lg">
              Date {dateNumber ? `${dateNumber}` : ' '}
            </Text>
            {dateFormIndex && removeOneDateForm && dateFormIndex > 0 ? (
              <Button
                variant="ghost"
                leftIconName="trash"
                leftIconColor="error.600"
                color="error.600"
                minW="8rem"
                w="8rem"
                onPress={() => removeOneDateForm(dateFormIndex)}
              >
                <Text color="error.600">Remove Date</Text>
              </Button>
            ) : null}{' '}
          </HStack>
          <Divider my={4} />
        </Stack>
        <HStack space={4} flex={1}>
          <Stack flex={0.5}>
            <CustomDatePicker
              control={control}
              name={`${formFieldName}[${parentIndex}].startDate`}
              label="Start date and time"
              placeholder="Type start date"
              errorMessage={
                errors[formFieldName]?.[parentIndex]?.startDate &&
                (errors[formFieldName]?.[parentIndex]?.startDate
                  ?.message as string)
              }
              isRequired
              dateFormat="dd/MM/yyyy h:mm aa"
              timeIntervals={10}
              showTimeSelect
              onChangeDate={onChangeStartDate}
            />
          </Stack>
          <Stack flex={0.5}>
            <CustomDatePicker
              control={control}
              name={`${formFieldName}[${parentIndex}].endDate`}
              label="End date and time"
              placeholder="Type the end date"
              errorMessage={
                errors[formFieldName]?.[parentIndex]?.endDate &&
                (errors[formFieldName]?.[parentIndex]?.endDate
                  ?.message as string)
              }
              dateFormat="dd/MM/yyyy h:mm aa"
              isRequired
              timeIntervals={10}
              showTimeSelect
              maxDate={isEventActivity ? maxDate : undefined}
            />
          </Stack>
        </HStack>
      </VStack>

      <HStack space={4} mt={4} flex={1}>
        <Stack flex={0.5}>
          <CustomDatePicker
            control={control}
            name={`${formFieldName}[${parentIndex}].dueDate`}
            label="Application deadline"
            placeholder="Type due date"
            helperText="Volunteers will no longer be able to apply after the deadline."
            errorMessage={
              errors[formFieldName]?.[parentIndex]?.dueDate &&
              (errors[formFieldName]?.[parentIndex]?.dueDate?.message as string)
            }
            dateFormat="dd/MM/yyyy"
            timeIntervals={10}
          />
        </Stack>
        {!isTeamEvent ? (
          <Stack flex={0.5}>
            <Input
              label="Volunteers"
              isNumeric
              isRequired={true}
              placeholder="Type the limit number of volunteers"
              name={`${formFieldName}[${parentIndex}].volunteerNumber`}
              control={control}
              isDisabled={currentNoVolunteerLimit}
              errorMessage={
                currentNoVolunteerLimit
                  ? ''
                  : errors[formFieldName]?.[parentIndex]?.volunteerNumber &&
                    (errors[formFieldName]?.[parentIndex]?.volunteerNumber
                      ?.message as string)
              }
            />
            <Box mt={2} ml={1}>
              <Controller
                control={control}
                name={`${formFieldName}[${parentIndex}].noVolunteerLimit`}
                render={({ field: { onChange, value } }) => (
                  <NBCheckbox
                    value={value}
                    onChange={(noLimit: boolean) => {
                      onChange(noLimit);
                      onChangeNoVolunteerLimit(noLimit);
                    }}
                    isChecked={value}
                    isDisabled={!!externalApplyLink}
                  >
                    <Text fontWeight={400} fontSize="sm" mx={1} mt={1}>
                      {noLimitOptionsCheck[0].text}
                    </Text>
                  </NBCheckbox>
                )}
              />
            </Box>
          </Stack>
        ) : (
          <Stack flex={0.5} />
        )}
      </HStack>
      {isTeamEvent && (
        <HStack my={4} space={4} w="full">
          <VStack flex={0.5}>
            <Input
              w="100%"
              label="Amount of Teams"
              isNumeric
              isRequired={isTeamEvent}
              placeholder="Limit number of teams"
              name={`${formFieldName}[${parentIndex}].teamsNumber`}
              control={control}
              errorMessage={
                currentNoVolunteerLimit
                  ? ''
                  : errors[formFieldName]?.[parentIndex]?.teamsNumber &&
                    (errors[formFieldName]?.[parentIndex]?.teamsNumber
                      ?.message as string)
              }
            />
          </VStack>
          <FormControl flex={0.5} isRequired>
            <VStack w="100%">
              <FormControl.Label>Team Size</FormControl.Label>
              <HStack space={2}>
                <Stack flex={0.5}>
                  <Input
                    isNumeric
                    isRequired={isTeamEvent}
                    placeholder="Minimum"
                    name={`${formFieldName}[${parentIndex}].teamsMinSize`}
                    control={control}
                  />
                </Stack>
                <Stack flex={0.5}>
                  <Input
                    isNumeric
                    isRequired={isTeamEvent}
                    placeholder="Maximum"
                    name={`${formFieldName}[${parentIndex}].teamsMaxSize`}
                    control={control}
                  />
                </Stack>
              </HStack>
              {errors[formFieldName] &&
                errors[formFieldName]?.[parentIndex]?.teamsMinSize?.message && (
                  <FormControl.ErrorMessage>
                    {
                      errors[formFieldName]?.[parentIndex]?.teamsMinSize
                        ?.message
                    }
                  </FormControl.ErrorMessage>
                )}

              {errors[formFieldName] &&
                errors[formFieldName]?.[parentIndex]?.teamsMaxSize?.message && (
                  <FormControl.ErrorMessage>
                    {
                      errors[formFieldName]?.[parentIndex]?.teamsMaxSize
                        ?.message
                    }
                  </FormControl.ErrorMessage>
                )}
            </VStack>
          </FormControl>
        </HStack>
      )}
      <ExternalApplyLinkInput
        isTeamEvent={isTeamEvent}
        control={control}
        isEditing={isEditing}
        name={`${formFieldName}[${parentIndex}].externalApplyLink`}
        onChange={(value) => onExternalApplyLinkChange(!!value)}
      />
    </Stack>
  );
};
