import {
  HStack,
  Image,
  Pressable,
  Skeleton,
  Stack,
  Text,
  VStack,
} from 'native-base';
import placeholderImage from 'ui/assets/svgs/placeholder.svg';
import { ActivityTypeBadge } from 'ui/components/v2/Badges/ActivityTypeBadge';
import { colors } from 'ui/theme/colors';
import { ActivitySemanticType } from 'ui/types/activities';

import { OngoingActivitySearchTileProps } from './types';

export const OngoingActivitySearchTile = (
  props: OngoingActivitySearchTileProps,
) => {
  const { title, coverImageUrl, organizationName, onPress } = props;

  return (
    <Pressable p={4} onPress={onPress}>
      <VStack
        backgroundColor={{ md: colors.gray[100] }}
        borderRadius={8}
        borderWidth={{ md: 1 }}
        borderColor={colors.gray[200]}
        padding={4}
      >
        <HStack flex={1} w="full" space={3} alignItems={'center'}>
          <VStack
            w={20}
            h={20}
            borderRadius={200}
            borderWidth={1}
            overflow={'hidden'}
            backgroundColor={colors.gray[500]}
            borderColor={colors.gray[200]}
          >
            <Image
              w="full"
              h="full"
              resizeMode="cover"
              source={{ uri: coverImageUrl }}
              alt={`Logo from Organization ${organizationName}`}
              fallbackSource={{ uri: placeholderImage }}
            />

            <Stack width="100%" height="100%">
              <Skeleton width="100%" height="100%" />
            </Stack>
          </VStack>
          <VStack space={2} flex={1}>
            <HStack
              justifyContent={{ base: 'space-between', md: 'flex-start' }}
              space={3}
            >
              <ActivityTypeBadge activityType={ActivitySemanticType.Ongoing} />
            </HStack>

            <VStack space={1}>
              <Text
                fontSize={{ base: 'sm', md: 'lg' }}
                fontWeight={500}
                color={colors.darkText}
                noOfLines={1}
              >
                {title}
              </Text>

              <Text
                fontSize={{ base: 'xs', md: 'sm' }}
                fontWeight={400}
                color={colors.gray[600]}
                noOfLines={1}
                lineHeight={'125%'}
              >
                by {organizationName}
              </Text>
            </VStack>
          </VStack>
        </HStack>
      </VStack>
    </Pressable>
  );
};
