import { format, parseISO } from 'date-fns';
import { VStack } from 'native-base';
import { useEffect, useMemo } from 'react';
import { useQuery } from 'react-query';
import { InsightsActivityType } from 'ui/types/activities';

import { BarChart } from '~/pages/Authenticated/Insights/charts/BarChart';
import { dateFilterIsMoreThan30Days } from '~/pages/Authenticated/Insights/components/Applications/utils';
import { InsightScaffoldChildrenReactComponent } from '~/pages/Authenticated/Insights/components/InsightScaffold';
import { activityTypeConfigMap } from '~/pages/Authenticated/Insights/components/Trending/utils';
import {
  InsightPageFilters,
  useInsightFilters,
} from '~/pages/Authenticated/Insights/useInsightFilters';
import { InsightCacheConfig } from '~/pages/Authenticated/Insights/utils';
import InsightActivityApplicationService, {
  GetApplicationsReportQueryParams,
} from '~/services/resources/insightActivityApplication';
import { InsightActivityApplicationGroupedBy } from '~/services/resources/types';

import { InsightsActivityColors } from '../../charts/constants';

const buildQueryKey = (filters: InsightPageFilters) => {
  const isMoreThan30Days = dateFilterIsMoreThan30Days(filters);

  const params: GetApplicationsReportQueryParams = {
    apps: filters.apps,
    ecosystem: filters.selectedEcosystemId,
    organization: filters.selectedOrganizationId,
    startDate: filters.from!,
    endDate: filters.to!,
    granularity: isMoreThan30Days ? 'Monthly' : 'Daily',
    groupBy: [
      InsightActivityApplicationGroupedBy.ApplicationDate,
      InsightActivityApplicationGroupedBy.ActivityType,
    ],
  };
  return [
    InsightActivityApplicationService.APPLICATION_DATE_REPORT_KEY,
    params,
  ];
};

export const ApplicationsByType: InsightScaffoldChildrenReactComponent = ({
  onSetChart,
}) => {
  const { filters, hasFilterSetted } = useInsightFilters();

  const {
    data: response,
    isFetching,
    isLoading,
  } = useQuery({
    queryKey: buildQueryKey(filters),
    queryFn: async ({ queryKey }) => {
      const [, params] = queryKey;

      return await InsightActivityApplicationService.getApplicationDatesReport(
        params as GetApplicationsReportQueryParams,
      );
    },
    onSuccess(data) {
      onSetChart(null, data);
    },
    enabled: hasFilterSetted,
    ...InsightCacheConfig,
  });

  useEffect(() => {
    onSetChart(null, response ?? []);
    return () => {
      onSetChart(null, []);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [response]);

  const chartData = useMemo(() => {
    const dataMap = new Map();
    const labelsSet = new Set<string>();
    const isMoreThan30Days = dateFilterIsMoreThan30Days(filters);

    // Agrupando os dados por activityType e por mês/ano
    (response ?? []).forEach((item) => {
      const activityTypeLabel =
        activityTypeConfigMap.get(item.activityType)?.label ??
        item.activityType;

      const monthYear = format(
        parseISO(item.date),
        isMoreThan30Days ? 'MMM yyyy' : 'dd MMM',
      );
      labelsSet.add(monthYear);

      if (!dataMap.has(activityTypeLabel)) {
        dataMap.set(activityTypeLabel, new Map());
      }

      const activityTypeMap = dataMap.get(activityTypeLabel);
      if (!activityTypeMap.has(monthYear)) {
        activityTypeMap.set(monthYear, 0);
      }

      activityTypeMap.set(
        monthYear,
        activityTypeMap.get(monthYear) + item.total,
      );
    });

    // Construindo os dados para o gráfico
    const series = Array.from(dataMap.keys()).map((activityType) => {
      const activityTypeMap = dataMap.get(activityType);
      return {
        name: activityType,
        data: Array.from(labelsSet).map(
          (label) => activityTypeMap.get(label) || 0,
        ),
      };
    });

    const labels = Array.from(labelsSet);
    return { series, labels };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [response]);

  const colors = useMemo(() => {
    return chartData.series.map(
      (series) => InsightsActivityColors[series.name as InsightsActivityType],
    );
  }, [chartData.series]);

  return (
    <VStack>
      <BarChart
        colors={colors}
        isLoading={isLoading || isFetching}
        series={chartData.series}
        labels={chartData.labels}
        xaxis={{
          categories: chartData.labels,
        }}
        yaxis={{
          title: {
            text: 'Number of Applications',
          },
        }}
      />
    </VStack>
  );
};
