/* eslint-disable @typescript-eslint/no-explicit-any */
import { Alert, Divider, HStack, Image, Stack, Text } from 'native-base';
import Icon from 'ui/components/Icon';
import ReadMore from 'ui/components/ReadMore';
import Select from 'ui/components/Select';
import { groupPermissionsByCategory } from 'ui/utils/groupPermissionByCategory';

import { colors } from '~/theme/colors';

import { PermissionsFormCardProps } from './types';

export const PermissionsFormCard = ({
  control,
  appsPermissionsGroupOptions,
  index,
  app,
  selectedPermissions,
  ecosystemId,
  cardTitle,
  memberType,
  selectInputName,
  selectInputLabel,
  handleChangeSelectedPermission,
}: PermissionsFormCardProps) => {
  return (
    <Stack
      borderWidth="1px"
      borderColor="gray.200"
      borderRadius={12}
      bgColor="white"
      padding={4}
    >
      <Stack space={3}>
        <Text color="gray.600" fontWeight={500} fontSize="md">
          {cardTitle}
        </Text>
        <Divider />
      </Stack>
      {!appsPermissionsGroupOptions.length ? (
        <Stack mt={6}>
          <Alert
            padding={3}
            backgroundColor="warning.100"
            status="warning"
            borderRadius="md"
            mb={8}
          >
            <Text maxW="500px">
              You don&apos;t have any published permission groups for this app.{' '}
              <a
                style={{ color: colors.singletons.darkText }}
                href="/settings/permissions"
              >
                Click here to review and publish your drafts.
              </a>
            </Text>
          </Alert>
        </Stack>
      ) : null}
      <Stack mt={8}>
        <Select
          label={selectInputLabel}
          name={selectInputName}
          isRequired
          control={control}
          onChange={({ value }: any) =>
            handleChangeSelectedPermission(value, index, ecosystemId)
          }
          options={appsPermissionsGroupOptions}
          placeholder="Select a group of permissions"
        />
      </Stack>
      <Stack space={2} mt={8}>
        <Text fontWeight={500} fontSize="md">
          {selectedPermissions[index]?.name || '-'}
        </Text>
        <Text fontSize="xs" color="gray.600">
          {selectedPermissions[index]?.description || '-'}
        </Text>
      </Stack>
      <HStack mt={8}>
        <Stack space={2}>
          <Text fontWeight={500} color="gray.600">
            App
          </Text>
          <HStack space={2} alignItems="center">
            <Image
              width="24px"
              height="24px"
              source={{ uri: app?.logo }}
              alt="App logo"
              borderRadius={2}
            />
            <Text>{app?.name || '-'}</Text>
          </HStack>
        </Stack>
        <Stack marginLeft="180px" space={2}>
          <Text fontWeight={500} color="gray.600">
            Type of user
          </Text>
          <Text>{memberType}</Text>
        </Stack>
      </HStack>

      <Stack mt={2}>
        <ReadMore
          textHeight="0px"
          btnBgColor="white"
          btnTextColor="darkText"
          rightIconColor="darkText"
          buttonTextShow="Show permissions"
          buttonTextHide="Hide permissions"
          text={
            <div id={`partner-permission-${index}`}>
              <Stack space={4} mt={6}>
                {Object.values(
                  groupPermissionsByCategory(
                    selectedPermissions[index]?.permissions || [],
                  ),
                ).map((category) => (
                  <Stack space={2} key={category.name}>
                    <Text fontSize="sm" fontWeight={500} color="gray.600">
                      {category?.displayName}
                    </Text>
                    {category?.permissions?.map((permission) => (
                      <Stack space={3} key={permission._id}>
                        <HStack space={2}>
                          <Icon
                            icon="check"
                            size={20}
                            color={colors.success[500]}
                          />
                          <Text fontSize="xs" fontWeight={400} color="gray.600">
                            {permission.description}
                          </Text>
                        </HStack>
                      </Stack>
                    ))}
                  </Stack>
                ))}
              </Stack>
            </div>
          }
        />
      </Stack>
    </Stack>
  );
};
