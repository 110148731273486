import { HStack, Stack, Text, VStack } from 'native-base';

import { IInputsProps } from './types';
import { useFormFieldsRenderController } from './useFormFieldsRenderController';

const FormFieldsRender = (props: IInputsProps) => {
  const { control, inputsSections, formState, renderInput } =
    useFormFieldsRenderController(props);

  return (
    <Stack w="full">
      {inputsSections?.map((inputSection) => {
        const { sectionCustomHeaderSlot } = inputSection;
        return (
          <VStack
            key={inputSection.key}
            mt={inputSection.sectionTitle ? '24px' : 0}
          >
            <VStack space={2}>
              {inputSection.sectionTitle && (
                <Text color="gray.600" fontSize="xl" fontWeight={500}>
                  {inputSection.sectionTitle}
                </Text>
              )}
              {inputSection.sectionSubtitle && (
                <Text color="gray.600" fontSize="sm" fontWeight="400">
                  {inputSection.sectionSubtitle}
                </Text>
              )}

              {inputSection.sectionCustomHeaderSlot && sectionCustomHeaderSlot}
            </VStack>

            {inputSection.sectionInputRows.map((inputRow) => (
              <HStack key={inputRow.key} w="full" space={4}>
                {inputRow.inputs.map((input) => (
                  <Stack flex={1} key={input.id} my={4} zIndex={9999}>
                    {renderInput({ ...input, control, formState })}
                  </Stack>
                ))}
              </HStack>
            ))}
          </VStack>
        );
      }) || null}
    </Stack>
  );
};

export default FormFieldsRender;
