import { useEffect, useState } from 'react';

import { TitleNode } from '~/components/Cards/NotificationCard/BaseNotificationCard/types';
import { makeOrganizationTitleNode } from '~/components/Cards/NotificationCard/utils';

import { BulkActivitiesCreatedNotificationCardProps } from './types';

export const useBulkActivitiesCreatedNotificationCardController = (
  props: BulkActivitiesCreatedNotificationCardProps,
) => {
  const {
    date,
    activitiesCount,
    organizationId,
    organizationName,
    organizationLogoUrl,
    activitiesAppName,
  } = props;

  const [titleNodes, setTitleNodes] = useState<TitleNode[]>([]);

  useEffect(() => {
    const authorTileNode = makeOrganizationTitleNode(
      organizationId,
      organizationName,
    );
    setTitleNodes([
      authorTileNode,
      {
        text: `published ${activitiesCount} new activities on`,
        fontWeight: 500,
      },
      { text: 'on' },
      { text: activitiesAppName, fontWeight: 500 },
    ]);
  }, [organizationId, organizationName, activitiesAppName, activitiesCount]);

  return {
    date,
    titleNodes,
    organizationName,
    organizationLogoUrl,
  };
};
