import { useCallback, useRef, useState } from 'react';
import { toast } from 'react-toastify';
import { IFilterValue } from 'ui/components/DataTable/types';
import { IModalRefProps } from 'ui/components/Modals/Modal/types';

import { ActivityTypeEnum } from '~/enums';
import { useLoadPartnerApplicationsReport } from '~/hooks/reports/partners/useLoadPartnerApplicationsReport';
import { useLoadPartnerLogHoursTotalReport } from '~/hooks/reports/partners/useLoadPartnerLogHoursTotalReport';

import { COMMON_MESSAGES } from '../../../../../../constants/messages.constants';
import { useLoadPartnerLogHoursReportByType } from '../../../../../../hooks/reports/partners/useLoadPartnerLogHoursReportByType';
import ActivityService from '../../../../../../services/resources/activity';
import { EcosystemNetworkConnectionsPopulated } from '../../../../../../types/interfaces/ecosystem-network-connections';
import { IOrganization } from '../../../../../../types/interfaces/organization';
import { getPartnerEcosystem } from '../../../../../../utils/functions';

export interface usePartnerActivitiesControllerProps {
  connection: EcosystemNetworkConnectionsPopulated;
  organization: IOrganization;
}

export const usePartnerActivitiesController = ({
  connection,
  organization,
}: usePartnerActivitiesControllerProps) => {
  const actionReportModalRef = useRef<IModalRefProps>(null);

  const { data: applicationsCount, isLoading: isApplicationsCountLoading } =
    useLoadPartnerApplicationsReport(connection._id);

  const {
    data: totalHoursLoggedCount,
    isLoading: isTotalHoursLoggedCountLoading,
  } = useLoadPartnerLogHoursTotalReport(connection._id);

  const { data: eventLogHoursCount, isLoading: isEventLogHoursCountLoading } =
    useLoadPartnerLogHoursReportByType(connection._id, ActivityTypeEnum.Event);

  const { data: actionLogHoursCount, isLoading: isActionLogHoursCountLoading } =
    useLoadPartnerLogHoursReportByType(connection._id, ActivityTypeEnum.Action);

  const {
    data: ongoingLogHoursCount,
    isLoading: isOngoingLogHoursCountLoading,
  } = useLoadPartnerLogHoursReportByType(
    connection._id,
    ActivityTypeEnum.OngoingOpportunity,
  );

  const [isLoadingCreatedActivities, setIsLoadingCreatedActivities] =
    useState<boolean>(false);

  const [createdActivitiesCount, setCreatedActitiviesCount] =
    useState<number>(0);

  const loadPartnerActivities = useCallback(
    async (skip: number, limit: number, filter: IFilterValue | null) => {
      try {
        setIsLoadingCreatedActivities(true);
        const partnerEcosystem = getPartnerEcosystem(connection, organization);
        const { data: response } = await ActivityService.findAll({
          filter: JSON.stringify({
            ...filter,
            organization: organization._id,
            ecosystem: partnerEcosystem?._id,
          }),
          skip,
          limit,
          sort: JSON.stringify({
            createdAt: -1,
          }),
        });

        setCreatedActitiviesCount(response?.count);

        return {
          data: response?.data,
          count: response?.count,
        };
      } catch (error) {
        toast.error(COMMON_MESSAGES.ERROR_FETCHING_PARTNER_CREATED_ACTIVITIES, {
          position: 'bottom-center',
        });
        return {
          data: [],
          count: 0,
        };
      } finally {
        setIsLoadingCreatedActivities(false);
      }
    },
    [],
  );

  const openModal = () => {
    actionReportModalRef.current?.open();
  };

  return {
    applicationsCount,
    isApplicationsCountLoading,
    totalHoursLoggedCount,
    isTotalHoursLoggedCountLoading,
    eventLogHoursCount,
    isEventLogHoursCountLoading,
    actionLogHoursCount,
    isActionLogHoursCountLoading,
    ongoingLogHoursCount,
    isOngoingLogHoursCountLoading,
    actionReportModalRef,
    loadPartnerActivities,
    openModal,
    isLoadingCreatedActivities,
    createdActivitiesCount,
  };
};
