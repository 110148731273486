import { useMemo } from 'react';
import { LocationOptionsEnum } from 'ui/enums';
import { ActivityDefinition } from 'ui/types/interfaces/activity-definition';
import { MeasurementUnit } from 'ui/types/measurementUnit';
import { formatMeasurementAmount } from 'ui/utils/measurements/format';

import { mapToActivitySemanticType } from './mappers';

export const ActivityDefinitionGetters = (
  activityDefinition: ActivityDefinition,
  populate?: { measurementUnit?: MeasurementUnit },
) => {
  const value = useMemo(
    () => ({
      isFromHome: () => {
        return (
          activityDefinition.locationOption === LocationOptionsEnum.FromHome
        );
      },

      getSemanticType: () => {
        return mapToActivitySemanticType(
          activityDefinition.type,
          activityDefinition.eventApplicationType,
        );
      },

      getFormattedTargetAmount: () => {
        if (!populate?.measurementUnit) return activityDefinition.targetAmount;

        return formatMeasurementAmount(
          activityDefinition.targetAmount || 0,
          populate.measurementUnit,
        );
      },
    }),
    [activityDefinition, populate],
  );
  return value;
};
