import { OrganizationConnectedNetworkAppSharing } from '~/types/interfaces/networkAppSharing';

import { useQuery } from '../useQuery';

export const useLoadAllAppSharingsByOrganization = (
  organizationId?: string,
) => {
  const {
    data: appsConnectedToOrganization,
    isLoading: isLoadingAppsConnectedToOrganization,
    refetch: refetchAppsConnectedToOrganization,
  } = useQuery<OrganizationConnectedNetworkAppSharing[]>(
    `/network-app-sharing/organization/${organizationId}/all-apps`,
    {
      queryOptions: {
        enabled: !!organizationId,
      },
      key: `all-network-app-sharing-${organizationId}`,
    },
  );

  return {
    appsConnectedToOrganization,
    isLoadingAppsConnectedToOrganization,
    refetchAppsConnectedToOrganization,
  };
};
