import FeatherIcon from 'feather-icons-react';
import { Box, Switch, Text } from 'native-base';
import React from 'react';
import Button from 'ui/components/Button';

import { colors } from '../../theme/colors';
import { PopOverOptions } from '../PopOverOptions';
import { IDataTableActionProps } from './types';

const DataTableActions: React.FC<IDataTableActionProps> = ({
  color = colors.primary[400],
  editButton,
  editText = 'Edit',
  containerWidth = '40%',
  editIconSize = '12px',
  viewButton,
  deleteButton,
  toggleValue = false,
  toggleButton,
  toggleText,
  popoverActions,
  popoverOptions,
  popoverText = 'Options',
  popoverTextColor = colors.primary[400],
  popoverTextPosition = 'right',
  popoverIcon = 'more-vertical',
  popoverIconColor = colors.primary[400],
  onPressToggleButton,
  onPressEditButton,
  onPressViewButton,
  onPressDeleteButton,
}) => {
  return (
    <Box flexDirection="row" alignItems="center" width={containerWidth}>
      {viewButton && onPressViewButton ? (
        <Button
          minW="20"
          leftIcon={
            <FeatherIcon
              icon="eye"
              size="12px"
              color={color || colors.primary}
            />
          }
          onPress={() => onPressViewButton()}
          variant="link"
          colorScheme="primary"
        >
          View
        </Button>
      ) : null}

      {deleteButton && onPressDeleteButton ? (
        <Button
          minW="20"
          leftIconName="trash"
          onPress={onPressDeleteButton}
          variant="link"
          colorScheme="error"
          leftIconColor={colors.error[500]}
        >
          Delete
        </Button>
      ) : null}

      {editButton && onPressEditButton ? (
        <Button
          minW="20"
          leftIconColor={color}
          leftIconName="edit"
          leftIconSize={editIconSize}
          onPress={() => onPressEditButton()}
          variant="link"
          colorScheme="primary"
        >
          {editText}
        </Button>
      ) : null}

      {toggleButton && onPressToggleButton ? (
        <Box flexDir="row">
          <Switch
            size="sm"
            value={toggleValue}
            onToggle={onPressToggleButton}
          />
          {toggleText ? (
            <Text
              fontSize="sm"
              color={toggleValue ? 'red.400' : 'grey.600'}
              fontWeight={400}
            >
              {toggleText}
            </Text>
          ) : null}
        </Box>
      ) : null}

      {popoverActions && popoverOptions?.length ? (
        <PopOverOptions
          popovText={popoverText}
          popovTextPosition={popoverTextPosition}
          popovIcon={popoverIcon}
          options={popoverOptions}
          popTextColor={popoverTextColor}
          popovIconColor={popoverIconColor}
        />
      ) : null}
    </Box>
  );
};

export default DataTableActions;
