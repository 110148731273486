import { useEffect, useState } from 'react';
import {
  Control,
  useFieldArray,
  UseFormGetValues,
  UseFormSetValue,
} from 'react-hook-form';
import { toast } from 'react-toastify';
import { PermissionCategories } from 'ui/enums';
import {
  Permission,
  PopulatedAppPermissionGroup,
} from 'ui/types/interfaces/app-permissions-group';
import { groupPermissionsByCategory } from 'ui/utils/groupPermissionByCategory';

import {
  AddPermissionGroupFormValues,
  GroupedPermissions,
  PermissionConfiguration,
} from '~/pages/Authenticated/Settings/Permissions/AddPermissionsGroup/types';
import { OrganizationConnectedNetworkAppSharing } from '~/types/interfaces/networkAppSharing';

import { AppPermissionGroupService } from '../../../../../../services/resources/appPermissionGroup';

export const usePermissionsController = (
  getValues: UseFormGetValues<AddPermissionGroupFormValues>,
  control: Control<AddPermissionGroupFormValues>,
  appPermissionGroupEdit?: PopulatedAppPermissionGroup,
  selectedNetworkAppSharing?: OrganizationConnectedNetworkAppSharing,
) => {
  const permissionType = getValues('type');

  const isUserOwnerOfApp =
    !!selectedNetworkAppSharing?.isSelfOrganizationSharing;

  const [isLoading, setIsLoading] = useState(false);

  const { fields, replace } = useFieldArray({
    control,
    name: 'groupedPermissions',
  });

  const loadTemplatePermissionsFromAppSharing = async () => {
    if (fields.length && fields[0]?.permissions[0]?.type === permissionType)
      return;
    try {
      setIsLoading(true);
      const networkAppSharingGroups =
        selectedNetworkAppSharing?.appPermissionGroups?.map(
          (permissionGroup) => permissionGroup._id,
        ) || [];
      const { data } =
        await AppPermissionGroupService.findAppSharingTemplatePermissions(
          networkAppSharingGroups,
          permissionType,
        );

      const arrayFieldDataObject = groupPermissionsByCategory(
        data
          .map((group: PopulatedAppPermissionGroup) => group.permissions)
          .flat(),
      );

      const arrayFieldData: GroupedPermissions[] = Object.values(
        arrayFieldDataObject,
      ).map((obj) => {
        return {
          category: {
            description: obj.description,
            displayName: obj.displayName,
            name: obj.name as PermissionCategories,
          },
          permissions: obj.permissions,
          configurations: loadSharedAppConfigurations(obj.permissions),
        };
      });
      replace(arrayFieldData);
    } catch (e) {
      toast.error('Error on trying to load permissions');
      console.log(e);
    } finally {
      setIsLoading(false);
    }
  };

  const loadAllTemplatePermissions = async () => {
    if (fields.length && fields[0]?.permissions[0]?.type === permissionType)
      return;
    try {
      setIsLoading(true);
      const { data } =
        await AppPermissionGroupService.findAllTemplatePermissions(
          permissionType,
        );
      const arrayFieldDataObject = groupPermissionsByCategory(data);
      const arrayFieldData: GroupedPermissions[] = Object.values(
        arrayFieldDataObject,
      ).map((obj) => {
        return {
          category: {
            description: obj.description,
            displayName: obj.displayName,
            name: obj.name as PermissionCategories,
          },
          permissions: obj.permissions.map((p) => ({
            ...p,
            permissionTemplateId: p._id,
          })) as Permission[],
          configurations: loadAppOwnerConfigurations(obj.permissions),
        };
      });
      replace(arrayFieldData);
    } catch (e) {
      toast.error('Error on trying to load permissions');
      console.log(e);
    } finally {
      setIsLoading(false);
    }
  };

  const loadAppOwnerConfigurations = (
    permissions: Permission[],
  ): PermissionConfiguration[] => {
    const selectedTemplates = appPermissionGroupEdit?.permissions || [];

    return permissions.map((permission) => {
      const selectedPermission = selectedTemplates.find(
        (p) => p.permissionTemplateId === permission._id,
      );
      return {
        templateId: selectedPermission?.permissionTemplateId || '',
        conditions:
          permission?.conditions?.map((c, cIndex) => {
            return {
              ...c,
              value:
                selectedPermission?.conditions?.[cIndex]?.value ||
                c.defaultValue,
            };
          }) || [],
        sharingSettings: selectedPermission
          ? selectedPermission.sharingSettings
          : permission.sharingSettings,
        restrictionType: permission.restrictionType,
      };
    });
  };

  const loadSharedAppConfigurations = (
    permissions: Permission[],
  ): PermissionConfiguration[] => {
    const selectedTemplates = appPermissionGroupEdit?.permissions || [];

    return permissions.map((permission) => {
      const selectedPermission = selectedTemplates.find(
        (p) => p.permissionTemplateId === permission.permissionTemplateId,
      );
      return {
        templateId: getSelectedTemplateIdsShared(
          selectedPermission,
          permission,
        ),
        conditions:
          permission?.conditions?.map((c, cIndex) => {
            return {
              ...c,
              value:
                selectedPermission?.conditions?.[cIndex]?.value ||
                c.value ||
                c.defaultValue,
            };
          }) || [],
        sharingSettings: {
          ...(selectedPermission
            ? selectedPermission.sharingSettings
            : permission.sharingSettings),
          isShared: true,
        },
        restrictionType: permission.restrictionType,
      };
    });
  };

  const getSelectedTemplateIdsShared = (
    savedPermission: Permission | undefined,
    templatePermission: Permission,
  ) => {
    if (templatePermission.sharingSettings.isRequired) {
      return templatePermission.permissionTemplateId || '';
    }
    if (savedPermission) {
      return savedPermission.permissionTemplateId || '';
    }
    return '';
  };

  useEffect(() => {
    // if isUserOwnerOfApp is false, we will fetch the template permissions
    // from the app sharing
    if (!isUserOwnerOfApp) {
      loadTemplatePermissionsFromAppSharing();
    }
    // load all permissions available
    else {
      loadAllTemplatePermissions();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isUserOwnerOfApp]);

  const isCheckboxDisabled = (configuration: PermissionConfiguration) => {
    return (
      configuration.sharingSettings.isShared &&
      configuration.sharingSettings.isRequired
    );
  };

  const isInputDisabled = (
    isCheckboxChecked: boolean,
    configuration: PermissionConfiguration,
  ) => {
    if (!isCheckboxChecked && !configuration.sharingSettings.isShared)
      return true;
    return (
      !isCheckboxChecked &&
      configuration.sharingSettings.isShared &&
      configuration.sharingSettings.denyEdition
    );
  };

  const onQuantitativeInputChanged = (
    value: string,
    setValue: UseFormSetValue<AddPermissionGroupFormValues>,
    index: number,
    cIndex: number,
    pIndex: number,
  ) => {
    console.log('value', value);
    setValue(
      `groupedPermissions.${index}.configurations.${pIndex}.conditions.${cIndex}.value`,
      value,
    );
  };

  return {
    isLoading,
    isCheckboxDisabled,
    isInputDisabled,
    fields,
    onQuantitativeInputChanged,
  };
};
