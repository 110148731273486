import { HStack, Skeleton } from 'native-base';

export const LoadingEmailPreferences = () => {
  return (
    <HStack space={2} alignItems="center">
      <Skeleton height="66px" width="85%" />
      <Skeleton height="40px" width="15%" />
    </HStack>
  );
};
