import { colors } from '../../theme/colors';
import Button from '../Button';
import Icon from '../Icon';
import { HelpButtonProps } from './types';

export const HelpButton = ({
  onPress,
  iconColor = colors.primary[400],
  iconSize = '16',
}: HelpButtonProps) => {
  return (
    <Button
      variant="ghost"
      minW="20px"
      maxW="20px"
      maxH="20px"
      onPress={onPress}
    >
      <Icon color={iconColor} size={iconSize} icon="help-circle" />
    </Button>
  );
};
