import { TypeColumn } from '@inovua/reactdatagrid-community/types';
import { Box, Divider, HStack, Stack, Text } from 'native-base';
import DataTable from 'ui/components/DataTable';
import Illustration, { ILLUSTRATIONS_NAME } from 'ui/components/Illustration';

import { AppsAccessCard } from '~/components/AppsAccessCard';
import { Loading } from '~/components/Loading';

import { EcosystemNetworkConnectionsPopulated } from '../../../../../../types/interfaces/ecosystem-network-connections';
import { IOrganization } from '../../../../../../types/interfaces/organization';
import { defaultFiltersValues } from '../../../utils';
import { usePartnerAccessController } from './controller';
import { styles } from './styles';
import { tableColumns } from './tableColumns';

export interface PartnerAccessProps {
  connection: EcosystemNetworkConnectionsPopulated;
  organization: IOrganization;
}

export const PartnerAccess = ({
  organization,
  connection,
}: PartnerAccessProps) => {
  const {
    appsShared,
    uniqueAppsConnectedToOrganization,
    isLoading,
    isLoadingAppPermissionsGroup,
    loadPermissions,
    handleGoToManageAccess,
  } = usePartnerAccessController({ organization, connection });

  if (isLoading) {
    return <Loading containerHeight="60vh" spinnerSize="sm" />;
  }

  return (
    <Stack
      bgColor="white"
      borderRadius="3xl"
      width="100%"
      space={3}
      padding={6}
    >
      <HStack alignItems="center" justifyContent="space-between">
        <Stack space={2}>
          <Text fontSize="lg" fontWeight={500} color="gray.600">
            Access
          </Text>
          <Text fontSize="sm" fontWeight={400} color="gray.600">
            View and manage the apps this member can access
          </Text>
        </Stack>
        {/* <Button
          leftIconName="sliders"
          leftIconColor={colors.primary[400]}
          maxWidth="168px"
          width="100%"
          variant="outline"
          borderColor="primary.400"
          onPress={handleGoToManageAccess}
        >
          Manage access
        </Button> */}
      </HStack>
      <Divider />

      <HStack
        mt={6}
        space={6}
        width="100%"
        flexWrap="wrap"
        style={styles.appsCard}
      >
        {appsShared?.length ? (
          <>
            {appsShared?.map((appShared) => (
              <Box key={appShared._id} width="23%">
                <AppsAccessCard app={appShared.app} />
              </Box>
            ))}
          </>
        ) : (
          <HStack
            alignItems="center"
            space="2"
            justifyContent="center"
            flex={1}
          >
            <Illustration
              width="120px"
              height="120px"
              name={ILLUSTRATIONS_NAME.NO_SIGNAL_CONNECTION_ERROR_BLACK}
            />
            <Text color="gray.500">{"You're not connected to any app."}</Text>
          </HStack>
        )}
      </HStack>

      <HStack mt={10} alignItems="center" justifyContent="space-between">
        <Stack space={2}>
          <Text fontSize="lg" fontWeight={500} color="gray.600">
            Permissions
          </Text>
          <Text fontSize="sm" fontWeight={400} color="gray.600">
            View and manage permissions
          </Text>
        </Stack>
      </HStack>
      <Divider mb={6} />
      <DataTable
        loadData={loadPermissions}
        columns={
          tableColumns({
            connectedApps: uniqueAppsConnectedToOrganization,
          }) as TypeColumn[]
        }
        isLoading={isLoadingAppPermissionsGroup}
        defaultFilterValues={defaultFiltersValues}
      />
    </Stack>
  );
};
