import { api } from '~/services/api';
import { QueryParams } from '~/services/resources/types';
import { UserProfile } from '~/types/interfaces/userProfile';

const MODULE_URL = 'user-profile';

interface UpdateUserProfileExternalIdDTO {
  externalId: string;
}

export default class UserProfileService {
  static async findByOrganizationId(
    organizationId: string,
    params?: QueryParams,
  ) {
    const options = {
      params,
    };

    return await api.get(
      `${MODULE_URL}/organization/${organizationId}`,
      options,
    );
  }

  static async updateUserProfile(
    profileId: string,
    emailPreferences: Partial<UserProfile['emailPreferences']>,
  ) {
    return await api.put(`${MODULE_URL}/${profileId}`, {
      emailPreferences,
    });
  }

  static async updateExternalId(
    profileId: string,
    data: UpdateUserProfileExternalIdDTO,
  ) {
    return await api.put(`${MODULE_URL}/${profileId}/external-id`, data);
  }
}
