import {
  Box,
  Checkbox as NBCheckbox,
  Divider,
  HStack,
  Progress,
  Stack,
  Text,
  VStack,
} from 'native-base';
import { Controller } from 'react-hook-form';
import Button from 'ui/components/Button';
import Input from 'ui/components/Input';
import ReadMore from 'ui/components/ReadMore';

import { AddLocationsModal } from '~/components/AddLocationsModal';
import { ExternalApplyLinkInput } from '~/components/ExternalApplyLinkInput';
import { LocationTag } from '~/components/LocationTag';
import { colors } from '~/theme/colors';

import { MultipleLocationsOngoingFormProps } from './types';
import { useMultipleLocationsOngoingForm } from './useMultipleLocationsOngoingFormController';

export const MultipleLocationsOngoingForm = ({
  addLocationGroup,
  removeLocationGroup,
  locationGroupsFields,
  locationTagsByGroupIndex,
  handleUpdateLocationGroup,
  handleDeleteAllLocationsFromGroup,
  handleDeleteLocationFromGroup,
  control,
  errors,
  updateNoVolunteerLimitValue,
  setValue,
  isEditing = false,
}: MultipleLocationsOngoingFormProps) => {
  const {
    groupByIndexExistsLocation,
    isLoadingLocations,
    loadingProgress,
    maxLoadingProgress,
    loadingPercentage,
    modalRef,
    handleOpenModal,
    closeModal,
    onAddAddresses,
    currentModalAddresses,
  } = useMultipleLocationsOngoingForm({
    handleUpdateLocationGroup,
    locationTagsByGroupIndex,
  });

  return (
    <>
      <Stack w="100%" mt="6" space={8}>
        {locationGroupsFields.map((locationGroup, index) => {
          const noVolunteerLimit = control._getWatch(
            `locationsGroups[${index}].noVolunteerLimit`,
          );
          const externalApplyLink = control._getWatch(
            `locationsGroups[${index}].externalApplyLink`,
          );
          return (
            <Stack
              p={6}
              borderRadius="xl"
              borderWidth={'1'}
              borderColor="gray.200"
              bgColor={colors.singletons.lightText}
              key={index}
            >
              <Stack flex={1}>
                <HStack
                  w="full"
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <Text color="gray.600" fontWeight="500" fontSize="18px">
                    Location {index + 1}
                  </Text>
                  {index > 0 && (
                    <Button
                      onPress={() => removeLocationGroup(index)}
                      variant="link"
                      leftIconName="plus-circle"
                      leftIconColor="red"
                      color="error.600"
                      width="container"
                    >
                      <Text color="error.600">Remove Location</Text>
                    </Button>
                  )}
                </HStack>
                <Divider my="10px" />
                <VStack borderRadius="xl" mt={4} bg="white" p={4}>
                  <HStack
                    borderRadius="xl"
                    justifyContent="space-between"
                    bg="white"
                    alignItems="center"
                  >
                    <Stack>
                      <Text color="gray.600" fontWeight="500" fontSize="md">
                        Address or Postcode
                      </Text>
                    </Stack>

                    <HStack
                      alignItems="center"
                      justifyContent="space-between"
                      space={4}
                    >
                      {groupByIndexExistsLocation(index) ? (
                        <Stack>
                          <Button
                            variant="ghost"
                            leftIconName="x-circle"
                            leftIconColor={colors.error[600]}
                            leftIconSize={16}
                            minH="2.5rem"
                            h={'2.5rem'}
                            borderRadius="2xl"
                            onPress={() =>
                              handleDeleteAllLocationsFromGroup(index)
                            }
                          >
                            <Text
                              color={colors.error[600]}
                              fontSize="xs"
                              fontWeight="medium"
                            >
                              Remove all
                            </Text>
                          </Button>
                        </Stack>
                      ) : null}
                      <Stack>
                        <Button
                          variant="outline"
                          leftIconName={
                            groupByIndexExistsLocation(index)
                              ? 'edit'
                              : 'plus-circle'
                          }
                          leftIconColor={colors.primary[400]}
                          leftIconSize={16}
                          minH="2.5rem"
                          h={'2.5rem'}
                          borderWidth={2}
                          borderColor={colors.primary[200]}
                          borderRadius="2xl"
                          onPress={() => handleOpenModal(index)}
                        >
                          <Text
                            color={colors.primary[400]}
                            fontSize="xs"
                            fontWeight="medium"
                          >
                            {groupByIndexExistsLocation(index)
                              ? 'Edit locations'
                              : 'Add locations'}
                          </Text>
                        </Button>
                      </Stack>
                    </HStack>
                  </HStack>
                  {isLoadingLocations ? (
                    <>
                      <Divider my={6} />
                      <VStack>
                        <Progress
                          value={loadingProgress}
                          size="md"
                          max={maxLoadingProgress}
                          width="full"
                        />
                        <Text fontWeight="medium" mt={2}>
                          {loadingPercentage}% completed
                        </Text>
                      </VStack>
                    </>
                  ) : null}
                </VStack>

                {groupByIndexExistsLocation(index) ? (
                  <ReadMore
                    textHeight="180px"
                    btnBgColor="transparent"
                    variant="ghost"
                    btnTextColor="darkText"
                    rightIconColor="darkText"
                    buttonTextShow="View all locations"
                    buttonTextHide="View less"
                    text={
                      <div id="locationsList">
                        <HStack flex={1} mt={4} space={4} flexWrap="wrap">
                          {locationTagsByGroupIndex &&
                          locationTagsByGroupIndex?.[index]?.length > 0
                            ? locationTagsByGroupIndex[index].map(
                                (locationTag, tagIndex) => (
                                  <LocationTag
                                    key={tagIndex}
                                    text={locationTag.sentInput}
                                    status={locationTag.status}
                                    onRemove={() =>
                                      handleDeleteLocationFromGroup(
                                        index,
                                        tagIndex,
                                      )
                                    }
                                  />
                                ),
                              )
                            : null}
                        </HStack>
                      </div>
                    }
                  />
                ) : null}
              </Stack>
              <Stack my={6}>
                <Input
                  label="Volunteers"
                  isNumeric
                  isRequired={true}
                  placeholder="Type the limit number of volunteers"
                  name={`locationsGroups[${index}].volunteerNumber`}
                  control={control}
                  isDisabled={noVolunteerLimit}
                  errorMessage={
                    locationGroup.noVolunteerLimit
                      ? ''
                      : errors.locationsGroups?.[index]?.volunteerNumber &&
                        (errors.locationsGroups?.[index]?.volunteerNumber
                          ?.message as string)
                  }
                />
                <Box mt={2} ml={1}>
                  <Controller
                    control={control}
                    name={`locationsGroups[${index}].noVolunteerLimit`}
                    defaultValue={noVolunteerLimit}
                    render={({ field: { onChange, value } }) => (
                      <NBCheckbox
                        value={value}
                        onChange={(isSelected: boolean) => {
                          onChange(isSelected);
                          updateNoVolunteerLimitValue(isSelected, index);
                        }}
                        isChecked={value}
                        isDisabled={!!externalApplyLink}
                      >
                        <Text fontWeight={400} fontSize="sm" mx={1} mt={1}>
                          No volunteer limit
                        </Text>
                      </NBCheckbox>
                    )}
                  />
                </Box>
              </Stack>
              <ExternalApplyLinkInput
                control={control}
                isEditing={isEditing}
                name={`locationsGroups[${index}].externalApplyLink`}
                onChange={(value) => {
                  updateNoVolunteerLimitValue(!!value, index);
                  setValue(
                    `locationsGroups[${index}].noVolunteerLimit`,
                    !!value,
                  );
                }}
              />
            </Stack>
          );
        })}
        <Button
          leftIconName="plus-circle"
          onPress={() => addLocationGroup(locationGroupsFields.length)}
          mb={4}
        >
          New Location
        </Button>
      </Stack>
      <AddLocationsModal
        modalRef={modalRef}
        onAddAddresses={onAddAddresses}
        onCloseModal={closeModal}
        existingAddress={currentModalAddresses}
      />
    </>
  );
};
