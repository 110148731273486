import { HStack, Skeleton, VStack } from 'native-base';

export const TotalHoursByEmployeesSkeleton = () => {
  return (
    <VStack px={6} pb={6}>
      <HStack space={4}>
        <Skeleton.Text lines={1} w="150px" />
        <Skeleton h="25px" w="50px" />
      </HStack>
      <HStack
        mt={5}
        mb={2}
        space={2}
        justifyContent={'space-between'}
        alignItems={'center'}
      >
        <VStack>
          <Skeleton.Text lines={1} w="200px" />
          <Skeleton.Text lines={1} w="100px" mt={2} />
        </VStack>
        <VStack>
          <Skeleton.Text lines={1} w="100px" />
          <Skeleton.Text lines={1} w="100px" mt={2} />
        </VStack>
      </HStack>
      <Skeleton h="10px" w="100%" mt={2} />
    </VStack>
  );
};
