import { HStack, Text, VStack } from 'native-base';
import Icon from 'ui/components/Icon';
import { useBaseLogProgressTileController } from 'ui/components/v2/Tiles/LogProgressTile/controller';
import { BaseLogProgressTileProps } from 'ui/components/v2/Tiles/LogProgressTile/types';
import { colors } from 'ui/theme/colors';

export type BasicLogProgressTileProps = BaseLogProgressTileProps;

export const BasicLogProgressTile = (props: BasicLogProgressTileProps) => {
  const { formattedValues, measurementIcon } =
    useBaseLogProgressTileController(props);

  return (
    <VStack
      borderWidth={1}
      bg={'gray.100'}
      w={'full'}
      borderColor={'gray.300'}
      borderRadius={'xl'}
      py={3}
      px={4}
    >
      <HStack w={'full'} alignItems={'center'} space={2}>
        <Icon
          icon={measurementIcon}
          enableAutoCustom
          size={'20px'}
          color={colors.gray['600']}
        />
        <Text textTransform={'uppercase'} color={'gray.600'}>
          Total Log
        </Text>
        <Text fontSize={'lg'} fontWeight={'500'}>
          {formattedValues.amountStr}
        </Text>
      </HStack>
    </VStack>
  );
};
