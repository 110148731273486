import Yup from '~/utils/validations/yup';

const emailFieldValidator = Yup.string()
  .transform((value: string) => value?.trim() || '')
  .email();

export const isValidEmail = (email: string) => {
  try {
    emailFieldValidator.validateSync(email);
    return true;
  } catch (err) {
    return false;
  }
};
