import { FeedNotificationType } from 'ui/types/interfaces/feed-notification';

import { UpdatesFeedCategory } from '~/pages/Authenticated/Dashboard/components/UpdatesFeed/types';

export const UpdatesFeedCategoryToNotificationTypeMap: Record<
  UpdatesFeedCategory,
  { types: FeedNotificationType[] }
> = {
  [UpdatesFeedCategory.AllUpdates]: {
    types: [
      FeedNotificationType.MeasurementCreated,
      FeedNotificationType.MeasurementDeleted,
      FeedNotificationType.MultipleMeasurementsCreated,
      FeedNotificationType.ActivityApplicationCanceled,
      FeedNotificationType.ActivityApplicationCreated,
      FeedNotificationType.ActivityCreated,
      FeedNotificationType.BulkActivitiesCreated,
      FeedNotificationType.UserProfileCreated,
    ],
  },
  [UpdatesFeedCategory.ActivityLogs]: {
    types: [
      FeedNotificationType.MeasurementCreated,
      FeedNotificationType.MeasurementDeleted,
      FeedNotificationType.MultipleMeasurementsCreated,
    ],
  },
  [UpdatesFeedCategory.Applications]: {
    types: [
      FeedNotificationType.ActivityApplicationCanceled,
      FeedNotificationType.ActivityApplicationCreated,
    ],
  },
  [UpdatesFeedCategory.NewActivities]: {
    types: [
      FeedNotificationType.ActivityCreated,
      FeedNotificationType.BulkActivitiesCreated,
    ],
  },
  [UpdatesFeedCategory.NewMembers]: {
    types: [FeedNotificationType.UserProfileCreated],
  },
};
