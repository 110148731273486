import { HStack, Text, VStack } from 'native-base';
import { AppTile } from 'ui/components/v2/Tiles/AppTile';

import { CorpSideCard } from '../BaseCorpSideCard';
import { CorpAppsSideCardProps } from './types';

const placeHolderLabel =
  'You don’t have any apps yet. \nPlease, click to connect to apps.';

export const CorpAppsSideCard = ({
  apps,
  max = 3,
  onAppPress,
  onViewAppsButtonPress,
  onConnectNewAppButtonPress,
}: CorpAppsSideCardProps) => {
  return (
    <CorpSideCard
      title="Apps"
      titleIcon="grid"
      secondaryActionLabel="View all"
      onSecondaryAction={onViewAppsButtonPress}
      primaryActionLabel="Connect to more apps"
      onPrimaryAction={onConnectNewAppButtonPress}
    >
      {apps?.length ? (
        <VStack space={4}>
          <HStack
            flexWrap={'wrap'}
            overflow={'hidden'}
            justifyContent={'space-between'}
          >
            {apps.map((app, i) => {
              if (i < max)
                return (
                  <VStack
                    key={app._id}
                    w={'33.333%'}
                    minW={'108px'}
                    p={2}
                    pb={4}
                  >
                    <AppTile
                      name={app?.name || ''}
                      logoUrl={app?.logo || ''}
                      onPress={() => onAppPress?.(app)}
                    />
                  </VStack>
                );
            })}
          </HStack>
        </VStack>
      ) : (
        <VStack>
          <Text
            textAlign={'center'}
            fontWeight={400}
            color="gray.600"
            fontSize={'sm'}
            margin={'auto'}
            py={6}
          >
            {placeHolderLabel}
          </Text>
        </VStack>
      )}
    </CorpSideCard>
  );
};
