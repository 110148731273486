import FeatherIcon from 'feather-icons-react';
import { Box, HStack, Text, VStack } from 'native-base';
import Button from 'ui/components/Button';

import { colors } from '~/theme/colors';

import { ModalNAButtonProps } from './types';

const ModalNAButton = ({ label, description, action }: ModalNAButtonProps) => {
  return (
    <Box flex={1} minHeight={'128px'} p={3}>
      <Button
        variant="outline"
        onPress={action}
        borderRadius="3xl"
        position={'absolute'}
        left={0}
        top={0}
        width={'full'}
        height={'full'}
        zIndex={2}
      ></Button>

      <HStack zIndex={1} space={2} flexWrap="wrap">
        <Box>
          <FeatherIcon
            icon="plus-circle"
            size={24}
            color={colors.primary[600]}
          />
        </Box>
        <VStack flex={1}>
          <Text
            fontSize="md"
            color="primary.600"
            fontWeight={500}
            ellipsizeMode="tail"
            numberOfLines={2}
          >
            {label}
          </Text>
          <Text
            fontSize="xs"
            mt={1}
            fontWeight={400}
            color="gray.600"
            ellipsizeMode="tail"
            numberOfLines={4}
          >
            {description}
          </Text>
        </VStack>
      </HStack>
    </Box>
  );
};

export default ModalNAButton;
