import { FeaturedActivityDefinition } from 'ui/types/interfaces/featured-activity-definition';

import { useQuery } from '~/hooks/useQuery';

export const useLoadFeaturedActivityDefinitionByEcosystem = (
  ecosystemId?: string,
) => {
  const url = `org-admin/featured-activity-definitions/ecosystems/${ecosystemId}`;

  const { data, isLoading, refetch } = useQuery<FeaturedActivityDefinition>(
    url,
    {
      key: `org-admin-featured-activity-definitions-ecosystems-${ecosystemId}`,
      queryOptions: { enabled: !!ecosystemId },
      requestOptions: {
        params: {
          populate: JSON.stringify([
            {
              model: 'MeasurementUnits',
              path: 'activityDefinitionSubDocument.measurementUnit',
            },
          ]),
        },
      },
    },
  );

  return {
    data,
    isLoading,
    refetch,
  };
};
