import { TypeColWithNameProperty } from '@inovua/reactdatagrid-community/types/TypeColumn';

export type BaseModel = {
  id: number | string;
};

export interface IColumn extends TypeColWithNameProperty {
  name?: string;
  header?: string;
  minWidth?: number;
  alignText?: string;
  defaultFlex?: number;
}

export type SelectedRows<Model extends BaseModel> = {
  [key: string | number]: Model;
};

export enum TableOperatorNames {
  Contains = 'contains',
  NotContains = 'notContains',
  Equals = 'eq',
  NotEquals = 'neq',
  Empty = 'empty',
  NotEmpty = 'notEmpty',
  StartsWith = 'startsWith',
  EndsWith = 'endsWith',
  GreaterThan = 'gt',
  GreaterThanOrEquals = 'gte',
  LowerThan = 'lt',
  LowerThanOrEquals = 'lte',
  After = 'after',
  AfterOrOn = 'afterOrOn',
  Before = 'before',
  BeforeOrOn = 'beforeOrOn',
  InRange = 'inrange',
  NotInRange = 'notinrange',
  Or = 'or',
}

export enum TableOperatorType {
  Date = 'date',
  String = 'string',
  Number = 'number',
  Boolean = 'boolean',
  Select = 'select',
}

export enum TableSizeClass {
  Default = 'default-table',
  Small = 'small-table',
}
