import { TypeColumn } from '@inovua/reactdatagrid-community/types';
import DataTableActions from 'ui/components/DataTableActions';

import { colors } from '~/theme/colors';
import { TableColumnsFunctions } from '~/types';
import { AdminInviteTable } from '~/types/interfaces/table';
import { formatDateLong } from '~/utils/date';

export const tableAdminInvitesColumns = ({
  onResendInvite,
  onDelete,
}: TableColumnsFunctions<string>): TypeColumn[] => [
  {
    defaultFlex: 1,
    name: 'email',
    header: 'Email',
    render: ({ data }: AdminInviteTable) => {
      return data.email || 'Unknown';
    },
  },
  {
    defaultFlex: 1,
    name: 'createdAt',
    header: 'Invitation date',
    render: ({ data }: AdminInviteTable) => {
      return formatDateLong(data.createdAt) || 'Unknown';
    },
  },
  {
    defaultFlex: 1,
    name: 'createdBy',
    header: 'Invited by',
    render: ({ data }: AdminInviteTable) => {
      return data.createdBy.name || 'Unknown';
    },
  },
  {
    name: 'actions',
    header: 'Actions',
    render: ({ data }: AdminInviteTable) => {
      const popoverOptions = [
        {
          title: 'Resend invitation',
          icon: {
            color: 'gray.600',
            icon: 'send',
            size: 22,
          },
          action: () => {
            if (typeof onResendInvite === 'function' && data._id) {
              onResendInvite(data._id);
            }
          },
        },
        {
          title: 'Delete invitation',
          icon: {
            color: colors.error[600],
            icon: 'x-circle',
            size: 22,
          },
          action: () => {
            if (typeof onResendInvite === 'function' && data._id) {
              onDelete(data._id);
            }
          },
        },
      ];
      return (
        <DataTableActions
          popoverActions
          popoverTextColor={colors.primary[400]}
          popoverIconColor={colors.primary[400]}
          popoverOptions={popoverOptions}
        />
      );
    },
  },
];
