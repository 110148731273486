import { Alert, HStack, Stack, Text } from 'native-base';
import Modal from 'ui/components/Modals/Modal';

import { AdminProfilesControllerAttributes } from '~/pages/Authenticated/Settings/AdminsSettings/types';
import { colors } from '~/theme/colors';

export const DeleteAdminModal = ({
  deleteAdminModalRef,
  onDeleteAdmin,
  isDeletingAdmin,
  closeDeleteAdminModal,
}: Pick<
  AdminProfilesControllerAttributes,
  | 'selectedAdmin'
  | 'deleteAdminModalRef'
  | 'onDeleteAdmin'
  | 'isDeletingAdmin'
  | 'closeDeleteAdminModal'
>) => (
  <Modal
    ref={deleteAdminModalRef}
    rightBtnText="Delete Administrator"
    rightBtnAction={onDeleteAdmin}
    rightBtnLoading={isDeletingAdmin}
    rightBtnBgColor={colors.error[600]}
    leftBtnText="Cancel"
    leftBtnAction={closeDeleteAdminModal}
    onClose={closeDeleteAdminModal}
    size="lg"
    headerText="Delete administrator"
  >
    <Stack space="5" mt={2}>
      <Alert status="warning">
        <HStack space={2}>
          <Alert.Icon />
          <Text>
            If you are the only administrator of this organisation, you have to
            invite someone else before being able to remove yourself.
          </Text>
        </HStack>
      </Alert>
      <Text>
        Are you sure that you want to delete this administrator? They will no
        longer have access to your organisation and all resources associated
        with it.
      </Text>
    </Stack>
  </Modal>
);
