import { Divider, Hidden, HStack } from 'native-base';
import { colors } from 'ui/theme/colors';

import { useTrendingTabController } from './controller';
import { TrendingDayElement } from './TrendingDayElement';
import { TrendingElement } from './TrendingElement';

export const TrendingTab = () => {
  const {
    isLoading,
    trendingCauseSummary,
    trendingActivityTypeSummary,
    trendingDayToVolunteerSummary,
  } = useTrendingTabController();

  return (
    <HStack
      bg="white"
      p={4}
      borderRadius={16}
      borderColor="gray.200"
      borderWidth={1}
      justifyContent={['center', 'center', 'flex-start']}
      direction={['column', 'column', 'row']}
      space={[2, 2, 0]}
      alignItems={'flex-start'}
      flexWrap={'wrap'}
      mb={6}
    >
      <TrendingElement
        icon="heart"
        trendingType="Cause"
        iconBgColor={'primary.50'}
        iconColor={colors.primary[400]}
        label={trendingCauseSummary.label}
        isLoading={isLoading}
      />

      <Hidden till="md">
        <Divider width={'1px'} height={'full'} mx={6} bg={'gray.300'} />
      </Hidden>

      <TrendingElement
        icon={trendingActivityTypeSummary.icon}
        label={trendingActivityTypeSummary.label}
        iconColor={trendingActivityTypeSummary.iconColor}
        iconBgColor={trendingActivityTypeSummary.bgColor}
        trendingType="Type of activity"
        isLoading={isLoading}
      />

      <Hidden till="md">
        <Divider width={'1px'} height={'full'} mx={6} bg={'gray.300'} />
      </Hidden>

      <TrendingDayElement
        day={trendingDayToVolunteerSummary.label}
        isLoading={isLoading}
      />
    </HStack>
  );
};
