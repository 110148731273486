import './styles.css';

import { HStack, VStack } from 'native-base';
import Cropper from 'react-easy-crop';
import Button from 'ui/components/Button';
import { colors } from 'ui/theme/colors';

import { ImageCropperZoomSlider } from './components/ZoomSlider';
import { ImageCropperController } from './controller';
import { ImageCropperProps } from './types';

export const ImageCropper = (props: ImageCropperProps) => {
  const {
    aspectRatio = 16 / 9,
    circularCrop = false,
    maxSale = 3,
    minScale = 1,
    submitLabel = 'Crop and save',
  } = props;

  const {
    zoom,
    crop,
    imageSrc,
    // TODO: Implement crop preview
    croppedImage,
    handleSubmit,
    handleHorizontalFlip,
    onCropComplete,
    handleCropChange,
    handleZoomChange,
  } = ImageCropperController(props);

  return (
    <VStack w={{ base: 'full', md: '500px' }} alignItems={'center'}>
      <div className="crop-area">
        <Cropper
          image={imageSrc}
          crop={crop}
          zoom={zoom}
          cropShape={circularCrop ? 'round' : 'rect'}
          aspect={aspectRatio}
          onCropChange={handleCropChange}
          onCropComplete={onCropComplete}
          onZoomChange={handleZoomChange}
        />
      </div>

      <HStack
        space={2}
        w="full"
        justifyContent={'space-between'}
        alignItems={'center'}
        p={1}
        px={0}
        py={1}
        mt={3}
      >
        <ImageCropperZoomSlider
          value={zoom}
          min={minScale}
          max={maxSale}
          onChange={handleZoomChange}
        />
        <HStack>
          <Button
            leftIconName="shuffle"
            leftIconColor={colors.primary[400]}
            isDisabled={false}
            variant={'outline'}
            onPress={handleHorizontalFlip}
          >
            Flip
          </Button>
        </HStack>
        <HStack>
          <Button
            leftIconName={'crop'}
            isDisabled={false}
            onPress={handleSubmit}
          >
            {submitLabel}
          </Button>
        </HStack>
      </HStack>
    </VStack>
  );
};
