import { useQuery } from '../useQuery';

export const useLoadImportProgress = (id?: string, refetchInterval = 5000) => {
  const { data } = useQuery<number>(`import-file/${id}/progress`, {
    queryOptions: {
      enabled: !!id,
      refetchInterval,
    },
  });
  return { data };
};
