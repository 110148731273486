import { useQuery } from '~/hooks/useQuery';

export const useLoadAppPermissionGroupCountByOrganization = (
  organizationId: string,
) => {
  const { data, isLoading, refetch } = useQuery<number>(
    `/app-permission-group/organization/${organizationId}/count-draft`,
    {
      queryOptions: {
        enabled: !!organizationId,
      },
      key: `app-permission-group-${organizationId}-count`,
    },
  );

  return {
    data,
    isLoading,
    refetch,
  };
};
