import { Divider, HStack, Popover, Pressable, Text } from 'native-base';
import { useState } from 'react';
import Icon from 'ui/components/Icon';
import { colors } from 'ui/theme/colors';

import {
  AccountPopOverOptionsProps,
  IPopoverOption,
} from '~/components/MenuDropdown/types';

export const MenuPopOverOptions = (props: AccountPopOverOptionsProps) => {
  const { children, onPress, optionGroups } = props;

  const [showPopOver, setShowPopOver] = useState<boolean>(false);

  const handleShowPopOver = (show: boolean) => {
    setShowPopOver(show);
  };

  const handleOptionPress = (option: IPopoverOption) => {
    onPress(option);
    setShowPopOver(false);
  };

  return (
    <Popover
      isOpen={showPopOver}
      trigger={(triggerOptions) => (
        <Pressable
          {...triggerOptions}
          variant="link"
          fontSize="14px"
          fontWeight={500}
          w="fit-content"
          onPress={() => handleShowPopOver(true)}
        >
          {children}
        </Pressable>
      )}
      onClose={() => handleShowPopOver(false)}
    >
      <Popover.Content
        top={2}
        w={{ base: 'full', md: 340 }}
        left={{ base: '-16px', md: '-48px' }}
        borderRadius={10}
      >
        <Popover.Body p={0} py={2} width={'full'}>
          {optionGroups.map((options: IPopoverOption[], index: number) => {
            return (
              <>
                {index > 0 && <Divider my={2} />}
                {options.map((option: IPopoverOption) => (
                  <Pressable
                    key={option.label}
                    onPress={() => handleOptionPress(option)}
                  >
                    <HStack space={3} p={3} minW={340}>
                      <Icon
                        size={'24px'}
                        icon={option.icon}
                        color={option.iconColor ?? colors.gray[600]}
                        enableAutoCustom
                      />
                      <Text fontSize={'md'} color={colors.gray[600]}>
                        {option.label}
                      </Text>
                    </HStack>
                  </Pressable>
                ))}
              </>
            );
          })}
        </Popover.Body>
      </Popover.Content>
    </Popover>
  );
};
