import { Avatar, HStack, Text } from 'native-base';
import { getAvatarText } from 'ui/utils/getAvatarText';

import { CustomRadioItem } from '../../../../Radio/RadioItem';
import { RadioSelectAppItemProps } from './types';

export const RadioSelectAppItem = (props: RadioSelectAppItemProps) => {
  const { name, value, logo, isDisabled, isChecked, onChange } = props;

  return (
    <HStack alignItems="center" w={'50%'} my={3}>
      <CustomRadioItem
        label=""
        onValueChange={onChange}
        value={value}
        name={name}
        isChecked={isChecked}
        isDisabled={isDisabled}
        customLabelRender={
          <HStack
            w="100%"
            maxWidth={'fit-content'}
            space={2}
            alignItems="center"
            flex={1}
          >
            <Avatar
              bgColor="muted.300"
              height="32px"
              width="32px"
              borderColor={'gray.300'}
              borderRadius={'md'}
              _text={{ color: 'darkText' }}
              source={{ uri: logo }}
              _image={{
                borderRadius: 'md',
              }}
            >
              {getAvatarText(name)}
            </Avatar>
            <Text
              numberOfLines={1}
              color="darkText"
              fontWeight={500}
              fontSize={'xs'}
            >
              {name}
            </Text>
          </HStack>
        }
      />
    </HStack>
  );
};
