import { Flex, Hidden, HStack, Stack } from 'native-base';

import { SwitchEcosystemCard } from '~/pages/Authenticated/SwitchEcosystem/components/SwitchEcosystemCard';
import { SwitchEcosystemListProps } from '~/pages/Authenticated/SwitchEcosystem/components/SwitchEcosystemList/types';
import { SwitchEcosystemShimmer } from '~/pages/Authenticated/SwitchEcosystem/components/SwitchEcosystemShimmer';

export const SwitchEcosystemList = ({
  ecosystems,
  isSearching,
  selectedEcosystem,
  currentOrganization,
  handleChangeEcosystem,
}: SwitchEcosystemListProps) => {
  return (
    <Flex
      width="100%"
      alignItems="center"
      justifyContent={{ base: 'space-between', sm: 'flex-start' }}
      mt={4}
      flexWrap="wrap"
      flexDir="row"
    >
      {isSearching ? (
        <HStack width="100%" space={{ base: 2, lg: 6 }} mt={6}>
          <SwitchEcosystemShimmer />
          <Hidden till="lg">
            <SwitchEcosystemShimmer />
          </Hidden>
        </HStack>
      ) : (
        <Stack direction="row" flexWrap="wrap" width="100%">
          {ecosystems?.map((ecosystem) => (
            <Stack ml={{ sm: 6 }} key={ecosystem._id} mt={6}>
              <SwitchEcosystemCard
                ecosystemName={ecosystem.name}
                ecosystemLogo={ecosystem.logo}
                isSelectedEcosystem={ecosystem._id === selectedEcosystem?._id}
                userName={ecosystem.name || ''}
                organizationName={currentOrganization?.name || ''}
                userImage={ecosystem.logo || ''}
                onPress={() => handleChangeEcosystem(ecosystem._id)}
              />
            </Stack>
          ))}
        </Stack>
      )}
    </Flex>
  );
};
