import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { App } from 'ui/types/interfaces/app';
import { IPopulatedNetworkAppSharing } from 'ui/types/interfaces/network-app-sharing';

import { EcosystemState } from '~/store/slices/ecosystem/types';
import { ESliceNames } from '~/store/types';
import { IEcosystem } from '~/types/interfaces/ecosystem';

const initialState: EcosystemState = {
  selectedEcosystem: undefined,
  ecosystemApps: {},
  networkAppSharings: [],
};

export const ecosystemSlice = createSlice({
  name: ESliceNames.ECOSYSTEM,
  initialState,
  reducers: {
    selectEcosystem: (state, { payload }: PayloadAction<IEcosystem>) => {
      return {
        ...state,
        selectedEcosystem: payload,
      };
    },
    resetEcosystem: () => {
      return initialState;
    },
    setEcosystemApps: (
      state,
      { payload }: PayloadAction<Record<string, App>>,
    ) => {
      return {
        ...state,
        ecosystemApps: payload,
      };
    },
    setNetworkAppSharings: (
      state,
      { payload }: PayloadAction<IPopulatedNetworkAppSharing[]>,
    ) => {
      return { ...state, networkAppSharings: payload };
    },
  },
});

export const {
  reducer: ecosystemSliceReducer,
  actions: ecosystemSliceActions,
} = ecosystemSlice;
