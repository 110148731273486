import { Avatar, HStack, Stack, Text } from 'native-base';
import { PopOverOptions } from 'ui/components/PopOverOptions';
import Tabs from 'ui/components/Tabs';

import PageHeader from '~/components/Layout/PageHeader';
import { Loading } from '~/components/Loading';
import ModalDelete from '~/components/ModalDelete';
import Scaffold from '~/components/Scaffold';
import { colors } from '~/theme/colors';

import { useMemberController } from './controller';
import { MemberAccess } from './MemberAccess';
import { MemberActivities } from './MemberActivities';
import { MemberDetails } from './MemberDetails';
import { tabsElements } from './tabElements';

export const ViewMember = () => {
  const {
    organization,
    member,
    popOverOptions,
    tabIndex,
    modalRef,
    isRemoveMemberLoading,
    isLoadingMember,
    isLoadingOrganization,
    handleCloseModal,
    handleRemoveMember,
    handleChangeTab,
  } = useMemberController();

  if (isLoadingMember || isLoadingOrganization) {
    return <Loading containerHeight="100vh" spinnerSize="sm" />;
  }

  if (!member) return null;

  return (
    <Scaffold
      title={
        <HStack width="100%" alignItems="center" justifyContent="space-between">
          <HStack space={4} alignItems="center">
            <Avatar
              source={{
                uri: member?.userSummary?.profileImage,
              }}
              height="40px"
              width="40px"
            />
            <PageHeader title={member?.userSummary.name || ''} />
          </HStack>
          <PopOverOptions
            popovText="Options"
            popovTextPosition="right"
            popovIcon="more-vertical"
            options={popOverOptions}
          />
        </HStack>
      }
      withBg={false}
    >
      <Stack width="100%">
        <Tabs
          tabWith="100%"
          tabBgColor="gray.200"
          tabIndicatorColor={colors.primary['400']}
          elements={tabsElements}
          onChangeTab={handleChangeTab}
          isClickableTab={true}
        />
        <Stack width="100%" mt={10}>
          {tabIndex === 0 ? <MemberActivities member={member} /> : null}
          {tabIndex === 1 ? (
            <MemberDetails member={member} organization={organization} />
          ) : null}
          {tabIndex === 2 ? <MemberAccess member={member} /> : null}
        </Stack>
      </Stack>

      <ModalDelete
        modalRef={modalRef}
        headerText="Remove member"
        handleCloseModal={handleCloseModal}
        handleDelete={handleRemoveMember}
        rightBtnLoading={isRemoveMemberLoading}
        rightBtnText="Remove"
      >
        <Stack space={5}>
          <Text fontWeight={400} fontSize="md" color="gray.500">
            Are you sure that you want to remove this member?
          </Text>

          <Text fontWeight={400} fontSize="md" color="gray.500">
            The user will no longer have access to the ecosystem and will lose
            all permissions associated with it.
          </Text>
        </Stack>
      </ModalDelete>
    </Scaffold>
  );
};
