import { EEventApplicationType } from 'ui/enums';
import { ActivityType } from 'ui/types/activities';
import { MeasurementStep } from 'ui/types/measurements';
import { MeasurementUnit } from 'ui/types/measurementUnit';

export enum FeedNotificationType {
  ActivityApplicationCreated = 'ActivityApplicationCreated',
  ActivityApplicationCanceled = 'ActivityApplicationCanceled',
  ActivityCreated = 'ActivityCreated',
  BulkActivitiesCreated = 'BulkActivitiesCreated',
  MeasurementCreated = 'MeasurementCreated',
  MeasurementDeleted = 'MeasurementDeleted',
  MultipleMeasurementsCreated = 'MultipleMeasurementsCreated',
  UserProfileCreated = 'UserProfileCreated',
}

export enum FeedNotificationVisibility {
  Organization = 'Organization',
  Ecosystem = 'Ecosystem',
  App = 'App',
}

export enum FeedNotificationAuthorType {
  Organization = 'Organization',
  UserProfile = 'UserProfile',
}

export enum FeedNotificationTargetType {
  Nfp = 'Nfp',
  Company = 'Company',
  All = 'All',
}

export interface FeedNotification<T> {
  _id: string;
  notificationDate: Date;
  type: FeedNotificationType;
  /* Author */
  notificationAuthor: FeedNotificationAuthorType;
  organizationId: string;
  ecosystemId: string;
  userProfileId?: string | null;
  appId?: string | null;
  /* Content */
  payload: T;
  /* audience */
  notificationTarget: FeedNotificationTargetType;
  visibility: FeedNotificationVisibility;
}

// -- Aggregates

export interface FeedNotificationPayload {
  authorName: string;
  authorAvatarUrl: string;
  messageTemplate: string;
}

// User Profile Created Payload
export interface FeedNotificationUserProfileCreatedPayload
  extends FeedNotificationPayload {
  ecosystemName: string;
}

// Activity Created Payload
export interface FeedNotificationActivityCreatedPayload
  extends FeedNotificationPayload {
  activitySummary: FeedNotificationActivitySummary;
}

// Bulk Activities Created Payload
export interface FeedNotificationBulkActivitiesCreatedPayload
  extends FeedNotificationPayload {
  activitiesCount: number;
  appName: string;
}

// Activity Application Created Payload
export interface FeedNotificationActivityApplicationCreatedPayload
  extends FeedNotificationPayload {
  activitySummary: FeedNotificationActivitySummary;
  activityApplicationId: string;
}

// Activity Application Canceled Payload
export interface FeedNotificationActivityApplicationCanceledPayload
  extends FeedNotificationPayload {
  activitySummary: FeedNotificationActivitySummary;
  activityApplicationId: string;
}

// Measurement Created Payload
export interface FeedNotificationMeasurementCreatedPayload
  extends FeedNotificationPayload {
  isExternal: boolean;
  activityType: ActivityType;
  activityTitle: string;
  activityOrganizationName: string;
  measurementSummary: FeedNotificationMeasurementSummary;
  activityApplicationId?: string;
  activitySummary?: FeedNotificationActivitySummary;
}

// Measurement Delete Payload
export type FeedNotificationMeasurementDeletedPayload =
  FeedNotificationMeasurementCreatedPayload;

// Multiples Measurements Created Payload
export interface FeedNotificationMultiplesMeasurementCreatedPayload
  extends Omit<
    FeedNotificationMeasurementCreatedPayload,
    'measurementSummary'
  > {
  measurementDefinitionId: string;
  measurementSummaries: FeedNotificationMeasurementSummary[];
}

// Summaries
export interface FeedNotificationActivitySummary {
  // Activity Definition Info
  activityDefinitionId: string;
  eventApplicationType: EEventApplicationType;
  type: ActivityType;
  title: string;
  appId: string;
  appName: string;
  publishedApps: string[];
  organizationId: string;
  organizationName: string;
  coverImageUrl: string;
  thumbnailImageUrl?: string;
  targetAmount?: number;
  measurementUnit?: MeasurementUnit;
  activityId: string;
  startDate?: Date;
}

export interface FeedNotificationMeasurementSummary {
  amount: number;
  measurementId: string;
  targetAmount?: number;
  measurementUnit: MeasurementUnit;
  steps?: MeasurementStep[];
}
