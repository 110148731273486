import Yup from '~/utils/validations/yup';

export const schemaValidation = Yup.object().shape({
  name: Yup.string().required(),
  causeOptions: Yup.array().required(),
  purpose: Yup.string().required(),
  contactPhoneNumber: Yup.string().required(),
  contactEmail: Yup.string().required(),
  fullAddress: Yup.object().required(),
  description: Yup.string(),
  websiteLink: Yup.string(),
  termsOfServicesLink: Yup.string(),
  logo: Yup.mixed(),
  hasExternalId: Yup.boolean(),
  measurementGoal: Yup.number().required(),
});
