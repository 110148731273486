function getMdc(a: number, b: number): any {
  return b === 0 ? a : getMdc(b, a % b); // MDC
}

export function getAspectRatioLabel(ratio: number) {
  // Define an acceptable tolerance for floating point comparison
  const tolerance = 0.01;

  // Start with initial values for width and height based on the ratio
  let width = Math.round(ratio * 1000); // Scale up for precision
  let height = 1000;

  const divisor = getMdc(width, height);
  width /= divisor;
  height /= divisor;

  // Handle special cases like 1:1 ratio
  if (Math.abs(ratio - 1) < tolerance) {
    return '1:1';
  }

  return `${width}:${height}`;
}
