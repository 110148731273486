import { AxiosResponse } from 'axios';
import {
  Organization,
  OrganizationSettings,
} from 'ui/types/interfaces/organization';

import { CreateOrganizationError } from '~/errors';
import { api } from '~/services/api';
import { QueryParams } from '~/services/resources/types';
import { IOrganizationCreatePayloadDTO } from '~/types/dtos';
import { IEcosystem } from '~/types/interfaces/ecosystem';
import { IOrganization } from '~/types/interfaces/organization';

const MODULE_URL = '/organization';

interface JoinAsAdminPayload {
  organizationId: string;
  code: string;
}

export class OrganizationService {
  static async createOrganization(
    data: Partial<IOrganizationCreatePayloadDTO>,
  ) {
    try {
      const {
        data: { newOrganization, newEcosystem },
      } = await api.post<{
        newOrganization: IOrganization;
        newEcosystem: IEcosystem;
      }>(MODULE_URL, data);

      return {
        newOrganization,
        newEcosystem,
      };
    } catch (err: unknown) {
      throw new CreateOrganizationError((err as Error).message);
    }
  }

  static async findAll(params?: QueryParams) {
    const options = {
      params,
    };

    return await api.get(MODULE_URL, options);
  }

  static async findOne(id: string): Promise<AxiosResponse<IOrganization>> {
    return await api.get<IOrganization>(`${MODULE_URL}/${id}`);
  }

  static async joinAsAdmin(data: JoinAsAdminPayload) {
    return await api.post(`${MODULE_URL}/join-as-admin`, data);
  }

  static async updateOne(
    id: string,
    data: Partial<Organization>,
  ): Promise<AxiosResponse<Organization>> {
    return await api.put<Organization>(`${MODULE_URL}/${id}`, data);
  }

  static async updateSettings(
    id: string,
    data: Partial<OrganizationSettings>,
  ): Promise<AxiosResponse<Organization>> {
    return await api.put<Organization>(`${MODULE_URL}/${id}/settings`, data);
  }

  static async updateDomainSlug(
    id: string,
    data: Pick<IOrganization, 'domainSlug'>,
  ): Promise<AxiosResponse<IOrganization>> {
    return await api.patch<IOrganization>(
      `${MODULE_URL}/${id}/domain-slug`,
      data,
    );
  }

  static async deleteAdmin(orgId: string, adminUserId: string) {
    return await api.delete(`${MODULE_URL}/${orgId}/admin/${adminUserId}`);
  }

  static async findAllEcosystemsByOrganization(
    organizationId: string,
  ): Promise<AxiosResponse<{ data: IEcosystem[]; count: number }>> {
    return await api.get<{ data: IEcosystem[]; count: number }>(
      `${MODULE_URL}/${organizationId}/ecosystems`,
    );
  }

  static async createApiKey(orgId: string) {
    return await api.post(`${MODULE_URL}/api-key/${orgId}/create`);
  }
}
