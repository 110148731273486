import { HStack, Text } from 'native-base';
import Icon from 'ui/components/Icon';
import { colors } from 'ui/theme/colors';

import { NotificationCardBadgeProps } from './types';

export const NotificationCardBadge = ({
  icon,
  text,
  borderColor = 'primary.400',
  iconColor = colors.primary[400],
  fontColor = 'primary.400',
}: NotificationCardBadgeProps) => {
  return (
    <HStack
      px={2}
      py={1}
      borderWidth={1}
      borderRadius={16}
      borderColor={borderColor}
      alignItems={'center'}
      w={'fit-content'}
      space={2}
    >
      <Icon enableAutoCustom icon={icon} size={'16px'} color={iconColor} />

      <Text
        color={fontColor}
        numberOfLines={1}
        fontSize={'12px'}
        fontWeight={500}
      >
        {text}
      </Text>
    </HStack>
  );
};
