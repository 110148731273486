import { MeasurementCategory } from 'ui/enums';
import { MeasurementUnit } from 'ui/types/measurementUnit';

export const defaultMeasurementUnits: Record<
  'hours' | string,
  MeasurementUnit
> = {
  hours: {
    _id: '',
    category: MeasurementCategory.Time,
    singularLabel: 'Hour',
    pluralLabel: 'Hours',
    steps: [],
  },
};
