import { useState } from 'react';

import { IMultipleFormProps } from './types';

export const useMultipleFormController = ({
  datesFields,
}: Partial<IMultipleFormProps>) => {
  function getTotalDates(formIndex: number) {
    let totalDates = 0;
    datesFields?.forEach((dateField) => {
      if (Number(dateField.placeFormIndex) === formIndex) {
        totalDates++;
      }
    });

    return totalDates;
  }

  return { getTotalDates };
};
