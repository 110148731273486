import { HStack, Progress, Stack, Text, VStack } from 'native-base';
import Button from 'ui/components/Button';
import Illustration, { ILLUSTRATIONS_NAME } from 'ui/components/Illustration';
import Input from 'ui/components/Input';
import { colors } from 'ui/theme/colors';

import { HelpCentreRedirectLink } from '~/components/HelpCentre';

import { useVerifyCodeController } from './controller';

export const VerifyCodePage = () => {
  const {
    control,
    email,
    errors,
    hadleResendCode,
    isSubmitting,
    onVerifyCode,
    isDisabled,
  } = useVerifyCodeController();
  return (
    <HStack
      minH="90vh"
      height="100%"
      width="100%"
      justifyContent="center"
      alignItems="center"
      space={8}
    >
      <Illustration
        width="496px"
        height="496px"
        name={ILLUSTRATIONS_NAME.NEW_EMAIL_MESSAGE}
        alt="PasswordRecovery"
      />

      <VStack maxWidth="450px" width="100%" space={4}>
        <HelpCentreRedirectLink />
        <Progress width="151px" value={30} />
        <Stack>
          <Text
            textAlign="initial"
            fontSize="xl"
            fontWeight={500}
            color="gray.900"
          >
            Enter the code we sent you. <br />
            <Text fontSize="sm" fontWeight={500} color="gray.900">
              We sent a code to{' '}
              <Text fontWeight={500} color="primary.500">
                {email}
              </Text>
            </Text>
          </Text>
        </Stack>

        <Stack>
          <Input
            name="accessCode"
            control={control}
            placeholder="Type the code we sent to you"
            rightIconName="key"
            rightIconColor={colors.muted[400]}
            label="Recovery code"
            errorMessage={errors.accessCode?.message}
            iconErrorMessage="info"
            p={4}
            isFullWidth
            backgroundColor="white"
            borderColor="muted.200"
          />
        </Stack>
        <VStack space={4} mt={4}>
          <Button
            onPress={onVerifyCode}
            color="primary.600"
            p={6}
            isDisabled={isDisabled}
            variant="outline"
          >
            <Text fontSize="medium" color="primary.600" fontWeight="medium">
              Continue
            </Text>
          </Button>
          <Button
            onPress={hadleResendCode}
            p={6}
            variant="ghost"
            isDisabled={isSubmitting}
            isLoading={isSubmitting}
          >
            <Text fontSize="medium" fontWeight="medium">
              Resend
            </Text>
          </Button>
        </VStack>
      </VStack>
    </HStack>
  );
};
