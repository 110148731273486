import { Alert, HStack, Stack, Text } from 'native-base';
import Button from 'ui/components/Button';
import DataTable from 'ui/components/DataTable';
import { HelpButton } from 'ui/components/HelpButton';
import Icon from 'ui/components/Icon';
import { ModalInformation } from 'ui/components/ModalInformation';
import Modal from 'ui/components/Modals/Modal';
import Tabs from 'ui/components/Tabs';

import { tableAppPermissionsColumns } from '~/pages/Authenticated/Settings/Permissions/tableAppPermissionsColumns';
import { colors } from '~/theme/colors';

import { usePermissionsController } from './controller';
import { tabsElements } from './tabElements';
import { defaultFiltersValues } from './utils';

export const Permissions = () => {
  const {
    publishDraftModalRef,
    modalRef,
    currentTab,
    loadDraftPermissions,
    loadPermissionsPublished,
    totalDraftPermissionsGroup,
    isLoadingDraftPermissionsGroup,
    isLoading,
    openModalInformation,
    appsConnectedToOrganization,
    handleGoToNewPermission,
    onAssignPress,
    onEditPress,
    onDeletePress,
    handleChangeTab,
    isPublishingPermissionGroup,
    handlePublishDraftGroup,
    onPublishDraft,
    closePublishDraftModal,
    deleteModalRef,
    closeDeleteModal,
    handleDeleteGroup,
    isDeletingPermissionGroup,
  } = usePermissionsController();
  return (
    <Stack space={5} mt={4} width="100%">
      <HStack
        justifyContent="space-between"
        width="100%"
        mb={!totalDraftPermissionsGroup ? 8 : 0}
      >
        <Stack space="2">
          <HStack space={2} alignItems="center">
            <Text fontSize="xl" color="gray.600" fontWeight={500}>
              Permission groups
            </Text>
            <HelpButton
              iconColor={colors.primary[400]}
              onPress={openModalInformation}
            />
          </HStack>
          <Text fontWeight={400} fontSize="sm" color="gray.600" mb="8">
            View and manage all the permissions in your organisation
          </Text>
        </Stack>
        <Stack>
          <Button
            variant="outline"
            leftIconColor={colors.primary[400]}
            leftIconSize="16px"
            leftIconName="plus-circle"
            padding={3}
            onPress={handleGoToNewPermission}
          >
            New permission group
          </Button>
        </Stack>
      </HStack>

      {totalDraftPermissionsGroup ? (
        <Alert
          padding={3}
          backgroundColor="warning.100"
          status="warning"
          borderRadius="md"
          mb={8}
        >
          <HStack width="100%" space={2}>
            <Icon icon="edit-3" color={colors.warning[500]} size={20} />
            <Text color="gray.800" fontSize="md" fontWeight={500}>
              Drafts
            </Text>
          </HStack>
          <Stack width="100%" mt={6}>
            <Text>
              You have{' '}
              <Text fontWeight={500} color={colors.singletons.darkText}>
                {totalDraftPermissionsGroup} permission groups
              </Text>{' '}
              on draft. To resolve, you need to publish them by clicking on{' '}
              <Text fontWeight={500} color={colors.singletons.darkText}>
                Options &gt; Publish.
              </Text>
            </Text>
          </Stack>
        </Alert>
      ) : null}

      <Tabs
        tabWith="100%"
        currentTab={currentTab}
        tabIndicatorColor={colors.primary[600]}
        onChangeTab={handleChangeTab}
        tabfocusedTextColor={colors.primary[600]}
        elements={tabsElements}
        isClickableTab={true}
      />

      <Stack width="100%" mt={8}>
        {currentTab === 0 ? (
          <DataTable
            loadData={loadPermissionsPublished}
            columns={tableAppPermissionsColumns({
              onAssignPress,
              onEditPress,
              connectedApps: appsConnectedToOrganization,
              onDeletePress,
            })}
            isLoading={isLoading}
            defaultFilterValues={defaultFiltersValues}
          />
        ) : null}

        {currentTab === 1 ? (
          <DataTable
            loadData={loadDraftPermissions}
            columns={tableAppPermissionsColumns({
              onAssignPress,
              onEditPress,
              connectedApps: appsConnectedToOrganization,
              onPublishDraft,
            })}
            isLoading={isLoadingDraftPermissionsGroup}
            defaultFilterValues={defaultFiltersValues}
          />
        ) : null}
      </Stack>

      <ModalInformation headerText="Permission group" modalRef={modalRef}>
        <Text fontSize="md" color="gray.600">
          A permission group is a group of permissions which you can grant to
          users to give them the authority to perform tasks. It’s a tool that
          helps you manage access and control within our platform, organising
          users based on their roles and responsibilities. These groups are
          organised per app. Since each app has its own unique features and
          specific permissions related to it, permission groups allow you to
          tailor access to these features precisely. Assigning individuals to
          specific permission groups within each app empowers you to clearly
          define who can do what, be it granting access to specific features,
          restricting actions, or customising permissions. Think of them as the
          building blocks for creating a secure and efficient environment for
          everyone involved, while ensuring that each user only has access to
          the resources and functionalities relevant to their role and the
          specific application they&apos;re using.
        </Text>
      </ModalInformation>

      <Modal
        ref={deleteModalRef}
        headerText="Delete group"
        rightBtnLoading={isDeletingPermissionGroup}
        rightBtnAction={handleDeleteGroup}
        modalContentMaxW="600"
        rightBtnText="Delete group"
        leftBtnAction={closeDeleteModal}
        leftBtnText="Cancel"
      >
        <Stack space={5}>
          <Text fontWeight={400} fontSize="md" color="gray.500">
            Are you sure that you want to delete this group? If this group were
            shared with other ecosystems, it will be deleted from them too.
          </Text>
        </Stack>
      </Modal>

      <Modal
        ref={publishDraftModalRef}
        headerText="Publish group"
        rightBtnLoading={isPublishingPermissionGroup}
        rightBtnAction={handlePublishDraftGroup}
        modalContentMaxW="600"
        rightBtnText="Publish group"
        leftBtnAction={closePublishDraftModal}
        leftBtnText="Cancel"
      >
        <Stack space={5}>
          <Text fontWeight={400} fontSize="md" color="gray.500">
            Are you sure that you want to publish this group?
          </Text>
        </Stack>
      </Modal>
    </Stack>
  );
};
