import { useQuery } from '~/hooks/useQuery';

export const useLoadEcosystemLogHoursTotalReport = (ecosystemId: string) => {
  const { data, isLoading, isRefetching } = useQuery<{ total: number }>(
    `measurement-insights/ecosystem/${ecosystemId}/total-time-logged`,
    {
      key: `ecosystem-activities-report/${ecosystemId}/total-time-logged`,
    },
  );

  return { data, isLoading, isRefetching };
};
