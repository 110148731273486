import { useEffect, useState } from 'react';
import { useController } from 'react-hook-form';

import { colors } from '../../theme/colors';
import { PickColorInputProps } from './types';

export const usePickColorInputController = ({
  control,
  inputProps: { name },
}: PickColorInputProps) => {
  const [isColorPickerOpen, setIsColorPickerOpen] = useState(false);
  const defaultValue = colors.gray[900];

  const toggleColorPicker = (isColorPickerOpen: boolean) => {
    setIsColorPickerOpen(isColorPickerOpen);
  };

  const { field } = useController({
    control,
    name,
  });

  const onInputChange = (value: string) => {
    const formattedValue = value.startsWith('#') ? value : `#${value}`;
    field.onChange(formattedValue);
  };

  useEffect(() => {
    if (!field.value) {
      field.onChange(defaultValue);
    }
  }, [field, defaultValue]);

  const onBrandColorChange = (color: string) => {
    field.onChange(color);
  };

  return {
    defaultValue,
    isColorPickerOpen,
    field,
    onInputChange,
    toggleColorPicker,
    onBrandColorChange,
  };
};
