import { Box, Hidden, HStack, Link, Stack, Text } from 'native-base';
import Button from 'ui/components/Button';
import Icon from 'ui/components/Icon';
import { ORG_HELP_CENTER_URL } from 'ui/constants/commonUrl.constants';

import { HelpCentreRedirectLink } from '~/components/HelpCentre';
import { CreateAccount } from '~/pages/Unauthenticated/SignUpPage/components/CreateAccount';
import { colors } from '~/theme/colors';

import { CreateAccountPageProps } from '../types';

export const CreateAccountPage = ({
  control,
  errors,
  isSubmitting,
  isDisabled,
  onSubmit,
  onOpenPrivacyPolicy,
  onOpenTermsOfService,
  onSignInPress,
}: CreateAccountPageProps) => {
  return (
    <Stack
      width={{ base: '100%', lg: '45vw' }}
      height="100%"
      minH="100%"
      justifyContent={{ base: 'initial' }}
      padding={{ base: 5, lg: '3rem' }}
      bgColor={colors.singletons.lightText}
      overflowY="auto"
    >
      <HelpCentreRedirectLink />
      <Stack space={2}>
        <Text fontSize="2xl" fontWeight={500}>
          Create your account
        </Text>
        <HStack space={2} alignItems="center">
          <Text fontSize="md">If you already have an account, please</Text>
          <Button
            variant="link"
            maxW={10}
            rightIconName="arrow-right"
            rightIconSize={18}
            rightIconColor={colors.lime[700]}
            onPress={onSignInPress}
          >
            <Text fontSize="md" fontWeight={500} color="lime.700">
              Sign in
            </Text>
          </Button>
        </HStack>
      </Stack>

      <Stack mt={6} width={{ base: '100%', lg: '100%' }}>
        <CreateAccount
          control={control}
          errors={errors}
          onOpenPrivacyPolicy={onOpenPrivacyPolicy}
          onOpenTermsOfService={onOpenTermsOfService}
        />
      </Stack>
      <Button
        variant="outline"
        borderColor="gray.900"
        padding={6}
        mt={6}
        width={{ base: '100%' }}
        _text={{
          color: 'gray.900',
          fontWeight: 500,
        }}
        onPress={onSubmit}
        isLoading={isSubmitting}
        isDisabled={isDisabled}
      >
        Continue
      </Button>
      <Hidden from="sm">
        <Box h={10} />
      </Hidden>
    </Stack>
  );
};
