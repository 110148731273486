import { HStack, VStack } from 'native-base';

import { TabItem } from '../TabItem';
import { TabsHeaderProps } from './types';

export const TabsHeader = ({
  tabs,
  selectedIndex,
  onTabPress,
}: TabsHeaderProps) => {
  return (
    <HStack
      width="full"
      overflow="hidden"
      flexWrap={'wrap'}
      alignItems="flex-start"
      justifyContent={'flex-start'}
      style={{ rowGap: 2 }}
    >
      {tabs?.map((tab, index) => {
        return (
          <VStack
            key={tab.label}
            flex={1}
            minW={{ base: '50%', md: '33%', lg: 'fit-content' }}
          >
            <TabItem
              label={tab.label}
              isActive={index === selectedIndex}
              onPress={() => onTabPress(index, tab.value)}
            />
          </VStack>
        );
      })}
    </HStack>
  );
};

export default TabsHeader;
