import { HStack, Skeleton, VStack } from 'native-base';
export const CorpSideCardLoadingSkeleton = () => {
  return (
    <VStack
      w="full"
      space={3}
      rounded="md"
      alignItems="center"
      _dark={{
        borderColor: 'coolGray.500',
      }}
      _light={{
        borderColor: 'coolGray.200',
      }}
      px={4}
      py={6}
      overflow={'hidden'}
    >
      <HStack space={2} w="full" alignItems={'center'}>
        <Skeleton size="4" rounded="full" />
        <Skeleton.Text lines={1} alignItems="center" pr={16} />
      </HStack>

      <Skeleton.Text lines={3} alignItems="center" px={8} my={6} />

      <Skeleton w="full" rounded="12" />
      <Skeleton.Text mt={2} lines={1} alignItems="center" px={12} />
    </VStack>
  );
};
