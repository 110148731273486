import { IColumn } from '@inovua/reactdatagrid-community/types';
import { Text, VStack } from 'native-base';
import { TableCellWithImage } from 'ui/components/TableCellWithImage';

import { ICharitiesHelpedFilters } from '~/pages/Authenticated/Insights/components/CharitiesHelped/CharitiesHelpedFilters';

export const tableColumns: IColumn[] = [
  {
    name: 'name',
    header: 'Company',
    defaultFlex: 1,

    render: ({ data }: any) => (
      <VStack pl={6}>
        <TableCellWithImage
          round
          name={data?.name || '-'}
          logo={data?.logo || ''}
        />
      </VStack>
    ),
  },
  {
    name: 'value',
    header: 'Total',
    defaultFlex: 1,
    render: ({ value }) => <Text pr={6}>{value || '-'}</Text>,
  },
];

export const INSIGHT_TABS = {
  LOGS: 'Logs',
  APPLICATIONS: 'Applications',
};

export const insightTabList = Object.values(INSIGHT_TABS).map((tabItem) => ({
  value: tabItem,
}));

export const applyLocalCharitiesHelpedFilters = <T extends { name: string }[]>(
  data: T,
  filters: Pick<ICharitiesHelpedFilters, 'text'>,
) => {
  return data.filter((item) => {
    const matchesTextFilter = filters.text
      ? item.name.toLowerCase().includes(filters.text.toLowerCase())
      : true;

    return matchesTextFilter;
  });
};
