import { useForm } from 'react-hook-form';

import { ICreateOpportunityDTO } from '~/types/dtos';

export const useWhenWhereFormController = () => {
  const { control, watch } = useForm<
    Pick<ICreateOpportunityDTO, 'locationOption'>
  >({
    mode: 'onChange',
    reValidateMode: 'onBlur',
  });

  const currentLocationType = watch('locationOption');

  return {
    control,
    currentLocationType,
  };
};
