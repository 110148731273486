import { Divider, HStack, Progress, Stack, Text, VStack } from 'native-base';
import Button from 'ui/components/Button';
import ReadMore from 'ui/components/ReadMore';

import { colors } from '~/theme/colors';

import { AddLocationsModal } from '../AddLocationsModal';
import { DateForm } from '../DateForm';
import { LocationTag } from '../LocationTag';
import { MultipleLocationsFormProps } from './types';
import { useMultipleLocationsFormController } from './useMultipleLocationsFormController';

export const MultipleLocationsForm = ({
  addLocationGroup,
  removeLocationGroup,
  locationGroupsFields,
  control,
  errors,
  isTeamEvent,
  isEditing,
  locationTagsByGroupIndex,
  setValue,
  handleUpdateLocationGroup,
  handleDeleteAllLocationsFromGroup,
  handleDeleteLocationFromGroup,
  isEventActivity,
}: MultipleLocationsFormProps) => {
  const {
    groupByIndexExistsLocation,
    isLoadingLocations,
    loadingProgress,
    maxLoadingProgress,
    loadingPercentage,
    modalRef,
    handleOpenModal,
    closeModal,
    onAddAddresses,
    currentModalAddresses,
  } = useMultipleLocationsFormController({
    handleUpdateLocationGroup,
    locationTagsByGroupIndex,
  });
  return (
    <>
      <Stack w="100%" mt="6">
        {locationGroupsFields.map((locationGroup, index) => {
          return (
            <Stack
              mb="10"
              p={6}
              borderRadius="xl"
              borderWidth={'1'}
              borderColor="gray.200"
              bgColor={colors.singletons.lightText}
              key={index}
            >
              <Stack flex={1}>
                <HStack
                  w="full"
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <Text color="gray.600" fontWeight="500" fontSize="18px">
                    Location {index + 1}
                  </Text>
                  {index > 0 && (
                    <Button
                      variant="ghost"
                      leftIconName="plus-circle"
                      leftIconColor="red"
                      color="error.600"
                      minW="8rem"
                      w="8rem"
                      onPress={() => removeLocationGroup(index)}
                    >
                      <Text color="error.600">Remove Location</Text>
                    </Button>
                  )}
                </HStack>
                <Divider my="10px" />
                <VStack borderRadius="xl" mt={4} bg="white" p={4}>
                  <HStack
                    borderRadius="xl"
                    justifyContent="space-between"
                    bg="white"
                    alignItems="center"
                  >
                    <Stack>
                      <Text color="gray.600" fontWeight="500" fontSize="md">
                        Address or Postcode
                      </Text>
                    </Stack>

                    <HStack
                      alignItems="center"
                      justifyContent="space-between"
                      space={4}
                    >
                      {groupByIndexExistsLocation(index) ? (
                        <Stack>
                          <Button
                            variant="ghost"
                            leftIconName="x-circle"
                            leftIconColor={colors.error[600]}
                            leftIconSize={16}
                            minH="2.5rem"
                            h={'2.5rem'}
                            borderRadius="2xl"
                            onPress={() =>
                              handleDeleteAllLocationsFromGroup(index)
                            }
                          >
                            <Text
                              color={colors.error[600]}
                              fontSize="xs"
                              fontWeight="medium"
                            >
                              Remove all
                            </Text>
                          </Button>
                        </Stack>
                      ) : null}
                      <Stack>
                        <Button
                          variant="outline"
                          leftIconName={
                            groupByIndexExistsLocation(index)
                              ? 'edit'
                              : 'plus-circle'
                          }
                          leftIconColor={colors.primary[400]}
                          leftIconSize={16}
                          minH="2.5rem"
                          h={'2.5rem'}
                          borderWidth={2}
                          borderColor={colors.primary[200]}
                          borderRadius="2xl"
                          onPress={() => handleOpenModal(index)}
                        >
                          <Text
                            color={colors.primary[400]}
                            fontSize="xs"
                            fontWeight="medium"
                          >
                            {groupByIndexExistsLocation(index)
                              ? 'Edit locations'
                              : 'Add locations'}
                          </Text>
                        </Button>
                      </Stack>
                    </HStack>
                  </HStack>
                  {isLoadingLocations ? (
                    <>
                      <Divider my={6} />
                      <VStack>
                        <Progress
                          value={loadingProgress}
                          size="md"
                          max={maxLoadingProgress}
                          width="full"
                        />
                        <Text fontWeight="medium" mt={2}>
                          {loadingPercentage}% completed
                        </Text>
                      </VStack>
                    </>
                  ) : null}
                </VStack>

                {groupByIndexExistsLocation(index) ? (
                  <ReadMore
                    textHeight="180px"
                    btnBgColor="transparent"
                    variant="ghost"
                    btnTextColor="darkText"
                    rightIconColor="darkText"
                    buttonTextShow="View all locations"
                    buttonTextHide="View less"
                    text={
                      <div id="locationsList">
                        <HStack flex={1} mt={4} space={4} flexWrap="wrap">
                          {locationTagsByGroupIndex &&
                          locationTagsByGroupIndex?.[index]?.length > 0
                            ? locationTagsByGroupIndex[index].map(
                                (locationTag, tagIndex) => (
                                  <LocationTag
                                    key={tagIndex}
                                    text={locationTag.sentInput}
                                    status={locationTag.status}
                                    onRemove={() =>
                                      handleDeleteLocationFromGroup(
                                        index,
                                        tagIndex,
                                      )
                                    }
                                  />
                                ),
                              )
                            : null}
                        </HStack>
                      </div>
                    }
                  />
                ) : null}
              </Stack>
              <DateForm
                control={control}
                parentIndex={index}
                formFieldName={'locationsGroups'}
                errors={errors}
                isLoading={isLoadingLocations}
                isEventActivity={isEventActivity}
                isTeamEvent={isTeamEvent}
                isEditing={isEditing}
                setValue={setValue}
              />
            </Stack>
          );
        })}
        <Button
          leftIconName="plus-circle"
          onPress={() =>
            addLocationGroup(
              locationGroupsFields.length - 1,
              locationGroupsFields.length,
            )
          }
          mt={8}
        >
          New Location
        </Button>
      </Stack>
      <AddLocationsModal
        modalRef={modalRef}
        onAddAddresses={onAddAddresses}
        onCloseModal={closeModal}
        existingAddress={currentModalAddresses}
      />
    </>
  );
};
