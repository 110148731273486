import { Box, HStack, Skeleton, VStack } from 'native-base';

export const MostCompletedActivitiesSkeleton = () => {
  return (
    <Box padding={4} borderRadius="md" backgroundColor="white">
      <VStack space={4}>
        <HStack alignItems="center" space={2}>
          <Skeleton h={'40px'} w={'40px'} rounded="full" />

          <VStack w="full" space={2}>
            <HStack space={2} alignItems={'center'}>
              <Skeleton h={'16px'} w={'16px'} rounded="full" />
              <Skeleton.Text lines={1} width="30%" />
            </HStack>
            <Skeleton.Text lines={1} fontSize="xl" width="60%" />
          </VStack>
        </HStack>

        <Skeleton.Text lines={1} width="30%" />

        <HStack space={2} alignItems={'center'}>
          <Skeleton h={'24px'} w={'24px'} rounded="full" />
          <Skeleton.Text lines={1} width="30%" />
        </HStack>
      </VStack>
    </Box>
  );
};

export const MostCompletedActivitiesSkeletonList = ({
  rows,
}: {
  rows: number;
}) => {
  const items = Array(rows).fill(0);
  return (
    <VStack space={4} mb={4}>
      {items.map((_, index) => {
        return <MostCompletedActivitiesSkeleton key={index} />;
      })}
    </VStack>
  );
};
