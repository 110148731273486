import { useRef } from 'react';
import { useIntersection } from 'ui/hooks/useIntersection';

import { FeedScaffoldProps } from './types';

export const useFeedScaffoldController = ({
  isLoading,
  hasNextPage,
  isRefetching,
  futureDataCount,
  isFetchingNextPage,
  dataCount,
  fetchNextPage,
  refetch,
}: FeedScaffoldProps) => {
  // States
  const topRef = useRef<HTMLElement | null>(null);

  // Hooks
  const handleOnScrollEndReached = () => {
    const preventFetchNextPage =
      !hasNextPage || isFetchingNextPage || isRefetching || isLoading;
    if (preventFetchNextPage) return;

    fetchNextPage?.();
  };

  const bottomRef = useIntersection(handleOnScrollEndReached);

  // Handlers
  const handleScrollToTop = () => {
    topRef.current?.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
      inline: 'nearest',
    });
  };

  const handleRefetchButtonPress = () => {
    handleScrollToTop();
    refetch?.();
  };

  // Render Statements
  const showRefetchButton = futureDataCount || 0 > 0;

  const isNextPageLoading = isLoading || isFetchingNextPage;

  const isRefetchingLoading = isRefetching && !isFetchingNextPage;

  const isEmpty = !isLoading && !isRefetching && dataCount == 0;

  const isOutData = !isRefetching && !hasNextPage && dataCount > 0;

  return {
    topRef,
    dataCount,
    futureDataCount,
    bottomRef,
    isEmpty,
    isOutData,
    showRefetchButton,
    isNextPageLoading,
    isRefetchingLoading,
    handleRefetchButtonPress,
  };
};
