import { Box, HStack, Pressable, Stack, Text } from 'native-base';
import React from 'react';
import Button from 'ui/components/Button';
import Icon from 'ui/components/Icon';
import Input from 'ui/components/Input';
import Modal from 'ui/components/Modals/Modal';
import Switch from 'ui/components/Switch';

import { colors } from '~/theme/colors';

import { IDetailsFormProps } from '../types';
import { useDetailsFormController } from './controller';
import { styleConfig } from './styleConfig';

const DetailsForm = ({
  control,
  errors,
  isDisabledButton,
  isPrivate,
  handleContinue,
  handleBack,
}: IDetailsFormProps) => {
  const { isMobile, modalRef, openModal } = useDetailsFormController();

  return (
    <>
      <Stack space={5} mt={8} p={2} w="100%">
        <Stack w="100%" space={2}>
          <Text fontSize="xl" color="gray.600" fontWeight={500}>
            About the ecosystem
          </Text>
          <Text color="gray.600" fontWeight={400} fontSize="sm">
            Fill out as much information as possible to find more volunteers!
          </Text>
        </Stack>
        <HStack justifyContent="center" alignItems="center" space="4">
          <HStack alignItems="center" space="1">
            <Icon
              icon="eye"
              size="18"
              color={isPrivate ? colors.gray[400] : colors.primary[600]}
            />
            <Text color={isPrivate ? 'gray.400' : 'primary.600'}>Public</Text>
          </HStack>
          <Switch control={control} name="isPrivate" />
          <HStack alignItems="center" space="1">
            <Icon
              icon="lock"
              size="18"
              color={isPrivate ? colors.primary[600] : colors.gray[400]}
            />
            <Text color={isPrivate ? 'primary.600' : 'gray.400'}>Private</Text>
          </HStack>
          <Box ml="2">
            <Pressable onPress={openModal}>
              <Icon icon="help-circle" size="15" color={colors.primary[600]} />
            </Pressable>
          </Box>
        </HStack>
        <Input
          name="name"
          control={control}
          placeholder="Name that will appear to the users"
          label="Name"
          isRequired
          errorMessage={errors.name?.message}
          isFullWidth
        />
        <Input
          multiline
          isFullWidth
          isRequired
          control={control}
          errorMessage={errors.description?.message}
          numberOfLines={6}
          name="description"
          label="Description"
          placeholder="Tell us more about your ecosystem"
        />
        <HStack
          w="full"
          flexDirection={styleConfig.buttonStackDirection}
          alignItems="center"
          space={2}
          mt="10"
        >
          {isMobile ? null : (
            <Stack w={styleConfig.buttonWidth}>
              <Button variant="ghost" width="100%" onPress={handleBack}>
                Cancel
              </Button>
            </Stack>
          )}
          <Stack w={styleConfig.buttonWidth}>
            <Button
              onPress={handleContinue}
              isDisabled={isDisabledButton}
              width="100%"
            >
              Continue
            </Button>
          </Stack>
          {isMobile ? (
            <Stack w={styleConfig.buttonWidth} mt={2}>
              <Button variant="ghost" width="100%" onPress={handleBack}>
                Cancel
              </Button>
            </Stack>
          ) : null}
        </HStack>
      </Stack>
      <Modal
        headerText="Public and Private ecosystems"
        modalContentMaxW="xl"
        ref={modalRef}
      >
        <Text color="gray.500">
          Both public and private ecosystems are visible to all users. However,
          private ecosystems are only accessible to users who have been invited,
          while public ecosystems can be joined by all users.
        </Text>
      </Modal>
    </>
  );
};

export default React.memo(DetailsForm);
