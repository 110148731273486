import { IColumn } from 'ui/components/DataTable/types';
import DataTableActions from 'ui/components/DataTableActions';
import { colors } from 'ui/theme/colors';
import { OrganizationDepartment } from 'ui/types/interfaces/organization';

export type MapOrganizationDepartmentsToTableColumnProps = {
  onEditAction: (data: OrganizationDepartment) => void;
  onDeleteAction: (data: OrganizationDepartment) => void;
};

export const MapOrganizationDepartmentsToTableColumn = ({
  onDeleteAction,
  onEditAction,
}: MapOrganizationDepartmentsToTableColumnProps): IColumn[] => {
  return [
    {
      name: 'name',
      header: 'Group',
      defaultFlex: 1,
      render: ({ data }) => data?.name || '-',
    },
    {
      name: 'actions',
      header: 'Actions',
      render: ({ data }) => {
        const basePopoverOptions = [
          {
            icon: {
              color: colors.gray[600],
              icon: 'edit',
              size: 24,
            },
            title: 'Edit',
            action: () => onEditAction(data),
          },
          {
            icon: {
              color: colors.error[600],
              icon: 'trash',
              size: 24,
            },
            title: 'Delete',
            titleColor: colors.error[600],
            action: () => onDeleteAction(data),
          },
        ];

        return (
          <DataTableActions
            popoverActions
            popoverTextColor={colors.primary[400]}
            popoverIconColor={colors.primary[400]}
            popoverOptions={basePopoverOptions}
          />
        );
      },
    },
  ];
};
