import { isBefore, isSameDay, isValid } from 'date-fns';
import {
  EActivityAttendanceTypes,
  EEventApplicationType,
  ESpaceOptionsKeys,
  ETypeOfWorkKeys,
} from 'ui/enums';
import { object, TestContext } from 'yup';

import { meetingLinkRegex } from '~/constants/forms.constants';
import { IEditEventDTO } from '~/types/dtos';
import { IAddress } from '~/types/interfaces/activity';
import Yup from '~/utils/validations/yup';

interface TestContextExtended {
  from: {
    value: IEditEventDTO;
  }[];
}

export const placesSchemaValidation = Yup.object().shape({
  address: Yup.object().test('attendanceType', async (value, context) => {
    const { from } = context as TestContext & TestContextExtended;
    const [, , parent] = from;

    const address = value;

    if (parent.value.attendanceType === EActivityAttendanceTypes.InPerson) {
      if (!address) return false;
      const isValid = await object()
        .test({
          name: 'address',
          test: (value: IAddress) => value && value.rawLocation !== null,
        })
        .validate(address);

      return !!isValid;
    } else {
      return true;
    }
  }),
  meetingLink: Yup.string().test('attendanceType', (value, context) => {
    const { from } = context as TestContext & TestContextExtended;
    const [, parent] = from;

    if (parent.value.attendanceType === EActivityAttendanceTypes.Remote) {
      if (value) {
        const isValid = meetingLinkRegex.test(encodeURI(value as string));
        return isValid;
      }

      return false;
    } else {
      return true;
    }
  }),
  noVolunteerLimit: Yup.boolean(),
  // @mycatdoitbetter TODO: Change this value tu number when the Input component can receive a number
  volunteerNumber: Yup.string().when('noVolunteerLimit', {
    is: false,
    then: Yup.string().required(),
  }),
});

export const datesSchemaValidation = Yup.object().shape(
  {
    startDate: Yup.date()
      .required()
      .nullable()
      .transform((_, originalValue) =>
        isValid(originalValue) ? originalValue : null,
      ),
    endDate: Yup.date()
      .required()
      .when('endDate', {
        is: (value: Date | undefined) => isValid(value),
        then: Yup.date().test(
          'endDate',
          'The end date must be after the start date',
          (value, context) => {
            const { startDate } = context.parent;
            if (!value || !startDate) return true;
            return isBefore(startDate, value) || isSameDay(value, startDate);
          },
        ),
        otherwise: Yup.date()
          .nullable()
          .transform((_, originalValue) =>
            isValid(originalValue) ? originalValue : null,
          ),
      }),
    dueDate: Yup.date().when('dueDate', {
      is: (value: Date | undefined) => isValid(value),
      then: Yup.date().test(
        'dueDate',
        'The due date must be within the event date',
        (value, context) => {
          const { endDate } = context.parent;
          if (!value || !endDate) return true;
          return isBefore(value, endDate) || isSameDay(value, endDate);
        },
      ),
      otherwise: Yup.date()
        .nullable()
        .transform((_, originalValue) =>
          isValid(originalValue) ? originalValue : null,
        ),
    }),
    noVolunteerLimit: Yup.boolean(),
    // @mycatdoitbetter TODO: Change this value tu number when the Input component can receive a number
    volunteerNumber: Yup.string().when('noVolunteerLimit', {
      is: false,
      then: Yup.string().required(),
    }),
    teamsNumber: Yup.number().test('eventApplicationType', (value, context) => {
      const { from } = context as TestContext & TestContextExtended;
      const [, parent] = from;

      const teamsNumber = value;

      if (parent.value.eventApplicationType === EEventApplicationType.Team) {
        if (teamsNumber) {
          return true;
        }
        return false;
      } else {
        return true;
      }
    }),
    teamsMaxSize: Yup.number().test(
      'eventApplicationType',
      (value, context) => {
        const { from } = context as TestContext & TestContextExtended;
        const [, parent] = from;

        const teamsMaxSize = value;

        if (parent.value.eventApplicationType === EEventApplicationType.Team) {
          if (teamsMaxSize) {
            return true;
          }
          return false;
        } else {
          return true;
        }
      },
    ),
    teamsMinSize: Yup.number().test(
      'eventApplicationType',
      (value, context) => {
        const { from } = context as TestContext & TestContextExtended;
        const [currentTree, parent] = from;

        const teamsMinSize = value;
        const teamsMaxSize = currentTree.value.teamsMaxSize;

        return (
          parent.value.eventApplicationType !== EEventApplicationType.Team ||
          (teamsMinSize && teamsMaxSize && teamsMaxSize > teamsMinSize) ||
          false
        );
      },
    ),
  },
  [
    ['dueDate', 'dueDate'],
    ['endDate', 'endDate'],
  ],
);

export const multipleSchemaValidation = Yup.object().shape({
  places: Yup.array().of(placesSchemaValidation),
  dates: Yup.array().of(datesSchemaValidation),
  typeOfWork: Yup.string().required(),
  spaceOptions: Yup.string().required(),
  attendanceType: Yup.string().required(),
});

export const formInitialDefaultValues = {
  attendanceType: EActivityAttendanceTypes.InPerson,
  spaceOptions: ESpaceOptionsKeys.Indoor,
  typeOfWork: ETypeOfWorkKeys.Alone,
  places: [
    {
      address: undefined,
      meetingLink: undefined,
    },
  ],
  dates: [
    {
      indexPosition: 0,
      placeFormIndex: 0,
      volunteerNumber: undefined,
      noVolunteerLimit: false,
      startDate: undefined,
      endDate: undefined,
      dueDate: undefined,
      teamsNumber: undefined,
      teamsMaxSize: undefined,
      teamsMinSize: undefined,
    },
  ],
};
