import { useState } from 'react';

import { useQuery } from '~/hooks/useQuery';
import { IOrganization } from '~/types/interfaces/organization';

export const useAppDetailsController = (organizationId: string) => {
  const { data: organization } = useQuery<IOrganization>(
    `/organization/${organizationId}`,
  );
  const [numberOfLines, setNumberOfLines] = useState<number | undefined>(3);

  const handleReadMore = () =>
    setNumberOfLines((current) => (current === 3 ? undefined : 3));

  return { numberOfLines, organization, handleReadMore };
};
