import { IconEnum } from 'ui/components/Icon';
import { EEventApplicationType } from 'ui/enums';

import { ActivityTypeEnum, MeasurementUnitsCategory } from '~/enums';
import { MeasurementUnit } from '~/types/interfaces/measurementUnit';

import { EcosystemNetworkConnectionsPopulated } from '../types/interfaces/ecosystem-network-connections';
import { IOrganization } from '../types/interfaces/organization';

export const secondsToHoursMinutesString = (totalSeconds: number) => {
  if (totalSeconds === 0) return '00h 00m';
  const hours = Math.floor(totalSeconds / 3600);
  const minutes = Math.floor((totalSeconds % 3600) / 60);

  const formattedHours = hours.toString().padStart(2, '0') + 'h';
  const formattedMinutes = minutes.toString().padStart(2, '0') + 'm';
  return `${formattedHours} ${formattedMinutes}`;
};

export const getAvatarFirstName = (name: string) => {
  return name.split(' ')[0]?.substring(0, 8);
};

export const showCustomIconByActivityTypeAction = (
  activityType: ActivityTypeEnum,
) => {
  return activityType === ActivityTypeEnum.Action ? true : false;
};

export const iconToShowByActivityType = (
  activityType: ActivityTypeEnum,
  eventApplicationType?: EEventApplicationType,
) => {
  switch (activityType) {
    case ActivityTypeEnum.Event:
      if (eventApplicationType === EEventApplicationType.Team) {
        return IconEnum.USERS;
      }
      return IconEnum.CALENDAR;
    case ActivityTypeEnum.Action:
      return IconEnum.PUZZLE;
    case ActivityTypeEnum.OngoingOpportunity:
      return IconEnum.REPEAT;
  }
};

export const textToShowByActivityType = (
  activityType: ActivityTypeEnum,
  eventApplicationType?: EEventApplicationType,
) => {
  switch (activityType) {
    case ActivityTypeEnum.Event:
      if (eventApplicationType === EEventApplicationType.Team) {
        return 'TEAM EVENT';
      }
      return 'EVENT';
    case ActivityTypeEnum.Action:
      return 'ACTION';
    case ActivityTypeEnum.OngoingOpportunity:
      return 'ONGOING';
  }
};

export const labelTypeEventBgColorByActivityType = (
  activityType: ActivityTypeEnum,
) => {
  switch (activityType) {
    case ActivityTypeEnum.Event:
      return 'lime.100';
    case ActivityTypeEnum.Action:
      return 'orange.100';
    case ActivityTypeEnum.OngoingOpportunity:
      return 'purple.100';
  }
};

export const transformHoursToSeconds = (hours: number) => {
  return hours * 60 * 60;
};

export const transformSecondsToHours = (seconds: number) => {
  return seconds / 60 / 60;
};

export const getMeasurementUnit = (
  measurementUnits: {
    [key: string]: MeasurementUnit[];
  },
  measurmentUnitId: string,
) => {
  const measurementUnit = Object.values(measurementUnits)
    .flatMap((measurmentUnit) => measurmentUnit)
    .find((measurementUnit) => measurementUnit._id === measurmentUnitId);

  return measurementUnit;
};

export const getMeasurementUnitNameAndCategory = (
  measurementUnit: MeasurementUnit,
  targetAmount: number,
): { measurementUnitName: string; measurementUnitCategory: string } => {
  if (
    measurementUnit.category === MeasurementUnitsCategory.CustomUnit &&
    measurementUnit.steps &&
    measurementUnit.steps.length > 0
  ) {
    if (targetAmount > 1) {
      return {
        measurementUnitName: 'Steps',
        measurementUnitCategory: 'Completion',
      };
    }
    return {
      measurementUnitName: 'Step',
      measurementUnitCategory: 'Completion',
    };
  } else {
    if (targetAmount > 1) {
      return {
        measurementUnitName: measurementUnit.pluralLabel,
        measurementUnitCategory: measurementUnit.category,
      };
    }
    return {
      measurementUnitName: measurementUnit.singularLabel,
      measurementUnitCategory: measurementUnit.category,
    };
  }
};

export const getPartnerEcosystem = (
  connection: EcosystemNetworkConnectionsPopulated,
  partnerOrganization: IOrganization,
) => {
  return connection.organizationOne._id === partnerOrganization._id
    ? connection.ecosystemOne
    : connection.ecosystemTwo;
};
