import { startOfDay } from 'date-fns';
import { useEffect, useState } from 'react';

import { useEcosystemAvailableApps } from '~/hooks/apps/useEcosystemAvailableApps';
import { InsightSelectedDateType } from '~/pages/Authenticated/Insights/InsightDateFilter';
import {
  InsightPageFilters,
  useInsightFilters,
} from '~/pages/Authenticated/Insights/useInsightFilters';
import { dateFilterBuilder } from '~/pages/Authenticated/Insights/utils';
import { InsightsPeriodType } from '~/services/resources/types';

export const useInsightsController = () => {
  // States
  const [defaultFilters, setDefaultFilters] =
    useState<InsightPageFilters | null>(null);

  // Hooks
  const { appIds, apps } = useEcosystemAvailableApps();
  const { filters, setInsightFilters } = useInsightFilters();

  useEffect(() => {
    // Capture initial search params
    if (!defaultFilters) {
      const isValid = filters.apps.length > 0 && !!filters.from && !!filters.to;

      if (!isValid) {
        // Set Default Filters values
        const currentDate = startOfDay(new Date());
        const periodType = InsightsPeriodType.ThisMonth;
        const [from, to] = dateFilterBuilder[periodType](currentDate);

        const _defaultFilters = {
          apps: appIds,
          from: from.toISOString(),
          to: to.toISOString(),
          periodType,
        } as InsightPageFilters;

        setInsightFilters(() => _defaultFilters);
        setDefaultFilters(_defaultFilters);
        return;
      }

      setDefaultFilters(filters);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters, appIds]);

  /* Handlers */
  const handleDateChange = (newSelectedDates: InsightSelectedDateType) => {
    setInsightFilters((prevState) => {
      return {
        ...prevState,
        periodType: newSelectedDates.periodType,
        from: newSelectedDates.from.toISOString(),
        to: newSelectedDates.to.toISOString(),
      };
    });
  };

  const handleSelectApp = (newSelectApp?: string) => {
    setInsightFilters(() => ({
      apps: !newSelectApp ? appIds : [newSelectApp],
    }));
  };

  return { defaultFilters, filters, apps, handleDateChange, handleSelectApp };
};
