export function isBase64Image(imageString: string) {
  return imageString.startsWith('data:image/');
}

export function isUrlImage(imageString: string) {
  try {
    const url = new URL(imageString);
    return url.protocol === 'http:' || url.protocol === 'https:';
  } catch (e) {
    return false;
  }
}
