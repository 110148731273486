import { Box, HStack, Image, Pressable, Stack, Text } from 'native-base';

import useImageGalleryController from './controller';
import { ImageGalleryProps } from './types';

const ImageGallery = (props: ImageGalleryProps) => {
  const {
    currentImage,
    images,
    coverImageLabel,
    extraImagesLabel,
    handleChangeCurrentImg,
    imageProps,
  } = useImageGalleryController(props);

  return (
    <Stack space={3}>
      <Stack space={3}>
        <Stack>
          <Text color="gray.600" fontSize="md" fontWeight="medium">
            {coverImageLabel}
          </Text>
        </Stack>
        <Stack flex={1} width="100%">
          {currentImage ? (
            <Image
              source={{ uri: currentImage }}
              alt="cover image"
              rounded="2xl"
              h="200px"
              resizeMode="cover"
              {...imageProps}
            />
          ) : null}
        </Stack>
      </Stack>
      <Stack space={3}>
        <Stack>
          <Text color="gray.600" fontSize={16} fontWeight="medium">
            {extraImagesLabel}
          </Text>
        </Stack>
        {images && images?.length > 0 ? (
          <HStack space={4} flexWrap="wrap">
            {images.map((image, index) => (
              <Pressable
                key={`${image}-${index}`}
                onPress={() => handleChangeCurrentImg(image)}
              >
                <Image
                  source={{ uri: image }}
                  alt="extra image"
                  size="lg"
                  rounded="2xl"
                />
              </Pressable>
            ))}
          </HStack>
        ) : null}
      </Stack>
    </Stack>
  );
};

export default ImageGallery;
