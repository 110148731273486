import { useEffect, useMemo, useState } from 'react';

import { VITE_DOIT_VOLUNTEER_URL } from '~/config';
import { PAGES } from '~/constants/pages.constants';
import { useAppDispatch } from '~/hooks/useAppDispatch';
import { useAppSelector } from '~/hooks/useAppSelector';
import { useQuery } from '~/hooks/useQuery';
import { useRouter } from '~/hooks/useRouter';
import { joinAsAdminSliceActions } from '~/store/slices/joinAsAdmin';
import { IOrganization } from '~/types/interfaces/organization';

export const useAdminSuccessfullyJoinedController = () => {
  const [isInviteModalOpen, setIsInviteModalOpen] = useState(false);

  const { isJoiningAsAdmin, organizationId } = useAppSelector(
    ({ joinAsAdmin }) => joinAsAdmin,
  );

  const { replaceRoute, goToRoute } = useRouter();

  const dispatch = useAppDispatch();

  const inviteLink = useMemo(() => {
    return `${VITE_DOIT_VOLUNTEER_URL}accept-invite?organizationId=${organizationId}`;
  }, [organizationId]);

  const queryOptions = useMemo(() => {
    return { queryOptions: { enabled: !!organizationId } };
  }, [organizationId]);

  const { data: organisation } = useQuery<IOrganization>(
    `/organization/public/${organizationId}`,
    queryOptions,
  );

  const goToDashBoard = () => {
    if (isInviteModalOpen) {
      setIsInviteModalOpen(false);
    }
    dispatch(joinAsAdminSliceActions.clear());
    goToRoute(PAGES.ROOT);
  };

  const toggleModal = () => setIsInviteModalOpen((current) => !current);

  useEffect(() => {
    if (!organizationId && !isJoiningAsAdmin) replaceRoute(PAGES.ROOT);
  }, [organizationId, replaceRoute, isJoiningAsAdmin]);

  return {
    isInviteModalOpen,
    organizationId,
    inviteLink,
    goToDashBoard,
    toggleModal,
    organisationName: organisation?.name,
  };
};
