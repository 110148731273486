import { yupResolver } from '@hookform/resolvers/yup';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import { COMMON_MESSAGES } from '~/constants/messages.constants';
import { PAGES } from '~/constants/pages.constants';
import { useAuthentication } from '~/hooks/authentication/useAuthentication';
import { useAppDispatch } from '~/hooks/useAppDispatch';
import { useAppSelector } from '~/hooks/useAppSelector';
import { useRouter } from '~/hooks/useRouter';
import { IConfirmEmailFormFields } from '~/pages/Unauthenticated/SignUpPage/ConfirmEmailPage/types';
import AuthService from '~/services/resources/auth';

import {
  defaultCoolDownTime,
  defaultValues,
  schemaValidation,
} from './constants';

export const useConfirmEmailController = () => {
  const [isSubmittingResendCode, setIsSubmittingResendCode] = useState(false);
  const [isResendCodeDisabled, setIsResendCodeDisabled] = useState(false);
  const [coolDownTime, setCoolDownTime] = useState(defaultCoolDownTime);
  const [searchParams] = useSearchParams();

  const { isJoiningAsAdmin, code, organizationId } = useAppSelector(
    ({ joinAsAdmin }) => joinAsAdmin,
  );

  const { signIn } = useAuthentication();

  const {
    location: { state },
    replaceRoute,
    goToRoute,
  } = useRouter();

  const dispatch = useAppDispatch();

  const {
    control,
    handleSubmit,
    formState: { errors, isValid, isDirty, isSubmitting },
  } = useForm<IConfirmEmailFormFields>({
    resolver: yupResolver(schemaValidation),
    defaultValues: defaultValues,
  });

  const email = state?.email;
  const password = state?.password;

  const disableVerifyCode = useMemo(
    () => !isValid || !isDirty || isSubmitting,
    [isValid, isDirty, isSubmitting],
  );

  const handleResendCode = async () => {
    try {
      if (isResendCodeDisabled) return;
      setIsSubmittingResendCode(true);
      await AuthService.resendConfirmationCode({ email });
      toast.success(COMMON_MESSAGES.ACCESS_CODE_SENT);
      setIsResendCodeDisabled(true);
    } catch (error) {
      toast.error(COMMON_MESSAGES.UNABLE_SEND_ACCESS_CODE);
      setIsResendCodeDisabled(false);
    } finally {
      setIsSubmittingResendCode(false);
    }
  };

  const onVerifyCode = useCallback(
    async ({ accessCode }: IConfirmEmailFormFields) => {
      const redirectURL = searchParams.get('redirect') || '';

      try {
        await AuthService.confirmSignUp({ email, accessCode, password });

        await signIn(email, password);
        toast.success(COMMON_MESSAGES.CODE_VERIFIED_WITH_SUCCESS);

        if (redirectURL) {
          replaceRoute(PAGES.REDIRECT, {}, { propagateSearchParams: true });
          return;
        }
        goToRoute(PAGES.SIGN_UP_CREATE_ORGANISATION);
      } catch (error) {
        toast.error(COMMON_MESSAGES.UNABLE_SEND_ACCESS_CODE);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [email, password, isJoiningAsAdmin, searchParams, goToRoute, dispatch],
  );

  useEffect(() => {
    if (!email) goToRoute(PAGES.ROOT);
  }, [email, goToRoute]);

  useEffect(() => {
    if (!isResendCodeDisabled) return;
    const interval = setInterval(() => {
      if (coolDownTime === 1) {
        setIsResendCodeDisabled(false);
        setCoolDownTime(defaultCoolDownTime);
        return;
      }
      setCoolDownTime((current) => current - 1);
    }, 1000);

    return () => clearInterval(interval);
  }, [coolDownTime, isResendCodeDisabled, setCoolDownTime]);

  return {
    control,
    errors,
    disableVerifyCode,
    email,
    isSubmitting,
    isSubmittingResendCode,
    isResendCodeDisabled,
    coolDownTime,
    onVerifyCode: handleSubmit(onVerifyCode),
    handleResendCode,
  };
};
