import { HStack } from 'native-base';

import { ThumbnailMiniItem } from '../ThumbnailMiniItem';
import { ThumbnailMiniProps } from './types';

const ThumbnailMini = ({ images, handleDeleteFile }: ThumbnailMiniProps) => {
  return (
    <HStack
      flex={1}
      mt="2"
      p={1}
      minH="120px"
      borderWidth={1}
      borderRadius={24}
      borderColor="muted.200"
      justifyContent="center"
      flexWrap="wrap"
    >
      {images.length > 0
        ? images?.map((image, index) => (
            <ThumbnailMiniItem
              key={
                image.isFile
                  ? `${index}${image.imageFile?.name}`
                  : `${index}${image.imageUrl}`
              }
              imageUrl={
                image.isFile
                  ? image.imageFile && image?.imageFile?.image
                  : image.imageUrl
              }
              handleDeleteFile={handleDeleteFile}
              imageItem={image}
            />
          ))
        : null}
    </HStack>
  );
};

export default ThumbnailMini;
