import { Skeleton, Stack } from 'native-base';

export const SwitchEcosystemShimmer: React.FC = () => {
  return (
    <Stack
      width={{ base: '160px', lg: '216px' }}
      height={{ base: '180px', lg: '202px' }}
      borderWidth={1}
      borderRadius="3xl"
      borderColor="gray.300"
    >
      <Skeleton width="100%" borderTopRadius="3xl" />

      <Stack width="100%" alignItems="center">
        <Skeleton.Text mt={4} lines={1} width="70%" />
        <Skeleton.Text mt={8} lines={1} width="40%" />
      </Stack>
    </Stack>
  );
};
