import { useEffect, useState } from 'react';

export const useTimer = ({ timeout }: { timeout: number }) => {
  const [seconds, setSeconds] = useState(0);
  const [isActiveCountdown, setIsActiveCountdown] = useState(false);

  useEffect(() => {
    const interval = setInterval(() => {
      setSeconds((seconds) => {
        if ((seconds > 0 || seconds <= timeout) && isActiveCountdown) {
          return seconds - 1;
        }
        return seconds;
      });

      if (seconds === 0) {
        setSeconds(timeout);
        setIsActiveCountdown(false);
      }
    }, 1000);

    return () => clearInterval(interval);
  }, [seconds, isActiveCountdown]);

  return {
    setIsActiveCountdown,
    isActiveCountdown,
    seconds,
  };
};

export default useTimer;
