import { useQuery } from '~/hooks/useQuery';

export const useLoadPartnerLogHoursTotalReport = (connectionId: string) => {
  const { data, isLoading, isRefetching } = useQuery<{ total: number }>(
    `measurement-insights/partner/${connectionId}/total-time-logged`,
    {
      key: `partner-activities-report/${connectionId}/total-time-logged`,
    },
  );

  return { data, isLoading, isRefetching };
};
