import { INPUT_COMPONENT_TYPES } from 'ui/components/FormBuilderV2';

import { AppInfoAlert } from '~/components/AppInfoAlert';

import { ActivityPublishedAppsSectionProps } from './types';
export const ActivityPublishedAppsSection = ({
  appsList,
  disabledValues,
  helpLink = '',
}: ActivityPublishedAppsSectionProps) => {
  return {
    sectionTitle: 'Where to publish the activity?',
    sectionSubtitle:
      'Select in which other apps you want to publish the activity',
    key: 'published-app-section',
    sectionCustomHeaderSlot: AppInfoAlert({ helpLink }),
    sectionInputRows: [
      {
        key: 'published-apps-row',
        inputs: [
          {
            id: 'publishedApps',
            name: 'publishedApps',
            isRequired: true,
            component: INPUT_COMPONENT_TYPES.APP_SELECT,
            inputProps: {
              disabledValues,
              isMultiple: true,
              appsList,
            },
          },
        ],
      },
    ],
  };
};
