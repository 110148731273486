import { useEffect, useMemo, useState } from 'react';
import { PermissionType } from 'ui/enums/index';
import { PopulatedAppPermissionGroup } from 'ui/types/interfaces/app-permissions-group';

import { MemberTypeEnum } from '~/enums';
import { useAppDispatch } from '~/hooks/useAppDispatch';
import { useAppSelector } from '~/hooks/useAppSelector';
import { useQuery } from '~/hooks/useQuery';
import { addMemberActions } from '~/store/slices/addMember';
import { App } from '~/types/interfaces/app';
import { PopulatedNetworkAppSharing } from '~/types/interfaces/networkAppSharing';

export const usePermissionsFormSectionController = (
  memberType: MemberTypeEnum,
  appsConnectedToOrganization?: PopulatedNetworkAppSharing[],
) => {
  const [appsMap, setAppsMap] = useState<Record<string, App>>({});

  const dispatch = useAppDispatch();

  const {
    ecosystemsToShare,
    selectedMemberPermissions,
    selectedPartnerPermissions,
  } = useAppSelector(({ addMember }) => addMember);

  const appsSelected = useMemo(() => {
    return ecosystemsToShare?.flatMap((ecosystemToShare) =>
      ecosystemToShare.appsData.flatMap((app) => {
        return {
          ecosystemId: ecosystemToShare.ecosystemId,
          appId: app.appId,
        };
      }),
    );
  }, [ecosystemsToShare]);

  const appPermissionsParams = useMemo(() => {
    return {
      apps: JSON.stringify(appsSelected.map((a) => a.appId)),
      ecosystems: JSON.stringify(
        ecosystemsToShare.flatMap(
          (ecosystemToShare) => ecosystemToShare.ecosystemId,
        ),
      ),
    };
  }, [appsSelected, ecosystemsToShare]);

  const {
    data: permissionsGroupMember,
    isLoading: isLoadingPermissionsGroupMember,
  } = useQuery<PopulatedAppPermissionGroup[]>(
    `/app-permission-group/ecosystem`,
    {
      key: [
        `app-permission-group-type-member`,
        `${ecosystemsToShare.map((e) => e.ecosystemId)}`,
        `${ecosystemsToShare.map((e) => e.appsData.map((a) => a.appId)) || []}`,
      ],
      requestOptions: {
        params: {
          ...appPermissionsParams,
          permissionType: PermissionType.MEMBER,
        },
      },
      queryOptions: {
        enabled: !!appsSelected.length,
      },
    },
  );

  const {
    data: permissionsGroupPartner,
    isLoading: isLoadingPermissionsGroupPartner,
  } = useQuery<PopulatedAppPermissionGroup[]>(
    `/app-permission-group/ecosystem`,
    {
      key: [
        `app-permission-group-type-partner`,
        `${ecosystemsToShare.map((e) => e.ecosystemId)}`,
        `${ecosystemsToShare.map((e) => e.appsData.map((a) => a.appId)) || []}`,
      ],
      requestOptions: {
        params: {
          ...appPermissionsParams,
          permissionType: PermissionType.PARTNER,
        },
      },
      queryOptions: {
        enabled: !!appsSelected.length && memberType !== MemberTypeEnum.Member,
      },
    },
  );

  const appsPermissionsGroupPartnerOptions = (
    appId: string,
    ecosystemId: string,
  ) => {
    if (!permissionsGroupPartner?.length) return [];
    return permissionsGroupPartner
      .filter(
        (appPermissionGroup) =>
          appPermissionGroup.app === appId &&
          appPermissionGroup.ecosystem === ecosystemId,
      )
      .map((appPermissionGroup) => {
        return {
          value: appPermissionGroup._id,
          label: appPermissionGroup.name,
        };
      });
  };

  const appsPermissionsGroupMemberOptions = (
    appId: string,
    ecosystemId: string,
  ) => {
    if (!permissionsGroupMember?.length) return [];
    return permissionsGroupMember
      .filter(
        (appPermissionGroup) =>
          appPermissionGroup.app === appId &&
          appPermissionGroup.ecosystem === ecosystemId,
      )
      .map((appPermissionGroup) => {
        return {
          value: appPermissionGroup._id,
          label: appPermissionGroup.name,
        };
      });
  };

  const handleOnPermissionsGroupChange = (
    value: string,
    index: number,
    permissions: PopulatedAppPermissionGroup[],
    appPermissionsGroup?: PopulatedAppPermissionGroup[],
  ) => {
    const appPermissionGroup = appPermissionsGroup?.find(
      (appPermissionGroup) => appPermissionGroup._id === value,
    );
    if (!appPermissionGroup) return [];

    const arrayCopy = [...permissions];
    arrayCopy[index] = appPermissionGroup;

    return arrayCopy;
  };

  const handleChangeSelectedPartnerPermission = (
    value: string,
    index: number,
    ecosystemToShareId: string,
  ) => {
    const permissions = handleOnPermissionsGroupChange(
      value,
      index,
      selectedPartnerPermissions,
      permissionsGroupPartner,
    );

    dispatch(
      addMemberActions.setPermissionToEcosystemApp({
        ecosystemId: ecosystemToShareId,
        appId: appsSelected[index]?.appId,
        permissionGroupId: value,
      }),
    );
    dispatch(addMemberActions.setSelectedPartnersPermissions(permissions));
  };

  const handleChangeSelectedMemberPermission = (
    value: string,
    index: number,
    ecosystemToShareId: string,
  ) => {
    const permissions = handleOnPermissionsGroupChange(
      value,
      index,
      selectedMemberPermissions,
      permissionsGroupMember,
    );

    dispatch(
      addMemberActions.setPermissionToEcosystemApp({
        ecosystemId: ecosystemToShareId,
        appId: appsSelected[index]?.appId,
        permissionGroupId: value,
      }),
    );
    dispatch(addMemberActions.setSelectedMembersPermissions(permissions));
  };

  const isLoading = useMemo(() => {
    return isLoadingPermissionsGroupPartner || isLoadingPermissionsGroupMember;
  }, [isLoadingPermissionsGroupPartner, isLoadingPermissionsGroupMember]);

  useEffect(() => {
    if (appsConnectedToOrganization?.length) {
      const map: Record<string, App> = {};
      for (const appConnected of appsConnectedToOrganization) {
        map[appConnected.app._id] = appConnected.app;
      }
      setAppsMap(map);
    }
  }, [appsConnectedToOrganization]);

  useEffect(() => {
    const initializePermissionsArray = Array(appsSelected.length).fill(null);
    if (!selectedMemberPermissions?.length) {
      dispatch(
        addMemberActions.setSelectedMembersPermissions(
          initializePermissionsArray,
        ),
      );
    }

    if (!selectedPartnerPermissions) {
      dispatch(
        addMemberActions.setSelectedPartnersPermissions(
          initializePermissionsArray,
        ),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    appsSelected,
    isLoading,
    appsMap,
    appsPermissionsGroupPartnerOptions,
    appsPermissionsGroupMemberOptions,
    selectedMemberPermissions,
    selectedPartnerPermissions,
    handleChangeSelectedPartnerPermission,
    handleChangeSelectedMemberPermission,
  };
};
