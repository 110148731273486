import { useQuery } from '~/hooks/useQuery';

import { ActivityTypeEnum } from '../../../../enums';

export const useLoadEcosystemApplicationsReport = (ecosystem: string) => {
  const { data, isLoading, isRefetching } = useQuery<{
    [key in ActivityTypeEnum]: number;
  }>(`activity-application-insights/ecosystem/${ecosystem}/count`, {
    key: `ecosystem-activities-report-${ecosystem}-count`,
  });

  return { data, isLoading, isRefetching };
};
