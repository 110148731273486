import { colors } from 'ui/theme/colors';

export const sliderStyle = {
  handle: {
    backgroundColor: colors.white,
    borderColor: colors.black,
    borderWidth: 2,
    width: 16,
    height: 16,
    opacity: 1,
  },
  track: {
    height: 8,
    backgroundColor: colors.black,
  },
  rail: {
    height: 8,
  },
  tracks: {
    height: 8,
  },
};
