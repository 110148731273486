import { Box } from 'native-base';
import logoDoitColorful from 'ui/assets/svgs/doit-logo-colorful.svg';

export const Header = () => (
  <Box bg="white" py="4" minH="82px" h="82px">
    <Box h="100%" width="min(90%, 1280px)" mx="auto" justifyContent="center">
      <Box w="70px">
        <img
          src={logoDoitColorful}
          width="100%"
          height="100%"
          alt="logo doIT"
        />
      </Box>
    </Box>
  </Box>
);
