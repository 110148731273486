import { Button, Stack, Text } from 'native-base';

import { SingleLocationForm } from '~/components/SingleLocationsForm';

import { SingleLocationProps } from './types';
import { useSingleLocationController } from './useEditSingleLocationController';

export const SingleLocation = (props: SingleLocationProps) => {
  const {
    control,
    addDateForm,
    appendDate,
    removeDateForm,
    datesFields,
    isDisabledPublishButton,
    isTeamEvent,
    errors,
    handleSubmitForm,
    handleBack,
    setValue,
    isLoading,
    isDirty,
  } = useSingleLocationController(props);

  return (
    <Stack>
      <SingleLocationForm
        control={control as any}
        datesFields={datesFields as any}
        appendDate={appendDate as any}
        addDateForm={addDateForm}
        removeDateForm={removeDateForm}
        isEventActivity
        isTeamEvent={isTeamEvent}
        isEditing={true}
        errors={errors}
        isDirty={isDirty}
        setValue={setValue}
      />

      <Stack
        my={4}
        w="100%"
        flexDirection="row"
        justifyContent="space-between"
        alignItems="center"
        space={3}
      >
        <Stack flex={1} ml={4}>
          <Button onPress={handleBack} flex={1} variant="ghost">
            <Text color="primary.600">Back</Text>
          </Button>
        </Stack>
        <Stack flex={1} ml={4}>
          <Button
            onPress={handleSubmitForm}
            flex={1}
            isDisabled={isDisabledPublishButton}
            isLoading={isLoading}
          >
            <Text color="white">Publish</Text>
          </Button>
        </Stack>
      </Stack>
    </Stack>
  );
};
