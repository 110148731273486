import { Skeleton, VStack } from 'native-base';

export const SpaceOptionsSkeleton = () => {
  return (
    <VStack
      w="full"
      h="full"
      px={{ base: 6, md: 12 }}
      py={8}
      mx="auto"
      space={4}
    >
      <Skeleton w="full" h="36px" maxW={'60%'} borderRadius={4} />
      <Skeleton w="full" h="36px" maxW={'80%'} borderRadius={4} />
      <Skeleton w="full" h="36px" maxW={'70%'} borderRadius={4} />
    </VStack>
  );
};
