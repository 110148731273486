import { Stack } from 'native-base';

import Page403 from '~/components/Page403';
import { Permissions } from '~/components/Permissions';
import { CreateEditActivityPermissions } from '~/constants/permissions.org.constants';

import { AddOpportunityForm } from './AddOpportunityForm';

export const AddOpportunity = () => {
  return (
    // <Permissions.ValidateAll
    //   requiredPermissions={CreateEditActivityPermissions.Ongoing}
    //   fallbackComponent={<Page403 />}
    // >
    <Stack>
      <AddOpportunityForm />
    </Stack>
    // </Permissions.ValidateAll>
  );
};
