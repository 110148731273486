import { useCallback, useMemo, useRef, useState } from 'react';
import { toast } from 'react-toastify';
import { IModalRefProps } from 'ui/components/Modals/Modal/types';

import { useAppSelector } from '~/hooks/useAppSelector';
import { useLoadAdminProfiles } from '~/hooks/useLoadAdminProfiles';
import { AdminProfilesControllerAttributes } from '~/pages/Authenticated/Settings/AdminsSettings/types';
import { OrganizationService } from '~/services/resources/organization';
import { QueryParams } from '~/services/resources/types';

export const useAdminProfilesController =
  (): AdminProfilesControllerAttributes => {
    const [queryParams, setQueryParams] = useState<QueryParams | undefined>(
      undefined,
    );
    const { organizationSelectedId } = useAppSelector(({ auth }) => auth);
    const {
      responseData,
      isLoading,
      isFetching,
      isRefetching,
      refetch: refetchProfiles,
    } = useLoadAdminProfiles(organizationSelectedId, queryParams);
    const areProfilesLoading = useMemo(
      () => isLoading || isFetching || isRefetching,
      [isLoading, isFetching, isRefetching],
    );

    const tableData = useMemo(() => {
      if (responseData?.data) {
        const data = responseData?.data;
        const count = responseData?.count;
        return { data, count };
      }
      return { data: [], count: 0 };
    }, [responseData]);

    const loadAdminProfiles = useCallback(
      async (skip: number, limit: number) => {
        setQueryParams({ skip, limit });
        return tableData;
      },
      [tableData],
    );

    const [selectedAdmin, setSelectedAdmin] = useState<{ id: string } | null>(
      null,
    );
    const [isDeletingAdmin, setIsDeletingAdmin] = useState(false);
    const deleteAdminModalRef = useRef<IModalRefProps>(null);
    const onDeleteAdmin = async () => {
      try {
        if (!selectedAdmin || !organizationSelectedId) return;
        setIsDeletingAdmin(true);
        await OrganizationService.deleteAdmin(
          organizationSelectedId,
          selectedAdmin.id,
        );
        toast.success('Administrator deleted');
      } catch (error) {
        toast.error('Error on trying to delete admin. Please try again later.');
      } finally {
        await refetchProfiles();
        setIsDeletingAdmin(false);
        closeDeleteAdminModal();
      }
    };
    const openDeleteAdminModal = (admin: string) => {
      setSelectedAdmin({ id: admin });
      deleteAdminModalRef.current?.open();
    };
    const closeDeleteAdminModal = () => {
      setSelectedAdmin(null);
      deleteAdminModalRef.current?.close();
    };

    return {
      areProfilesLoading,
      loadAdminProfiles,
      refetchProfiles,
      selectedAdmin,
      isDeletingAdmin,
      deleteAdminModalRef,
      onDeleteAdmin,
      openDeleteAdminModal,
      closeDeleteAdminModal,
    };
  };
