import { HStack, Text, VStack } from 'native-base';
import { useRef } from 'react';
import { Controller } from 'react-hook-form';
import { Pressable } from 'react-native';
import Modal from 'ui/components/Modals/Modal';

import { colors } from '../../../../theme/colors';
import Icon from '../../../Icon';
import { RadioSelectAppItem } from './RadioSelectAppItem';
import { RadioAppSectionProps } from './types';

export const RADIO_APP_SECTION_URL = '';

export const RadioAppSection = ({
  name,
  control,
  appsList,
  subtitle,
  title,
  isDisabled = false,
  onChangeValue,
}: RadioAppSectionProps) => {
  const modalRef = useRef();

  const openHelpModal = () => {
    (modalRef?.current as any).open();
  };

  return (
    <>
      <VStack
        w="full"
        space={4}
        borderWidth={1}
        borderColor={'gray.200'}
        backgroundColor={colors.singletons.lightText}
        borderRadius={16}
        p={4}
      >
        <HStack>
          <VStack space={2} flex={1}>
            {title && (
              <Text color="gray.600" fontSize="xl" fontWeight={500}>
                {title}
              </Text>
            )}
            {subtitle && (
              <Text color="gray.600" fontSize="sm" fontWeight="400">
                {subtitle}
              </Text>
            )}
          </VStack>

          <VStack p={2} h="fit-content">
            <Pressable onPress={openHelpModal}>
              <Icon
                icon="question"
                color={'primary.400'}
                size={5}
                enableAutoCustom
              />
            </Pressable>
          </VStack>
        </HStack>

        <Controller
          name={name}
          control={control}
          render={({ field: { value = [], onChange } }) => {
            return (
              <HStack flex={1} w="full" flexWrap="wrap">
                {appsList.map((app) => {
                  const isChecked = value.some((v: string) => v == app.value);

                  const handleItemChange = () => {
                    onChangeValue?.(app.value);
                    onChange([app.value]);
                  };

                  return (
                    <RadioSelectAppItem
                      key={app.name}
                      name={app.name}
                      value={app.value}
                      logo={app.logo || ''}
                      onChange={handleItemChange}
                      isDisabled={isDisabled}
                      isChecked={isChecked}
                    />
                  );
                })}
              </HStack>
            );
          }}
        />
      </VStack>
      <Modal
        ref={modalRef}
        headerText="Understanding host and shared apps"
        modalContentMaxW={{ base: '90vw', lg: '669' }}
        modalContentW={{ base: '90vw', lg: '669' }}
        _overlay={{ style: { position: 'fixed' } as any }}
      >
        <VStack py={4} px={1} pb={8}>
          <Text fontWeight={400} fontSize="md" color="gray.500">
            When you publish an activity, you need to select one of the apps in
            your current ecosystem to host it. Additionally, you can share the
            activity with other connected apps, allowing it to be visible and
            accessible across different apps.
          </Text>
        </VStack>
      </Modal>
    </>
  );
};
