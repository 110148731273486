import { Box, HStack, Input, Stack, Text, useClipboard } from 'native-base';
import React from 'react';
import { toast } from 'react-toastify';
import Button from 'ui/components/Button';
import { colors } from 'ui/theme/colors';

import { COMMON_MESSAGES } from '~/constants/messages.constants';

import { ICopyInviteLinkProps } from './types';

export const useCopyLinkController = () => {
  const { onCopy } = useClipboard();

  const handleCopyLink = (link: string) => {
    onCopy(link);
    toast.success(COMMON_MESSAGES.LINK_COPIED);
  };

  return { handleCopyLink };
};

export const CopyInviteLinkComponent = ({ link }: ICopyInviteLinkProps) => {
  const { handleCopyLink } = useCopyLinkController();

  if (!link) return null;

  return (
    <>
      <Box h="8" />
      <Text mb="3" fontSize="md" fontWeight="medium" color="gray.600">
        Invite by link
      </Text>
      <HStack alignItems="center" space={5} w="full">
        <Stack width="80%">
          <Input
            value={link}
            w="100%"
            style={{ textDecorationStyle: 'dashed' }}
            editable={false}
            isDisabled
          />
        </Stack>
        <Button
          flex="1"
          borderColor="primary.600"
          leftIconName="copy"
          leftIconColor={colors.green[600]}
          py="6"
          variant="outline"
          onPress={() => handleCopyLink(link)}
          minW="16%"
          maxW="16%"
        >
          <Text color="primary.600" fontSize="md" fontWeight="500">
            Copy
          </Text>
        </Button>
      </HStack>
    </>
  );
};
