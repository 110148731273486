import { useEffect, useMemo, useState } from 'react';
import { EEventApplicationType } from 'ui/enums';

import { useActivityFormCommonController } from '~/components/ActivityFormBuilderPresets/Controllers/useActivityFormCommonController';

import { AboutFormProps, AppsForSelector } from './types';
import { createFormSchema } from './utils';

export const useAboutFormController = ({
  handleContinue,
  handleBack,
  defaultValues,
  requirementOptions,
  causeOptions,
  isLoading,
  connectedApps,
  handleUpdateEventApplication,
}: AboutFormProps) => {
  const [appsForSelector, setAppsForSelector] = useState<AppsForSelector[]>([]);

  // Activity Forms Common Controller
  const commonController = useActivityFormCommonController({});
  const { formBuilderImperativeRef, selectedApp } = commonController;

  const appHasAllEventPermissions = useMemo(() => {
    if (!selectedApp) return true;
    return true;

    //FIXME: REMOVING PERMISSIONS FOR NOW
    // const eventPermissions =
    //   CreateEditActivityPermissions.BothEventsPermissions;

    // const appPermissions = getAvailableAppEventPermissions(
    //   selectedApp,
    //   eventPermissions,
    // );
    // const hasAllEventPermissions =
    //   appPermissions.length === eventPermissions.length;

    // if (!hasAllEventPermissions) {
    //   const applicationType =
    //     appPermissions[0].name === PartnerActivityPermissions.PublishEvents.name
    //       ? EEventApplicationType.Individual
    //       : EEventApplicationType.Team;

    //   handleUpdateEventApplication(applicationType as EEventApplicationType);
    // } else {
    //   handleUpdateEventApplication(undefined);
    // }

    // return hasAllEventPermissions;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedApp]);

  const eventApplicationType = useMemo(() => {
    return defaultValues?.eventApplicationType;
  }, [defaultValues?.eventApplicationType]);

  const formSchema = useMemo(() => {
    return createFormSchema({
      causeOptions,
      requirementOptions,
      handleBack,
      connectedApps: appsForSelector,
      showApplicationTypeCheckbox: appHasAllEventPermissions,
      eventApplicationType: eventApplicationType as EEventApplicationType,
      onChangeApplicationType: handleUpdateEventApplication,
      commonController,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    causeOptions,
    requirementOptions,
    handleBack,
    appsForSelector,
    appHasAllEventPermissions,
    eventApplicationType,
    handleUpdateEventApplication,
    commonController,
  ]);

  useEffect(() => {
    if (connectedApps && connectedApps?.length > 0) {
      const mappedApps = connectedApps.map((conenctedApp) => ({
        name: conenctedApp.name,
        value: conenctedApp._id,
        logo: conenctedApp.logo,
      }));

      setAppsForSelector(mappedApps);
    }
  }, [connectedApps]);

  return {
    causeOptions,
    formSchema,
    defaultValues,
    handleContinue,
    isLoading,
    appsForSelector,
    formBuilderImperativeRef,
  };
};
