import { Skeleton, VStack } from 'native-base';
import Chart from 'react-apexcharts';
import { colors } from 'ui/theme/colors';

import { InsightsGenericEmptyState } from '~/pages/Authenticated/Insights/components/EmptyState';

export type DonutChartDataParams = {
  series: number[];
  labels: string[];
};

type DonutChartProps = DonutChartDataParams & {
  isLoading?: boolean;
  title?: string;
  colors?: string[];
};

const defaultColors = [
  colors.tertiary[400],
  colors.orange[400],
  colors.primary[200],
  colors.tertiary[300],
];

const DonutChartSkeleton = () => (
  <>
    <Skeleton height="180px" width="180px" borderRadius="full" mx="auto" />
    <Skeleton.Text mt="4" px="4" lines={1} width={'25%'} />
    <Skeleton.Text mt="4" px="4" lines={1} width={'35%'} />
    <Skeleton.Text mt="4" px="4" lines={1} width={'40%'} />
    <Skeleton.Text mt="4" px="4" lines={1} width={'32%'} />
    <Skeleton.Text mt="4" px="4" lines={1} width={'55%'} />
  </>
);

const DonutChartEmptyState = () => (
  <VStack>
    <InsightsGenericEmptyState />
  </VStack>
);

export const DonutChart = ({
  labels,
  series,
  isLoading,
  colors = defaultColors,
  title = 'Total',
}: DonutChartProps) => {
  if (isLoading) {
    return <DonutChartSkeleton />;
  }

  if (!series.length) {
    return <DonutChartEmptyState />;
  }

  return (
    <Chart
      series={series}
      type="donut"
      width="100%"
      height={450}
      options={{
        colors,
        labels,
        dataLabels: {
          enabled: true,
          background: {
            enabled: false,
          },
          style: {
            fontSize: '10px',
            fontWeight: '700',
          },
          dropShadow: {
            enabled: true,
            opacity: 0.25,
            top: 1,
            blur: 1,
          },
        },
        plotOptions: {
          pie: {
            donut: {
              size: '55%',
              labels: {
                show: true,
                total: {
                  showAlways: true,
                  show: true,
                  label: title,
                },
              },
            },
          },
        },
        legend: {
          position: 'bottom',
          fontSize: '16px',
          markers: {
            shape: 'circle',
          },
        },
      }}
    />
  );
};
