import { TypeComputedProps as DataGridProps } from '@inovua/reactdatagrid-community/types';
import { useCallback, useRef, useState } from 'react';
import { toast } from 'react-toastify';
import { IFilter } from 'ui/components/DataTable/types';
import { IModalRefProps } from 'ui/components/Modals/Modal/types';
import { EEventApplicationType } from 'ui/enums';

import { COMMON_MESSAGES } from '~/constants/messages.constants';
import { useAppSelector } from '~/hooks/useAppSelector';
import { useHandleActivityApplications } from '~/hooks/useHandleActivityApplications';
import { useRedirectToViewDetailsPage } from '~/hooks/useRedirectToViewDetailsPage';
import { IApplicationListProps } from '~/pages/Authenticated/Activities/ApplicationsList/types';
import ActivityApplicationService from '~/services/resources/activityApplication';

import { useLoadEcosystemApplicationsAttendanceReport } from '../../../../hooks/reports/ecosystems/useLoadEcosystemApplicationsAttendanceReport';
import { useLoadEcosystemApplicationsStatusReport } from '../../../../hooks/reports/ecosystems/useLoadEcosystemApplicationsStatusReport';

export const useApplicationListController = ({
  setPendingApplications,
}: IApplicationListProps) => {
  const modalRef = useRef<IModalRefProps>(null);
  // Ref will be auto-assigned on onReady function of the table, as according to the docs, so we need let in this case and to type correctly
  // https://reactdatagrid.io/docs/api-reference#props-onReady
  // eslint-disable-next-line prefer-const
  let tableRef = useRef<DataGridProps | null>(null);
  const { selectedEcosystem } = useAppSelector(({ ecosystem }) => ecosystem);
  const { handleViewMemberApplication, handleViewTeamApplication } =
    useRedirectToViewDetailsPage();

  const {
    declineModalRef,
    setTableRef,
    handleAcceptApplication,
    handleBatchAcceptApplications,
    handleCloseDeclineModal,
    handleOpenDeclineModal,
    isHandlingApplications,
    isSelectedApplicationsEmpty,
    handleDeclineApplications,
    handleDeclineSingleApplication,
    onSelectionChange,
    selectedApplications,
    selectedApplicationsCount,
    setSelectedApplications,
    control,
  } = useHandleActivityApplications(modalRef, tableRef);

  const [isLoadingActivities, setIsLoadingActivities] =
    useState<boolean>(false);

  const [applicationsCount, setApplicationsCount] = useState(0);

  const {
    data: applicationStatusCount,
    isLoading: isLoadingApplicationStatusCount,
  } = useLoadEcosystemApplicationsStatusReport(selectedEcosystem?._id || '');

  const {
    data: applicationAttendanceCount,
    isLoading: isLoadingApplicationAttendanceCount,
  } = useLoadEcosystemApplicationsAttendanceReport(
    selectedEcosystem?._id || '',
  );

  const loadApplications = useCallback(
    async (skip: number, limit: number, filter: IFilter) => {
      try {
        let overrideFilter;

        if (filter && filter['userProfileSubDocument.userSummary.name']) {
          overrideFilter = {
            $or: [
              {
                'userProfileSubDocument.userSummary.name': {
                  $regex: filter['userProfileSubDocument.userSummary.name'].$or,
                  $options: 'i',
                },
              },
              {
                'teamSubDocument.name': {
                  $regex: filter['userProfileSubDocument.userSummary.name'].$or,
                  $options: 'i',
                },
              },
            ],
          };
          delete filter['userProfileSubDocument.userSummary.name'];
        }

        setIsLoadingActivities(true);
        const { data: responseActivityApplicationData } =
          await ActivityApplicationService.findAll({
            filter: JSON.stringify({
              ecosystem: selectedEcosystem?._id,
              $or: [
                {
                  applicationType: EEventApplicationType.Individual,
                  team: { $exists: false },
                },
                {
                  applicationType: EEventApplicationType.Team,
                  team: { $exists: true },
                },
              ],
              ...overrideFilter,
              ...filter,
            }),
            skip,
            limit,
            populate: JSON.stringify(['organization', 'userProfile', 'user']),
            sort: JSON.stringify({ applicationDate: -1 }),
          });

        const totalApplications = responseActivityApplicationData?.count;

        setApplicationsCount(totalApplications || 0);

        return {
          data: responseActivityApplicationData?.data,
          count: responseActivityApplicationData?.count,
        };
      } catch (error) {
        toast.error(COMMON_MESSAGES.ERROR_FETCHING_APPLICATIONS, {
          position: 'bottom-center',
        });
        return { data: [], count: 0 };
      } finally {
        setIsLoadingActivities(false);
      }
    },
    [selectedEcosystem],
  );

  return {
    loadApplications,
    isLoadingActivities,
    setSelectedApplications,
    onSelectionChange,
    selectedApplications,
    isSelectedApplicationsEmpty,
    selectedApplicationsCount,
    applicationsCount,
    handleAcceptApplication,
    handleDeclineSingleApplication,
    handleDeclineApplications,
    handleBatchAcceptApplications,
    declineModalRef,
    handleCloseDeclineModal,
    handleOpenDeclineModal,
    control,
    setTableRef,
    isHandlingApplications,
    applicationStatusCount,
    isLoadingApplicationStatusCount,
    applicationAttendanceCount,
    isLoadingApplicationAttendanceCount,
    handleViewMemberApplication,
    handleViewTeamApplication,
  };
};
