import { Image, Text, VStack } from 'native-base';
import Button from 'ui/components/Button';
import Icon from 'ui/components/Icon';
import CommonInputWrapper from 'ui/components/v2/CommonInputWrapper';
import { Loading } from 'ui/components/v2/Loading';
import { colors } from 'ui/theme/colors';

import { ImageTransformModal } from '../../Modals/ImageTransformModal';
import { useBaseImagePickerController } from './controller';
import { ImagePickerBaseProps } from './types';

export const ImagePickerBase = ({
  src,
  onChange,
  aspectRatio,
  circularCrop,
  cropModalTitle,
  label,
  helpText,
  isDisabled,
  isRequired,
  errorMessage,
  submitLabel = 'change image',
  buttonFontColor = colors.primary[300],
  buttonBackgroundColor = colors.primary[50],
}: ImagePickerBaseProps) => {
  const {
    imgSrc,
    modalRef,
    isLoading,
    fileInputRef,
    imagePreviewDimensions,
    handleSubmit,
    handlePress,
    handleInputFileChange,
  } = useBaseImagePickerController({ src, aspectRatio, onChange });

  return (
    <CommonInputWrapper
      label={label}
      helpText={helpText}
      isRequired={isRequired}
      isDisabled={isDisabled}
      errorMessage={errorMessage}
    >
      <VStack space={3} alignItems={'center'}>
        <VStack
          borderWidth={2}
          overflow={'hidden'}
          borderColor={'gray.200'}
          w={imagePreviewDimensions.width}
          h={imagePreviewDimensions.height}
          borderRadius={circularCrop ? 100 : 2}
        >
          {src ? (
            <Image key={src} src={src} w={'full'} h={'full'} />
          ) : (
            <VStack
              w="full"
              h="full"
              alignItems={'center'}
              justifyContent="center"
              space={2}
            >
              <Icon color={colors.gray[300]} size="24" icon="image" />
              <Text color="gray.300" fontSize={'xs'}>
                No image
              </Text>
            </VStack>
          )}

          {isLoading && <Loading float height={'100%'} />}
        </VStack>

        <Button
          leftIconName={'edit'}
          leftIconSize={16}
          leftIconColor={buttonFontColor}
          bgColor={buttonBackgroundColor}
          onPress={handlePress}
        >
          <Text w="full" color={buttonFontColor} fontSize="xs" fontWeight="500">
            {submitLabel}
          </Text>
        </Button>

        <input
          ref={fileInputRef}
          style={{ display: 'none' }}
          type="file"
          accept="image/*"
          onChange={handleInputFileChange}
        />

        <ImageTransformModal
          src={imgSrc}
          title={cropModalTitle}
          modalRef={modalRef}
          onSubmit={handleSubmit}
          aspectRatio={aspectRatio}
          circularCrop={circularCrop}
        />
      </VStack>
    </CommonInputWrapper>
  );
};

export default ImagePickerBase;
