import { useMemo } from 'react';
import { useQuery } from 'react-query';

import {
  InsightPageFilters,
  useInsightFilters,
} from '~/pages/Authenticated/Insights/useInsightFilters';
import { InsightCacheConfig } from '~/pages/Authenticated/Insights/utils';
import { INSIGHT_MOST_COMPLETED_ACTIVITIES_URL } from '~/services/resources/insights/constants';
import InsightService from '~/services/resources/insights/insightsService';
import { InsightByPeriodTypeQueryParams } from '~/services/resources/types';

const buildQueryKey = (filters: InsightPageFilters) => [
  INSIGHT_MOST_COMPLETED_ACTIVITIES_URL,
  {
    apps: filters.apps,
    ecosystem: filters.selectedEcosystemId,
    organization: filters.selectedOrganizationId,
    startDate: filters.from!,
    endDate: filters.to!,
    granularity: 'Daily',
    periodType: filters.periodType,
  },
];

export const useMostCompletedActivitiesController = () => {
  const { filters, hasFilterSetted: hasFilterSet } = useInsightFilters();

  const { data: usersActivityCompletionSummaries, isLoading } = useQuery({
    queryKey: buildQueryKey(filters),
    queryFn: async ({ queryKey }) => {
      const [, params] = queryKey;
      return InsightService.getMostCompletedActivitiesSummaries(
        params as InsightByPeriodTypeQueryParams,
      );
    },
    enabled: !!hasFilterSet,
    ...InsightCacheConfig,
  });

  const usersSummaries = useMemo(() => {
    if (!usersActivityCompletionSummaries?.length) return [];

    const result = usersActivityCompletionSummaries.map((summary) => {
      const organizations = summary?.organizations || [];

      const { organization } = summary.organizations.reduce(
        (topOrg, currentOrg) => {
          return currentOrg.total > topOrg.total ? currentOrg : topOrg;
        },
        organizations[0],
      );

      return {
        score: summary.total,
        id: summary?.userProfile?._id || '',
        name: summary?.userProfile?.name || '',
        profilePicture: summary?.userProfile?.profilePicture || '',
        organizationName: organization?.name || '',
        organizationLogo: organization?.logo || '',
      };
    });
    return result;
  }, [usersActivityCompletionSummaries]);

  return {
    isLoading,
    usersSummaries,
  };
};
