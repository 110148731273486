import { LogsSummaryTile } from 'ui/components/v2/Tiles/LogsSummaryTile';

import { NotificationCard } from '../../BaseNotificationCard';
import { useMeasurementCreatedNotificationCardController } from './controller';
import { MeasurementCreatedNotificationCardProps } from './types';

export const MeasurementCreatedNotificationCard = (
  props: MeasurementCreatedNotificationCardProps,
) => {
  const { activityOrganizationName, activityAppName, activityTitle } = props;
  const { titleNodes, date, profilePictureUrl, measurementSummary } =
    useMeasurementCreatedNotificationCardController(props);

  return (
    <NotificationCard
      authorImageUrl={profilePictureUrl}
      titleNodes={titleNodes}
      date={date}
    >
      <LogsSummaryTile
        appName={activityAppName}
        activityTitle={activityTitle}
        measurementSummaries={[measurementSummary]}
        organizationName={activityOrganizationName}
      />
    </NotificationCard>
  );
};
