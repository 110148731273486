import { ICreateActivityDTO, ICreateEventDTO } from '~/types/dtos';

export interface MultipleLocationsProps {
  handleContinue: (data: ICreateActivityDTO) => void;
  handleBack: () => void;
  defaultValues?: object;
  isLoading?: boolean;
  isTeamEvent?: boolean;
  isEditing?: boolean;
}

export enum LocationDataStatus {
  OK = 'OK',
  ZERO_RESULTS = 'ZERO_RESULTS',
}
export interface LocationData {
  returnedAddress: string;
  location:
    | {
        lat: number;
        lng: number;
      }
    | undefined;
  sentInput: string;
  status: LocationDataStatus;
  placeId?: string;
  _activityId?: string;
}
