import { Text, VStack } from 'native-base';

import { CorpSideCard } from '../BaseCorpSideCard';
import { CorpEmployeesSideCardProps } from './types';

export const CorpEmployeesSideCard = ({
  onInviteButtonPress,
  onViewInsightsButtonPress,
  employeesCount,
  isLoading,
}: CorpEmployeesSideCardProps) => {
  const labelText = `Total number of employees`;

  return (
    <CorpSideCard
      titleIcon="users"
      title="Number of Employees"
      primaryActionIcon="plus-circle"
      primaryActionLabel="Invite employee"
      onPrimaryAction={onInviteButtonPress}
      secondaryActionLabel="View more insights"
      onSecondaryAction={onViewInsightsButtonPress}
      isLoading={isLoading}
    >
      <VStack space={4}>
        <VStack>
          <Text fontSize={'2.25rem'} fontWeight={500} color={'gray.800'}>
            {employeesCount || ''}
          </Text>
          <Text fontSize={'sm'} fontWeight={400} color={'gray.500'}>
            {labelText}
          </Text>
        </VStack>
      </VStack>
    </CorpSideCard>
  );
};
