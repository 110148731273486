import { HStack, PresenceTransition, Stack, Text, VStack } from 'native-base';
import Button from 'ui/components/Button';
import DataTable from 'ui/components/DataTable';
import Tabs from 'ui/components/Tabs';

import { adminsTabElements } from '~/pages/Authenticated/Settings/AdminsSettings/adminsTabElements';
import { useAdminsSettinsController } from '~/pages/Authenticated/Settings/AdminsSettings/controller';
import { useAdminInvitesController } from '~/pages/Authenticated/Settings/AdminsSettings/controllers/adminInvitesController';
import { useAdminProfilesController } from '~/pages/Authenticated/Settings/AdminsSettings/controllers/adminProfilesController';
import { useInviteNewAdminController } from '~/pages/Authenticated/Settings/AdminsSettings/controllers/inviteNewAdminController';
import { DeleteAdminModal } from '~/pages/Authenticated/Settings/AdminsSettings/modals/DeleteAdminModal';
import { DeleteInviteModal } from '~/pages/Authenticated/Settings/AdminsSettings/modals/DeleteInviteModal';
import { InviteNewAdminModal } from '~/pages/Authenticated/Settings/AdminsSettings/modals/InviteNewAdminModal';
import { ResendInviteModal } from '~/pages/Authenticated/Settings/AdminsSettings/modals/ResendInviteModal';
import { tableAdminInvitesColumns } from '~/pages/Authenticated/Settings/AdminsSettings/tableAdminInvitesColumns';
import { adminProfilesColumns } from '~/pages/Authenticated/Settings/AdminsSettings/tableAdminProfilesColumns';
import { AdminsTabs } from '~/pages/Authenticated/Settings/AdminsSettings/types';
import { colors } from '~/theme/colors';

export const AdminsSettings = () => {
  const { currentTab, tabsRef, handleChangeTab } = useAdminsSettinsController();

  const adminProfilesController = useAdminProfilesController();
  const { openDeleteAdminModal, areProfilesLoading, loadAdminProfiles } =
    adminProfilesController;

  const adminInvitesController = useAdminInvitesController();
  const {
    openResendInviteModal,
    openDeleteInviteModal,
    loadAdminInvites,
    areInvitesLoading,
    refetchInvites,
  } = adminInvitesController;

  const inviteNewAdminController = useInviteNewAdminController({
    refetchInvites,
  });
  const { openInviteNewAdminModal } = inviteNewAdminController;

  return (
    <Stack mt={4} width="100%">
      <HStack justifyContent="space-between" width="100%">
        <Stack space="2">
          <HStack space={2} alignItems="center">
            <Text fontSize="xl" color="gray.600" fontWeight={500}>
              Administrators
            </Text>
          </HStack>
          <Text fontWeight={400} fontSize="sm" color="gray.600" mb="8">
            View and manage your organisation&apos;s administrators
          </Text>
        </Stack>
        <Stack>
          <Button
            variant="outline"
            leftIconColor={colors.primary[400]}
            leftIconSize="16px"
            leftIconName="plus-circle"
            padding={3}
            onPress={openInviteNewAdminModal}
          >
            New administrator
          </Button>
        </Stack>
      </HStack>
      <VStack w={'full'} width="100%" alignItems="center" mb={4}>
        <Tabs
          tabWith="100%"
          ref={tabsRef}
          onChangeTab={handleChangeTab}
          elements={adminsTabElements}
          defaultTabIndex={AdminsTabs.ADMINS}
          tabTextColor={colors.primary['600']}
          tabIndicatorColor={colors.primary['600']}
          tabfocusedTextColor={colors.primary['600']}
          isClickableTab
        />
      </VStack>
      <HStack>
        <PresenceTransition
          visible={currentTab === AdminsTabs.ADMINS}
          style={{
            position: 'absolute',
            width: '100%',
          }}
          initial={{ opacity: 0, scaleX: 0.9444 }}
          animate={{ opacity: 1, scaleX: 1, transition: { duration: 250 } }}
        >
          <DataTable
            sortable={false}
            loadData={loadAdminProfiles}
            loading={areProfilesLoading}
            columns={adminProfilesColumns({
              onDelete: openDeleteAdminModal,
            })}
          />
        </PresenceTransition>
        <PresenceTransition
          visible={currentTab === AdminsTabs.PENDING}
          style={{
            position: 'absolute',
            width: '100%',
          }}
          initial={{ opacity: 0, scaleX: 0.9444 }}
          animate={{ opacity: 1, scaleX: 1, transition: { duration: 250 } }}
        >
          <DataTable
            sortable={false}
            loadData={loadAdminInvites}
            loading={areInvitesLoading}
            columns={tableAdminInvitesColumns({
              onResendInvite: openResendInviteModal,
              onDelete: openDeleteInviteModal,
            })}
          />
        </PresenceTransition>
      </HStack>
      <InviteNewAdminModal {...inviteNewAdminController} />
      <DeleteAdminModal {...adminProfilesController} />
      <ResendInviteModal {...adminInvitesController} />
      <DeleteInviteModal {...adminInvitesController} />
    </Stack>
  );
};
