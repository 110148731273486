export const COGNITO_ERRORS_CODE = {
  NOT_AUTHORIZED_EXCEPTION: 'NotAuthorizedException',
  USER_NOT_CONFIRMED_EXCEPTION: 'UserNotConfirmedException',
};

export const COMMON_ERRORS = {
  ERROR_ON_LOAD: 'Error on load data',
  ERROR_ON_LOAD_REPORT: 'Error on loading report',
  ERROR_ON_LOAD_EVENT: 'Error on loading event',
  ERROR_ON_LOAD_EVENTS: 'Error on loading events',
  ERROR_ON_LOAD_VOLUNTEERS: 'Error on loading volunteers',
  ERROR_ON_LOAD_OPPORTUNITIES: 'Error on loading opportunities',
  ERROR_ON_LOAD_ACTIVITIES: 'Error loading this activity locations and dates!',
};
