import { api } from '~/services/api';
import { QueryParams } from '~/services/resources/types';
import { CreateEcosystemDTO } from '~/types/dtos';
import { IEcosystem } from '~/types/interfaces/ecosystem';

const MODULE_URL = '/ecosystem';

interface DisconnectAppsFromEcosystem {
  receiverEcosystem: string;
  appSharingIds: string[];
}

interface EcosystemConnectToAppPayload {
  ecosystem: string;
  app: string;
}

interface DisconnectEcosystem {
  ecosystemOne: string;
  ecosystemTwo: string;
}

interface DisconnectMember {
  ecosystemId: string;
  userId: string;
}

export class EcosystemService {
  static async updateOne(id: string, payload: Partial<CreateEcosystemDTO>) {
    return await api.put<IEcosystem>(`${MODULE_URL}/${id}`, payload);
  }

  static async connectEcosystemToApp(data: EcosystemConnectToAppPayload) {
    return await api.put(`${MODULE_URL}/apps/connect`, data);
  }

  static async createEcosystem(payload: CreateEcosystemDTO) {
    return await api.post<IEcosystem>(`${MODULE_URL}/`, payload);
  }

  static async findAll(params?: QueryParams) {
    const options = {
      params,
    };

    return await api.get(MODULE_URL, options);
  }

  static async disconnectAppsFromEcosystem(data: DisconnectAppsFromEcosystem) {
    return await api.put(`${MODULE_URL}/apps/disconnect`, data);
  }

  static async findPartners(ecosystemId: string, params?: QueryParams) {
    const options = {
      params,
    };
    return await api.get(`${MODULE_URL}/${ecosystemId}/partners`, options);
  }

  static async findChildPartners(ecosystemId: string, params?: QueryParams) {
    const options = {
      params,
    };
    return await api.get(
      `${MODULE_URL}/${ecosystemId}/partners/child`,
      options,
    );
  }

  static async findMembers(ecosystemId: string, params?: QueryParams) {
    const options = {
      params,
    };
    return await api.get(`${MODULE_URL}/${ecosystemId}/members`, options);
  }

  static async disconnect(data: DisconnectEcosystem) {
    return await api.put(`${MODULE_URL}/disconnect`, data);
  }

  static async disconnectMember(data: DisconnectMember) {
    return await api.put(
      `${MODULE_URL}/disconnect/${data.ecosystemId}/user/${data.userId}`,
      data,
    );
  }
}
