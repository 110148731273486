import { yupResolver } from '@hookform/resolvers/yup';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';

import { useLoadMember } from '~/hooks/useLoadMember';
import {
  ModalEditExternalIdFormValues,
  ModalEditExternalIdProps,
} from '~/pages/Authenticated/Members/Member/MembersList/components/ModalEditExternalId/types';
import UserProfileService from '~/services/resources/userProfile';

import { schemaValidation } from './constants';

export const useModalEditExternalIdController = ({
  modalRef,
  memberId,
  refetchMembers,
}: ModalEditExternalIdProps) => {
  const {
    control,
    handleSubmit,
    formState: { errors, isSubmitting },
    reset,
  } = useForm<ModalEditExternalIdFormValues>({
    resolver: yupResolver(schemaValidation),
  });

  const { isLoadingMember, member } = useLoadMember(memberId || '');

  const closeModal = () => modalRef?.current?.close();

  const onSubmit = handleSubmit(async (data) => {
    try {
      if (!memberId) return;
      await UserProfileService.updateExternalId(memberId, data);
      toast.success('External member ID updated successfully.');
      closeModal();
      refetchMembers();
    } catch (error) {
      console.error('Error saving the external member ID.', error);
      toast.error('Error saving the external member ID.');
    }
  });

  useEffect(() => {
    if (member?.externalId) {
      reset({ externalId: member.externalId });
    }
  }, [member, reset]);

  return {
    isLoadingMember,
    member,
    errors,
    control,
    isSubmitting,
    onSubmit,
    closeModal,
  };
};
