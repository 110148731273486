import { Alert, Button, Stack, Text } from 'native-base';

import { MultipleLocationsForm } from '~/components/MultipleLocationsForm';

import { MultipleLocationsProps } from './types';
import { useMultipleLocationsController } from './useMultipleLocationsController';

export const MultipleLocations = (props: MultipleLocationsProps) => {
  const {
    control,
    locationsGroupsFields,
    addLocationGroup,
    deleteLocationGroup,
    appendLocationGroup,
    isDisabledPublishButton,
    isTeamEvent,
    errors,
    handleSubmitForm,
    handleBack,
    isLoading,
    locationsByGroup,
    handleUpdateLocationGroup,
    handleDeleteAllLocationsFromGroup,
    handleDeleteLocationFromGroup,
    setValue,
  } = useMultipleLocationsController(props);

  return (
    <Stack>
      <Alert
        padding={3}
        backgroundColor="warning.100"
        status="warning"
        borderRadius="md"
        mt={6}
      >
        <Text>
          If there are duplicated addresses or postcodes, only one will be shown
          on the list and used to create an activity
        </Text>
      </Alert>
      <MultipleLocationsForm
        control={control as any}
        locationGroupsFields={locationsGroupsFields as any}
        appendLocationGroup={appendLocationGroup as any}
        addLocationGroup={addLocationGroup}
        removeLocationGroup={deleteLocationGroup}
        isEventActivity
        isTeamEvent={isTeamEvent}
        isEditing={true}
        errors={errors}
        locationTagsByGroupIndex={locationsByGroup}
        handleUpdateLocationGroup={handleUpdateLocationGroup}
        handleDeleteAllLocationsFromGroup={handleDeleteAllLocationsFromGroup}
        handleDeleteLocationFromGroup={handleDeleteLocationFromGroup}
        setValue={setValue}
      />

      <Stack
        my={4}
        w="100%"
        flexDirection="row"
        justifyContent="space-between"
        alignItems="center"
        space={3}
      >
        <Stack flex={1} ml={4}>
          <Button onPress={handleBack} flex={1} variant="ghost">
            <Text color="primary.600">Back</Text>
          </Button>
        </Stack>
        <Stack flex={1} ml={4}>
          <Button
            onPress={handleSubmitForm}
            flex={1}
            isDisabled={isDisabledPublishButton}
            isLoading={isLoading}
          >
            <Text color="white">Publish</Text>
          </Button>
        </Stack>
      </Stack>
    </Stack>
  );
};
