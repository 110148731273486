import { EActivityAttendanceTypes } from 'ui/enums';
import { object, TestContext } from 'yup';

import { volunteerNumberSchemaValidation } from '~/pages/Authenticated/Events/schemaValidations';
import { ICreateActivityDTO } from '~/types/dtos';
import { IAddress } from '~/types/interfaces/activity';
import Yup from '~/utils/validations/yup';

interface TestContextExtended {
  from: {
    value: ICreateActivityDTO;
  }[];
}

export const placesSchemaValidation = Yup.object().shape({
  address: Yup.object().test('attendanceType', async (value, context) => {
    const { from } = context as TestContext & TestContextExtended;
    const [, , parent] = from;

    const address = value;

    if (parent.value.attendanceType === EActivityAttendanceTypes.InPerson) {
      if (!address) return false;
      const isValid = await object()
        .test({
          name: 'address',
          test: (value: IAddress) => value && value.rawLocation !== null,
        })
        .validate(address);

      return !!isValid;
    } else {
      return true;
    }
  }),
  noVolunteerLimit: Yup.boolean(),
  // @mycatdoitbetter TODO: Change this value tu number when the Input component can receive a number
  volunteerNumber: volunteerNumberSchemaValidation,
});

export const inPersonSchemaValidation = Yup.object().shape({
  places: Yup.array().of(placesSchemaValidation),
});
