import { api } from '~/services/api';
import { QueryParams } from '~/services/resources/types';
import {
  InviteMemberDTO,
  PublicInviteDTO,
} from '~/types/interfaces/invite-member';

const MODULE_URL = '/invite-members';

export class InviteMemberService {
  static async findPendingInvites(
    organizationId: string,
    params?: QueryParams,
  ) {
    const options = {
      params,
    };
    return await api.get(
      `${MODULE_URL}/organization/${organizationId}/pending`,
      options,
    );
  }

  static async createInvite(data: InviteMemberDTO) {
    return await api.post(`${MODULE_URL}`, data);
  }

  static async createPublicInvite(data: PublicInviteDTO) {
    return await api.post(`${MODULE_URL}/public`, data);
  }

  static async resendInvite(inviteId: string) {
    return await api.post(`${MODULE_URL}/${inviteId}/resend`);
  }
}
