import { VStack } from 'native-base';
import { useEffect, useMemo } from 'react';
import { useQuery } from 'react-query';
import { defaultMeasurementUnits } from 'ui/utils/measurements/constants';
import { formatMeasurementAmount } from 'ui/utils/measurements/format';

import {
  PieChart,
  PieChartDataParams,
} from '~/pages/Authenticated/Insights/charts/PieChart';
import { InsightScaffoldChildrenReactComponent } from '~/pages/Authenticated/Insights/components/InsightScaffold';
import {
  InsightPageFilters,
  useInsightFilters,
} from '~/pages/Authenticated/Insights/useInsightFilters';
import { InsightCacheConfig } from '~/pages/Authenticated/Insights/utils';
import InsightMeasurementService from '~/services/resources/insightMeasurement';
import { BaseInsightQueryParams } from '~/services/resources/types';

const buildQueryKey = (filters: InsightPageFilters) => [
  InsightMeasurementService.FAVORITE_CAUSES_BY_HOUR_KEY,
  {
    apps: filters.apps,
    ecosystem: filters.selectedEcosystemId,
    organization: filters.selectedOrganizationId,
    startDate: filters.from!,
    endDate: filters.to!,
    granularity: 'Daily',
  } as BaseInsightQueryParams,
];

export const FavoriteCauseHoursTab: InsightScaffoldChildrenReactComponent = ({
  onSetChart,
}) => {
  const chartId = `chart-favorite-causes`;
  const { filters, hasFilterSetted } = useInsightFilters();

  const {
    data: response,
    isFetching,
    isLoading,
  } = useQuery({
    queryKey: buildQueryKey(filters),
    queryFn: async ({ queryKey }) => {
      const [, params] = queryKey;

      return await InsightMeasurementService.getFavoriteCausesByHour(
        params as BaseInsightQueryParams,
      );
    },
    onSuccess(data) {
      onSetChart(null, data);
    },
    enabled: hasFilterSetted,
    ...InsightCacheConfig,
  });

  useEffect(() => {
    onSetChart(
      null,
      response?.map(({ total, ...item }) => ({
        ...item,
        totalInSeconds: total,
        totalInHours: formatMeasurementAmount(
          total,
          defaultMeasurementUnits.hours,
        ),
      })) ?? [],
    );

    return () => {
      onSetChart(null, []);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [response]);

  const chartData = useMemo(() => {
    const data = (response ?? []).reduce(
      (acc, item) => {
        acc.series.push(item.total);
        acc.labels.push(item.causeOption);
        return acc;
      },
      {
        series: [],
        labels: [],
      } as PieChartDataParams,
    );

    return data;
  }, [response]);

  return (
    <VStack>
      <PieChart
        isLoading={isFetching || isLoading}
        chartId={chartId}
        series={chartData.series}
        labels={chartData.labels}
        tooltip={{
          y: {
            formatter(val) {
              return formatMeasurementAmount(
                val,
                defaultMeasurementUnits.hours,
              );
            },
          },
        }}
      />
    </VStack>
  );
};
