import { VStack } from 'native-base';
import { useEffect, useMemo, useState } from 'react';
import { useQuery } from 'react-query';
import { SimpleDataTable } from 'ui/components/DataTable';
import { MeasurementUnit } from 'ui/types/measurementUnit';
import { formatMeasurementAmount } from 'ui/utils/measurements/format';

import {
  InsightPageFilters,
  useInsightFilters,
} from '~/pages/Authenticated/Insights/useInsightFilters';
import { InsightCacheConfig } from '~/pages/Authenticated/Insights/utils';
import InsightMeasurementService, {
  GetMeasurementCharitiesHelpedReportQueryParams,
} from '~/services/resources/insightMeasurement';
import { CharitiesReportResponse } from '~/services/resources/types';

import {
  CharitiesHelpedFilters,
  ICharitiesHelpedFilters,
} from './CharitiesHelpedFilters';
import { applyLocalCharitiesHelpedFilters, tableColumns } from './utils';

const buildQueryKey = (
  filters: InsightPageFilters,
  charitiesFilter: Pick<ICharitiesHelpedFilters, 'option'>,
) => {
  const params: GetMeasurementCharitiesHelpedReportQueryParams = {
    apps: filters.apps,
    ecosystem: filters.selectedEcosystemId,
    organization: filters.selectedOrganizationId,
    startDate: filters.from!,
    endDate: filters.to!,
    periodType: filters.periodType,
    granularity: 'Daily',
  };
  if (charitiesFilter.option?.payload?.group) {
    params.measurementType = charitiesFilter.option?.payload?.group;
  }

  if (charitiesFilter.option?.value) {
    params.measurementUnit = charitiesFilter.option?.value;
  }

  return [
    InsightMeasurementService.MEASUREMENT_CHARITIES_HELPED_REPORT_KEY,
    params,
  ];
};

type CharitiesHelpedLogsProps = {
  onSetPeriodData: (data?: CharitiesReportResponse) => void;
};

export const CharitiesHelpedLogs = ({
  onSetPeriodData,
}: CharitiesHelpedLogsProps) => {
  const { filters, hasFilterSetted } = useInsightFilters();
  const [measurementUnits, setMeasurementUnits] = useState<MeasurementUnit[]>(
    [],
  );
  const [charitiesFilter, setCharitiesFilter] =
    useState<ICharitiesHelpedFilters>({
      option: null,
      text: '',
    });

  const {
    data: response,
    isFetching,
    isLoading,
  } = useQuery({
    queryKey: buildQueryKey(filters, charitiesFilter),
    queryFn: async ({ queryKey }) => {
      const [, params] = queryKey;

      return await InsightMeasurementService.getMeasurementCharitiesHelpedReport(
        params as GetMeasurementCharitiesHelpedReportQueryParams,
      );
    },
    enabled: hasFilterSetted,
    ...InsightCacheConfig,
  });

  useEffect(() => {
    if (response) {
      onSetPeriodData(response);
    }
    return () => {
      onSetPeriodData();
    };
  }, [response]);

  useEffect(() => {
    if (charitiesFilter.option) return;

    const _measurementUnits = (response?.currentPeriodInsights ?? []).flatMap(
      (item) =>
        item.measurementUnitSummary ? [item.measurementUnitSummary] : [],
    );

    setMeasurementUnits(_measurementUnits);
  }, [charitiesFilter.option, response]);

  const dataSource = useMemo(() => {
    const _dataSource = (response?.currentPeriodInsights ?? []).map((item) => ({
      name: item.activityOwnerOrganizationSummary.name,
      logo: item.activityOwnerOrganizationSummary.logo,
      value: formatMeasurementAmount(item.total, item.measurementUnitSummary),
    }));

    if (charitiesFilter.text) {
      return applyLocalCharitiesHelpedFilters(_dataSource, {
        text: charitiesFilter.text,
      });
    }

    return _dataSource;
  }, [charitiesFilter.text, response]);

  return (
    <VStack space={4}>
      <CharitiesHelpedFilters
        measurementUnits={measurementUnits}
        onFilterChange={setCharitiesFilter}
      />
      <SimpleDataTable
        showHeader={false}
        style={{ borderWidth: 0, borderRadius: 0 }}
        columns={tableColumns}
        dataSource={dataSource}
        isLoading={isLoading || isFetching}
      />
    </VStack>
  );
};
