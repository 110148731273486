import { Avatar, HStack, Text } from 'native-base';

import { TableCellWithAvatarProps } from './types';

export const TableCellWithAvatar = ({
  name = '',
  avatar,
  isOrganization,
  size,
}: TableCellWithAvatarProps) => {
  return (
    <HStack space={2} alignItems="center">
      <Avatar
        source={{
          uri: avatar,
        }}
        height={`${size || 32}px`}
        width={`${size || 32}px`}
        borderRadius={isOrganization ? 'sm' : 'full'}
        borderColor="gray.300"
        bg={isOrganization ? 'primary.500' : 'gray.500'}
      >
        {name.charAt(0)}
      </Avatar>
      <Text color="gray.600" numberOfLines={2}>
        {name}
      </Text>
    </HStack>
  );
};
