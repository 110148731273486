import { MeasurementCategory } from 'ui/enums';
import { sanitizeQueryParams } from 'ui/utils/queryParams';

import { api } from '~/services/api';
import { INSIGHT_MEASUREMENT_BASE_URL } from '~/services/resources/insights/constants';
import {
  BaseInsightQueryParams,
  InsightMeasurementGroupedBy,
} from '~/services/resources/types';

import { GetDepartmentsMeasurementMetricsResponse } from './types';

export default class MeasurementInsightsService {
  static async getDepartmentsMeasurementMetrics(props: BaseInsightQueryParams) {
    const { organization, ...queryParams } = props;
    const url = INSIGHT_MEASUREMENT_BASE_URL.replace(
      ':organizationId',
      organization,
    );

    const response = await api.get<GetDepartmentsMeasurementMetricsResponse[]>(
      url,
      {
        params: sanitizeQueryParams({
          ...queryParams,
          groupBy: [InsightMeasurementGroupedBy.UserProfileDepartment],
          measurementType: MeasurementCategory.Time,
        }),
      },
    );

    return response.data ?? [];
  }
}
