import { Stack } from 'native-base';

import Page403 from '~/components/Page403';
import { Permissions } from '~/components/Permissions';
import { CreateEditActivityPermissions } from '~/constants/permissions.org.constants';

import { EventForm } from './EventForm';

export const AddEvent = () => {
  return (
    // <Permissions.ValidateAll
    //   requiredPermissions={CreateEditActivityPermissions.BothEventsPermissions}
    //   fallbackComponent={<Page403 />}
    // >
    <Stack>
      <EventForm />
    </Stack>
    // </Permissions.ValidateAll>
  );
};
