import './App.css';
import './config/amplify';
import './config/sentry';
import './config/firebase';

import Providers from '~/Providers';
import { Routes } from '~/routes';

// Root component of org-admin
// Admin pannel for Do IT's organisations
const App = () => {
  return (
    <Providers>
      <Routes />
    </Providers>
  );
};

export default App;
