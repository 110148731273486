import { Box, Image, Stack, Text, VStack } from 'native-base';
import Button from 'ui/components/Button';
import Input from 'ui/components/Input';
import { transformInputValueToLowerCase } from 'ui/utils/formatter';

import loginBackground from '~/assets/images/login-background.svg';
import { HelpCentreRedirectLink } from '~/components/HelpCentre';

import { useForgotPasswordController } from './controller';
import { containerFlexDirection, styles } from './styles';

export const ForgotPassword = () => {
  const { control, onSubmit, errors, disableSubmit, isSubmitting } =
    useForgotPasswordController();

  return (
    <Box
      flex={1}
      flexDirection={containerFlexDirection}
      minHeight="100vh"
      width="100%"
      alignSelf="center"
      background="singletons.mediumWhite"
    >
      <Stack
        position="relative"
        overflow="hidden"
        justifyContent="center"
        alignItems="center"
        w={styles.imageStack.w}
      >
        <Image
          src={loginBackground}
          width="100%"
          height="100%"
          alt="The Big Help out"
        />
      </Stack>

      <Stack
        w={styles.pageStack.w}
        h="full"
        my="auto"
        px={styles.pageStack.px}
        justifyContent={'center'}
      >
        <VStack maxWidth="550px">
          <Stack paddingTop={12}>
            <HelpCentreRedirectLink />
          </Stack>
          <Box mb={44}>
            <Text
              textAlign="initial"
              fontSize="xl"
              fontWeight={500}
              color="gray.900"
            >
              Did you forget your password? <br />
              We help you to recover! <br />
              <Text fontSize="sm" fontWeight={400} color="gray.900">
                We&apos;ll send you a code to change your password.
              </Text>
            </Text>
          </Box>
          <Stack width="full">
            <Input
              name="email"
              control={control}
              placeholder="Type your email"
              rightIconName="mail"
              isInvalid
              label="Email"
              errorMessage={errors.email?.message}
              iconErrorMessage="info"
              p={4}
              isFullWidth
              backgroundColor="white"
              borderColor="muted.200"
              onChangeTransform={transformInputValueToLowerCase}
            />
            <Box mb={4} />
          </Stack>
          <Stack mt={4} mb={6}>
            <Button
              onPress={onSubmit}
              color="primary.600"
              p={6}
              isDisabled={disableSubmit}
              isLoading={isSubmitting}
            >
              <Text fontSize="medium" color="white" fontWeight="medium">
                Send password recovery code
              </Text>
            </Button>
          </Stack>
        </VStack>
      </Stack>
    </Box>
  );
};
