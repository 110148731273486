import { LogTileMeasurementSummary } from 'ui/components/v2/Tiles/LogsSummaryTile/types';
import {
  FeedNotification,
  FeedNotificationActivityApplicationCanceledPayload,
  FeedNotificationActivityApplicationCreatedPayload,
  FeedNotificationActivityCreatedPayload,
  FeedNotificationBulkActivitiesCreatedPayload,
  FeedNotificationMeasurementCreatedPayload,
  FeedNotificationMeasurementDeletedPayload,
  FeedNotificationMultiplesMeasurementCreatedPayload,
  FeedNotificationPayload,
  FeedNotificationType,
  FeedNotificationUserProfileCreatedPayload,
} from 'ui/types/interfaces/feed-notification';

import { NotificationCard } from '~/components/Cards/NotificationCard';

export type UpdatesFeedItemProps = {
  data: FeedNotification<FeedNotificationPayload>;
};

export const UpdatesFeedItem = ({ data }: UpdatesFeedItemProps) => {
  if (!data || !data.type) return null;

  const { authorAvatarUrl, authorName } = data.payload;
  const notificationDate = new Date(data.notificationDate);

  const renderUserProfileCreatedNotification = (
    feedNotification: FeedNotification<FeedNotificationUserProfileCreatedPayload>,
  ) => {
    return (
      <NotificationCard.UserProfileCreated
        date={notificationDate}
        userName={authorName || ''}
        profilePictureUrl={authorAvatarUrl || ''}
        userProfileId={feedNotification.userProfileId || ''}
        ecosystemName={feedNotification.payload?.ecosystemName || ''}
      />
    );
  };

  const renderActivityApplicationCreatedNotification = (
    feedNotification: FeedNotification<FeedNotificationActivityApplicationCreatedPayload>,
  ) => {
    const { title, type, activityDefinitionId } =
      feedNotification.payload?.activitySummary;

    return (
      <NotificationCard.ActivityApplicationCreated
        date={notificationDate}
        userName={authorName || ''}
        profilePictureUrl={authorAvatarUrl || ''}
        userProfileId={feedNotification.userProfileId || ''}
        activityDefinitionId={activityDefinitionId || ''}
        activityTitle={title || ''}
        activityType={type}
      />
    );
  };

  const renderActivityApplicationCanceledNotification = (
    feedNotification: FeedNotification<FeedNotificationActivityApplicationCanceledPayload>,
  ) => {
    const { title, type, activityDefinitionId } =
      feedNotification.payload?.activitySummary;

    return (
      <NotificationCard.ActivityApplicationCanceled
        date={notificationDate}
        userName={authorName || ''}
        profilePictureUrl={authorAvatarUrl || ''}
        userProfileId={feedNotification.userProfileId || ''}
        activityDefinitionId={activityDefinitionId || ''}
        activityTitle={title || ''}
        activityType={type}
      />
    );
  };

  const renderMeasurementCreatedNotification = (
    feedNotification: FeedNotification<FeedNotificationMeasurementCreatedPayload>,
  ) => {
    const {
      isExternal,
      activityType,
      activityTitle,
      activitySummary,
      measurementSummary,
      activityOrganizationName,
    } = feedNotification.payload;

    const activityAppName = isExternal
      ? 'Off-platform'
      : activitySummary?.appName || '';

    return (
      <NotificationCard.MeasurementCreated
        date={notificationDate}
        userName={authorName || ''}
        profilePictureUrl={authorAvatarUrl || ''}
        userProfileId={feedNotification.userProfileId || ''}
        activityType={activityType}
        activityTitle={activityTitle}
        activityOrganizationName={activityOrganizationName || ''}
        activityAppName={activityAppName}
        measurementUnit={measurementSummary.measurementUnit}
        measurementsTarget={measurementSummary?.targetAmount || 0}
        measurementsAmount={measurementSummary?.amount || 0}
      />
    );
  };

  const renderMeasurementDeletedNotification = (
    feedNotification: FeedNotification<FeedNotificationMeasurementDeletedPayload>,
  ) => {
    const {
      activityTitle,
      activityType,
      activityOrganizationName,
      activitySummary,
      measurementSummary,
      isExternal,
    } = feedNotification.payload;

    const activityAppName = isExternal
      ? 'Off-platform'
      : activitySummary?.appName || '';

    return (
      <NotificationCard.MeasurementDeleted
        date={notificationDate}
        userName={authorName || ''}
        profilePictureUrl={authorAvatarUrl || ''}
        userProfileId={feedNotification.userProfileId || ''}
        activityType={activityType}
        activityTitle={activityTitle}
        activityOrganizationName={activityOrganizationName || ''}
        activityAppName={activityAppName}
        measurementUnit={measurementSummary.measurementUnit}
        measurementsAmount={measurementSummary?.amount || 0}
      />
    );
  };

  const renderMultipleMeasurementsCreatedNotification = (
    feedNotification: FeedNotification<FeedNotificationMultiplesMeasurementCreatedPayload>,
  ) => {
    const {
      activityTitle,
      activityType,
      activityOrganizationName,
      activitySummary,
      measurementSummaries,
      isExternal,
    } = feedNotification.payload;

    const measurements: LogTileMeasurementSummary[] = measurementSummaries.map(
      (measurementSummary) => {
        return {
          amount: measurementSummary?.amount || 0,
          target: measurementSummary?.targetAmount || 0,
          measurementUnit: measurementSummary.measurementUnit,
        };
      },
    );

    const activityAppName = isExternal
      ? 'Off-platform'
      : activitySummary?.appName || '';

    return (
      <NotificationCard.MultipleMeasurementsCreated
        date={notificationDate}
        userName={authorName || ''}
        profilePictureUrl={authorAvatarUrl || ''}
        userProfileId={feedNotification.userProfileId || ''}
        activityType={activityType}
        activityTitle={activityTitle}
        activityOrganizationName={activityOrganizationName || ''}
        isExternal={isExternal}
        activityAppName={activityAppName}
        measurementSummaries={measurements}
      />
    );
  };

  const renderBulkActivitiesCreatedNotification = (
    feedNotification: FeedNotification<FeedNotificationBulkActivitiesCreatedPayload>,
  ) => {
    return (
      <NotificationCard.BulkActivitiesCreated
        date={notificationDate}
        organizationName={authorName || ''}
        organizationLogoUrl={authorAvatarUrl || ''}
        organizationId={feedNotification.organizationId}
        activitiesAppName={feedNotification.payload.appName}
        activitiesCount={feedNotification.payload?.activitiesCount || 0}
      />
    );
  };

  const renderActivityCreatedNotification = (
    feedNotification: FeedNotification<FeedNotificationActivityCreatedPayload>,
  ) => {
    const { activitySummary } = feedNotification.payload;

    return (
      <NotificationCard.ActivityCreated
        date={notificationDate}
        organizationName={authorName || ''}
        organizationLogoUrl={authorAvatarUrl || ''}
        organizationId={feedNotification.organizationId}
        activityStartDate={
          activitySummary.startDate
            ? new Date(activitySummary.startDate)
            : undefined
        }
        activityType={activitySummary.type}
        activityTitle={activitySummary.title}
        activityAppName={activitySummary.appName}
        activityDefinitionId={activitySummary.activityDefinitionId}
        activityOrganizationName={activitySummary.organizationName}
        activityOrganizationLogoUrl={authorAvatarUrl}
        activityCoverImageUrl={activitySummary.coverImageUrl}
        activityMeasurementUnit={activitySummary.measurementUnit}
        activityTargetAmount={activitySummary.targetAmount}
      />
    );
  };

  switch (data.type) {
    /* -- User Profile -- */
    case FeedNotificationType.UserProfileCreated: {
      return renderUserProfileCreatedNotification(
        data as FeedNotification<FeedNotificationUserProfileCreatedPayload>,
      );
    }

    /* -- Activity Application -- */
    case FeedNotificationType.ActivityApplicationCreated: {
      return renderActivityApplicationCreatedNotification(
        data as FeedNotification<FeedNotificationActivityApplicationCreatedPayload>,
      );
    }

    case FeedNotificationType.ActivityApplicationCanceled: {
      return renderActivityApplicationCanceledNotification(
        data as FeedNotification<FeedNotificationActivityApplicationCanceledPayload>,
      );
    }

    /* -- Measurements --  */
    case FeedNotificationType.MeasurementCreated: {
      return renderMeasurementCreatedNotification(
        data as FeedNotification<FeedNotificationMeasurementCreatedPayload>,
      );
    }

    case FeedNotificationType.MeasurementDeleted: {
      return renderMeasurementDeletedNotification(
        data as FeedNotification<FeedNotificationMeasurementDeletedPayload>,
      );
    }

    /* -- Multiple Measurements -- */
    case FeedNotificationType.MultipleMeasurementsCreated: {
      return renderMultipleMeasurementsCreatedNotification(
        data as FeedNotification<FeedNotificationMultiplesMeasurementCreatedPayload>,
      );
    }

    /* -- Multiple Measurements -- */
    case FeedNotificationType.BulkActivitiesCreated: {
      return renderBulkActivitiesCreatedNotification(
        data as FeedNotification<FeedNotificationBulkActivitiesCreatedPayload>,
      );
    }

    /* -- Activity Created --  */
    case FeedNotificationType.ActivityCreated: {
      return renderActivityCreatedNotification(
        data as FeedNotification<FeedNotificationActivityCreatedPayload>,
      );
    }

    default:
      return null;
  }
};
