import { IconEnum } from 'ui/components/Icon';
import { TagIcon } from 'ui/components/Tags/Tag/types';

import { EFileImportStatus } from '~/pages/Authenticated/BulkUploads/types';
import { colors } from '~/theme/colors';

export type StatusStyleSheet = {
  backgroudColor?: string;
  textColor?: string;
  icon?: TagIcon | IconEnum | undefined;
};

const DEFAULT_VALUES = {
  backgroudColor: colors.gray['200'],
  textColor: colors.singletons.darkText,
  icon: 'clock' as TagIcon | IconEnum | undefined,
};

export const FileImportStatusMap = new Map<
  EFileImportStatus,
  Partial<StatusStyleSheet>
>([
  [EFileImportStatus.Queued, { backgroudColor: colors.gray['200'] }],
  [EFileImportStatus.Processing, { backgroudColor: colors.yellow['200'] }],
  [
    EFileImportStatus.Completed,
    { icon: 'check-circle', backgroudColor: colors.green['200'] },
  ],
  [
    EFileImportStatus.Failed,
    {
      icon: 'alert-triangle',
      backgroudColor: colors.red['200'],
      textColor: colors.red['800'],
    },
  ],
]);

export const getTabStyle = (status: EFileImportStatus): StatusStyleSheet => {
  const preset = FileImportStatusMap.get(status) ?? {};
  return { ...DEFAULT_VALUES, ...preset };
};
