import { Stack, Text } from 'native-base';
import Button from 'ui/components/Button';

import { SingleLocationOngoingForm } from '../components/SingleLocationOngoingForm';
import { SingleLocationOngoingProps } from './types';
import { useSingleLocationOngoingController } from './useSingleLocationOngoingController';

export const SingleLocationOngoing = (props: SingleLocationOngoingProps) => {
  const {
    control,
    isDisabledPublishButton,
    isLoading,
    isEditing,
    handleSubmitForm,
    handleBack,
    errors,
    updateNoVolunteerLimitValue,
  } = useSingleLocationOngoingController(props);

  return (
    <Stack>
      <SingleLocationOngoingForm
        errors={errors}
        control={control}
        updateNoVolunteerLimitValue={updateNoVolunteerLimitValue}
        isEditing={isEditing}
      />
      <Stack
        my={4}
        w="100%"
        flexDirection="row"
        justifyContent="space-between"
        alignItems="center"
        space={3}
      >
        <Stack flex={1} ml={4}>
          <Button onPress={handleBack} flex={1} variant="ghost">
            <Text color="primary.600">Back</Text>
          </Button>
        </Stack>
        <Stack flex={1} ml={4}>
          <Button
            onPress={handleSubmitForm}
            flex={1}
            isDisabled={isDisabledPublishButton}
            isLoading={isLoading}
          >
            <Text color="white">Publish</Text>
          </Button>
        </Stack>
      </Stack>
    </Stack>
  );
};
