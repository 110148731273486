import { HStack, Stack, Text } from 'native-base';
import Button from 'ui/components/Button';
import Icon from 'ui/components/Icon';

import { colors } from '~/theme/colors';

import { styles } from './styles';
import { DetailsCardProps } from './types';

export const DetailsCard = ({
  icon,
  onCopy,
  title,
  value,
}: DetailsCardProps) => {
  return (
    <HStack justifyContent="space-between">
      <Stack space={8} maxWidth="320px" width="100%">
        <HStack justifyContent="space-between" alignItems="center">
          <Stack space={2}>
            <Text fontSize="sm" fontWeight="500" color="gray.600">
              {title}
            </Text>
            <HStack space={2} alignItems="center">
              {icon ? (
                <Icon icon={icon} color={colors.gray[400]} size={16} />
              ) : null}

              {typeof value === 'string' ? (
                <Text
                  fontSize="sm"
                  fontWeight="400"
                  color={colors.singletons.darkText}
                >
                  {value}
                </Text>
              ) : (
                value
              )}
            </HStack>
          </Stack>
          {onCopy ? (
            <Button
              leftIconName="copy"
              leftIconColor={colors.primary[300]}
              leftIconSize={16}
              minW="76px"
              maxW="76px"
              bgColor="primary.50"
              _text={styles.button._text}
              onPress={onCopy}
            >
              Copy
            </Button>
          ) : null}
        </HStack>
      </Stack>
    </HStack>
  );
};
