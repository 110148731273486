import { useQuery } from '~/hooks/useQuery';
import { PopulatedNetworkAppSharedToOrganization } from '~/types/interfaces/networkAppSharing';

export const useLoadAppsSharedToOrganization = (organizationId?: string) => {
  const {
    data: appsConnectedToOrganization,
    isLoading: isLoadingAppsConnectedToOrganization,
    refetch: refetchAppsConnectedToOrganization,
  } = useQuery<PopulatedNetworkAppSharedToOrganization[]>(
    `/network-app-sharing/organization/${organizationId}/only-shared`,
    {
      queryOptions: {
        enabled: !!organizationId,
      },
      key: `only-network-app-shared-to-organization-${organizationId}`,
    },
  );

  return {
    appsConnectedToOrganization,
    isLoadingAppsConnectedToOrganization,
    refetchAppsConnectedToOrganization,
  };
};
