import { AxiosError } from 'axios';
import { useState } from 'react';
import { toast } from 'react-toastify';
import { IModalRefProps } from 'ui/components/Modals/Modal/types';

import { ORGANIZATION_DEPARTMENT_MESSAGES } from '~/constants/messages.constants';
import { useAppSelector } from '~/hooks/useAppSelector';
import OrganizationDepartmentsService from '~/services/resources/organization/departments/departments.service';

import { DeleteDepartmentModalProps } from './types';

export const useDeleteDepartmentModalController = (
  { onSuccess, department }: DeleteDepartmentModalProps,
  ref: React.MutableRefObject<IModalRefProps>,
) => {
  // Providers
  const { selectedEcosystem } = useAppSelector(({ ecosystem }) => ecosystem);

  // States
  const [isLoading, setIsLoading] = useState(false);
  const [tabIndex, setTabIndex] = useState(0);

  // Handlers
  const handleCancel = () => {
    setTabIndex(0);
    ref?.current?.close();
  };

  const handleSubmit = async (force?: boolean) => {
    if (!department) return;
    setIsLoading(true);
    try {
      await OrganizationDepartmentsService.delete(
        selectedEcosystem?.organization || '',
        department._id,
        force,
      );
      toast.success(ORGANIZATION_DEPARTMENT_MESSAGES.DELETE_SUCCESS);
      setTabIndex(0);
      onSuccess?.();
      ref?.current?.close();
    } catch (error: unknown) {
      if (error instanceof AxiosError) {
        const errorMessage = error?.response?.data?.message || '';
        if (errorMessage === 'USER_PROFILES_RELATED') {
          toast.warning(
            ORGANIZATION_DEPARTMENT_MESSAGES.DELETE_USERS_RELATED_WARNING,
          );
          setTabIndex(1);
          return;
        }
      }

      toast.error(ORGANIZATION_DEPARTMENT_MESSAGES.DELETE_SUCCESS);
    } finally {
      setIsLoading(false);
    }
  };

  return { handleCancel, handleSubmit, isLoading, tabIndex };
};
