import { yupResolver } from '@hookform/resolvers/yup';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { IModalRefProps } from 'ui/components/Modals/Modal/types';

import {
  COMMON_MESSAGES,
  SIGN_UP_MESSAGES,
} from '~/constants/messages.constants';
import { PAGES } from '~/constants/pages.constants';
import { useAppDispatch } from '~/hooks/useAppDispatch';
import { useLoadOrganizationPublic } from '~/hooks/useLoadingOrganizationPublic';
import { useLoadPartnerInviteCodePublic } from '~/hooks/useLoadPartnerInviteCodePublic';
import { useRouter } from '~/hooks/useRouter';
import { usePartnerInviteFromUrl } from '~/pages/Common/JoinAsPartner/hooks/usePartnerInviteFromUrl';
import { SignUpFormFields } from '~/pages/Common/JoinAsPartnerSignup/types';
import { defaultCoolDownTime } from '~/pages/Unauthenticated/SignUpPage/ConfirmEmailPage/constants';
import AuthService from '~/services/resources/auth';
import UserService from '~/services/resources/user';
import { authSliceActions } from '~/store/slices/auth';
import {
  CognitoError,
  CognitoErrorCode,
  CognitoErrorCodeMessages,
} from '~/types/interfaces/cognito-errors';

import { defaultValues, schemaValidation } from './constants';

export const useJoinAsPartnerSignUpController = () => {
  const [currentPage, setCurrentPage] = useState(0);
  const [isSubmittingResendCode, setIsSubmittingResendCode] = useState(false);
  const [isSubmittingVerifyCode, setIsSubmittingVerifyCode] = useState(false);
  const [isSubmittingCreateAccount, setIsSubmittingCreateAccount] =
    useState(false);
  const [isResendCodeDisabled, setIsResendCodeDisabled] = useState(false);
  const [coolDownTime, setCoolDownTime] = useState(defaultCoolDownTime);

  const modalTermsOfServiceRef = useRef<IModalRefProps>(null);
  const modalPrivacyPolicyRef = useRef<IModalRefProps>(null);

  const { goToRoute, location } = useRouter();
  const { inviteURL, code } = usePartnerInviteFromUrl();

  const dispatch = useAppDispatch();

  const {
    control,
    handleSubmit,
    formState: { errors, isValid, isDirty },
    getValues,
  } = useForm<SignUpFormFields>({
    resolver: yupResolver(schemaValidation?.[currentPage]),
    defaultValues,
    mode: 'all',
  });

  const { data: inviteCode, isLoading: isLoadingInviteCode } =
    useLoadPartnerInviteCodePublic(code);

  const { data: organization, isLoading: isLoadingOrganization } =
    useLoadOrganizationPublic(inviteCode?.sharerOrganization || '');

  const isLoading = isLoadingInviteCode || isLoadingOrganization;

  const inviteEcosystem = useMemo(() => {
    if (!inviteCode) return;
    return inviteCode?.configurations[0].ecosystemId;
  }, [inviteCode]);

  const isDisabled = !isValid || !isDirty || isSubmittingCreateAccount;

  const nextPage = () => {
    if (currentPage === 1) return;
    setCurrentPage((prev) => prev + 1);
  };

  const email = getValues('email');

  const handleCreateAccount = handleSubmit(async (data) => {
    try {
      setIsSubmittingCreateAccount(true);
      await AuthService.signUpWithEmailAndPassword({
        email: data.email,
        password: data.password.trim(),
        attributes: {
          name: `${data.firstName} ${data.lastName}`,
          phone_number: data.phoneNumber.replace(/\s/g, ''),
        },
      });
      nextPage();
    } catch (err) {
      const error = err as CognitoError;
      if (error.code === CognitoErrorCode.UsernameExistsException) {
        toast.info(CognitoErrorCodeMessages[error.code]);
        goToRoute(PAGES.ROOT);
        return;
      }
      if (error.code === CognitoErrorCode.NotAuthorized) {
        toast.info(CognitoErrorCodeMessages.UsernameExistsException);
        goToRoute(PAGES.ROOT);
        return;
      }
      toast.error(SIGN_UP_MESSAGES.CREATE_ACCOUNT_ERROR);
    } finally {
      setIsSubmittingCreateAccount(false);
    }
  });

  const handleResendCode = async () => {
    try {
      const email = getValues('email');
      if (isResendCodeDisabled) return;
      setIsSubmittingResendCode(true);
      await AuthService.resendConfirmationCode({ email });
      toast.success(COMMON_MESSAGES.ACCESS_CODE_SENT);
      setIsResendCodeDisabled(true);
    } catch (error) {
      toast.error(COMMON_MESSAGES.UNABLE_SEND_ACCESS_CODE);
      setIsResendCodeDisabled(false);
    } finally {
      setIsSubmittingResendCode(false);
    }
  };

  const onVerifyCode = handleSubmit(async (data) => {
    try {
      if (!data.accessCode) return;
      setIsSubmittingVerifyCode(true);
      const email = getValues('email');
      const password = getValues('password');
      await AuthService.confirmSignUp({
        email,
        accessCode: data.accessCode,
        password,
      });
      const response = await AuthService.signInWithEmailAndPassword({
        email,
        password,
      });

      toast.success(COMMON_MESSAGES.CODE_VERIFIED_WITH_SUCCESS);
      dispatch(authSliceActions.signIn(response));
      const { data: responseMeData } = await UserService.me();
      const userId = responseMeData._id;
      dispatch(authSliceActions.update({ _id: userId, isAuthenticated: true }));
      goToRoute(`${PAGES.SIGN_UP_CREATE_ORGANISATION}?redirect=${inviteURL}`);
    } catch (error) {
      toast.error(COMMON_MESSAGES.UNABLE_SEND_ACCESS_CODE);
    } finally {
      setIsSubmittingVerifyCode(false);
    }
  });

  const onSignInPress = () => {
    goToRoute(location.pathname.replace('signup', 'sign-in'));
  };

  const handleOpenTermsOfService = () => modalTermsOfServiceRef.current?.open();

  const handleOpenPrivacyPolicy = () => modalPrivacyPolicyRef.current?.open();

  useEffect(() => {
    if (!isResendCodeDisabled) return;
    const interval = setInterval(() => {
      if (coolDownTime === 1) {
        setIsResendCodeDisabled(false);
        setCoolDownTime(defaultCoolDownTime);
        return;
      }
      setCoolDownTime((current) => current - 1);
    }, 1000);

    return () => clearInterval(interval);
  }, [coolDownTime, isResendCodeDisabled, setCoolDownTime]);

  return {
    isLoading,
    control,
    errors,
    inviteCode,
    organization,
    inviteEcosystem,
    isDisabled,
    isSubmittingResendCode,
    coolDownTime,
    email,
    currentPage,
    isSubmittingCreateAccount,
    isSubmittingVerifyCode,
    modalTermsOfServiceRef,
    modalPrivacyPolicyRef,
    isResendCodeDisabled,
    handleResendCode,
    handleCreateAccount,
    onVerifyCode,
    onSignInPress,
    handleOpenTermsOfService,
    handleOpenPrivacyPolicy,
  };
};
