import SelectFilter from '@inovua/reactdatagrid-community/SelectFilter';
import DataTableActions from 'ui/components/DataTableActions';
import { TableCellWithImage } from 'ui/components/TableCellWithImage';
import { PermissionType } from 'ui/enums';
import { capitalize } from 'ui/utils/capitalize';

import { colors } from '~/theme/colors';
import { OrganizationConnectedNetworkAppSharing } from '~/types/interfaces/networkAppSharing';
import { AppPermissionGroupTable } from '~/types/interfaces/table';

interface TableAppPermissionColumnsProps {
  onEditPress: (id: string) => void;
  onAssignPress: (id: string) => void;
  connectedApps?: OrganizationConnectedNetworkAppSharing[];
  onPublishDraft?: (id: string) => void;
  onDeletePress?: (id: string) => void;
}

export const tableAppPermissionsColumns = ({
  onAssignPress,
  onEditPress,
  connectedApps,
  onPublishDraft,
  onDeletePress,
}: TableAppPermissionColumnsProps) => [
  {
    defaultFlex: 1,
    name: 'name',
    header: 'Name',
    filterable: true,
    render: ({ data }: AppPermissionGroupTable) => {
      return data?.name || '';
    },
  },
  {
    defaultFlex: 1,
    name: 'app',
    header: 'App',
    filterEditor: SelectFilter,
    filterable: true,
    filterEditorProps: {
      multiple: false,
      wrapMultiple: false,
      dataSource:
        connectedApps?.length &&
        connectedApps.map((connectedApp) => {
          return {
            id: connectedApp.app?._id,
            label: connectedApp.app?.name,
          };
        }),
    },
    render: ({ data }: AppPermissionGroupTable) => {
      return (
        <TableCellWithImage
          name={data.app?.name || ''}
          logo={data.app?.logo || ''}
        />
      );
    },
  },
  {
    defaultFlex: 1,
    name: 'ecosystem',
    header: 'Ecosystem',
    filterEditor: SelectFilter,
    filterable: true,
    filterEditorProps: {
      multiple: false,
      wrapMultiple: false,
      dataSource:
        connectedApps?.length &&
        connectedApps.map((connectedApp) => {
          return {
            id: connectedApp.receiverEcosystem?._id,
            label: connectedApp.receiverEcosystem?.name,
          };
        }),
    },
    render: ({ data }: AppPermissionGroupTable) => {
      return (
        <TableCellWithImage
          name={data.ecosystem?.name || ''}
          logo={data.ecosystem?.logo || ''}
        />
      );
    },
  },
  {
    defaultFlex: 1,
    name: 'type',
    header: 'Type of user',
    filterEditor: SelectFilter,
    filterable: true,
    filterEditorProps: {
      multiple: false,
      wrapMultiple: false,
      dataSource: Object.values(PermissionType).map(
        (permissionType: PermissionType) => {
          return {
            id: permissionType,
            label: capitalize(permissionType),
          };
        },
      ),
    },
    render: ({ data }: AppPermissionGroupTable) => {
      return capitalize(data.type || '');
    },
  },
  {
    defaultFlex: 1,
    name: 'actions',
    header: 'Actions',
    render: ({ data }: AppPermissionGroupTable) => {
      const popoverOptions = [];

      if (!data.isDraft && data.type === PermissionType.MEMBER) {
        popoverOptions.push({
          icon: {
            color: colors.gray[600],
            icon: 'user-check',
            size: 22,
          },
          title: 'Assign group',
          action: () => onAssignPress(data._id),
        });
      }

      if (data.isDraft && onPublishDraft) {
        popoverOptions.push({
          icon: {
            color: colors.gray[600],
            icon: 'send',
            size: 22,
          },
          title: 'Publish draft',
          action: () => onPublishDraft(data._id),
        });
      }

      // {
      //   icon: {
      //     color: colors.gray[600],
      //     icon: 'eye',
      //     size: 22,
      //   },
      //   title: 'View group',
      //   action: () => console.log(),
      // },
      // {
      //   icon: {
      //     color: colors.error[600],
      //     icon: 'trash',
      //     size: 22,
      //   },
      //   titleColor: colors.error[600],
      //   title: 'Delete group',
      //   action: () => console.log(),
      // },

      popoverOptions.push({
        icon: {
          color: colors.gray[600],
          icon: 'edit',
          size: 22,
        },
        title: 'Edit group',
        action: () => onEditPress(data._id),
      });

      if (!data.isDefault) {
        popoverOptions.push({
          icon: {
            color: colors.error[600],
            icon: 'trash',
            size: 22,
          },
          titleColor: colors.error[600],
          title: 'Delete group',
          action: () => onDeletePress?.(data._id),
        });
      }

      return (
        <DataTableActions
          popoverActions
          popoverTextColor={colors.primary[400]}
          popoverIconColor={colors.primary[400]}
          popoverOptions={popoverOptions}
        />
      );
    },
  },
];
