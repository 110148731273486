import { Box, Flex, Text, VStack } from 'native-base';
import Button from 'ui/components/Button';
import Illustration, { ILLUSTRATIONS_NAME } from 'ui/components/Illustration';

import { BottomButton } from '~/components/BottomButton';
import { styles } from '~/pages/Common/JoinAdmin/JoinAsAdminInviteDeclined/styles';
import { useJoinAsAdminInviteDeclinedController } from '~/pages/Common/JoinAdmin/JoinAsAdminInviteDeclined/useJoinAsAdminInviteDeclinedController';
import { colors } from '~/theme/colors';

const JoinAsAdminInviteDeclined = () => {
  const { goToHome } = useJoinAsAdminInviteDeclinedController();

  return (
    <>
      <Flex
        height="100%"
        backgroundColor={colors.singletons.lightText}
        justifyContent="center"
      >
        <Flex
          w="min(90%, 900px)"
          mx="auto"
          alignItems="center"
          flexDirection={styles.flexDirection}
        >
          <Box w={styles.imageContainerW}>
            <Illustration
              name={ILLUSTRATIONS_NAME.INVITATION_DECLINED}
              alt="Logo refused application"
              w={styles.imageW}
              h={styles.imageH}
              rounded="full"
              resizeMode="cover"
            />
          </Box>
          <Box flex="1" mt={styles.boxMt}>
            <Text
              color="gray.700"
              fontWeight="700"
              fontSize={styles.titleFontSize}
              textAlign={styles.titleAlignment}
            >
              We&apos;re sorry to hear that you declined the invitation.
            </Text>
            <Text
              mt="4"
              color="gray.500"
              fontSize="md"
              textAlign={styles.subTitleAlignment}
            >
              We appreciate your consideration and hope to have the opportunity
              to work with you in the future. Your invitation&apos;s link will
              remain active until it expires or an administrator revokes it.
            </Text>
            <VStack
              width="100%"
              alignItems="center"
              space={2}
              mt="6"
              display={styles.buttonContainerDisplay}
            >
              <Button p={6} fontWeight={500} fontSize="md" onPress={goToHome}>
                Back to home screen
              </Button>
            </VStack>
          </Box>
        </Flex>
      </Flex>
      <BottomButton
        text="Back to home screen"
        primaryButtonVariant="solid"
        secondaryButtonTextColor="red.600"
        onSubmit={goToHome}
      />
    </>
  );
};

export default JoinAsAdminInviteDeclined;
