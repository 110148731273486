import { addDays } from 'date-fns';
import { useEffect, useState } from 'react';

import { DateFormProps } from './types';

export const useDateFormController = ({
  control,
  formFieldName,
  parentIndex,
  isEditing,
  setValue,
}: Omit<DateFormProps, 'errors'>) => {
  const defaultValues = control._defaultValues;

  const externalApplyLink = control._getWatch(
    `${formFieldName}[${parentIndex}].externalApplyLink`,
  );

  const defaultStartDate =
    defaultValues[formFieldName]?.[parentIndex]?.startDate;

  const [currentStartDate, setCurrentStartDate] = useState<Date | null>(
    defaultStartDate,
  );
  const [currentNoVolunteerLimit, setCurrentNoVolunteerLimit] =
    useState<boolean>(false);

  const maxDate = currentStartDate ? addDays(currentStartDate, 2) : undefined;

  const onChangeStartDate = (date: Date | null) => {
    setCurrentStartDate(date);
  };
  const onChangeNoVolunteerLimit = (noLimit: boolean) => {
    setCurrentNoVolunteerLimit(noLimit);
  };

  const onExternalApplyLinkChange = (value: boolean) => {
    if (currentNoVolunteerLimit !== value) {
      setValue(`${formFieldName}[${parentIndex}].noVolunteerLimit`, !!value);
      onChangeNoVolunteerLimit(!!value);
    }
  };

  useEffect(() => {
    if (isEditing && externalApplyLink) {
      onChangeNoVolunteerLimit(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    onChangeStartDate,
    onChangeNoVolunteerLimit,
    onExternalApplyLinkChange,
    currentNoVolunteerLimit,
    maxDate,
    externalApplyLink,
  };
};
