import { colors } from '~/theme/colors';

export const getColorFromBookings = (
  bookingsNumber: number,
  volunteerNumber: number,
) => {
  const percentage = (bookingsNumber / volunteerNumber) * 100;
  switch (true) {
    case percentage < 25:
      return colors.success[500];
    case percentage >= 25 && percentage < 50:
      return colors.yellow[500];
    case percentage >= 50 && percentage < 90:
      return colors.warning[500];
    case percentage >= 90:
      return colors.danger[500];
    default:
  }
};
