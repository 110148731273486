import { IColumn } from '@inovua/reactdatagrid-community/types';
import { TypeOnSelectionChangeArg } from '@inovua/reactdatagrid-community/types/TypeDataGridProps';
import { useCallback, useMemo, useState } from 'react';

import {
  BaseModel,
  SelectedRows,
  TableOperatorNames,
} from './@types/basicTypes';
import { IFilterValue, ISort, ISortInfo } from './@types/filterTypes';
import { MongoOperators, MongoOptions } from './@types/mongoTypes';
import {
  IUseSimpleTableControllerProps,
  IUseTableControllerProps,
} from './@types/propTypes';
import { ROWS_PER_PAGE } from './utils';

export const useTableController = <Model extends BaseModel>({
  loadData,
  defaultFilterValues,
  refetchQuantity,
  checkboxColumn,
  onSelectionChange,
}: IUseTableControllerProps<Model>) => {
  const [filterValue, setFilterValue] = useState(defaultFilterValues);
  const [skip, setSkip] = useState(0);
  const [limit, setLimit] = useState(ROWS_PER_PAGE[1]);
  const [filter, setFilter] = useState<IFilterValue | null>(null);
  const [sortInfo, setSortInfo] = useState<ISort | null>(null);

  const transformFilterValueInQuery = (filterValue: IFilterValue) => {
    const { operator, value } = filterValue;
    switch (operator) {
      case TableOperatorNames.Equals:
        return { [MongoOperators.Equals]: value };
      case TableOperatorNames.NotEquals:
        return { [MongoOperators.NotEquals]: value };
      case TableOperatorNames.NotContains:
        return { [MongoOperators.NotContains]: value };
      case TableOperatorNames.NotEmpty:
        return { [MongoOperators.Exists]: true };
      case TableOperatorNames.Empty:
        return null;
      case TableOperatorNames.After:
      case TableOperatorNames.GreaterThan:
        return { [MongoOperators.GreaterThan]: value };
      case TableOperatorNames.AfterOrOn:
      case TableOperatorNames.GreaterThanOrEquals:
        return { [MongoOperators.GreaterThanOrEquals]: value };
      case TableOperatorNames.LowerThan:
      case TableOperatorNames.Before:
        return { [MongoOperators.LowerThan]: value };
      case TableOperatorNames.BeforeOrOn:
      case TableOperatorNames.LowerThanOrEquals:
        return { [MongoOperators.LowerThanOrEquals]: value };
      case TableOperatorNames.Or:
        return { [MongoOperators.Or]: value };
      default:
        return {
          [MongoOperators.Regex]: value,
          [MongoOperators.Options]: MongoOptions.CaseInsensitive,
        };
    }
  };

  const onFilterValueChange = useCallback((filterValue) => {
    const filteredValues = filterValue.filter(
      (f: IFilterValue) =>
        (f.value && f.operator !== TableOperatorNames.Empty) ||
        (f.value && f.operator !== TableOperatorNames.NotEmpty),
    );
    const formatedFilters = filteredValues.reduce(
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (accumulator: any, value: IFilterValue) => {
        return {
          ...accumulator,
          [value.name]: transformFilterValueInQuery(value),
        };
      },
      {},
    );
    setFilterValue(filterValue);
    setFilter(formatedFilters);
  }, []);

  const onSkipChange = (currentSkip: number) => {
    setSkip(currentSkip);
  };

  const onLimitChange = (currentLimit: number) => {
    setLimit(currentLimit);
  };

  const onSortInfoChange = (sortInfo: ISortInfo) => {
    const mongoSort: ISort = {};
    mongoSort[sortInfo.name] = sortInfo.dir;
    setSortInfo(mongoSort);
  };

  // This disables the column header checkbox, as it selects all the rows (even paginated ones).
  // It's important to disable it in cases that the table has pagination,
  // or the user can end up doing some actions on rows that are not visible for him.
  const checkboxColumnConfig: IColumn | undefined = useMemo(() => {
    if (!checkboxColumn) return undefined;

    return {
      renderCheckbox: (_checkboxProps, cellProps) => {
        // This is a hack for not showing the header checkbox
        // that marks/unmarks all the rows across the entire table
        if (Array.isArray(cellProps.data)) {
          cellProps.style = {
            visibility: 'hidden',
          };
        }

        return undefined;
      },
    };
  }, [checkboxColumn]);

  const dataSource = useMemo(() => {
    return loadData(skip, limit, filter, sortInfo);
    // @mycatdoitbetter NOTE: refetchQuantity is an optional prop used to refresh the dataSource.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [skip, limit, filter, loadData, refetchQuantity, sortInfo]);

  const handleOnSelectionChange: (config: TypeOnSelectionChangeArg) => void = ({
    selected,
  }) => {
    onSelectionChange?.(selected as SelectedRows<Model>);
  };

  return {
    filterValue,
    onFilterValueChange,
    onSkipChange,
    onLimitChange,
    skip,
    limit,
    filter,
    dataSource,
    checkboxColumnConfig,
    handleOnSelectionChange,
    onSortInfoChange,
  };
};

export const useSimpleTableController = <Model extends BaseModel>({
  defaultFilterValues,
  checkboxColumn,
  onSelectionChange,
}: IUseSimpleTableControllerProps<Model>) => {
  const [filterValue, setFilterValue] = useState(defaultFilterValues);
  const [filter, setFilter] = useState<IFilterValue | null>(null);

  const transformFilterValueInQuery = (filterValue: IFilterValue) => {
    const { operator, value } = filterValue;
    switch (operator) {
      case TableOperatorNames.Equals:
        return { [MongoOperators.Equals]: value };
      case TableOperatorNames.NotEquals:
        return { [MongoOperators.NotEquals]: value };
      case TableOperatorNames.NotContains:
        return { [MongoOperators.NotContains]: value };
      case TableOperatorNames.NotEmpty:
        return { [MongoOperators.Exists]: true };
      case TableOperatorNames.Empty:
        return null;
      case TableOperatorNames.After:
      case TableOperatorNames.GreaterThan:
        return { [MongoOperators.GreaterThan]: value };
      case TableOperatorNames.AfterOrOn:
      case TableOperatorNames.GreaterThanOrEquals:
        return { [MongoOperators.GreaterThanOrEquals]: value };
      case TableOperatorNames.LowerThan:
      case TableOperatorNames.Before:
        return { [MongoOperators.LowerThan]: value };
      case TableOperatorNames.BeforeOrOn:
      case TableOperatorNames.LowerThanOrEquals:
        return { [MongoOperators.LowerThanOrEquals]: value };
      case TableOperatorNames.Or:
        return { [MongoOperators.Or]: value };
      default:
        return {
          [MongoOperators.Regex]: value,
          [MongoOperators.Options]: MongoOptions.CaseInsensitive,
        };
    }
  };

  const onFilterValueChange = useCallback((filterValue) => {
    const filteredValues = filterValue.filter(
      (f: IFilterValue) =>
        (f.value && f.operator !== TableOperatorNames.Empty) ||
        (f.value && f.operator !== TableOperatorNames.NotEmpty),
    );
    const formatedFilters = filteredValues.reduce(
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (accumulator: any, value: IFilterValue) => {
        return {
          ...accumulator,
          [value.name]: transformFilterValueInQuery(value),
        };
      },
      {},
    );
    setFilterValue(filterValue);
    setFilter(formatedFilters);
  }, []);

  // This disables the column header checkbox, as it selects all the rows (even paginated ones).
  // It's important to disable it in cases that the table has pagination,
  // or the user can end up doing some actions on rows that are not visible for him.
  const checkboxColumnConfig: IColumn | undefined = useMemo(() => {
    if (!checkboxColumn) return undefined;

    return {
      renderCheckbox: (_checkboxProps, cellProps) => {
        // This is a hack for not showing the header checkbox
        // that marks/unmarks all the rows across the entire table
        if (Array.isArray(cellProps.data)) {
          cellProps.style = {
            visibility: 'hidden',
          };
        }

        return undefined;
      },
    };
  }, [checkboxColumn]);

  const handleOnSelectionChange: (config: TypeOnSelectionChangeArg) => void = ({
    selected,
  }) => {
    onSelectionChange?.(selected as SelectedRows<Model>);
  };

  return {
    filterValue,
    onFilterValueChange,
    // onSkipChange,
    // onLimitChange,
    // skip,
    // limit,
    filter,
    checkboxColumnConfig,
    handleOnSelectionChange,
  };
};
