import { useMemo } from 'react';

import {
  makeActivityTitleNode,
  makeActivityTypeBadgeTitleNode,
} from '~/components/Cards/NotificationCard/utils';

import { userUserNotificationCardController } from '../controller';
import { ActivityApplicationCanceledNotificationCardProps } from './types';

export const useActivityApplicationCanceledNotificationCardController = (
  props: ActivityApplicationCanceledNotificationCardProps,
) => {
  const { activityDefinitionId, activityTitle, activityType } = props;
  const { date, profilePictureUrl, authorTitleNode } =
    userUserNotificationCardController(props);

  const titleNodes = useMemo(() => {
    authorTitleNode.text += `‘s`;
    return [
      authorTitleNode,
      { text: 'application for' },
      makeActivityTitleNode(activityDefinitionId, activityTitle, activityType),
      { text: 'was cancelled', fontColor: 'error.600' },
      makeActivityTypeBadgeTitleNode(activityType),
    ];
  }, [activityDefinitionId, activityTitle, activityType, authorTitleNode]);
  return { titleNodes, date, profilePictureUrl };
};
