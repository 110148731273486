import { NotificationCard } from '../../BaseNotificationCard';
import { useActivityApplicationCanceledNotificationCardController } from './controller';
import { ActivityApplicationCanceledNotificationCardProps } from './types';

export const ActivityApplicationCanceledNotificationCard = (
  props: ActivityApplicationCanceledNotificationCardProps,
) => {
  const { titleNodes, date, profilePictureUrl } =
    useActivityApplicationCanceledNotificationCardController(props);

  return (
    <NotificationCard
      authorImageUrl={profilePictureUrl}
      titleNodes={titleNodes}
      date={date}
    />
  );
};
