import { useRouter } from '~/hooks/useRouter';

export const useAppsAccessCardController = () => {
  const { goToRoute } = useRouter();

  const handleGoToManageApps = (appId: string) => {
    goToRoute(`/settings/apps/${appId}`);
  };

  return { handleGoToManageApps };
};
