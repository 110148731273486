import { FeedNotification } from 'ui/types/interfaces/feed-notification';

import useInfinityLoad from '~/hooks/useinfinityLoad';

import { FeedNotificationsInfinityLoadProps } from './types';

export const useFeedNotificationsInfinityLoad = (
  filter: FeedNotificationsInfinityLoadProps,
  isEnabled = false,
) => {
  const {
    ecosystemId,
    organizationId,
    appIds,
    types,
    startDate,
    notificationTargets,
  } = filter;

  const response = useInfinityLoad({
    url: `org-admin/feed-notifications/${organizationId}/search`,
    queryKey: `org-admin-feed-notifications-${organizationId}-search`,
    props: {
      types,
      startDate,
      ecosystemId,
      apps: appIds,
      notificationTargets,
    },
    disabled: !isEnabled,
  });

  const serializedData = ((response.data?.pages as any) ?? []).flatMap(
    (page: { responseData: FeedNotification<unknown> }) => page.responseData,
  );

  return { ...response, data: serializedData };
};
