import { StatusCodes } from 'http-status-codes';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { ImageZoneImageItem } from 'ui/components/Dropzone/ImageZoneMini/types';
import { ITabsRefProps } from 'ui/components/Tabs/types';
import { ActivityType } from 'ui/types/activities';
import { convertCarouselImagesToImageZone } from 'ui/utils/formatter';
import { ValidationsRegex } from 'ui/utils/validations/validations';

import { COMMON_ERRORS } from '~/constants/error.constants';
import { ACTION_MESSAGES } from '~/constants/messages.constants';
import { PAGES } from '~/constants/pages.constants';
import {
  AttendanceTypes,
  EventConfigurationOptions,
  MeasurementUnitsCategory,
} from '~/enums';
import { useActivityCategoryOptions } from '~/hooks/useActivityCategoriesOptions';
import { useAppSelector } from '~/hooks/useAppSelector';
import { useGetMeasurementUnits } from '~/hooks/useGetMeasurementUnits';
import { usePermissions } from '~/hooks/usePermissions';
import { useRouter } from '~/hooks/useRouter';
import ActivitiesService from '~/services/resources/activities';
import ActivityService from '~/services/resources/activity';
import ActivityDefinitionService from '~/services/resources/activityDefinition';
import MeasurementUnitsService from '~/services/resources/measurmentUnits';
import {
  CreateCustomUnitPayload,
  IActivityCategoryDTO,
  IEditActivityDTO,
} from '~/types/dtos';
import {
  handleActivityCoverImage,
  handleCarouselImagesForActivityEdit,
} from '~/utils/activitiesImages';
import { getMeasurementUnit, transformHoursToSeconds } from '~/utils/functions';

import { ActionFormTabsPages } from './types';
import { mapPlacesAndDatesFormValues } from './utils';

export const useEditActionFormController = () => {
  const tabsRef = useRef<ITabsRefProps>(null);
  const {
    replaceRoute,
    params: { id: actionId },
    searchParams,
  } = useRouter();

  const tabId = Number(searchParams.get('tabId'));

  const [formData, setFormData] = useState<IEditActivityDTO>(
    {} as IEditActivityDTO,
  );

  const { organizationSelectedId } = useAppSelector(({ auth }) => auth);
  const { selectedEcosystem } = useAppSelector(({ ecosystem }) => ecosystem);

  const [currentTab, setCurrentTab] = useState(
    tabId || ActionFormTabsPages.About,
  );
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingDefaultValues, setIsLoadingDefaultValues] = useState(true);

  const location = useLocation();
  const navigate = useNavigate();

  const { filterAppsForActivityCreation } = usePermissions();

  const filteredAppsByPermissions = filterAppsForActivityCreation(
    ActivityType.Action,
    false,
  );

  const appIdToValidatePermisssions =
    formData.selectedApp && formData?.selectedApp.length > 0
      ? (formData?.selectedApp[0] as string)
      : '';

  const { setValue, setError } = useForm<IEditActivityDTO>({
    mode: 'onChange',
    reValidateMode: 'onBlur',
  });

  const { causeOptions, causeOptionsData } = useActivityCategoryOptions();

  const isTeamEvent = useMemo(() => {
    return formData.eventConfiguration === EventConfigurationOptions.Teams;
  }, [formData]);

  const { measurementUnits } = useGetMeasurementUnits();

  const handleContinue = (data: IEditActivityDTO) => {
    if (data.measurementUnit) {
      const measurementUnit = Object.values(measurementUnits)
        .flatMap((measurmentUnit) => measurmentUnit)
        .find(
          (measurementUnit) => measurementUnit._id === data.measurementUnit,
        );

      if (
        measurementUnit?.category !== MeasurementUnitsCategory.Completion &&
        !data.targetAmount
      ) {
        setError('targetAmount', {
          message: 'The field is required',
          type: 'required',
        });

        return;
      }

      setValue('measurementUnitPluralLabel', measurementUnit?.pluralLabel);
    }

    setFormData((prev: IEditActivityDTO) => ({ ...prev, ...data }));

    if (tabsRef.current?.currentTabIndex !== 2) {
      tabsRef.current?.nextStep();
      setCurrentTab((prev) => prev + 1);
    } else {
      editAction({ ...formData, ...data });
    }
  };

  const handleBack = () => {
    if (
      tabsRef.current?.currentTabIndex &&
      tabsRef.current?.currentTabIndex > 0
    ) {
      tabsRef.current?.backStep();
      setCurrentTab((prev) => prev - 1);
    } else {
      navigate(-1);
    }
  };

  const handleCreateMeasurementUnitStep = async (
    payload: CreateCustomUnitPayload,
  ) => {
    try {
      const { data } = await MeasurementUnitsService.createCustomUnit(payload);

      return data;
    } catch (error) {
      console.log('error', error);
    }
  };

  const editAction = async (data: IEditActivityDTO) => {
    try {
      setIsLoading(true);
      const causeOptions = causeOptionsData?.data.filter(
        (cause: IActivityCategoryDTO) => data.cause?.includes(cause._id),
      );

      const app =
        data.selectedApp && data.selectedApp.length > 0 && data.selectedApp[0];

      if (data.measurementUnit) {
        const measurementUnit = getMeasurementUnit(
          measurementUnits,
          data.measurementUnit,
        );
        if (
          measurementUnit?.category === MeasurementUnitsCategory.Time &&
          data.targetAmount
        ) {
          data.targetAmount = transformHoursToSeconds(
            Number(data.targetAmount),
          );
        }

        data.measurementUnitPluralLabel = measurementUnit?.pluralLabel;

        if (
          measurementUnit?.category === MeasurementUnitsCategory.Completion &&
          organizationSelectedId &&
          data?.steps?.length
        ) {
          const measurementCreated = await handleCreateMeasurementUnitStep({
            organization: organizationSelectedId,
            pluralLabel: 'Steps',
            singularLabel: 'Step',
            steps: data.steps,
          });

          data.measurementUnit = measurementCreated._id;
        }
      }
      const { coverImageURL, thumbnailImageURL } =
        await handleActivityCoverImage(data.coverImage, data.thumbnailImage);

      const carouselImagesURLs = await handleCarouselImagesForActivityEdit(
        data.carouselImages as ImageZoneImageItem[],
      );

      const activityDefinitionPayload: Partial<IEditActivityDTO> = {
        ...data,
        coverImage: coverImageURL,
        carouselImages: carouselImagesURLs as string[],
        app: app as string,
        ecosystem: selectedEcosystem?._id,
        organization: organizationSelectedId,
        targetAmount: data.targetAmount || 0,
        measurementUnit: data.measurementUnit,
        causeOptions,
        thumbnailImage: thumbnailImageURL,
      };

      if (data.meetingLink) {
        if (!ValidationsRegex.Url.test(encodeURI(data.meetingLink))) {
          toast.error(
            'Meeting link should be a valid full url! Only https is allowed. E.g: https://example.com',
          );
          throw Error();
        }
      }
      const { data: activityDefinitionData } =
        await ActivityDefinitionService.editActionActivityDefininition(
          activityDefinitionPayload._id as string,
          activityDefinitionPayload,
        );

      let payload: IEditActivityDTO = {
        activityDefinition: activityDefinitionData._id,
        organization: organizationSelectedId || '',
        app: app as string,
        ecosystem: selectedEcosystem?._id,
        regions: data.regions,
      };

      payload = data.meetingLink
        ? { ...payload, meetingLink: data.meetingLink }
        : payload;

      await ActivitiesService.editActivityEvent(
        data._activityId as string,
        payload,
      );

      replaceRoute(`${PAGES.ACTIVITIES}`);
      setTimeout(() => {
        replaceRoute(`${PAGES.EDIT_ACTION_SUCCESS}`, {
          state: {
            locationForActivitySuccessful: location,
            activity: activityDefinitionData,
          },
        });
      }, 500);
    } catch (error) {
      console.log({ error });
      toast.error(ACTION_MESSAGES.EDIT_ACTION_ERROR);
    } finally {
      setIsLoading(false);
    }
  };

  const loadData = useCallback(async () => {
    try {
      setIsLoadingDefaultValues(true);
      if (!actionId) return;
      const { data: activityDefinition, status: activityDefinitionStatus } =
        await ActivityDefinitionService.get(actionId);

      const {
        data: allActivitiesByDefinition,
        status: allActivitiesByDefinitionStatus,
      } = await ActivityService.getAllByDefinition(activityDefinition._id);

      if (
        activityDefinitionStatus === StatusCodes.OK &&
        allActivitiesByDefinitionStatus === StatusCodes.OK
      ) {
        const parsedFormData: IEditActivityDTO = {
          ...activityDefinition,
          // @mycatdoitbetter TODO: Fix this type
          cause: activityDefinition.causeOptions.map(
            (cause: { _id: string }) => cause._id,
          ),
          eventConfiguration: 'Individual',
          selectedApp: [activityDefinition.app],
          measurementUnit: activityDefinition.measurementUnit,
          targetAmount: activityDefinition.targetAmount,
          regions: allActivitiesByDefinition.data[0].regions,
          _activityId: allActivitiesByDefinition.data[0]._id,
          meetingLink: allActivitiesByDefinition.data[0].meetingLink || '',
          externalApplyLink:
            allActivitiesByDefinition.data[0].externalApplyLink || '',
          carouselImages: convertCarouselImagesToImageZone(
            activityDefinition.carouselImages,
          ),
        };

        if (allActivitiesByDefinition.data.length > 1) {
          const { places, dates } = mapPlacesAndDatesFormValues(
            allActivitiesByDefinition.data,
            activityDefinition.attendanceType as AttendanceTypes,
          );
          setFormData({
            ...parsedFormData,
            places: places,
            dates: dates,
          });
        } else {
          setFormData({
            ...parsedFormData,
          });
        }

        throw new Error(COMMON_ERRORS.ERROR_ON_LOAD_EVENT);
      }
    } catch (error) {
      return error;
    } finally {
      setIsLoadingDefaultValues(false);
    }
  }, [actionId]);

  useEffect(() => {
    loadData();
  }, [loadData]);

  return {
    tabsRef,
    currentTab,
    causeOptions,
    handleContinue,
    handleBack,
    isLoading,
    formData,
    isTeamEvent,
    isLoadingDefaultValues,
    filteredAppsByPermissions,
    appIdToValidatePermisssions,
  };
};
