export const useModalActionReportController = () => {
  return {
    customUnitsTargetAmountLoggedData: [
      { _id: 'plastic bottles', total: 10 },
      { _id: 'pieces of clothing', total: 52 },
      { _id: 'books', total: 12 },
      { _id: 'lifts', total: 5 },
    ],
    totalKilogramsLogged: { total: 10 },
    totalStepsLogged: { total: 5 },
    isLoading: false,
  };
};
