import { KeyboardEvent, useEffect, useState } from 'react';
import {
  NativeSyntheticEvent,
  TextInputChangeEventData,
  TextInputKeyPressEventData,
} from 'react-native/types';
import { colors } from 'ui/theme/colors';

import { TagGroupProps } from './types';

const useTagGroupController = ({
  label,
  placeholder,
  defaultValues = [],
  field,
  name,
  control,
  rightIconName = 'plus-circle',
  rightIconColor = colors.muted[400],
  rightIconSize = 20,
  errorMessage,
  isRequired,
  isInvalid,
  isDisabled,
  hasError,
  iconErrorMessage,
  iconErrorSize,
  tagIconName = 'x-circle',
  tagIconSize = 14,
  tagIconColor = colors.primary[600],
  tagColor = 'primary.600',
  maxLength = 64,
  helperText,
  handleCleanTags,
}: TagGroupProps) => {
  const [currentTag, setCurrentTag] = useState<string>('');
  const [tags, setTags] = useState<string[]>(defaultValues);

  const handleSetCurrentTag = (
    e: NativeSyntheticEvent<TextInputChangeEventData>,
  ) => {
    setCurrentTag(e.nativeEvent.text);
  };

  const handlePress = (
    e: NativeSyntheticEvent<TextInputKeyPressEventData> | KeyboardEvent,
    onChange: (event?: string[]) => void,
  ) => {
    const event = e as KeyboardEvent;
    if (event?.code === 'Enter') {
      onChange(handleAddTag());
    }
  };

  const handleAddTag = () => {
    if (currentTag?.trim()?.length && !tags.includes(currentTag)) {
      setTags((prev) => [...prev, currentTag]);
      setCurrentTag('');
      return [...tags, currentTag];
    }
    setCurrentTag('');
  };

  const handleRemoveTag = (removeTagName: string) => {
    const newTags = tags.filter((tag: string) => tag !== removeTagName);

    if (!newTags.length) {
      setTags([]);
      return [];
    } else {
      setTags(newTags);
      return newTags;
    }
  };

  // TODO: Handle this with react-hook-form control defaultValues.
  // NOTE: I have done this to ensure that the form i'll have the correct tags
  // when filled with default values.
  useEffect(() => {
    if (!control) return;

    const fieldState = control._defaultValues[name];

    if (fieldState) setTags(fieldState);
  }, [control, name]);

  return {
    label,
    placeholder,
    tags,
    field,
    maxLength,
    helperText,
    control,
    rightIconName,
    name,
    rightIconColor,
    rightIconSize,
    errorMessage,
    isRequired,
    isInvalid,
    hasError,
    isDisabled,
    iconErrorMessage,
    iconErrorSize,
    tagIconName,
    tagIconSize,
    tagIconColor,
    tagColor,
    currentTag,
    handleAddTag,
    handleCleanTags,
    handlePress,
    handleRemoveTag,
    handleSetCurrentTag,
  };
};

export default useTagGroupController;
