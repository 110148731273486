import { LocationDataStatus } from 'ui/enums';
import { colors } from 'ui/theme/colors';

export const useLocationTagController = () => {
  const tagStyleByStatus = {
    [LocationDataStatus.OK]: {
      bg: 'primary.50',
      color: 'primary.400',
      icon: colors.primaryAdmin[400],
    },
    [LocationDataStatus.ZERO_RESULTS]: {
      bg: 'error.100',
      color: 'error.600',
      icon: colors.error[600],
    },
  };
  return { tagStyleByStatus };
};
