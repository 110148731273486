import { Progress, Text, VStack } from 'native-base';

import { ApplicationSlotsProps } from './types';
import { getColorFromBookings } from './utils';

export const ApplicationSlots = ({
  volunteerNumber,
  bookingsNumbers,
  isVolunteerNumberLimited,
}: ApplicationSlotsProps) => {
  return (
    <VStack>
      {isVolunteerNumberLimited && volunteerNumber ? (
        <Text fontSize="xs" fontWeight="normal" color="gray.600">
          <Text
            fontSize="xs"
            fontWeight="medium"
            color={getColorFromBookings(bookingsNumbers, volunteerNumber)}
          >
            {' '}
            {volunteerNumber - bookingsNumbers}
          </Text>{' '}
          of {volunteerNumber} spaces remaining
        </Text>
      ) : (
        <Text fontSize="xs" fontWeight="normal" color="gray.600">
          {bookingsNumbers} registered
        </Text>
      )}
      {isVolunteerNumberLimited && volunteerNumber && (
        <Progress
          value={bookingsNumbers}
          max={volunteerNumber}
          mt={0.5}
          _filledTrack={{
            bg: getColorFromBookings(bookingsNumbers, volunteerNumber),
          }}
        />
      )}
    </VStack>
  );
};
