import 'react-toastify/dist/ReactToastify.css';

import { QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { Provider } from 'react-redux';

import { store } from '~/store';
import ThemeProvider from '~/theme/ThemeProvider';
import { ToastStyledContainer } from '~/theme/ToastContainer';
import { ReactFunctionComponent } from '~/types/interfaces';

import { queryClient } from './config/react-query.config';
import { renderToastItem } from './config/toast';

const Providers = ({ children }: ReactFunctionComponent) => {
  return (
    <QueryClientProvider client={queryClient}>
      <ThemeProvider>
        <Provider store={store}>{children}</Provider>
        <ToastStyledContainer
          closeButton={false}
          autoClose={3000}
          icon={renderToastItem}
          hideProgressBar
        />
      </ThemeProvider>
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  );
};

export default Providers;
