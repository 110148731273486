import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { PopulatedAppPermissionGroup } from 'ui/types/interfaces/app-permissions-group';

import { ESliceNames } from '~/store/types';
import { InviteComposeSettings } from '~/types/interfaces/invite';

import {
  AddMemberState,
  EcosystemToShare,
  SetPermissionToEcosystemAppPayload,
  userConfiguration,
} from './types';

const initialState: AddMemberState = {
  userConfigurations: [],
  ecosystemsToShare: [],
  selectedMemberPermissions: [],
  selectedPartnerPermissions: [],
  composeSettings: undefined,
};

export const addMemberSlice = createSlice({
  name: ESliceNames.ADD_MEMBER,
  initialState,
  reducers: {
    setInviteUserConfigurations: (
      state,
      { payload: { email, externalId } }: PayloadAction<userConfiguration>,
    ) => {
      const findEmail = state.userConfigurations.find(
        (configuration) => configuration.email === email,
      );
      if (findEmail) {
        return state;
      }
      state.userConfigurations.push({ email, externalId });
    },
    removeInviteUserConfiguration: (
      state,
      { payload: email }: PayloadAction<string>,
    ) => {
      state.userConfigurations = state.userConfigurations.filter(
        (configuration) => configuration.email !== email,
      );
    },
    setEcosystemToShare: (
      state,
      { payload }: PayloadAction<EcosystemToShare>,
    ) => {
      const existingEcosystem = state.ecosystemsToShare.find(
        (ecosystem) => ecosystem.ecosystemId === payload.ecosystemId,
      );

      if (existingEcosystem && !payload.appsData.length) {
        state.ecosystemsToShare = state.ecosystemsToShare.filter(
          (ecosystem) => ecosystem.ecosystemId !== payload.ecosystemId,
        );
        return;
      }

      if (existingEcosystem) {
        existingEcosystem.appsData = payload.appsData;
      } else {
        state.ecosystemsToShare.push(payload);
      }
    },
    setPermissionToEcosystemApp: (
      state,
      { payload }: PayloadAction<SetPermissionToEcosystemAppPayload>,
    ) => {
      const ecosystemToShare = state.ecosystemsToShare.find(
        (ecosystem) => ecosystem.ecosystemId === payload.ecosystemId,
      );
      if (ecosystemToShare) {
        const appToShare = ecosystemToShare.appsData.find(
          (app) => app.appId === payload.appId,
        );
        if (appToShare) {
          const appPermissionGroup = appToShare.appPermissionGroupsData.find(
            (appPermissionGroup) =>
              appPermissionGroup.appPermissionGroupId ===
              payload.permissionGroupId,
          );
          if (appPermissionGroup) {
            appPermissionGroup.appPermissionGroupId = payload.permissionGroupId;
          } else {
            appToShare.appPermissionGroupsData.push({
              appPermissionGroupId: payload.permissionGroupId,
            });
          }
        }
      }
    },
    setSelectedMembersPermissions: (
      state,
      { payload }: PayloadAction<PopulatedAppPermissionGroup[]>,
    ) => {
      state.selectedMemberPermissions = payload;
    },
    setSelectedPartnersPermissions: (
      state,
      { payload }: PayloadAction<PopulatedAppPermissionGroup[]>,
    ) => {
      state.selectedPartnerPermissions = payload;
    },
    setComposeSettings: (
      state,
      { payload }: PayloadAction<InviteComposeSettings>,
    ) => {
      state.composeSettings = payload;
    },
    reset: () => initialState,
  },
});

export const { reducer: addMemberReducer, actions: addMemberActions } =
  addMemberSlice;
