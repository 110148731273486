import { Text } from 'native-base';

import { colors } from '../../theme/colors';
import Button from '../Button';
import { useCopyButtonController } from './controller';
import { CopyButtonProps } from './types';
const CopyButton = ({
  value,
  color = 'primary.50',
  iconColor = colors.primaryAdmin[500],
  variant = 'solid',
  textColor = 'primary.400',
}: CopyButtonProps) => {
  const { handleCopy } = useCopyButtonController();

  return (
    <Button
      leftIconName="copy"
      leftIconColor={iconColor}
      leftIconSize={16}
      maxWidth={{ base: 'full', lg: '100px' }}
      minWidth={{ base: 'full', lg: '100px' }}
      bgColor={variant === 'outline' ? 'transparent' : color}
      variant={variant}
      onPress={() => handleCopy(value)}
    >
      <Text fontSize="xs" fontWeight="medium" color={textColor}>
        Copy
      </Text>
    </Button>
  );
};

export default CopyButton;
