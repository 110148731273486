import { useForm } from 'react-hook-form';

import { ICreateOpportunityDTO, IEditActivityDTO } from '~/types/dtos';

export const useWhenWhereFormController = ({
  locationOption,
}: Pick<IEditActivityDTO, 'locationOption'>) => {
  const { control, watch } = useForm<
    Pick<ICreateOpportunityDTO, 'locationOption'>
  >({
    mode: 'onChange',
    reValidateMode: 'onBlur',
    defaultValues: {
      locationOption,
    },
  });

  const currentLocationType = watch('locationOption');

  return {
    control,
    currentLocationType,
  };
};
