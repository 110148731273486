import { useCallback, useMemo, useRef, useState } from 'react';
import { toast } from 'react-toastify';
import { IModalRefProps } from 'ui/components/Modals/Modal/types';

import { useAppSelector } from '~/hooks/useAppSelector';
import { useLoadAdminInvites } from '~/hooks/useLoadAdminInvites';
import { AdminInvitesControllerAttributes } from '~/pages/Authenticated/Settings/AdminsSettings/types';
import { InviteCodeService } from '~/services/resources/inviteCode';
import { QueryParams } from '~/services/resources/types';

export const useAdminInvitesController =
  (): AdminInvitesControllerAttributes => {
    const { organizationSelectedId } = useAppSelector(({ auth }) => auth);
    const [queryParams, setQueryParams] = useState<QueryParams | undefined>(
      undefined,
    );
    const {
      responseData,
      isLoading,
      isFetching,
      isRefetching,
      refetch: refetchInvites,
    } = useLoadAdminInvites(organizationSelectedId, queryParams);
    const areInvitesLoading = useMemo(
      () => isLoading || isFetching || isRefetching,
      [isLoading, isFetching, isRefetching],
    );

    const [invite, setInvite] = useState<{
      id: string;
    } | null>(null);
    const modalResendRef = useRef<IModalRefProps>(null);
    const modalDeleteRef = useRef<IModalRefProps>(null);
    const openResendInviteModal = (inviteId: string) => {
      setInvite({ id: inviteId });
      modalResendRef.current?.open();
    };
    const closeResendInviteModal = () => {
      setInvite(null);
      modalResendRef.current?.close();
    };
    const openDeleteInviteModal = (inviteId: string) => {
      setInvite({ id: inviteId });
      modalDeleteRef.current?.open();
    };
    const closeDeleteInviteModal = () => {
      setInvite(null);
      modalDeleteRef.current?.close();
    };
    const [isResendingOrDeleting, setIsResendingOrDeleting] = useState(false);
    const onResendInvite = async () => {
      try {
        if (!invite) return;
        setIsResendingOrDeleting(true);
        await InviteCodeService.resend(invite.id);
        toast.success('Invite resent');
      } catch (error) {
        toast.error('Error on trying to resend invite');
      } finally {
        await refetchInvites();
        setIsResendingOrDeleting(false);
        closeResendInviteModal();
      }
    };
    const onDeleteInvite = async () => {
      try {
        if (!invite || !organizationSelectedId) return;
        setIsResendingOrDeleting(true);
        await InviteCodeService.delete(invite.id, organizationSelectedId);
        toast.success('Invite deleted');
      } catch (error) {
        toast.error('Error on trying to delete invite');
      } finally {
        await refetchInvites();
        setIsResendingOrDeleting(false);
        closeDeleteInviteModal();
      }
    };

    const tableData = useMemo(() => {
      if (responseData?.data) {
        const data = responseData?.data;
        const count = responseData?.count;
        return { data, count };
      }
      return { data: [], count: 0 };
    }, [responseData]);

    const loadAdminInvites = useCallback(
      async (skip: number, limit: number) => {
        setQueryParams({ skip, limit });
        return tableData;
      },
      [tableData],
    );

    return {
      refetchInvites,
      invite,
      modalResendRef,
      openResendInviteModal,
      closeResendInviteModal,
      modalDeleteRef,
      openDeleteInviteModal,
      closeDeleteInviteModal,
      isResendingOrDeleting,
      onResendInvite,
      onDeleteInvite,
      loadAdminInvites,
      areInvitesLoading,
    };
  };
