import { useQuery } from '~/hooks/useQuery';
import { QueryParams } from '~/services/resources/types';
import { IPaginatedResponse } from '~/types';
import { InviteCode } from '~/types/interfaces/inviteCode';

export const useLoadAdminInvites = (
  organizationId = '',
  params?: QueryParams,
) => {
  const {
    data: responseData,
    isLoading,
    isFetching,
    isRefetching,
    refetch,
  } = useQuery<IPaginatedResponse<InviteCode[]>>(
    `/invite-code/organization/${organizationId}`,
    {
      queryOptions: {
        enabled: !!organizationId && !!params,
      },
      requestOptions: {
        params: {
          filter: JSON.stringify({
            isUsed: false,
          }),
          populate: JSON.stringify({
            model: 'User',
            path: 'createdBy',
          }),
          ...params,
        },
      },
      key: `app-invite-code-${organizationId}-${JSON.stringify(params)}`,
    },
  );

  return {
    responseData,
    isLoading,
    isFetching,
    isRefetching,
    refetch,
  };
};
