import { LogProgressTile } from 'ui/components/v2/Tiles/LogProgressTile';
import { MeasurementCategory } from 'ui/enums';

import { LogsSummaryTileContentProps } from './types';

export const LogsSummaryTileContent = ({
  measurementUnit,
  measurementsTotal,
  measurementsTarget,
}: LogsSummaryTileContentProps) => {
  const isCompletion =
    measurementUnit.category === MeasurementCategory.Completion ||
    (measurementUnit?.steps || []).length > 0;

  if (isCompletion) {
    return (
      <LogProgressTile.Completion
        targetValue={measurementsTarget}
        progressValue={measurementsTotal}
        measurementUnit={measurementUnit}
      />
    );
  }

  if (!!measurementsTarget) {
    return (
      <LogProgressTile.Target
        targetValue={measurementsTarget}
        progressValue={measurementsTotal}
        measurementUnit={measurementUnit}
      />
    );
  }

  return (
    <LogProgressTile.Basic
      progressValue={measurementsTotal}
      measurementUnit={measurementUnit}
    />
  );
};
