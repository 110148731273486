import { createAsyncThunk } from '@reduxjs/toolkit';
import { QueryClient } from 'react-query';

import { api } from '~/services/api';
import { ChangeOrgActionPayload } from '~/store/common/actions/organizations/types';
import { updatePartnerPermissionsAction } from '~/store/common/actions/permissions/updatePartnerPermissionsAction';
import { authSlice } from '~/store/slices/auth';
import { ecosystemSlice } from '~/store/slices/ecosystem';
import { redirectSliceActions } from '~/store/slices/redirect';

export const changeOrgByIdAction = createAsyncThunk(
  'changeOrgByIdAction',
  async (orgId: string, { dispatch }): Promise<ChangeOrgActionPayload> => {
    try {
      const queryClient = new QueryClient();
      const { data } = await queryClient.fetchQuery(['ecosystem', orgId], () =>
        api.get<ChangeOrgActionPayload['data']>(
          `ecosystem/organizations/${orgId}`,
        ),
      );

      const selectedEcosystem = data?.[0];

      await Promise.all([
        dispatch(authSlice.actions.setOrganization(orgId)),
        dispatch(ecosystemSlice.actions.selectEcosystem(selectedEcosystem)),
        dispatch(updatePartnerPermissionsAction(selectedEcosystem?._id)),
      ]);
      dispatch(redirectSliceActions.clear());
      return { data, selectedEcosystem };
    } catch (error) {
      throw error;
    }
  },
);
