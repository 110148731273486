import { HStack, Stack, Text } from 'native-base';
import Input from 'ui/components/Input';
import Select from 'ui/components/Select';

import { Loading } from '~/components/Loading';

import { GroupDetailsProps } from './types';

export const GroupDetailsSection = ({
  control,
  appsConnectedToOrganizationOptions,
  errors,
  isLoading,
  typeOfUserOptions,
}: GroupDetailsProps) => {
  if (isLoading) {
    return <Loading containerHeight="50vh" spinnerSize="sm" />;
  }

  return (
    <Stack mt={8} minH="50vh">
      <Stack space={2}>
        <Text fontWeight={500} fontSize="xl" color="gray.600">
          Group details
        </Text>
        <Text fontSize="sm" color="gray.600">
          Give a name and a brief description to the group
        </Text>
      </Stack>
      <Stack mt={8} space={4}>
        <HStack justifyContent="space-between" maxWidth="620px" width="100%">
          <Stack width="48%">
            <Select
              placeholder="Select the app"
              name="app"
              control={control}
              label="App"
              options={appsConnectedToOrganizationOptions}
              errorMessage={errors?.app?.message}
            />
          </Stack>
          <Stack width="48%">
            <Select
              placeholder="Select the type"
              name="type"
              control={control}
              label="Type of user"
              options={typeOfUserOptions}
              errorMessage={errors?.type?.message}
            />
          </Stack>
        </HStack>
        <Input
          placeholder="Type the name of the group"
          name="name"
          control={control}
          label="Name"
          errorMessage={errors?.name?.message}
        />
        <Input
          placeholder="e.g. “Applies to any org admin” (max. 72 characters)"
          name="description"
          control={control}
          label="Description"
          errorMessage={errors?.description?.message}
        />
      </Stack>
    </Stack>
  );
};
