import { Avatar, Badge, Divider, HStack, Stack, Text } from 'native-base';
import Icon from 'ui/components/Icon';
import { ModalInformation } from 'ui/components/ModalInformation';
import { Organization } from 'ui/types/interfaces/organization';

import { SettingsSectionHeader } from '~/pages/Authenticated/Settings/components/SectionHeaders/SettingsSectionHeader';
import { CompanyDetailsSection } from '~/pages/Authenticated/Settings/General/components/Sections/CompanyDetails';
import { colors } from '~/theme/colors';
import { getAvatarFirstName } from '~/utils/functions';
import { transformURL } from '~/utils/transformURL';

import { DetailsCard } from '../components/DetailsCard';
import { styles } from '../styles';
import { ViewGeneralSettingsProps } from './types';
import { useViewGeneralSettingsController } from './useViewGeneralSettingsController';

const getOrganizationExternalIdSettingsProps = (hasExternalId: boolean) => {
  if (hasExternalId) {
    return {
      icon: 'check-circle',
      color: colors.primary[400],
      text: 'Enabled',
    };
  }

  return {
    icon: 'x-circle',
    color: colors.error[600],
    text: 'Disabled',
  };
};

export const ViewGeneralSettings = ({
  organization,
  organizationLogo,
  handleCopy,
  onRefetch,
}: ViewGeneralSettingsProps) => {
  const {
    color: externalIdColor,
    icon: externalIdIcon,
    text: externalIdText,
  } = getOrganizationExternalIdSettingsProps(
    !!organization?.organizationSettings?.hasExternalId,
  );

  const { modalInformationRef, openModalInformation } =
    useViewGeneralSettingsController();

  return (
    <Stack
      flexDir={{ base: 'column', lg: 'row' }}
      style={{ columnGap: 20 }}
      space={10}
    >
      <Stack space={3} alignItems={'center'}>
        <Text fontSize="sm" color="gray.600" fontWeight="500">
          Profile image
        </Text>
        <Avatar
          bgColor="muted.50"
          source={organizationLogo}
          height="128px"
          width="128px"
          _text={{ color: 'darkText' }}
        >
          {getAvatarFirstName(organization?.name || '')}
        </Avatar>
      </Stack>
      <Stack width="full" space={6} flex={1}>
        {/* Basic information section */}
        <SettingsSectionHeader title="Basic information" primary />

        <Stack space={2}>
          <SettingsSectionHeader title="Organisation" />

          <Text
            fontWeight="500"
            fontSize="lg"
            color={colors.singletons.darkText}
          >
            {organization?.name || ''}
          </Text>
        </Stack>

        <HStack justifyContent="space-between" flex={1}>
          <Stack space={2} flex={0.5}>
            <SettingsSectionHeader title="Causes" />

            <HStack space={2} flexWrap="wrap">
              {organization?.causeOptions?.map((cause) => (
                <Stack key={cause._id} m={1}>
                  <Badge borderRadius="2xl" _text={styles.badge._text}>
                    {cause.displayName}
                  </Badge>
                </Stack>
              ))}
            </HStack>
          </Stack>
          {organization?.purpose ? (
            <Stack space={2} flex={0.5}>
              <SettingsSectionHeader title="Purpose" />

              <Text
                fontSize="sm"
                fontWeight="400"
                color={colors.singletons.darkText}
              >
                {organization.purpose}
              </Text>
            </Stack>
          ) : null}
        </HStack>

        {/* Corporate exclusive section  */}
        <CompanyDetailsSection
          organization={organization as Organization}
          onRefetch={onRefetch}
          editMode={false}
        />

        {/* Contact Information section */}
        <Stack mt={12} space={6}>
          <SettingsSectionHeader title="Contact information" primary />

          <HStack justifyContent="space-between">
            <Stack space={8} flex={1} width="100%">
              {organization?.contactPhoneNumber ? (
                <DetailsCard
                  icon="phone"
                  title="Contact phone number"
                  value={organization.contactPhoneNumber}
                  onCopy={() =>
                    handleCopy(
                      'Contact phone number',
                      organization.contactPhoneNumber || '',
                    )
                  }
                />
              ) : null}
              {organization?.contactEmail ? (
                <DetailsCard
                  icon="mail"
                  title="Contact email address"
                  value={organization.contactEmail}
                  onCopy={() =>
                    handleCopy(
                      'Contact email address',
                      organization.contactEmail || '',
                    )
                  }
                />
              ) : null}
              <DetailsCard
                icon="map-pin"
                title="Full address"
                value={organization?.fullAddress?.street || '-'}
              />

              <DetailsCard
                icon="globe"
                title="URL"
                value={
                  organization?.websiteLink ? (
                    <a
                      style={styles.link}
                      href={transformURL(organization.websiteLink)}
                      target="_blank"
                      rel="noreferrer"
                    >
                      {transformURL(organization.websiteLink)}
                    </a>
                  ) : (
                    '-'
                  )
                }
              />
              <DetailsCard
                title="Terms of services"
                value={
                  organization?.termsOfServicesLink ? (
                    <a
                      style={styles.link}
                      href={transformURL(organization.termsOfServicesLink)}
                      target="_blank"
                      rel="noreferrer"
                    >
                      {transformURL(organization.termsOfServicesLink)}
                    </a>
                  ) : (
                    '-'
                  )
                }
              />
            </Stack>

            <Stack space={2} flex={1}>
              <SettingsSectionHeader title="Description" />

              <Text>{organization?.description || '-'}</Text>
            </Stack>
          </HStack>
        </Stack>

        {/* Settings Sections */}
        <Stack mt={10} space={6}>
          <SettingsSectionHeader title="Settings" primary />

          <Stack space={2} flex={1}>
            <SettingsSectionHeader
              title="External ID"
              showTitleHelpButton
              onTitleHelpButtonPress={openModalInformation}
            />

            <HStack space={2} alignItems="center">
              <Icon icon={externalIdIcon} size={18} color={externalIdColor} />
              <Text>{externalIdText}</Text>
            </HStack>
          </Stack>
        </Stack>
      </Stack>

      {/* Modals */}
      <ModalInformation headerText="External ID" modalRef={modalInformationRef}>
        <Text mt={2} fontSize="md" color="gray.600">
          The External ID is a unique identification number assigned to each
          volunteer on the platform. This number serves as a seamless reference
          point for organisations using an external volunteering management
          platform. They can input the External ID within Do IT&apos;s platform
          to efficiently link and manage volunteer information. It is
          prominently displayed in both the list of volunteers and individual
          volunteer profiles. To associate or edit the External ID, access the
          field through the options button on the list of volunteers, or during
          in the invitation process. Admins have the flexibility to enable or
          disable the External ID field in the{' '}
          <Text fontWeight={500}>Organization Settings &gt; General.</Text>
        </Text>
      </ModalInformation>
    </Stack>
  );
};
