import { Avatar, HStack, Switch, Text, VStack } from 'native-base';
import Button from 'ui/components/Button';
import Icon from 'ui/components/Icon';
import { AppVisibility } from 'ui/enums';

import { colors } from '~/theme/colors';

import { SelectedAppCardProps } from './types';
import { useSelectedAppCardController } from './useSelectedAppCardController';

export const SelectedAppCard = ({
  app,
  isAppSharingEnabled = false,
  showToggle = false,
  onChangeSwitch,
  onDelete,
}: SelectedAppCardProps) => {
  const { on, handleOnChange } = useSelectedAppCardController(onChangeSwitch);

  return (
    <VStack
      p="4"
      bg={colors.singletons.lightText}
      rounded="2xl"
      borderWidth="1"
      borderColor="gray.200"
      space="6"
    >
      <HStack alignItems="center" justifyContent="space-between">
        <Avatar rounded="xl" w="40px" h="40px" source={{ uri: app.logo }} />
        <HStack space="1" alignItems="center">
          <Icon
            icon={app.visibility === AppVisibility.Private ? 'lock' : 'eye'}
            size="16"
            color={colors.gray[600]}
          />
          <Text color="gray.600">
            {app.visibility === AppVisibility.Private ? 'Private' : 'Public'}
          </Text>
        </HStack>
      </HStack>
      <HStack alignItems="center">
        <Text fontSize="lg" numberOfLines={2} height="3.4rem">
          {app.name}
        </Text>
      </HStack>
      <HStack flex="1" justifyContent="space-between" alignItems="center">
        <Button
          w="24"
          minW="auto"
          variant="outline"
          rounded="2xl"
          borderColor="error.400"
          leftIconName="x-circle"
          leftIconColor={colors.red[600]}
          onPress={onDelete}
        >
          <Text color="error.600">Remove</Text>
        </Button>
        {showToggle ? (
          <HStack alignItems="center" space={2}>
            <Text fontWeight={500} color={on ? 'gray.400' : 'primary.400'}>
              OFF
            </Text>
            <Switch
              onValueChange={handleOnChange}
              isChecked={isAppSharingEnabled}
            />
            <Text fontWeight={500} color={on ? 'primary.400' : 'gray.400'}>
              ON
            </Text>
          </HStack>
        ) : null}
      </HStack>
    </VStack>
  );
};
