import { isSameDay, isValid as isValidDate } from 'date-fns';
import { FlatList, PresenceTransition, Stack } from 'native-base';
import { useCallback } from 'react';
import { FeedScaffold } from 'ui/components/v2/FeedScaffold';
import {
  FeedNotification,
  FeedNotificationPayload,
} from 'ui/types/interfaces/feed-notification';

import { UpdatesFeedEmptyCard } from '~/pages/Authenticated/Dashboard/components/UpdatesFeed/fragments/UpdatesFeedEmptyCard';
import { UpdatesFeedHeader } from '~/pages/Authenticated/Dashboard/components/UpdatesFeed/fragments/UpdatesFeedHeader';
import { UpdatesFeedItem } from '~/pages/Authenticated/Dashboard/components/UpdatesFeed/fragments/UpdatesFeedItem';

import { useUpdatesFeedController } from './controller';
import { DateDivider } from './fragments/DateDivider';
import { UpdatesFeedProps } from './types';

export const UpdatesFeed = (props: UpdatesFeedProps) => {
  const {
    apps,
    count,
    isLoading,
    feedUpdates,
    hasNextPage,
    isRefetching,
    selectedApps,
    isFetchingNextPage,
    handlerInviteNewMember,
    handleCategoryChange,
    handleSelectNewApp,
    handleRefetchData,
    fetchNextPage,
  } = useUpdatesFeedController(props);

  const renderItem = useCallback(
    ({
      item,
      index,
    }: {
      item: FeedNotification<FeedNotificationPayload>;
      index: number;
    }) => {
      const prevItem = index > 0 ? feedUpdates[index - 1] : null;
      const currentDate = new Date(item?.notificationDate);

      let showDivider = false;
      if (!!prevItem) {
        const prevDate = new Date(prevItem?.notificationDate);
        if (isValidDate(currentDate) && isValidDate(prevDate)) {
          showDivider = !isSameDay(currentDate, prevDate);
        }
      }

      return (
        <PresenceTransition
          visible={true}
          initial={{ opacity: 0.5 }}
          animate={{ opacity: 1, transition: { duration: 150 } }}
        >
          <Stack mb={4}>
            {!!showDivider && <DateDivider date={currentDate} />}
            <UpdatesFeedItem data={item} />
          </Stack>
        </PresenceTransition>
      );
    },
    [feedUpdates],
  );

  return (
    <FeedScaffold
      resourceName="updates"
      headerSlot={
        <UpdatesFeedHeader
          selectedAppIds={selectedApps}
          onAppChange={handleSelectNewApp}
          onCategoryChange={handleCategoryChange}
          key={apps.length}
          apps={apps}
        />
      }
      emptyStateSlot={
        <UpdatesFeedEmptyCard onAction={handlerInviteNewMember} />
      }
      dataCount={feedUpdates.length}
      futureDataCount={count}
      isLoading={isLoading}
      hasNextPage={hasNextPage}
      isRefetching={isRefetching}
      isFetchingNextPage={isFetchingNextPage}
      fetchNextPage={fetchNextPage}
      refetch={handleRefetchData}
    >
      <FlatList
        data={feedUpdates}
        keyExtractor={(item: FeedNotification<FeedNotificationPayload>) =>
          item._id
        }
        renderItem={renderItem}
      />
    </FeedScaffold>
  );
};

export default UpdatesFeed;
