import SelectFilter from '@inovua/reactdatagrid-community/SelectFilter';
import { HStack, Image, Text } from 'native-base';
import DataTableActions from 'ui/components/DataTableActions';
import { TableCellWithImage } from 'ui/components/TableCellWithImage';
import { PermissionType } from 'ui/enums';
import { capitalize } from 'ui/utils/capitalize';

import { useRouter } from '~/hooks/useRouter';
import { colors } from '~/theme/colors';
import {
  AppPermissionGroupTable,
  TableAppPermissionsGroupColumnsProps,
} from '~/types/interfaces/table';

const ActionsColumn = ({ permissionId }: { permissionId: string }) => {
  const { goToRoute } = useRouter();

  return (
    <DataTableActions
      editButton
      editText="Change permissions"
      onPressEditButton={() => goToRoute(`/app-permissions/${permissionId}`)}
      color={colors.primary['400']}
      containerWidth="100%"
      editIconSize="16px"
    />
  );
};

export const tableColumns = ({
  connectedApps,
}: TableAppPermissionsGroupColumnsProps) => [
  {
    name: 'name',
    header: 'Name',
    defaultFlex: 2,
    filterable: true,
    render: ({ data }: AppPermissionGroupTable) => {
      return data?.name || '';
    },
  },
  {
    name: 'app',
    header: 'App',
    defaultFlex: 1,
    filterEditor: SelectFilter,
    filterable: true,
    filterEditorProps: {
      multiple: false,
      wrapMultiple: false,
      dataSource:
        connectedApps?.length &&
        connectedApps.map((connectedApp) => {
          return {
            id: connectedApp.app?._id,
            label: connectedApp.app?.name,
          };
        }),
    },
    render: ({ data }: AppPermissionGroupTable) => {
      return (
        <TableCellWithImage
          name={data.app?.name || ''}
          logo={data.app?.logo || ''}
        />
      );
    },
  },
  {
    name: 'type',
    header: 'Type of user',
    defaultFlex: 0.5,
    filterEditor: SelectFilter,
    filterable: true,
    filterEditorProps: {
      multiple: false,
      wrapMultiple: false,
      dataSource: Object.values(PermissionType)
        .filter((p) => p === PermissionType.MEMBER)
        .map((permissionType: PermissionType) => {
          return {
            id: permissionType,
            label: capitalize(permissionType),
          };
        }),
    },
    render: ({ data }: AppPermissionGroupTable) => {
      return capitalize(data.type || '');
    },
  },
  // {
  //   name: 'actions',
  //   header: 'Actions',
  //   defaultFlex: 1,
  //   textAlign: 'center',
  //   render: ({ data }: AppPermissionGroupTable) => {
  //     return <ActionsColumn permissionId={data._id} />;
  //   },
  // },
];
