import { Hidden, HStack, Text, useBreakpointValue } from 'native-base';
import { useMemo } from 'react';
import Icon from 'ui/components/Icon';
import { colors } from 'ui/theme/colors';

import { getOptionGroups } from '~/components/MenuDropdown/constants';
import { useMenuDropdownController } from '~/components/MenuDropdown/controller';
import { MenuPopOverOptions } from '~/components/MenuDropdown/popover';
import ModalSignOut from '~/components/ModalSignOut';
import { useCurrentOrganization } from '~/hooks/organization/useCurrentOrganization';

export const MenuDropdown = () => {
  const {
    modalRef,
    handleCloseSignOutModal,
    handleOptionPress,
    handleSignOut,
  } = useMenuDropdownController();

  const isMobile = useBreakpointValue({ base: true, lg: false });
  const { isNFP } = useCurrentOrganization();

  const optionGroups = useMemo(() => {
    return getOptionGroups({ isMobile, isCompany: !isNFP });
  }, [isMobile, isNFP]);

  return (
    <>
      <MenuPopOverOptions
        optionGroups={optionGroups}
        onPress={handleOptionPress}
      >
        <HStack
          p={{ base: 3, md: 2 }}
          pr={3}
          borderWidth={1}
          borderColor={colors.gray[200]}
          background={colors.white}
          borderRadius={100}
          alignItems={'center'}
          space={{ base: 0, md: 2 }}
        >
          <Icon icon="menu" size={20} color={colors.gray[500]} />

          <Hidden till={'md'}>
            <>
              <Text
                fontWeight={500}
                color={colors.gray[500]}
                fontSize={'sm'}
                marginRight={1}
              >
                Menu
              </Text>
              <Icon
                icon="chevron-down"
                size={'16px'}
                color={colors.gray[500]}
              />
            </>
          </Hidden>
        </HStack>
      </MenuPopOverOptions>

      <ModalSignOut
        modalRef={modalRef}
        closeModal={() => handleCloseSignOutModal()}
        signOut={handleSignOut}
      />
    </>
  );
};
