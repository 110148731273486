import { INPUT_COMPONENT_TYPES } from 'ui/components/FormBuilder';
import { IOptionsV2 } from 'ui/components/FormBuilderV2/types';

import { ICreateMediaFormSchemaProps } from '~/types/interfaces/forms';

export const createFormSchema = ({
  handleBack,
  defaultImages,
  defaultCoverImage,
}: ICreateMediaFormSchemaProps): IOptionsV2 => ({
  header: {
    title: 'Images',
    subTitle: 'Add photos to show what the event is about',
    isShowDivider: false,
  },

  inputsSections: [
    {
      key: 'extraImages-section',
      sectionInputRows: [
        {
          key: 'title-row',
          inputs: [
            {
              id: 'coverImage',
              name: 'coverImage',
              isRequired: false,
              component: INPUT_COMPONENT_TYPES.DROPZONE_AREA,
              inputProps: {
                label: 'Cover',
                defaultImage: defaultCoverImage,
              },
            },
          ],
        },
      ],
    },
    {
      key: 'extraImagesSection',
      sectionInputRows: [
        {
          key: 'extraImages-row',
          inputs: [
            {
              id: 'carouselImages',
              name: 'carouselImages',
              isRequired: false,
              component: INPUT_COMPONENT_TYPES.UPLOAD_FILES,
              inputProps: {
                label: 'Extra Images',
                defaultImages,
              },
            },
          ],
        },
      ],
    },
    // {
    //   sectionTitle: 'Video',
    //   sectionSubtitle: 'Add a video embed link to show more about the event',
    //   key: 'external-link-section',
    //   sectionInputRows: [
    //     {
    //       key: 'video-input-row',
    //       inputs: [
    //         {
    //           id: 'video',
    //           name: 'video',
    //           isRequired: false,
    //           component: INPUT_COMPONENT_TYPES.INPUT,
    //           inputProps: {
    //             label: 'Embed link',
    //             placeholder: 'Paste the video embed link here',
    //             leftIconName: 'youtube',
    //             leftIconColor: colors.gray[400],
    //             leftIconSize: '16',
    //           },
    //         },
    //       ],
    //     },
    //   ],
    // },
  ],

  actions: {
    options: {
      alignItems: 'center',
      justifyContent: 'space-around',
      spacingBetweenItems: 2,
    },
    items: [
      {
        id: 'reset',
        name: 'back',
        text: 'Back',
        buttonProps: {
          variant: 'ghost',
          flex: 1,
        },
        onClick: handleBack,
        textProps: { color: 'primary.400', w: '100%' },
      },
      {
        id: 'submit',
        name: 'submit',
        isSubmitButton: true,
        text: 'Continue',
        buttonProps: {
          flex: 1,
        },
        textProps: { color: 'white' },
      },
    ],
  },
});
