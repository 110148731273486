import 'rc-slider/assets/index.css';

import { HStack, Text, VStack } from 'native-base';
import RcSlider from 'rc-slider';

import { sliderStyle } from './constants';

export type ImageCropperZoomSliderProps = {
  value: number;
  onChange: (zoomValue: number) => void;
  min: number;
  max: number;
};
export const ImageCropperZoomSlider = ({
  max,
  min,
  onChange,
  value,
}: ImageCropperZoomSliderProps) => {
  return (
    <VStack flex={1} alignItems="center" w="100%" px={4} space={2}>
      <RcSlider
        min={min}
        max={max}
        value={value}
        step={0.25}
        defaultValue={value}
        styles={sliderStyle}
        onChange={(v) => onChange(v as number)}
      />
      <HStack justifyContent={'space-between'} w="full">
        <Text color="gray.900" fontSize="xs" fontWeight="500">
          {min}x
        </Text>
        <Text color="gray.900" fontSize="xs" fontWeight="500">
          {max}x
        </Text>
      </HStack>
    </VStack>
  );
};
