export const actionFormTabsElements = [
  {
    title: 'About the Action',
    component: () => <></>,
  },
  {
    title: 'Media',
    component: () => <></>,
  },
  {
    title: 'Where',
    component: () => <></>,
  },
];
