import { Box, HStack, Skeleton, Stack, Text } from 'native-base';
import ReportCards from 'ui/components/ReportCards';

import { colors } from '~/theme/colors';

import { secondsToHoursMinutesString } from '../../../../../../utils/date';
import { ActivitiesReportSectionProps } from './types';

export const ActivitiesReportSection = ({
  totalLogHours,
  eventsCount,
  actionsCount,
  ongoingsCount,
  eventsLogHoursCount,
  actionsLogHoursCount,
  ongoingsLogHoursCount,
  isLoadingReports,
  onOpenActionModal,
}: ActivitiesReportSectionProps) => {
  return (
    <HStack space={6} width="100%" justifyContent="space-between" mb={6}>
      <Box flex={0.25}>
        <ReportCards
          withBorder
          icon="clock"
          title="Hours logged"
          value={secondsToHoursMinutesString(totalLogHours)}
          valueInfo="Total hours achieved"
          dividerColor={colors.singletons.darkBlue}
          iconColor={colors.singletons.darkBlue}
          paddingTitle={0}
          cardHeight="2/8"
          isLoading={isLoadingReports}
        />
      </Box>
      <Box flex={0.25}>
        <ReportCards
          withBorder
          icon="calendar"
          value={eventsCount}
          valueInfo="Applications"
          title="Events"
          dividerColor="lime.500"
          iconColor={colors.lime['500']}
          paddingTitle={0}
          cardHeight="2/8"
          isLoading={isLoadingReports}
          direction="column"
          contentChildren={
            <Stack
              direction={{ md: 'column', lg: 'column', xl: 'row' }}
              space={2}
              alignItems="baseline"
            >
              {isLoadingReports ? (
                <Box w="50%" fontSize="2xl">
                  <Skeleton />
                </Box>
              ) : (
                <>
                  <Text fontSize="xl" fontWeight="700" color="gray.900">
                    {secondsToHoursMinutesString(eventsLogHoursCount || 0)}
                  </Text>
                  <HStack alignItems="center" space={1}>
                    <Text fontSize="xs" color="gray.500" fontWeight="500">
                      time in events
                    </Text>
                  </HStack>
                </>
              )}
            </Stack>
          }
        />
      </Box>
      <Box flex={0.25}>
        <ReportCards
          withBorder
          icon="puzzle-tertiary"
          value={actionsCount}
          valueInfo="Applications"
          title="Actions"
          dividerColor="orange.500"
          iconColor={colors.orange['500']}
          isIconCustom
          paddingTitle={0}
          cardHeight="2/8"
          isLoading={isLoadingReports}
          // actionButtonName="More"
          action={onOpenActionModal}
          direction="column"
          contentChildren={
            <Stack
              direction={{ md: 'column', lg: 'column', xl: 'row' }}
              space={2}
              alignItems="baseline"
            >
              {isLoadingReports ? (
                <Box w="50%" fontSize="2xl">
                  <Skeleton />
                </Box>
              ) : (
                <>
                  <Text fontSize="xl" fontWeight="700" color="gray.900">
                    {secondsToHoursMinutesString(actionsLogHoursCount || 0)}
                  </Text>
                  <HStack alignItems="center" space={1}>
                    <Text fontSize="xs" color="gray.500" fontWeight="500">
                      time in actions
                    </Text>
                  </HStack>
                </>
              )}
            </Stack>
          }
        />
      </Box>
      <Box flex={0.25}>
        <ReportCards
          withBorder
          icon="repeat"
          value={ongoingsCount}
          valueInfo="Applications"
          title="Ongoing opportunities"
          dividerColor="purple.500"
          iconColor={colors.purple['500']}
          paddingTitle={0}
          cardHeight="2/8"
          isLoading={isLoadingReports}
          direction="column"
          contentChildren={
            <Stack
              direction={{ md: 'column', lg: 'column', xl: 'row' }}
              space={2}
              alignItems="baseline"
            >
              {isLoadingReports ? (
                <Box w="50%" fontSize="2xl">
                  <Skeleton />
                </Box>
              ) : (
                <>
                  <Text fontSize="xl" fontWeight="700" color="gray.900">
                    {secondsToHoursMinutesString(ongoingsLogHoursCount || 0)}
                  </Text>
                  <HStack alignItems="center" space={1}>
                    <Text fontSize="xs" color="gray.500" fontWeight="500">
                      time in ongoing op.
                    </Text>
                  </HStack>
                </>
              )}
            </Stack>
          }
        />
      </Box>
    </HStack>
  );
};
