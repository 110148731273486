import { HStack, Stack, Text } from 'native-base';
import React from 'react';
import Button from 'ui/components/Button';
import Input from 'ui/components/Input';
import { Select } from 'ui/components/Select';

import { HelpCentreRedirectLink } from '~/components/HelpCentre';

import { useBasicInformationFormController } from '../BasicInformationForm/controller';
import { IBasicInformationFormProps } from '../types';
import { styleConfig } from './styleConfig';

const BasicInformationForm = ({
  control,
  errors,
  organisationTypes,
  causeOptions,
  isDisabledButton,
  handleContinue,
  handleBack,
}: IBasicInformationFormProps) => {
  const { isMobile } = useBasicInformationFormController();

  return (
    <Stack space={5} mt={8} p={2} w="100%">
      <Stack paddingRight={6}>
        <HelpCentreRedirectLink />
      </Stack>
      <Stack w="100%" space={2}>
        <Text fontSize="20px" color="gray.600" fontWeight={500}>
          Basic information
        </Text>
        <Text color="gray.600" fontWeight={400} fontSize="sm">
          Fill in general information about the organisation.
        </Text>
      </Stack>
      <Input
        name="name"
        control={control}
        placeholder="Type your organisation name"
        label="Organisation"
        isRequired
        errorMessage={errors.name?.message}
        isFullWidth
      />
      <Select
        name="type"
        label="Type"
        control={control}
        isRequired
        placeholder="Select your organisation type"
        options={organisationTypes}
      />
      <Select
        name="causeOptions"
        control={control}
        isMulti
        isRequired
        placeholder="Select at least one cause"
        options={causeOptions}
        label="Causes"
        errorMessage={errors.causeOptions?.message}
      />
      <Input
        name="purpose"
        multiline
        control={control}
        numberOfLines={5}
        errorMessage={errors.purpose?.message}
        isFullWidth
        label="Purpose"
        isRequired
        placeholder="Describe in a few words the purpose of your organisation..."
      />

      <HStack
        w="full"
        flexDirection={styleConfig.buttonStackDirection}
        alignItems="center"
        space={2}
        mt="40px"
      >
        {isMobile ? null : (
          <Stack w={styleConfig.buttonWidth}>
            <Button variant="ghost" width="100%" onPress={handleBack}>
              Back
            </Button>
          </Stack>
        )}
        <Stack w={styleConfig.buttonWidth}>
          <Button
            onPress={handleContinue}
            isDisabled={isDisabledButton}
            width="100%"
          >
            Continue
          </Button>
        </Stack>
        {isMobile ? (
          <Stack w={styleConfig.buttonWidth} mt={2}>
            <Button variant="ghost" width="100%" onPress={handleBack}>
              Back
            </Button>
          </Stack>
        ) : null}
      </HStack>
    </Stack>
  );
};

export default React.memo(BasicInformationForm);
