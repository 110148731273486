export const tabsElements = [
  {
    title: 'Activities',
    component: () => <></>,
  },
  {
    title: 'Members',
    component: () => <></>,
  },
  {
    title: 'Information',
    component: () => <></>,
  },
  {
    title: 'Access and Permissions',
    component: () => <></>,
  },
];
