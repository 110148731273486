import { Avatar, HStack, Image, Stack, Text } from 'native-base';
import { PopOverOptions } from 'ui/components/PopOverOptions';
import Tabs from 'ui/components/Tabs';
import { getNameInitials } from 'ui/utils/getNameInititals';

import PageHeader from '~/components/Layout/PageHeader';
import { Loading } from '~/components/Loading';
import ModalDelete from '~/components/ModalDelete';
import Scaffold from '~/components/Scaffold';
import { colors } from '~/theme/colors';

import { usePartnerController } from './controller';
import { PartnerAccess } from './PartnerAccess';
import { PartnerActivities } from './PartnerActivities';
import { PartnerDetails } from './PartnerDetails';
import { tabsElements } from './tabElements';

export const ViewPartner = () => {
  const {
    organization,
    isOrganizationLoading,
    connection,
    isConnectionLoading,
    popOverOptions,
    tabIndex,
    modalRef,
    isLoadingEcosystemConnections,
    isSubmitting,
    // partner,
    handleCloseModal,
    handleRemovePartner,
    handleChangeTab,
  } = usePartnerController();

  if (
    isLoadingEcosystemConnections &&
    isConnectionLoading &&
    isOrganizationLoading
  ) {
    return <Loading spinnerSize="sm" />;
  }

  if (!organization || !connection) {
    return <Loading spinnerSize="sm" />;
  }

  return (
    <Scaffold
      title={
        <HStack width="100%" alignItems="center" justifyContent="space-between">
          <HStack space={4} alignItems="center">
            <Avatar
              rounded={6}
              _image={{ rounded: 6 }}
              source={{ uri: organization?.logo }}
              width={'2.5rem'}
              height={'2.5rem'}
            >
              {getNameInitials(organization.name || '')}
            </Avatar>

            <PageHeader title={organization?.name || ''} />
          </HStack>
          {/* <PopOverOptions
            popovText="Options"
            popovTextPosition="right"
            popovIcon="more-vertical"
            options={popOverOptions}
          /> */}
        </HStack>
      }
      withBg={false}
    >
      <Stack width="100%">
        <Tabs
          tabWith="100%"
          tabBgColor="gray.200"
          tabIndicatorColor={colors.primary['400']}
          elements={tabsElements}
          onChangeTab={handleChangeTab}
          isClickableTab={true}
        />
        <Stack width="100%" mt={10}>
          {tabIndex === 0 ? (
            <PartnerActivities
              organization={organization}
              connection={connection}
            />
          ) : null}
          {tabIndex === 1 ? (
            <PartnerDetails
              organization={organization}
              isLoadingOrganization={isOrganizationLoading}
            />
          ) : null}
          {tabIndex === 2 ? (
            <PartnerAccess
              organization={organization}
              connection={connection}
            />
          ) : null}
        </Stack>
      </Stack>

      <ModalDelete
        modalRef={modalRef}
        headerText="Remove partner"
        handleCloseModal={handleCloseModal}
        handleDelete={handleRemovePartner}
        rightBtnLoading={isSubmitting}
        rightBtnText="Remove"
      >
        <Stack space={5}>
          <Text fontWeight={400} fontSize="md" color="gray.500">
            Are you sure that you want to remove this partner?
          </Text>

          <Text fontWeight={400} fontSize="md" color="gray.500">
            The members and admins will no longer have access to the ecosystem
            and will lose all permissions associated with it.
          </Text>
        </Stack>
      </ModalDelete>
    </Scaffold>
  );
};
