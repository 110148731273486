import { CorpSideCard as BaseCorpSideCard } from './BaseCorpSideCard';
import { CorpAppsSideCard } from './CorpAppsSideCard';
import { CorpEmployeesSideCard } from './CorpEmployeesSideCard';
import { CorpFeaturedActivitySideCard } from './CorpFeaturedActivitySideCard';

export const CorpSideCard = {
  Base: BaseCorpSideCard,
  Employees: CorpEmployeesSideCard,
  FeaturedActivity: CorpFeaturedActivitySideCard,
  Apps: CorpAppsSideCard,
};
