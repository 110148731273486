import { Stack, VStack } from 'native-base';
import FormHeader from 'ui/components/FormBuilder/components/FormHeader';

import { AddEmailInputComponent } from './AddEmailInputComponent';
import { CopyInviteLinkComponent } from './CopyInviteComponent';
import { EmailListComponent } from './EmailListComponent';
import { useInvitationFormSectionController } from './invitationFormSectionController';
import { IEmployee, IInvitationFormSectionProps } from './types';

export const InvitationFormSection = ({
  control,
  invitationLink,
  errors,
  organization,
  memberType,
}: IInvitationFormSectionProps) => {
  const {
    employeesEmailsArray,
    field,
    fieldState,
    handleAppendEmail,
    handleRemoveEmail,
  } = useInvitationFormSectionController({
    control,
    errors,
    memberType,
  });

  return (
    <Stack justifyContent="center" w="100%" alignItems="center" p={6}>
      <VStack maxW="620px" width="100%">
        <FormHeader
          title="Invitation"
          subTitle="Invite someone to join your organisation by email, or obtain a direct invitation link at the conclusion of this process."
        />
        <CopyInviteLinkComponent link={invitationLink} />
        <AddEmailInputComponent
          onAddEmail={handleAppendEmail}
          onChange={field.onChange}
          value={field.value}
          isInvalid={fieldState.invalid || !field.value}
          errors={errors}
          control={control}
          organization={organization}
          memberType={memberType}
        />
        <EmailListComponent
          emails={employeesEmailsArray as IEmployee[]}
          onRemoveEmail={handleRemoveEmail}
        />
      </VStack>
    </Stack>
  );
};
