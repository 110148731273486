import { Stack, Text } from 'native-base';
import Button from 'ui/components/Button';
import Input from 'ui/components/Input';

import { colors } from '~/theme/colors';

import { ConfirmEmailPageProps } from '../types';

export const ConfirmEmailPage = ({
  control,
  coolDownTime,
  errors,
  isSubmitting,
  isResendCodeDisabled,
  isDisabled,
  email,
  handleResendCode,
  onVerifyCode,
}: ConfirmEmailPageProps) => {
  return (
    <Stack
      width={{ base: '100%', lg: '45%' }}
      height="100%"
      justifyContent="center"
      paddingLeft={{ base: 5, lg: '6.3rem' }}
      padding={{ base: 5, lg: 0 }}
      bgColor={colors.singletons.lightText}
    >
      <Stack>
        <Text
          textAlign="initial"
          fontSize="2xl"
          fontWeight={500}
          color="gray.900"
          maxWidth={{ base: '100%', lg: '45%' }}
        >
          Enter the code we sent you to confirm your email address
        </Text>
        <Text fontSize="lg" mt="3" color="gray.900">
          We sent a code to{' '}
          <Text fontSize="lg" color="primary.900" fontWeight="500">
            {email}
          </Text>
        </Text>
      </Stack>

      <Stack width={{ base: '100%', lg: '80%' }} mt="4">
        <Input
          name="accessCode"
          control={control}
          placeholder="Type the code we sent to you"
          rightIconName="key"
          keyboardType="numeric"
          leftIconColor={colors.muted[400]}
          label="Code"
          errorMessage={errors.accessCode?.message}
          iconErrorMessage="info"
          p={4}
          isFullWidth
          borderColor="muted.200"
        />
      </Stack>

      <Stack width={{ base: '100%', lg: '80%' }} mt={12} mb={3}>
        <Button
          onPress={onVerifyCode}
          p={6}
          isDisabled={isDisabled}
          variant="solid"
          isLoading={isSubmitting}
          bgColor="gray.900"
        >
          <Text fontSize="medium" color="white" fontWeight="medium">
            Confirm email address
          </Text>
        </Button>
      </Stack>

      <Stack width={{ base: '100%', lg: '80%' }} mb={6}>
        <Button
          onPress={handleResendCode}
          p={6}
          variant="ghost"
          isDisabled={isResendCodeDisabled}
        >
          <Text fontSize="medium" fontWeight="medium">
            {isResendCodeDisabled ? coolDownTime : 'Resend code'}
          </Text>
        </Button>
      </Stack>
    </Stack>
  );
};
