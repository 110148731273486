import { yupResolver } from '@hookform/resolvers/yup';
import { useEffect, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';

import { COMMON_MESSAGES } from '~/constants/messages.constants';
import { PAGES } from '~/constants/pages.constants';
import { useAppDispatch } from '~/hooks/useAppDispatch';
import { useAppSelector } from '~/hooks/useAppSelector';
import { useRouter } from '~/hooks/useRouter';
import AuthService from '~/services/resources/auth';
import { forgotPasswordSliceActions } from '~/store/slices/forgotPassword';
import { IRequestForgotPassword } from '~/types/dtos';

import { defaultValues, schemaValidation } from './utils';

export const useForgotPasswordController = () => {
  const { goToRoute } = useRouter();

  const { currentStep } = useAppSelector(
    ({ forgotPassword }) => forgotPassword,
  );
  const dispatch = useAppDispatch();

  const {
    control,
    handleSubmit,
    formState: { errors, isValid, isDirty, isSubmitting },
  } = useForm<IRequestForgotPassword>({
    resolver: yupResolver(schemaValidation),
    defaultValues,
  });

  const disableSubmit = useMemo(
    () => !isValid || !isDirty || isSubmitting,
    [isValid, isDirty, isSubmitting],
  );

  const onSubmit = handleSubmit(async ({ email }) => {
    try {
      const verifiedResponse = await AuthService.isUserVerified(email);

      if (!verifiedResponse.data.verified) {
        await AuthService.resendConfirmationCode({ email });
        toast.info(COMMON_MESSAGES.UNVERIFIED_ACCOUNT);
        dispatch(forgotPasswordSliceActions.update({ email }));
        goToRoute(PAGES.RECOVERY_PASSWORD, {
          state: { isUserUnverified: true },
        });
      } else {
        await AuthService.requestForgotPassword({ email });
        toast.success(COMMON_MESSAGES.ACCESS_CODE_SENT);
        dispatch(forgotPasswordSliceActions.update({ email }));
        goToRoute(PAGES.RECOVERY_PASSWORD, {
          state: {
            isUserUnverified: false,
          },
        });
      }
    } catch (error) {
      toast.error(COMMON_MESSAGES.UNABLE_SEND_ACCESS_CODE);
    }
  });

  useEffect(() => {
    if (currentStep !== 0) {
      dispatch(forgotPasswordSliceActions.clear());
    }
  }, [currentStep, dispatch]);

  return {
    isValid,
    control,
    isDirty,
    disableSubmit,
    onSubmit,
    errors,
    isSubmitting,
  };
};
