import { Box, HStack, Text, VStack } from 'native-base';
import Button from 'ui/components/Button';
import Illustration, { ILLUSTRATIONS_NAME } from 'ui/components/Illustration';
import { colors } from 'ui/theme/colors';

import { UpdatesFeedEmptyCardProps } from '~/pages/Authenticated/Dashboard/components/UpdatesFeed/fragments/UpdatesFeedEmptyCard/types';

export const UpdatesFeedEmptyCard = ({
  onAction,
}: UpdatesFeedEmptyCardProps) => {
  return (
    <VStack
      space={4}
      margin="auto"
      maxW={'600px'}
      height={'548px'}
      alignItems={'center'}
      justifyContent={'center'}
    >
      <HStack
        space={4}
        style={{ rowGap: 4 }}
        alignItems={'center'}
        flexDirection={{ base: 'column', md: 'row' }}
      >
        <Box w={24} h={24} overflow={'hidden'}>
          <Illustration
            name={ILLUSTRATIONS_NAME.NO_SIGNAL_CONNECTION_ERROR_BLACK}
            width={'full'}
            height={'full'}
          />
        </Box>
        <Text textAlign={'center'} color="gray.500" fontSize={'sm'}>
          No updates to show. Be sure to invite employees and partners to join
          the ecosystem, and you’ll be able to view their activity here.
        </Text>
      </HStack>

      <Button
        py={5}
        maxW={'185px'}
        variant="outline"
        leftIconSize={24}
        leftIconName="plus-circle"
        borderColor={colors.primary[200]}
        leftIconColor={colors.primary[400]}
        onPress={onAction}
      >
        <Text fontSize={'md'} fontWeight="500" color="primary.400">
          Invite employee
        </Text>
      </Button>
    </VStack>
  );
};
