export const eventFormTabsElements = [
  {
    title: 'About the Event',
    component: () => <></>,
  },
  {
    title: 'Media',
    component: () => <></>,
  },
  {
    title: 'When and Where',
    component: () => <></>,
  },
];
