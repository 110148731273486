import { yupResolver } from '@hookform/resolvers/yup';
import { useClipboard } from 'native-base';
import { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import {
  Organization,
  OrganizationSettings,
} from 'ui/types/interfaces/organization';
import { isBase64Image, isUrlImage } from 'ui/utils/validations/url';

import { ORGANIZATION_MESSAGES } from '~/constants/messages.constants';
import { useAppSelector } from '~/hooks/useAppSelector';
import { useQuery } from '~/hooks/useQuery';
import { OrganizationFormFields } from '~/pages/Authenticated/Settings/General/types';
import { OrganizationService } from '~/services/resources/organization';
import { IPaginatedResponse } from '~/types';
import { ICauseOptions } from '~/types/interfaces/activity';
import { uploadImageFromBase64 } from '~/utils/uploadLogoIfProvided';

import { schemaValidation } from './constants';

export const useGeneralSettingsController = () => {
  // States
  const [isEditing, setIsEditing] = useState(false);

  const { organizationSelectedId } = useAppSelector(({ auth }) => auth);

  const { onCopy } = useClipboard();

  const {
    control,
    handleSubmit,
    formState: { errors, isSubmitting },
    reset,
  } = useForm<OrganizationFormFields>({
    resolver: yupResolver(schemaValidation),
  });

  const {
    data: organization,
    isLoading: isLoadingOrganization,
    refetch: refetchOrganization,
  } = useQuery<Organization>(`organization/${organizationSelectedId}`, {
    queryOptions: {
      enabled: !!organizationSelectedId,
    },
  });

  const { data: causeOptionsData } = useQuery<
    IPaginatedResponse<ICauseOptions[]>
  >(`/activity-category`, {
    requestOptions: {
      params: {
        filter: JSON.stringify({ relatedTo: ['causeOptions'] }),
      },
    },
    queryOptions: {
      enabled: !!organizationSelectedId,
    },
  });

  const causeOptions = useMemo(() => {
    if (causeOptionsData?.data?.length) {
      return causeOptionsData.data.map((cause) => ({
        value: cause._id,
        label: cause.displayName,
      }));
    }
    return [];
  }, [causeOptionsData]);

  const onSubmitUpdate = handleSubmit(async (data: OrganizationFormFields) => {
    try {
      if (!organizationSelectedId) return;

      const causeOptions =
        causeOptionsData?.data?.filter((cause) =>
          data?.causeOptions?.includes(cause._id),
        ) || [];

      const logo = data?.logo || '';
      let logoURL;

      if (isBase64Image(logo)) {
        logoURL = await uploadImageFromBase64(logo);
      } else if (isUrlImage(logo)) {
        logoURL = logo;
      }

      const payload: Partial<Organization> = {
        ...data,
        causeOptions,
        logo: logoURL,
        fullAddress: {
          street: data?.fullAddress?.rawLocation ?? '',
          lat: data.fullAddress?.lat,
          lng: data.fullAddress?.lng,
          location: {
            type: 'Point',
            coordinates: [
              data?.fullAddress?.lng || 0,
              data?.fullAddress?.lat || 0,
            ],
          },
        },
      };

      const settingsPayload: Partial<OrganizationSettings> = {
        hasExternalId: !!data.hasExternalId,
        measurementGoal: data.measurementGoal,
      };

      await OrganizationService.updateOne(organizationSelectedId, payload);

      await OrganizationService.updateSettings(
        organizationSelectedId,
        settingsPayload,
      );

      toast.success(ORGANIZATION_MESSAGES.SUCCESS_ON_UPDATE);
      setIsEditing(false);
      refetchOrganization();
    } catch (error) {
      toast.error(ORGANIZATION_MESSAGES.ERROR_ON_UPDATE);
    }
  });

  const handleCopy = (field: string, value: string) => {
    onCopy(value);
    toast.success(`${field} copied to clipboard`);
  };

  const handleSetIsEditing = () => {
    setIsEditing(true);
  };

  useEffect(() => {
    reset({
      name: organization?.name,
      causeOptions: organization?.causeOptions?.map((cause) => cause._id),
      purpose: organization?.purpose,
      contactPhoneNumber: organization?.contactPhoneNumber,
      contactEmail: organization?.contactEmail,
      fullAddress: organization?.fullAddress
        ? {
            lat: organization.fullAddress?.location?.coordinates[1],
            lng: organization.fullAddress?.location?.coordinates[0],
            rawLocation: organization.fullAddress?.street,
            placeID: null,
          }
        : {},
      description: organization?.description,
      websiteLink: organization?.websiteLink,
      termsOfServicesLink: organization?.termsOfServicesLink,
      logo: organization?.logo,
      hasExternalId: !!organization?.organizationSettings?.hasExternalId,
      measurementGoal: organization?.organizationSettings?.measurementGoal || 0,
    });
  }, [reset, organization]);

  // Computed values
  const organizationLogo = { uri: organization?.logo };

  return {
    organization,
    isLoadingOrganization,
    organizationLogo,
    isEditing,
    control,
    errors,
    causeOptions,
    isSubmitting,
    onSubmitUpdate,
    handleCopy,
    handleSetIsEditing,
    refetchOrganization,
  };
};
