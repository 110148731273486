import { useQuery } from '~/hooks/useQuery';

export const useLoadMemberLogHoursTotalReport = (userProfileId: string) => {
  const { data, isLoading, isRefetching } = useQuery<{ total: number }>(
    `measurement-insights/member/${userProfileId}/total-time-logged`,
    {
      key: `user-profile-activities-report/${userProfileId}/total-time-logged`,
    },
  );

  return { data, isLoading, isRefetching };
};
