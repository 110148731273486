import { Stack } from 'native-base';
import { forwardRef, useImperativeHandle } from 'react';

import FormAction from './components/FormAction';
import FormFieldsRender from './components/FormFieldsRender';
import { INPUT_COMPONENT_TYPES } from './components/FormFieldsRender/types';
import FormHeader from './components/FormHeader';
import { IFormBuilderProps } from './types';
import { useFormBuilderController } from './useFormBuilderController';

const FormBuilder = forwardRef(function FormBuilder(
  props: IFormBuilderProps,
  ref,
) {
  const {
    control,
    watch,
    options,
    handleSubmitForm,
    formState,
    isLoadingSubmit,
  } = useFormBuilderController(props);

  useImperativeHandle(
    ref,
    () => ({
      handleGetControl() {
        return { control, watch };
      },
    }),
    [control, watch],
  );

  return (
    <Stack>
      <FormHeader {...options.header} />
      <FormFieldsRender
        {...options.inputs}
        control={control}
        formState={formState}
      />
      <FormAction
        {...options.actions}
        formState={formState}
        isLoadingSubmit={isLoadingSubmit}
        handleSubmitForm={handleSubmitForm}
      />
    </Stack>
  );
});

export default FormBuilder;
export { INPUT_COMPONENT_TYPES };
