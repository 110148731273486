import { endOfDay, startOfDay } from 'date-fns';
import { HStack, Text, VStack } from 'native-base';
import { useEffect, useMemo, useState } from 'react';
import { DateRangePicker } from 'ui/components/DateTimePickers/DateRangePicker';
import { DropdownMenu, useDropdownMenuRef } from 'ui/components/DropdownMenu';

import {
  periodDropdownOptions,
  periodTypeToDateFilterType,
} from '~/pages/Authenticated/Insights/constants';
import { dateFilterBuilder } from '~/pages/Authenticated/Insights/utils';
import { InsightsPeriodType } from '~/services/resources/types';
import { browserTimezoneFormatDateShort, formatDateShort } from '~/utils/date';

export type InsightSelectedDateType = {
  periodType: InsightsPeriodType;
  from: Date;
  to: Date;
};

export type InsightDateFilterProps = {
  defaultSelectedDates?: InsightSelectedDateType;
  onChange: (newValue: InsightSelectedDateType) => void;
};

export const InsightDateFilter = ({
  defaultSelectedDates,
  onChange,
}: InsightDateFilterProps) => {
  // states
  const dropdownRef = useDropdownMenuRef();
  const [selectedDates, setSelectedDates] =
    useState<InsightSelectedDateType | null>(defaultSelectedDates || null);

  //Hooks
  useEffect(() => {
    if (!selectedDates) return;
    onChange(selectedDates);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDates]);

  // Computed values
  const selectedDatesLabel = useMemo(() => {
    if (!selectedDates?.from || !selectedDates?.to) return '';
    return `${browserTimezoneFormatDateShort(
      selectedDates.from,
    )} - ${browserTimezoneFormatDateShort(selectedDates.to)}`;
  }, [selectedDates]);

  return (
    <HStack
      alignItems={'center'}
      space={5}
      width={['full', 'full', 340, 340, 380]}
      justifyContent={'space-between'}
    >
      <VStack alignItems={'start'}>
        <Text fontSize="xs" fontWeight={500}>
          Period
        </Text>
        <Text fontSize="sm">
          {selectedDates?.periodType &&
            periodTypeToDateFilterType[selectedDates.periodType]}
        </Text>
      </VStack>
      <DateRangePicker
        onSelectDates={(dates) => {
          setSelectedDates({
            from: startOfDay(dates[0]),
            to: endOfDay(dates[1]),
            periodType: InsightsPeriodType.Custom,
          });
        }}
        shouldCloseOnSelect
        customInput={(datepickerRef) => (
          <DropdownMenu
            ref={dropdownRef}
            selectedValue={selectedDates?.periodType}
            options={periodDropdownOptions}
            onSelect={(item) => {
              if (item.value === InsightsPeriodType.Custom) {
                datepickerRef.current?.setOpen(true);
                return;
              }

              const currentDate = startOfDay(new Date());
              const periodType = item.value as InsightsPeriodType;
              const [from, to] = dateFilterBuilder[periodType](currentDate);

              setSelectedDates({ from, to, periodType });
            }}
            buttonProps={{
              label: selectedDatesLabel,
              leftIcon: 'calendar',
            }}
          />
        )}
      />
    </HStack>
  );
};
