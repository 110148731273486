import { COMMON_MESSAGES } from '~/constants/messages.constants';
import { ISignUpFormFields } from '~/pages/Unauthenticated/SignUpPage/types';
import Yup from '~/utils/validations/yup';

export const defaultValues: ISignUpFormFields = {
  firstName: '',
  lastName: '',
  phoneNumber: '',
  email: '',
  password: '',
  termsOfService: false,
};

export const schemaValidation = Yup.object({
  firstName: Yup.string().required(),
  lastName: Yup.string().required(),
  phoneNumber: Yup.string().required(),
  email: Yup.string()
    .transform((value: string) => value?.trim() || '')
    .email()
    .required(),
  password: Yup.string().password().required(),
  termsOfService: Yup.boolean()
    .required()
    .oneOf([true], COMMON_MESSAGES.ACCEPT_TERMS),
});
