import { HStack, Stack, Text } from 'native-base';
import Button from 'ui/components/Button';
import DataTable from 'ui/components/DataTable';
import { SelectedRows } from 'ui/components/DataTable/@types/basicTypes';
import { BaseModel } from 'ui/components/DataTable/types';
import { GoBackButton } from 'ui/components/GoBackButton';
import Modal from 'ui/components/Modals/Modal';

import { Loading } from '~/components/Loading';
import { colors } from '~/theme/colors';

import { assignGroupDefaultFiltersValues } from '../utils';
import { useAssignPermissionController } from './controller';
import { tableAssignGroupColumns } from './tableAssignGroupColumns';

export const AssignGroup = () => {
  const {
    modalRef,
    handleBack,
    appPermissionGroup,
    isLoadingAppPermissionsGroup,
    isLoadingMembers,
    loadMembers,
    onSelectionChange,
    selectedMembers,
    isSelectedMembers,
    onCancelPress,
    isSubmitting,
    onAssignToGroup,
    closeModal,
    openModal,
  } = useAssignPermissionController();

  if (isLoadingAppPermissionsGroup) {
    return <Loading containerHeight="80vh" spinnerSize="sm" />;
  }

  return (
    <>
      <GoBackButton onPress={handleBack} title="Back to Permissions" />
      <Stack space={5} mt={4}>
        <Stack space="2">
          <HStack space={2} alignItems="center" justifyContent="space-between">
            <Text fontWeight={500} fontSize="xl" color="gray.600">
              Assign{' '}
              <Text fontWeight={700}>{appPermissionGroup?.name || ''}</Text>
            </Text>
            <HStack space={3} marginRight={8}>
              <Button
                maxWidth="100px"
                minWidth="100px"
                width="100%"
                variant="outline"
                borderColor="error.600"
                leftIconName="x-circle"
                leftIconColor={colors.error[600]}
                leftIconSize="16px"
                isDisabled={!isSelectedMembers}
                onPress={onCancelPress}
              >
                <Text color={colors.error[600]} fontWeight={500}>
                  Cancel
                </Text>
              </Button>
              <Button
                color={colors.primary[400]}
                leftIconName="user-check"
                leftIconColor="white"
                leftIconSize="16px"
                maxWidth="200px"
                minWidth="200px"
                width="100%"
                isDisabled={!isSelectedMembers}
                onPress={openModal}
              >
                <Text fontWeight={500} color="white">
                  Assign to all selected
                </Text>
              </Button>
            </HStack>
          </HStack>
          <Text fontWeight={400} fontSize="sm" color="gray.600" mb="8">
            Please select the users you want to assign this permission group to
          </Text>
        </Stack>

        <DataTable
          loadData={loadMembers}
          columns={tableAssignGroupColumns()}
          isLoading={isLoadingMembers}
          defaultFilterValues={assignGroupDefaultFiltersValues}
          onSelectionChange={
            onSelectionChange as (selectedRows: SelectedRows<BaseModel>) => void
          }
          selected={selectedMembers}
          checkboxColumn
          checkboxOnlyRowSelect
        />
      </Stack>
      <Modal
        ref={modalRef}
        headerText="Assign group"
        rightBtnLoading={isSubmitting}
        rightBtnAction={onAssignToGroup}
        modalContentMaxW="600"
        rightBtnText="Assign group"
        leftBtnAction={closeModal}
        leftBtnText="Cancel"
      >
        <Stack mt={2} space={5}>
          <Text fontWeight={400} fontSize="md" color="gray.500">
            Are you sure that you want to assign this group for this selected
            members?
          </Text>
        </Stack>
      </Modal>
    </>
  );
};
