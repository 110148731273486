import { useMemo } from 'react';

import { IReportCardsControllerProps } from './types';

export const useReportCardsController = ({
  direction,
  actionButtonName,
}: IReportCardsControllerProps) => {
  const optionsMemo = useMemo(() => {
    return {
      direction: direction || 'row',
    };
  }, [direction]);

  const commonIconName = actionButtonName || 'external-link';

  return {
    optionsMemo,
    commonIconName,
  };
};
