import { HStack, Text, VStack } from 'native-base';
import Icon from 'ui/components/Icon';

import { colors } from '~/theme/colors';

type PercentageChangeIndicatorProps = {
  percentage: number | string;
  isPositive: boolean;
};

export const PercentageChangeIndicator = ({
  isPositive,
  percentage,
}: PercentageChangeIndicatorProps) => {
  return (
    <VStack>
      <HStack space={2}>
        <Icon
          size={16}
          icon={isPositive ? 'trending-up' : 'trending-down'}
          color={isPositive ? colors.success[500] : colors.error[500]}
        />
        <Text
          fontSize={'sm'}
          fontWeight={500}
          color={isPositive ? 'success.500' : 'error.500'}
        >
          {percentage}%
        </Text>
      </HStack>
      <Text fontSize={'sm'} color={'gray.500'}>
        over the last period
      </Text>
    </VStack>
  );
};
