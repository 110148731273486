export const styles = {
  imageStack: {
    w: { sm: '100%', md: '40%' },
    px: { base: 0, md: '7' },
  },
  pageStack: {
    w: { sm: '100%', md: '60%' },
    px: { base: '15px', sm: '1/6', md: '10vw', lg: '150px' },
  },
};

// ResponsiveValue not is exported by native-base, for that we can be use any type.
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const containerFlexDirection: any = {
  sm: 'column',
  md: 'row',
};
