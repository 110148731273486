import DateFilter from '@inovua/reactdatagrid-community/DateFilter';
import { IColumn } from '@inovua/reactdatagrid-community/types';
import {
  TableOperatorNames,
  TableOperatorType,
} from 'ui/components/DataTable/@types/basicTypes';
import DataTableActions from 'ui/components/DataTableActions';
import { EActivityAttendanceTypes, LocationOptionsEnum } from 'ui/enums';
import { formattedDate } from 'ui/utils/formatter';

import { ActivityTypeEnum } from '~/enums';
import { ApplicationSlots } from '~/pages/Authenticated/Activities/components/ApplicationSlots';
import { colors } from '~/theme/colors';
import { IActivity } from '~/types/interfaces/activity';
import { isClosedApplication } from '~/utils/validations/normalizers';

export const baseColumns: IColumn[] = [
  {
    name: 'bookingsNumber',
    header: 'Applications',
    defaultFlex: 1,
    render: ({ data }: { data: IActivity }) => (
      <ApplicationSlots
        bookingsNumbers={data.bookingsNumber}
        volunteerNumber={data.volunteerNumber}
        isVolunteerNumberLimited={data.isVolunteerNumberLimited}
      />
    ),
  },
  {
    name: 'attendeesNumber',
    header: 'Attendances',
    defaultFlex: 0.6,
    render: ({ data }: { data: IActivity }) => data.attendeesNumber,
  },
];

export const columnsByActivityType = (
  activityType: ActivityTypeEnum,
  handleCloseApplication: (
    _id: string,
    isToggled: boolean,
    endDate: Date,
  ) => void,
  attendanceType: EActivityAttendanceTypes,
  locationOption?: LocationOptionsEnum,
): IColumn[] => {
  const addressColumn: IColumn = {
    name: 'address.street',
    header: 'Place',
    defaultFlex: 1.3,
    render: ({ data }: { data: IActivity }) => data.address.street,
  };
  const eventDateColumn: IColumn = {
    name: 'startDate',
    header: 'Date',
    defaultFlex: 0.4,
    filterEditor: DateFilter,
    filterEditorProps: () => ({
      dateFormat: 'YYYY-MM-DD',
      cancelButton: false,
      highlightWeekends: false,
    }),
    render: ({ data }: { data: IActivity }) =>
      data.startDate ? formattedDate(data.startDate, 'dd/MM/yyyy') : '',
  };

  const eventActionColumn: IColumn = {
    name: 'actions',
    header: 'Actions',
    defaultFlex: 1,
    hideable: true,
    render: ({ data }: { data: IActivity }) => (
      <DataTableActions
        toggleButton
        toggleText="Close applications"
        toggleValue={isClosedApplication(data.dueDate as Date, data.endDate)}
        onPressToggleButton={(isToggled) => {
          handleCloseApplication(data._id, isToggled, data.endDate);
        }}
        color={colors.primary['600']}
      />
    ),
  };

  const eventColumns =
    locationOption !== LocationOptionsEnum.FromHome
      ? [addressColumn, eventDateColumn, ...baseColumns, eventActionColumn]
      : [eventDateColumn, ...baseColumns, eventActionColumn];

  const ongoingColumns =
    locationOption !== LocationOptionsEnum.FromHome
      ? [addressColumn, ...baseColumns]
      : [...baseColumns];

  return {
    [ActivityTypeEnum.Event]: eventColumns, // Action doesn't need to show a table, but we need to return something.
    [ActivityTypeEnum.Action]: [],
    // Opportunity doesn't need to close applications, so we don't show the action column.
    [ActivityTypeEnum.OngoingOpportunity]: ongoingColumns,
  }[activityType];
};

export const defaultFilterValues = [
  {
    name: 'address.street',
    operator: TableOperatorNames.Equals,
    type: TableOperatorType.String,
    value: '',
  },
  {
    name: 'meetingLink',
    operator: TableOperatorNames.Equals,
    type: TableOperatorType.String,
    value: '',
  },
  {
    name: 'startDate',
    operator: TableOperatorNames.AfterOrOn,
    type: TableOperatorType.Date,
    value: '',
  },
  {
    name: 'bookingsNumber',
    operator: TableOperatorNames.GreaterThanOrEquals,
    type: TableOperatorType.String,
    value: '',
  },
  {
    name: 'attendeesNumber',
    operator: TableOperatorNames.GreaterThanOrEquals,
    type: TableOperatorType.String,
    value: '',
  },
];
