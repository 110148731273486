import { Stack } from 'native-base';
import Input from 'ui/components/Input';
import Modal from 'ui/components/Modals/Modal';

import { Loading } from '~/components/Loading';

import { useModalEditExternalIdController } from './controller';
import { ModalEditExternalIdProps } from './types';

export const ModalEditExternalId = ({
  modalRef,
  memberId,
  refetchMembers,
}: ModalEditExternalIdProps) => {
  const {
    isLoadingMember,
    isSubmitting,
    onSubmit,
    control,
    errors,
    closeModal,
  } = useModalEditExternalIdController({ memberId, modalRef, refetchMembers });

  return (
    <Modal
      ref={modalRef}
      headerText="Edit member"
      rightBtnLoading={isSubmitting}
      rightBtnAction={onSubmit as any}
      modalContentMaxW="600"
      rightBtnText="Save"
      leftBtnAction={closeModal}
      leftBtnText="Cancel"
    >
      {isLoadingMember ? (
        <Loading containerHeight="10vh" spinnerSize="sm" />
      ) : (
        <Stack space={5} mt={5}>
          <Input
            name="externalId"
            label="External ID"
            control={control}
            errorMessage={errors?.externalId?.message}
          />
        </Stack>
      )}
    </Modal>
  );
};
