import { useRef, useState } from 'react';
import { toast } from 'react-toastify';
import { IModalRefProps } from 'ui/components/Modals/Modal/types';

import { colors } from '~/theme/colors';

export const useChildOrgController = () => {
  const [tabIndex, setTabIndex] = useState(0);
  const modalRef = useRef<IModalRefProps>(null);

  const handleOpenModal = () => {
    modalRef.current?.open();
  };

  const handleCloseModal = () => {
    modalRef.current?.close();
  };

  const handleRemoveChildOrg = () => {
    handleCloseModal();
    toast.success('Child organisation removed successfully');
  };

  const handleResendInvitation = () => {
    console.log('resend invitation');
  };

  const handleChangeTab = (index: number) => {
    setTabIndex(index);
  };

  const popOverOptions = [
    {
      icon: {
        color: colors.gray[600],
        icon: 'send',
        size: 22,
      },
      title: 'Resend invitation',
      action: handleResendInvitation,
    },
    {
      icon: {
        color: colors.error[600],
        icon: 'x-circle',
        size: 22,
      },
      title: 'Remove child org',
      action: handleOpenModal,
    },
  ];

  return {
    popOverOptions,
    tabIndex,
    modalRef,
    handleCloseModal,
    handleChangeTab,
    handleRemoveChildOrg,
  };
};
