import { api } from '~/services/api';
import { QueryParams } from '~/services/resources/types';
import {
  InvitePartnerDTO,
  PublicInviteDTO,
} from '~/types/interfaces/invite-member';

const MODULE_URL = '/invite-partners';

interface AcceptInvitePartnersDTO {
  code: string;
  receiverEcosystem: string;
}

export class InvitePartnerService {
  static async findPendingInvites(
    organizationId: string,
    params?: QueryParams,
  ) {
    const options = {
      params,
    };
    return await api.get(
      `${MODULE_URL}/organization/${organizationId}/pending`,
      options,
    );
  }

  static async createInvite(data: InvitePartnerDTO) {
    return await api.post(`${MODULE_URL}`, data);
  }

  static async createPublicInvite(data: PublicInviteDTO) {
    return await api.post(`${MODULE_URL}/public`, data);
  }

  static async resendInvite(inviteId: string) {
    return await api.post(`${MODULE_URL}/${inviteId}/resend`);
  }

  static async acceptInvite(data: AcceptInvitePartnersDTO) {
    return await api.post(`${MODULE_URL}/accept-invite`, data);
  }
}
