import { Box, Input, Spinner, Text, VStack } from 'native-base';
import Tabs from 'ui/components/Tabs';

import { colors } from '~/theme/colors';

import { AppCard } from '../AppCard';
import { styles } from './styles';
import { AppsListProps } from './types';
import { useAppsListController } from './useAppsListController';

export const AppsList = ({
  pickedApps = [],
  ecosystemId,
  uniqueAppsConnectedToOrganization,
  isLoading,
  publicApps,
  currentTab = 0,
  onChangeTab,
  onChangeText,
  onViewApp,
  onGetApp,
}: AppsListProps) => {
  const { isPickedApp, tabRef } = useAppsListController(ecosystemId);

  return (
    <>
      <VStack space="2" mb="8">
        <Text color="gray.600">Search app</Text>
        <Input
          placeholder="Search by name"
          bg="white"
          onChangeText={onChangeText}
        />
      </VStack>
      <Box mb="6">
        <Tabs
          isClickableTab
          ref={tabRef}
          tabTextColor={colors.primary['600']}
          tabIndicatorColor={colors.primary['600']}
          tabfocusedTextColor={colors.primary['600']}
          tabBgColor="none"
          swipeEnabled={false}
          onChangeTab={onChangeTab}
          elements={[
            {
              title: 'Public apps',
              component: () => <></>,
            },
            {
              title: 'Your apps',
              component: () => <></>,
            },
          ]}
        />
      </Box>

      {currentTab === 0 ? (
        <Box minH="32">
          {isLoading ? (
            <Spinner my="10" />
          ) : (
            <Box pb="10">
              <div style={styles.grid}>
                {publicApps?.map((app) => (
                  <AppCard
                    key={app._id}
                    app={app}
                    isPicked={isPickedApp(
                      app._id,
                      pickedApps,
                      uniqueAppsConnectedToOrganization,
                    )}
                    onViewApp={onViewApp}
                    onGetApp={onGetApp}
                  />
                )) || []}
              </div>
            </Box>
          )}
        </Box>
      ) : (
        <Box minH="32">
          {isLoading ? (
            <Spinner my="10" />
          ) : (
            <Box pb="10">
              <div style={styles.grid}>
                {uniqueAppsConnectedToOrganization?.map((networkAppSharing) => (
                  <AppCard
                    key={networkAppSharing.app._id}
                    app={networkAppSharing.app}
                    isPicked={isPickedApp(
                      networkAppSharing.app._id,
                      pickedApps,
                      uniqueAppsConnectedToOrganization,
                    )}
                    onViewApp={onViewApp}
                    onGetApp={onGetApp}
                  />
                )) || []}
              </div>
            </Box>
          )}
        </Box>
      )}
    </>
  );
};
