import {
  Box,
  HStack,
  Image,
  Pressable,
  Skeleton,
  Stack,
  Text,
  VStack,
} from 'native-base';
import placeholderImage from 'ui/assets/svgs/placeholder.svg';
import Icon from 'ui/components/Icon';
import { ActivityTypeBadge } from 'ui/components/v2/Badges/ActivityTypeBadge';
import { colors } from 'ui/theme/colors';

import { useCommonActivitySearchTileController } from './controller';
import { CommonActivitySearchTileProps } from './types';

export const CommonActivitySearchTile = (
  props: CommonActivitySearchTileProps,
) => {
  const { title, coverImageUrl, organizationName, onPress } = props;

  const { formattedLabels, activitySemanticType } =
    useCommonActivitySearchTileController(props);

  return (
    <Pressable p={4} onPress={onPress}>
      <HStack
        flex={1}
        w="full"
        backgroundColor={{ md: colors.gray[100] }}
        borderRadius={12}
        borderWidth={{ md: 1 }}
        borderColor={colors.gray[200]}
        alignItems={'center'}
        overflow={'hidden'}
      >
        <Box
          w={{ base: 24, md: '216px' }}
          height={{ base: 24, md: 'full' }}
          minH={{ base: 'unset', md: '160px' }}
          backgroundColor={colors.gray[500]}
          borderRadius={{ base: 12, md: 0 }}
          overflow={'hidden'}
        >
          <Image
            h="full"
            w="full"
            resizeMode="cover"
            alt={`Logo from activity ${title}`}
            source={{ uri: coverImageUrl }}
            fallbackSource={{ uri: placeholderImage }}
          />
          <Stack width="100%" height="100%">
            <Skeleton width="100%" height="100%" />
          </Stack>
        </Box>
        <VStack
          space={{ base: 2, md: 3 }}
          flex={1}
          p={{ base: 0, md: 4 }}
          pl={{ base: 3, md: 4 }}
        >
          <HStack justifyContent={{ base: 'space-between', md: 'flex-start' }}>
            <ActivityTypeBadge activityType={activitySemanticType} />
          </HStack>

          <VStack>
            <Text
              fontSize={{ base: 'sm', md: 'lg' }}
              fontWeight={500}
              color={colors.darkText}
              noOfLines={1}
            >
              {title}
            </Text>

            <Text
              fontSize={{ base: 'xs', md: 'sm' }}
              fontWeight={400}
              color={colors.gray[600]}
              noOfLines={1}
            >
              by {organizationName}
            </Text>
          </VStack>

          <VStack space={{ base: 0, md: 2 }}>
            {formattedLabels.startDateStr && (
              <HStack space={1} alignItems="center">
                <Icon icon="calendar" size="16px" color={'gray.600'} />
                <Text
                  fontWeight={500}
                  color={colors.gray[600]}
                  fontSize={{ base: 'xs', md: 'md' }}
                >
                  {formattedLabels.startDateStr}
                </Text>
              </HStack>
            )}

            {formattedLabels.targetAmountStr && (
              <HStack space={1} alignItems="center">
                <Icon
                  size="16px"
                  enableAutoCustom
                  icon={formattedLabels.targetAmountIcon}
                  color={colors.gray[600]}
                />
                <Text
                  fontWeight={500}
                  color={'gray.600'}
                  fontSize={{ base: 'xs', md: 'md' }}
                >
                  {formattedLabels.targetAmountStr}
                </Text>
              </HStack>
            )}
          </VStack>
        </VStack>
      </HStack>
    </Pressable>
  );
};
