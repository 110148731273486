import {
  Box,
  Checkbox,
  Divider,
  Flex,
  HStack,
  Image,
  Link,
  Spinner,
  Stack,
  Text,
  VStack,
} from 'native-base';
import { Controller } from 'react-hook-form';
import Button from 'ui/components/Button';
import Icon from 'ui/components/Icon';

import { BottomButton } from '~/components/BottomButton';
import { HelpCentreRedirectLink } from '~/components/HelpCentre';
import ModalTermsOfService from '~/components/ModalTermsOfService';
import { colors } from '~/theme/colors';
import { transformURL } from '~/utils/transformURL';

import { styles } from './styles';
import { useJoinAsAdminInviteController } from './useJoinAdminInviteController';

const JoinAsAdminInvite = () => {
  const {
    control,
    organization,
    isValid,
    isSubmitting,
    modalTermsOfServiceRef,
    isLoading,
    isLoadingInviteCode,
    inviteCode,
    onSubmit,
    handleOpenTerms,
    handleDeclineInvitation,
  } = useJoinAsAdminInviteController();

  if (isLoading || isLoadingInviteCode) {
    return (
      <Flex minH="80vh" justifyContent="center">
        <Spinner />
      </Flex>
    );
  }

  return (
    <>
      <Flex
        pb="24"
        minH="100vh"
        backgroundColor={colors.singletons.lightText}
        justifyContent="center"
      >
        <Flex
          w="min(90%, 1200px)"
          mx="auto"
          flexDirection={styles.flexDirection}
          style={{ gap: 74 }}
          alignItems="center"
        >
          <Box
            bgColor="white"
            w={styles.imageContainerW}
            padding={6}
            borderWidth="1px"
            borderColor="gray.200"
            borderRadius="3xl"
          >
            <HStack space={8}>
              <Image
                width="130px"
                height="130px"
                src={organization?.logo}
                borderRadius="full"
                alt="Organisation logo"
              />
              <Stack width="70%" space={4}>
                <Stack space={6}>
                  <Text
                    fontSize="xl"
                    fontWeight="500"
                    color={colors.singletons.darkText}
                  >
                    {organization?.name || '-'}
                  </Text>
                  <Divider />
                </Stack>
                {inviteCode?.ecosystems?.length ? (
                  <Stack space={3}>
                    <Text fontWeight="500" color="gray.600" fontSize="sm">
                      Ecosystems
                    </Text>
                    {inviteCode?.ecosystems?.map((ecosystem) => (
                      <HStack
                        key={ecosystem._id}
                        alignItems="center"
                        width="100%"
                        bgColor="gray.50"
                        space={3}
                      >
                        <Image
                          borderRadius="md"
                          borderWidth="2px"
                          borderColor="gray.300"
                          width="24px"
                          height="24px"
                          src={ecosystem?.logo}
                        />
                        <Text maxW="75%" color="gray.600" fontWeight={400}>
                          {ecosystem.name}
                        </Text>
                        <HStack
                          alignItems="center"
                          justifyContent="flex-end"
                          flex={1}
                          space={2}
                        >
                          <Icon
                            icon={ecosystem?.isPrivate ? 'lock' : 'eye'}
                            color={colors.gray[500]}
                            size={16}
                          />
                          <Text fontWeight={500} color="gray.500">
                            {ecosystem?.isPrivate ? 'Private' : 'Public'}
                          </Text>
                        </HStack>
                      </HStack>
                    ))}

                    <Divider width="100%" mt={4} />
                  </Stack>
                ) : null}

                {inviteCode?.apps?.length ? (
                  <Stack space={2}>
                    <Text fontWeight="500" color="gray.600" fontSize="sm">
                      Apps
                    </Text>
                    {inviteCode?.apps?.map((app) => (
                      <HStack
                        key={app._id}
                        space={4}
                        alignItems="center"
                        flexWrap="wrap"
                      >
                        <HStack space={2} alignItems="center">
                          <Image
                            borderRadius="md"
                            borderWidth="2px"
                            borderColor="gray.300"
                            width="24px"
                            height="24px"
                            src={app?.logo}
                          />
                          <Text
                            maxWidth="85px"
                            width="100%"
                            fontWeight={500}
                            fontSize="sm"
                            color="gray.600"
                          >
                            {app.name}
                          </Text>
                        </HStack>
                      </HStack>
                    ))}

                    <Divider mt={3} />
                  </Stack>
                ) : null}

                <Stack space={2}>
                  <Text fontWeight="500" color="gray.600" fontSize="sm">
                    Description
                  </Text>
                  <Text flex={1}>{organization?.description || '-'}</Text>
                </Stack>
                {organization?.contactEmail ||
                organization?.contactPhoneNumber ||
                organization?.fullAddress ? (
                  <Stack space={2}>
                    <Divider mt={3} />
                    <Text fontWeight="500" color="gray.600" fontSize="sm">
                      Contact information
                    </Text>
                    {organization?.contactEmail ? (
                      <HStack space={2} alignItems="center">
                        <Icon icon="mail" color={colors.gray[400]} size={16} />
                        <Text color={colors.singletons.darkText}>
                          {organization.contactEmail}
                        </Text>
                      </HStack>
                    ) : null}
                    {organization?.contactPhoneNumber ? (
                      <HStack space={2} alignItems="center">
                        <Icon icon="phone" color={colors.gray[400]} size={16} />
                        <Text color={colors.singletons.darkText}>
                          {organization.contactPhoneNumber}
                        </Text>
                      </HStack>
                    ) : null}
                    {organization?.fullAddress?.street ? (
                      <HStack space={2} alignItems="center">
                        <Icon
                          icon="map-pin"
                          color={colors.gray[400]}
                          size={16}
                        />
                        <Text color={colors.singletons.darkText}>
                          {organization.fullAddress.street}
                        </Text>
                      </HStack>
                    ) : null}
                  </Stack>
                ) : null}
              </Stack>
            </HStack>
          </Box>
          <Box flex="1" mt={styles.boxMt}>
            <HelpCentreRedirectLink />
            <Text
              color="gray.700"
              fontWeight="700"
              maxW={390}
              fontSize={styles.titleFontSize}
              textAlign={styles.titleAlignment}
            >
              You have been invited to be an admin of
              <Stack marginLeft={2}>
                <Text color="gray.700" fontWeight="700">
                  {organization?.name}
                </Text>
              </Stack>
            </Text>
            <Text
              mt="4"
              color="gray.500"
              fontSize="md"
              textAlign={styles.subTitleAlignment}
            >
              An invitation has been sent for you to join as administrator. Will
              you accept?
            </Text>
            <Controller
              control={control}
              name="termsOfServiceAccepted"
              render={({ field: { value, onChange } }) => (
                <HStack alignItems="center" space="3" mt="10">
                  <Checkbox
                    value="true"
                    isChecked={value}
                    onChange={onChange}
                  />
                  <Text>
                    By joining this organisation you are accepting the{' '}
                    <Text underline>
                      {organization?.termsOfServicesLink ? (
                        <Link
                          isExternal
                          color="gray.800"
                          href={transformURL(organization?.termsOfServicesLink)}
                        >
                          terms and conditions.
                        </Link>
                      ) : (
                        <Link color="gray.800" onPress={handleOpenTerms}>
                          terms and conditions.
                        </Link>
                      )}
                    </Text>
                  </Text>
                </HStack>
              )}
            />
            <VStack
              width="100%"
              alignItems="center"
              space={2}
              mt="12"
              display={styles.buttonContainerDisplay}
            >
              <Button
                p={6}
                fontWeight={500}
                fontSize="md"
                isLoading={isSubmitting}
                isDisabled={!isValid || isSubmitting}
                onPress={onSubmit}
              >
                Accept invitation
              </Button>
              <Button
                variant="ghost"
                fontWeight={500}
                fontSize="md"
                onPress={handleDeclineInvitation}
              >
                <Text color="red.600">Decline</Text>
              </Button>
            </VStack>
          </Box>
        </Flex>
      </Flex>
      <BottomButton
        hasSecondaryButton
        text="Accept invitation"
        secondaryButtonText="Refuse"
        primaryButtonVariant="solid"
        secondaryButtonTextColor="red.600"
        isDisabled={!isValid || isSubmitting}
        isLoading={isSubmitting}
        onSubmit={onSubmit}
        secondaryButtonOnPress={handleDeclineInvitation}
      />
      <ModalTermsOfService modalRef={modalTermsOfServiceRef} />
    </>
  );
};

export default JoinAsAdminInvite;
