import { MemberTypeEnum } from '~/enums';
import { AddOrgMembersForm } from '~/pages/Authenticated/AddOrgMember/components/Form';

import { useAddMemberController } from './controller';

export const AddMember = () => {
  const {
    handleInviteMember,
    handleCreatePublicInvite,
    isSubmittingPublicInvite,
    isSubmitting,
  } = useAddMemberController();
  return (
    <AddOrgMembersForm
      title="Add member"
      isSubmitting={isSubmitting}
      memberType={MemberTypeEnum.Member}
      onSubmitInviteMember={handleInviteMember}
      isSubmittingPublicInvite={isSubmittingPublicInvite}
      onSubmitPublicInviteMember={handleCreatePublicInvite}
    />
  );
};
