import { INPUT_COMPONENT_TYPES } from 'ui/components/FormBuilderV2';
import { IOptionsV2 } from 'ui/components/FormBuilderV2/types';
import { IOptionsItem } from 'ui/components/Select/types';

import {
  ActivityPublishedAppsSection,
  ActivityRadioAppSection,
} from '~/components/ActivityFormBuilderPresets/InputSections';
import { EventCategoryOptions, EventConfigurationOptions } from '~/enums';
import { MeasurementUnit } from '~/types/interfaces/measurementUnit';
import Yup from '~/utils/validations/yup';

import { ICreateFormSchemaProps } from './types';

export const eventConfigDictionary = {
  [EventConfigurationOptions.Individual]: 'Individual',
  [EventConfigurationOptions.Teams]: 'Teams',
};

export const eventCategoryDictionary = {
  [EventCategoryOptions.Volunteering]: 'Volunteering',
};

export const eventConfigurationOptions = [
  {
    text: eventConfigDictionary[EventConfigurationOptions.Individual],
    value: EventConfigurationOptions.Individual,
  },
  {
    text: eventConfigDictionary[EventConfigurationOptions.Teams],
    value: EventConfigurationOptions.Teams,
  },
];

export const eventCategoryOptions = [
  {
    label: 'Volunteering',
    value: 'volunteering',
  },
];

export const createFormSchema = ({
  causeOptions,
  customControl,
  customValidation,
  connectedApps,
  commonController,
}: ICreateFormSchemaProps): IOptionsV2 => ({
  inputsSections: [
    ActivityRadioAppSection({
      appsList: connectedApps,
      helpLink: commonController.appsHelpLink,
      isDisabled: commonController.isHostAppDisabled,
      onChangeValue: commonController.onChangeApp,
    }),
    ActivityPublishedAppsSection({
      appsList: connectedApps,
      disabledValues: commonController.disabledAppIds,
      helpLink: commonController.appsHelpLink,
    }),
    {
      key: 'main-section',
      sectionTitle: 'Basic information',
      sectionSubtitle: 'Basic information about the event',
      sectionInputRows: [
        {
          key: 'title-row',
          inputs: [
            {
              id: 'title',
              name: 'title',
              isRequired: true,
              component: INPUT_COMPONENT_TYPES.INPUT,
              inputProps: {
                label: 'Title',
                placeholder: 'Type at least one cause',
              },
            },
          ],
        },
        {
          key: 'category-subcategory-row',
          inputs: [
            {
              id: 'category',
              name: 'category',
              isRequired: true,
              component: INPUT_COMPONENT_TYPES.SELECT,
              inputProps: {
                label: 'Category',
                isMulti: false,
                placeholder: 'Select the event category',
                options: eventCategoryOptions,
                defaultValue: eventCategoryOptions[0].value,
              },
            },
            {
              id: 'cause',
              name: 'cause',
              isRequired: true,
              component: INPUT_COMPONENT_TYPES.SELECT,
              inputProps: {
                label: 'Cause',
                isMulti: true,
                placeholder: 'Select at least one cause',
                options: causeOptions,
              },
            },
          ],
        },
        {
          key: 'description-row',
          inputs: [
            {
              id: 'description',
              name: 'description',
              component: INPUT_COMPONENT_TYPES.TEXT_AREA,
              inputProps: {
                h: 194,
                label: 'Description',
                placeholder: 'About the event',
              },
            },
          ],
        },
      ],
    },
  ],
  customControl: customControl,
  customValidations: customValidation,
});

export const activityMetricsOptions = (measurementUnits: {
  [key: string]: MeasurementUnit[];
}) => {
  return Object.entries(measurementUnits).map(([key, values]) => {
    const options: IOptionsItem[] = values.map((value) => {
      return {
        value: value._id,
        label: value.pluralLabel,
      };
    });

    return {
      label: key,
      options: options,
    };
  });
};

export const stepSchemaValidation = {
  title: Yup.string().required(),
  description: Yup.string().optional(),
};

export const schemaValidation = Yup.object().shape(
  {
    title: Yup.string().required(),
    description: Yup.string(),
    category: Yup.string().required(),
    cause: Yup.array().min(1).of(Yup.string()).required(),
    externalLink: Yup.string(),
    selectedApp: Yup.array().min(1).max(1).of(Yup.string()).required(),
    publishedApps: Yup.array().min(1).of(Yup.string()).required(),
    measurementUnit: Yup.string().required(),
    targetAmount: Yup.number().when('steps', {
      is: (steps: any[]) => steps && steps.length > 0,
      then: Yup.number()
        .nullable(true)
        .transform((_, val) => (val === Number(val) ? val : null))
        .optional(),
      otherwise: Yup.number()
        .nullable(true)
        .transform((_, val) => (val === Number(val) ? val : null))
        .required(),
    }),

    steps: Yup.array().when('steps', {
      is: (steps: any) => steps && steps?.length > 0,
      then: Yup.array().min(1).of(Yup.object().shape(stepSchemaValidation)),
      otherwise: Yup.array().optional(),
    }),
  },
  [['steps', 'steps']],
);
