import { FC } from 'react';

import { ImagePickerBase } from './Base';
import { ImagePickerBaseProps } from './Base/types';
import { DropArea } from './DropArea';
import { ImageDropAreaProps } from './DropArea/types';

export const ImagePicker: {
  Base: FC<ImagePickerBaseProps>;
  DropArea: FC<ImageDropAreaProps>;
} = {
  Base: ImagePickerBase,
  DropArea,
};

export default ImagePicker;
