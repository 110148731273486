export enum COGNITO_AUTHENTICATION_FLOW {
  USER_SRP_AUTH = 'USER_SRP_AUTH',
  USER_PASSWORD_AUTH = 'USER_PASSWORD_AUTH',
  CUSTOM_AUTH = 'CUSTOM_AUTH',
}

export enum OrganizationType {
  NFP = 'NFP',
  COMPANY = 'COMPANY',
}

export enum ActivityTypeEnum {
  Event = 'Event',
  Action = 'Action',
  OngoingOpportunity = 'OngoingOpportunity',
}

export enum MemberTypeEnum {
  ChildOrg = 'Child Organization',
  Partner = 'Partner',
  Member = 'Member',
}

export enum AccountStatusEnum {
  PENDING = 'Pending',
  ACTIVE = 'Active',
}

export enum APP_TYPE {
  FREE = 'Free',
  PREMIUM = 'Premium',
}

export enum InviteCodeReason {
  NEW_INVITE = 'NEW_INVITE',
  INVITER_WANTS_DELETE_ACCOUNT = 'INVITER_WANTS_DELETE_ACCOUNT',
}

export enum InviteCodeType {
  INVITE_ADMIN_MEMBER = 'INVITE_ADMIN_MEMBER',
  INVITE_VOLUNTEER_MEMBER = 'INVITE_VOLUNTEER_MEMBER',
}

export enum OrgPlusContentViewedFlags {
  firstEditEcosystemModal = 2 ** 0,
}

export enum EventTypeEnum {
  National = 'National',
  Local = 'Local',
}

export enum EcosystemVisibility {
  Private = 'private',
  Public = 'public',
}

export enum MeasurementUnitsCategory {
  Time = 'Time',
  Weight = 'Weight',
  Completion = 'Completion',
  CustomUnit = 'Custom unit',
}

// Remove these in another pr for other forms
export enum EventCategoryOptions {
  Volunteering = 'Volunteering',
}

export enum EventConfigurationOptions {
  Individual = 'Individual',
  Teams = 'Teams',
}

export enum AttendanceTypes {
  InPerson = 'Local',
  Remote = 'Home',
}
