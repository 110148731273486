import { Button, HStack, Stack, Text } from 'native-base';
import Modal from 'ui/components/Modals/Modal';

import { IModalDeleteAccountProps } from './types';

export const ModalDeleteAccount = ({
  modalRef,
  isFetching,
  closeModal,
  confirmAction,
}: IModalDeleteAccountProps) => {
  return (
    <Modal
      headerText="Delete account"
      ref={modalRef}
      modalContentMaxW="669"
      onClose={closeModal}
    >
      <Stack py="2" space={6}>
        <Text fontSize="16px" color="gray.600">
          Are you sure that you want to delete the account?
        </Text>
        <Text fontSize="16px" color="gray.600">
          This action is irreversible and you will lose all registered
          activities, bookings and volunteers.
        </Text>

        <HStack>
          <Button variant="link" w="50%" onPress={closeModal}>
            <Text fontSize="16px" fontWeight={500} color="singletons.black">
              No, go back
            </Text>
          </Button>
          <Button
            colorScheme="error"
            w="50%"
            bgColor="error.600"
            isDisabled={isFetching}
            isLoading={isFetching}
            onPress={confirmAction}
          >
            <Text fontSize="16px" fontWeight={500} color="singletons.white">
              Delete account permanently
            </Text>
          </Button>
        </HStack>
      </Stack>
    </Modal>
  );
};
