import { useState } from 'react';
import { toast } from 'react-toastify';

import { PAGES } from '~/constants/pages.constants';
import { MemberTypeEnum } from '~/enums';
import { useAppSelector } from '~/hooks/useAppSelector';
import { useRouter } from '~/hooks/useRouter';
import { UploadComposeSettingsImages } from '~/pages/Authenticated/AddOrgMember/components/Form/types';
import { InviteMemberService } from '~/services/resources/inviteMember';
import { InvitePartnerService } from '~/services/resources/invitePartner';
import { PublicInviteDTO } from '~/types/interfaces/invite-member';

export const useCreatePublicInvite = (memberType: MemberTypeEnum) => {
  const [isSubmittingPublicInvite, setIsSubmittingPublicInvite] =
    useState(false);

  const { organizationSelectedId } = useAppSelector(({ auth }) => auth);
  const { ecosystemsToShare, composeSettings } = useAppSelector(
    ({ addMember }) => addMember,
  );

  const { goToRoute, location } = useRouter();

  const handleCreatePublicInvite = async ({
    backgroundImage,
    logo,
  }: UploadComposeSettingsImages) => {
    try {
      if (!organizationSelectedId || !composeSettings) return;
      setIsSubmittingPublicInvite(true);
      const data: PublicInviteDTO = {
        ecosystemsData: ecosystemsToShare,
        sharerOrganization: organizationSelectedId,
        composeSettings: {
          ...composeSettings,
          logo,
          backgroundImage,
        },
      };

      if (memberType === MemberTypeEnum.Member) {
        await handleCreatePublicMemberInvite(data);
        return;
      }

      if (memberType === MemberTypeEnum.Partner) {
        await handleCreatePublicPartnerInvite(data);
        return;
      }

      if (memberType === MemberTypeEnum.ChildOrg) {
        await handleCreatePublicChildOrgInvite(data);
        return;
      }
    } catch (error) {
      toast.error('Error on trying to create public invite.');
    } finally {
      setIsSubmittingPublicInvite(false);
    }
  };

  const handleCreatePublicMemberInvite = async (data: PublicInviteDTO) => {
    const { data: responseData } = await InviteMemberService.createPublicInvite(
      data,
    );
    goToRoute(PAGES.ADD_ORG_MEMBER_SUCCESS, {
      state: {
        addMemberSuccess: location,
        inviteURL: responseData.inviteURL,
      },
    });
  };

  const handleCreatePublicPartnerInvite = async (data: PublicInviteDTO) => {
    const { data: responseData } =
      await InvitePartnerService.createPublicInvite(data);
    goToRoute(PAGES.ADD_ORG_PARTNER_SUCCESS, {
      state: {
        addMemberSuccess: location,
        inviteURL: responseData.inviteURL,
      },
    });
  };

  const handleCreatePublicChildOrgInvite = async (data: PublicInviteDTO) => {
    const { data: responseData } =
      await InvitePartnerService.createPublicInvite({
        ...data,
        parentOrganization: data.sharerOrganization,
      });
    goToRoute(PAGES.ADD_CHILD_ORG_SUCCESS, {
      state: {
        addMemberSuccess: location,
        inviteURL: responseData.inviteURL,
      },
    });
  };

  return { isSubmittingPublicInvite, handleCreatePublicInvite };
};
