export const tabsElements = [
  {
    title: 'Permission groups',
    component: () => <></>,
  },
  {
    title: 'Drafts',
    component: () => <></>,
  },
];
