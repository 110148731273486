import { Box, VStack } from 'native-base';
import FormAction from 'ui/components/FormBuilder/components/FormAction';
import Tabs from 'ui/components/Tabs';
import { colors } from 'ui/theme/colors';

import Scaffold from '~/components/Scaffold';
import { AccessFormSection } from '~/pages/Authenticated/AddOrgMember/components/Form/AccessFormSection';
import { ComposeFormSection } from '~/pages/Authenticated/AddOrgMember/components/Form/ComposeFormSection';
import { InvitationFormSection } from '~/pages/Authenticated/AddOrgMember/components/Form/InvitationFormSection';
import { PermissionsFormSection } from '~/pages/Authenticated/AddOrgMember/components/Form/PermissionsFormSection';

import { useAddOrgMembersController } from './addOrgMembersController';
import { tabsElements } from './tabsElements';
import { AddMemberTab, IAddOrgMembersFormProps } from './types';

export const AddOrgMembersForm = (props: IAddOrgMembersFormProps) => {
  const {
    title,
    memberType,
    isSubmitting,
    onSubmitInviteMember,
    isSubmittingPublicInvite,
    onSubmitPublicInviteMember,
  } = props;

  const {
    organization,
    tabsRef,
    currentTab,
    formActions,
    formState,
    control,
    appsConnectedToOrganization,
    isLoading,
    ecosystemWithApps,
    handleSubmitCustom,
    setValue,
  } = useAddOrgMembersController({
    memberType,
    onSubmitInviteMember,
    onSubmitPublicInviteMember,
  });

  return (
    <Scaffold title={title}>
      <VStack maxWidth="1250px" width="100%" alignItems="center">
        <Tabs
          ref={tabsRef}
          currentTab={currentTab}
          tabIndicatorColor={colors.primary[600]}
          swipeEnabled={false}
          tabfocusedTextColor={colors.primary[600]}
          elements={tabsElements}
        />
        <Box w="38.75rem">
          {currentTab === AddMemberTab.Access ? (
            <AccessFormSection
              memberType={memberType}
              isLoading={isLoading}
              ecosystems={ecosystemWithApps}
              control={control}
              appsConnectedToOrganization={appsConnectedToOrganization}
              setValue={setValue}
            />
          ) : null}
          {currentTab === AddMemberTab.Permissions ? (
            <PermissionsFormSection
              memberType={memberType}
              control={control}
              appsConnectedToOrganization={appsConnectedToOrganization}
            />
          ) : null}
          {currentTab === AddMemberTab.Compose ? (
            <ComposeFormSection control={control} formState={formState} />
          ) : null}
          {currentTab === AddMemberTab.Invitation ? (
            <InvitationFormSection
              errors={formState.errors}
              control={control}
              organization={organization}
              memberType={memberType}
            />
          ) : null}
          <FormAction
            options={{
              alignItems: 'center',
              justifyContent: 'space-around',
              spacingBetweenItems: 2,
            }}
            items={formActions}
            formState={formState}
            handleSubmitForm={handleSubmitCustom}
            isLoadingSubmit={isSubmitting || isSubmittingPublicInvite}
          />
        </Box>
      </VStack>
    </Scaffold>
  );
};
