import { BrowserRouter, Route, Routes as RouterRoutes } from 'react-router-dom';

import { useAppSelector } from '~/hooks/useAppSelector';
import { AuthenticatedRoutes } from '~/routes/Authenticated';
import { InvitesRoutes } from '~/routes/Common/partner-invites';
import { UnauthenticatedRoutes } from '~/routes/Unauthenticated';

export const Routes = () => {
  const { isAuthenticated } = useAppSelector(({ auth }) => auth);

  return (
    <BrowserRouter>
      <RouterRoutes>
        {/* INVITES */}
        <Route path="/:slug?/invite/*" element={<InvitesRoutes />} />

        {/* AUTH/UNAUTHENTICATED */}
        <Route
          path="/*"
          element={
            isAuthenticated ? (
              <AuthenticatedRoutes />
            ) : (
              <UnauthenticatedRoutes />
            )
          }
        />
      </RouterRoutes>
    </BrowserRouter>
  );
};
