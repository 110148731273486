import { HStack, Stack, Text, VStack } from 'native-base';
import Button from 'ui/components/Button';
import Select from 'ui/components/Select';

import { ModalInformation } from '~/components/ModalInformation';
import { TARGET_AMOUNT_MESSAGE } from '~/constants/messages.constants';
import { colors } from '~/theme/colors';
import { ICreateActivityDTO } from '~/types/dtos';

import { useActivityMetricsController } from './controller';
import { CustomUnitModal } from './CustomUnitModal';
import { ActivityMetricsProps } from './types';
import { handleShowUnitField } from './utils';

export const ActivityMetrics = ({
  control,
  watch,
  options,
  isEditing,
  clearMeasurementUnit,
  addOneForm,
  fields,
  onChangeMeasurementUnit,
  onAddedCustomUnit,
}: ActivityMetricsProps<ICreateActivityDTO>) => {
  const {
    handleOpenModal,
    handleCloseModal,
    handleOpenModalInformation,
    modalRef,
    modalInfoRef,
  } = useActivityMetricsController();

  return (
    <Stack justifyContent="center" w="100%" alignItems="center" mt={6}>
      <VStack w="full">
        <Stack w="full">
          <Stack w="3/4">
            <Stack>
              <Text fontSize="xl" fontWeight={500} color="gray.500">
                Activity Metrics
              </Text>
              <Text fontSize="sm" mt={2} fontWeight={400} color="gray.600">
                Tell volunteers how you will measure the success of this
                activity.
              </Text>
            </Stack>
          </Stack>
          <HStack width="100%" space={2} mt={4} mb={4}>
            <Stack width="100%">
              <Select
                name="measurementUnit"
                control={control}
                options={options}
                hasOptionsGroup
                isDisabled={isEditing}
                isClearable
                onChange={(value, { action }) => {
                  if (action === 'clear') {
                    clearMeasurementUnit && clearMeasurementUnit();
                  }
                  if (action === 'select-option') {
                    onChangeMeasurementUnit &&
                      onChangeMeasurementUnit(
                        value as { label: string; value: string },
                      );
                  }
                }}
              />
            </Stack>
            {/*  <Button
              maxWidth="20%"
              variant="outline"
              py="6"
              leftIconName="plus-circle"
              leftIconColor={colors.primary[500]}
              onPress={handleOpenModal}
            >
              Add Custom
            </Button> */}
          </HStack>
        </Stack>
        {handleShowUnitField({
          control,
          fields,
          options,
          watch,
          addOneForm,
          clearMeasurementUnit,
          isEditing,
          handleOpenModalInformation,
        })}
        <CustomUnitModal
          cancelAction={handleCloseModal}
          modalRef={modalRef}
          closeModal={handleCloseModal}
          onAddedCustomUnit={onAddedCustomUnit}
        />
      </VStack>
      <ModalInformation
        description={TARGET_AMOUNT_MESSAGE}
        headerText="Target amount"
        modalRef={modalInfoRef}
      />
    </Stack>
  );
};
