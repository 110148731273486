import { useEffect, useMemo, useRef, useState } from 'react';
import { IModalRefProps } from 'ui/components/Modals/Modal/types';

import { useActivityFormCommonController } from '~/components/ActivityFormBuilderPresets/Controllers/useActivityFormCommonController';

import { AboutFormProps, AppsForSelector } from './types';
import { createFormSchema } from './utils';

export const useAboutFormController = ({
  handleContinue,
  defaultValues,
  causeOptions,
  isLoading,
  connectedApps,
  requirementOptions,
  handleBack,
}: AboutFormProps) => {
  const modalRef = useRef<IModalRefProps>(null);

  const [appsForSelector, setAppsForSelector] = useState<AppsForSelector[]>([]);
  const commonController = useActivityFormCommonController({});

  const handleOpenModal = () => {
    modalRef.current?.open();
  };

  const formSchema = useMemo(() => {
    return createFormSchema({
      causeOptions,
      requirementOptions,
      connectedApps: appsForSelector,
      commonController,
      handleOpenModal,
      handleBack,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [causeOptions, requirementOptions, appsForSelector, commonController]);

  useEffect(() => {
    if (connectedApps && connectedApps?.length > 0) {
      const mappedApps = connectedApps.map((conenctedApp) => ({
        name: conenctedApp.name,
        value: conenctedApp._id,
        logo: conenctedApp.logo,
      }));

      setAppsForSelector(mappedApps);
    }
  }, [connectedApps]);

  return {
    causeOptions,
    formSchema,
    defaultValues,
    handleContinue,
    isLoading,
    appsForSelector,
    modalRef,
    formBuilderImperativeRef: commonController.formBuilderImperativeRef,
  };
};
