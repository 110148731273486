import { useRef, useState } from 'react';
import { toast } from 'react-toastify';
import { IModalRefProps } from 'ui/components/Modals/Modal/types';

import { ORGANIZATION_MESSAGES } from '~/constants/messages.constants';
import { PAGES } from '~/constants/pages.constants';
import { useAppSelector } from '~/hooks/useAppSelector';
import { useQuery } from '~/hooks/useQuery';
import { useRouter } from '~/hooks/useRouter';
import { EcosystemService } from '~/services/resources/ecosystem';
import { colors } from '~/theme/colors';
import { IPaginatedResponse } from '~/types';
import { EcosystemNetworkConnections } from '~/types/interfaces/ecosystem-network-connections';

import { useLoadConnection } from '../../../../../hooks/useLoadConnection';
import { useLoadOrganization } from '../../../../../hooks/useLoadOrganization';

export const usePartnerController = () => {
  const [tabIndex, setTabIndex] = useState(0);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const modalRef = useRef<IModalRefProps>(null);

  const {
    goToRoute,
    params: { organizationId, connectionId },
  } = useRouter();

  const { data: connection, isLoading: isConnectionLoading } =
    useLoadConnection(connectionId as string);

  const { data: organization, isLoading: isOrganizationLoading } =
    useLoadOrganization(organizationId as string);

  const { organizationSelectedId } = useAppSelector(({ auth }) => auth);
  const { selectedEcosystem } = useAppSelector(({ ecosystem }) => ecosystem);

  // const { data: partner, isLoading: isLoadingPartner } =
  //   useQuery<IOrganization>(`/organization/${partnerId}`, {
  //     key: `partner-${partnerId}-details`,
  //     queryOptions: {
  //       enabled: !!partnerId,
  //     },
  //   });

  const {
    data: ecosystemConnections,
    isLoading: isLoadingEcosystemConnections,
  } = useQuery<IPaginatedResponse<EcosystemNetworkConnections[]>>(
    `/ecosystem/${selectedEcosystem?._id}/partners`,
    {
      queryOptions: {
        enabled:
          !!organizationSelectedId &&
          !!connectionId &&
          !!selectedEcosystem?._id,
      },
      requestOptions: {
        params: {
          filter: JSON.stringify({
            $or: [
              {
                organizationOne: organizationSelectedId,
                organizationTwo: connectionId,
              },
              {
                organizationOne: connectionId,
                organizationTwo: organizationSelectedId,
              },
            ],
          }),
        },
      },
      key: `ecosystem-connections-filtered-${organizationSelectedId}`,
    },
  );

  const handleOpenModal = () => {
    modalRef.current?.open();
  };

  const handleCloseModal = () => {
    modalRef.current?.close();
  };

  const handleRemovePartner = async () => {
    try {
      const connectionToRemove = ecosystemConnections?.data[0];
      if (!selectedEcosystem || !connectionToRemove) return;
      setIsSubmitting(true);
      await EcosystemService.disconnect({
        ecosystemOne: connectionToRemove.ecosystemOne,
        ecosystemTwo: connectionToRemove.ecosystemTwo,
      });
      toast.success(ORGANIZATION_MESSAGES.REMOVE_PARTNER_SUCCESS);
      handleCloseModal();
      goToRoute(PAGES.MEMBERS);
    } catch (error) {
      toast.error(ORGANIZATION_MESSAGES.ERROR_ON_REMOVE_PARTNER);
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleChangeTab = (index: number) => {
    setTabIndex(index);
  };

  const popOverOptions = [
    {
      icon: {
        color: colors.error[600],
        icon: 'x-circle',
        size: 22,
      },
      title: 'Remove partner',
      action: handleOpenModal,
    },
  ];
  return {
    organization,
    isOrganizationLoading,
    connection,
    isConnectionLoading,
    popOverOptions,
    tabIndex,
    modalRef,
    isSubmitting,
    isLoadingEcosystemConnections,
    handleRemovePartner,
    handleCloseModal,
    handleChangeTab,
  };
};
