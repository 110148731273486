import { Stack, Text, VStack } from 'native-base';
import { forwardRef } from 'react';
import Button from 'ui/components/Button';
import Modal from 'ui/components/Modals/Modal';
import { IModalRefProps } from 'ui/components/Modals/Modal/types';
import TextInput from 'ui/components/v2/inputs/TextInput';
import { Loading } from 'ui/components/v2/Loading';
import { colors } from 'ui/theme/colors';

import { useDepartmentFormModalController } from './controller';
import { DepartmentFormModalProps } from './types';

export const DepartmentFormModal = forwardRef(function ModalNewActivity(
  props: DepartmentFormModalProps,
  ref,
) {
  const {
    title,
    control,
    isLoading,
    formState,
    actionLabel,
    handleCancel,
    onSubmitHandler,
  } = useDepartmentFormModalController(
    props,
    ref as React.MutableRefObject<IModalRefProps>,
  );
  return (
    <Modal
      ref={ref}
      headerText={title}
      onClose={handleCancel}
      modalContentMaxW="620px"
    >
      <VStack>
        {isLoading && (
          <Loading
            backgroundColor="#ffffff55"
            showSpinner={false}
            height={'100%'}
            float
          />
        )}
        <VStack py={6}>
          <TextInput.Controlled
            isRequired
            name="name"
            label="Group"
            placeholder="e.g. Finance, Human Resources, Marketing"
            control={control}
            isDisabled={isLoading}
            errorMessage={formState.errors?.name?.message}
          />
        </VStack>

        <Stack
          my={4}
          w="full"
          flexDirection={{ base: 'column', md: 'row' }}
          style={{ gap: 3 }}
        >
          <Stack flex={1}>
            <Button
              variant={'ghost'}
              onPress={handleCancel}
              isDisabled={false}
              isLoading={false}
            >
              <Text fontSize={'sm'} fontWeight={500}>
                Cancel
              </Text>
            </Button>
          </Stack>

          <Stack flex={1}>
            <Button
              onPress={onSubmitHandler}
              backgroundColor={colors.primary[400]}
              isLoading={isLoading}
            >
              <Text fontSize={'sm'} fontWeight={500} color={'white'}>
                {actionLabel}
              </Text>
            </Button>
          </Stack>
        </Stack>
      </VStack>
    </Modal>
  );
});

export default DepartmentFormModal;
