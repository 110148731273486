import { Divider, HStack, Stack, Text } from 'native-base';
import { Controller, FieldErrors } from 'react-hook-form';
import ReadMore from 'ui/components/ReadMore';
import { LocationAutoComplete } from 'ui/components/v2/LocationInputs/LocationAutoComplete';

import { useMultipleFormController } from '~/pages/Authenticated/Events/AddEvent/EventForm/WhenWhereForm/MultipleForm/controller';
import { GooglePlacesAutoCompleteAdapter } from '~/services/google/google-places-auto-auto-complete';
import { colors } from '~/theme/colors';
import { IDatesDTO } from '~/types/dtos';

import { DatesForm } from '../DatesForm';
import { SingleFormProps } from './types';

const googlePlacesAutoComplete = GooglePlacesAutoCompleteAdapter.getSingleton();

export const SingleLocationForm = ({
  control,
  addDateForm,
  datesFields,
  appendDate,
  errors,
  removeDateForm,
  isTeamEvent,
  isEditing,
  setValue,
  isEventActivity,
}: SingleFormProps) => {
  const { getTotalDates } = useMultipleFormController({ datesFields });
  return (
    <Stack w="100%" mt="10">
      <Stack
        mb="10"
        p={6}
        borderRadius="xl"
        borderWidth={'1'}
        borderColor="gray.200"
        bgColor={colors.singletons.lightText}
      >
        <Stack flex={1}>
          <HStack w="full" alignItems="center" justifyContent="space-between">
            <Text color="gray.600" fontWeight="500" fontSize="18px">
              Location
            </Text>
          </HStack>
          <Divider my="10px" />
        </Stack>

        <Stack flex={1} zIndex={1} mb={4}>
          <Controller
            name={'address'}
            control={control}
            render={({ field: { onChange, value } }) => (
              <LocationAutoComplete
                isRequired
                label="Full address"
                onChange={onChange}
                defaultValue={value}
                provider={googlePlacesAutoComplete}
              />
            )}
          />
        </Stack>
        <ReadMore
          textHeight="0"
          btnBgColor="transparent"
          variant="ghost"
          btnTextColor="darkText"
          rightIconColor="darkText"
          buttonTextShow="Show dates"
          buttonTextHide="Hide dates"
          open
          text={
            <div id="datesForm">
              <DatesForm
                placeFormIndex={0}
                addOneForm={() => addDateForm(0, getTotalDates(0))}
                removeOneForm={removeDateForm}
                control={control}
                append={appendDate}
                fields={datesFields}
                errors={errors as FieldErrors<{ [key: string]: IDatesDTO[] }>}
                isEventActivity={isEventActivity}
                isTeamEvent={isTeamEvent}
                isEditing={isEditing}
                setValue={setValue}
              />
            </div>
          }
        />
      </Stack>
    </Stack>
  );
};
