import { Divider, HStack, Spinner, Stack, Text, VStack } from 'native-base';
import Icon from 'ui/components/Icon';
import Modal from 'ui/components/Modals/Modal';

import {
  IDataContentProps,
  IModalActionReportProps,
} from '~/components/ModalActionReport/types';

import { useModalActionReportController } from './controller';

const DataContent = ({ label, value }: IDataContentProps) => (
  <HStack space={2} alignItems={'baseline'}>
    <Text fontSize="3xl" fontWeight="700" color="gray.900">
      {value}
    </Text>
    <Text fontSize="md" color="gray.500" fontWeight="500">
      {label}
    </Text>
  </HStack>
);

export const ModalActionReport = ({
  headerText,
  modalRef,
  placement = 'center',
  totalTimeLoggedInAction,
  totalApplications,
  userPofileId,
  modalIsOpen,
}: IModalActionReportProps) => {
  const {
    customUnitsTargetAmountLoggedData,
    totalKilogramsLogged,
    totalStepsLogged,
    isLoading,
  } = useModalActionReportController();

  return (
    <Modal
      placement={placement}
      headerText={headerText}
      modalContentMaxW="816"
      bodyPadding="24px"
      headerIconName="puzzle-tertiary"
      isHeaderIconCustom
      ref={modalRef}
    >
      <>
        {isLoading ? (
          <Stack
            space={2}
            alignItems="center"
            justifyContent="center"
            h="350px"
          >
            <Spinner size="lg" />
          </Stack>
        ) : (
          <HStack space={2}>
            <Stack space={2}>
              <Text fontSize="6xl" fontWeight="700" color="gray.900">
                {totalApplications}
              </Text>
              <Text fontSize="lg" color="gray.500" fontWeight="500">
                Applications
              </Text>
            </Stack>
            <Divider
              orientation="vertical"
              bgColor="orange.500"
              size="0.5"
              height="342px"
              mx={8}
            />
            <Stack>
              <VStack space={6}>
                <HStack space={2} color="gray.500" alignItems="center">
                  <Icon icon="trending-up" color="gray.500" size={'20px'} />
                  <Text fontSize="lg" color="gray.500" fontWeight="500">
                    Results of their actions
                  </Text>
                </HStack>
                <HStack space={10}>
                  <VStack space={3}>
                    <DataContent
                      value={totalTimeLoggedInAction}
                      label="in actions"
                    />
                    <DataContent
                      value={totalStepsLogged?.total?.toString() || '0'}
                      label="steps completed"
                    />
                    <DataContent
                      value={totalKilogramsLogged?.total?.toString() || '0'}
                      label="kg achieved"
                    />
                  </VStack>
                  <VStack space={3}>
                    {customUnitsTargetAmountLoggedData?.map((target) => (
                      <DataContent
                        key={target._id}
                        value={target?.total?.toString() || '0'}
                        label={target?._id || ''}
                      />
                    ))}
                  </VStack>
                </HStack>
              </VStack>
            </Stack>
          </HStack>
        )}
      </>
    </Modal>
  );
};
