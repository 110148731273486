import { Alert, HStack, Progress, Stack, Text, VStack } from 'native-base';
import Button from 'ui/components/Button';
import { AppSection } from 'ui/components/FormBuilderV2/components/AppSection';
import Icon from 'ui/components/Icon';
import Loading from 'ui/components/Loading';

import Scaffold from '~/components/Scaffold';
import { PAGES } from '~/constants/pages.constants';
import { useActivitiesUploadController } from '~/pages/Authenticated/BulkUploads/ActivitiesUpload/controller';
import { ActivitiesUploadContent } from '~/pages/Authenticated/BulkUploads/components/ActivitiesUploadContent';
import { CSVErrorList } from '~/pages/Authenticated/BulkUploads/components/CSVErrorsList';
import { ImportsTable } from '~/pages/Authenticated/BulkUploads/components/ImportsTable';
import { UploadScafoldFooter } from '~/pages/Authenticated/BulkUploads/components/UploadScafoldFooter';
import { EImportFileType } from '~/pages/Authenticated/BulkUploads/types';
import { UploadsSignedURLObjectTarget } from '~/services/resources/types';
import { colors } from '~/theme/colors';

export const ActivitiesUpload = () => {
  const {
    control,
    uploadActivityFile,
    uploadIsLoading,
    refetchQuantity,
    csvValidationErrors,
    isViewMode,
    handleDownloadTemplate,
    handleDropFile,
    handleRemoveFile,
    appList,
    isDefaultImportFileLoading,
    defaultImportFile,
    goToRoute,
    progressValue,
    refreshImports,
  } = useActivitiesUploadController({
    target: UploadsSignedURLObjectTarget.ImportActivityDefinition,
    importFileType: EImportFileType.ActivityDefinition,
  });

  if (isViewMode && isDefaultImportFileLoading) {
    return (
      <Scaffold title="Activities file">
        <Stack py="6" justifyContent="center" w="100%">
          <Loading containerHeight="" />
        </Stack>
      </Scaffold>
    );
  }

  return (
    <Scaffold
      title={isViewMode ? 'Activities file' : 'Activities Upload'}
      actionComponents={
        <HStack space={4}>
          <Button
            variant="outline"
            p={5}
            width={'fit-content'}
            onPress={() => goToRoute(PAGES.UPLOADS_PLACES)}
          >
            <HStack space={2} alignItems={'center'}>
              <Icon
                isCustom
                icon="folder-arrow-up"
                color={colors.primary[300]}
                size={4}
              />

              <Text color={colors.primary[300]} fontSize="sm" fontWeight={500}>
                Upload places
              </Text>
            </HStack>
          </Button>
          <Button
            variant="solid"
            p={5}
            width={'fit-content'}
            fontWeight={600}
            onPress={() => goToRoute(PAGES.ACTIVITIES)}
          >
            Finish
          </Button>
        </HStack>
      }
    >
      {!defaultImportFile && (
        <>
          <Stack space="1">
            <Text color="gray.600" fontSize={18} fontWeight="medium" mb="6">
              Upload your activities
            </Text>

            <Button
              variant={'outline'}
              leftIconName="file-text"
              w="container"
              leftIconColor={colors.primary['600']}
              onPress={handleDownloadTemplate}
            >
              Download template
            </Button>
          </Stack>

          <Alert status="warning">
            <Text>
              The bulk upload template was updated on 10/04/24 to cover regional
              work-from-home activities and applications external to our
              platform. If you are using the previously provided template, the
              bulk upload will also work for this model.
            </Text>
          </Alert>

          <AppSection
            name={'appSelect'}
            appsList={appList}
            title={'Selecione o app'}
            subTitle="123"
            control={control}
            onChangeValue={() => null}
          />
        </>
      )}

      <ActivitiesUploadContent
        control={control}
        haveImports={!!defaultImportFile}
        uploadIsLoading={uploadIsLoading}
        defaultImportedFile={defaultImportFile}
        handleDropFile={handleDropFile}
        handleRemoveFile={handleRemoveFile}
        importFileType={EImportFileType.ActivityDefinition}
      />

      {!defaultImportFile ? (
        <>
          <UploadScafoldFooter
            isLoading={uploadIsLoading}
            isDisabled={!!defaultImportFile}
            onClick={uploadActivityFile}
          />
          <CSVErrorList errors={csvValidationErrors} />
        </>
      ) : (
        <VStack width="100%" space="2">
          <Alert
            padding={3}
            backgroundColor="warning.100"
            status="warning"
            borderRadius="md"
            width="100%"
          >
            <Text>
              You can leave this page and come back later to check the progress.
            </Text>
          </Alert>
          <UploadScafoldFooter showRefreshButton onClick={refreshImports} />
          <HStack
            flex={1}
            alignItems={'center'}
            justifyContent={'space-between'}
            space={2}
          >
            <Progress value={progressValue || 0} flexGrow={1} />
            <Text color="gray.500" fontSize="sm">
              {(progressValue || 0)?.toFixed(2)}%
            </Text>
          </HStack>

          <ImportsTable
            importFileId={defaultImportFile?._id}
            refetchQuantity={refetchQuantity}
          />
        </VStack>
      )}
    </Scaffold>
  );
};
