import { TypeComputedProps as DataGridProps } from '@inovua/reactdatagrid-community/types';
import { useCallback, useEffect, useRef, useState } from 'react';
import { IFilter } from 'ui/components/DataTable/types';
import { IModalRefProps } from 'ui/components/Modals/Modal/types';

import { PAGES } from '~/constants/pages.constants';
import { ActivityTypeEnum } from '~/enums';
import { useAppSelector } from '~/hooks/useAppSelector';
import { useLoadAppsConnectedToEcosystem } from '~/hooks/useLoadAppsConnectedToEcosystem';
import { usePermissions } from '~/hooks/usePermissions';
import { useRouter } from '~/hooks/useRouter';
import ActivityDefinitionService from '~/services/resources/activityDefinition';

import { useLoadEcosystemApplicationsReport } from '../../../../hooks/reports/ecosystems/useLoadEcosystemApplicationsReport';
import { useLoadEcosystemLogHoursReportByType } from '../../../../hooks/reports/ecosystems/useLoadEcosystemLogHoursReportByType';
import { useLoadEcosystemLogHoursTotalReport } from '../../../../hooks/reports/ecosystems/useLoadEcosystemLogHoursTotalReport';

export const useActivitiesListController = () => {
  const actionReportModalRef = useRef<IModalRefProps>(null);
  // Ref will be auto-assigned on onReady function of the table, as according to the docs, so we need let in this case and to type correctly
  // https://reactdatagrid.io/docs/api-reference#props-onReady
  // eslint-disable-next-line prefer-const
  let tableRef = useRef<DataGridProps | null>(null);

  const [activitiesCount, setActivitiesCount] = useState(0);

  const { organizationSelectedId } = useAppSelector(({ auth }) => auth);
  const { selectedEcosystem } = useAppSelector(({ ecosystem }) => ecosystem);
  const { connectedApps, isLoadingConnectedApps, isRefetchingConnectedApps } =
    useLoadAppsConnectedToEcosystem(
      organizationSelectedId,
      selectedEcosystem?._id,
    );

  const { goToRoute } = useRouter();
  const {
    data: applicationsCount,
    isLoading: isLoadingEventApplicationsCount,
  } = useLoadEcosystemApplicationsReport(selectedEcosystem?._id || '');

  const setTableRef = (
    computedPropsRef: React.MutableRefObject<DataGridProps | null>,
  ) => {
    tableRef.current = computedPropsRef.current;
  };

  const { data: logHoursReportData, isLoading: isLoadingLogHoursReportData } =
    useLoadEcosystemLogHoursReportByType(selectedEcosystem?._id || '');

  const { data: logHoursTotal, isLoading: isLoadingLogHoursTotal } =
    useLoadEcosystemLogHoursTotalReport(selectedEcosystem?._id || '');
  const { validateIfOrganizationIsAppOwner, validateIfAppHasPermission } =
    usePermissions();
  const loadActivitiesList = useCallback(
    async (skip: number, limit: number, filter: IFilter) => {
      try {
        const {
          data: { data, count },
        } = await ActivityDefinitionService.findAll({
          skip,
          filter: JSON.stringify({
            ...filter,
            organization: organizationSelectedId,
            ecosystem: selectedEcosystem?._id,
          }),
          limit,
          populate: JSON.stringify(['app']),
          sort: JSON.stringify({ createdAt: -1 }),
        });
        setActivitiesCount(count);
        return { data, count };
      } catch (err) {
        return [];
      }
    },
    [organizationSelectedId, selectedEcosystem?._id],
  );

  const openModal = () => {
    actionReportModalRef.current?.open();
  };

  const handleEditActivity = (activityType: ActivityTypeEnum, id: string) => {
    switch (activityType) {
      case ActivityTypeEnum.Event:
        goToRoute(`${PAGES.EDIT_EVENT}/${id}`);
        break;
      case ActivityTypeEnum.OngoingOpportunity:
        goToRoute(`${PAGES.EDIT_ONGOING_OPPORTUNITY}/${id}`);
        break;
      case ActivityTypeEnum.Action:
        goToRoute(`${PAGES.EDIT_ACTION}/${id}`);
        break;
      default:
        break;
    }
  };

  const handleViewActivity = (activityType: ActivityTypeEnum, id: string) => {
    switch (activityType) {
      case ActivityTypeEnum.Event:
        goToRoute(`${PAGES.VIEW_EVENT_ACTIVITY}/${id}`);
        break;
      case ActivityTypeEnum.OngoingOpportunity:
        goToRoute(`${PAGES.VIEW_ONGOING_ACTIVITY}/${id}`);
        break;
      case ActivityTypeEnum.Action:
        goToRoute(`${PAGES.VIEW_ACTION_ACTIVITY}/${id}`);
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    // Hack to avoid showing empty page when changing ecosystem, we need to modify the table
    // to work better with this in the future.
    if (isRefetchingConnectedApps) {
      tableRef?.current?.setSkip && tableRef.current.setSkip(0);
    }
  }, [isRefetchingConnectedApps]);

  return {
    logHoursReportData,
    logHoursTotal,
    isLoadingReports:
      isLoadingEventApplicationsCount || isLoadingLogHoursReportData,
    applicationsCount,
    loadActivitiesList,
    actionReportModalRef,
    openModal,
    handleEditActivity,
    handleViewActivity,
    activitiesCount,
    connectedApps,
    isLoadingConnectedApps,
    isRefetchingConnectedApps,
    setTableRef,
    validateIfOrganizationIsAppOwner,
    validateIfAppHasPermission,
  };
};
