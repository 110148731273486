import { colors } from 'ui/theme/colors';

import { TeamMemberStatus } from '../../../../types/teams';

export const TeamMemberStatusMap = {
  [TeamMemberStatus.PENDING]: {
    color: '#c2410c',
    label: 'Pending invitation',
  },
  [TeamMemberStatus.REJECTED]: {
    color: '#dc2626',
    label: 'Declined',
  },
  [TeamMemberStatus.ACCEPTED]: {
    color: colors.green[400],
    label: '',
  },
};
