import { useMemo } from 'react';
import { LogTileMeasurementSummary } from 'ui/components/v2/Tiles/LogsSummaryTile/types';
import { formatMeasurementAmount } from 'ui/utils/measurements/format';

import { makeActivityTypeBadgeTitleNode } from '~/components/Cards/NotificationCard/utils';

import { userUserNotificationCardController } from '../controller';
import { MeasurementDeletedNotificationCardProps } from './types';

export const useMeasurementDeletedNotificationCardController = (
  props: MeasurementDeletedNotificationCardProps,
) => {
  const { activityType, measurementUnit, measurementsAmount } = props;

  const { date, profilePictureUrl, authorTitleNode } =
    userUserNotificationCardController(props);

  const titleNodes = useMemo(() => {
    const amount = formatMeasurementAmount(measurementsAmount, measurementUnit);
    return [
      authorTitleNode,
      {
        text: `deleted a log of`,
        fontColor: 'error.600',
      },
      {
        text: amount,
        fontColor: 'error.600',
        fontWeight: 500,
      },
      { text: 'on an activity' },
      makeActivityTypeBadgeTitleNode(activityType),
    ];
  }, [activityType, measurementUnit, measurementsAmount, authorTitleNode]);

  const measurementSummary: LogTileMeasurementSummary = {
    measurementUnit,
    amount: measurementsAmount,
  };

  return { titleNodes, date, profilePictureUrl, measurementSummary };
};
