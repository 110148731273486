import { useMemo } from 'react';

import { IActionOptions, IActionsProps } from './types';

export const useFormActionController = ({
  items,
  options,
  handleSubmitForm,
  isLoadingSubmit,
  formState: { isValid, isSubmitting },
}: IActionsProps) => {
  const optionsMemo: IActionOptions = useMemo(() => {
    return {
      alignItems: options?.alignItems || 'flex-end',
      justifyContent: options?.justifyContent || 'flex-end',
      direction: options?.direction || 'row',
      spacingBetweenItems: options?.spacingBetweenItems || 3,
    };
  }, [options]);

  return {
    items,
    optionsMemo,
    isSubmitting,
    isLoadingSubmit,
    handleSubmitForm,
    isValid,
  };
};
