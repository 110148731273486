import { api } from '~/services/api';
import { EmailPreferences } from '~/types/interfaces/email-preferences';

const MODULE_URL = 'admin-profile';

interface AdminProfilePayload {
  emailPreferences: Partial<EmailPreferences>;
  organization: string;
}

export default class AdminProfileService {
  static async findByOrganizationId(organizationId: string) {
    return await api.get(`${MODULE_URL}/organization/${organizationId}`);
  }

  static async createAdminProfile(data: AdminProfilePayload) {
    return await api.post(`${MODULE_URL}`, data);
  }

  static async updateAdminProfile(
    adminProfileId: string,
    data: AdminProfilePayload,
  ) {
    return await api.put(`${MODULE_URL}/${adminProfileId}`, data);
  }
}
