import { Stack } from 'native-base';

import { EditActionForm } from './EditActionForm';

export const EditAction = () => {
  return (
    <Stack>
      <EditActionForm />
    </Stack>
  );
};
