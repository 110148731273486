import { yupResolver } from '@hookform/resolvers/yup';
import { useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { EActivityAttendanceTypes } from 'ui/enums';

import { ICreateActivityDTO } from '~/types/dtos';

import { SingleLocationOngoingProps } from './types';
import { inPersonSchemaValidation } from './utils';

export const useSingleLocationOngoingController = ({
  handleContinue,
  handleBack,
  isLoading,
  defaultValues,
  isEditing,
}: SingleLocationOngoingProps) => {
  const {
    control,
    handleSubmit,
    formState: { isValid, errors },
    resetField,
  } = useForm<ICreateActivityDTO>({
    resolver: yupResolver(inPersonSchemaValidation),
    mode: 'onChange',
    reValidateMode: 'onBlur',

    defaultValues: defaultValues || {
      attendanceType: EActivityAttendanceTypes.InPerson,
      address: undefined,
      volunteerNumber: undefined,
      noVolunteerLimit: false,
    },
  });

  const handleSubmitForm = handleSubmit((data: ICreateActivityDTO) => {
    handleContinue(data);
  });

  const { isDisabledPublishButton } = useMemo(() => {
    return {
      isDisabledPublishButton: !isValid,
    };
  }, [isValid]);

  const updateNoVolunteerLimitValue = (value: boolean) => {
    resetField('noVolunteerLimit', { defaultValue: value });
  };

  return {
    handleBack,
    handleSubmitForm,
    control,
    isLoading,
    isDisabledPublishButton,
    errors,
    isEditing,
    updateNoVolunteerLimitValue,
  };
};
