import { useMemo } from 'react';

import { PAGES } from '~/constants/pages.constants';
import { useRouter } from '~/hooks/useRouter';

export const usePartnerInviteFromUrl = () => {
  const { params } = useRouter();

  const inviteURL = useMemo(() => {
    if (!params.code) return '';

    let _invite_url = PAGES.INVITE_JOIN_AS_PARTNER.replace(
      ':code',
      params.code,
    );

    if (params.slug) {
      _invite_url = PAGES.INVITE_JOIN_AS_PARTNER_SLUG.replace(
        ':slug',
        params.slug,
      ).replace(':code', params.code);
    }

    return _invite_url;
  }, [params]);

  return { inviteURL, code: params.code, slug: params.slug };
};
