import { HStack, Spinner, Stack, Text } from 'native-base';
import Button from 'ui/components/Button';

import { colors } from '~/theme/colors';

import { EditGeneralSettings } from './EditGeneralSettings';
import { styles } from './styles';
import { useGeneralSettingsController } from './useGeneralSettingsController';
import { ViewGeneralSettings } from './ViewGeneralSettings';

export const GeneralSettings = () => {
  const {
    isLoadingOrganization,
    organization,
    organizationLogo,
    isEditing,
    control,
    errors,
    isSubmitting,
    causeOptions,
    refetchOrganization,
    onSubmitUpdate,
    handleSetIsEditing,
    handleCopy,
  } = useGeneralSettingsController();

  if (isLoadingOrganization) {
    return (
      <Stack height="100%" alignItems="center" justifyContent="center">
        <Spinner size={20} />
      </Stack>
    );
  }

  return (
    <Stack space={5}>
      <HStack justifyContent="space-between">
        <Stack space={2}>
          <Text fontSize="xl" fontWeight="500" color="gray.600">
            General
          </Text>
          <Text fontSize="sm" fontWeight="400" color="gray.600">
            General information about the organisation
          </Text>
        </Stack>
        {!isEditing ? (
          <Button
            minW="76px"
            maxW="76px"
            variant="outline"
            borderColor={colors.primary[200]}
            _text={styles.button._text}
            leftIconName="edit"
            leftIconColor={colors.primary[400]}
            leftIconSize={16}
            onPress={handleSetIsEditing}
          >
            Edit
          </Button>
        ) : (
          <Button
            minW="124px"
            maxW="124px"
            onPress={onSubmitUpdate}
            _text={{ fontWeight: 500 }}
            isLoading={isSubmitting}
            isDisabled={isSubmitting}
          >
            Save changes
          </Button>
        )}
      </HStack>
      {!isEditing ? (
        <ViewGeneralSettings
          handleCopy={handleCopy}
          organizationLogo={organizationLogo}
          organization={organization}
          onRefetch={refetchOrganization}
        />
      ) : (
        <EditGeneralSettings
          errors={errors}
          control={control}
          causeOptions={causeOptions}
          organization={organization}
          onRefetch={refetchOrganization}
        />
      )}
    </Stack>
  );
};
