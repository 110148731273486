export const opportunityFormTabsElements = [
  {
    title: 'About the Opportunity',
    component: () => <></>,
  },
  {
    title: 'Media',
    component: () => <></>,
  },
  {
    title: 'When and Where',
    component: () => <></>,
  },
];
