import { Flex, Image, Pressable, Spinner, Stack, Text } from 'native-base';
import FallBackLogo from 'ui/assets/svgs/placeholder.svg';
import { GoBackButton } from 'ui/components/GoBackButton';
import { colors } from 'ui/theme/colors';

import { useChangeOrgController } from '~/pages/Common/ChangeOrgController/controller';

export const ChangeOrg = () => {
  const { organizations, goBack, onChangeOrg, isChangingOrg } =
    useChangeOrgController();

  if (isChangingOrg) {
    return (
      <Stack
        space={4}
        alignItems="center"
        justifyContent="center"
        width="100%"
        height="100vh"
      >
        <Text>Loading your organisation</Text>
        <Spinner color="gray.900" size="lg" />
      </Stack>
    );
  }

  return (
    <Stack height="100vh" paddingX={{ base: 4, lg: 24 }}>
      <GoBackButton title="Back" onPress={goBack} />
      <Flex
        direction="column"
        alignItems="center"
        justifyContent="center"
        height="100vh"
        style={{ gap: 48 }}
      >
        <Text fontSize={20} fontWeight={500} lineHeight="150%">
          Choose an organisation
        </Text>
        <Flex
          justifyContent="center"
          style={{ gap: 12 }}
          alignItems="stretch"
          direction="row"
          flexWrap="wrap"
          backgroundColor={colors.white}
        >
          {organizations?.map((org) => (
            <Pressable
              key={org._id}
              onPress={() => onChangeOrg(org._id)}
              _hover={{
                backgroundColor: colors.gray[100],
              }}
              padding={3}
              borderRadius={24}
            >
              <Flex
                justifyContent="center"
                alignItems="center"
                textAlign="center"
                style={{ gap: 12 }}
              >
                <Image
                  src={org.logo || FallBackLogo}
                  width="3.5rem"
                  height="3.5rem"
                  borderRadius={100}
                />
                <Flex
                  width={100}
                  style={{ gap: 12 }}
                  alignItems="center"
                  alignSelf="stretch"
                >
                  <Text
                    textAlign="center"
                    fontSize={14}
                    fontWeight={500}
                    lineHeight="150%"
                  >
                    {org.name}
                  </Text>
                </Flex>
              </Flex>
            </Pressable>
          ))}
        </Flex>
      </Flex>
    </Stack>
  );
};
