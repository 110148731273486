import { useQuery } from '~/hooks/useQuery';

export const useLoadEcosystemUserProfilesCount = (ecosystemId?: string) => {
  const { data, isLoading, isFetched } = useQuery<{ count: number }>(
    `org-admin/ecosystem/${ecosystemId}/user-profiles/count`,
    {
      key: `org-admin-ecosystem-${ecosystemId}-user-profiles-count`,
      queryOptions: { enabled: !!ecosystemId },
    },
  );

  return { data: data?.count || 0, isLoading, isFetched };
};
